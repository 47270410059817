import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d", "fill", "stroke"]
const _hoisted_3 = ["x", "y", "fill", "font-size"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      viewBox: _ctx.viewBox,
      height: "6rem"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allAspects, (aspect, i) => {
        return (_openBlock(), _createElementBlock("g", { key: i }, [
          _createElementVNode("path", {
            d: _ctx.getAspectPath(i),
            fill: _ctx.isActiveAspect(aspect) ? 'black' : 'white',
            stroke: _ctx.isActiveAspect(aspect) ? 'none' : 'black',
            "stroke-width": "2"
          }, null, 8, _hoisted_2),
          (i % 2 === 0)
            ? (_openBlock(), _createElementBlock("text", {
                key: 0,
                x: _ctx.labelPositions[i].x,
                y: _ctx.labelPositions[i].y,
                fill: _ctx.isActiveAspect(aspect) ? 'white' : 'black',
                "font-size": _ctx.dimensions.labelFontSize,
                "text-anchor": "middle",
                "dominant-baseline": "middle"
              }, _toDisplayString(aspect), 9, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 8, _hoisted_1))
  ]))
}
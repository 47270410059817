import BaseService from '@/services/BaseService'
import { AxiosInstance, AxiosResponse } from 'axios'
import { LineString, Point } from 'geojson'
import { CardinalDirection } from '@/model/CardinalDirection'

export interface PointInfo {
  longitude: number
  latitude: number
  elevation?: number | null
  aspect?: CardinalDirection | null
  slopeAngle?: number | null
}

export interface FirstLast {
  first: PointInfo
  last: PointInfo
}

export interface LowestHighest {
  lowest: PointInfo
  highest: PointInfo
}

export class GeoService {
  private static _baseService?: BaseService

  private static get instance(): AxiosInstance {
    const baseService =
      this._baseService || new BaseService('GEO_SERVICE_URL', 5000)
    this._baseService = baseService
    return baseService.instance
  }

  public static async getPointInfo(point: Point): Promise<PointInfo> {
    const result: AxiosResponse<PointInfo> = await this.instance.post(
      `/point-info`,
      point
    )
    return result.data
  }

  public static async getFirstLast(lineString: LineString): Promise<FirstLast> {
    const result: AxiosResponse<FirstLast> = await this.instance.post(
      `/start-end`,
      lineString
    )
    return result.data
  }

  public static async getLowestHighest(
    lineString: LineString
  ): Promise<LowestHighest> {
    const result: AxiosResponse<LowestHighest> = await this.instance.post(
      `/lowest-highest`,
      lineString
    )
    return result.data
  }
}

<template>
  <div v-if="visible" v-bind:class="{ legend: !mobile, legend_mobile: mobile }">
    <div v-if="$props.legend && legend.title" class="legend-title">
      {{ $t(legend.title) }}
    </div>
    <div class="legend-subtitle">{{ $t('pageTitel.' + productVariant) }}</div>
    <b-row
      class="legend-row"
      v-for="entry in legend.items.slice(0, 2)"
      :key="entry.id"
    >
      <b-col cols="4"><img :src="entry.symbol.src" class="symbol" /></b-col>
      <b-col cols="1" class="label">{{ $t(entry.label) }}</b-col>
    </b-row>
  </div>
</template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'ProblemLegend',
  props: ['legend'],
  data() {
    return {
      visible: false,
      mobile: false,
      productVariant: null,
    }
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      // closed legend initially
      this.visible = false
    } else {
      this.mobile = true
    }
  },
  mounted() {
    const self = this
    emitter.on('updateProblemLegend', (productVariant) => {
      self.productVariant = productVariant
    })
    emitter.on('showLegend', (show) => {
      show ? (this.visible = true) : (this.visible = false)
    })
  },
  beforeUnmount() {
    emitter.off('showLegend')
    emitter.off('updateProblemLegend')
  },
}
</script>

<style scoped>
.legend-row {
  display: flex;
  align-items: center;
}

.symbol {
  height: 18px;
}

.legend-subtitle {
  font-weight: bolder;
  padding-top: 5px;
  padding-bottom: 0;
  font-size: 12px;
  max-width: 175px;
}

.label {
  font-size: 14px;
}

.legend-title {
  font-weight: bolder;
  color: black;
  padding-top: 5px;
  padding-bottom: 0;
  white-space: normal;
}
</style>

<template>
  <b-container class="jumbotron">
    <h1 class="display-3">SLFPro</h1>
    <p class="info-text">{{ $t('ui.home.infotext') }}</p>
    <p class="version">
      {{ `${$t('common.version')}:` }} <b>{{ version }}</b>
      {{ environmentName }}
    </p>
  </b-container>
</template>

<script>
import packageInfo from '../../package.json'
import { EnvironmentService } from '@/services/EnvironmentService'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  computed: {
    version() {
      return packageInfo.version
    },
    environmentName() {
      const envName = EnvironmentService.getOptionalVariable('ENVIRONMENT_NAME')
      return envName == null || envName === 'PRODUCTION' ? '' : envName
    },
  },
}
</script>

<style scoped>
.jumbotron {
  margin-top: 1.25rem;
  background-color: #eeeeee;
}

.info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.version {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>

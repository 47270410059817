import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js'
import { EnvironmentService } from '@/services/EnvironmentService'

const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = { onLoad: 'login-required' }
// In Sekunden. Der Token wird aktualisiert, wenn er nur noch weniger als x gültig ist
const MIN_VALIDITY = 70
// In Millisekunden
const REFRESH_INTERVAL = 60000

export class KeycloakAuthentication {
  private static keycloak: Keycloak.KeycloakInstance | undefined = undefined

  static getKeycloakInstance(): Keycloak | undefined {
    return this.keycloak
  }

  static getKeycloakLogoutUrl(): string | undefined {
    return this.keycloak?.createLogoutUrl()
  }

  static getKeycloakToken(): string | undefined {
    return this.keycloak?.token
  }

  static async initialize(): Promise<void> {
    if (this.keycloak) {
      return Promise.resolve()
    }
    const keycloak = new Keycloak(this.getKeycloakConfig())
    // Lokal 'checkLoginIframe = false' verwenden
    let keycloakInitOptions = { ...KEYCLOAK_INIT_OPTIONS }
    if (process.env.NODE_ENV !== 'production') {
      keycloakInitOptions = {
        ...KEYCLOAK_INIT_OPTIONS,
        checkLoginIframe: false,
      }
    }
    return keycloak
      .init(keycloakInitOptions)
      .then((auth) => {
        if (!auth) {
          window.location.reload()
        } else {
          this.keycloak = keycloak
        }
        //Token Refresh
        setInterval(() => {
          keycloak.updateToken(MIN_VALIDITY).catch(() => {
            console.error('Failed to refresh token')
          })
        }, REFRESH_INTERVAL)
      })
      .catch((e) => {
        console.error('Authenticated Failed', e)
      })
  }

  private static getKeycloakConfig(): KeycloakConfig {
    const authRealm =
      EnvironmentService.getOptionalVariable('KEYCLOAK_AUTHREALM') || 'SLF'
    const authUrl = EnvironmentService.getVariable('KEYCLOAK_AUTHSERVERURL')
    const authClientId = EnvironmentService.getVariable('KEYCLOAK_AUTHCLIENTID')

    return {
      url: authUrl,
      realm: authRealm,
      clientId: authClientId,
    }
  }
}

<template>
  <div v-if="visible" :class="{ legend: !mobile, legend_mobile: mobile }">
    <b>{{ $t('snow.label.surfaceHoarObserved') }}</b>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[0]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarObservedLarge') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[1]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarObservedSmall') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[2]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarNo') }}</b-col>
    </b-row>
    <b>{{ $t('snow.label.surfaceHoarSimulated') }}</b>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[3]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarSimulatedLarge') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[4]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarSimulatedSmall') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div class="symbol" v-html="symbols[5]"></div></b-col>
      <b-col>{{ $t('snow.label.surfaceHoarNo') }}</b-col>
    </b-row>
  </div>
</template>

<script>
import { SurfaceHoarStyle } from '../styles/snowStyling/SurfaceHoarStyle.js'
import emitter from '@/components/EventHub'

export default {
  name: 'SurfaceHoarLegend',
  data() {
    return {
      symbols: {},
      style: null,
      visible: false,
      mobile: false,
    }
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      // Legend neu initial auch auf Desktop geschlossen / SLPPWB-804 Legende per Default geschlossen
      this.visible = false
    } else {
      this.mobile = true
    }
  },
  mounted() {
    this.style = new SurfaceHoarStyle()
    this.symbols = this.style.getLegendSymbols()
    emitter.on('showLegend', (show) => {
      show ? (this.visible = true) : (this.visible = false)
    })
  },
  beforeUnmount() {
    emitter.off('showLegend')
  },
}
</script>

<style scoped>
.symbol {
  height: 30px;
}

.legend_mobile {
  opacity: 1;
  border: 1px solid #929292;
  border-radius: 4px;
  position: absolute;
  top: 6.9em;
  right: 0.5em;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  padding: 5px;
  padding-bottom: 0;
  display: inline-block;
  width: 9em;
}
</style>

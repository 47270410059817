import moment from 'moment'
import { v1 as uuidV1 } from 'uuid'
import {
  ASSESSMENT_DECISION,
  ASSESSMENT_SECTOR,
  ASSESSMENT_SITUATION,
  ATTACHMENT,
  AVALANCHE,
  DANGER,
  DANGERSIGN,
  SNOW,
  STUDYPLOTOBSERVATION,
  STUDYPLOTOBSERVATION_DANGER,
  STUDYPLOTOBSERVATION_MORNING,
} from '@/scripts/const.js'
import { deepClone } from '@/scripts/common'

const RANGE_OLD_MESSAGES = 30
let messagesToMenuItem = []

export const listMixin = {
  data() {
    return {
      loaded: false,
      rangeOldMessages: RANGE_OLD_MESSAGES,
      observationsAndAvalanches: [],
      obsDates: [],
      visibleState: [],
    }
  },

  methods: {
    // eslint-disable-next-line complexity
    getMessagesToMenuItem(
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      messagesToMenuItem = []
      const obsAvals = this.observationsAndAvalanches

      if (this.loaded && (!obsAvals || obsAvals.length <= 0)) {
        return messagesToMenuItem
      }

      switch (itemType) {
        case SNOW:
        case DANGERSIGN:
        case ATTACHMENT:
          messagesToMenuItem = this.getNormalObservations(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        case AVALANCHE:
          messagesToMenuItem = this.getAvalanches(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        case DANGER:
        case STUDYPLOTOBSERVATION_DANGER:
          // ACHTUNG: Danger kann eine normale Observation, oder ein Studyplot sein
          messagesToMenuItem = this.getDanger(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        case STUDYPLOTOBSERVATION:
          messagesToMenuItem = this.getStudyplotObservations(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        case STUDYPLOTOBSERVATION_MORNING:
          messagesToMenuItem = this.getMeasurementObservations(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        case ASSESSMENT_SITUATION:
        case ASSESSMENT_SECTOR:
        case ASSESSMENT_DECISION:
          messagesToMenuItem = this.getAssessmentObservations(
            obsAvals,
            tabElement,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
          break
        default:
          break
      }

      return messagesToMenuItem
    },

    isSameTypeAndDate(obsAval, itemType, messageDate, isCreateTimeCompare) {
      if (isCreateTimeCompare) {
        return (
          obsAval.type === itemType &&
          moment(obsAval.cTimestamp).isSame(messageDate, 'day')
        )
      }
      return (
        obsAval.type === itemType &&
        moment(obsAval.date).isSame(messageDate, 'day')
      )
    },

    getMessagedateString(obsAval, obsAvalDate) {
      obsAval.dateTimeString = moment(obsAvalDate).format('DD.MM/HH:mm')
      return obsAval
    },

    getAvalDate(aval) {
      return aval.triggerTime
        ? moment
            .utc(aval.triggerDate + 'T' + aval.triggerTime, 'YYYY-MM-DDTHH:mm')
            .local()
        : moment(aval.triggerDate)
    },

    getFormattedAvalTriggerDate(aval) {
      return aval.triggerTime
        ? moment
            .utc(aval.triggerDate + 'T' + aval.triggerTime, 'YYYY-MM-DDTHH:mm')
            .local()
            .format('YYYY-MM-DD')
        : moment(aval.triggerDate).format('YYYY-MM-DD')
    },

    getNormalObservations(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      obsAvals.forEach((obsAval) => {
        if (
          this.isSameTypeAndDate(
            obsAval,
            itemType,
            messageDate,
            isCreateTimeCompare
          ) &&
          !obsAval.studyplotId &&
          obsAval.tags &&
          (obsAval.tags.observerGroup === tabElement.groupId ||
            !obsAval.tags.observerGroup)
        ) {
          messages.push(this.getMessagedateString(obsAval, obsAval.cTimestamp))
        }
      })
      return messages
    },

    getAvalanches(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      // eslint-disable-next-line complexity
      obsAvals.forEach((obsAval) => {
        if (obsAval[itemType]) {
          const avalLocalCreationDate = moment.utc(obsAval.date).local()
          const avalLocalTriggerDate = this.getAvalDate(obsAval[itemType])
          const dateToCompare = isCreateTimeCompare
            ? avalLocalCreationDate
            : avalLocalTriggerDate
          if (
            dateToCompare.isSame(messageDate, 'day') &&
            obsAval[itemType].tags &&
            (obsAval[itemType].tags.observerGroup === tabElement.groupId ||
              !obsAval[itemType].tags.observerGroup) &&
            !obsAval.studyplotId
          ) {
            messages.push(
              this.getMessagedateString(obsAval, avalLocalCreationDate)
            )
          }
        }
      })
      return messages
    },

    getDanger(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      // eslint-disable-next-line complexity
      obsAvals.forEach((obsAval) => {
        if (
          this.isSameTypeAndDate(
            obsAval,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
        ) {
          if (
            tabElement.isStudyplot &&
            obsAval.studyplotId === tabElement.studyplotId
          ) {
            messages.push(
              this.getMessagedateString(obsAval, obsAval.cTimestamp)
            )
          } else if (
            tabElement.isObserverGroup &&
            obsAval.tags &&
            obsAval.tags.observerGroup === tabElement.groupId &&
            !obsAval.studyplotId
          ) {
            messages.push(
              this.getMessagedateString(obsAval, obsAval.cTimestamp)
            )
          } else if (!tabElement.isObserverGroup && !tabElement.isStudyplot) {
            messages.push(
              this.getMessagedateString(obsAval, obsAval.cTimestamp)
            )
          }
        }
      })
      return messages
    },
    getStudyplotObservations(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      obsAvals.forEach((obsAval) => {
        if (
          this.isSameTypeAndDate(
            obsAval,
            itemType,
            messageDate,
            isCreateTimeCompare
          ) &&
          obsAval.studyplotId === tabElement.studyplotId
        ) {
          messages.push(this.getMessagedateString(obsAval, obsAval.cTimestamp))
        }
      })
      return messages
    },

    getMeasurementObservations(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      obsAvals.forEach((obsAval) => {
        if (
          this.isSameTypeAndDate(
            obsAval,
            itemType,
            messageDate,
            isCreateTimeCompare
          ) &&
          obsAval.measurement &&
          obsAval.measurement.studyplot === tabElement.studyplotId
        ) {
          messages.push(this.getMessagedateString(obsAval, obsAval.cTimestamp))
        }
      })
      return messages
    },

    getAssessmentObservations(
      obsAvals,
      tabElement,
      itemType,
      messageDate,
      isCreateTimeCompare
    ) {
      const messages = []
      obsAvals.forEach((obsAval) => {
        let avalServiceId
        if (
          this.isSameTypeAndDate(
            obsAval,
            itemType,
            messageDate,
            isCreateTimeCompare
          )
        ) {
          avalServiceId = this.getAvalServiceId(obsAval)
          if (avalServiceId === tabElement.ldId) {
            messages.push(
              this.getMessagedateString(obsAval, obsAval.cTimestamp)
            )
          }
        }
      })
      return messages
    },

    getAvalServiceId(assObs) {
      return assObs.hierarchy.avalServiceId
    },

    createObservationsAndAvalanchesAndAssessments() {
      let allOf = []
      allOf = allOf.concat(
        deepClone(this.$store.state.observation.observations)
      )
      // Avalanches hinzufügen
      const avalanches = deepClone(
        this.$store.state.avalanche.createDateAvalanches
      )
      if (avalanches && avalanches.length > 0) {
        avalanches.forEach((avalanche) => {
          allOf.push({
            id: avalanche.id,
            date: avalanche.creation,
            avalanche: avalanche,
          })
        })
      }
      // Measurements hinzufügen
      const measurements = deepClone(this.$store.state.studyplot.measurements)

      if (measurements && measurements.length > 0) {
        measurements.forEach((measurement) => {
          allOf.push({
            id: uuidV1(),
            type: measurement.type,
            date: measurement.date,
            cTimestamp: measurement.cTimestamp,
            uid: measurement.uid,
            management: measurement.management,
            measurement: measurement,
          })
        })
      }

      // re-sort by creation timestamp - TODO: quick solution w/ unsupportable performance until time for a better implementation found
      // allOf.sort((item1, item2) => ((item1.avalanche ? item1.avalanche.triggerDate : item1.date) > (item2.avalanche ? item2.avalanche.triggerDate : item2.date)) ? -1 : ((item1.avalanche ? item1.avalanche.triggerDate : item1.date) === (item2.avalanche ? item2.avalanche.triggerDate : item2.date) ? 0 : 1))
      // eslint-disable-next-line complexity
      allOf.sort((item1, item2) =>
        (item1.avalanche
          ? this.getFormattedAvalTriggerDate(item1.avalanche)
          : item1.date) >
        (item2.avalanche
          ? this.getFormattedAvalTriggerDate(item2.avalanche)
          : item2.date)
          ? -1
          : (item1.avalanche
              ? this.getFormattedAvalTriggerDate(item1.avalanche)
              : item1.date) ===
            (item2.avalanche
              ? this.getFormattedAvalTriggerDate(item2.avalanche)
              : item2.date)
          ? 0
          : 1
      )

      this.observationsAndAvalanches = allOf
    },
  },
}

import axios, { Canceler, CancelToken } from 'axios'

export interface WrappedAxiosCanceler {
  cancel: Canceler | null
}

export function getCancelToken(
  wrappedAxiosCanceler?: WrappedAxiosCanceler
): CancelToken {
  return new axios.CancelToken(function executor(c) {
    if (wrappedAxiosCanceler) {
      wrappedAxiosCanceler.cancel = c
    }
  })
}

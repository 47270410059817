import contactService from '@/services/contactService.js'
import { enums } from '@/components/I18N.js'
import { i18n } from '@/plugins/i18n'
import { KeycloakAuthentication } from '@/plugins/KeycloakAuthentication'
import { deepClone } from '@/scripts/common'

const CREATOR_SYSTEM_REPLY_PUBLIC = enums.creatorSystem[1]

// Helpers
// Einen allgemeinen User für Reply Public Meldungen erstellen wenn dies nötig ist
function getCommonUserdata(id, state, creatorSystem) {
  if (creatorSystem !== CREATOR_SYSTEM_REPLY_PUBLIC) {
    // Dann kommt der User aus dem store
    return undefined
  }
  return isAdmin(state)
    ? getCommonUserdataForAdmin(id, state)
    : getCommonUserdataForNormalUser()
}
// Einen allgemeinen User für NICHT Admins für Reply Public Meldungen
function getCommonUserdataForNormalUser() {
  return {
    // Muss nicht übersetzt werden, da für alle Sprachen gültig
    firstName: 'Reply',
    lastName: 'Public',
  }
}
// Einen allgemeinen User für Admins für anonyme Reply Public Meldungen
function getCommonUserdataForAdmin(id, state) {
  const replyUser = state.users.find((user) => user.id === id)
  if (!replyUser || !replyUser.isAnonymous) {
    // Für nicht anonyme User wird der User im store gefunden
    return undefined
  }
  // Muss nicht übersetzt werden, da nur für Admin
  const replyUserKlon = deepClone(replyUser)
  replyUserKlon.firstName = 'Anonymer'
  replyUserKlon.lastName = 'Reply Public Beobachter'

  return replyUserKlon
}
function isAdmin(state) {
  return state && state.user && state.user.isAdmin
}
// Einen allgemeinen unbekannten User zurückgeben
function getUnknownUser(creatorSystem) {
  let creatorSysText = ' (' + creatorSystem + ')'
  if (!creatorSystem) {
    creatorSysText = ''
  }
  return {
    firstName: i18n.global.t('observer.name'),
    lastName: i18n.global.t('observer.unknown') + creatorSysText,
  }
}
// Eingen allgemeinen nicht gefunden User zurückgeben
function getNotFoundUser() {
  return {
    firstName: i18n.global.t('observer.name'),
    lastName: i18n.global.t('observer.notFound'),
  }
}
/*
 * Stellt sicher, dass der zurückgegeben User nicht undefined im firstName und/oder lastName hat.
 * Ansonsten wird '' gesetzt.
 * Sind firstName und lastName leer, wird ein Unknown-Name mit dem Usrsprungssystem gesetzt.
 */
function getCheckedUser(user, creatorSystem) {
  if (user === null) {
    return null
  }

  if (!user.firstName) {
    user.firstName = ''
  }
  if (!user.lastName) {
    user.lastName = ''
  }
  if (user.lastName.length <= 0 && user.firstName.length <= 0) {
    const unknownUser = getUnknownUser(creatorSystem)
    user.firstName = unknownUser.firstName
    user.lastName = unknownUser.lastName
  }
  return user
}

// state
const state = {
  users: [],
  user: {},
  // auth: null
  personalRegion: {
    center: [783820, 187392],
    zoom: 20,
  },
  userLocale: 'de',
}

// getters
const getters = {
  getUserToId: (state) => (id, creatorSystem) => {
    const foundedUser = state.users.find((user) => user.id === id)
    if (foundedUser?.isAnonymous) {
      // Wenn es sich um einem anonymen ReplyPublic-User handelt dann den User über 'getUserToIdOrDummyUser'
      // ermitteln, damit ein Dummy-User zurückgegeben wird und nicht 'null null' als Name angezeigt wird-
      return getters['getUserToIdOrDummyUser'](state)(
        id,
        CREATOR_SYSTEM_REPLY_PUBLIC
      )
    } else if (foundedUser?.isNotFound) {
      return getters['getUserToIdOrDummyUser'](state)(id, creatorSystem)
    }
    return foundedUser
  },
  getUserToIdOrDummyUser: (state) => (id, creatorSystem) => {
    // Wenn der User nicht gefunden und Creator System nicht Reply Public wurden, dann einen unbekannten User zurückgeben
    const user = state.users.find((user) => user.id === id)
    if (user?.isNotFound && creatorSystem !== CREATOR_SYSTEM_REPLY_PUBLIC) {
      return getNotFoundUser()
    }

    // Für Public Reply (Je nach Admin/User/Anonym) einen allgemeinen User erzeugen und zurückgeben
    const commonUser = getCommonUserdata(id, state, creatorSystem)
    if (commonUser) {
      return commonUser
    }
    /*
     * Für
     * - nicht Public Reply Meldungen
     * - Reply Public-Meldungen welche nicht anonym und für Adminuser sind
     * den User aus dem Store holen
     */
    return user && !user.isNotFound
      ? getCheckedUser(user, creatorSystem)
      : getUnknownUser(creatorSystem)
  },
}

// actions
const actions = {
  loadContactById(context, userId) {
    return contactService
      .getContact(userId)
      .then((user) => {
        context.commit('ADD_USER', user.data)
        context.commit('SET_EXCEPTION', null, { root: true })
        console.log('User ' + user.data.lastName + ' loaded')
        return user
      })
      .catch(function (error) {
        console.error('Loading user failed: ' + error.request.response)
        if (error.response?.status === 404) {
          context.commit('ADD_USER', { id: userId, isNotFound: true })
        } else if (!(error.response?.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
  loadMissingContacts(context, contactIds) {
    // Schon vorhandene contactids nicht mehr abfragen und doppelte entfernen
    const uniqueContactIds = [...new Set(contactIds)]
    const newContactIds = uniqueContactIds.filter(
      (contactId) =>
        context.state.users.findIndex(
          (existUser) => existUser.id === contactId
        ) === -1
    )
    if (newContactIds.length <= 0) {
      return Promise.resolve(true)
    }
    return contactService
      .getContacts(newContactIds)
      .then((contacts) => {
        context.commit('ADD_USERS', contacts.data)
        context.commit('SET_EXCEPTION', null, { root: true })
      })
      .catch(function (error) {
        console.error('Loading user failed: ' + error.request.response)
        if (
          !(error.response && error.response.status === 401) &&
          !(error.response && error.response.status === 404)
        ) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

// mutations
const mutations = {
  SET_USER(state) {
    const keycloakInstance = KeycloakAuthentication.getKeycloakInstance()
    state.user = {
      id: keycloakInstance?.subject,
      isAdmin: keycloakInstance?.hasRealmRole('ADMIN') || false,
      clientRoles: {
        bulletinConfidential:
          keycloakInstance?.hasResourceRole(
            'confidential',
            'app_aval-bulletin'
          ) || false,
      },
    }
  },
  ADD_USERS(state, users) {
    state.users.push(...users)
  },
  ADD_USER(state, user) {
    state.users.push(user)
  },
  SET_USER_LOCALE(state, userLocale) {
    state.userLocale = userLocale
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div
    id="layerSelect"
    v-if="visible && $props.layerOptions"
    class="label"
    v-bind:class="{ layeropt: !mobile, layeropt_mobile: mobile }"
  >
    <span v-if="layerOptions.title"
      ><b>{{ layerOptions.title }}</b></span
    >
    <b-form-group
      v-for="entry in layerOptions.items"
      :key="entry.key"
      :label="entry.label"
      style="pointer-events: all"
      label-class="font-weight-bold"
      class="layer-group nofocus"
    >
      <!-- TODO: VUE-Bootstrap: '.native' entfernen, wenn VUE-Bootstrap(VUE2) entfernt wird-->
      <!-- eslint-disable-->
      <template v-for="(val, ind) in entry.options" :key="ind">
        <b-form-checkbox
          v-show="layerVisibility[entry.key][ind]"
          v-model="selectedLayers[entry.key][ind]"
          :value="val.value"
          :id="entry.key + val.value"
          :name="entry.key"
          @click.native="uncheck('selectedLayers', entry.key, ind, val)"
          stacked
          >{{ val.text }}</b-form-checkbox
        >
      </template>
      <!-- eslint-disable-->
    </b-form-group>
  </div>
</template>

<script>
import emitter from '@/components/EventHub'
import { deepClone } from '@/scripts/common'

export default {
  name: 'LayerAuxiliary',
  props: ['layerOptions'],
  watch: {
    layerOptions: {
      handler(newVal, oldVal) {
        // initialize w/ 1st selected, all visible
        if (oldVal === 'undefined' || oldVal === null) {
          const vm = this
          if (newVal && newVal.items) {
            newVal.items.forEach((item) => {
              if (item && item.options && item.options.length > 0) {
                vm.selectedLayers[item.key] = Array(item.options.length).fill(
                  ''
                )
                // SLPPWB-1039: Keine Default-Selektion der Lawinenbulletin-Gefahrenkarte hier.
                // Die Default-Selektion wird in Dangermap und Problemmap gemacht (eventHub.$on('update::filter', filter => {)...)
                // Wenn hier was anderes gesetzt wird, führt dies zu einem flackern.
                vm.layerVisibility[item.key] = Array(item.options.length).fill(
                  true
                )
              }
            })
            // XOR-state flags
            vm.previousState['selectedLayers'] = deepClone(vm.selectedLayers)
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      mobile: false,
      selectedLayers: {},
      previousState: {
        // keep previous state of selected layer
        selectedLayers: {},
      },
      layerVisibility: {},
    }
  },
  beforeUnmount() {
    emitter.off('showLayer')
    emitter.off('update::layerList')
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      this.visible = true
    } else {
      this.mobile = true
    }
  },
  mounted() {
    const vm = this
    emitter.on('showLayer', (show) => {
      show ? (vm.visible = true) : (vm.visible = false)
    })
    emitter.on('update::layerList', (listConfig) => {
      // NOTE: should only be called when user LWD
      // NOTE: if 'productVariantAll' -> 1st as default - but might be 2nd as well; if 'productVariantDry' -> 1. as default + hide 2nd; if 'productVariantWet' -> set 2. as default and hide 1
      const keyItem = vm.layerOptions.items.find((item) => {
        return item.key === listConfig.key
      })
      if (keyItem) {
        if (listConfig.selectionIndex !== null) {
          const itemValue = keyItem.options[listConfig.selectionIndex].value

          vm.selectedLayers[listConfig.key].fill('')
          vm.selectedLayers[listConfig.key][listConfig.selectionIndex] =
            itemValue
        } else {
          vm.selectedLayers[listConfig.key].fill('')
        }
        vm.selectedLayers = deepClone(vm.selectedLayers) // important for reactivity!
        // XOR-state flags
        vm.previousState['selectedLayers'] = deepClone(vm.selectedLayers)
        emitter.emit('update::layer', {
          productVariant: 'danger.bulletin',
          layers: vm.selectedLayers,
        })

        if (listConfig.visibilityIndex !== null) {
          // NOTE: if index, only that one, if all, show all
          vm.layerVisibility[listConfig.key].fill(false)
          vm.layerVisibility[listConfig.key][listConfig.visibilityIndex] = true
        } else {
          vm.layerVisibility[listConfig.key].fill(true)
        }
        vm.layerVisibility = deepClone(vm.layerVisibility) // important for reactivity!
      }
    })
  },
  methods: {
    updateLayer() {
      this.$nextTick(function () {
        emitter.emit('update::layer', {
          productVariant: 'danger.bulletin',
          layers: this.selectedLayers,
        })
      })
    },
    uncheck(member, key, index, checked) {
      if (
        this.previousState[member][key][index] === checked.value &&
        this[member][key][index] === checked.value
      ) {
        this[member][key][index] = ''
      } else {
        for (let ind = 0; ind < this[member][key].length; ind++) {
          if (ind === index) {
            this[member][key][ind] = checked.value
          } else {
            this[member][key][ind] = ''
          }
        }
      }
      this[member] = deepClone(this[member]) // important for reactivity!
      this.previousState[member] = deepClone(this[member])
      this.updateLayer(key)
    },
  },
}
</script>

<style scoped>
.label {
  font-size: 12px;
}

.layeropt .layer-group {
  margin-bottom: 0.5rem;
}
</style>

<style>
.nofocus > div:focus {
  outline: none;
}

.layeropt {
  bottom: 4em;
  border: 1px solid #929292;
  border-radius: 4px;
  width: 14em;
  opacity: 1;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  display: block;
  position: absolute;
  padding: 10px;
}

.layeropt_mobile {
  opacity: 1;
  border: 1px solid #929292;
  border-radius: 4px;
  position: absolute;
  top: 15em;
  right: 0.6em;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  padding: 5px 5px 0;
  display: inline-block;
  max-width: 14em;
}
</style>

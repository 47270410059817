import observationService from '@/services/observationService.js'
import util from '@/scripts/util.js'
import moment from 'moment'
import { observationProjection } from '@/model/geoJsonModels'

// Helpers

// eslint-disable-next-line complexity
function getTypeForDbCall(snowType) {
  if (snowType) {
    switch (snowType) {
      case 'hn24h':
        return ['SNOW']
      case 'snowRainAltitude':
      case 'snowline':
      case 'snowSurfaceCondition':
        return ['SNOW', 'STUDYPLOTOBSERVATION']
      default:
        return null
    }
  }
  return null
}

// state
const state = {
  snowFeatureCollection: [], // avalanches of current date for map
}

// getters
const getters = {}

// actions
const actions = {
  loadSnowObservationsByDate(context, options) {
    const startDate = moment(options.date).startOf('day')
    const duration = 1440
    return this.dispatch('snow/loadSnowObservationsByDateTimeInterval', {
      param: options.param,
      startDate,
      duration,
      cancel: options.cancel,
    })
  },

  loadSnowObservationsByDateTimeInterval(context, options) {
    const type = getTypeForDbCall(options.param)
    const lower = options.startDate
    const upper = moment(options.startDate)
      .add(options.duration, 'minutes')
      .subtract(1, 'seconds')
    const crs = {
      type: 'name',
      properties: { name: observationProjection },
    }

    return observationService
      .getObservationsByObservationDateTimeRange(
        lower,
        upper,
        type,
        options.cancel
      )
      .then((result) => {
        if (!result) {
          return
        }
        // Filter snow observations
        let snowObservations = result.data.data.filter((obs) => obs.snow)
        let filterParam = options.param
        if (options.param === 'hn24h') {
          filterParam = 'newSnowEstimated'
        }

        // eslint-disable-next-line complexity
        snowObservations = snowObservations.filter(function (obs) {
          switch (filterParam) {
            case 'newSnowEstimated':
              return (
                obs.snow.newSnowEstimated &&
                obs.snow.newSnowEstimated.some(function (i) {
                  return i !== null
                })
              )
            case 'snowline':
              return (
                obs.snow.snowline &&
                (obs.snow.snowline.north || obs.snow.snowline.south)
              )
            case 'snowSurfaceCondition':
              return (
                obs.snow.snowSurfaceCondition &&
                (obs.snow.snowSurfaceCondition.snowSurfaceConditionNorth ||
                  obs.snow.snowSurfaceCondition.snowSurfaceConditionSouth ||
                  obs.snow.snowSurfaceCondition.snowSurfaceConditionFlat)
              )
            default:
              return obs.snow[filterParam]
          }
        })
        // Create features
        const features = util.mapObservationsToFeatures(
          'snow',
          snowObservations
        )
        // Build feature properties
        features.forEach((f) => {
          if (f.properties.snow.newSnowEstimated) {
            f.properties.hn24h = Object.values(
              f.properties.snow.newSnowEstimated
            )
              .map((x) => (x === 'X' ? null : x))
              .reduce((max, curr) => {
                return max > curr ? max : curr || curr === 0 ? curr : max
              })
          }
          if (f.properties.snow[options.param]) {
            f.properties[options.param] = f.properties.snow[options.param]
          }
        })

        const featureCollection = { type: 'FeatureCollection', crs, features }
        context.commit('SET_SNOW_FEATURE_COLLECTION', featureCollection)
        context.commit('SET_EXCEPTION', null, { root: true })
        console.log('snow observations loaded')
      })
      .catch(function (error) {
        const featureCollection = {
          type: 'FeatureCollection',
          crs,
          features: [],
        }
        context.commit('SET_SNOW_FEATURE_COLLECTION', featureCollection)
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

// mutations
const mutations = {
  SET_SNOW_FEATURE_COLLECTION(state, snowFeatureCollection) {
    state.snowFeatureCollection = snowFeatureCollection
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

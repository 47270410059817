'use strict'

import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import { SVG } from '../SVG'
import { AbstractStyle } from '../AbstractStyle'
import { isUndefinedOrNull } from 'bootstrap-vue/esm/utils/inspect'

const surfaceHoarStyleCache = new Map()

export class SurfaceHoarStyle extends AbstractStyle {
  // eslint-disable-next-line complexity
  styleFunction(feature) {
    let type, level
    const surfaceObject = feature.get('snowSurfaceCondition')
    const hoarSize = feature.get('hoar_size')
    if (!surfaceObject && !hoarSize === null) return
    if (surfaceObject) {
      const manualValue = surfaceObject.valueToShow
      level = surfaceObject.index
      if (manualValue === 'SURFACE_HOAR_LARGE') {
        type = 'SURFACE_HOAR_LARGE'
      } else if (manualValue === 'SURFACE_HOAR_SMALL') {
        type = 'SURFACE_HOAR_SMALL'
      } else {
        type = 'NO_MANUAL_HOAR'
      }
    } else {
      const value = feature.get('hoar_size')
      if (!isUndefinedOrNull(value)) {
        if (value >= 5) {
          type = 'MEASURED_HOAR_LARGE'
        } else if (value >= 2) {
          type = 'MEASURED_HOAR_SMALL'
        } else {
          type = 'NO_MEASUREMENT_HOAR'
        }
      } else {
        return
      }
    }

    const cacheKey = `${type}`
    let surfaceHoarStyle

    if (surfaceHoarStyleCache.has(cacheKey)) {
      surfaceHoarStyle = surfaceHoarStyleCache.get(cacheKey)
    } else {
      surfaceHoarStyle = new Style({
        image: new Icon({
          src: getSymbol(type).src,
        }),
      })
      surfaceHoarStyleCache.set(cacheKey, surfaceHoarStyle)
    }

    if (level) {
      surfaceHoarStyle.setZIndex(level)
    }
    return [surfaceHoarStyle]
  }
  getLegendSymbols() {
    const symbols = []
    Object.keys(SURFACE_SVG).forEach((key) => {
      symbols.push(getSymbol(key, '-15 -15 50 50').outerHTML)
    })
    return symbols
  }
  mouseOverFunction(feature) {
    const observed = feature.get('snow')
    if (observed) {
      let htmlString = super.getMouseOverTitel(feature)
      if (feature.get('snowSurfaceCondition')) {
        htmlString = htmlString.concat(
          '<div>' + feature.get('snowSurfaceCondition').aspect + '</div>'
        )
      }
      return htmlString
    } else {
      const simulated = feature.get('id')
      if (simulated) {
        return feature.get('network') + ' ' + feature.get('id')
      } else {
        return null
      }
    }
  }
}

const getSymbol = function (value, viewbox) {
  const symbol = '<g>' + SURFACE_SVG[value] + '</g>'
  const svg = new SVG(viewbox)
  return svg.createImage(symbol)
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------

const SURFACE_SVG = {
  SURFACE_HOAR_LARGE:
    '<polygon stroke="red" fill="red" points="-12,-11 12,-11 0,11"></polygon>',
  SURFACE_HOAR_SMALL:
    '<polygon stroke="red" fill="red" points="-6,-5 6,-5 0,5"></polygon>',
  NO_MANUAL_HOAR:
    '<polygon stroke="grey" fill="grey" points="-5,-5 5,-5 0,5"></polygon>',
  MEASURED_HOAR_LARGE:
    '<polygon stroke="red" fill="white" stroke-width="2" points="-12,-11 12,-11 0,11"></polygon>',
  MEASURED_HOAR_SMALL:
    '<polygon stroke="red" fill="white" stroke-width="2" points="-6,-5 6,-5 0,5"></polygon>',
  NO_MEASUREMENT_HOAR:
    '<polygon stroke="grey" fill="white" stroke-width="2" points="-5,-5 5,-5 0,5"></polygon>',
}

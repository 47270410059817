import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import RegularShape from 'ol/style/RegularShape'

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------
// Map symbol meta:
export const MAP_RADIUS_MIN = 10 // NOTE: radius independent of resolution
export const MAP_RADIUS_MAX = 20

// IMPORTANT: simplified colors for wb only (YT#SLPPWB-826):
export const MAP_DANGER_LEVEL_COLORS = {
  '1': 'rgba(204, 255, 102, 0.4)',
  '2': 'rgba(255, 255, 0, 0.4)',
  '3-': 'rgba(255,153,0,0.4)',
  '3': 'rgba(255,153,0,0.4)',
  '3+': 'rgba(255,153,0,0.4)',
  '4': 'rgba(255,0,0,0.4)',
  '5': 'rgba(100,0,0,0.4)',
  UNKNOWN: 'rgba(255,255,255,0.4)',
}
export const MAP_DANGER_PROBLEM_COLORS = {
  DRY: 'rgba(0,0,255,0.4)',
  WET: 'rgba(255,0,0,0.4)',
  FULL_DEPTH: 'rgba(153,76,0,0.4)',
  UNKNOWN: 'rgba(153,153,153,0.4)',
}

// NOTE: same style for both legend and map
export const STYLE_ELEMENTS: Record<string, string> = {
  DRY: 'fill: none; stroke: #0000FF; fill-opacity: 0.4; stroke-width: 3px',
  WET: 'fill: none; stroke: #FF0000; fill-opacity: 0.4; stroke-width: 3px',
  FULL_DEPTH:
    'fill: none; stroke: #994C00; fill-opacity: 0.4; stroke-width: 3px',
  /* color codes from bulletin editor, from LWD, for visualisation: */
  '1': 'fill: #CCFF66; stroke: #CCFF66; fill-opacity: 0.4; stroke-width: 2px',
  '2': 'fill: #FFFF00; stroke: #FFFF00; fill-opacity: 0.4; stroke-width: 2px',
  /* IMPORTANT: simplified colors for wb only (YT#SLPPWB-826):*/
  '3-': 'fill: #FF9900; stroke: #FF9900; fill-opacity: 0.4; stroke-width: 2px',
  '3': 'fill: #FF9900; stroke: #FF9900; fill-opacity: 0.4; stroke-width: 2px',
  '3+': 'fill: #FF9900; stroke: #FF9900; fill-opacity: 0.4; stroke-width: 2px',
  '4': 'fill: #FF0000; stroke: #FF0000; fill-opacity: 0.4; stroke-width: 2px',
  '5': 'fill: #640000; stroke: #640000; fill-opacity: 0.4; stroke-width: 2px',
  UNKNOWN: 'fill:#FFFFFF; stroke:#999999; fill-opacity: 0.4; stroke-width:2px',
  UNKNOWNP: 'fill:none; stroke:#999999; fill-opacity: 0.4; stroke-width:2px',
}

const SUBDIVISION_STYLE_COLOR = '#000000'
const SUBDIVISION_STYLE_FILL = new Fill({
  color: SUBDIVISION_STYLE_COLOR,
})
const SUBDIVISION_STYLE_STROKE = new Stroke({
  color: SUBDIVISION_STYLE_COLOR,
  width: 1.6,
})
export const PLUS_STYLE = new Style({
  image: new RegularShape({
    fill: SUBDIVISION_STYLE_FILL,
    stroke: SUBDIVISION_STYLE_STROKE,
    points: 4,
    radius: 5,
    radius2: 0,
    angle: 0,
  }),
})
export const MINUS_STYLE = new Style({
  image: new RegularShape({
    fill: SUBDIVISION_STYLE_FILL,
    stroke: SUBDIVISION_STYLE_STROKE,
    points: 2,
    radius: 5,
    angle: Math.PI / 2,
  }),
})
export const NEUTRAL_STYLE = [
  new Style({
    image: new RegularShape({
      fill: SUBDIVISION_STYLE_FILL,
      stroke: SUBDIVISION_STYLE_STROKE,
      points: 2,
      radius: 5,
      displacement: [0, -2],
      angle: Math.PI / 2,
    }),
  }),
  new Style({
    image: new RegularShape({
      fill: SUBDIVISION_STYLE_FILL,
      stroke: SUBDIVISION_STYLE_STROKE,
      points: 2,
      radius: 5,
      displacement: [0, 2],
      angle: Math.PI / 2,
    }),
  }),
]

// NOTE (20190919/csz): switched over from hex to rgba format due to "non-conformity" in edge browser if in combination w/ opacity
const MAP_TRIANGLE_COLOR = 'rgba(0,0,0,0.4)'

export const TRIANGLE_STYLE = new RegularShape({
  fill: new Fill({ color: MAP_TRIANGLE_COLOR }),
  points: 3,
  radius: 3,
  angle: 0,
})

'use strict'

import TableEntryBuilder from '@/model/report/TableEntryBuilder.js'
import ImageEntryBuilder from '@/model/report/ImageEntryBuilder.js'
import store from '@/store/index'

export default class ReportElement {
  constructor(reportConfigFuncs, reportElement) {
    this.headerLabel = reportConfigFuncs.headerLabel(reportElement.cTimestamp)
    this.headerText = this.addAvalServiceName(
      reportConfigFuncs.headerText(reportElement.uid, reportElement),
      reportElement
    )
    this.coordinates = []
    this.startPoint = []
    this.depositPoint = []
    this.tables = []
    this.images = []
  }

  /* 
    Erzeugt aus den übergebenen Beobachtungen die einzelnen Reportelemente.
    Für den Titel der einzelnen Beobachtungen muss eine Function
    übergeben werden.
   */
  static buildReportElements(reportEles, reportConfigFuncs) {
    const elements = []
    if (reportEles && reportEles.length > 0) {
      let reportEle
      reportEles.forEach((ele) => {
        reportEle = new ReportElement(reportConfigFuncs, ele)
        reportEle.setCoordinates(ele)
        reportEle.tables = TableEntryBuilder.buildTableEntries(
          ele,
          reportConfigFuncs
        )
        reportEle.images = ImageEntryBuilder.buildImageEntries(ele)
        elements.push(reportEle)
      })
    }
    return elements
  }

  setCoordinates(obsEle) {
    if (obsEle.location) {
      this.coordinates = obsEle.location.coordinates
      this.startPoint = obsEle.location.startPoint
      this.depositPoint = obsEle.location.depositPoint
    } else {
      this.coordinates = undefined
      this.startPoint = undefined
      this.depositPoint = undefined
    }
  }

  addAvalServiceName(headerText, reportElement) {
    if (reportElement?.hierarchy?.avalServiceId) {
      const avalService = store.getters['profile/getAssmAvalServiceToId'](
        reportElement.hierarchy.avalServiceId
      )
      if (avalService) {
        return `${headerText} (${avalService.name})`
      }
    }
    return headerText
  }

  getModelAsObj() {
    const reportEleObj = {
      header: {
        label: this.headerLabel,
        text: this.headerText,
      },
      coordinates: this.coordinates,
      startPoint: this.startPoint,
      depositPoint: this.depositPoint,
      tables: [],
      images: [],
    }
    this.tables.forEach((ele) => {
      reportEleObj.tables.push(ele.getModelAsObj())
    })
    reportEleObj.images = this.images
    return reportEleObj
  }
}

<template>
  <div>
    <FilterMultiple :filterOptions="filterOptions"></FilterMultiple>
    <AvalancheLegend></AvalancheLegend>
    <CommonLayersPopup></CommonLayersPopup>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal
      :aval="obs.avalanche"
      :obsIds="obsIds"
      detId="detailModal"
      :aggregate="true"
    />
  </div>
</template>

<script>
import { AvalancheStyleMap } from '../styles/avalancheStyling/AvalancheStyleMap.js'
import avalancheUtil from '../../scripts/avalancheInputMap/avalancheUtil.js'
import moment from 'moment'
import AvalancheLegend from './AvalancheLegend'
import DetailModal from '../observation/DetailModal'
import FilterMultiple from '../FilterMultiple'
import { i18nMixin } from '../I18N'
import { i18n } from '@/plugins/i18n'
import { Canceler } from '@/scripts/requestUtil.js'
import CommonLayersPopup from '../CommonLayersPopup'
import ActivityIndexStyleFactory from '@/components/styles/avalancheStyling/ActivityIndexStyleFactory'
import GeneralStyleFactory from '@/components/styles/avalancheStyling/GeneralStyleFactory'
import emitter from '@/components/EventHub'
import { deepClone } from '@/scripts/common'

const Cancel = Canceler.getCancelObj()

export default {
  data() {
    return {
      style: null,
      obs: { avalanche: null },
      obsIds: [],
      selected: 'avalanche.today',
      isLoading: false,
      lastMoment: undefined,
      lastDatePreset: undefined,
      initialFilterValue: true,
      filter: {},
      isMounted: false,
    }
  },

  created() {
    // Avalanche Symbol-Factories bereits initialisieren
    ActivityIndexStyleFactory.getInstance()
    GeneralStyleFactory.getInstance()
  },
  mounted() {
    // eslint-disable-next-line complexity
    emitter.on('update::filter', (filter) => {
      if (
        filter &&
        filter.productVariant &&
        filter.productVariant.startsWith('avalanche.')
      ) {
        this.selected = filter.productVariant
      }
      if (filter && filter.filters) {
        const avalancheFilters = {}
        for (const filterKey in filter.filters) {
          if (
            Object.prototype.hasOwnProperty.call(filter.filters, filterKey) &&
            filterKey.startsWith('avalanche.')
          ) {
            avalancheFilters[filterKey] = filter.filters[filterKey]
          }
          this.filter.filters = avalancheFilters
        }
      }
      this.refresh()
    })
    emitter.on('repos::map', (flag) => {
      this.isLoading = flag && flag === true
    })
    this.isMounted = true
  },

  beforeUnmount() {
    emitter.emit('layer', null)
    emitter.off('update::filter')
    emitter.off('repos::map')

    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },

  props: ['moment', 'calendar'],
  mixins: [i18nMixin],
  components: {
    CommonLayersPopup,
    DetailModal,
    AvalancheLegend,
    FilterMultiple,
  },

  watch: {
    moment: function () {
      // Mehrfachaufrufe beim Start verhindern. Beim initialen Aufruf genügt es, wenn Refresh-Request durch update::filter gemacht wird.
      // Future task would be to clean up and remove completely initialFilterValue and lastMoment.
      if (this.initialFilterValue) {
        this.initialFilterValue = false
        if (this.$store.state.calendar.preset !== 'calendar') {
          this.lastMoment = this.moment
          this.lastDatePreset = this.$store.state.calendar.preset
        }
      }
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },

  computed: {
    calendarPresetInterval() {
      return this.calendar.getPresetInterval()
    },
    filterOptions() {
      // Siehe fc3c39e
      // Die filter options dürfen nicht im data gesetzt werden, weil FilterMultiple bei der Initialisierung ein Wechsel des Properties erwartet !!
      if (!this.isMounted) return null
      return {
        items: [
          {
            key: 'avalanche.avalancheType',
            label: i18n.global.t('avalanche.label.avalancheType'),
            options: this.options('avalanche', 'avalancheType', true),
          },
          {
            key: 'avalanche.triggerType',
            label: i18n.global.t('avalanche.label.triggerType'),
            options: this.options('avalanche', 'triggerType', true),
          },
        ],
      }
    },
  },

  methods: {
    findAndShowAvalanche(avalancheId, avalanches, avalancheReleaseFeatures) {
      const avalObj = avalanches.find((item) => {
        return item.id === avalancheId
      })
      if (avalObj) {
        const foundAval = avalObj
        this.obs = {
          id: foundAval.id,
          avalanche: deepClone(foundAval),
          date: foundAval.creation,
        }
        this.obsIds = []
        this.$bvModal.show('detailModal')
      } else {
        const avalReleaseObj = avalancheReleaseFeatures.find((item) => {
          return item.get('id') === avalancheId
        })
        if (avalReleaseObj) {
          const avalancheRelease = avalReleaseObj.get('avalancheRelease')
          if (avalancheRelease) {
            this.obsIds = []
            this.obsIds.push(avalancheRelease.id)
            this.obs = { avalanche: null }
            this.$bvModal.show('detailModal')
          }
        }
      }
    },

    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },

    refresh() {
      console.debug('Cancel requests on load')
      this.cancelRequests()

      // initialFilterValue should be run at the first display of the map.
      // Future task would be to clean up and remove completely initialFilterValue and lastMoment.
      if (this.initialFilterValue) {
        this.initialFilterValue = false
        if (this.$store.state.calendar.preset !== 'calendar') {
          this.lastMoment = this.moment
          this.lastDatePreset = this.$store.state.calendar.preset
        }
      }

      this.isLoading = true
      let avalancheInterval
      let avalancheCreateInterval
      let releaseInterval
      let endDate
      this.style = new AvalancheStyleMap()
      this.styleFunction = this.style.styleFunction
      const startDateTime = moment(this.calendarPresetInterval.split('P')[0])
        .seconds(0)
        .milliseconds(0)
      const startObsDateTime = moment(this.calendarPresetInterval.split('P')[0])
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0) // trigger date time has always to be from beginning of day!!!
      const endObsDateTime = moment(startDateTime)
        .add(moment.duration('P' + this.calendarPresetInterval.split('P')[1]))
        .subtract(1, 'seconds')
      if (endObsDateTime.hours() === 7) {
        endObsDateTime
          .subtract(1, 'day')
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
      }
      const now = Object.assign({}, this.$store.state.calendar.moment)
      let plusOneDay = endObsDateTime.clone()
      plusOneDay = plusOneDay.add(1, 'day')
      if (this.selected === 'avalanche.today') {
        // change to utc in avalanche service
        avalancheInterval = [startObsDateTime, endObsDateTime]
        avalancheCreateInterval = [startDateTime, moment().add(10, 'minutes')]
        releaseInterval = [startDateTime, plusOneDay]
        endDate = moment(now)
      } else {
        // change to utc in avalanche service
        const dayBeforeStart = startDateTime.clone().subtract(1, 'day')
        const dayBeforeStartObs = startObsDateTime.clone().subtract(1, 'day')
        const dayBeforeEnd = endObsDateTime.clone().subtract(1, 'day')
        avalancheInterval = [dayBeforeStartObs, dayBeforeEnd]
        avalancheCreateInterval = [dayBeforeStart, moment().add(10, 'minutes')]
        releaseInterval = [dayBeforeStart, endObsDateTime]
        endDate = moment(now)
          .subtract(1, 'day')
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
      }
      const self = this
      const promises = []

      const cancelObj = { cancel: null }
      promises.push(
        this.$store.dispatch(
          'avalanche/loadAvalanchesByTriggerDateAndCreateDateTimeInterval',
          {
            interval: avalancheInterval,
            createInterval: avalancheCreateInterval,
            cancel: cancelObj,
          }
        )
      )
      Cancel.addCancel(cancelObj.cancel)

      const cancelObj2 = { cancel: null }
      promises.push(
        this.$store.dispatch('dangersign/loadAvalancheReleasesByDateInterval', {
          interval: releaseInterval,
          endDate: endDate,
          cancel: cancelObj2,
        })
      )

      Promise.all(promises).then(() => {
        const avalanches = self.$store.state.avalanche.triggerDateAvalanches
        const features = avalancheUtil.getAvalancheFeatures(
          this.applyFilter(avalanches, this.filter)
        )
        const avalancheReleases = self.$store.state.dangersign.avalancheReleases
        if (!avalancheReleases) {
          return
        }
        const avalancheReleaseFeatures =
          avalancheUtil.getAvalancheReleaseFeatures(avalancheReleases)
        features.features = features.features.concat(
          avalancheReleaseFeatures.features
        )
        const avalancheClickHandler = function (feature) {
          const features = feature.get('features')
          if (features && features.length < 2) {
            const avalanche = features[0].get('avalanche')
            if (avalanche) {
              const avalObj = avalanches.find((item) => {
                return item.id === avalanche.id
              })
              if (avalObj) {
                // var foundAval = self.$store.state.avalanche.triggerDateAvalanches[index] // DANGER (20190812/csz): why is this empty? and observer...
                const foundAval = avalObj
                self.obs = {
                  id: foundAval.id,
                  avalanche: deepClone(foundAval),
                  date: foundAval.creation,
                }
                self.obsIds = []
                self.$bvModal.show('detailModal')
              }
            } else {
              const avalancheRelease = features[0].get('avalancheRelease')
              if (avalancheRelease) {
                self.obsIds = []
                self.obsIds.push(avalancheRelease.id)
                self.obs = { avalanche: null }
                self.$bvModal.show('detailModal')
              }
            }
          }
        }
        // no changeResolutionHandler needed - clustering starts automatically
        emitter.emit('layer', {
          name: 'avalanche',
          features: features,
          styleFunction: this.styleFunction,
          onClick: avalancheClickHandler,
          style: this.style,
          supportFilter: true,
        })
        emitter.emit('mouseOver', this.style.mouseOverFunction)
        if (self.$route.query.openObs) {
          self.findAndShowAvalanche(
            self.$route.query.openObs,
            avalanches,
            avalancheReleaseFeatures.features
          )
        }
        this.isLoading = false
      })
    },

    // eslint-disable-next-line complexity
    applyFilter(avalanches, filter) {
      let filteredAvalanches = avalanches
      if (filter && filter.filters && filteredAvalanches) {
        // TODO: + globale filter (letztere nicht in spec.)
        for (const filterKey in filter.filters) {
          switch (filterKey) {
            case 'avalanche.avalancheType':
              filteredAvalanches = filteredAvalanches.filter(
                (avalanche) =>
                  avalanche.avalancheType === null ||
                  filter.filters[filterKey].includes(avalanche.avalancheType)
              )
              break
            case 'avalanche.triggerType':
              filteredAvalanches = filteredAvalanches.filter(
                (avalanche) =>
                  avalanche.triggerType === null ||
                  filter.filters[filterKey].includes(avalanche.triggerType)
              )
              break
          }
        }
      }
      return filteredAvalanches
    },

    cancelRequests() {
      Cancel.cancelAll()
    },
  },
}
</script>

<style scoped>
img {
  width: 600px;
}

.mapPanel :deep(.ol-legend) {
  top: 0.5em;
  right: 0.5em;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

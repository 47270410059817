<template>
  <b-container pane fluid smooth class="ticker-container">
    <b-row>
      <b-col class="label">
        <font-awesome-icon icon="fa-solid fa-calendar-alt" fixed-width />
        <span class="d-none d-sm-inline">
          &emsp;{{ $t('ui.calendar.title') }}
        </span>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="2" class="date-time-selectors">
        <b-form-radio
          plain
          v-model="selector"
          name="timestamp-radios"
          value="time"
          @change="setSelector"
        >
          <div class="vp-time-select">
            <primevue-calendar
              id="timepicker"
              :time-only="true"
              v-model="jsTime"
              name="datepicker"
              :show-seconds="false"
            />
            <span class="label">bis jetzt</span>
          </div>
        </b-form-radio>
        <b-form-radio
          plain
          v-model="selector"
          name="timestamp-radios"
          value="date"
          @change="setSelector"
        >
          <calendar-component
            class="ticker-calendar-picker w-100"
            v-model="dateString"
            name="datepicker"
            @update:model-value="setDate"
          />
        </b-form-radio>
      </b-col>
      <b-col class="reload-button-container">
        <b-button size="sm" variant="success" @click="refresh">Reload</b-button>
      </b-col>
    </b-row>
    <br />
    <b-tabs content-class="mt-3">
      <b-tab title="Lawinengefahr" active>
        <b-table
          small
          striped
          hover
          :items="dangerItems"
          :fields="dangerFields"
          :busy="isBusyDanger"
          sort-by="cTimestamp"
          :sort-desc="true"
          @row-clicked="showDangerDetails"
          :sort-compare="sortCompare"
        >
          <template v-slot:cell(type)="data">
            {{ data.value }}
            <font-awesome-icon icon="fa-solid fa-exclamation-circle" />
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Lawinenmeldung">
        <b-table
          small
          striped
          hover
          :items="avalancheItems"
          :fields="avalancheFields"
          :busy="isBusyAval"
          sort-by="creation"
          :sort-desc="true"
          @row-clicked="showAvalancheDetails"
          :sort-compare="sortCompare"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <!-- NOTE: since no confidential flag delivered for list avalanche items, no distinction possible here -->
        </b-table>
      </b-tab>
    </b-tabs>
    <DetailModal
      :aval="aval"
      detId="detailModal"
      :obsIds="obsIds"
      :aggregate="agg"
    />
    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assm"
      :aggregate="agg"
    />
  </b-container>
</template>

<script>
import moment from 'moment'
import turfDistance from '@turf/distance'
import pointOnFeature from '@turf/point-on-feature'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { polygon as turfPolygon } from '@turf/helpers'

import CalendarComponent from '../calendar/CalendarComponent'
import { i18n } from '@/plugins/i18n'
import observationService from '@/services/observationService.js'
import avalancheService from '@/services/avalancheService.js'
import DetailModal from '../observation/DetailModal'
import { Canceler } from '@/scripts/requestUtil.js'
import DetailModalAssessment from '../assessment/detail/DetailModalAssessment'
import util from '@/scripts/util'
import { DANGER, ATTACHMENT } from '@/scripts/const.js'
import { DateTime } from 'luxon'
import { getSubdivisionChar } from '@/model/dangerModels'
import warnRegionService from '@/services/warnRegionService'
import { InfoService } from '@/services/InfoService'
import { deepClone } from '@/scripts/common'

const Cancel = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Ticker',
  data() {
    return {
      selector: this.$store.state.ticker.selector
        ? this.$store.state.ticker.selector
        : 'time',
      time: this.$store.state.ticker.time
        ? this.$store.state.ticker.time
        : '00:00',
      date: this.$store.state.ticker.date
        ? this.$store.state.ticker.date
        : moment().format('DD.MM.YYYY'),
      dateOptions: {
        format: 'DD.MM.YYYY',
        useCurrent: false,
      },
      // Nr / Warnregion / Vorname Name / Datum, Zeit / Beobachtungsdatum (nur falls nachgetragen) / Gefahrenstufe / Gefahr für / Detail / Expo / Höhenlage / Herkunft der Infos / Qualität der Einschätzung / Bemerkungen / Dateien (ja/nein)
      dangerFields: [
        { key: 'nr', label: 'Nr.' },
        {
          key: 'region',
          label: 'Warnregion',
          sortable: true,
          formatter: (v, k, i) => this.region(i.region),
        },
        {
          key: 'uid',
          label: 'Vorname Name',
          sortable: true,
          formatter: (v, k, i) => this.user(v, i),
        },
        {
          key: 'cTimestamp',
          label: 'Datum, Zeit',
          sortable: true,
          formatter: (v) => moment(v).format('DD.MM.YYYY, HH:mm'),
        },
        {
          key: 'date',
          label: 'Datum Beob.',
          sortable: true,
          formatter: (v, k, i) => this.observationDate(i),
        },
        {
          key: 'danger.level',
          label: 'Gef. Stufe',
          sortable: true,
          formatter: (v, k, i) => this.level(i.danger),
        },
        {
          key: 'danger.problem',
          label: 'Gefahr für',
          sortable: true,
          formatter: (v) => this.translate('danger.problem', v),
        },
        {
          key: 'danger.pattern',
          label: 'Detail',
          sortable: true,
          formatter: (v, k, i) => this.pattern(i.danger),
        },
        {
          key: 'danger.aspect',
          label: 'Expo',
          sortable: true,
          formatter: (v, k, i) => this.aspect(i.danger),
        },
        {
          key: 'danger.altitude',
          label: 'Höhe',
          sortable: true,
          formatter: (v, k, i) => this.altitude(i.danger),
        },
        {
          key: 'danger.origin',
          label: 'Herkunft der Infos',
          sortable: true,
          formatter: (v) => this.translate('observation.origin', v),
        },
        { key: 'comment', label: 'Bemerkungen', sortable: true },
        {
          key: 'documents',
          label: 'Dateien',
          sortable: true,
          formatter: (v) => this.translate('common.flag', v || false),
        },
      ],
      dangerItems: [],
      // Nr / Warnregion / Vorname Name / Datum, Zeit / Abgangsdatum / Anzahl pro Grösse (1 mittel; bei „mehrere Lawinen“: 2/0/0/6/0) / Exposition / Anriss Höhenlage / Feuchte / Auslöseart / Schaden (Sach||Rettung||Person) / Bemerkung / Dateien (ja/nein)
      avalancheFields: [
        { key: 'nr', label: 'Nr.' },
        {
          key: 'region',
          label: 'Warnregion',
          sortable: true,
          formatter: (v, k, i) => this.region(i.region),
        },
        {
          key: 'userId',
          label: 'Vorname Name',
          sortable: true,
          formatter: (v, k, i) => this.user(v, i),
        },
        {
          key: 'creation',
          label: 'Datum Meldung',
          sortable: true,
          formatter: (v) =>
            moment(v + 'Z')
              .local()
              .format('DD.MM.YYYY, HH:mm'),
        },
        {
          key: 'triggerDate',
          label: 'Datum Abgang',
          sortable: true,
          formatter: (v, k, i) => this.formattedRealDate(i),
        },
        {
          key: 'size',
          label: 'Anzahl pro Grösse',
          sortable: true,
          formatter: (v, k, i) => this.avalSize(i),
        },
        {
          key: 'aspect',
          label: 'Expo',
          sortable: true,
          formatter: (v, k, i) => this.avalAspect(i.details),
        },
        {
          key: 'startZoneElevation',
          label: 'Höhenlage Anriss',
          sortable: true,
          formatter: (v) => this.startZoneElevation(v),
        },
        {
          key: 'avalancheMoisture',
          label: 'Feuchte',
          sortable: true,
          formatter: (v) => this.translate('avalanche.avalancheMoisture', v),
        },
        {
          key: 'triggerType',
          label: 'Auslöseart',
          sortable: true,
          formatter: (v) => this.translate('avalanche.triggerType', v),
        },
        {
          key: 'damage',
          label: 'Schaden',
          sortable: true,
          formatter: (v, k, i) => this.damage(i),
        },
        { key: 'noteText', label: 'Bemerkung', sortable: true },
        { key: 'documentCount', label: 'Dateien', sortable: true },
      ],
      avalancheItems: [],
      isBusyDanger: false,
      isBusyAval: false,
      warnregions: [],
      warnregionsDate: null,
      agg: true,
      obsIds: [],
      aval: null,
      assm: {},
    }
  },
  components: {
    DetailModal,
    CalendarComponent,
    DetailModalAssessment,
  },

  mounted() {
    this.refresh()
  },

  beforeUnmount() {
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },

  methods: {
    async loadWarnRegions(isoDate) {
      if (this.warnregions.length <= 0 || this.warnregionsDate !== isoDate) {
        this.warnregionsDate = isoDate
        try {
          const result = await warnRegionService.getWarnRegionsByDate(isoDate)
          this.warnregions = result.data.features.map((r) =>
            turfPolygon(r.geometry.coordinates, r.properties)
          )
        } catch (e) {
          InfoService.handleError(e)
        }
      }
    },
    /**
     * The sort and compare function to use when sorting a column of table Lawinengefahr
     */
    // eslint-disable-next-line complexity
    sortCompare(
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      const aObj = aRow[key]
      const bObj = bRow[key]

      if (!compareLocale) {
        /* eslint-disable-next-line no-param-reassign*/
        compareLocale = this.$store.state.user.userLocale
      }

      let a, b

      switch (key) {
        case 'region': {
          a = aObj?.properties?.sector_id
          b = bObj?.properties?.sector_id
          break
        }
        case 'uid': {
          a = this.user(aObj, aRow)
          b = this.user(bObj, bRow)
          break
        }
        case 'cTimestamp': {
          a = moment(aObj).toDate()
          b = moment(bObj).toDate()
          break
        }
        case 'date': {
          a = new Date(aObj)
          b = new Date(bObj)
          break
        }
        case 'danger.level': {
          a =
            aRow['danger'] !== undefined && aRow['danger'].level !== undefined
              ? Number(aRow['danger'].level)
              : -1
          b =
            bRow['danger'] !== undefined && bRow['danger'].level !== undefined
              ? Number(bRow['danger'].level)
              : -1
          break
        }
        case 'danger.problem': {
          a =
            aRow['danger'] !== undefined && aRow['danger'].problem !== undefined
              ? aRow['danger'].problem
              : ''
          b =
            bRow['danger'] !== undefined && bRow['danger'].problem !== undefined
              ? bRow['danger'].problem
              : ''
          break
        }
        case 'danger.pattern': {
          a =
            aRow['danger'] !== undefined && aRow['danger'].pattern !== undefined
              ? aRow['danger'].pattern
              : ''
          b =
            bRow['danger'] !== undefined && bRow['danger'].pattern !== undefined
              ? bRow['danger'].pattern
              : ''
          break
        }
        case 'danger.aspect': {
          a = this.aspect(aRow['danger'])
          b = this.aspect(bRow['danger'])
          break
        }
        case 'danger.altitude': {
          a = this.altitude(aRow['danger'])
          b = this.altitude(bRow['danger'])
          break
        }
        case 'danger.origin': {
          a =
            aRow['danger'] !== undefined && aRow['danger'].origin !== undefined
              ? aRow['danger'].origin
              : ''
          b =
            bRow['danger'] !== undefined && bRow['danger'].origin !== undefined
              ? bRow['danger'].origin
              : ''
          break
        }
        case 'comment': {
          a = aObj
          b = bObj
          break
        }
        case 'documents': {
          a = this.translate('common.flag', aObj || false)
          b = this.translate('common.flag', bObj || false)
          break
        }

        // Lawinenmeldung
        case 'userId': {
          a = this.user(aObj, aRow)
          b = this.user(bObj, bRow)
          break
        }
        case 'creation': {
          a = moment(aObj).toDate()
          b = moment(bObj).toDate()
          break
        }
        case 'triggerDate': {
          a = moment(aObj).toDate()
          b = moment(bObj).toDate()
          break
        }
        case 'size': {
          a = this.avalSize(aRow)
          b = this.avalSize(bRow)
          break
        }
        case 'aspect': {
          a = this.avalAspect(aRow.details)
          b = this.avalAspect(bRow.details)
          break
        }
        case 'startZoneElevation': {
          a = aObj && aObj.length > 0 ? aObj[0] : 0
          b = bObj && bObj.length > 0 ? bObj[0] : 0
          break
        }
        case 'avalancheMoisture': {
          a = this.translate('avalanche.avalancheMoisture', aObj || '')
          b = this.translate('avalanche.avalancheMoisture', bObj || '')
          break
        }
        case 'triggerType': {
          a = this.translate('avalanche.triggerType', aObj || '')
          b = this.translate('avalanche.triggerType', bObj || '')
          break
        }
        case 'damage': {
          a = this.damage(aRow)
          b = this.damage(bRow)
          break
        }
        case 'noteText': {
          a = aObj
          b = bObj
          break
        }
        case 'documentCount': {
          a = aObj
          b = bObj
          break
        }
      }

      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toString(a).localeCompare(
          this.toString(b),
          compareLocale,
          compareOptions
        )
      }
    },
    /*eslint-enable complexity*/

    // Helper function to stringify the values of an Object
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },

    setDate(value) {
      this.dateString = value
      this.setSelector('date', true)
      this.$store.dispatch('ticker/setDate', this.date)
      this.refresh()
    },

    updateTime() {
      this.setSelector('time', true)
      this.$store.dispatch('ticker/setTime', this.time)
      this.refresh()
    },

    setSelector(value, preventRefresh = false) {
      this.selector = value
      this.$store.dispatch('ticker/setSelector', this.selector)
      if (!preventRefresh) {
        this.refresh()
      }
    },

    async refresh() {
      console.debug('Cancel requests on load')
      this.cancelRequests()

      this.isBusyDanger = true
      this.isBusyAval = true

      let lower = moment().startOf('day')
      let upper = moment()
      if (this.selector === 'time') {
        const [hour, minute] = this.time.split(':')
        lower.set({ hour, minute })
        if (lower.isAfter(moment())) {
          lower.add(-1, 'days')
        }
      } else {
        lower = moment(this.date, 'DD.MM.YYYY').startOf('day')
        upper = moment(this.date, 'DD.MM.YYYY').endOf('day')
      }

      const self = this

      // get normal observations
      const promises = []
      let individualError = false
      const handleError = function (error) {
        // NOTE (20190815/csz): Partial error (one of the services failed)
        individualError = true
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          self.$store.commit('SET_EXCEPTION', error.message, { root: true })
        }
        return error
      }
      await this.loadWarnRegions(
        DateTime.fromISO(lower.toISOString()).toISODate()
      )
      const cancelObj = { cancel: null }
      promises.push(
        observationService.getObservationsByCreationDateTimeRange(
          lower,
          upper,
          ['DANGER', 'ATTACHMENT'],
          cancelObj
        )
      )
      if (Cancel) {
        Cancel.addCancel(cancelObj.cancel)
      }

      // NOTE (20191015/csz): deliver partial results even if one of the services returns w/ error - better than no results at all
      let dangerContactIds = []
      const avalContactIds = []
      Promise.all(
        promises.map(function (promise) {
          return promise.catch(handleError)
        })
      )
        .then(function (res) {
          self.resetDangerItems()
          if (res) {
            res.forEach((result) => {
              if (result) {
                if (result.data && result.data.data) {
                  dangerContactIds = dangerContactIds.concat(
                    result.data.data
                      .filter((i) => i.uid)
                      .map((y) => {
                        return y.uid
                      })
                  )
                  self.dangerItems = self.dangerItems.concat(
                    result.data.data.filter((i) => i.type === 'danger')
                  )
                  self.attachmentItems = self.attachmentItems.concat(
                    result.data.data.filter((i) => i.type === 'attachment')
                  )
                }
              }
            })
          }
          self.dangerItems = self
            .aggregate(self.dangerItems, self.attachmentItems)
            .sort((a, b) => a.cTimestamp - b.cTimestamp)
          let nr = 1
          self.dangerItems.forEach((i) => {
            i.nr = nr++
          })
          self.dangerItems.forEach((i) => {
            i.region = self.getWarnregion(i.location.coordinates)
          })

          if (individualError !== true) {
            // i.e. all promises w/o error
            self.$store.commit('SET_EXCEPTION', null, { root: true })
          }
          self.isBusyDanger = false
        })
        .then(function () {
          self.$store.dispatch('user/loadMissingContacts', dangerContactIds)
        })
        .catch(function (error) {
          self.resetDangerItems()
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            self.$store.commit('SET_EXCEPTION', error.message, { root: true })
          }
          self.isBusyDanger = false
        })

      const cancelObj2 = { cancel: null }
      avalancheService
        .getAvalanchesByCreationDateIntervalAndOptObserver(
          lower,
          upper,
          null,
          cancelObj2
        )
        .then((result) => {
          if (result) {
            self.avalancheItems = result.data
              .sort((a, b) => moment(a.creation) - moment(b.creation))
              .filter(self.avalancheFilter)
            let nr = 1
            self.avalancheItems.forEach((i) => {
              i.nr = nr++
            })

            self.avalancheItems.forEach((aval) => {
              if (aval.avalancheGeo) {
                aval.point = pointOnFeature(aval.avalancheGeo)
              } else {
                console.log('aval has no geometry: id: ' + aval.id)
              }
              if (this.hasDamage(aval)) {
                aval._rowVariant = 'danger'
              }
            })

            self.avalancheItems.forEach((aval) => {
              if (aval.point) {
                aval.region = self.getWarnregion(
                  aval.point.geometry.coordinates
                )
              }
              if (aval.userId) {
                avalContactIds.push(aval.userId)
              }
            })
          }
          self.isBusyAval = false
        })
        .then(function () {
          self.$store.dispatch('user/loadMissingContacts', avalContactIds)
        })
        .catch(function (error) {
          self.avalancheItems = []
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            self.$store.commit('SET_EXCEPTION', error.message, { root: true })
          }
          self.isBusyAval = false
        })

      if (Cancel) {
        Cancel.addCancel(cancelObj2.cancel)
      }
    },

    // eslint-disable-next-line complexity
    aggregate(dangerItems, attachmentItems) {
      const aggregation = dangerItems.slice(0)
      attachmentItems.forEach((attachment) => {
        let aggregated = false
        // eslint-disable-next-line complexity
        dangerItems.forEach((danger) => {
          if (danger.uid !== attachment.uid) return
          if (danger.date !== attachment.date) return
          if (
            Math.abs(danger.cTimestamp - attachment.cTimestamp) >
            60 * 60 * 1000
          )
            return
          const distance = turfDistance(
            danger.location.coordinates,
            attachment.location.coordinates,
            { units: 'kilometers' }
          )
          if (distance <= 5) {
            danger.comment = danger.comment
              ? danger.comment + ' ' + attachment.attachment.comment
              : attachment.attachment.comment
            danger.documents =
              danger.documents ||
              (attachment.attachment &&
                attachment.attachment.docIds &&
                attachment.attachment.docIds.length > 0)
            danger.confidential =
              danger.confidential || attachment.attachment.confidential
            aggregated = true
          }
        })
        if (!aggregated) {
          attachment.comment = attachment.attachment.comment
          attachment.documents =
            attachment.attachment &&
            attachment.attachment.docIds &&
            attachment.attachment.docIds.length > 0
          attachment.confidential = attachment.attachment.confidential
          aggregation.push(attachment)
        }
      })
      return aggregation
    },
    /*eslint-enable complexity*/

    avalancheFilter(aval) {
      // Liegt der Abgangszeitpunkt mind. 2 Tage zurück wird die Lawine nur dargestellt, wenn eine Bemerkung dazu erfasst oder ein Bild mitgeschickt oder es sich um eine Schadenslawine handelt
      const age = moment(aval.creation)
        .startOf()
        .diff(this.realDate(aval), 'days')
      return (
        age < 2 ||
        aval.noteText ||
        aval.documentCount > 0 ||
        aval.materialDamageExists ||
        aval.rescueMissionExists ||
        aval.personDamageExists
      )
    },

    getWarnregion(pt) {
      return this.warnregions.find((p) => booleanPointInPolygon(pt, p))
    },

    /* Achtung: Hier werden auch 'nur' Bemerkungen angezeigt, sofern es keine zugehörige Danger-Meldung gibt.*/
    showDangerDetails(item) {
      this.obsIds = []
      this.aval = null
      this.agg = true
      if (this.isDangerOrAtachmentAssessment(item)) {
        // Objekt klonen, damit der Watcher eine Änderung entdeckt
        this.assm = Object.assign({}, item)
      } else {
        this.obsIds = [item.id]
      }
    },

    showAvalancheDetails(item) {
      this.obsIds = []
      this.aval = deepClone(item)
      this.agg = false
    },

    // eslint-disable-next-line complexity
    user(id, item) {
      const user = this.$store.getters['user/getUserToIdOrDummyUser'](
        id,
        item.creatorSystem
      )
      return user ? `${user.firstName} ${user.lastName}` : '-'
    },
    /*eslint-enable complexity*/

    translate(k, v) {
      return v || v === false ? i18n.global.t(`${k}.${v}`) : ''
    },
    observationDate(obs) {
      return obs.date &&
        obs.cTimestamp &&
        moment(obs.date).isSame(obs.cTimestamp, 'day')
        ? ''
        : moment(obs.date).format('DD.MM.YYYY')
    },
    aspect(danger) {
      return danger
        ? (danger.aspectFrom || '') + '-' + (danger.aspectTo || '')
        : ''
    },
    level(danger) {
      if (danger && danger.level) {
        return `${danger.level}${getSubdivisionChar(danger.subdivision)}`
      }
      return ''
    },
    pattern(danger) {
      return danger && danger.pattern
        ? danger.pattern.map((p) => this.$t('danger.pattern.' + p)).join(', ')
        : ''
    },
    altitude(danger) {
      return danger && danger.altitudeValue
        ? (danger.altitudeAbove === true || danger.altitudeAbove === 'true'
            ? '>'
            : '<') + danger.altitudeValue
        : ''
    },
    region(region) {
      return region && region.properties
        ? region.properties.sector_id + ' ' + region.properties.sector_name
        : 'nicht in Warnregion'
    },

    formattedRealDate(aval) {
      if (!aval) {
        return null
      }
      // a) if no triggerTime, get triggerDate as it is
      // b) if w/ triggerTime, convert both triggerDate and triggerTime to local and then use individual fields in local zone
      return aval.triggerTime
        ? moment
            .utc(aval.triggerDate + 'T' + aval.triggerTime, 'YYYY-MM-DDTHH:mm')
            .local()
            .format('DD.MM.YYYY')
        : moment(aval.triggerDate).format('DD.MM.YYYY')
    },
    realDate(aval) {
      if (!aval) {
        return null
      }
      return aval.triggerTime
        ? moment
            .utc(aval.triggerDate + 'T' + aval.triggerTime, 'YYYY-MM-DDTHH:mm')
            .local()
        : moment(aval.triggerDate)
    },
    avalAspect(details) {
      return details
        ? [...new Set(details.map((detail) => detail.startZoneAspect))].join(
            ', '
          )
        : ''
    },
    avalSize(aval) {
      if (!aval || !aval.details) return ''
      if (aval.avalancheClass === 'SEVERAL_AVALANCHE') {
        const sizes = [0, 0, 0, 0, 0]
        aval.details.forEach((detail) => {
          const idx = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'].indexOf(
            detail.avalancheSize
          )
          sizes[idx] += detail.count
        })
        return sizes.join('/')
      } else {
        if (aval.details[0] && aval.details[0].avalancheSize) {
          return (
            '1 ' +
            this.translate(
              'avalanche.avalancheSize',
              aval.details[0].avalancheSize
            )
          )
        }
        return ''
      }
    },
    damage(v) {
      return this.hasDamage(v)
        ? i18n.global.t('common.flag.true')
        : i18n.global.t('common.flag.false')
    },
    hasDamage(aval) {
      return (
        aval.materialDamageExists ||
        aval.rescueMissionExists ||
        aval.personDamageExists
      )
    },
    startZoneElevation(v) {
      const text = v && v.length > 0 ? v.join(' - ') : ''
      return text ? text + ' m' : ''
    },
    resetDangerItems() {
      this.dangerItems = []
      this.attachmentItems = []
    },

    cancelRequests() {
      Cancel.cancelAll()
    },

    isDangerOrAtachmentAssessment(item) {
      return (
        util.isAssessmentContent(item, DANGER) ||
        util.isAssessmentContent(item, ATTACHMENT)
      )
    },
  },
  computed: {
    dateString: {
      get() {
        return this.date
      },
      set(val) {
        this.date = val
      },
    },
    jsTime: {
      get() {
        return DateTime.fromISO(this.time).toJSDate()
      },
      set(val) {
        this.time = DateTime.fromJSDate(val).toFormat('HH:mm')
        this.updateTime()
      },
    },
  },
}
</script>

<style scoped>
.form-control {
  border: none !important;
  font: 14px Arial, Helvetica, sans-serif;
  padding: 3px;
  height: 100%;
  width: 100px;
  float: left;
}
.label {
  font: 14px Arial, Helvetica, sans-serif;
  color: #929292;
  white-space: nowrap;
}
.conf {
  color: #ff0000;
}
:deep(.p-calendar-timeonly .p-inputtext) {
  padding: 0;
  border: none;
  font-size: 0.875rem;
}
:deep(.ticker-calendar-picker) {
  height: 1.563rem;
}
.vp-time-select {
  display: flex;
  flex-direction: row;
}
.reload-button-container {
  display: flex;
  align-items: center;
}
.date-time-selectors {
  min-width: 12.5rem;
}
.ticker-container {
  min-width: 25rem;
}
</style>

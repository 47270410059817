<template>
  <b-container v-show="hierarchy.avalanchePathId">
    <b-card
      header-tag="header"
      footer-tag="footer"
      style="pointer-events: auto"
    >
      <template #header>
        <b-row>
          <b-col>
            <b>{{ $t('triggering.label.avalancheTriggeringSuspension') }}</b>
            "{{ avalanchePath.name }}"
          </b-col>
        </b-row>
      </template>
      <template #footer>
        <b-row>
          <b-col class="text-left">
            <b-button
              type="button"
              variant="warning"
              @click="routeBack(true)"
              >{{ $t('ui.cancel') }}</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              ref="triggeringInputSendButton"
              @click="onSubmit"
              type="button"
              variant="success"
              :disabled="!reasonState"
              ><font-awesome-icon
                icon="fa-solid fa-paper-plane"
              ></font-awesome-icon>
              {{ $t('ui.send') }}</b-button
            >
          </b-col>
        </b-row>
      </template>
      <b-form>
        <b-row>
          <b-col>
            <b-form-group :label="$t('triggering.label.reason')">
              <b-form-textarea
                id="reason"
                v-model="form.reason"
                :rows="3"
                :spellcheck="false"
                :state="reasonState"
                size="lg"
              />
              <b-form-invalid-feedback id="inputCommentHelp">
                {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { i18nMixin } from '../I18N'
import { routerMixin } from '@/router/RouterMixin'

export default {
  props: {
    parent: {
      type: Object,
      required: true,
      validator: function (value) {
        const parentObject = JSON.parse(value)
        return (
          parentObject.hierarchy &&
          parentObject.hierarchy.areaId &&
          parentObject.hierarchy.avalanchePathId
        )
      },
    },
  },
  mixins: [i18nMixin, routerMixin],
  data() {
    const parentObject = JSON.parse(this.parent)
    return {
      hierarchy: parentObject.hierarchy,
      form: {
        blastingMethod: parentObject.triggering.blastingMethod,
        planned: null,
        executor: parentObject.triggering.executor,
        reason: null,
        parentId: parentObject.triggering.id,
      },
      processing: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  computed: {
    reasonState() {
      return (
        this.form.reason !== null &&
        this.form.reason.length >= 2 &&
        this.form.reason.length <= 500
      )
    },
    avalanchePath() {
      return this.$store.getters['profile/getAvalanchePath'](
        this.hierarchy.avalanchePathId
      )
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this
      this.$store
        .dispatch('triggering/addTriggering', {
          form: this.form,
          hierarchy: this.hierarchy,
        })
        .then(
          () => {
            self.processing = false
            self.routeBack(false)
          },
          (reason) => {
            console.error('Error submitting delete triggering:' + reason)
            self.processing = false
          }
        )
    },
    routeBack() {
      this.routeToWhenNeeded(this.routeOrigin)
    },
  },
}
</script>

<style scoped></style>

import moment from 'moment'
import { i18n } from '@/plugins/i18n'
import { StudyplotService } from '@/services/StudyplotService'
import { DateTime } from 'luxon'
import {
  mapInputMeasToServiceMeasForAdd,
  mapInputMeasToServiceMeasForUpdate,
} from '@/model/studyplotInput'

// state
const state = {
  measurements: [],
  studyplots: [],
}

// getters
const getters = {
  getStudyplot: (state) => (studyplotCode) => {
    return state.studyplots.find(
      (studyplot) => studyplot.stationCode.slice(0, -1) === studyplotCode
    )
  },
  getActiveStudyplotCodes: (state) => {
    return state.studyplots
      .filter((studyplot) => studyplot.status === 1)
      .map((studyplot) => {
        return studyplot.code
      })
      .sort()
  },
}

// actions
const actions = {
  loadMeasurements({ commit, rootGetters }, interval) {
    commit('SET_MEASUREMENTS', [])
    return StudyplotService.findMeasurements(
      rootGetters.user.id,
      DateTime.fromISO(moment(interval.upperDate).toISOString()),
      interval.range,
      interval.cancel
    )
      .then((data) => {
        commit('SET_MEASUREMENTS', data)
        commit('SET_EXCEPTION', null, { root: true })
        console.log('measurements loaded.')
      })
      .catch(function (error) {
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },

  addMeasurement(context, payload) {
    const measurement = mapInputMeasToServiceMeasForAdd(
      payload.measurementForm,
      payload.isManagmentMode
    )

    return new Promise((resolve, reject) => {
      StudyplotService.addMeasurement(
        measurement,
        payload.isManagmentMode,
        payload.measurementForm.studyplot
      )
        .then(function () {
          context.commit('ADD_MEASUREMENT', measurement)
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            if (error.response && error.response.status === 409) {
              context.commit(
                'SET_EXCEPTION',
                i18n.global.t('measurement.label.duplicate', {
                  date: moment(measurement.date).format('DD.MM.YYYY'),
                }),
                { root: true }
              )
            } else {
              context.commit('SET_EXCEPTION', error.message, { root: true })
            }
          }
          reject(error)
        })
    })
  },

  updateMeasurement(context, payload) {
    const measurement = mapInputMeasToServiceMeasForUpdate(
      payload.measurementForm,
      payload.isManagmentMode
    )

    return new Promise((resolve, reject) => {
      StudyplotService.updateMeasurement(
        measurement,
        payload.isManagmentMode,
        payload.measurementForm.studyplot
      )
        .then(function () {
          context.commit('UPDATE_MEASUREMENT', measurement)
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          handleUpdateError(error, context)
          reject(error)
        })
    })
  },
  async loadStudyplots(context) {
    context.commit('SET_STUDYPLOTS', [])
    try {
      const result = await StudyplotService.getStudyplots()
      context.commit('SET_STUDYPLOTS', result.data)
      context.commit('SET_EXCEPTION', null, { root: true })
    } catch (error) {
      if (!(error.response && error.response.status === 401)) {
        context.commit('SET_EXCEPTION', error.message, { root: true })
      }
    }
  },
}

// mutations
const mutations = {
  SET_STUDYPLOTS(state, studyplots) {
    state.studyplots = studyplots
  },
  SET_MEASUREMENTS(state, measurements) {
    state.measurements = measurements
  },
  ADD_MEASUREMENT(state, measurement) {
    state.measurements.push(measurement)
  },
  UPDATE_MEASUREMENT(state, measurement) {
    const idx = state.measurements.findIndex(
      (el) =>
        el.code === measurement.code &&
        el.date === measurement.date &&
        el.type === measurement.type
    )
    if (idx >= 0) {
      state.measurements[idx] = measurement
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

const OVERRIDING_FORBIDDEN = 'Overriding forbidden'
function handleUpdateError(error, context) {
  console.error(error)
  if (
    error.response?.status === 403 &&
    error.response.data?.message?.includes(OVERRIDING_FORBIDDEN)
  ) {
    context.commit(
      'SET_EXCEPTION',
      i18n.global.t('measurement.error.noModifyAllowed'),
      { root: true }
    )
    return
  }
  if (!(error.response && error.response.status === 401)) {
    const errorInfo =
      error.response.data?.message != null
        ? `: ${error.response.data.message}`
        : ''
    context.commit('SET_EXCEPTION', `${error.message}${errorInfo}`, {
      root: true,
    })
  }
}

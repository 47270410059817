'use strict'

import BaseObject from './BaseObject'

export default class Building extends BaseObject {
  constructor(id, name, location, active) {
    super(id, name, location, active)
    this.class = 'building'
    this.type = 'building'
  }
}

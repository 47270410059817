'use strict'

import { Control } from 'ol/control.js'
import emitter from '@/components/EventHub'

export default class LegendControl extends Control {
  constructor(mobile, legendIconEl) {
    const element = document.createElement('div')
    super({
      element: element,
    })
    this.visible = false
    this.mobile = mobile

    const self = this
    const clickedLegend = function (event) {
      event.preventDefault()
      if (self.visible) {
        emitter.emit('showLegend', false)
        self.visible = false
      } else {
        emitter.emit('showLegend', true)
        self.visible = true
        if (self.mobile) {
          emitter.emit('showFilter', false)
          emitter.emit('showLayer', false)
          emitter.emit('showCommonLayers', false)
        }
      }
    }
    const buttonLegend = document.createElement('button')
    buttonLegend.className = 'button-legend'
    buttonLegend.appendChild(legendIconEl)

    buttonLegend.addEventListener('click', clickedLegend, false)

    element.appendChild(buttonLegend)
    if (mobile) {
      element.className = 'ol-unselectable ol-control legend-control-mobile'
    } else {
      element.className = 'ol-unselectable ol-control legend-control'
    }
  }
}

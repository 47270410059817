import { HumidityStylesValues } from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'
import Style from 'ol/style/Style'
import { Fill, Stroke } from 'ol/style'
import {
  HUMIDITY_STYLES,
  HUMIDITY_STYLES_INPUT_MAP,
} from '@/components/styles/avalancheStyling/AvalancheCommonStyling'

export const AVAL_MOISTURE = ['NO', 'DRY', 'WET', 'MIXED', 'UNKNOWN']

export default class GeneralStyleFactory {
  private static instance: GeneralStyleFactory | undefined = undefined
  private generalStyle = new Map<string, Style>()
  private generalStyleFill = new Map<string, Fill>()
  private generalStyleStroke = new Map<string, Stroke>()
  private generalStyleInputMap = new Map<string, Style>()
  private generalStyleFillInputMap = new Map<string, Fill>()
  private generalStyleStrokeInputMap = new Map<string, Stroke>()

  private constructor() {
    AVAL_MOISTURE.forEach((moisture) => {
      this.generalStyleFill.set(
        moisture,
        this.getGeneralStyleFill(
          HUMIDITY_STYLES[moisture as keyof typeof HUMIDITY_STYLES]
        )
      )
      this.generalStyleFillInputMap.set(
        moisture,
        this.getGeneralStyleFill(
          HUMIDITY_STYLES_INPUT_MAP[
            moisture as keyof typeof HUMIDITY_STYLES_INPUT_MAP
          ]
        )
      )
      this.generalStyleStroke.set(
        moisture,
        this.getGeneralStyleStroke(
          HUMIDITY_STYLES[moisture as keyof typeof HUMIDITY_STYLES]
        )
      )
      this.generalStyleStrokeInputMap.set(
        moisture,
        this.getGeneralStyleStroke(
          HUMIDITY_STYLES_INPUT_MAP[
            moisture as keyof typeof HUMIDITY_STYLES_INPUT_MAP
          ]
        )
      )
      this.generalStyle.set(moisture, this.getGeneralStyle(moisture, false))
      this.generalStyleInputMap.set(
        moisture,
        this.getGeneralStyle(moisture, true)
      )
    })
  }

  static getInstance(): GeneralStyleFactory {
    this.instance = this.instance || new GeneralStyleFactory()
    return this.instance
  }

  public getGeneralStyleToAvalMoisture(
    avalMoisture: string,
    useInputMapStyling: boolean
  ): Style {
    let resultingStyle
    if (useInputMapStyling) {
      resultingStyle = this.generalStyleInputMap.get(avalMoisture)
    } else {
      resultingStyle = this.generalStyle.get(avalMoisture)
    }
    if (!resultingStyle) {
      const msg = `Zu den übergebenen Parameter, konnte kein Style gefunden werden. MOISTURE:${avalMoisture}, useInputMapStyling:${useInputMapStyling},`
      console.error(msg)
      throw new Error(msg)
    }
    return resultingStyle
  }

  private getGeneralStyle(
    avalMoisture: string,
    useInputMapStyling: boolean
  ): Style {
    return new Style({
      fill: useInputMapStyling
        ? this.generalStyleFillInputMap.get(avalMoisture)
        : this.generalStyleFill.get(avalMoisture),
      stroke: useInputMapStyling
        ? this.generalStyleStrokeInputMap.get(avalMoisture)
        : this.generalStyleStroke.get(avalMoisture),
    })
  }
  private getGeneralStyleFill(style: HumidityStylesValues): Fill {
    return new Fill({
      color: style.fill,
    })
  }
  private getGeneralStyleStroke(style: HumidityStylesValues): Stroke {
    return new Stroke({
      color: style.stroke_color,
      width: style.stroke_width,
    })
  }
}

'use strict'

import { Control } from 'ol/control.js'

export default class InfoControl extends Control {
  constructor(mapComponent, infoIconEl) {
    const element = document.createElement('div')
    super({
      element: element,
    })
    const clicked = function (event) {
      if (event) {
        event.preventDefault()
      }
      mapComponent.showInfo()
    }

    this.buttonInfo = document.createElement('button')
    this.buttonInfo.appendChild(infoIconEl)
    this.buttonInfo.className = 'info-button'
    this.buttonInfo.id = 'infoButton'

    this.buttonInfo.addEventListener('click', clicked, false)

    element.className = 'info ol-unselectable ol-control'
    element.appendChild(this.buttonInfo)
  }
}

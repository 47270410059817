<template>
  <div v-if="showMe">
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template #label>
            <InfoLabel
              :text="$t('dangersign.label.avalReleaseCurrentDay')"
              :info="$t('help.observation.avalReleaseCurrentDay')"
            />
          </template>
          <b-form-select
            id="currentDay"
            :options="avalanche"
            size="sm"
            :value="myValue.avalRelease.currentDay"
            @change="update('currentDay', 'avalRelease', $event)"
            :state="combinedRequiredState"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template #label>
            <InfoLabel
              :text="$t('dangersign.label.avalReleasePreviousDay')"
              :info="$t('help.observation.avalReleasePreviousDay')"
            />
          </template>
          <b-form-select
            id="previousDay"
            :options="avalanche"
            size="sm"
            :value="myValue.avalRelease.previousDay"
            @change="update('previousDay', 'avalRelease', $event)"
            :state="combinedRequiredState"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="margin-top: 10px">
      <b-col sm="6">
        <InfoLabel
          :text="$t('dangersign.label.avalReleaseOlder')"
          :info="$t('help.observation.avalReleaseOlder')"
          hide="true"
        />
        <b-form-checkbox
          size="sm"
          :checked="myValue.oldAvalanche"
          @change="update('oldAvalanche', null, $event)"
          >{{ $t('dangersign.label.avalReleaseOlder') }}</b-form-checkbox
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { i18nMixin } from '../I18N'
import InfoLabel from '../InfoLabel'
import { deepClone } from '@/scripts/common'
export default {
  props: ['modelValue', 'combinedRequiredState'],
  emits: ['update:modelValue', 'avalSignsVisibilityUpdated'],
  compatConfig: { COMPONENT_V_MODEL: false },
  components: { InfoLabel },
  mixins: [i18nMixin],
  data() {
    return {
      myValue: deepClone(this.modelValue),
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.myValue = deepClone(this.modelValue)
      },
    },
  },
  computed: {
    avalanche() {
      return this.options('common', 'flag').concat(
        this.options('dangersign', 'avalancheObservation', true)
      )
    },
    showMe() {
      const isVisible =
        this.$store.getters['observation/isObsForToday'] ||
        (this.$store.state.observation.selected !== undefined &&
          this.$store.state.observation.selected !== null &&
          this.$store.state.user.user.isAdmin)
      this.$emit('avalSignsVisibilityUpdated', isVisible)
      return isVisible
    },
  },
  methods: {
    update(key, childKey, value) {
      const deepClonedValue = deepClone(this.modelValue)
      if (childKey != null) {
        deepClonedValue[childKey][key] = value
      } else {
        deepClonedValue[key] = value
      }
      this.$emit('update:modelValue', deepClonedValue)
    },
  },
}
</script>

<style scoped>
.custom-select.is-invalid {
  border-width: medium;
}
</style>

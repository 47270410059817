import {
  MeasurementBody,
  MeasurementBodyManagement,
  MeasurementUpdateBody,
  Studyplot,
  StudyplotTimeseries,
} from '@/services/StudyplotService'
import { DateTime } from 'luxon'

export const HS_MEASURAND_CODE = 'HS'
export const HN_MEASURAND_CODE = 'HN_1D'
export const HNW_MEASURAND_CODE = 'HNW_1D'
export const BEOB_SNOW_MEASURAND_CODES = [
  HS_MEASURAND_CODE,
  HN_MEASURAND_CODE,
  HNW_MEASURAND_CODE,
]
interface MeasurementInputMeasures {
  hn: string | null
  hnw: string | null
  hs: string | null
}
export interface MeasurementInput extends MeasurementInputMeasures {
  date: string
  time: string
  studyplot: string
  comment: string
  oldMeasures: MeasurementInputMeasures
}
export interface MeasurementInputUpdate extends MeasurementInput {
  oldDate: string
}

export function mapInputMeasToServiceMeasForAdd(
  measurementForm: MeasurementInput,
  isManagementMode: boolean
): MeasurementBodyManagement | MeasurementBody {
  if (isManagementMode) {
    return mapInputMeasforManagement(measurementForm)
  } else {
    return mapInputMeasforCommonAdd(measurementForm)
  }
}

export function mapInputMeasToServiceMeasForUpdate(
  measurementForm: MeasurementInputUpdate,
  isManagementMode: boolean
): MeasurementUpdateBody | MeasurementBodyManagement {
  if (isManagementMode) {
    return mapInputMeasforManagement(measurementForm)
  } else {
    return mapInputMeasforCommonUpdate(measurementForm)
  }
}

function mapInputMeasforCommonAdd(
  measurementForm: MeasurementInput
): MeasurementBody {
  return {
    date: measurementForm.date,
    time: measurementForm.time,
    hn: maptoNumberOrNull(measurementForm.hn),
    hnw: maptoNumberOrNull(measurementForm.hnw),
    hs: maptoNumberOrNull(measurementForm.hs),
    comment: measurementForm.comment,
  }
}

function mapInputMeasforCommonUpdate(
  measurementForm: MeasurementInputUpdate
): MeasurementUpdateBody {
  return {
    date: measurementForm.date,
    oldDate: measurementForm.oldDate,
    time: measurementForm.time,
    hn: maptoNumberOrNull(measurementForm.hn),
    hnw: maptoNumberOrNull(measurementForm.hnw),
    hs: maptoNumberOrNull(measurementForm.hs),
    comment: measurementForm.comment,
  }
}

function mapInputMeasforManagement(
  measurementForm: MeasurementInput | MeasurementInputUpdate
): MeasurementBodyManagement {
  return {
    date: measurementForm.date,
    time: measurementForm.time,
    hn: maptoNumberOrNull(measurementForm.hn),
    hnw: maptoNumberOrNull(measurementForm.hnw),
    hs: maptoNumberOrNull(measurementForm.hs),
    comment: measurementForm.comment,
  }
}

function maptoNumberOrNull(value: string | null): number | null {
  let result = value
  if (result != null && result.length === 0) {
    result = null
  }
  return result != null ? Number(result) : null
}

export function hasStationAnActiveBeobTimeserie(
  date: DateTime,
  station: Studyplot,
  beobMeasurandCodes: string[] = BEOB_SNOW_MEASURAND_CODES
): boolean {
  const utcStartDateTime = DateTime.fromObject(
    { year: date.year, month: date.month, day: date.day },
    { zone: 'utc' }
  )
  return station.timeseries.some(
    (timeseries) =>
      timeseriesMatchesAnyMeasurandCode(timeseries, beobMeasurandCodes) &&
      timeseriesOverlapsDateRange(
        timeseries,
        utcStartDateTime,
        utcStartDateTime.plus({ hour: 6, minute: 1 })
      )
  )
}

function timeseriesMatchesAnyMeasurandCode(
  ts: StudyplotTimeseries,
  measurandCodes: string[]
): boolean {
  return ts.measurandCode != null && measurandCodes.includes(ts.measurandCode)
}

function timeseriesOverlapsDateRange(
  ts: StudyplotTimeseries,
  startDate: DateTime,
  endDate: DateTime
): boolean {
  if (!ts.since) {
    return false
  }
  const since: DateTime = DateTime.fromISO(ts.since)
  const until: DateTime = ts.until ? DateTime.fromISO(ts.until) : DateTime.now()

  return startDate < until && endDate > since
}

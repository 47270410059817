<script lang="ts">
import DangerInput from '../danger/Input.vue'
import AreaSectorInput from './AreaSectorInput.vue'
import { defineComponent } from 'vue'
import { beforeRouteEnter } from '@/components/routingConfig'

export default defineComponent({
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: DangerInput,
  components: { AreaSectorInput },
  data() {
    return {
      assFlag: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    beforeRouteEnter(to, from, next)
  },
  computed: {
    isStudyplotDanger() {
      return false
    },
  },
})
</script>

<style lang="css" scoped src="../danger/Input.css"></style>

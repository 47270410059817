'use strict'

import Map from 'ol/Map'
import View from 'ol/View'
import VectorLayer from 'ol/layer/Vector'
import avalancheUtil from '../../scripts/avalancheInputMap/avalancheUtil.js'
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom.js'
import PositionControl from '../controls/PositionControl.js'
import PinchRotate from 'ol/interaction/PinchRotate.js'
import DragZoom from 'ol/interaction/DragZoom.js'
import Draw from 'ol/interaction/Draw.js'
import { AvalancheDrawStyle } from '@/components/styles/avalancheStyling/AvalancheDrawStyle'
import { createCommonLayers } from '../common/commonLayers'
import {
  createCommonControls,
  getAttributionControl,
  getZoomControl,
} from '../common/commonControls'
import { resolutionLimit } from '../layers/tileLayerUtil'
import {
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_LAYER_GROUP,
} from '@/plugins/fontawesomeConsts'
import { DEFAULT_VIEW_EXTENT } from '@/scripts/Map'

export default class ObservationMap extends Map {
  // eslint-disable-next-line complexity
  constructor(mapComponent, defaultZoom, iconRefs) {
    const DEFAULT_CENTER = mapComponent.$store.state.map.center || [
      660000, 180000,
    ]
    const DEFAULT_ZOOM = mapComponent.$store.state.map.zoom || defaultZoom

    const drawnItems = new VectorLayer({
      source: mapComponent.drawnSource,
      style: new AvalancheDrawStyle().styleFunction,
      zIndex: 20,
      renderers: ['Canvas', 'VML', 'SVG'],
    })

    const view = new View({
      projection: mapComponent.projection,
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
      zoomFactor: 1.5,
      minZoom: 11,
      maxZoom: 35,
      extent: DEFAULT_VIEW_EXTENT,
      constrainOnlyCenter: true,
    })

    const additionalControls = []
    if (!mapComponent.readOnly) {
      const positionControl = new PositionControl(
        view,
        'observation',
        mapComponent.mobile,
        iconRefs[FA_SOLID_FA_CROSSHAIRS]
      )
      additionalControls.push(positionControl)
    }
    const controls = createCommonControls(
      mapComponent.mobile,
      iconRefs[FA_SOLID_FA_LAYER_GROUP]
    ).extend(additionalControls)

    const namedCommonLayers = createCommonLayers()
    const commonLayers = {}
    for (const { name, layer } of namedCommonLayers) {
      commonLayers[name] = layer
    }
    const commonLayerArray = namedCommonLayers.map(({ layer }) => layer)

    const options = {
      controls: controls,
      theme: null,
      layers: [...commonLayerArray, drawnItems],
      target: 'inputMapPanel',
      view: view,
      renderer: 'canvas',
    }
    super(options)
    this.commonLayers = commonLayers
    this.mapComponent = mapComponent
    this.mapComponent.showTwoFingerMessage = false
    this.drawPresentFeatures()
    this.addDrawInteraction(mapComponent)
    const centralPoint = this.getCentralPointOfPresentFeatures()
    if (centralPoint) {
      if (!mapComponent.readOnly && mapComponent.adaptMap) {
        this.mapComponent.$store.dispatch('map/setCenter', centralPoint)
        this.mapComponent.$store.dispatch('map/setZoom', 20)
      }
      this.getView().setCenter(centralPoint)
      this.getView().setZoom(20)
    }

    this.setAttributionVisibility()

    const ghostZoom = view.getZoom()
    const myInstance = this
    this.on('moveend', () => {
      if (!mapComponent.readOnly && mapComponent.adaptMap) {
        mapComponent.$store.dispatch('map/setCenter', view.getCenter())
        mapComponent.$store.dispatch('map/setZoom', 25)
      }
      if (ghostZoom !== view.getZoom()) {
        this.setAttributionVisibility()
        const zoomControl = getZoomControl(super.getControls())
        if (zoomControl) {
          zoomControl.element.children[1].disabled =
            view.getZoom() === view.getMinZoom()
          zoomControl.element.children[0].disabled =
            view.getZoom() === view.getMaxZoom()
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let timeoutRef = setTimeout(() => {
          myInstance.updateSize()
          timeoutRef = null
        }, 2)
      }
    })

    // do not zoom on drag, as its confusing
    const dragZoomIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof DragZoom
      })
    this.getInteractions().removeAt(dragZoomIndex)

    // remove zoom on double click which is a problem when terminating a lineString
    const dbClickIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof DoubleClickZoom
      })
    this.getInteractions().removeAt(dbClickIndex)

    // remove rotate with two fingers
    const rotateIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof PinchRotate
      })
    this.getInteractions().removeAt(rotateIndex)
  }
  setAttributionVisibility() {
    if (super.getView().getResolution() < resolutionLimit) {
      getAttributionControl(super.getControls()).element.classList.remove(
        'ol-hidden'
      )
    } else {
      getAttributionControl(super.getControls()).element.classList.add(
        'ol-hidden'
      )
    }
  }
  getCentralPointOfPresentFeatures() {
    let centralPoint
    if (this.mapComponent.coord) {
      const features = this.mapComponent.drawnSource.getFeatures()
      if (features && features.length > 0) {
        if (this.mapComponent.coord) {
          centralPoint = features[0].getGeometry().getCoordinates()
        }
      }
    }
    return centralPoint
  }
  drawPresentFeatures() {
    this.mapComponent.drawnSource.clear(true)
    if (this.mapComponent.coord) {
      this.addFeaturesToSource(this.mapComponent, this.mapComponent.drawnSource)
    }
  }

  addFeaturesToSource(mapComponent, source) {
    const features = []
    if (mapComponent.coord) {
      const pointFeature = avalancheUtil.addCircle(
        source,
        mapComponent.coord.coordinates,
        this.mapComponent.projection
      )
      features.push(pointFeature)
    }
    return features
  }

  addDrawInteraction(mapComponent) {
    const myInstance = this
    if (mapComponent.mobile) {
      this.draw = new Draw({
        style: [],
        source: mapComponent.drawnSource,
        type: 'Point',
      })
    } else {
      this.draw = new Draw({
        source: mapComponent.drawnSource,
        type: 'Point',
      })
    }
    this.draw.on('drawend', function (e) {
      const geometry = e.feature.getGeometry()
      mapComponent.addFeature(geometry)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let timeoutRef = setTimeout(() => {
        myInstance.updateSize()
        timeoutRef = null
      }, 1)
    })
    this.addInteraction(this.draw)
  }

  setCommonLayerVisibility(layerName, visible) {
    this.commonLayers[layerName].setVisible(visible)
  }
}

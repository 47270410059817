import { i18n } from '@/plugins/i18n'
import { hasStationAnActiveBeobTimeserie } from '@/model/studyplotInput'
import { Studyplot } from '@/services/StudyplotService'
import { DateTime } from 'luxon'

export const inputAllowedChecker = {
  methods: {
    checkItHasActiveTimeserieError(
      date: Date,
      studyplot: Studyplot
    ): string | null {
      let alert: string | null = null
      if (
        !hasStationAnActiveBeobTimeserie(DateTime.fromJSDate(date), studyplot)
      ) {
        alert = i18n.global.t('measurement.label.noTimeserieAtDate').toString()
      }
      return alert
    },
  },
}

'use strict'

import { i18n } from '@/plugins/i18n'
import { GEFAHREN_INDEX_COLORS } from '@/components/styles/AbstractStyle'
import util from '../../scripts/util.js'

const ALTITUDE_RANGE_ARRAY = [
  '1000-1500',
  '1500-2000',
  '2000-2500',
  '2500-3000',
  '3000-3500',
  '3500-4000',
  '>4000',
]
const ALTITUDE_ARRAY = [1000, 1500, 2000, 2500, 3000, 3500, 4000]

export default class SpecialValueBuilder {
  static getWuCraRosettenValue(objProp) {
    let val = ''
    objProp.forEach((ele, idx) => {
      if (ele !== null) {
        val = val + SpecialValueBuilder.getRosettenVal(idx, ele, val)
      }
    })
    return val
  }

  static getRosettenVal(idx, ele, val) {
    let singleVal = ''
    if (val.length > 0) {
      singleVal = singleVal + '; '
    }
    singleVal =
      singleVal +
      ALTITUDE_RANGE_ARRAY[idx] +
      i18n.global.t('common.unit.meter') +
      ' '
    return (
      singleVal + i18n.global.t('common.flag.' + (ele === 1 ? 'true' : 'false'))
    )
  }

  static getWuCraColors(frequency) {
    return [
      GEFAHREN_INDEX_COLORS[0],
      util.frequencyColor(false, frequency, GEFAHREN_INDEX_COLORS),
    ]
  }

  static getWuCraRadarValue(objProp) {
    const EXPOS = ['north', 'east', 'south', 'west']
    const COLORS = this.getWuCraColors(objProp.frequency)
    const EXPOS_MAPPER = {
      north: 'N',
      east: 'E',
      south: 'S',
      west: 'W',
    }

    const filteredExpos = Object.assign(
      {},
      ...['N', 'W', 'S', 'E'].map((e) => ({
        [e]: Array(3).map(() => '#FFFFFF'),
      }))
    )

    const colors = this.colors ? this.colors : COLORS

    const height = this.getWuCraHeight(objProp)

    for (let i = 0; i < 3; i++) {
      const idx = height / 500 + (i - 2)

      EXPOS.forEach((e) => {
        if (objProp[e]) {
          const value = objProp[e][idx]
          const radarExpo = EXPOS_MAPPER[e]
          filteredExpos[radarExpo][i] = value != null ? colors[value] : ''
        }
      })
    }

    const legends = [
      i18n.global.t('common.flag.true') + '-' + colors[1],
      i18n.global.t('common.flag.false') + '-' + colors[0],
    ].join(',')

    const serializedExpos = [
      'label1:' + (height + 500),
      'label2:' + height,
      'legends:' + legends,
    ]

    for (const expo in EXPOS_MAPPER) {
      const e = EXPOS_MAPPER[expo]
      if (filteredExpos[e] != null && Array.isArray(filteredExpos[e])) {
        serializedExpos.push(e + ':' + filteredExpos[e].join(','))
      } else {
        serializedExpos.push(e + ':')
      }
    }

    return serializedExpos.join(';')
  }

  static getWuCraHeight(objProp) {
    const EXPOS = ['west', 'south', 'east', 'north']

    let startIndexFound = false
    // Search index of first value in all aspect arrays [1000müM, 1500müM, 2000müM, 2500müM, 3000müM, 3500müM, 4000müM]
    let startIndex = 5
    EXPOS.forEach((aspect) => {
      if (objProp[aspect]) {
        const index = objProp[aspect].findIndex((v) => v !== null)
        if (index > -1) {
          startIndexFound = true
          startIndex = Math.min(startIndex, index)
        }
      }
    })
    if (!startIndexFound) startIndex = 1

    return startIndex * 500 + 1000
  }

  static getDangerAspectRosetteValue(objProp) {
    return objProp.aspectFrom + '-' + objProp.aspectTo
  }

  static getSnowHeightChartValue(objProp) {
    let val = objProp
      .map((ele, idx) => {
        const prefix = ALTITUDE_ARRAY[idx] + ':'
        if (ele == null || ele === '') {
          return prefix + 'X'
        } else {
          return prefix + ele
        }
      })
      .join(';')
    val +=
      ';YLabel:' +
      i18n.global.t('snow.label.seaElevation') +
      ' [' +
      i18n.global.t('common.unit.meterAboveSeaLevel') +
      ']'
    val +=
      ';XLabel:' +
      i18n.global.t('snow.label.newSnow') +
      ' [' +
      i18n.global.t('common.unit.centimeter') +
      ']'
    return val
  }

  static getAvalStartzoneCountsValue(objProp, size) {
    let avalOne = {}
    let avalTwo = {}
    let avalThree = {}
    objProp.forEach((item) => {
      const errorMsg =
        'Für Mehrfachlawinen werden nur die Grössen ONE, TWO und THREE unterstützt! objProp.avalancheSize=' +
        item.avalancheSize
      switch (item.avalancheSize) {
        case 'ONE':
          avalOne = SpecialValueBuilder.buildAvalObject(item, avalOne)
          break
        case 'TWO':
          avalTwo = SpecialValueBuilder.buildAvalObject(item, avalTwo)
          break
        case 'THREE':
          avalThree = SpecialValueBuilder.buildAvalObject(item, avalThree)
          break
        default:
          console.error(errorMsg)
          throw errorMsg
      }
    })
    const sizeObj = size === 1 ? avalOne : size === 2 ? avalTwo : avalThree
    return SpecialValueBuilder.buildSizeValue(sizeObj)
  }

  static buildAvalObject(objProp, avalSizeObj) {
    if (objProp.startZoneAspect === 'N') {
      avalSizeObj.northCount = objProp.count
    }
    if (objProp.startZoneAspect === 'E') {
      avalSizeObj.eastCount = objProp.count
    }
    if (objProp.startZoneAspect === 'S') {
      avalSizeObj.southCount = objProp.count
    }
    if (objProp.startZoneAspect === 'W') {
      avalSizeObj.westCount = objProp.count
    }
    return avalSizeObj
  }

  static buildSizeValue(sizeObj) {
    let val = ''
    const aspectArray = ['northCount', 'eastCount', 'southCount', 'westCount']
    aspectArray.forEach((el) => {
      const newval = sizeObj[el]
        ? SpecialValueBuilder.getSeperatedAspectValue(
            val,
            el.charAt(0).toUpperCase() + ': ' + sizeObj[el]
          )
        : ''
      val = val + newval
    })
    return val
  }

  static getSeperatedAspectValue(val, newVal) {
    if (val && val.length > 0) {
      return ', ' + newVal
    }
    return newVal
  }
}

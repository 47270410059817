import { i18n } from '@/plugins/i18n'
import { FeatureLike } from 'ol/Feature'
import { DangerDto, getSubdivisionChar } from '@/model/dangerModels'

export const getMouseOverText = function (
  feature: FeatureLike | null | undefined,
  mouseOverTitle: string
): string {
  const dangerProps = feature?.get('danger')
  if (dangerProps) {
    const origin = getDangerOriginText(dangerProps.origin)
    const problem = getDangerProblemText(dangerProps.problem)
    let problemDetail = ''
    if (dangerProps.pattern && dangerProps.pattern.length > 0) {
      problemDetail = getDangerPatternText(dangerProps.pattern)
    }
    if (dangerProps.allDay) {
      problemDetail += i18n.global.t('danger.allDay.' + dangerProps.allDay)
    }
    const detail =
      problemDetail.length > 0
        ? '<div>' +
          i18n.global.t('danger.label.problemDetail') +
          ': <b>' +
          problemDetail +
          '</b></div>'
        : ''
    return mouseOverTitle
      .concat(origin)
      .concat(getMouseOverLevel(dangerProps))
      .concat(problem)
      .concat(detail)
  } else {
    return ''
  }
}

const getDangerPatternText = function (
  pattern: Array<string> | undefined | null
): string {
  let problemDetail = ''
  if (pattern != null) {
    const len = pattern.length
    for (let i = 0; i < len; i++) {
      if (i > 0) {
        problemDetail += ', '
      }
      problemDetail += i18n.global.t('danger.pattern.' + pattern[i])
    }
  }
  return problemDetail
}
const getDangerProblemText = function (problem: string): string {
  return problem
    ? '<div>' +
        i18n.global.t('danger.label.problem') +
        ': <b>' +
        i18n.global.t('danger.problem.' + problem) +
        '</b></div>'
    : ''
}

const getDangerOriginText = function (origin: string): string {
  return origin
    ? '<div>' +
        i18n.global.t('observation.label.origin') +
        ': <b>' +
        i18n.global.t('observation.origin.' + origin) +
        '</b></div>'
    : ''
}
const getMouseOverLevel = (dangerProps: DangerDto): string => {
  if (dangerProps.level) {
    return `<div>${i18n.global.t('danger.label.level')}: <b>${
      dangerProps.level
    }${getSubdivisionChar(dangerProps.subdivision)}</b></div>`
  }
  return ''
}

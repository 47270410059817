import BaseService from '@/services/BaseService'

export default class contactService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('CONTACT_SERVICE_URL', 5000)
    return this.srv.getInstance()
  }
  static getKeycloakUser(userId) {
    return this.getInstance().get('/keycloak/users/' + userId)
  }
  static updateKeycloakUser(user) {
    return this.getInstance().put('/keycloak/users/' + user.id, user)
  }
  static getContact(userId) {
    return this.getInstance().get('/contacts/' + userId)
  }
  static getContacts(userIds) {
    return this.getInstance().post('/contacts', userIds)
  }
}

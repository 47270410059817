'use strict'

const SVG_DEF =
  '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" xml:space="preserve" viewBox="'

export class SVG {
  constructor(viewBox) {
    this.viewBox = viewBox || '-25 -25 50 50'
    this.svg = SVG_DEF + this.viewBox + '">'
  }
  createImage(el) {
    const img = new Image()
    img.src = 'data:image/svg+xml,' + escape(this.svg + el + '</svg>')
    return img
  }
}

export const draggableModalReportMixin = {
  data() {
    return {
      printRef: undefined,
      isReportPrint: false,
      aggregate: false,
    }
  },
  computed: {
    showReportPrint() {
      return this.isReportPrint && !this.reportIsLoading
    },
  },
  methods: {
    initReportPrinting(obsType) {
      switch (obsType) {
        case 'assessment':
          this.isReportPrint = true
          this.printRef = 'assChild'
          break
        case 'observation':
          this.isReportPrint = true
          this.printRef = 'commonObsChild'
          break
        case 'measurement':
          // Messung Schnee zum Beispiel
          this.isReportPrint = true
          this.printRef = 'measurementChild'
          break
        case 'station':
        case 'snowprofile':
          // Stationsdiagramme und Schneeprofile vom Snowprofiler
          this.isReportPrint = false
          this.printRef = undefined
          break
        default:
          // Als default, wenn etwas anderes kommt als oben erwartet,
          // das normale Printingsymbol für Admins anbieten
          this.isReportPrint = false
          this.printRef = undefined
          break
      }
    },
    reportPrint() {
      if (this.printRef && this.printRef.length > 0) {
        this.$refs[this.printRef].pdfReport()
      } else {
        console.error(
          'Fuer den Typ "' +
            this.type +
            '" existiert keine ReportPrint-Implementierung!'
        )
      }
    },
  },
}

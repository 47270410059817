<template>
  <Menu :moment="moment" :routes="routes"></Menu>
</template>

<script>
import Menu from '../Menu'
import NewSnow from './Newsnow'
import Surface from './Surface'
import Snowline from './Snowline'
import SnowRainAltitude from './SnowRainAltitude'
import { i18n } from '@/plugins/i18n'
import { EnvironmentService } from '@/services/EnvironmentService'

export const Routes = [
  {
    name: 'snow.label.newsnowMeasured',
    path: '/snow/whiterisk/newsnow',
    class: 'fa-solid fa-snowflake',
    isExternal: true,
    beforeEnter(to, from) {
      window.open(
        EnvironmentService.getVariable('WHITERISK_URL') +
          `/${i18n.global.locale}/conditions/measurements/new-snow`,
        '_blank'
      )
      return from
    },
  },

  {
    name: 'snow.label.newsnowObserved',
    path: '/snow/newsnow',
    component: NewSnow,
    meta: { map: true },
    class: 'fa-solid fa-eye',
  },

  {
    name: 'snow.label.snowRainAltitude',
    path: '/snow/snowRainAltitude',
    component: SnowRainAltitude,
    meta: { map: true },
    class: 'fa-solid fa-cloud-rain',
  },

  {
    name: 'measurement.label.hs',
    path: '/snow/whiterisk/snowdepth',
    isExternal: true,
    beforeEnter(to, from) {
      window.open(
        EnvironmentService.getVariable('WHITERISK_URL') +
          `/${i18n.global.locale}/conditions/measurements/snow-depth`,
        '_blank'
      )
      return from
    },
    class: 'fa-solid fa-ruler-vertical',
  },

  {
    name: 'snow.label.snowline',
    path: '/snow/map5',
    component: Snowline,
    meta: {
      map: true,
      main: 'snowline.productMain',
      defaultProductGroupVariant: 'snowline.productVariantNorth',
    },
    class: 'fa-solid fa-mountain',
  },
  {
    name: 'snow.label.snowlineNorthShort',
    path: '/snow/map5',
    component: Snowline,
    meta: {
      map: true,
      main: 'snowline.productMain',
      variant: 'snowline.productVariantNorth',
    },
  },
  {
    name: 'snow.label.snowlineSouthShort',
    path: '/snow/map5',
    component: Snowline,
    meta: {
      map: true,
      main: 'snowline.productMain',
      variant: 'snowline.productVariantSouth',
    },
  },

  {
    name: 'snow.label.snowSurfaceCondition',
    path: '/snow/map3',
    component: Surface,
    meta: {
      map: true,
      main: 'surface.productMain',
      defaultProductGroupVariant: 'surface.productVariantNature',
    },
    class: 'fa-solid fa-stroopwafel',
  },
  {
    name: 'snow.label.surfaceNature',
    path: '/snow/map3',
    component: Surface,
    meta: {
      map: true,
      main: 'surface.productMain',
      variant: 'surface.productVariantNature',
    },
    class: 'fa-solid fa-stroopwafel',
  },
  {
    name: 'snow.label.surfaceHoar',
    path: '/snow/map3',
    component: Surface,
    meta: {
      map: true,
      main: 'surface.productMain',
      variant: 'surface.productVariantHoar',
    },
    class: 'fa-solid fa-stroopwafel',
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snow',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Menu },
  data() {
    return {
      routes: Routes,
    }
  },
}
</script>

<style scoped></style>

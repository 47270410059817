const state = {
  position: null,
}

const actions = {
  setPosition({ commit }, value) {
    commit('SET_POSITION', value)
  },
}

const mutations = {
  SET_POSITION(state, value) {
    state.position = value
  },
}

export default {
  namespaced: true,
  state,
  getters: {},
  actions,
  mutations,
}

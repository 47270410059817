import { createI18n } from 'vue-i18n'
import de from '../locales/de.json'
import fr from '../locales/fr.json'
import it from '../locales/it.json'
import en from '../locales/en.json'

export type KnownLanguages = 'de' | 'fr' | 'it' | 'en'

export const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de: de,
    fr: fr,
    it: it,
    en: en,
  },
})

<template>
  <b-card footer-tag="footer" header-tag="header">
    <template #header>
      <b-row>
        <b-col>
          <b
            >{{ $t(`ui.feedback.measurement.${type}`) }}
            -
            {{
              existing
                ? $t(`ui.feedback.measurement.edit`)
                : $t(`ui.feedback.measurement.create`)
            }}
            ({{ code }})</b
          >
        </b-col>
        <b-col class="text-right" @click="datepicker = !datepicker">
          <span class="date">{{ formattedDate }}</span>
          <span v-show="!isManagmentMode"
            >&nbsp;&nbsp;<font-awesome-icon
              icon="fa-solid fa-calendar-alt"
            ></font-awesome-icon
          ></span>
        </b-col>
      </b-row>
      <b-form class="dateForm" v-if="datepicker && !isManagmentMode">
        <b-container>
          <b-row align-h="center">
            <calendar-component
              inline
              class="calendar-picker"
              v-model="dateString"
              name="datepicker"
              @update:model-value="setDate"
              :disabled-dates="disabledDates"
              :min="minDate"
              :max="maxDate"
              :show-icon="false"
            />
          </b-row>
        </b-container>
      </b-form>
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button type="button" variant="warning" @click="routeBack(true)">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="onSubmit"
            type="button"
            variant="success"
            :disabled="!valid"
          >
            <font-awesome-icon icon="fa-solid fa-paper-plane" />
            {{ $t('ui.send') }}
          </b-button>
        </b-col>
      </b-row>
    </template>

    <b-alert
      v-if="alert || hasActiveTimeserieError"
      show
      dismissible
      variant="warning"
    >
      {{ alert }}
      {{ hasActiveTimeserieError }}
    </b-alert>

    <b-form
      v-if="!alert && hasActiveTimeserieError == null"
      id="stdPlot.input.form"
    >
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('measurement.label.timestamp')">
            <b-form-input
              size="sm"
              type="time"
              v-model="form.time"
              :state="timeState"
              aria-describedby="timeFeedback"
            />
            <b-form-invalid-feedback id="timeFeedback">
              {{ $t('msg.selectValidTime') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <InputHnHnwHsSection
        v-model="form"
        :previous="previous"
        :date="date"
        :studyplot-code="code"
      />

      <b-form-group :label="$t('measurement.label.comment')">
        <template #label>
          <Label
            :text="$t('measurement.label.comment')"
            :info="$t('help.measurement.comment')"
          />
        </template>
        <b-form-textarea
          id="comment"
          v-model="form.comment"
          :rows="3"
          :state="commentState"
          aria-describedby="inputCommentHelp"
          size="lg"
        />
        <b-form-invalid-feedback id="inputCommentHelp">
          {{ $tc('msg.maxCharacters', 200) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>

    <b-modal
      id="modalMeasurementTable"
      :title="$t('measurement.label.measurements') + code"
    >
      <b-table cols="6" striped hover :items="history"></b-table>
    </b-modal>
  </b-card>
</template>

<script>
import moment from 'moment'
import CalendarComponent from '../../calendar/CalendarComponent'
import Label from '../../InfoLabel'
import InputHnHnwHsSection from './InputHnHnwHs'
import { StudyplotService } from '@/services/StudyplotService'
import { HnHnwHsStates } from './HnHnwHsStates'
import { InfoService } from '@/services/InfoService'
import { inputAllowedChecker } from '@/components/studyplot/studyplotInput/InputAllowedChecker'
import { deepClone } from '@/scripts/common'

const JOURNAL_PATH = '/observation/list'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Label, CalendarComponent, InputHnHnwHsSection },
  props: ['code', 'type', 'isManagmentMode', 'createDate'],
  mixins: [HnHnwHsStates, inputAllowedChecker],
  data() {
    return {
      date: new Date(), // this.$store.state.observation.date,
      datepicker: false,
      disabledDates: [],
      minDate: moment()
        .add(-29, 'd')
        .startOf('day') // Damit der Tag vom DatePicker noch selektiert werden kann
        .toISOString(),
      maxDate: moment().toISOString(),
      selection: null,
      form: this.getInitForm(),
      processing: null,
      measurements: [],
      alert: null,
      error: false,
      new: true,
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },

  mounted() {
    if (this.existing) {
      this.getInitFormForUpdateMode()
    } else {
      if (this.isManagmentMode && this.createDate) {
        this.setDate(this.createDate)
      } else {
        this.setDate(moment().format('DD.MM.YYYY'))
      }
    }

    // load all measurement dates
    StudyplotService.getMeasurements(this.code)
      .then((response) => {
        this.measurements = response
        response.forEach((o) => {
          if (o.type === this.type) {
            this.disabledDates.push(moment(o.date).format('YYYY-MM-DD'))
          }
        })
        if (!this.existing) {
          this.checkDate()
        }
      })
      .catch((e) => {
        console.log('Could not read measurements:' + e)
      })
  },

  unmounted() {
    if (!this.isManagmentMode) {
      this.$store.commit('observation/SET_SELECTED', null)
    }
    this.reset()
  },
  computed: {
    hasActiveTimeserieError() {
      return this.checkItHasActiveTimeserieError(
        this.date,
        this.$store.getters['studyplot/getStudyplot'](this.code)
      )
    },
    existing() {
      return this.$store.state.observation.selected
    },
    formattedDate() {
      return moment(this.date).format('DD.MM.YY')
    },
    history() {
      const history = []
      for (let i = 8; i >= 0; i--) {
        const date = moment().add(-i, 'days')
        const m = this.measurements.find(
          (el) => el.date === date.format('YYYY-MM-DD')
        )
        history.push({
          Datum: date.format('DD.MM.YYYY'),
          hs: m ? m.hs : null,
        })
      }
      return history
    },
    previous() {
      const date = moment(this.date).add(
        this.type === 'morning' ? -1 : 0,
        'days'
      )
      const m = this.measurements.find(
        (el) => el.date === date.format('YYYY-MM-DD') && el.type === 'morning'
      )
      return m ? m.hs : '-'
    },
    // Zeit darf nicht in der Zukunft liegen
    timeState() {
      if (
        !/^([0-1]{1}[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}$/.test(
          this.form.time
        )
      )
        return false
      const [hour, minute] = this.form.time.split(':')
      const time = moment(this.date).set({ hour, minute, second: 0 })
      return moment().isAfter(time)
    },
    hnState() {
      return this.getHnState(this.form.hn)
    },
    hnwState() {
      return this.getHnwState(this.form.hnw, this.form.hn)
    },
    hsState() {
      return this.getHsState(this.form.hs)
    },
    commentState() {
      if (!this.form.comment) return null
      return this.form.comment.length <= 200
    },
    // eslint-disable-next-line complexity
    valid() {
      const baseState =
        this.timeState &&
        this.nvl(this.hnState) &&
        this.nvl(this.hnwState) &&
        this.nvl(this.hsState) &&
        this.nvl(this.commentState)
      // mindestens 1 Wert für Beobachter && Admins
      return baseState && (this.hnState || this.hnwState || this.hsState)
    },
    dateString: {
      get() {
        return moment(this.date).format('DD.MM.YYYY')
      },
      set(val) {
        this.date = moment(val, 'DD.MM.YYYY').toDate()
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this
      if (this.existing) {
        this.form.oldDate = this.form.date
      }
      this.form.date = moment(this.date).format('YYYY-MM-DD')
      this.form.hn = this.form.hn ? this.form.hn.replace(',', '.') : null
      if (['.1', '.2', '.4', '0.1', '0.2', '0.4'].includes(this.form.hn)) {
        this.form.hn = '0.3'
      }
      this.$store
        .dispatch(
          'studyplot/' +
            (this.existing ? 'updateMeasurement' : 'addMeasurement'),
          { measurementForm: this.form, isManagmentMode: this.isManagmentMode }
        )
        .then(
          () => {
            InfoService.showSuccessMessage({
              titleKey: 'ui.feedback.studyplotManagement.submitted.title',
              messageKey: 'ui.feedback.studyplotManagement.submitted.body',
              autoHide: true,
            })
            self.processing = false
            self.routeBack()
          },
          (reason) => {
            console.error('Error submitting observation:' + reason)
            self.processing = false
          }
        )
    },
    routeBack(isCancel) {
      if (this.isManagmentMode) {
        this.managementRouteBack(isCancel)
        return
      }
      if (this.routeOrigin.includes(JOURNAL_PATH)) {
        if (this.new) {
          this.$router.push({
            path: JOURNAL_PATH,
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          const measurement = this.$store.state.observation.selected.measurement
          this.$router.push({
            path: JOURNAL_PATH,
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
              openObs:
                'studyplot.' +
                measurement.studyplot +
                '.' +
                measurement.type +
                '.' +
                measurement.date,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    managementRouteBack(isCancel) {
      if (!isCancel) {
        this.$emit('submitted')
        return
      }
      this.$router.push({
        path: JOURNAL_PATH,
      })
    },
    reset() {
      this.form = this.getInitForm()
    },
    // eslint-disable-next-line complexity
    getInitFormForUpdateMode() {
      this.new = false
      this.form = deepClone(this.$store.state.observation.selected.measurement)
      this.form.hn = this.form.hn != null ? this.form.hn.toString() : null
      this.form.hnw = this.form.hnw != null ? this.form.hnw.toString() : null
      this.form.hs = this.form.hs != null ? this.form.hs.toString() : null
      this.form.oldMeasures = {
        hn: this.form.hn,
        hnw: this.form.hnw,
        hs: this.form.hs,
      }
      this.date = new Date(this.form.date)
    },
    getInitForm() {
      return {
        type: this.type,
        studyplot: this.code,
        time: '07:30',
        hn: null,
        hnw: null,
        hs: null,
        comment: null,
        oldMeasures: {
          hn: null,
          hnw: null,
          hs: null,
        },
      }
    },
    setDate(date) {
      this.dateString = date
      this.checkDate()
      this.datepicker = false
      this.$store.commit('observation/SET_DATE', this.date)
    },
    checkDate() {
      const ts = moment(this.date).startOf('day').unix() * 1000
      if (
        this.disabledDates.find((d) => {
          return moment(d).valueOf() === ts
        })
      ) {
        this.alert = this.$t('measurement.label.duplicate', {
          date: moment(this.date).format('DD.MM.YYYY'),
        })
      } else {
        this.alert = null
      }
    },
  },
}
</script>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.disabled {
  color: #acacac;
}
</style>

<template>
  <Menu :moment="moment" :routes="routes"></Menu>
</template>

<script>
import Menu from '../Menu'
import DangerMap from './Dangermap'
import ProblemMap from './ProblemMap'

// NOTE (20200116/csz): distinction between admin (danger) and normal user (bulletin) possibilities until normal
// users not allowed to see danger items (prelim. forecast 2020/2021)
// IMPORTANT (20200117/csz):
// Keycloak initialization and user set in store finishes AFTER VueRouter (and routes) set. Therefore, a distinction
// based on admin flag not done here but rather in Navigation.vue (the pragmatic way...)

// NOTE: product route entries w/o product variant don't need grouping by 'main' attribute and product variant distinguishing by 'variant' attribute
// NOTE: 'defaultGeneralVariant' used to define variant that should appear when navigated to main menu this menu item is part of (general default)
//       while 'defaultProductGroupVariant' designates the default within one menu item group (local default)
export const Routes = [
  {
    name: 'danger.label.productMainEstimate',
    path: '/danger/danger',
    component: DangerMap,
    meta: {
      map: true,
      defaultGeneralVariant: 'danger.productVariantAll',
      main: 'danger.productMainEstimate',
      defaultProductGroupVariant: 'danger.productVariantAll',
    },
    class: 'fa-solid fa-sort-amount-up-alt',
  },
  {
    name: 'danger.label.productVariantAll',
    path: '/danger/danger',
    component: DangerMap,
    meta: {
      map: true,
      main: 'danger.productMainEstimate',
      variant: 'danger.productVariantAll',
    },
  },
  {
    name: 'danger.label.productVariantDry',
    path: '/danger/danger',
    component: DangerMap,
    meta: {
      map: true,
      main: 'danger.productMainEstimate',
      variant: 'danger.productVariantDry',
    },
  },
  {
    name: 'danger.label.productVariantWet',
    path: '/danger/danger',
    component: DangerMap,
    meta: {
      map: true,
      main: 'danger.productMainEstimate',
      variant: 'danger.productVariantWet',
    },
  },
  {
    name: 'danger.label.productMainProblem',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      defaultProductGroupVariant: 'problem.productVariantOldSnow',
    },
    class: 'fa-solid fa-bomb',
  },
  {
    name: 'danger.label.productVariantNewSnow',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantNewSnow',
    },
  },
  {
    name: 'danger.label.productVariantSnowDrift',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantSnowDrift',
    },
  },
  {
    name: 'danger.label.productVariantOldSnow',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantOldSnow',
    },
  },
  {
    name: 'danger.label.productVariantUndefined',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantUndefined',
    },
  },
  {
    name: 'danger.label.productVariantWetSnow',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantWetSnow',
    },
  },
  {
    name: 'danger.label.productVariantFullDepth',
    path: '/danger/problem',
    component: ProblemMap,
    meta: {
      map: true,
      main: 'problem.productMainProblem',
      variant: 'problem.productVariantFullDepth',
    },
  },
]
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Danger',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Menu },
  data() {
    return {
      routes: Routes,
    }
  },
}
</script>

<style scoped></style>

import BaseService from '@/services/BaseService'
import { AxiosInstance, AxiosResponse } from 'axios'
import { FeatureCollection, Point } from 'geojson'
import { CRS } from '@/model/geoJsonModels'
import { getCancelToken, WrappedAxiosCanceler } from '@/services/axiosUtils'

const TOLERANCE_IN_MIN = 120

export type AggregationType = 'min' | 'max' | 'mean' | 'sum'

export interface TimepointDataAtStationGeoJson
  extends FeatureCollection<Point, TimepointDataAtStationGeoJsonProperties> {
  crs: CRS
}

export interface AggregatedDataAtStationGeoJson
  extends FeatureCollection<Point, AggregatedDataAtStationGeoJsonProperties> {
  crs: CRS
}

export interface BaseDataAtStationGeoJsonProperties {
  id: number
  network: string
  label: string
  code: string
  value: number | null
}

export interface TimepointDataAtStationGeoJsonProperties
  extends BaseDataAtStationGeoJsonProperties {
  measureDate: string | null
}

export interface AggregatedDataAtStationGeoJsonProperties
  extends BaseDataAtStationGeoJsonProperties {
  count: number
  minMeasureDate: string | null
  maxMeasureDate: string | null
}

export class MeasurementMapService {
  private static _baseService?: BaseService

  private static get instance(): AxiosInstance {
    const baseService =
      this._baseService || new BaseService('MEASUREMENT_MAP_SERVICE_URL', 5000)
    this._baseService = baseService
    return baseService.instance
  }

  public static async getDataAtTimepoint(
    measurementParameter: string,
    startDate: string,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<TimepointDataAtStationGeoJson> {
    const result: AxiosResponse<TimepointDataAtStationGeoJson> =
      await this.instance.get(
        `/public/station-data/timepoint/${measurementParameter}/geojson`,
        {
          params: {
            startDate,
            toleranceInMin: TOLERANCE_IN_MIN,
          },
          cancelToken: getCancelToken(wrappedAxiosCanceler),
        }
      )
    return result.data
  }

  public static async getAggregatedData(
    aggregationType: AggregationType,
    measurementParameter: string,
    startDate: string,
    endDate: string,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<AggregatedDataAtStationGeoJson> {
    const result: AxiosResponse<AggregatedDataAtStationGeoJson> =
      await this.instance.get(
        `/public/station-data/agg/${aggregationType}/${measurementParameter}/geojson`,
        {
          params: {
            startDate,
            endDate,
            toleranceInMin: TOLERANCE_IN_MIN,
          },
          cancelToken: getCancelToken(wrappedAxiosCanceler),
        }
      )
    return result.data
  }
}

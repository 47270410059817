import axios from 'axios'
import { KeycloakAuthentication } from '@/plugins/KeycloakAuthentication'
import { EnvironmentService } from '@/services/EnvironmentService'

export default class BaseService {
  constructor(
    name,
    timeout,
    contentType = 'application/json',
    responseType,
    auth = true
  ) {
    const baseURL = EnvironmentService.getVariable(name)
    this.instance = axios.create({
      baseURL,
      timeout,
      headers: {
        Accept: contentType,
        'Content-Type': contentType === null ? undefined : contentType,
      },
      ...(responseType && { responseType }),
    })
    if (auth) {
      this.instance.interceptors.request.use(
        (config) => {
          if (KeycloakAuthentication.getKeycloakToken() && config.headers) {
            config.headers.Authorization = `Bearer ${KeycloakAuthentication.getKeycloakToken()}`
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }
  getInstance() {
    return this.instance
  }
}

<template>
  <div v-show="visible" :class="{ legend: !mobile, legend_mobile: mobile }">
    <b>{{ $t('avalanche.label.avalancheMoisture') }}</b>
    <b-row>
      <b-col cols="3"><div v-html="symbols.no"></div></b-col>
      <b-col>{{ $t('avalanche.no') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3"><div v-html="symbols.dry"></div></b-col>
      <b-col>{{ $t('avalanche.avalancheMoisture.DRY') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3"><div v-html="symbols.wet"></div></b-col>
      <b-col>{{ $t('avalanche.avalancheMoisture.WET') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3"><div v-html="symbols.mixed"></div></b-col>
      <b-col>{{ $t('avalanche.avalancheMoisture.MIXED') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3"><div v-html="symbols.unknown"></div></b-col>
      <b-col>{{ $t('avalanche.avalancheMoisture.UNKNOWN') }}</b-col>
    </b-row>
    <b>{{ $t('avalanche.label.startZoneElevation') }}</b>
    <b-row>
      <b-col cols="3">21</b-col>
      <b-col>2100 {{ $t('common.unit.meterAboveSeaLevel') }}</b-col>
    </b-row>
    <b>{{ $t('avalanche.label.triggerType') }}</b>
    <b-row>
      <b-col cols="3">!</b-col>
      <b-col>{{ $t('ui.mapLabel.withNatural') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3">P</b-col>
      <b-col>{{ $t('ui.mapLabel.withPersons') }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="3">E</b-col>
      <b-col>{{ $t('ui.mapLabel.onlyRatrac') }}</b-col>
    </b-row>
    <b>{{ $t('ui.mapLabel.activityIndex') }}</b>
    <b-row>
      <b-col cols="4"><div id="aai1"></div></b-col>
      <b-col>{{ text.aai1 }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div id="aai2"></div></b-col>
      <b-col>{{ text.aai2 }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4"><div id="aai3"></div></b-col>
      <b-col>{{ text.aai3 }}</b-col>
    </b-row>
  </div>
</template>

<script>
import { AvalancheStyleMap } from '../styles/avalancheStyling/AvalancheStyleMap.js'
import emitter from '@/components/EventHub'

export default {
  name: 'AvalancheLegend',
  props: ['features'],
  data() {
    return {
      symbols: {},
      text: { aai1: ' ', aai2: ' ', aai3: ' ' },
      style: null,
      aai1Div: null,
      aai2Div: null,
      aai3Div: null,
      visible: false,
      mobile: false,
    }
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      // Legend neu initial auch auf Desktop geschlossen / SLPPWB-804 Legende per Default geschlossen
      this.visible = false
    } else {
      this.mobile = true
    }
  },
  mounted() {
    this.style = new AvalancheStyleMap()
    this.symbols = this.style.getLegendSymbols()
    this.aai1Div = document.getElementById('aai1')
    this.aai2Div = document.getElementById('aai2')
    this.aai3Div = document.getElementById('aai3')
    const self = this
    emitter.on('showLegend', (show) => {
      show ? (self.visible = true) : (self.visible = false)
    })
    this.setDynamicElements(this.style.legendFunction())
  },
  beforeUnmount() {
    emitter.off('showLegend')
  },
  methods: {
    setDynamicElements(activityIndexObject) {
      this.aai1Div.innerHTML = ''
      if (this.aai2Div) {
        this.aai2Div.innerHTML = ''
      }
      if (this.aai3Div) {
        this.aai3Div.innerHTML = ''
      }
      this.text.aai2 = ''
      this.text.aai3 = ''
      if (activityIndexObject.symbols.length > 0) {
        this.text.aai1 = activityIndexObject.text[0]
        this.aai1Div.appendChild(activityIndexObject.symbols[0])
      }
      if (activityIndexObject.symbols.length > 1) {
        this.aai2Div.appendChild(activityIndexObject.symbols[1])
        this.text.aai2 = activityIndexObject.text[1]
      }
      if (activityIndexObject.symbols.length > 2) {
        this.aai3Div.appendChild(activityIndexObject.symbols[2])
        this.text.aai3 = activityIndexObject.text[2]
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
.symbol {
  width: 25px;
  margin-right: 1em;
}
.symbol-rosette {
  width: 50px;
  margin-right: 1em;
}
.table {
  margin-top: 0;
}
.label {
  font-size: 14px;
}

.legend-title {
  caption-side: top;
  font-weight: bolder;
  color: black;
  padding-top: 5px;
  padding-bottom: 0;
}

hr {
  background-color: black;
  color: black;
  border: black;
  height: 1px;
  margin: 10px 0 10px 0;
}
</style>

<template>
  <div class="detailHeader">
    <b-card no-body class="mb-4">
      <!-- Header section -->
      <b-card-text>
        <b-container>
          <b-row>
            <b-col cols="12" class="headerLines">
              <b-container v-if="isAdmin">
                <b-row v-if="phone">
                  <b-col cols="4" class="label">{{
                    $t('user.label.phone')
                  }}</b-col>
                  <b-col cols="8">{{ phone }}</b-col>
                </b-row>
                <b-row v-if="email">
                  <b-col cols="4" class="label">{{
                    $t('user.label.email')
                  }}</b-col>
                  <b-col cols="8">{{ email }}</b-col>
                </b-row>
                <b-row v-if="education">
                  <b-col cols="4" class="label">{{
                    $t('user.label.education')
                  }}</b-col>
                  <b-col cols="8">{{ education }}</b-col>
                </b-row>
                <b-row class="mb-2" />
                <!-- Distanzrow zum nächsten Container / mb-1 = Margin-Bottom-->
              </b-container>
              <b-container>
                <b-row v-if="place">
                  <b-col cols="4" class="label">{{
                    $t('ui.header.place')
                  }}</b-col>
                  <b-col cols="8">{{ place }}</b-col>
                </b-row>
                <b-row v-if="coord">
                  <b-col cols="4" class="label">{{ $t('common.coord') }}</b-col>
                  <b-col cols="8"
                    ><b-link
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="geoSearchUrl"
                      >{{ coord }}</b-link
                    ></b-col
                  >
                </b-row>
                <b-row v-if="avalSafetyService">
                  <b-col cols="4" class="label">{{
                    $t('assessment.ui.header.avalSafetyService')
                  }}</b-col>
                  <b-col cols="8">{{ avalSafetyService }}</b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { EnvironmentService } from '@/services/EnvironmentService'

export default {
  name: 'DetailHeader',
  props: ['headerInfos'],
  data() {
    return {
      isAdmin: this.$store.state.user.user.isAdmin,
      phone: this.headerInfos.phone,
      email: this.headerInfos.email,
      education: this.headerInfos.education,
      place: this.headerInfos.place,
      coord: this.headerInfos.coord,
      avalSafetyService: this.headerInfos.avalSafetyService,
    }
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    geoSearchUrl() {
      if (!this.headerInfos.coord) {
        return ''
      }
      const intCoord = this.headerInfos.coord.split('/')
      if (intCoord && intCoord.length === 2) {
        return (
          EnvironmentService.getVariable('GEOADMIN_WEB_URL') +
          '/?swisssearch=' +
          intCoord[0] +
          ',' +
          intCoord[1]
        )
      } else {
        return ''
      }
    },
  },
  watch: {
    headerInfos: {
      handler() {
        this.phone = this.headerInfos.phone
        this.email = this.headerInfos.email
        this.education = this.headerInfos.education
        this.place = this.headerInfos.place
        this.coord = this.headerInfos.coord
        this.avalSafetyService = this.headerInfos.avalSafetyService
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.headerLines {
  font: 14px Arial, Helvetica, sans-serif;
  padding: 8px 0;
}
.headerLines .label {
  font-weight: 550;
}
</style>

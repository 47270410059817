import { enums } from '../I18N'

/* Setzt voraus, dass die Styles unter MeasureTable.vue zur verfügung stehen.
  Dieses Mixin ist für MeasureTable.vue und WarningLightTable.vue konzipiert */
export const MEASURE_DECISION_CLASS = {
  [enums.measure.path[0]]: 'bg-green',
  [enums.measure.path[1]]: 'bg-red',
  [enums.measure.path[2]]: 'bg-red',
  [enums.measure.building[0]]: 'bg-green',
  [enums.measure.building[1]]: 'bg-red',
  [enums.measure.building[2]]: 'bg-red',
  [enums.measure.warningLight[0]]: 'bg-yellow',
  [enums.measure.warningLight[1]]: 'bg-green',
}

export const MeasureTableMixin = {
  data() {
    return {
      assessment: {},
    }
  },

  methods: {
    formatTimestamp(value, nullText) {
      return value ? value.format('DD.MM.YYYY HH:mm') : nullText
    },
    formatMeasure(clazz, measure) {
      return clazz && measure ? this.$i18n.t(`measure.${clazz}.${measure}`) : ''
    },
    findClass(measure) {
      return MEASURE_DECISION_CLASS[measure] || ''
    },
  },
}

<script>
import { SnowdriftStyle } from '../styles/dangersignStyling/SnowdriftStyle'
import { SNOWDRIFT } from '@/scripts/const.js'
import AbstractMapView from '../observation/AbstractMapView'
import { Canceler } from '@/scripts/requestUtil.js'

const canceler = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snowdrift',
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: AbstractMapView,
  data() {
    return {}
  },
  methods: {
    setIndividualProps() {
      this.style = new SnowdriftStyle()
      this.legend = this.style.legend()
      this.typ = SNOWDRIFT
      this.aggregate = true
    },
    getObservationData(obsDate) {
      console.debug('Cancel requests on load')
      this.cancelRequests()
      const self = this
      return new Promise(function (resolve) {
        self.$store
          .dispatch(
            'dangersign/loadDangersignObservationsByObservationDateRange',
            { typ: SNOWDRIFT, obsDate: obsDate, cancel: canceler }
          )
          .then((result) => {
            resolve(result)
          })
      })
    },
    needsResolutionChangeHandler() {
      return true
    },

    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },
  },

  beforeUnmount() {
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },
}
</script>

<style lang="css" scoped src="../observation/AbstractMapView.css"></style>

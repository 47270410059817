<template>
  <b-row>
    <b-col md="6" v-if="myValue">
      <b-form-group :label="$t('assessment.common.label.area')">
        <b-form-select
          :options="areas"
          v-model="myValue.areaId"
          class="area-input"
        ></b-form-select>
      </b-form-group>
    </b-col>
    <b-col md="6" v-if="!(disableSector && disableSector === true) && myValue">
      <b-form-group :label="$t('assessment.common.label.sector')">
        <b-form-select
          :options="sectors"
          required
          v-model="myValue.sectorId"
          class="sector-input"
        ></b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { i18n } from '@/plugins/i18n'

// NOTE (20200506/csz): using v-model rather than 1-way :initialLocation attribute; otherwise would need to set location in store from here
// w/o any OK button -> if user cancels form afterwards, these settings would remain => handling location set in store from
// parent and thus introducing more dependencies... (when using events rather than v-model, initialization wouldn't work => staying w/ v-model)
export default {
  name: 'AreaSectorInput',
  props: ['modelValue', 'ldId', 'disableSector'],
  emits: ['update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      oldAreaId: null,
      myValue: this.modelValue,
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.myValue = this.modelValue
      },
      deep: true,
    },
    myValue: {
      handler() {
        if (this.myValue && this.myValue.areaId !== this.oldAreaId) {
          if (this.oldAreaId != null) {
            this.myValue.sectorId = null
          }
          this.oldAreaId = this.myValue.areaId
        }

        this.$nextTick(function () {
          this.$emit('update:modelValue', this.myValue)
        })
      },
      deep: true,
    },
    areas: {
      handler() {
        // wenn genau ein Eintrag (und der Platzhalter) vorhanden ist,
        // wähle diesen aus
        if (this.areas.length === 2 && this.myValue) {
          this.myValue.areaId = this.areas[1].value
        }
      },
      immediate: true,
      deep: true,
    },
    sectors: {
      handler() {
        // wenn genau ein Eintrag (und der Platzhalter) vorhanden ist,
        // wähle diesen aus
        if (this.sectors.length === 2 && this.myValue) {
          this.myValue.sectorId = this.sectors[1].value
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    areas() {
      const areaList = this.$store.getters['profile/getAreasToAvalServiceId'](
        this.ldId
      )
      if (areaList) {
        const options = areaList
          .filter((item) => item.id && item.name)
          .map((item) => {
            return { text: item.name, value: item.id }
          })
        options.sort((a, b) => a.text.localeCompare(b.text))

        return [{ text: i18n.global.t('ui.select'), value: null }].concat(
          options
        )
      }
      return []
    },
    sectors() {
      const sectorList = this.$store.getters['profile/getSectorsToAreaId'](
        this.myValue?.areaId
      )
      if (sectorList) {
        const options = sectorList
          .filter((item) => item.id && item.name)
          .map((item) => {
            return { text: item.name, value: item.id }
          })
        options.sort((a, b) => a.text.localeCompare(b.text))

        return [{ text: i18n.global.t('ui.select'), value: null }].concat(
          options
        )
      }
      return []
    },
  },
}
</script>

<style scoped></style>

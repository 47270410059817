import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../components/Home'
import Ticker from '../components/ticker/Ticker'
import Observation, {
  Routes as ObservationRoutes,
} from '../components/observation/Observation'
import Snow, { Routes as SnowRoutes } from '../components/snow/Snow'
import Dangersign, {
  Routes as DangersignRoutes,
} from '../components/dangersign/Dangersign'
import Danger, { Routes as DangerRoutes } from '../components/danger/Danger'
import Avalanche, {
  Routes as AvalancheRoutes,
} from '../components/avalanche/Avalanche'
import Settings, {
  Routes as SettingsRoutes,
} from '../components/settings/Settings'
import Measure, { Routes as MeasureRoutes } from '../components/measure/Measure'
import emitter from '@/components/EventHub'

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.el = to.hash

      if (document.querySelector(to.hash)) {
        return position
      }
      // if the returned position is falsy or an empty object, will retain current scroll position.
      return false
    }

    // Wenn es wieder die gleiche Route ist, dann nicht zum Top scrollen
    if (to?.path === from?.path) {
      return false
    }

    return {
      left: 0,
      top: 0,
    }
  }
}

const routes = [
  { path: '/', component: Home },
  {
    path: '/observation',
    component: Observation,
    children: ObservationRoutes,
  },
  { path: '/ticker', component: Ticker },
  { name: 'snow', path: '/snow', component: Snow, children: SnowRoutes },
  {
    name: 'dangersign',
    path: '/dangersign',
    component: Dangersign,
    children: DangersignRoutes,
  },
  {
    name: 'danger',
    path: '/danger',
    component: Danger,
    children: DangerRoutes,
  },
  {
    name: 'measure',
    path: '/measure',
    component: Measure,
    children: MeasureRoutes,
  },
  {
    name: 'avalanche',
    path: '/avalanche',
    component: Avalanche,
    children: AvalancheRoutes,
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    children: SettingsRoutes,
  },
]

let appRouter
const getOrInitRouterInstance = () => {
  if (appRouter == null) {
    appRouter = createRouter({
      history: createWebHashHistory(),
      scrollBehavior,
      routes,
    })
    appRouter.afterEach((to) => {
      if (!to?.meta?.map) {
        emitter.emit('layer', false)
      }
    })
  }
  return appRouter
}
export { getOrInitRouterInstance }

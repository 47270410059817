<script>
import ObservationDetail from '../observation/ObservationDetail'
import { DETAIL_COMPONENTS } from '@/scripts/const'

export default {
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: ObservationDetail,
  name: 'MeasurementDetail',
  data() {
    return {}
  },
  methods: {
    showOrAggregateObservation() {
      if (this.aval) {
        this.components = []
        this.obs = []
        this.obs.push(this.aval)
        this.importComponent(DETAIL_COMPONENTS[3])
        this.loadUserData(this.getHeaderInfo(this.aval.uid))
        this.mdetailDate()
      }
    },
    getHeaderInfo(uid) {
      return {
        uid: uid,
        coord: undefined,
        place: undefined,
      }
    },
  },
}
</script>

<style lang="css " scoped src="../observation/ObservationDetail.css"></style>

'use strict'
import Style from 'ol/style/Style'
import { AbstractAvalancheStyle } from './AbstractAvalancheStyle'
import { Circle, Fill, Stroke } from 'ol/style.js'
import Point from 'ol/geom/Point'
import { toContext } from 'ol/render'
import { AbstractStyle } from '../AbstractStyle'
import { i18n } from '@/plugins/i18n'
import moment from 'moment'
import { getAvalSymbolText } from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'
import { HUMIDITY_STYLES } from '@/components/styles/avalancheStyling/AvalancheCommonStyling'
import ActivityIndexStyleFactory from '@/components/styles/avalancheStyling/ActivityIndexStyleFactory'

export class AvalancheStyleMap extends AbstractAvalancheStyle {
  styleFunction(feature, resolution) {
    return super.styleFunction(feature, resolution, getLabelStyle, false)
  }

  legendFunction() {
    const canvasArray = []
    const textArray = []
    const legendValues = [0.1, 5, 10]
    const legendTextToValues = ['0 - 0.49', '5 - 7.99', '>=10']
    const maxRadius = ActivityIndexStyleFactory.getRadiusForActivityIndex(100)

    legendValues.forEach((value, idx) => {
      textArray.push(legendTextToValues[idx])
      const radius = ActivityIndexStyleFactory.getRadiusForActivityIndex(value)
      const newStyle = new Style({
        image: new Circle({
          stroke: new Stroke({
            color: 'rgba(125, 125, 125, 1.2)',
            width: 1,
          }),
          fill: new Fill({ color: 'rgba(125, 125, 125, 0.6)' }),
          radius: radius,
        }),
      })
      const canvas = document.createElement('canvas')
      const vectorContext = toContext(canvas.getContext('2d'), {
        size: [maxRadius * 2 + 4, radius * 2 + 4],
      })
      vectorContext.setStyle(newStyle)
      vectorContext.drawGeometry(new Point([maxRadius + 1, radius + 1]))
      canvasArray.push(canvas)
    })

    return { symbols: canvasArray, text: textArray }
  }
  /*eslint-disable complexity*/
  mouseOverFunction(feature) {
    let sizes = ''
    let i = 0
    const avalanche = feature.get('avalanche')
    let htmlString = ''
    if (avalanche) {
      avalanche.sizeArray.forEach((size) => {
        sizes = sizes.concat(size)
        if (i < 4) {
          sizes = sizes.concat('/')
        }
        i++
      })
      htmlString =
        i18n.global.t('avalanche.label.sizes') + ': ' + sizes + '<br>'
      htmlString = htmlString.concat(
        i18n.global.t('avalanche.label.avalancheType') + ':'
      )
      if (avalanche.avalancheTypes.indexOf('SLAB') > -1) {
        htmlString = htmlString.concat(getTypeSymbol('SLAB'))
      }
      if (avalanche.avalancheTypes.indexOf('LOOSE_SNOW') > -1) {
        htmlString = htmlString.concat(getTypeSymbol('LOOSE_SNOW'))
      }
      if (avalanche.avalancheTypes.indexOf('FULL_DEPTH') > -1) {
        htmlString = htmlString.concat(getTypeSymbol('FULL_DEPTH'))
      }
      htmlString = htmlString.concat('<br>')
      let mouseOverText = ''
      const maxMouseOverText = 200
      avalanche.noteTexts.forEach((text) => {
        if (text && mouseOverText.length <= maxMouseOverText) {
          mouseOverText = mouseOverText.concat(text)
          if (mouseOverText.length >= maxMouseOverText) {
            mouseOverText = mouseOverText
              .substr(0, maxMouseOverText)
              .concat('...')
          }
          mouseOverText = mouseOverText.concat('<br>')
        }
      })
      htmlString = htmlString.concat(mouseOverText)
      htmlString = htmlString.concat('AAI ' + avalanche.activityIndex)
      let globalTitle = '' // for cluster no date
      if (feature.get('features') && feature.get('features').length < 2) {
        const avalFromFeature = feature.get('features')[0].get('avalanche')
        globalTitle =
          globalTitle +
          AbstractStyle.getMouseOverUsername(feature.get('features')[0])

        const avalTriggerDate = avalFromFeature.triggerTime
          ? moment
              .utc(
                avalFromFeature.triggerDate + 'T' + avalFromFeature.triggerTime,
                'YYYY-MM-DDTHH:mm'
              )
              .local()
              .format('DD.MM.YYYY')
          : moment(avalFromFeature.triggerDate).format('DD.MM.YYYY')
        globalTitle =
          globalTitle +
          '<div>' +
          i18n.global.t('avalanche.label.triggerDate') +
          ': ' +
          avalTriggerDate +
          '</div>'
      }
      return globalTitle.concat(htmlString)
    } else {
      const avalancheRelease = feature.get('avalancheRelease')
      if (avalancheRelease) {
        const isAvalRelease = avalancheRelease.value
          ? i18n.global.t('common.flag.true')
          : i18n.global.t('common.flag.false')
        htmlString =
          '<div>' +
          i18n.global.t('dangersign.label.avalRelease') +
          ':  ' +
          isAvalRelease +
          '</div>'
      }
      if (feature.get('features') && feature.get('features').length < 2) {
        return super
          .getMouseOverTitel(feature.get('features')[0])
          .concat(htmlString)
      } else {
        return htmlString
      }
    }
  }
  /*eslint-enable complexity*/
  getLegendSymbols() {
    return {
      no: getMoistureSymbol('NO'),
      dry: getMoistureSymbol('DRY'),
      wet: getMoistureSymbol('WET'),
      mixed: getMoistureSymbol('MIXED'),
      unknown: getMoistureSymbol('UNKNOWN'),
    }
  }
}

export const getLabelStyle = function (
  avalanche,
  geometry,
  textOffsetY,
  textOffsetX
) {
  return new Style({
    text: getText(avalanche, geometry, textOffsetY, textOffsetX),
    zIndex: Infinity,
  })
}

const getText = function (avalanche, geometry, textOffsetY, textOffsetX) {
  if (!avalanche) return null
  const text = createText(avalanche)
  return getAvalSymbolText(text, textOffsetX, textOffsetY)
}

const createText = function (avalanche) {
  let text = trunc(avalanche.startZoneElevationRange[0])
  if (avalanche.startZoneElevationRange.length > 2) {
    text = text + ' - ' + trunc(avalanche.startZoneElevationRange[1])
  }
  const triggers = getTriggerText(avalanche)
  text = text + ' ' + triggers
  return text
}

const trunc = function (value) {
  return value ? Math.round(value / 100) : ''
}

const getTriggerText = function (avalanche) {
  let label = ''
  const triggers = avalanche.triggerTypes
  if (triggers.indexOf('NATURAL') !== -1) {
    label += '!'
  }
  if (triggers.indexOf('PERSON') !== -1) {
    label += 'P'
  }
  if (label === '') {
    if (
      triggers.indexOf('EXPLOSIVE') !== -1 ||
      triggers.indexOf('SNOW_GROOMER') !== -1
    ) {
      label += 'E'
    }
  }
  return label
}

const getMoistureSymbol = function (type) {
  const color = HUMIDITY_STYLES[type].stroke_color
  const colorStroke = HUMIDITY_STYLES[type].stroke
  const strokeWidth = HUMIDITY_STYLES[type].stroke_width
  const svgNS = 'http://www.w3.org/2000/svg'
  const svgEl = document.createElementNS(svgNS, 'svg')
  svgEl.setAttribute('class', 'mouseover-symbol')
  const circle = document.createElementNS(svgNS, 'circle')
  circle.setAttributeNS(null, 'cx', LEGEND_SYMBOL_RADIUS + 1)
  circle.setAttributeNS(null, 'cy', LEGEND_SYMBOL_RADIUS + 1)
  circle.setAttributeNS(null, 'r', LEGEND_SYMBOL_RADIUS)
  circle.setAttributeNS(
    null,
    'style',
    'fill:' +
      color +
      '; stroke: ' +
      colorStroke +
      '; stroke-width=' +
      strokeWidth +
      ';'
  )
  svgEl.appendChild(circle)
  return svgEl.outerHTML
}

const getTypeSymbol = function (type) {
  const svgNS = 'http://www.w3.org/2000/svg'
  const svgEl = document.createElementNS(svgNS, 'svg')
  svgEl.innerHTML = TYPE_SYMBOLS[type]
  svgEl.setAttribute('class', 'mouseover-symbol')
  return svgEl.outerHTML
}

const LEGEND_SYMBOL_RADIUS = 7
const TYPE_SYMBOLS = {
  SLAB: '<path d="M 2 16 V 6 H 22 V 16" stroke="black" fill="none"/>',
  LOOSE_SNOW: '<path d="M 2 16 L 10 1 L 20 16" stroke="black" fill="none"/>',
  FULL_DEPTH:
    '<path d="M 4 16 V 6 H 24 V 16 M 1 11 H 27" stroke="black" fill="none"/>',
}

import BaseService from '@/services/BaseService'
import axios, { AxiosInstance, AxiosResponse, Canceler } from 'axios'
import { DateTime } from 'luxon'
import { components } from '@/model/generated/studyplotApi'

export interface WrappedAxiosCanceler {
  cancel: Canceler | null
}

export type StudyplotLocation = components['schemas']['Location']
export type StudyplotTimeseries = components['schemas']['StudyplotTimeseries']
export type Studyplot = components['schemas']['Studyplot']
export type Measurement = components['schemas']['Measurement']
export type ManagedMeasurement = components['schemas']['ManagedMeasurement']
export type MeasurementBody = components['schemas']['MeasurementBody']
export type MeasurementUpdateBody =
  components['schemas']['MeasurementUpdateBody']
export type MeasurementBodyManagement =
  components['schemas']['MeasurementBodyManagement']

function localToUtc<T extends { date: string; time?: string | null }>(
  withTimeAsLocal: T
): T {
  const { date, time } = withTimeAsLocal
  const dateTimeFormat = time != null ? `${date}T${time}` : `${date}`

  const timeAsUtc = DateTime.fromISO(dateTimeFormat)
    .toUTC()
    .toISOTime({ suppressMilliseconds: true, includeOffset: false })

  const withTimeAsUtc = Object.assign({}, withTimeAsLocal)
  withTimeAsUtc.time = timeAsUtc
  return withTimeAsUtc
}

function utcToLocal<T extends { date: string; time: string }>(
  withTimeAsUtc: T
): T {
  const { date, time } = withTimeAsUtc

  const timeAsUtc = DateTime.fromISO(`${date}T${time}Z`)
    .toLocal()
    .toISOTime({ suppressSeconds: true, includeOffset: false })

  if (timeAsUtc == null) {
    throw new Error(
      `utcToLocal konnte das übergebene Datum ${date}/${time} nicht konvertieren`
    )
  }
  const withTimeAsLocal = Object.assign({}, withTimeAsUtc)
  withTimeAsLocal.time = timeAsUtc
  return withTimeAsLocal
}

export class StudyplotService {
  private static _baseService?: BaseService

  private static get instance(): AxiosInstance {
    const baseService =
      this._baseService || new BaseService('STUDYPLOT_SERVICE_URL', 5000)
    this._baseService = baseService
    return baseService.instance
  }

  private static getCancelToken(wrappedAxiosCanceler?: WrappedAxiosCanceler) {
    return new axios.CancelToken(function executor(c) {
      if (wrappedAxiosCanceler) {
        wrappedAxiosCanceler.cancel = c
      }
    })
  }

  public static async getStudyplots(): Promise<Array<Studyplot>> {
    return this.instance.get('/studyplots')
  }

  public static async getStudyplotByCode(code: string): Promise<Studyplot> {
    const result: AxiosResponse<Studyplot> = await this.instance.get(
      `/studyplots/${code}`
    )
    return result.data
  }

  public static async getMeasurements(code: string): Promise<Measurement[]> {
    const result: AxiosResponse<Measurement[]> = await this.instance.get(
      `/studyplots/${code}/measurements`
    )
    return result.data.map((measurement) => utcToLocal(measurement))
  }

  public static async getMeasurementsToDate(
    code: string,
    date: DateTime
  ): Promise<Measurement[]> {
    const result: AxiosResponse<Measurement[]> = await this.instance.get(
      `/studyplots/${code}/measurements`,
      {
        params: {
          date: date.toISODate(),
          range: 0,
        },
      }
    )
    return result.data.map((measurement) => utcToLocal(measurement))
  }

  public static async findMeasurements(
    uid: string,
    date: DateTime,
    range: number,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<ManagedMeasurement[]> {
    const result: AxiosResponse<ManagedMeasurement[]> = await this.instance.get(
      `/studyplots/findMeasurements`,
      {
        params: {
          uid,
          date: date.toISODate(),
          range,
        },
        cancelToken: this.getCancelToken(wrappedAxiosCanceler),
      }
    )
    return result.data.map((measurement) => utcToLocal(measurement))
  }

  public static async addMeasurement(
    measurement: MeasurementBody | MeasurementBodyManagement,
    isManagmentMode: boolean,
    studyplotCode: string
  ): Promise<void> {
    const utcMeasurement = localToUtc(measurement)
    if (!isManagmentMode) {
      return this.addMeasurementCommon(
        utcMeasurement as MeasurementBody,
        studyplotCode
      )
    }
    return this.addMeasurementManagement(
      utcMeasurement as MeasurementBodyManagement,
      studyplotCode
    )
  }

  public static async addMeasurementCommon(
    measurement: MeasurementBody,
    studyplotCode: string
  ): Promise<void> {
    await this.instance.post(
      `/studyplots/${studyplotCode}/measurements`,
      measurement
    )
  }

  public static async addMeasurementManagement(
    measurement: MeasurementBodyManagement,
    studyplotCode: string
  ): Promise<void> {
    await this.instance.post(
      `/studyplots/management/${studyplotCode}/measurements`,
      measurement
    )
  }

  public static async updateMeasurement(
    measurement: MeasurementUpdateBody | MeasurementBodyManagement,
    isManagmentMode: boolean,
    studyplotCode: string
  ): Promise<void> {
    const utcMeasurement = localToUtc(measurement)
    if (!isManagmentMode) {
      return this.updateMeasurementCommon(
        utcMeasurement as MeasurementUpdateBody,
        studyplotCode
      )
    }
    return this.updateMeasurementManagement(
      utcMeasurement as MeasurementBodyManagement,
      studyplotCode
    )
  }

  public static async updateMeasurementCommon(
    measurement: MeasurementUpdateBody,
    studyplotCode: string
  ): Promise<void> {
    return this.instance.put(
      `/studyplots/${studyplotCode}/measurements`,
      measurement
    )
  }

  public static async updateMeasurementManagement(
    measurement: MeasurementBodyManagement,
    studyplotCode: string
  ): Promise<void> {
    return this.instance.put(
      `/studyplots/management/${studyplotCode}/measurements`,
      measurement
    )
  }
}

<template>
  <b-container class="calendar">
    <b-row>
      <b-col cols="auto" class="text-left">
        <span class="title">{{ $t('ui.calendar.title') }}</span>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="5"
        class="text-left"
        :class="{ inactive: preset !== 'current' }"
      >
        <b-form-radio
          plain
          v-model="preset"
          name="timestamp-radios"
          value="current"
          @change="setPreset"
          >{{ $t('ui.calendar.current') }}</b-form-radio
        >
      </b-col>
      <b-col
        cols="7"
        class="text-left"
        v-if="isAdmin && extended"
        :class="{ inactive: preset !== '1000' }"
      >
        <b-form-radio
          plain
          v-model="preset"
          name="timestamp-radios"
          value="1000"
          @change="setPreset"
          >{{ $t('ui.calendar.since') }} 10:00 h</b-form-radio
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="5" class="text-left"> </b-col>
      <b-col
        cols="7"
        class="text-left"
        v-if="isAdmin && extended"
        :class="{ inactive: preset !== '1700' }"
      >
        <b-form-radio
          plain
          v-model="preset"
          name="timestamp-radios"
          value="1700"
          @change="setPreset"
          >{{ $t('ui.calendar.since') }} 17:00 h</b-form-radio
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="auto" class="text-left date" @click="setCalendar">
        <b-form-radio
          plain
          v-model="preset"
          name="timestamp-radios"
          value="calendar"
        >
          <CalendarComponent
            :with-time="true"
            v-model="currentDatetime"
            name="datepicker"
            @click="setPreset"
            :class="{
              inactive: preset !== 'calendar',
              calendarComponent: true,
            }"
          />
        </b-form-radio>
      </b-col>
    </b-row>

    <b-row class="separator">
      <b-col class="text-left">
        <span @click="increment(-1, 'day')">
          <font-awesome-icon
            icon="fa-solid fa-caret-square-left"
          ></font-awesome-icon
          >&nbsp;-1d
        </span>
      </b-col>
      <b-col class="text-right">
        <span @click="increment(1, 'day')">
          +1d&nbsp;<font-awesome-icon
            icon="fa-solid fa-caret-square-right"
          ></font-awesome-icon>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import CalendarComponent from './calendar/CalendarComponent'
import emitter from '@/components/EventHub'

const EXTENDED_ROUTES = [
  '/dangersign/dangersignAvalanche',
  '/dangersign/whumpfCracks',
  '/dangersign/snowdrift',
  '/danger/danger',
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Calendar',
  components: { CalendarComponent },
  props: [],
  mounted() {
    emitter.on('calendar::refresh', (momentVal) => {
      this.datetimeString = momentVal.format(this.dateFormat)
    })
    this.checkPreset()
  },
  created() {
    this.options.locale = this.$root.$i18n.locale
  },
  beforeUnmount() {
    emitter.off('calendar::refresh')
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    },
    extended() {
      return EXTENDED_ROUTES.includes(this.$route.path)
    },
    dateFormat() {
      return 'DD.MM.YYYY HH:mm'
    },
    preset: {
      get() {
        return this.$store.state.calendar.preset
      },
      set(value) {
        this.setPreset(value)
      },
    },
    currentDatetime: {
      get() {
        return this.datetimeString
      },
      set(val) {
        this.datetimeString = val

        this.$store.dispatch(
          'calendar/setMoment',
          moment(this.datetimeString, this.dateFormat)
        )
      },
    },
  },
  watch: {
    locale(value) {
      this.options.locale = value
    },
    extended() {
      this.checkPreset()
    },
  },
  data() {
    return {
      datetimeString: moment(this.$store.state.calendar.moment).format(
        'DD.MM.YYYY HH:mm'
      ),
      isAdmin: this.$store.state.user.user.isAdmin,
      isMobile: this.$store.state.mobilePlatform,
      options: {
        format: 'DD.MM.YYYY HH:mm',
        locale: 'de',
        useCurrent: false,
        icons: {
          time: 'fa-solid fa-clock',
          date: 'fa-solid fa-calendar-alt',
        },
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'auto',
        },
        showClose: true,
        ignoreReadonly: true,
        allowInputToggle: true,
        // debug: true
      },
    }
  },
  methods: {
    setCalendar() {
      this.preset = 'calendar'
      this.$store.dispatch('calendar/setPreset', this.preset)
    },
    setPreset(value) {
      this.$store.dispatch('calendar/setPreset', value)
      if (value === 'current') {
        this.currentDatetime = moment().format(this.dateFormat)
      }
    },
    increment(value, unit) {
      if (this.preset === 'current') {
        this.setPreset('calendar')
      }
      const datetime = moment(this.datetimeString, this.dateFormat).add(
        value,
        unit
      )
      this.datetimeString = datetime.format(this.dateFormat)
      this.$store.dispatch('calendar/setMoment', datetime)
    },
    checkPreset() {
      if (
        !this.extended &&
        (this.preset === '1000' || this.preset === '1700')
      ) {
        this.setPreset('current')
      }
    },
  },
}
</script>

<style scoped>
.calendar {
  color: #494949 !important;
  border: 1px solid #494949;
  border-radius: 2px;
}
.text-left {
  padding-right: 10px;
  padding-left: 10px;
}
.title {
  font-weight: bold;
}
.separator {
  border-top: 1px solid #494949;
}
.form-control {
  border: none !important;
  font: 14px Arial, Helvetica, sans-serif;
  padding: 0;
  height: 100%;
}
.inactive {
  color: #929292 !important;
}

.calendarComponent {
  padding: 0;
  height: 25px;
  margin: 2px 0;
}

:deep(div.bootstrap-datetimepicker-widget) {
  background-color: #9c9c9c;
}
:deep(div.dropdown-menu) {
  position: sticky;
}
</style>

import LayerVector from 'ol/layer/Vector'
import SourceVector from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'

export function geoLayer(
  features: Record<string, unknown>,
  width: number | undefined
): LayerVector<SourceVector> {
  return new LayerVector({
    source: new SourceVector({
      format: new GeoJSON(),
      features: new GeoJSON().readFeatures(features) as Feature<Geometry>[],
    }),
    style: new Style({
      fill: new Fill({
        color: '#B2B2B2',
      }),
      stroke: new Stroke({
        color: '#9C9C9C',
        width: width,
      }),
    }),
    minResolution: 160,
  })
}

<template>
  <span>
    <span v-if="!hide">{{ text }}&nbsp;</span>
    <span @click="show"
      ><font-awesome-icon
        class="icon"
        icon="fa-solid fa-info-circle"
      ></font-awesome-icon
    ></span>
    <b-modal :id="ts" hide-footer :title="text" no-fade centered>
      <div class="info-modal">{{ info }}</div>
    </b-modal>
  </span>
</template>

<script>
import { v1 as uuidV1 } from 'uuid'

export default {
  props: ['text', 'info', 'hide'],
  data() {
    return {
      ts: uuidV1(),
    }
  },
  methods: {
    show(evt) {
      this.$bvModal.show(this.ts)
      evt.stopImmediatePropagation()
    },
  },
}
</script>

<style scoped>
.icon {
  color: #acacac;
}
.info-modal {
  white-space: pre-wrap;
}
</style>

<template>
  <b-container fluid>
    <b-row>
      <b-col offset-lg="2" lg="6">
        <b-card :header="$t('ui.profile')">
          <b-form @submit.prevent="onSubmit">
            <b-form-group v-if="uid" label="ID" label-cols label-size="sm">
              <b-form-input
                :value="uid.substr(0, 36)"
                type="text"
                size="sm"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('user.label.firstName')"
              label-cols
              label-size="sm"
            >
              <b-form-input
                v-model="form.firstName"
                type="text"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.lastName')"
              label-cols
              label-size="sm"
            >
              <b-form-input
                v-model="form.lastName"
                type="text"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.email')"
              label-cols
              label-size="sm"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.language')"
              label-cols
              label-size="sm"
            >
              <b-form-select
                v-model="form.language"
                :options="languages"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.education')"
              label-cols
              label-size="sm"
            >
              <b-form-select
                v-model="form.education"
                :options="educations"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.phone')"
              label-cols
              label-size="sm"
            >
              <b-form-input
                v-model="form.phone"
                type="tel"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.mobile')"
              label-cols
              label-size="sm"
            >
              <b-form-input
                v-model="form.mobile"
                type="tel"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.address')"
              label-cols
              label-size="sm"
            >
              <b-form-textarea
                v-model="form.address"
                type="text"
                size="sm"
                rows="4"
                @input="onChange"
              />
            </b-form-group>
            <b-form-group
              :label="$t('user.label.canton')"
              label-cols
              label-size="sm"
            >
              <b-form-select
                v-model="form.canton"
                :options="cantons"
                size="sm"
                @input="onChange"
              />
            </b-form-group>
          </b-form>
          <b-button type="warning" variant="warning" to="/">{{
            $t('ui.cancel')
          }}</b-button>
          <b-button
            @click="onSubmit"
            variant="success"
            class="float-right"
            :disabled="!changed"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import contactService from '../../services/contactService'
import { i18nMixin } from '../I18N'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Profile',
  props: ['uid'],
  mixins: [i18nMixin],
  watch: {
    uid: function () {
      this.load()
    },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      user: {},
      form: {},
      changed: false,
      languages: ['de', 'fr', 'it', 'en'],
      educations: this.options('user', 'education'),
      cantons: [
        'AG',
        'AI',
        'AR',
        'BE',
        'BL',
        'BS',
        'FR',
        'GE',
        'GL',
        'GR',
        'JU',
        'LU',
        'NE',
        'NW',
        'OW',
        'SG',
        'SH',
        'SO',
        'SZ',
        'TG',
        'TI',
        'UR',
        'VD',
        'VS',
        'ZG',
        'ZH',
      ],
    }
  },
  methods: {
    load() {
      let uid = this.uid || this.$store.state.user.user.id
      if (this.uid) {
        // Temp fix for deep link problem: settings/profile/768c9e05-7baa-477f-9b3d-1a3d292ae0d0 => settings/profile/768c9e05-7baa-477f-9b3d-1a3d292ae0d0&state=5744895a...
        uid = this.uid.substr(0, 36)
      }
      contactService.getKeycloakUser(uid).then(
        (response) => {
          this.fill(response.data)
        },
        (reason) => {
          console.error('Error reading user data' + reason)
        }
      )
    },
    fill(user) {
      this.user = user
      this.form = Object.assign({}, user)
    },
    onSubmit() {
      contactService.updateKeycloakUser(this.form).then(
        () => {
          this.$router.push('/')
        },
        (reason) => {
          console.error('Error updating user profile:' + reason)
        }
      )
    },
    onCancel() {
      this.fill(this.user)
    },
    onChange() {
      this.changed = true
    },
  },
}
</script>

<style scoped></style>

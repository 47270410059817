import Style from 'ol/style/Style'
import { Circle, Stroke } from 'ol/style'
import { GEFAHREN_INDEX_COLORS_STROKE } from '@/components/styles/AbstractStyle'
import { COLOR_FAVORABLE_FILL } from '@/components/styles/avalancheStyling/AvalancheCommonStyling'
import ActivityIndexStyleFactory from '@/components/styles/avalancheStyling/ActivityIndexStyleFactory'
import { AvalancheRelease } from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'

const AVAL_RELEASE_CIRCLE = new Style({
  image: new Circle({
    radius: ActivityIndexStyleFactory.getRadiusForActivityIndex(0.01),
    fill: COLOR_FAVORABLE_FILL,
    stroke: new Stroke({ color: GEFAHREN_INDEX_COLORS_STROKE[0], width: 1 }),
  }),
  zIndex: 3,
})

export const getAvalancheReleaseStyleArray = function (
  avalancheReleaseValue: AvalancheRelease
): Array<Style> {
  const styles = []
  if (!avalancheReleaseValue.value) {
    styles.push(AVAL_RELEASE_CIRCLE)
  }
  return styles
}

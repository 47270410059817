<template>
  <b-modal
    id="modalException"
    ref="modalExceptionRef"
    title="Fehler"
    :visible="exception != null"
  >
    <template #modal-footer>
      <div class="w-100">
        <b-btn size="sm" class="float-right" variant="primary" @click="close()">
          Schliessen
        </b-btn>
      </div>
    </template>
    <b-row align-v="center">
      <b-col cols="2">
        <span style="font-size: 2em; color: red"
          ><font-awesome-icon
            icon="fa-solid fa-times-circle"
          ></font-awesome-icon
        ></span>
      </b-col>
      <b-col cols="10">
        <div v-if="exception && exception.message">
          <div>{{ exception.message }}</div>
          <div>Details: {{ exception.details }}</div>
        </div>
        <div v-else>{{ exception }}</div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Exception',
  data() {
    return {}
  },
  computed: {
    exception() {
      const ex = this.$store.state.exception
      const validEx = this.$store.state.validationException
      if (ex) {
        return this.$store.state.exception
      }
      return validEx
    },
  },
  methods: {
    close() {
      this.$store.commit('SET_EXCEPTION', null)
      this.$store.commit('SET_VALIDATION_EXCEPTION', null)
      this.$refs.modalExceptionRef.hide()
    },
  },
}
</script>

<style scoped></style>

import { Collection } from 'ol'
import {
  Attribution,
  Control,
  Zoom,
  defaults as defaultControls,
} from 'ol/control'
import PositionControl from '@/scripts/controls/PositionControl'
import { CommonLayersControl } from '@/scripts/controls/CommonLayersControl'

export function createCommonControls(
  mobile: boolean,
  layerIconEl: HTMLElement
): Collection<Control> {
  const attribution = new Attribution({ collapsible: false })
  const commonLayersControl = new CommonLayersControl(mobile, layerIconEl)

  return defaultControls({
    zoom: !mobile,
    rotate: false,
    attribution: false,
  }).extend([attribution, commonLayersControl])
}

export function getAttributionControl(
  controls: Collection<Control>
): Attribution | undefined {
  return controls
    .getArray()
    .find((control) => control instanceof Attribution) as
    | Attribution
    | undefined
}

export function getZoomControl(
  controls: Collection<Control>
): Zoom | undefined {
  return controls.getArray().find((control) => control instanceof Zoom) as
    | Zoom
    | undefined
}

export function getPositionControl(
  controls: Collection<Control>
): PositionControl | undefined {
  return controls
    .getArray()
    .find((control) => control instanceof PositionControl) as
    | PositionControl
    | undefined
}

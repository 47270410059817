'use strict'

export default class MeasureAreas {
  constructor(areas = []) {
    this.areas = areas
  }
  setDecisions(decisions) {
    const set = new Set()
    this.areas = decisions
      .filter((el) => el.hierarchy.sectorId === null)
      .sort((a, b) => b.cTimestamp - a.cTimestamp)
      .filter(
        (el) => !set.has(el.hierarchy?.areaId) && set.add(el.hierarchy?.areaId)
      )
      .filter((el) => el.decision?.measure === false)
      .map((el) => ({
        id: el.hierarchy.areaId,
        location: el.location,
      }))
    return this
  }
  filterByDecisions(decisions) {
    const set = new Set()
    const list = decisions
      .filter((el) => el.hierarchy.sectorId !== null)
      .sort((a, b) => b.cTimestamp - a.cTimestamp)
      .filter(
        (el) =>
          !set.has(el.hierarchy?.sectorId) && set.add(el.hierarchy?.sectorId)
      )
      .filter((el) => el.decision?.measure === true)
      .map((el) => el.hierarchy.areaId)
    this.areas = this.areas.filter((el) => !list.includes(el.id))
    return this
  }
  filterByMeasures(measures) {
    const list = measures
      .filter((el) => {
        const a = el.actualMeasure?.measure?.measure
        const p = el.planedMeasure?.measure?.measure
        return (a && a !== 'NO_MEASURE') || (p && p !== 'NO_MEASURE')
      })
      .filter((el) => el.objectId) // ignore warning light measures
      .map((el) => el.areaId)
    this.areas = this.areas.filter((el) => !list.includes(el.id))
    return this
  }
  getFeatures() {
    return this.areas.map((el) => ({
      type: 'Feature',
      geometry: el.location,
      properties: { id: el.id },
    }))
  }
}

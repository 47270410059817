import {
  AggregatedDataAtStationGeoJson,
  BaseDataAtStationGeoJsonProperties,
  TimepointDataAtStationGeoJson,
} from '@/services/MeasurementMapService'
import { DateTime } from 'luxon'
import { getTransform } from 'ol/proj'
import { CRS, StationDataGeoJsonProperties } from '@/model/geoJsonModels'
import { FeatureCollection, Point } from 'geojson'

interface DataAtStationGeoJson<
  SOURCE_PROPERTIES extends BaseDataAtStationGeoJsonProperties
> extends FeatureCollection<Point, SOURCE_PROPERTIES> {
  crs: CRS
}

function transformGeoJson<
  SOURCE_PROPERTIES extends BaseDataAtStationGeoJsonProperties,
  TARGET_PROPERTIES extends StationDataGeoJsonProperties
>(
  dataAtStationGeoJson: DataAtStationGeoJson<SOURCE_PROPERTIES>,
  dateTime: DateTime,
  projection: string,
  getMeasureDate: (properties: SOURCE_PROPERTIES) => string | null,
  setValueOnProperties: (
    properties: StationDataGeoJsonProperties,
    value: number | null
  ) => TARGET_PROPERTIES
): FeatureCollection<Point, TARGET_PROPERTIES> & { crs: CRS } {
  const transformation = getTransform(
    dataAtStationGeoJson.crs.properties.name,
    projection
  )

  return {
    ...dataAtStationGeoJson,
    crs: {
      ...dataAtStationGeoJson.crs,
      properties: { name: projection },
    },
    features: dataAtStationGeoJson.features.map((feature) => ({
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: transformation(feature.geometry.coordinates, undefined, 3),
      },
      properties: setValueOnProperties(
        {
          id: feature.properties.code,
          network: feature.properties.network,
          name: feature.properties.label,
          delay: getDelay(dateTime, getMeasureDate(feature.properties)),
        },
        feature.properties.value
      ),
    })),
  }
}

export function transformTimepointGeoJson<
  PROPERTIES extends StationDataGeoJsonProperties
>(
  dataAtStationGeoJson: TimepointDataAtStationGeoJson,
  dateTime: DateTime,
  projection: string,
  setValueOnProperties: (
    properties: StationDataGeoJsonProperties,
    value: number | null
  ) => PROPERTIES
): FeatureCollection<Point, PROPERTIES> & { crs: CRS } {
  return transformGeoJson(
    dataAtStationGeoJson,
    dateTime,
    projection,
    (properties) => properties.measureDate,
    setValueOnProperties
  )
}

export function transformAggregatedGeoJson<
  PROPERTIES extends StationDataGeoJsonProperties
>(
  dataAtStationGeoJson: AggregatedDataAtStationGeoJson,
  dateTime: DateTime,
  projection: string,
  setValueOnProperties: (
    properties: StationDataGeoJsonProperties,
    value: number | null
  ) => PROPERTIES
): FeatureCollection<Point, PROPERTIES> & { crs: CRS } {
  return transformGeoJson(
    dataAtStationGeoJson,
    dateTime,
    projection,
    (properties) => properties.maxMeasureDate,
    setValueOnProperties
  )
}

function getDelay(
  endDate: DateTime,
  measureDateString: string | null
): number | null {
  if (measureDateString == null) {
    return null
  }
  const measureDate = DateTime.fromISO(measureDateString, { zone: 'utc' })
  return endDate.diff(measureDate).as('minutes')
}

'use strict'

import moment from 'moment'
import store from '../../store/index'
import { i18n } from '@/plugins/i18n'

export class AbstractStyle {
  constructor(locale, ol) {
    if (locale) {
      i18n.global.locale = locale
    }
    this.ol = ol
  }
  getSymbol(properties) {
    const style = new this.ol.style.Style({
      image: this.getShape(properties),
    })
    const canvas = document.createElement('canvas')
    canvas.setAttribute('width', 20)
    canvas.setAttribute('height', 20)
    const vectorContext = this.ol.render.toContext(canvas[0].getContext('2d'), {
      size: [20, 20],
    })
    vectorContext.setStyle(style)
    vectorContext.drawGeometry(new this.ol.geom.Point([10, 10]))
    return canvas
  }

  static getMouseOverUsername(feature) {
    if (feature) {
      let beobName = feature.get('observer')
      if (!beobName || beobName.length <= 0) {
        beobName = '' // NOTE: for clustered avalanches no name(s) to provide better than unknown - a better way here rather than to refactor this to display all userids of an aval cluster?
        const uid = feature.get('uid')
        const creatorSystem = feature.get('creatorSystem')
        if (uid) {
          const user = store.getters['user/getUserToId'](uid, creatorSystem)
          if (!user) {
            store
              .dispatch('user/loadContactById', uid)
              .then(() => {
                const user = store.getters['user/getUserToIdOrDummyUser'](
                  uid,
                  creatorSystem
                )
                if (user) {
                  document.getElementById('observerName').innerHTML =
                    user.firstName + ' ' + user.lastName
                } else {
                  console.warn(
                    'User-ID: `' + uid + '` konnte nicht geladen werden.'
                  )
                }
              })
              .catch(function (error) {
                console.error(error)
              })
          } else {
            beobName = user.firstName + ' ' + user.lastName
          }
        }
      }
      return '<div id="observerName">' + beobName + '</div>'
    } else {
      return ''
    }
  }
  getMouseOverTitel(feature) {
    let globalTitle = AbstractStyle.getMouseOverUsername(feature)
    if (feature) {
      const commitDate = moment
        .unix(feature.get('cTimestamp'))
        .format('YYYY-MM-DD')
      if (feature.get('cTimestamp') && commitDate === feature.get('oDate')) {
        globalTitle =
          globalTitle +
          '<div>' +
          i18n.global.t('mouseover.observation') +
          ': ' +
          moment.unix(feature.get('cTimestamp')).format('DD.MM.YYYY, HH:mm') +
          '</div>'
      } else {
        if (feature.get('oDate')) {
          globalTitle =
            globalTitle +
            '<div>' +
            i18n.global.t('mouseover.observation') +
            ': ' +
            moment(feature.get('oDate')).format('DD.MM.YYYY') +
            '</div>'
        }
      }
    }
    return globalTitle
  }
  setZindexIfExists(feature, style) {
    if (feature && style && feature.zIndex) {
      style.setZIndex(feature.zIndex)
    }
  }
}

export const Z_INDEX = 'zIndex'
export const COLOR_GUENSTIG = '#baf0bb'
export const COLOR_FAVORABLE = COLOR_GUENSTIG
export const COLOR_MITTEL = '#f5a803'
export const COLOR_MEDIUM = COLOR_MITTEL
export const COLOR_SCHWACH = '#800026'
export const COLOR_UNFAVORABLE = COLOR_SCHWACH
export const COLOR_GUENSTIG_STROKE = '#5d785d'
export const COLOR_MITTEL_STROKE = '#7a5402'
export const COLOR_SCHWACH_STROKE = '#400013'
export const GEFAHREN_INDEX_COLORS = [
  COLOR_GUENSTIG,
  COLOR_MITTEL,
  COLOR_SCHWACH,
]
export const GEFAHREN_INDEX_TEXT_COLORS = ['black', 'black', 'white']
export const GEFAHREN_INDEX_COLORS_STROKE = [
  COLOR_GUENSTIG_STROKE,
  COLOR_MITTEL_STROKE,
  COLOR_SCHWACH_STROKE,
]
export const GEFAHREN_KLASSE = 'dangerClass'

import Modify from 'ol/interaction/Modify.js'
import Feature from 'ol/Feature'
import ModifyDeleteControl from '@/scripts/avalancheInputMap/modifyDeleteControl/ModifyDeleteControl'

export function getModifyInteraction(
  modifyDelCtrl: ModifyDeleteControl
): Modify {
  const modifyInteraction = new Modify({
    source: modifyDelCtrl.getAvalMapCompDrawnSrc(),
    pixelTolerance: 20,
  })
  modifyInteraction.on('modifyend', getModifyEndEvent(modifyDelCtrl))
  return modifyInteraction
}
function getModifyEndEvent(modifyDelCtrl: ModifyDeleteControl) {
  return async () => {
    modifyDelCtrl.getAvalMapComponent().reset()
    const drawnFeatures: Feature[] =
      modifyDelCtrl.getAvalMapCompDrawnSrcFeatures()
    drawnFeatures.forEach((feature) => {
      const geometry = feature.getGeometry()
      modifyDelCtrl.getAvalMapComponent().addFeature(geometry)
    })
    // Die ModifyInteraction funktioniert sich sauber, wenn nach dem 'moveend' mit der alten weitergearbeitet wird.
    modifyDelCtrl.removeAllInteractions()
    modifyDelCtrl.addModifyInteraction()
  }
}

export enum BaseLayer {
  COLOR_MAP = 'baseLayer.colorMap',
  ARIAL_IMAGERY = 'baseLayer.arialImagery',
}

export enum AdditionalLayer {
  SLOPE_CLASSES = 'additionalLayer.slopeClasses',
}

export interface CommonLayersState {
  baseLayer: BaseLayer
  additionalLayers: AdditionalLayer[]
}

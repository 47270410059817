<template>
  <primevue-calendar
    ref="pv-calendar-ref"
    name="datepicker"
    v-model="modelJSDate"
    :showTime="isWithTime"
    :show-seconds="false"
    :step-minute="5"
    hourFormat="24"
    :show-icon="showIcon"
    :showOnFocus="true"
    @update:model-value="onInput"
    @click="calendarClick()"
    :min-date="minJSDate"
    :max-date="maxJSDate"
    :inline="isInline"
    date-format="dd.mm.yy"
    :touchUI="isMobile"
    :base-z-index="99999"
    inputClass="pv-calendar-input"
    :class="isInline ? '' : 'pv-calendar'"
    appendTo="body"
    :disabled="disabled"
    :disabled-dates="disabledJSDates"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import { CalendarProps, CalendarState } from 'primevue/calendar'

export default defineComponent({
  name: 'CalendarComponent',
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    withTime: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    min: {
      type: String as PropType<string | undefined>,
    },
    max: {
      type: String as PropType<string | undefined>,
    },
    inline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabledDates: {
      type: Array as PropType<string[] | undefined>,
    },
    showIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      dateTest: null,
      innerModelValue: DateTime.now() as DateTime | undefined,
    }
  },
  watch: {
    modelValue: {
      handler: function () {
        this.onValueChange()
      },
    },
  },
  mounted() {
    this.innerModelValue = this.parseStringDateWithOptionalTime(this.modelValue)
    if (!this.innerModelValue) {
      this.innerModelValue = DateTime.now()
    }
  },
  computed: {
    isMobile() {
      if (this.isInline) {
        return false
      } else {
        return this.$store.state.mobilePlatform
      }
    },
    modelJSDate: {
      get(): Date | string | undefined {
        if (this.innerModelValue && this.innerModelValue.isValid) {
          return this.innerModelValue.toJSDate()
        } else {
          return undefined
        }
      },
      set(): void {
        // Do nothing on set
      },
    },
    minDate(): DateTime | null {
      const parsedDate = DateTime.fromISO(this.min || '')
      return parsedDate.isValid ? parsedDate : null
    },
    minJSDate(): Date | null {
      return this.minDate?.toJSDate() || null
    },
    maxDate(): DateTime | null {
      const parsedDate = DateTime.fromISO(this.max || '')
      return parsedDate.isValid ? parsedDate : null
    },
    maxJSDate(): Date | null {
      return this.maxDate?.toJSDate() || null
    },
    disabledJSDates(): Date[] | null {
      if (this.disabledDates) {
        return this.disabledDates.map((strDate: string) =>
          DateTime.fromFormat(strDate, 'yy-LL-dd').toJSDate()
        )
      } else {
        return null
      }
    },
    isWithTime(): boolean {
      // boolean prop can be empty string --> withTime=true
      return this.withTime !== false
    },
    isInline(): boolean {
      return this.inline !== false
    },
    luxonDateFormat() {
      return this.getLuxonDateFormat(this.isWithTime)
    },
  },
  methods: {
    getLuxonDateFormat(withTime: boolean) {
      return withTime ? 'dd.LL.yyyy HH:mm' : 'dd.LL.yyyy'
    },
    onInput(formattedDate: Date | string) {
      if (formattedDate instanceof Date) {
        this.$emit(
          'update:modelValue',
          DateTime.fromJSDate(formattedDate).toFormat(this.luxonDateFormat)
        )
      } else if (typeof formattedDate === 'string') {
        if (formattedDate === '') {
          this.$emit('update:modelValue', '')
        } else {
          const parsedDate = this.parseStringDateWithOptionalTime(formattedDate)
          if (parsedDate?.isValid) {
            this.$emit(
              'update:modelValue',
              parsedDate.toFormat(this.luxonDateFormat)
            )
          }
        }
      }
    },
    parseStringDateWithOptionalTime(strDate: string) {
      let parsedDate = strDate
        ? DateTime.fromFormat(strDate, this.luxonDateFormat)
        : undefined
      if (!parsedDate?.isValid) {
        parsedDate = DateTime.fromFormat(
          strDate,
          this.getLuxonDateFormat(!this.isWithTime)
        ).startOf('day')
      }
      return parsedDate
    },
    onValueChange() {
      this.innerModelValue = this.parseStringDateWithOptionalTime(
        this.modelValue
      )
    },
    calendarClick() {
      const calendar = this.$refs['pv-calendar-ref'] ?? null
      if (calendar != null) {
        const castedCalendar = calendar as CalendarState & CalendarProps
        if (!castedCalendar.disabled) {
          castedCalendar.overlayVisible = true
        }
      }
    },
  },
})
</script>

<style>
.pv-calendar {
  width: 190px;
  height: calc(1.5em + 0.75rem + 2px);
}
</style>

<script>
import AttachmentObservationInput from '../attachment/Input.vue'
import AreaSectorInput from './AreaSectorInput.vue'
import { beforeRouteEnter } from '@/components/routingConfig'
import { useStagedDocumentRemover } from '@/composables/useStagedDocumentRemover'

export default {
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: AttachmentObservationInput,
  components: { AreaSectorInput },
  data() {
    return {
      assFlag: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    beforeRouteEnter(to, from, next)
  },
  setup() {
    return useStagedDocumentRemover()
  },
}
</script>

<style lang="css" scoped src="../attachment/Input.css"></style>

import BaseService from '@/services/BaseService'
import { enums } from '@/components/I18N.js'

// application ID required for some services like document service
const SERVICE_APPLICATION_ID = enums.creatorSystem[0]

export default class documentService {
  static srv = undefined
  static uploadSrv = undefined

  static getInstance() {
    this.srv = this.srv || new BaseService('DOCUMENT_SERVICE_URL', 120000)
    return this.srv.getInstance()
  }

  static getUploadInstance() {
    this.uploadSrv =
      this.uploadSrv || new BaseService('DOCUMENT_SERVICE_URL', 40000, null)
    return this.uploadSrv.getInstance()
  }

  static createDocument(formData) {
    if (formData) {
      formData.append('application', SERVICE_APPLICATION_ID)
    }
    return this.getUploadInstance().post('single/', formData)
  }

  static deleteDocument(id) {
    return this.getInstance().delete(
      'single/' + id + '?application=' + SERVICE_APPLICATION_ID
    )
  }

  static getDocumentMeta(id) {
    return this.getInstance().get('single/' + id + '/metadata')
  }

  static updateDocumentMeta(meta) {
    return this.getInstance().put(
      'single/' + meta.id + '/metadata?application=' + SERVICE_APPLICATION_ID,
      meta
    )
  }

  static fetchFromUrl(id, type, mimeType, width, height) {
    // NOTE: constructing URL manually rather than taking over 'thumbnailUrl' and 'url' due to CORS issues locally
    const fetchInstance = Object.assign({}, this.getInstance())
    // fetchInstance.defaults.headers['Accept'] = mimeType // NOTE: das überschreibt alles!!!! -> code 406
    if (type && type.length > 0) {
      return fetchInstance.get(
        'single/' +
          id +
          '/' +
          type +
          (width && height ? '?width=' + width + '&height=' + height : ''),
        { headers: { accept: mimeType }, responseType: 'arraybuffer' }
      )
    } else {
      return fetchInstance.get('single/' + id, {
        headers: { accept: mimeType },
        responseType: 'arraybuffer',
      })
    }
  }
}

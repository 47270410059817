'use strict'

import {
  ASSESSMENT_TYPES,
  ASSESSMENT_DECISION,
  ASSESSMENT_SITUATION,
  ASSESSMENT_SECTOR,
  ATTACHMENT,
  TRIGGERING,
} from '@/scripts/const.js'
import { i18n } from '@/plugins/i18n'

const funcs = {
  // Bereitet ein Assessment-Observation vor, so dass sie gespeichert werden kann
  // Hierarchymap neu im root und nicht im Type
  prepareAssessmentObservation(type, observation) {
    if (this.isAssessmentType(type) && observation[type].hierarchy) {
      observation.hierarchy = observation[type].hierarchy
      observation[type].hierarchy = undefined
    }
    this.prepareAssessmentObservationComments(type, observation)
    return observation
  },
  // eslint-disable-next-line complexity
  prepareAssessmentObservationComments(type, observation) {
    if (type === ASSESSMENT_SITUATION) {
      if (observation.situation.current.comment === '') {
        observation.situation.current.comment = null
      }
      if (observation.situation.forecast.comment === '') {
        observation.situation.forecast.comment = null
      }
    } else if (type === ASSESSMENT_SECTOR) {
      if (observation.evaluation.comment === '') {
        observation.evaluation.comment = null
      }
    } else if (type === ATTACHMENT) {
      if (observation.attachment.comment === '') {
        observation.attachment.comment = null
      }
    }
  },
  prepareAssessmentObservationInputForm(type, observation, inputForm) {
    if (
      this.isAssessmentType(type) &&
      observation.hierarchy &&
      inputForm.hierarchy
    ) {
      inputForm.hierarchy.avalServiceId = observation.hierarchy.avalServiceId
      inputForm.hierarchy.areaId = observation.hierarchy.areaId
      inputForm.hierarchy.sectorId = observation.hierarchy.sectorId
      inputForm.hierarchy.objectId = observation.hierarchy.objectId
    }
  },
  prepareAssessmentObservationResult(type, observation) {
    if (this.isAssessmentType(type) && observation.hierarchy) {
      observation[type].hierarchy = observation.hierarchy
    }
  },
  isAssessmentType(type) {
    let result = false
    ASSESSMENT_TYPES.forEach((assType) => {
      if (assType === type) {
        result = true
      }
    })
    return result
  },
  // eslint-disable-next-line complexity
  isAssessmentObservation(obs, property) {
    if (
      obs &&
      obs[property] &&
      ASSESSMENT_TYPES.includes(property) &&
      (obs[property].avalServiceId ||
        (obs.hierarchy && obs.hierarchy.avalServiceId))
    ) {
      return true
    }
    return false
  },
  isAssessmentDecisionArea(assessmentObs) {
    return (
      assessmentObs &&
      assessmentObs.type === ASSESSMENT_DECISION &&
      assessmentObs.hierarchy.areaId &&
      !assessmentObs.hierarchy.sectorId
    )
  },
  isAssessmentDecisionSector(assessmentObs) {
    return (
      assessmentObs &&
      assessmentObs.type === ASSESSMENT_DECISION &&
      assessmentObs.hierarchy.areaId &&
      assessmentObs.hierarchy.sectorId
    )
  },
  getAssessmentTypeName(value, item, givenTypeName) {
    if (TRIGGERING === value && !item[TRIGGERING].planned) {
      return i18n.global.t('assessment.ui.suspensionTriggering')
    }
    return givenTypeName
  },
}
export default funcs

/* eslint-disable */
'use strict';

import Style from 'ol/style/Style'
import {SVG} from '../SVG'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import {AbstractStyle} from '../AbstractStyle'
import moment from 'moment'
import {getMouseOverText} from "@/components/styles/dangerStyling/dangerMouseOver";

export class ProblemStyle extends AbstractStyle {
  constructor (locale) {
    super(locale)
    moment.locale(locale)
  }

  setVariant (variantValue) {
    variant = variantValue
  }

  setFilter (calendarValue) {
    calendar = calendarValue
  }

  styleFunction(feature) {
    let value = feature.get(DANGER)
    if (value === null || value === undefined) {
      return []
    }

    return [getProblemStyle(feature)]
  }

  legend() {
    return {
      title: 'danger.label.productMainProblem',
      items: [
        { label: 'common.flag.false', symbol: getLegendColorSymbol(STYLE_ELEMENTS.NO) },
        { label: 'common.flag.true', symbol: getLegendColorSymbol( STYLE_ELEMENTS.YES) },
      ]
    }
  }

  mouseOverFunction (feature) {
      return getMouseOverText(feature, super.getMouseOverTitel(feature))
  }
}

const problemStyleCache = new Map()
const problemStyleRadius = 9

let getProblemStyle = function (feature) {
  let problemValue = feature.get(DANGER)
  // NOTE: default should not occur
  let problemStyle = (problemValue.problemC === true ? STYLE_ELEMENTS.YES : (problemValue.problemC === false ? STYLE_ELEMENTS.NO : STYLE_ELEMENTS.DEFAULT))

  let problemStyleSymbol
  if (problemStyleCache.get(problemStyle.key)) {
    problemStyleSymbol = problemStyleCache.get(problemStyle.key)
  } else {
    problemStyleSymbol = new Style({
      image: new Circle({
        radius: problemStyleRadius,
        fill: new Fill({color: problemStyle.fill}),
        stroke: new Stroke({color: problemStyle.stroke, width: 2})
      }),
    })
    problemStyleCache.set(problemStyle.key, problemStyleSymbol)
  }

  problemStyleSymbol.setZIndex(getMapProblemIndex(feature))
  return problemStyleSymbol
}

let getMapProblemIndex = function (feature) {
  let problemValue = feature.get(DANGER)
   // NOTE: default should not occur
  return (problemValue.problemC === true ? 100 : (problemValue.problemC === false ? 90 : 10))
}

let getLegendColorSymbol = function(problemStyle) {
  let viewBox = '-15 -15 30 30'
  const symbol = `<circle r="${problemStyleRadius + 6}" fill="${problemStyle.fill}" />`;
  const svg = new SVG(viewBox);

  return svg.createImage(symbol)
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------

// Feature type
const DANGER = 'danger'

// Map symbols
const STYLE_ELEMENTS = {
    NO: {fill: 'rgb(242, 216, 76)', stroke: 'rgb(242, 216, 76)', key: 'no'},
    YES: {fill: 'rgb(49, 0, 87)', stroke: 'rgb(49, 0, 87)', key: 'yes'},
    DEFAULT: {fill: 'rgb(153, 153, 153)', stroke: 'rgb(153, 153, 153)',  key: 'default'}
}

let variant = null
let calendar = null

'use strict'

import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import { SVG } from '../SVG'
import TextLabel from '../TextLabel'
import { i18n } from '@/plugins/i18n'
import { enums } from '../../I18N'
import {
  COLOR_GUENSTIG,
  COLOR_MITTEL,
  COLOR_SCHWACH,
  GEFAHREN_INDEX_COLORS_STROKE,
  AbstractStyle,
  Z_INDEX,
} from '../AbstractStyle'
import { SNOWDRIFT } from '@/scripts/const.js'

export class SnowdriftStyle extends AbstractStyle {
  styleFunction(feature, resolution) {
    const snowdriftFeature = feature.get(SNOWDRIFT)
    snowdriftFeature[Z_INDEX] = getSnowdriftSizeIdx(snowdriftFeature)
    const textLabel = new TextLabel()
    const labelValue = computeLabelValue(snowdriftFeature)
    const style = new Style({
      image: getSnowdriftSymbol(snowdriftFeature),
      text: textLabel.get(labelValue, resolution),
    })
    super.setZindexIfExists(snowdriftFeature, style)
    return [style]
  }
  legend() {
    const legend = {
      title: 'dangersign.label.snowdrift',
    }
    legend.items = [
      {
        label: i18n.global.t('dangersign.snowdriftsize.NONE'),
        symbol: getLegendSymbol({ size: enums.dangersign.snowdriftsize[0] }),
      },
      {
        label: i18n.global.t('dangersign.snowdriftsize.SMALL'),
        symbol: getLegendSymbol({ size: enums.dangersign.snowdriftsize[1] }),
      },
      {
        label: i18n.global.t('dangersign.snowdriftsize.MEDIUM'),
        symbol: getLegendSymbol({ size: enums.dangersign.snowdriftsize[2] }),
      },
      {
        label: i18n.global.t('dangersign.snowdriftsize.LARGE'),
        symbol: getLegendSymbol({ size: enums.dangersign.snowdriftsize[3] }),
      },
    ]
    return legend
  }
  mouseOverFunction(feature) {
    const snowdrift = feature.get(SNOWDRIFT)
    if (snowdrift) {
      const size = i18n.global.t('dangersign.snowdriftsize.' + snowdrift.size)
      let htmlString = super.getMouseOverTitel(feature) + size
      if (
        enums.dangersign.snowdriftsize[0].localeCompare(snowdrift.size) !== 0
      ) {
        if (snowdrift.distribution) {
          htmlString =
            htmlString +
            ', ' +
            i18n.global.t('dangersign.distribution.' + snowdrift.distribution)
        }
      }
      return htmlString
    } else {
      return null
    }
  }
}
const getSnowdriftSizeIdx = function (snowdriftFeature) {
  const snowdriftSizeArray = enums.dangersign.snowdriftsize
  for (let index = 0; index < snowdriftSizeArray.length; index++) {
    if (snowdriftSizeArray[index].localeCompare(snowdriftFeature.size) === 0) {
      return index
    }
  }
  return 0
}
const computeLabelValue = function (snowdriftFeature) {
  return snowdriftFeature.altitude
    ? '+' + Math.round(snowdriftFeature.altitude / 100)
    : ''
}
const getSnowdriftSymbol = function (snowdriftFeature) {
  const snowdriftStyle = getSnowdriftStyle(snowdriftFeature).style
  return new Circle({
    radius: snowdriftStyle.radius,
    fill: new Fill({ color: snowdriftStyle.fill }),
    stroke: new Stroke({ color: snowdriftStyle.stroke, width: 1 }),
  })
}
const getLegendSymbol = function (snowdriftFeature) {
  const snowdriftStyle = getSnowdriftStyle(snowdriftFeature).style
  const symbol =
    '<g style="fill:' +
    snowdriftStyle.fill +
    '; stroke:' +
    snowdriftStyle.stroke +
    '">' +
    getCircleSvg(snowdriftStyle.radius) +
    '</g>'
  const svg = new SVG('0 0 25 15')
  return svg.createImage(symbol)
}
const getCircleSvg = function (radius) {
  return '<circle cx="6" cy="7" r="' + radius + '" />'
}

const getSnowdriftStyle = function (snowdriftFeature) {
  const featureStyle = SNOWDRIFT_STYLE_ELEMENTS.find(function (styleEle) {
    return styleEle.size.localeCompare(snowdriftFeature.size) === 0
  })
  return featureStyle !== undefined ? featureStyle : NO_VALUE_CLASS
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------npm run dev
const NO_VALUE_CLASS = {
  style: { fill: '#ffffff', stroke: '#122e42' },
  label: 'kein Wert',
}
const SNOWDRIFT_STYLE_ELEMENTS = [
  {
    size: enums.dangersign.snowdriftsize[0],
    style: { fill: '#fff7fb', stroke: '#7f7b7d', radius: 5 },
  },
  {
    size: enums.dangersign.snowdriftsize[1],
    style: {
      fill: COLOR_GUENSTIG,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[0],
      radius: 5,
    },
  },
  {
    size: enums.dangersign.snowdriftsize[2],
    style: {
      fill: COLOR_MITTEL,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[1],
      radius: 5,
    },
  },
  {
    size: enums.dangersign.snowdriftsize[3],
    style: {
      fill: COLOR_SCHWACH,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[2],
      radius: 5,
    },
  },
]

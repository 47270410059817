import Circle from 'ol/style/Circle'
import Style from 'ol/style/Style.js'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Point from 'ol/geom/Point'
import MultiLineString from 'ol/geom/MultiLineString'
import LineString from 'ol/geom/LineString'
import { getUid } from 'ol/util'
import Feature from 'ol/Feature'
import { featureCollection as turfFeatureCollection } from '@turf/helpers'
import { point as turfPoint } from '@turf/helpers'
import turfCenter from '@turf/center'

const SPIDERSEPARATION = {
  snowSurfaceCondition: { separation: 20, spidersize: 30 },
  hn24h: { separation: 17, spidersize: 25 },
  snowRainAltitude: { separation: 17, spidersize: 25 },
  snowline: { separation: 17, spidersize: 25 },
  whumpfCracks: { separation: 40, spidersize: 50 },
  snowdrift: { separation: 17, spidersize: 25 },
  dangersignAvalanche: { separation: 30, spidersize: 40 },
  danger: { separation: 35, spidersize: 45 },
  problem: { separation: 35, spidersize: 45 },
}
export const SHOW_SPIDER_AT_RESOLUTION = 70

export class Spider {
  constructor(layerRoute) {
    this.layerRoute = layerRoute[0]
    this.dislocatedFeatures = []
    this.mapDistance = 2
  }
  findOverlapping(features, feature, clustered, type, resolution) {
    let neighbors = []
    // these are the ones concerned if a spider is created (depending on spider size)
    const additional = []
    const spiderRadius = type.spidersize * resolution
    features.forEach((otherFeature) => {
      const distance = checkNeighborhood(
        otherFeature,
        feature,
        this.mapDistance
      )
      if (distance < this.mapDistance) {
        neighbors.push(otherFeature)
      } else if (distance < spiderRadius) {
        additional.push(otherFeature)
      }
    })
    if (neighbors.length > 1) {
      neighbors = filterNeighbors(neighbors, clustered)
      if (neighbors.length > 1) {
        additional.forEach((addedFeature) => {
          const uid = getUid(addedFeature)
          clustered[uid] = true
        })
        neighbors = neighbors.concat(additional) // there will be a spider, add the elements in the spider radius
      }
    }
    return neighbors
  }
  resetSpiders() {
    this.layerRoute.getSource().clear(true)
    this.dislocatedFeatures.forEach((feature) => {
      feature.setGeometry(feature.get('originalGeometry'))
    })
    this.dislocatedFeatures = []
  }
  displayOverlappingGroups(features, layer, resolution) {
    const type = SPIDERSEPARATION[layer.get('id')]
    this.resetSpiders()
    const groups = []
    const clustered = []
    // eslint-disable-next-line no-param-reassign
    features = features.filter(function (feature) {
      return feature.get('network') === 'OBSERVER'
    })
    features.forEach((feature) => {
      const newGroup = this.findOverlapping(
        features,
        feature,
        clustered,
        type,
        resolution
      )
      if (newGroup.length > 1) {
        groups.push(newGroup)
      }
    })
    groups.forEach((group) =>
      this.displayOverlapping(group, layer, resolution, type)
    )
  }
  displayOverlapping(overlappingFeatures, layer, resolution, type) {
    const spiderPoints = this.createSpider(
      overlappingFeatures,
      layer,
      resolution,
      type
    )
    this.moveElements(overlappingFeatures, resolution, spiderPoints)
  }
  createSpider(overlappingFeatures, layer, resolution, type) {
    let separation = 20
    if (type) {
      separation = type.separation
    }
    const coord = findMeanPoint(overlappingFeatures)
    const points = generatePointsCircle(
      overlappingFeatures.length,
      coord.geometry.coordinates,
      separation,
      resolution
    )
    const centerPoint = new Feature({
      geometry: new Point(coord.geometry.coordinates),
    })
    centerPoint.setStyle(circleStyle)
    const multiLineString = new MultiLineString([])
    overlappingFeatures.forEach(function (row, index) {
      const cdEnd = points[index]
      multiLineString.appendLineString(
        new LineString([coord.geometry.coordinates, cdEnd])
      )
    })
    this.layerRoute
      .getSource()
      .addFeatures([centerPoint, new Feature({ geometry: multiLineString })])
    return points
  }
  moveElements(overlappingFeatures, resolution, spiderPoints) {
    const movedFeatures = this.dislocatedFeatures
    overlappingFeatures.forEach(function (row, index) {
      const cdEnd = spiderPoints[index]
      row.set('originalGeometry', row.getGeometry())
      movedFeatures.push(row)
      row.setGeometry(new Point(cdEnd))
    })
  }
  resolutionChangeHandler(layer, resolution) {
    if (resolution < SHOW_SPIDER_AT_RESOLUTION) {
      const source = layer.get('source')
      this.displayOverlappingGroups(source.getFeatures(), layer, resolution)
    } else {
      this.resetSpiders()
    }
  }
}

const circleStyle = new Style({
  image: new Circle({
    radius: 2,
    fill: new Fill({ color: 'black' }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
})

const filterNeighbors = function (neighbors, clustered) {
  return neighbors.filter(function (neighbor) {
    const uid = getUid(neighbor)
    if (!(uid in clustered)) {
      clustered[uid] = true
      return true
    } else {
      return false
    }
  })
}

const generatePointsCircle = function (
  count,
  centerPixel,
  separation,
  resolution
) {
  const twoPi = Math.PI * 2
  const startAngle = twoPi / 12
  const legLength = separation * resolution // radius
  const angleStep = twoPi / count
  const res = []
  let i
  let angle
  res.length = count

  for (i = count - 1; i >= 0; i--) {
    angle = startAngle + i * angleStep
    res[i] = [
      centerPixel[0] + legLength * Math.cos(angle),
      centerPixel[1] + legLength * Math.sin(angle),
    ]
  }
  return res
}

const checkNeighborhood = function (otherFeature, feature) {
  return Math.sqrt(
    Math.pow(
      otherFeature.getGeometry().getCoordinates()[0] -
        feature.getGeometry().getCoordinates()[0],
      2
    ) +
      Math.pow(
        otherFeature.getGeometry().getCoordinates()[1] -
          feature.getGeometry().getCoordinates()[1],
        2
      )
  )
}

const findMeanPoint = function (points) {
  const turfFeatures = []
  points.forEach((point) => {
    turfFeatures.push(
      turfPoint([
        point.getGeometry().getCoordinates()[0],
        point.getGeometry().getCoordinates()[1],
      ])
    )
  })
  const features = turfFeatureCollection(turfFeatures)
  return turfCenter(features)
}

import BaseService from '@/services/BaseService'

export default class assessmentService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('ASSESSMENT_SERVICE_URL', 5000)
    return this.srv.getInstance()
  }
  static loadAreas() {
    return this.getInstance().get('areas')
  }
}

<template>
  <b-card footer-tag="footer" header-tag="header">
    <template #header>
      <Header
        v-if="assFlag === false"
        ref="header"
        :title="$t('ui.danger')"
        :obs="form"
        :disableMap="isStudyplotDanger"
        :initialLocation="location"
        :adaptMap="adaptMap"
      />
      <b-row v-if="assFlag === true">
        <b-col>
          <b>{{ $t('ui.danger') }}</b>
        </b-col>
      </b-row>
    </template>
    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button
            id="dangerCancelButton"
            variant="warning"
            @click="routeBack()"
            >{{ $t('ui.cancel') }}</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button id="dangerSendButton" variant="success" @click="onSubmit()">
            <font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}
          </b-button>
        </b-col>
      </b-row>
    </template>

    <div>
      <AreaSectorInput
        :ldId="avalServiceId"
        :disableSector="true"
        v-model="form.hierarchy"
        v-if="assFlag === true"
      />
      <b-row v-if="assFlag === false">
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="locationState"
              aria-describedby="locationFeedback"
            />
            <b-form-invalid-feedback id="locationFeedback">
              {{ $t('msg.validObservationLocation') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="hierarchyState"
              aria-describedby="hierarchyFeedback"
            />
            <b-form-invalid-feedback id="hierarchyFeedback">
              {{ $t('assessment.msg.validHierarchy') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="contentState"
              aria-describedby="contentFeedback"
            />
            <b-form-invalid-feedback id="contentFeedback">
              {{ $t('msg.validMinimalContent') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form @submit.stop.prevent="onSubmit">
      <b-row v-if="!isStudyplotDanger">
        <b-col lg="6">
          <b-form-group :label="$t('observation.label.origin')">
            <b-form-select
              id="dangerOriginInput"
              v-model="form.origin"
              :options="origin"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('danger.label.problem')"
                :info="$t('help.observation.danger.problem')"
              />
            </template>
            <b-form-select
              id="dangerProblemInput"
              v-model="form.problem"
              :options="problem"
              @change="resetProblemDetail()"
              :state="contentAState"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('danger.label.problemDetail')"
                :info="$t('help.observation.danger.problemDetail')"
              />
            </template>
            <b-form-checkbox-group
              id="dangerProblemDetailPatternInput"
              v-model="form.pattern"
              :options="pattern"
              :disabled="form.problem !== 'DRY'"
            />
            <b-form-radio-group
              id="dangerProblemDetailAllDayInput"
              v-model="form.allDay"
              :options="allDay"
              :disabled="form.problem !== 'WET'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template #label>
                  <InfoLabel
                    :text="$t('danger.label.aspectFrom')"
                    :info="$t('help.observation.danger.aspect')"
                  />
                </template>
                <b-form-select
                  id="dangerAspectFromInput"
                  v-model="form.aspectFrom"
                  :options="aspect"
                  :state="aspectState"
                  aria-describedby="aspectFeedback"
                />
                <b-form-invalid-feedback id="aspectFeedback">
                  {{ $t('msg.validAspectPlausibility') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template #label>
                  <InfoLabel
                    :text="$t('danger.label.aspectTo')"
                    :info="$t('help.observation.danger.aspect')"
                  />
                </template>
                <b-form-select
                  id="dangerAspectToInput"
                  v-model="form.aspectTo"
                  :options="aspect"
                  :state="aspectState"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <div class="col-sm-3 d-flex justify-content-center">
              <wind-eurose :aspects="selectedAspects"></wind-eurose>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('danger.label.altitudeValue')"
                :info="$t('help.observation.danger.altitudeAbove')"
              />
            </template>
            <b-form-select
              id="dangerAltitudeAboveInput"
              v-model="form.altitudeAbove"
              :options="altitudeAbove"
              :state="altitudeState"
              aria-describedby="altitudeFeedback"
            />
            <b-form-invalid-feedback id="altitudeFeedback">
              {{ $t('msg.validAltitudePlausibility') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('danger.label.altitudeValue') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.danger.altitude')"
              />
            </template>
            <b-form-select
              id="dangerAltitudeInput"
              v-model="form.altitudeValue"
              :options="height"
              :state="altitudeState"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('danger.label.level')"
                :info="$t('help.observation.danger.level')"
              />
            </template>
            <b-form-select
              id="dangerLevelInput"
              v-model="form.level"
              :options="level"
              :state="contentBState"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('danger.label.subdivision')"
                :info="$t('help.observation.danger.subdivision')"
              />
            </template>
            <b-form-select
              id="dangerSubdivisionInput"
              v-model="form.subdivision"
              :options="subdivision"
              :disabled="isSubdivisionDisabled"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <InfoLabel
            :text="$t('danger.label.dangerConfidental')"
            :info="$t('help.observation.danger.dangerConfidental')"
            hide="true"
          />
          <b-form-checkbox
            id="dangerConfidental"
            size="sm"
            v-model="form.confidential"
          >
            {{ $t('danger.label.dangerConfidental') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script lang="ts">
import Header from '../observation/Header.vue'
import InfoLabel from '../InfoLabel.vue'
import { i18nMixin } from '../I18N'
import moment from 'moment'
import util from '@/scripts/util.js'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import { DANGER } from '@/scripts/const'
import '@/assets/expo/ae-comp-aspectsel45.css'
import { inputFrmRouteBackMixin } from '@/components/observation/InputFrmRouteBackMixin.js'
import WindEurose from '@/components/eurose/WindEurose.vue'
import {
  StudyplotLocation,
  StudyplotService,
} from '@/services/StudyplotService'
import { defineComponent } from 'vue'
import {
  DANGER_LEVELS,
  DANGER_PROBLEMS,
  DangerModel,
} from '@/model/dangerModels'
import { Aspect } from '@/model/commonModels'
import { getAspectRange } from '@/components/eurose/useWindEurose'
import { i18n } from '@/plugins/i18n'
import { deepClone } from '@/scripts/common'
import { beforeRouteEnter } from '@/components/routingConfig'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Header, InfoLabel, WindEurose },
  mixins: [i18nMixin, inputFrmRouteBackMixin],
  props: ['code'],

  data() {
    return {
      assFlag: false as boolean,
      location: undefined as StudyplotLocation | undefined,
      processing: null as boolean | null,
      formSubmitted: null as boolean | null,
      form: {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        problem: null,
        pattern: [],
        allDay: null,
        aspectFrom: null,
        aspectTo: null,
        altitudeAbove: null,
        altitudeValue: null,
        level: null,
        subdivision: null,
        origin: null,
        confidential: false,
      } as DangerModel,
      adaptMap: false as boolean,
      selectedAspects: [] as Aspect[],
      new: true as boolean,
    }
  },
  beforeRouteEnter(to, from, next) {
    beforeRouteEnter(to, from, next)
  },
  watch: {
    'form.level': function (value) {
      if (value == null || value === DANGER_LEVELS.ONE) {
        this.form.subdivision = null
      }
    },
    'form.problem': function (value) {
      if (value !== DANGER_PROBLEMS.DRY) {
        this.form.subdivision = null
      }
    },
    /*eslint-disable complexity*/
    'form.pattern': {
      handler(value, oldValue) {
        // NOTE: changing form.pattern inside here will trigger a second watch event which won't change value again but is not necessary
        if (value && oldValue) {
          if (
            value.findIndex((elem: string) => {
              return elem === 'UNDEFINED'
            }) > -1 &&
            oldValue.findIndex((elem: string) => {
              return elem === 'UNDEFINED'
            }) === -1
          ) {
            // need to de-select other options
            this.form.pattern = ['UNDEFINED']
          } else {
            if (oldValue.length < value.length) {
              // deselect undefined
              const index = this.form.pattern.findIndex((elem) => {
                return elem === 'UNDEFINED'
              })
              if (index > -1) {
                this.form.pattern.splice(index, 1)
              }
            }
          }
        }
      },
      deep: true,
    },
    /*eslint-enable complexity*/
    'form.aspectFrom': {
      handler(newValue) {
        this.selectedAspects =
          newValue && this.form.aspectTo
            ? getAspectRange(newValue, this.form.aspectTo)
            : []
      },
      deep: true,
    },
    'form.aspectTo': {
      handler(newValue) {
        this.selectedAspects =
          newValue && this.form.aspectFrom
            ? getAspectRange(this.form.aspectFrom, newValue)
            : []
      },
      deep: true,
    },
  },
  created() {
    if (this.$store.state.observation.selected) {
      this.new = false
      if (!this.isStudyplotDanger) {
        this.location = deepClone(
          this.$store.state.observation.selected.location
        ) as StudyplotLocation
      }
    }
  },
  /*eslint-disable complexity*/
  mounted() {
    if (this.isStudyplotDanger) {
      // if studyplot danger, use studyplot location
      if (this.$store.state.profile && this.$store.state.profile.studyplots) {
        StudyplotService.getStudyplotByCode(this.code)
          .then((result) => {
            this.location = result.location
          })
          .catch((e) => {
            console.log('Could not find studyplot :' + e)
          })
      }
      // use 'DISTANCE' non-mod.
      this.form.origin = 'DISTANCE'
    }

    if (this.$store.state.observation.selected) {
      this.form = deepClone(
        this.$store.state.observation.selected.danger
      ) as DangerModel
      assessmentUtil.prepareAssessmentObservationInputForm(
        DANGER,
        this.$store.state.observation.selected,
        this.form
      )
    } else {
      if (this.assFlag) {
        const hierarchyMap = this.$store.state.assessmentObs.hierarchyMap
        if (
          util.existValidHierarchy(
            hierarchyMap.get(this.avalServiceId),
            this.$store.state.assessmentObs.date
          )
        ) {
          this.form.hierarchy = deepClone(
            hierarchyMap.get(this.avalServiceId).selection
          )
        }
        this.form.hierarchy.avalServiceId = this.avalServiceId
      }
    }
    // NOTE (20190708): if in edit mode within 15minutes w/ field not filled but set in the meantime in another form, the proposal will be active
    // check whether origin has already been set for selected date
    // console.log('form origin: %o', this.form.origin)
    // console.log('store origin: %o', this.$store.state.observation.origin)
    if (!this.isStudyplotDanger) {
      if (
        !this.form.origin &&
        util.existValidOrigin(
          this.$store.state.observation.origin,
          this.$store.state.observation.date
        )
      ) {
        this.form.origin = this.$store.state.observation.origin.selection
      }
    }
  },
  /*eslint-enable complexity*/
  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)
    this.reset()
  },
  computed: {
    isSubdivisionDisabled() {
      return (
        this.form.level == null ||
        this.form.level === DANGER_LEVELS.ONE ||
        this.form.problem !== DANGER_PROBLEMS.DRY
      )
    },
    height() {
      return [
        { text: i18n.global.t('ui.select'), value: null },
        1000,
        1100,
        1200,
        1300,
        1400,
        1500,
        1600,
        1700,
        1800,
        1900,
        2000,
        2100,
        2200,
        2300,
        2400,
        2500,
        2600,
        2700,
        2800,
        2900,
        3000,
        3100,
        3200,
        3300,
        3400,
        3500,
        3600,
        3700,
        3800,
        3900,
        4000,
        4100,
        4200,
      ]
    },
    problem() {
      return this.options('danger', 'problem')
    },
    pattern() {
      return this.options('danger', 'pattern', true)
    },
    allDay() {
      return this.options('danger', 'allDay', true)
    },
    altitudeAbove() {
      return this.options('danger', 'altitudeAbove')
    },
    level() {
      return this.options('danger', 'level')
    },
    subdivision() {
      return this.options('danger', 'subdivision')
    },
    origin() {
      return this.options('observation', 'origin')
    },
    aspect() {
      return this.options('common', 'aspect')
    },
    /*eslint-disable complexity*/
    complete() {
      // NOTE: at least one observation field must be filled
      if (!this.assFlag) {
        // !ASS!
        return (
          this.dateState === true &&
          this.locationState === true &&
          this.contentState === true &&
          this.aspectState !== false &&
          this.altitudeState !== false
        )
      } else {
        return (
          this.dateState === true &&
          this.hierarchyState === true &&
          this.contentState === true &&
          this.aspectState !== false &&
          this.altitudeState !== false
        )
      }
    },
    /*eslint-enable complexity*/

    isStudyplotDanger() {
      return !!(
        this.code ||
        (this.$store.state.observation.selected &&
          this.$store.state.observation.selected.studyplotId)
      )
    },
    avalServiceId() {
      return this.$store.getters['profile/getAvalServiceId']
    },
    hierarchyState(): boolean | null {
      if (this.formSubmitted) {
        return !!(this.form.hierarchy && this.form.hierarchy.areaId)
      } else {
        return null
      }
    },
    dateState() {
      if (this.formSubmitted) {
        return !!this.$store.state.observation.date
      } else {
        return null
      }
    },
    locationState() {
      if (this.formSubmitted) {
        if (this.isStudyplotDanger) {
          return !!this.location
        } else {
          return !!this.$store.state.observation.location
        }
      } else {
        return null
      }
    },
    contentState() {
      if (this.formSubmitted) {
        // Def. see YT#SLPPWB-417 && YT#:SLPPWB-395 && YT#SLPPWB-435
        return !!(this.form.problem || this.form.level)
      } else {
        return null
      }
    },
    contentAState() {
      // only for problem
      if (this.formSubmitted) {
        if (!this.form.problem) {
          if (this.form.level) {
            return null
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return null
      }
    },
    contentBState() {
      // only for level
      if (this.formSubmitted) {
        if (!this.form.level) {
          if (this.form.problem) {
            return null
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return null
      }
    },
    /*eslint-disable complexity*/
    aspectState() {
      if (this.formSubmitted) {
        if (this.form.aspectTo && this.form.aspectFrom) {
          return true
        } else {
          if (
            (!this.form.aspectTo && this.form.aspectFrom) ||
            (this.form.aspectTo && !this.form.aspectFrom)
          ) {
            return false
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    altitudeState() {
      if (this.formSubmitted) {
        if (
          (this.form.altitudeAbove === true ||
            this.form.altitudeAbove === false) &&
          this.form.altitudeValue
        ) {
          return true
        } else {
          if (
            (!(
              this.form.altitudeAbove === true ||
              this.form.altitudeAbove === false
            ) &&
              this.form.altitudeValue) ||
            ((this.form.altitudeAbove === true ||
              this.form.altitudeAbove === false) &&
              !this.form.altitudeValue)
          ) {
            return false
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    /*eslint-enable complexity*/
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      this.formSubmitted = true
      if (this.complete) {
        const self = this
        if (!this.isStudyplotDanger) {
          if (!this.assFlag) {
            // !ASS!
            this.location = this.$store.state.observation.location
          } else {
            this.location = this.$store.getters[
              'profile/getAreaLocationToAreaId'
            ](this.form.hierarchy.areaId)
          }
        }
        const hierarchyClone = Object.assign({}, self.form.hierarchy)
        this.$store
          .dispatch(
            'observation/submitObservation',
            assessmentUtil.prepareAssessmentObservation(DANGER, {
              danger: this.form,
              type: DANGER,
              location: this.location,
            })
          )
          .then(
            () => {
              // NOTE(20190708): origin will only be set if not set yet within the last 15 minutes
              // NOTE (20190809/csz): re-definition possible
              // if (!this.existValidOrigin(this.$store.state.observation.origin) && this.form.origin) {
              if (!self.isStudyplotDanger && this.form.origin) {
                this.$store.commit('observation/SET_ORIGIN', {
                  selection: this.form.origin,
                  obsdate: this.$store.state.observation.date,
                  cdatetime: moment().valueOf(),
                })
              }
              if (self.assFlag) {
                // !ASS!
                if (hierarchyClone) {
                  const storeHierarchy =
                    self.$store.state.assessmentObs.hierarchyMap.get(
                      self.avalServiceId
                    )
                  self.$store.commit('assessmentObs/SET_HIERARCHY', {
                    avalServiceId: self.avalServiceId,
                    hierarchy: {
                      selection: util.getConsolidatedHierarchySelection(
                        Object.assign({}, hierarchyClone),
                        storeHierarchy ? storeHierarchy.selection : null,
                        true
                      ),
                      date: moment(),
                    },
                  })
                }
              }
              self.processing = false
              self.routeBack()
            },
            (reason) => {
              console.error('Error submitting observation:' + reason)
              self.processing = false
            }
          )
      } else {
        window.scrollTo(0, 0) // NOTE: direct workaround
        this.processing = false
      }
    },
    routeBack() {
      this.routeBackMixedTypes(
        this.adaptMap,
        this.new,
        this.assFlag,
        this.isStudyplotDanger
      )
    },
    resetProblemDetail() {
      this.form.pattern = []
      this.form.allDay = null
    },
    reset() {
      this.formSubmitted = null
      this.form = {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        problem: null,
        pattern: [],
        allDay: null,
        aspectFrom: null,
        aspectTo: null,
        altitudeAbove: null,
        altitudeValue: null,
        level: null,
        subdivision: null,
        origin: null,
        confidential: false,
      }
      this.selectedAspects = []
    },
  },
})
</script>

<!--
Scoped Styles werden, wenn diese Komponente mit 'extends' wiederverwendet
wird, im Parent nicht mehr berücksichtigt. Daher wird dieser Teil ausgelagert
und in den Parents importiert. ACHTUNG: Hier trotzdem auch belassen/importieren.
Dies ist nötig, falls die Komponente, als 'Component-Child' verwendet wird!
-->
<style lang="css" scoped src="./Input.css"></style>

<template>
  <Menu :moment="moment" :routes="routes"></Menu>
</template>

<script>
import Menu from '../Menu'
import AvalancheMap from './AvalancheMap'

export const Routes = [
  {
    name: 'avalanche.label.avalancheEvent',
    path: '/avalanche/map',
    component: AvalancheMap,
    meta: {
      map: true,
      defaultGeneralVariant: 'avalanche.today',
      main: 'avalanche.productMain',
      defaultProductGroupVariant: 'avalanche.today',
    },
    class: 'fa-solid fa-map',
  },

  {
    name: 'avalanche.label.avalancheToday',
    path: '',
    component: AvalancheMap,
    meta: {
      map: true,
      main: 'avalanche.productMain',
      variant: 'avalanche.today',
    },
  },
  {
    name: 'avalanche.label.avalancheYesterday',
    path: '/avalanche/map',
    component: AvalancheMap,
    meta: {
      map: true,
      main: 'avalanche.productMain',
      variant: 'avalanche.yesterday',
    },
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Avalanche',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Menu },
  data() {
    return {
      routes: Routes,
    }
  },
}
</script>

<style scoped></style>

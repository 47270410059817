import observationService from '@/services/observationService.js'
import { SNOW_PROFILE, WHUMPF_CRACKS } from '@/scripts/const.js'
import util from '@/scripts/util.js'
import moment from 'moment'

// Helpers
function getTypeForDbCall(dangersignType) {
  if (dangersignType) {
    switch (dangersignType) {
      case 'whumpfCracks':
      case 'snowdrift':
        return ['DANGERSIGN']
      default:
        return null
    }
  }
  return null
}

const state = {
  dangersignObservations: [], // Beinhaltet dangersignObservation-Features
  avalancheReleases: [],
}

const actions = {
  // Loading Dangersign-Composite
  loadDangersignComposite({ dispatch }, params) {
    const promises = []
    const dangersignObsParams = {
      typ: WHUMPF_CRACKS,
      obsDate: params.obsDate,
      cancel: params.cancel,
    }
    const snowprofileParams = { typ: SNOW_PROFILE, obsDate: params.obsDate }

    // Wumm,Riss und 'keine Lawinenbeobachtungen
    const promiseDangersignFeature = dispatch(
      'loadDangersignObservationsByObservationDateRange',
      dangersignObsParams
    )

    const cancelObj = { cancel: null }
    // Snowprofiles laden
    const promiseSnowprofFeature = dispatch(
      'snowprofile/loadSnowprofileObsFeatureByDaterangeOptUser',
      snowprofileParams,
      { root: true, cancel: cancelObj }
    )
    if (params.cancel) {
      params.cancel.addCancel(cancelObj.cancel)
    }

    promises.push(promiseDangersignFeature, promiseSnowprofFeature)
    return promises
  },

  /*eslint-disable complexity*/
  loadAvalancheReleasesByDateInterval(context, params) {
    const upperBound = moment(params.interval[1]).subtract(1, 'seconds')
    return observationService
      .getObservationsByObservationDateTimeRange(
        params.interval[0],
        upperBound,
        ['DANGERSIGN', 'STUDYPLOTOBSERVATION'],
        params.cancel
      )
      .then((observations) => {
        const avalanchesReleasesOfDateInterval = []
        const relevantObservations = []
        if (observations && observations.data && observations.data.data) {
          observations.data.data.forEach((observation) => {
            if (observation.dangersign && observation.dangersign.avalRelease) {
              if (
                observation.dangersign.avalRelease.currentDay === 'false' &&
                observation.date === params.endDate.format('YYYY-MM-DD')
              ) {
                avalanchesReleasesOfDateInterval.push(observation)
              }
              const endDateCopy = moment(Object.assign({}, params.endDate))
              if (
                observation.dangersign.avalRelease.previousDay === 'false' &&
                observation.date ===
                  endDateCopy.add(1, 'days').format('YYYY-MM-DD')
              ) {
                avalanchesReleasesOfDateInterval.push(observation)
              }
            }
          })
          avalanchesReleasesOfDateInterval.forEach((observation) => {
            const sameObservations = avalanchesReleasesOfDateInterval.filter(
              (element) => {
                return (
                  observation.location.coordinates ===
                  element.location.coordinates
                )
              }
            )
            if (sameObservations.length === 1) {
              relevantObservations.push(observation)
            } else {
              sameObservations.forEach((obs) => {
                if (obs.dangersign.avalRelease.previousDay) {
                  relevantObservations.push(observation)
                }
              })
            }
          })
        }
        context.commit('SET_AVALANCHE_RELEASES', relevantObservations)
        context.commit('SET_EXCEPTION', null, { root: true })
        console.log('avalanches releases by date loaded')
      })
      .catch(function (error) {
        context.commit('SET_AVALANCHE_RELEASES', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
  /*eslint-enable complexity*/

  /**
   * Loading Dangersign-Observation by Observation Daterange
   */
  loadDangersignObservationsByObservationDateRange(context, params) {
    const promises = []
    let individualError = false
    const handleError = function (error) {
      // NOTE (20190816/csz): Partial error (one of the services failed), therefore do not set feature
      //                      collection globally to empty -> problem if both fail individually and
      //                      not globally and collection remains in an old non-valid state...
      individualError = true
      console.error(error)
      if (!(error.response && error.response.status === 401)) {
        context.commit('SET_EXCEPTION', error.message, { root: true })
      }
      return error
    }

    let lowerBound = null
    let upperBound = null
    const interval = params.obsDate.split('/')
    if (interval && interval.length === 2) {
      lowerBound = moment(interval[0]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
      upperBound = moment(interval[1]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
      const datePeriod = params.obsDate.split('P')
      lowerBound = moment(datePeriod[0]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
      upperBound = moment(lowerBound)
        .add(moment.duration('P' + datePeriod[1]))
        .subtract(1, 'seconds')
    }

    const cancelObj = { cancel: null }
    if (params.cancel) {
      params.cancel.addCancel(cancelObj.cancel)
    }
    const cancelObj2 = { cancel: null }
    const type = getTypeForDbCall(params.typ)
    promises.push(
      observationService.getObservationsByObservationDateTimeRange(
        lowerBound,
        upperBound,
        type,
        cancelObj2
      )
    ) //  type=dangersign not working
    if (params.cancel) {
      params.cancel.addCancel(cancelObj2.cancel)
    }

    // NOTE (20190816/csz): deliver partial results even if one of the services returns w/ error - better than no results at all
    return Promise.all(
      promises.map(function (promise) {
        return promise.catch(handleError)
      })
    )
      .then(function (res) {
        const featureCollection = {
          type: 'FeatureCollection',
          crs: { type: 'name', properties: { name: 'EPSG:21781' } },
          features: [],
        }
        res.forEach((result) => {
          if (result && result.data) {
            if (result.data.data) {
              // i.e. features delivered from observation service
              const dangersignObservations = result.data.data.filter(
                (obs) => obs.dangersign
              )
              const features = util.mapObservationsToFeatures(
                'dangersign',
                dangersignObservations
              )
              features.forEach((f) => {
                if (f.properties.dangersign[params.typ]) {
                  f.properties[params.typ] = f.properties.dangersign[params.typ]
                }
              })
              featureCollection.features =
                featureCollection.features.concat(features) // suppose that EPSG:21781, no more check here
            }
          }
        })
        if (individualError !== true) {
          // i.e. all promises w/o error
          context.commit('SET_EXCEPTION', null, { root: true })
        }
        console.log('dangersign observations by date loaded')
        return featureCollection
      })
      .catch(function (error) {
        context.commit('SET_DANGERSIGN_OBSERVATIONS', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

const mutations = {
  SET_DANGERSIGN_OBSERVATIONS(state, dangersignObservations) {
    state.dangersignObservations = dangersignObservations
  },
  SET_AVALANCHE_RELEASES(state, avalancheReleases) {
    state.avalancheReleases = avalancheReleases
  },
}

export default {
  namespaced: true,
  state,
  getters: {},
  actions,
  mutations,
}

export const HnHnwHsStates = {
  methods: {
    // Im Feld Neuschnee dürfen nur die Werte 0/0,1/0,2/0,3/0,4/1/2/3/……… bis 200 eingegeben werden.
    // eslint-disable-next-line complexity
    getHnState(hnValue: string | null): boolean | null {
      if (!hnValue) return null
      if (!/^[\d,.]+$/.test(hnValue)) return false
      const hn = parseFloat(hnValue.replace(',', '.'))
      return (
        hn >= 0 &&
        hn <= 200 &&
        (hn === 0.1 || hn === 0.2 || hn === 0.3 || hn === 0.4 || hn % 1 === 0)
      )
    },
    getHnwState(hnw: string | null, hn: string): boolean | null {
      if (!hnw) return null
      if (!/^[\d]+$/.test(hnw)) return false
      return (
        this.getIsHnwInRange(hnw) &&
        this.getIsHnwValidForHnGreaterZero(hn, hnw) &&
        this.getIsHnwValidForHnEqualZero(hn, hnw)
      )
    },
    // Im Feld Wasserwert Neuschnee dürfen nur ganze Zahlen zwischen 0 und 200 eingegeben werden.
    getIsHnwInRange(hnw: string): boolean {
      const hnwNum = parseFloat(hnw)
      return hnwNum >= 0 && hnwNum <= 200 && hnwNum % 1 === 0
    },
    // Ist Neuschnee > 1, dann darf Wasserwert NICHT 0 (leer aber schon) sein.
    // Ist Neuschnee 0 bis 1, dann darf für Wesserwert auch 0 eingegeben werden
    getIsHnwValidForHnGreaterZero(hn: string, hnw: string): boolean {
      return !(parseFloat(hn) > 1 && Number(hnw) === 0)
    },
    // Ist Neuschnee = 0, dann darf Wasserwert nur 0 oder 1 sein
    getIsHnwValidForHnEqualZero(hn: string, hnw: string): boolean {
      if (hn && Number(hn) === 0) {
        return Number(hnw) === 0 || Number(hnw) === 1
      }
      return true
    },
    // Im Feld Schneehöhe dürfen nur ganze Zahlen eingegeben werden.
    getHsState(hs: string | null): boolean | null {
      if (!hs) return null
      if (!/^[\d]+$/.test(hs)) return false
      const hsNum = parseFloat(hs)
      return hsNum >= 0 && hsNum % 1 === 0
    },
    nvl(value: boolean | null): boolean {
      return value === null ? true : value
    },
  },
}

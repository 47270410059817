<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <router-view :moment="moment"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from './Profile'

export const Routes = [
  {
    name: 'UserProfile',
    path: '/settings/profile/:uid?',
    component: Profile,
    props: true,
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Settings',
  props: ['moment'],
  data() {
    return {
      routes: Routes,
    }
  },
}
</script>

<style scoped></style>

<template>
  <!-- main product + product variants - route + radio group -->
  <b-card no-body>
    <b-card-header
      header-tag="header"
      class="p-0 text-nowrap"
      v-b-toggle="'variant-' + mainRoute.name"
      role="tab"
    >
      <router-link
        :to="mainRoute.path"
        class="nav-link d-inline-block mr-0"
        active-class="active"
      >
        <font-awesome-icon
          fixed-width
          :icon="mainRoute.class || 'fa-solid fa-times'"
        ></font-awesome-icon
        ><span class="d-none d-sm-inline">&emsp;{{ $t(mainRoute.name) }}</span>
        <span class="when-opened"
          ><font-awesome-icon icon="fa-solid fa-chevron-up"></font-awesome-icon
        ></span>
        <span class="when-closed"
          ><font-awesome-icon
            icon="fa-solid fa-chevron-down"
          ></font-awesome-icon
        ></span>
      </router-link>
    </b-card-header>
    <b-collapse
      :ref="'variant-' + mainRoute.name"
      :id="'variant-' + mainRoute.name"
      role="tabpanel"
      accordion="menu-accordion"
    >
      <b-card-body class="pt-0">
        <b-container class="pr-2">
          <b-form-group style="pointer-events: all">
            <b-form-radio-group
              plain
              stacked
              @input="newRadioSelected"
              v-model="selectedProductVariant"
              :options="variantOptions"
              :id="'radio-' + mainRoute.name"
              :name="'radio-' + mainRoute.name"
            />
          </b-form-group>
        </b-container>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import moment from 'moment'
import emitter from '@/components/EventHub'

export default {
  name: 'MenuProduct',
  props: ['routes'],
  data() {
    return {
      selectedProductVariant: null, // string
      collapseStateChangeFunction: undefined,
    }
  },
  watch: {
    selectedProductVariant: function () {
      if (this.selectedProductVariant) {
        this.updateProductVariant()
      }
    },
  },
  mounted() {
    const array = this.mainRoute.meta.defaultProductGroupVariant.split('.')
    if (this.$store.state.filter[array[0]] !== undefined) {
      this.$emit('reactivateMainRoute', this.mainRoute)
      this.selectedProductVariant = this.$store.state.filter[array[0]]
      this.$root.$emit('bv::toggle::collapse', 'variant-' + this.mainRoute.name)
      this.$store.dispatch('filter/resetVariant', this.selectedProductVariant)
    } else {
      this.setDefaultGroupRoute()
      this.setDefaultGeneralRoute()
    }
    const vm = this
    emitter.on('save-menu-state', (filter) => {
      if (vm.selectedProductVariant) {
        const type = vm.selectedProductVariant.split('.')[0]
        if (
          filter.toLowerCase().includes(type) ||
          (type === 'newsnow' && filter === 'hn24h')
        ) {
          vm.$store.dispatch('filter/setVariant', vm.selectedProductVariant)
        }
      }
    })
    // per default, load map w/ prodvariant selection if within mainroute
    this.collapseStateChangeFunction = (collapseId, isJustShown) => {
      if (collapseId === 'variant-' + this.mainRoute.name) {
        if (isJustShown) {
          if (collapseId !== this.$store.state.filter.currentSubMenu) {
            vm.setDefaultGroupRoute()
          }
          this.$store.commit('filter/SET_CURRENT_SUBMENU', collapseId)
        }
      }
    }
    this.$root.$on('bv::collapse::state', this.collapseStateChangeFunction)
    emitter.on('update::productVariant', (productVariant) => {
      if (
        productVariant &&
        this.selectedProductVariant.startsWith(
          productVariant.slice(0, productVariant.indexOf('.') + 1)
        )
      ) {
        this.selectedProductVariant = productVariant
      }
    })
  },
  computed: {
    mainRoute() {
      return this.routes.find((route) => {
        return route.meta && !route.meta.variant
      })
    },
    variantOptions() {
      const options = []
      this.routes.forEach((item) => {
        if (item.meta) {
          if (item.meta.variant) {
            let text
            if (item.count) {
              text = this.$i18n.tc(item.name, item.count)
            } else {
              text = this.$i18n.t(item.name)
            }
            options.push({ text: text, value: item.meta.variant })
          }
        }
      })
      return options
    },
  },
  methods: {
    setDefaultGroupRoute() {
      if (this.mainRoute && this.mainRoute.meta) {
        if (this.mainRoute.meta.defaultProductGroupVariant) {
          // local default found -> preselect variant option
          const defaultGroupRoute = this.routes.find((route) => {
            return (
              route.meta &&
              route.meta.variant ===
                this.mainRoute.meta.defaultProductGroupVariant
            )
          })
          if (defaultGroupRoute) {
            this.selectedProductVariant = defaultGroupRoute.meta.variant
          }
        }
      }
    },
    // eslint-disable-next-line complexity
    setDefaultGeneralRoute() {
      if (this.mainRoute && this.mainRoute.meta) {
        if (
          this.mainRoute.meta.defaultGeneralVariant &&
          !this.$route.query.openObs
        ) {
          // general default found -> redirect
          const defaultGeneralRoute = this.routes.find((route) => {
            return (
              route.meta &&
              route.meta.variant === this.mainRoute.meta.defaultGeneralVariant
            )
          })
          if (defaultGeneralRoute && defaultGeneralRoute.path) {
            if (this.$route.path !== defaultGeneralRoute.path) {
              this.$router.push(defaultGeneralRoute.path)
            }
          }
        }
      }
    },
    updateProductVariant() {
      const calendar = this.$store.getters['calendar/getCalendar']
      const calPreset = calendar.preset
      if (calPreset === 'current') {
        this.$store.dispatch(
          'calendar/setMoment',
          moment(moment(), 'DD.MM.YYYY HH:mm')
        )
      }
      emitter.emit('update::filter', {
        productVariant: this.selectedProductVariant,
      })
    },
    close() {
      const variant = this.$refs['variant-' + this.mainRoute.name]
      if (variant.show) {
        this.setDefaultGroupRoute()
        this.$root.$emit(
          'bv::toggle::collapse',
          'variant-' + this.mainRoute.name
        )
      }
    },
    newRadioSelected(value) {
      const submenuEntry = {
        key: this.mainRoute.name,
        value: value,
      }
      this.$store.dispatch('filter/updateSubmenuEntry', submenuEntry)
      this.$emit('subEntryChanged', this.mainRoute)
    },
  },
  beforeUnmount() {
    this.$root.$off('bv::collapse::state', this.collapsStateChangeFunction)
    emitter.off('save-menu-state')
    emitter.off('update::productVariant')
  },
}
</script>

<style scoped>
#menu .btn-toolbar {
  background-color: white;
  opacity: 0.8;
  border-radius: 4px;
}
#menu .nav-link {
  background: none;
  border: none;
  padding: 10px;
  margin: 0;
  color: #929292 !important;
}
#menu .nav-link.active {
  font-weight: bold;
  color: #494949 !important;
}
#menu .card-header,
#menu .card {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.row {
  margin-top: 5px;
}
.overlay {
  pointer-events: none;
}

@media (max-width: 36em) {
  .pt-0 {
    padding: 0.25em;
  }

  .pr-2 {
    padding-left: 0.8em;
    padding-right: 0 !important;
  }
}

header.collapsed .when-opened,
header:not(.collapsed) .when-closed {
  display: none;
}
</style>

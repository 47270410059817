import Point from 'ol/geom/Point'
import LineString from 'ol/geom/LineString'
import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature'
import MapProjection from '../MapProjection.js'
import { computeIndex } from '../ActivityIndex.js'
import { getSizeArray } from '../SizeArray.js'
import moment from 'moment'
import store from '../../store/index'

const funcs = {
  addCircle(source, coordinates, projection) {
    const point = new Point(coordinates).transform('EPSG:4326', projection)
    const feature = new Feature(point)
    source.addFeature(feature)
    return feature
  },
  addLine(source, coordinates, projection) {
    const line = new LineString(coordinates).transform('EPSG:4326', projection)
    const feature = new Feature(line)
    source.addFeature(feature)
    return feature
  },
  addPolygon(source, coordinates, projection) {
    const polygon = new Polygon(coordinates).transform('EPSG:4326', projection)
    const feature = new Feature(polygon)
    source.addFeature(feature)
    return feature
  },
  getCenter(Extent) {
    const X = Extent[0] + (Extent[2] - Extent[0]) / 2
    const Y = Extent[1] + (Extent[3] - Extent[1]) / 2
    return [X, Y]
  },
  getArea(points) {
    let area = 0
    let i
    let j
    let point1
    let point2
    for (i = 0, j = points.length - 1; i < points.length; j = i, i++) {
      point1 = this.points[i]
      point2 = this.points[j]
      area += point1.lat * point2.lng
      area -= point1.lng * point2.lat
    }
    area /= 2

    return area
  },
  getAvalancheFeatures(avalanchesOfDate) {
    const mapProjection = new MapProjection()
    const featureArray = []

    // eslint-disable-next-line complexity
    avalanchesOfDate?.forEach((avalanche) => {
      let feature
      const user = store.getters['user/getUserToId'](
        avalanche.userId,
        avalanche.creatorSystem
      )

      const reducedAvalanche = {
        triggerDate: avalanche.triggerDate,
        triggerTime: avalanche.triggerTime,
        id: avalanche.id,
        objectType: avalanche.avalancheClass || 'SINGLE_AVALANCHE',
        triggerTypes: [avalanche.triggerType || 'UNKNOWN'],
        avalancheMoisture: avalanche.avalancheMoisture,
        activityIndex: computeIndex(avalanche),
        avalancheTypes: [avalanche.avalancheType],
        sizeArray: getSizeArray(avalanche),
        noteTexts: [avalanche.noteText],
        observer: user ? user.firstName + ' ' + user.lastName : null,
        // NOTE (20190807/csz): startZoneElevation is either a number (single get) or an array (list). range comes from several get
        startZoneElevationRange:
          avalanche.startZoneElevationRange ||
          (Array.isArray(avalanche.startZoneElevation)
            ? avalanche.startZoneElevation
            : [avalanche.startZoneElevation]),
        detailGeometry: undefined,
        noAvalDetailFound: false,
        reloadErrors: 0,
      }
      if (avalanche.coord) {
        reducedAvalanche.objectType = 'SEVERAL_AVALANCHE'
      }
      let geometry
      if (avalanche.avalancheGeo) {
        switch (avalanche.avalancheGeo.type) {
          case 'Point':
            geometry = new Point(avalanche.avalancheGeo.coordinates)
            break
          case 'LineString':
            geometry = new LineString(avalanche.avalancheGeo.coordinates)
            break
          case 'Polygon':
            geometry = new Polygon(avalanche.avalancheGeo.coordinates)
            break
          default:
            break
        }
      } else if (avalanche.coord && avalanche.coord.type) {
        geometry = new Point(avalanche.coord.coordinates)
      }

      let additionalStartZonePoint
      let geometryElement
      if (avalanche.startZoneGeo && avalanche.startZoneGeo.type === 'Point') {
        geometryElement = new Point(avalanche.startZoneGeo.coordinates)
        if (!geometry) {
          geometry = geometryElement
        } else {
          additionalStartZonePoint = geometryElement
        }
      }

      if (avalanche.depositGeo && avalanche.depositGeo.type === 'Point') {
        geometryElement = new Point(avalanche.depositGeo.coordinates)
        if (!geometry) {
          geometry = geometryElement
        }
      }

      if (geometry) {
        try {
          geometry.transform('EPSG:4326', mapProjection.getOldProjection())
          feature = new Feature(geometry)
          if (additionalStartZonePoint) {
            additionalStartZonePoint.transform(
              'EPSG:4326',
              mapProjection.getOldProjection()
            )
            feature.set('startZonePoint', additionalStartZonePoint)
          }
          feature.set('avalanche', reducedAvalanche)
          feature.set('uid', avalanche.userId)
          feature.set('creatorSystem', avalanche.creatorSystem)
          feature.set(
            'timestamp',
            avalanche.creation ? moment(avalanche.creation).format('X') : null
          )
          featureArray.push(feature)
        } catch (e) {
          console.error(
            'avalanche geometry could not be transformed: ' +
              reducedAvalanche.id
          )
        }
      }
    })

    return {
      type: 'FeatureCollection',
      crs: { type: 'name', properties: { name: 'EPSG:4326' } },
      features: featureArray,
    }
  },
  getAvalancheReleaseFeatures(avalancheReleases) {
    const mapProjection = new MapProjection()
    const featureArray = []
    avalancheReleases.forEach((avalancheReleaseObservation) => {
      let feature
      let avalancheRelease
      if (avalancheReleaseObservation.dangersign.avalRelease.currentDay) {
        avalancheRelease =
          avalancheReleaseObservation.dangersign.avalRelease.currentDay ===
          'true'
      } else {
        avalancheRelease =
          avalancheReleaseObservation.dangersign.avalRelease.previousDay ===
          'true'
      }
      const geometry = new Point(
        avalancheReleaseObservation.location.coordinates
      )
      if (geometry) {
        geometry.transform('EPSG:4326', mapProjection.getOldProjection())
        feature = new Feature(geometry)
        feature.set('avalancheRelease', {
          value: avalancheRelease,
          id: avalancheReleaseObservation.id,
        })
        feature.set('id', avalancheReleaseObservation.id)
        feature.set('network', avalancheReleaseObservation.network)
        feature.set('oDate', avalancheReleaseObservation.date)
        feature.set('observer', avalancheReleaseObservation.observer)
        feature.set('timestamp', avalancheReleaseObservation.timestamp / 1000)
        feature.set('cTimestamp', avalancheReleaseObservation.cTimestamp / 1000)
        feature.set('uid', avalancheReleaseObservation.uid)
        feature.set('creatorSystem', avalancheReleaseObservation.creatorSystem)
        featureArray.push(feature)
      }
    })

    return {
      type: 'FeatureCollection',
      crs: { type: 'name', properties: { name: 'EPSG:4326' } },
      features: featureArray,
    }
  },
  getPointOfFeature(feature) {
    if (feature.getGeometry().getType() === 'Point') {
      return feature.getGeometry()
    } else {
      const point = funcs.getCenter(feature.getGeometry().getExtent())
      return new Point(point)
    }
  },
  getPointOfAval(aval) {
    const avalFeature = funcs.getAvalancheFeatures([aval]).features[0]
    if (avalFeature) {
      return funcs.getPointOfFeature(avalFeature)
    }
    return null
  },
}

export default funcs

<template>
  <div>
    <FilterMultiple :filterOptions="translatedFilterOptions" />
    <Legend class="danger-legend" :legend="legend" />
    <Legend class="aux-legend" :legend="auxLegend" />
    <CommonLayersPopup></CommonLayersPopup>
    <LayerAux
      :layerOptions="translatedAuxLayerOptions"
      :productVariant="filter.productVariant"
    />
    <b-alert
      :show="alertCountDown"
      dismissible
      variant="info"
      @dismissed="alertCountDown = 0"
      @dismiss-count-down="alertCountDownChanged"
      class="alert-text"
      >{{ alertText }}
    </b-alert>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..." />
    </div>
    <DetailModal det-id="detailModal" :obsIds="obsIds" :aggregate="true" />
    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assm"
      :aggregate="true"
    />
  </div>
</template>

<script>
import Legend from '../Legend'
import FilterMultiple from '../FilterMultiple'
import LayerAux from '../LayerAux'
import DetailModal from '../observation/DetailModal'
import { DangerStyle } from '../styles/dangerStyling/DangerStyle'
import { BulletinStyle } from '../styles/dangerStyling/BulletinStyle'
import { DistributionAuxStyle } from '../styles/dangerStyling/DistributionAuxStyle'
import bulletinUtil from '../../scripts/bulletin/bulletinUtil'
import { i18nMixin } from '../I18N'
import moment from 'moment'
import { Canceler } from '@/scripts/requestUtil.js'
import AbstractBulletinAux from './AbstractBulletinAux'
import DetailModalAssessment from '../assessment/detail/DetailModalAssessment'
import util from '../../scripts/util'
import { dangerDetailMixin } from '@/components/danger/dangerDetailMixin'
import { BulletinTime } from '@/model/dangerModels'
import emitter from '@/components/EventHub'
import { deepClone } from '@/scripts/common'

const CancelNormal = Canceler.getCancelObj()
const CancelAuxViz1 = Canceler.getCancelObj()
const CancelAuxViz2 = Canceler.getCancelObj()
const BULLETIN_DATE_FORMAT = 'DD.MM.YY HH:mm'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dangermap',
  props: ['moment', 'calendar'],
  mixins: [i18nMixin, dangerDetailMixin],
  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
        this.refreshLayers()
      }
    },
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Legend,
    DetailModal,
    FilterMultiple,
    LayerAux,
    DetailModalAssessment,
  },
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: AbstractBulletinAux,
  data() {
    return {
      isAdmin: this.$store.state.user.user.isAdmin,
      clientRoles: this.$store.state.user.user.clientRoles,
      legend: new DangerStyle(this.$i18n.locale).legend(),
      auxLegend: new BulletinStyle(this.$i18n.locale).legend(),
      filterOptions: null,
      obsIds: [],
      assm: {},
      filter: {}, // current filter selection incl. productVariant and generic + danger specific filters
      alertSecs: 1,
      alertCountDown: 0,
      alertText: null,
      isMoving: false,
      isRefreshing: false,
      isLayerRefreshing: false,
      initialFilterValue: true, // set to false once filter event w/ filter
      lastMoment: undefined,
      lastDatePreset: undefined,
    }
  },
  beforeUnmount() {
    emitter.off('update::filter')
    emitter.off('repos::map')

    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests('normal')
    this.cancelRequests('aux1')
    this.cancelRequests('aux2')
  },
  mounted() {
    // for refresh in case of variant group change not handled by menuvariant...
    this.$nextTick(function () {
      emitter.emit('update::filter', {
        productVariant: this.$route.meta.defaultProductGroupVariant,
        own: true,
      })
    })

    /*eslint-disable complexity*/
    emitter.on('update::filter', (filter) => {
      // NOTE: for both productVariant and filter, react only on those of type 'danger'
      let isRightVariant = false
      let isRightFilter = false
      let refresh = false
      if (
        filter &&
        filter.productVariant &&
        filter.productVariant.startsWith('danger.')
      ) {
        if (
          filter.own === true ||
          (this.filter.productVariant &&
            this.filter.productVariant !== filter.productVariant)
        ) {
          // ignore events coming from menuvariant, except for variant changes
          refresh = true
        }
        this.filter.productVariant = filter.productVariant
        isRightVariant = true
      }
      if (filter && filter.filters) {
        const dangerFilters = {}
        for (const filterKey in filter.filters) {
          if (
            Object.prototype.hasOwnProperty.call(filter.filters, filterKey) &&
            filterKey.startsWith('danger.')
          ) {
            dangerFilters[filterKey] = filter.filters[filterKey]
            isRightFilter = true
          }
          this.filter.filters = dangerFilters
        }
        if (!this.initialFilterValue) {
          if (isRightFilter) {
            refresh = true
          }
        } else {
          this.initialFilterValue = false
        }
      }
      // NOTE: update w/ productVariant, filter or both possible
      if (isRightVariant || isRightFilter) {
        this.filterOptions = this.getDangerFilterOptions()
        if (refresh) {
          this.refresh()
          // update layer selection / availability based on product variant (LWD only)
          if (isRightVariant) {
            // SLPPWB-1039: Mit dem selectionIndex, kann die Default-Einstellung für die einzelnen Produktvarianten gesetzt werden.
            // Neu soll dies keine Gefahrenkarte sein.
            switch (this.filter.productVariant) {
              case 'danger.productVariantAll':
                this.$nextTick(function () {
                  emitter.emit('update::layerList', {
                    key: 'danger.bulletin',
                    selectionIndex: null,
                    visibilityIndex: null,
                  })
                })
                break
              case 'danger.productVariantDry':
                this.$nextTick(function () {
                  emitter.emit('update::layerList', {
                    key: 'danger.bulletin',
                    selectionIndex: null,
                    visibilityIndex: 0,
                  })
                })
                break
              case 'danger.productVariantWet':
                this.$nextTick(function () {
                  emitter.emit('update::layerList', {
                    key: 'danger.bulletin',
                    selectionIndex: null,
                    visibilityIndex: 1,
                  })
                })
                break
            }
          }
        }
      }
    })
    /*eslint-enable complexity*/
    emitter.on('repos::map', (flag) => {
      this.isMoving = flag && flag === true
    })
  },

  computed: {
    calendarPresetInterval() {
      return this.calendar.getPresetInterval()
    },
    bulletinMoment() {
      if (!this.isAdmin) {
        return moment(this.calendar.getMoment())
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
      } else {
        switch (this.$store.state.calendar.preset) {
          case '1000':
            console.log(
              moment(this.calendar.getMoment())
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
            )
            return moment(this.calendar.getMoment())
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss[Z]')
          case '1700': {
            const format = 'HH:mm:ss'
            const time = moment(this.calendar.getMoment(), format)
            const beforeTime = moment('17:00:00', format).add(-1, 'day')
            const afterTime = moment('10:00:00', format)
            if (time.isBetween(beforeTime, afterTime)) {
              return moment(this.calendar.getMoment())
                .add(-10, 'hour')
                .startOf('day')
                .set('hour', 17)
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
            } else {
              return moment(this.calendar.getMoment())
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
            }
          }
          default:
            return moment(this.calendar.getMoment())
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      }
    },
    isLoading() {
      return this.isMoving || this.isRefreshing || this.isLayerRefreshing
    },
    unknownOptions() {
      return { value: 'UNKNOWN', text: this.$i18n.t('danger.legend.unknown') }
    },
    translatedFilterOptions() {
      if (this.filterOptions) {
        return this.getDangerFilterOptions()
      } else {
        return this.filterOptions
      }
    },
  },
  methods: {
    findAndShowObservation(observationId, featureCollection) {
      const feature = featureCollection.features.find((item) => {
        return item.properties.id === observationId
      })
      if (feature) {
        const isAssessment = util.isAssessmentContent(
          feature.properties,
          'danger'
        )
        if (isAssessment) {
          // Objekt klonen, damit der Watcher eine Änderung entdeckt
          this.assm = Object.assign({}, feature.properties)
        } else {
          this.obsIds = []
          this.obsIds.push(observationId)
        }
      }
    },
    getDangerFilterOptions() {
      // build specific filter options for form select display
      switch (this.filter.productVariant) {
        case 'danger.productVariantAll':
        case 'danger.productVariantDry':
        case 'danger.productVariantWet':
          return {
            items: [
              {
                key: 'danger.origin',
                label: this.$i18n.t('observation.label.origin'),
                options: this.options('observation', 'origin', true).concat(
                  this.unknownOptions
                ),
              },
            ],
          }
        default:
          return {
            items: [],
          }
      }
    },
    /*eslint-disable complexity, no-param-reassign*/
    applyDangerFilter(features, filter) {
      let filteredFeatures = features
      // filter delivered features based on productVariant and filter selection
      if (filter && filteredFeatures) {
        if (filter.productVariant) {
          switch (filter.productVariant) {
            case 'danger.productVariantAll': // no action
              break
            case 'danger.productVariantDry':
              filteredFeatures = filteredFeatures.filter(
                (feature) => feature.properties.danger.problem === 'DRY'
              )
              break
            case 'danger.productVariantWet':
              filteredFeatures = filteredFeatures.filter(
                (feature) =>
                  feature.properties.danger.problem === 'WET' ||
                  feature.properties.danger.problem === 'FULL_DEPTH'
              )
              break
          }
        }
        if (filter.filters) {
          for (const filterKey in filter.filters) {
            switch (filterKey) {
              case 'danger.origin':
                filteredFeatures = filteredFeatures.filter(
                  (feature) =>
                    (!feature.properties.danger.origin &&
                      filter.filters[filterKey].includes('UNKNOWN')) ||
                    filter.filters[filterKey].includes(
                      feature.properties.danger.origin
                    )
                )
                break
            }
          }
        }
      }
      return filteredFeatures
    },
    /*eslint-enable complexity, no-param-reassign*/
    alertCountDownChanged(alertCountDown) {
      this.alertCountDown = alertCountDown
    },
    refreshLayers() {
      if (this.auxLayerSelection && this.auxLayerSelection.layers) {
        const self = this

        for (const layerKey in this.auxLayerSelection.layers) {
          if (layerKey === 'danger.bulletin') {
            self.cancelRequests('aux1')
            self.cancelRequests('aux2')

            emitter.emit('layer', {
              name: 'bulletin.FIRST_DANGERMAP',
              auxiliary: true,
              operation: ['removeAuxLayer'],
              supportFilter: true,
              supportLayer: true,
            })
            emitter.emit('layer', {
              name: 'bulletin.SECOND_DANGERMAP',
              auxiliary: true,
              operation: ['removeAuxLayer'],
              supportFilter: true,
              supportLayer: true,
            })
            emitter.emit('update::mapSubtitle', {
              subtitle: '',
              method: 'append',
            })

            this.auxLayerSelection.layers[layerKey].forEach((layer) => {
              self.isLayerRefreshing = true
              // NOTE: either first dangerMap, second dangerMap or no layer to be displayed XOR
              switch (layer) {
                case 'FIRST_DANGERMAP':
                  self.$store
                    .dispatch('bulletin/loadLatestActiveBulletinAtAsGeoJson', {
                      timestamp: self.bulletinMoment,
                      rating: 1,
                      clientRoles: self.clientRoles,
                      cancel: CancelAuxViz1,
                    })
                    .then(
                      (geoResult) => {
                        if (
                          geoResult &&
                          (bulletinUtil.hasBulletinMapRating(
                            geoResult.data,
                            true,
                            1
                          ) ||
                            bulletinUtil.isTxtBulletin(geoResult.data, true))
                        ) {
                          // i.e. request not cancelled, there is a current bulletin
                          emitter.emit('update::mapSubtitle', {
                            subtitle:
                              self.$i18n.t('bulletin.label.publicationDate') +
                              ': ' +
                              moment(
                                bulletinUtil.getBulletinValidFrom(
                                  geoResult.data,
                                  true
                                )
                              ).format(BULLETIN_DATE_FORMAT),
                            method: 'append',
                          })

                          const featureCollection = Object.assign(
                            {},
                            geoResult.data
                          )

                          const bulleStyle = new BulletinStyle(
                            self.$i18n.locale
                          )
                          const bulleStyleFunction =
                            !bulletinUtil.isTxtBulletin(geoResult.data, true)
                              ? bulleStyle.dangerStyleFunction
                              : bulleStyle.dangerTextStyleFunction
                          const bulleDistStyle = new DistributionAuxStyle(
                            self.$i18n.locale
                          )
                          const bulleMouseOverFunction =
                            bulleDistStyle.mouseOverFunction

                          emitter.emit('layer', {
                            name: 'bulletin.FIRST_DANGERMAP',
                            auxiliary: true,
                            operation: ['addAuxLayer', 'placeUnderLayers'],
                            styleFunction: bulleStyleFunction,
                            supportFilter: true,
                            supportLayer: true,
                            features: featureCollection,
                            onClick: this.getDangerClickHandler(
                              this.$i18n,
                              BulletinTime.Morning
                            ),
                          })
                          emitter.emit('mouseOver', bulleMouseOverFunction)
                        } else {
                          self.alertText = self.$i18n.t(
                            'bulletin.label.alertNoBulletin'
                          )
                          self.alertCountDown = self.alertSecs
                        }
                        self.isLayerRefreshing = false
                      },
                      (geoReason) => {
                        if (
                          geoReason &&
                          geoReason.response &&
                          geoReason.response.status === 404
                        ) {
                          self.alertText = self.$i18n.t(
                            'bulletin.label.alertNoBulletin'
                          )
                          self.alertCountDown = self.alertSecs
                        } else {
                          console.error(
                            'Error getting latest active bulletin geojson at specified datetime'
                          )
                        }
                        self.isLayerRefreshing = false
                      }
                    )
                  CancelAuxViz1.addCancel(CancelAuxViz1.cancelGeo)
                  break
                case 'SECOND_DANGERMAP':
                  self.$store
                    .dispatch('bulletin/loadLatestActiveBulletinAtAsGeoJson', {
                      timestamp: self.bulletinMoment,
                      rating: 2,
                      clientRoles: self.clientRoles,
                      cancel: CancelAuxViz2,
                    })
                    .then(
                      (geoResult) => {
                        if (
                          geoResult &&
                          bulletinUtil.hasBulletinMapRating(
                            geoResult.data,
                            true,
                            2
                          )
                        ) {
                          // i.e. request not cancelled, there is a current bulletin
                          emitter.emit('update::mapSubtitle', {
                            subtitle:
                              self.$i18n.t('bulletin.label.publicationDate') +
                              ': ' +
                              moment(
                                bulletinUtil.getBulletinValidFrom(
                                  geoResult.data,
                                  true
                                )
                              ).format(BULLETIN_DATE_FORMAT),
                            method: 'append',
                          })

                          const featureCollection = Object.assign(
                            {},
                            geoResult.data
                          )

                          const bulleStyle = new BulletinStyle(
                            self.$i18n.locale
                          )
                          const bulleStyleFunction =
                            bulleStyle.dangerStyleFunction
                          const bulleDistStyle = new DistributionAuxStyle(
                            self.$i18n.locale
                          )
                          const bulleMouseOverFunction =
                            bulleDistStyle.mouseOverFunction

                          emitter.emit('layer', {
                            name: 'bulletin.SECOND_DANGERMAP',
                            auxiliary: true,
                            operation: ['addAuxLayer', 'placeUnderLayers'],
                            styleFunction: bulleStyleFunction,
                            supportFilter: true,
                            supportLayer: true,
                            features: featureCollection,
                            onClick: this.getDangerClickHandler(
                              this.$i18n,
                              BulletinTime.Later
                            ),
                          })
                          emitter.emit('mouseOver', bulleMouseOverFunction)
                        } else {
                          self.alertText = self.$i18n.t(
                            'bulletin.label.alertNoBulletin'
                          )
                          self.alertCountDown = self.alertSecs
                        }
                        self.isLayerRefreshing = false
                      },
                      (geoReason) => {
                        if (
                          geoReason &&
                          geoReason.response &&
                          geoReason.response.status === 404
                        ) {
                          self.alertText = self.$i18n.t(
                            'bulletin.label.alertNoBulletin'
                          )
                          self.alertCountDown = self.alertSecs
                        } else {
                          console.error(
                            'Error getting latest active bulletin geojson at specified datetime'
                          )
                        }
                        self.isLayerRefreshing = false
                      }
                    )
                  CancelAuxViz2.addCancel(CancelAuxViz2.cancelGeo)
                  break
                default:
                  console.log('layer "' + layer + '" - no processing') // NOTE: happens for non-selected layers (-> '')
                  self.isLayerRefreshing = false
              }
            })
          } else {
            console.log('layer type "' + layerKey + '" not supported')
          }
        }
      }
    },
    refresh() {
      this.isRefreshing = true

      console.debug('Cancel requests on load')
      this.cancelRequests('normal')

      this.style = new DangerStyle(this.$i18n.locale)
      this.styleFunction = this.style.styleFunction
      this.distStyle = new DistributionAuxStyle(this.$i18n.locale)
      this.mouseOverFunction = this.distStyle.mouseOverFunction
      const self = this

      this.$store
        .dispatch(
          'danger/loadDangerObservationFeaturesByObservationDateTimeRange',
          {
            observationDateTimeRange: this.calendarPresetInterval,
            isAdmin: this.isAdmin,
            cancel: CancelNormal,
          }
        )
        .then(() => {
          console.debug(
            'Number of features to draw:',
            self.$store.state.danger.dangerFeatureCollection.features?.length
          )
          const featureCollection = deepClone(
            self.$store.state.danger.dangerFeatureCollection
          )
          featureCollection.features = self.applyDangerFilter(
            featureCollection.features,
            self.filter
          )
          const dangerClickHandler = function (feature) {
            // NOTE: reset values in individual if-else branches rather than here, because set value
            // triggers detail modal immediately /watch!/ - and it might be the wrong modal...
            // .show modals don't serve to anything..
            if (feature) {
              const dangerFeature = featureCollection.features.find((item) => {
                return item.properties.id === feature.get('id')
              })
              if (dangerFeature) {
                const isAssessment = util.isAssessmentContent(
                  dangerFeature.properties,
                  'danger'
                )
                if (isAssessment) {
                  // Objekt klonen, damit der Watcher eine Änderung entdeckt
                  self.assm = Object.assign({}, dangerFeature.properties)
                } else {
                  self.obsIds = []
                  self.obsIds.push(feature.get('id'))
                }
              }
            }
          }
          const resolutionChangeHandler = function (
            resolution,
            layers,
            spider
          ) {
            spider.resolutionChangeHandler(
              layers['danger'].vectorLayer,
              resolution
            )
          }

          emitter.emit('layer', {
            name: 'danger',
            features: featureCollection,
            styleFunction: self.styleFunction,
            onClick: dangerClickHandler,
            style: self.style,
            supportFilter: true,
            supportLayer: true,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit('mouseOver', self.mouseOverFunction)
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }
          self.isRefreshing = false
        })
        .catch((e) => {
          console.error(
            'Catch request loadDangerObservationFeaturesByObservationDateTimeRange: ' +
              e
          )
          alert(e)
          self.isRefreshing = false
        })
      CancelNormal.addCancel(CancelNormal.cancel)
    },
    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },
    cancelRequests(layerType) {
      switch (layerType) {
        case 'normal':
          CancelNormal.cancelAll()
          break
        case 'aux1':
          CancelAuxViz1.cancelAll()
          break
        case 'aux2':
          CancelAuxViz2.cancelAll()
          break
      }
    },
  },
}
</script>

<style lang="css" scoped src="./AbstractBulletinAux.css"></style>
<style scoped>
img {
  width: 600px;
}

.danger-legend {
  width: fit-content;
}

.aux-legend {
  width: fit-content;
  right: 14.8em;
}

.legend_mobile {
  top: 6.9em;
}

.aux-legend.legend_mobile {
  right: 12.4em;
}

.alert-text {
  z-index: 100;
}

.overlay .alert {
  position: absolute;
  top: 0;
  width: 100%;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

<template>
  <b-card footer-tag="footer" header-tag="header">
    <template #header>
      <Header
        ref="header"
        :title="$t('ui.snow')"
        :obs="form"
        :initial-location="location"
        :adapt-map="adaptMap"
      />
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click="routeBack()" variant="warning">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button @click="onSubmit()" :disabled="!valid" variant="success"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('snow.label.newSnowEstimated')"
                :info="$t('help.observation.newSnowEstimated')"
              />
            </template>
            <snowmap-component v-model="newSnowEstimatedMap" />
          </b-form-group>
        </b-col>
        <b-col lg="6" />
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowRainAltitude') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowRainAltitude')"
              />
            </template>
            <b-form-select
              id="snowpackInput5"
              :options="masl"
              v-model="form.snowRainAltitude"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowlineNorth') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowlineNorth')"
              />
            </template>
            <b-form-select
              id="snowpackInput4"
              :options="masl"
              v-model="form.snowline.north"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowlineSouth') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowlineSouth')"
              />
            </template>
            <b-form-select
              id="snowpackInput3"
              :options="masl"
              v-model="form.snowline.south"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('snow.label.snowSurfaceConditionNorth')"
                :info="$t('help.observation.snowSurfaceConditionNorth')"
              />
            </template>
            <b-form-select
              id="snowpackInput2.1"
              :options="snowSurfaceCondition"
              v-model="form.snowSurfaceCondition.snowSurfaceConditionNorth"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('snow.label.snowSurfaceConditionSouth')"
                :info="$t('help.observation.snowSurfaceConditionSouth')"
              />
            </template>
            <b-form-select
              id="snowpackInput2.2"
              :options="snowSurfaceCondition"
              v-model="form.snowSurfaceCondition.snowSurfaceConditionSouth"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Header from '../observation/Header'
import InfoLabel from '../InfoLabel'
import { i18nMixin } from '../I18N'
import SnowmapComponent from '@/components/snowmap/SnowmapComponent.vue'
import { deepClone } from '@/scripts/common'

const altitudeList = [1000, 1500, 2000, 2500, 3000, 3500, 4000]
function createEmptySnowmap() {
  return Object.fromEntries(altitudeList.map((height) => [height, null]))
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  components: {
    SnowmapComponent,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    InfoLabel,
  },
  mixins: [i18nMixin],
  data() {
    return {
      location: null,
      newSnowEstimatedMap: createEmptySnowmap(),
      form: {
        snowSurfaceCondition: {
          snowSurfaceConditionNorth: null,
          snowSurfaceConditionSouth: null,
        },
        snowRainAltitude: null,
        snowline: { south: null, north: null },
      },
      processing: null,
      adaptMap: false,
      new: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
      if (from.path.includes('/observation/list')) {
        vm.adaptMap = true
      }
    })
  },
  computed: {
    masl() {
      const from400to4200 = Array.from({ length: 20 }, (_, i) => 400 + i * 200)

      return [
        { text: this.$i18n.t('ui.select'), value: null },
        ...from400to4200,
      ]
    },
    snowSurfaceCondition() {
      return this.options('snow', 'snowSurfaceCondition')
    },
    valid() {
      return this.locationState && this.contentState
    },
    locationState() {
      return this.$store.state.observation.location
    },
    /*eslint-disable complexity*/
    contentState() {
      return (
        (this.newSnowEstimatedMap &&
          altitudeList.some(
            (height) => this.newSnowEstimatedMap[height] !== null
          )) ||
        (this.form.snowSurfaceCondition &&
          (this.form.snowSurfaceCondition.snowSurfaceConditionNorth ||
            this.form.snowSurfaceCondition.snowSurfaceConditionSouth)) ||
        this.form.snowRainAltitude ||
        this.form.snowline.north ||
        this.form.snowline.south
      )
    },
    /*eslint-enable complexity*/
  },
  created() {
    if (this.$store.state.observation.selected) {
      this.location = deepClone(this.$store.state.observation.selected.location)
      this.new = false
    }
  },
  mounted() {
    if (this.$store.state.observation.selected) {
      this.form = deepClone(this.$store.state.observation.selected.snow)
      const newSnowArray =
        this.$store.state.observation.selected.snow.newSnowEstimated

      altitudeList.forEach((altitude, idx) => {
        this.newSnowEstimatedMap[altitude] = newSnowArray[idx] || null
      })
    }
  },
  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)
    this.reset()
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const formCopy = Object.assign({}, this.form)
      formCopy.newSnowEstimated = altitudeList.map(
        (altitude) => this.newSnowEstimatedMap[altitude]
      )
      const self = this
      this.$store
        .dispatch('observation/submitObservation', {
          snow: formCopy,
          type: 'snow',
        })
        .then(
          () => {
            self.processing = false
            self.routeBack()
          },
          (reason) => {
            console.error('Error submitting observation:' + reason)
            self.processing = false
          }
        )
    },
    routeBack() {
      if (this.adaptMap) {
        if (this.new) {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
              openObs: this.$store.state.observation.selected.id,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    reset() {
      this.newSnowEstimatedMap = createEmptySnowmap()
      this.form = {
        snowSurfaceCondition: {
          snowSurfaceConditionNorth: null,
          snowSurfaceConditionSouth: null,
        },
        snowRainAltitude: null,
        snowline: { south: null, north: null },
      }
    },
  },
}
</script>

<style scoped>
.label {
  font: bold 12px Helvetica;
}
.modal-body {
  padding: 0 !important;
}
.card {
  margin-bottom: 10px;
}
.card-body {
  padding-bottom: 0;
}
.card-header {
  padding-left: 10px;
  padding-right: 10px;
}
</style>

'use strict'

import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import RegularShape from 'ol/style/RegularShape'
import { SVG } from '@/components/styles/SVG'
import { i18n } from '@/plugins/i18n'
import Text from 'ol/style/Text'

function MeasureStyle() {
  const styleFunction = function (feature, resolution) {
    const actual = feature.get('actual')
    const planned = feature.get('planned') || actual
    if (actual === 'NO_MEASURE' && planned === 'NO_MEASURE') {
      return
    }

    const styleOptions = { image: getSymbol(actual, planned) }
    const text = getText(feature.get('objectType'), resolution)
    if (text != null) {
      styleOptions.text = text
    }

    const style = new Style(styleOptions)
    return [style]
  }
  const getSymbol = function (actual, planned) {
    const fill = new Fill({ color: getColor(actual) })
    const stroke = new Stroke({ color: getColor(planned), width: 3 })

    if (isPathClosure(actual, planned)) return getCircle(fill, stroke)
    else if (!actual) return getTriangle(fill, stroke)
    else return getRect(fill, stroke)
  }
  const getCircle = function (fill, stroke) {
    return new Circle({
      radius: 6,
      fill: fill,
      stroke: stroke,
    })
  }
  const getRect = function (fill, stroke) {
    return new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: 8,
      angle: Math.PI / 4,
    })
  }
  const getTriangle = function (fill, stroke) {
    return new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 3,
      radius: 6,
      angle: Math.PI * 2,
    })
  }
  const getText = function (objectType, resolution) {
    if (resolution > 160 || objectType == null) {
      return null
    }

    return new Text({
      zIndex: 1000,
      text: i18n.global.t(`measure.objectType.${objectType}`),
      offsetY: -14,
      offsetX: 0,
      font: 'bold 12px sans-serif',
      stroke: new Stroke({
        color: 'white',
        width: 2,
      }),
    })
  }
  const legend = function () {
    return {
      title: 'measure.label.measures',
      items: [
        getLegendEntry('none', 'NO_MEASURE', 'NO_MEASURE', 'triangle'),
        {
          label: 'measure.label.paths',
          symbol: {},
          header: true,
          margin: true,
        },
        { line: true },
        getLegendEntry('measure', 'NO_MEASURE', 'CLOSURE', 'circle'),
        getLegendEntry('closure', 'CLOSURE', 'CLOSURE', 'circle'),
        getLegendEntry(
          'closureWinter',
          'CLOSURE_WINTER',
          'CLOSURE_WINTER',
          'circle'
        ),
        getLegendEntry('reversal', 'CLOSURE', 'NO_MEASURE', 'circle'),
        {
          label: 'measure.label.buildings',
          symbol: {},
          header: true,
          margin: true,
        },
        { line: true },
        getLegendEntry('measure', 'NO_MEASURE', 'CLOSURE'),
        getLegendEntry('lockdown', 'LOCKDOWN', 'LOCKDOWN'),
        getLegendEntry('evacuation', 'EVACUATION', 'EVACUATION'),
        getLegendEntry('reversal', 'CLOSURE', 'NO_MEASURE'),
      ],
    }
  }

  const getLegendEntry = function (label, actual, planned, type) {
    return {
      label: 'measure.legend.' + label,
      symbol: getLegendSymbol(actual, planned, type),
    }
  }

  const getLegendSymbol = function (actual, planned, type = 'rect') {
    const fill = getColor(actual)
    const stroke = getColor(planned)
    const symbol = `
      <g style="fill:${fill}; stroke: ${stroke}; stroke-width: 2px">
        ${SVG_SYMBOLS[type]}
      </g>`
    return new SVG('0 0 25 15').createImage(symbol)
  }

  const getColor = function (measure) {
    return COLOR_DEFINITION[measure || 'NO_MEASURE']
  }

  const mouseOverFunction = function (feature) {
    const name = feature.get('objectName')
    if (typeof name === 'undefined') return null
    let content = `<div>${name}</div>`
    const planned = feature.get('plannedMeasure')
    if (planned) {
      const measure = planned.formatMeasure()
      const validFrom = planned.formatBegin()
      const validTo = planned.formatEnd()
      content += `
        <div>${measure}</div>
        <div>-${i18n.global.t('measure.label.validFrom')}: ${validFrom}</div>
        <div>-${i18n.global.t('measure.label.validTo')}: ${validTo}</div>
      `
    }
    return content
  }

  return {
    styleFunction: styleFunction,
    legend: legend,
    mouseOverFunction: mouseOverFunction,
    getSymbol,
    getText,
  }
}

export default MeasureStyle

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------

const COLOR_DEFINITION = {
  EVACUATION: '#772217',
  LOCKDOWN: '#D84532',
  CLOSURE: '#D84532',
  CLOSURE_WINTER: '#12cbf6',
  NO_MEASURE: '#67AB64',
}

const SVG_SYMBOLS = {
  circle: '<circle cx="6" cy="7" r="5" />',
  rect: '<rect x="1" y="1" width="11" height="11" />',
  triangle: '<polygon points="8,4 3,12 13,12" />',
}

function isPathClosure(actual, planned) {
  return actual?.startsWith('CLOSURE') || planned?.startsWith('CLOSURE')
}

export class Jwt {
  constructor(keycloak) {
    this.keycloak = keycloak
  }
  getArtifacts() {
    const token = this.keycloak?.tokenParsed
    if (!Array.isArray(token?.artifacts)) return []
    return token.artifacts
      .map((artifact) => {
        const [id, name] = artifact.split(':')
        return { id, name }
      })
      .filter((el) => el.id && el.name)
  }
}

<template>
  <div class="mapContainer">
    <CommonLayersPopup context="input"></CommonLayersPopup>
    <div :id="mapId" class="mapPanel">
      <div v-if="showTwoFingerMessage" class="two-finger-text">
        {{ $t('msg.useTwoFingers') }}
      </div>
    </div>
    <b-modal ref="info-modal" :title="modalTitle" hide-footer no-fade centered>
      <div class="info-modal">{{ modalText }}</div>
    </b-modal>
    <span v-show="false">
      <font-awesome-icon
        :ref="faSolidFaCrosshairs"
        :icon="faSolidFaCrosshairs"
      />
      <font-awesome-icon
        class="icon"
        :ref="faSolidFaInfoCircle"
        :icon="faSolidFaInfoCircle"
      />
      <font-awesome-icon
        :ref="faSolidFaLayerGroup"
        :icon="faSolidFaLayerGroup"
      />
      <font-awesome-icon :ref="faSolidFaEdit" :icon="faSolidFaEdit" />
      <font-awesome-icon
        :ref="faRegularFaTrashAlt"
        :icon="faRegularFaTrashAlt"
      />
      <font-awesome-icon :ref="faSolidFaArrowUp" :icon="faSolidFaArrowUp" />
      <font-awesome-icon :ref="faSolidFaArrowDown" :icon="faSolidFaArrowDown" />
    </span>
  </div>
</template>

<script>
import LineString from 'ol/geom/LineString'
import Polygon from 'ol/geom/Polygon'
import avalancheUtil from '../../scripts/avalancheInputMap/avalancheUtil.js'
import AvalancheMap from '../../scripts/avalancheInputMap/AvalancheMap.js'
import { Vector as VectorSource } from 'ol/source.js'
import moment from 'moment'
import MapProjection from '../../scripts/MapProjection.js'
import Point from 'ol/geom/Point'
import { i18n } from '@/plugins/i18n'
import { v1 as uuidV1 } from 'uuid'
import {
  getDrawControl,
  getModifyDeleteControl,
} from '@/scripts/avalancheInputMap/avalancheInputControls'
import { getPositionControl } from '@/scripts/common/commonControls'
import CommonLayersPopup from '../CommonLayersPopup'
import { AdditionalLayer, BaseLayer } from '@/model/map/commonLayers'
import { GeoService } from '@/services/GeoService'
import {
  FA_REGULAR_FA_TRASH_ALT,
  FA_SOLID_FA_ARROW_DOWN,
  FA_SOLID_FA_ARROW_UP,
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_EDIT,
  FA_SOLID_FA_INFO_CIRCLE,
  FA_SOLID_FA_LAYER_GROUP,
} from '@/plugins/fontawesomeConsts'
import emitter from '@/components/EventHub'

const LIMIT_SHOW_AVALANCHES_IN_EDITMODE = 10000

export default {
  name: 'MapComponent',
  components: { CommonLayersPopup },
  // readOnly: Kommt nur von den AvalDetails ('View.Details.Several.vue und View.Details.Single.vue')
  props: ['avalanche', 'readOnly', 'date', 'adaptMap', 'initialZoom'],
  data() {
    return {
      map: null,
      mobile: false,
      multi: false,
      showTwoFingerMessage: false,
      drawingAvalanche: false,
      drawingStartZone: false,
      drawingDeposit: false,
      avalancheGeo: null,
      startZoneGeo: null,
      depositGeo: null,
      coord: null,
      drawnSource: null,
      preexistingSource: null,
      projection: null,
      mapId: uuidV1(),
      faSolidFaCrosshairs: FA_SOLID_FA_CROSSHAIRS,
      faSolidFaInfoCircle: FA_SOLID_FA_INFO_CIRCLE,
      faSolidFaLayerGroup: FA_SOLID_FA_LAYER_GROUP,
      faSolidFaEdit: FA_SOLID_FA_EDIT,
      faRegularFaTrashAlt: FA_REGULAR_FA_TRASH_ALT,
      faSolidFaArrowUp: FA_SOLID_FA_ARROW_UP,
      faSolidFaArrowDown: FA_SOLID_FA_ARROW_DOWN,
    }
  },
  watch: {
    date: {
      handler: 'loadAvalanches',
    },
    baseLayer: {
      handler: 'updateBaseLayer',
      immediate: true,
    },
    additionalLayers: {
      handler: 'updateAdditionalLayers',
      immediate: true,
      deep: true,
    },
  },
  computed: {
    modalTitle: function () {
      if (!this.multi) {
        return i18n.global.t('help.avalanche.mapping')
      } else {
        return i18n.global.t('help.avalanche.roughCoordinate')
      }
    },
    modalText: function () {
      if (!this.multi) {
        return i18n.global.t('help.avalanche.map')
      } else {
        return i18n.global.t('help.avalanches.coord')
      }
    },
    baseLayer() {
      return this.$store.state.commonLayers.baseLayer
    },
    additionalLayers() {
      return this.$store.state.commonLayers.additionalLayers
    },
  },
  created() {
    if (this.$store.state.mobilePlatform) {
      this.mobile = true
    }
    if (this.$props.avalanche.coord !== undefined) {
      this.multi = true
    }
    this.coord = this.$props.avalanche.coord
    this.avalancheGeo = this.$props.avalanche.avalancheGeo
    this.startZoneGeo = this.$props.avalanche.startZoneGeo
    this.depositGeo = this.$props.avalanche.depositGeo
    if (!this.$props.readOnly) {
      emitter.on('soft-reset', this.softReset)
    }
  },
  mounted() {
    this.projection = new MapProjection().getOldProjection()
    this.drawnSource = new VectorSource({ wrapX: false })
    this.preexistingSource = new VectorSource({ wrapX: false })
    this.initializeMap()
  },
  beforeUnmount() {
    this.$store.commit('avalanche/SET_TRIGGER_AVALANCHES', [])
    this.preexistingSource = null
    this.map.releaseMem()
    this.map = null
    emitter.off('soft-reset')
  },
  methods: {
    showInfo() {
      this.$refs['info-modal'].show()
    },
    // eslint-disable-next-line complexity
    initializeMap() {
      this.map = new AvalancheMap(this, this.mobile ? 12 : 15, this.mapId, {
        [FA_SOLID_FA_CROSSHAIRS]: this.$refs[FA_SOLID_FA_CROSSHAIRS].$el,
        [FA_SOLID_FA_INFO_CIRCLE]: this.$refs[FA_SOLID_FA_INFO_CIRCLE].$el,
        [FA_SOLID_FA_LAYER_GROUP]: this.$refs[FA_SOLID_FA_LAYER_GROUP].$el,
        [FA_SOLID_FA_EDIT]: this.$refs[FA_SOLID_FA_EDIT].$el,
        [FA_REGULAR_FA_TRASH_ALT]: this.$refs[FA_REGULAR_FA_TRASH_ALT].$el,
        [FA_SOLID_FA_ARROW_UP]: this.$refs[FA_SOLID_FA_ARROW_UP].$el,
        [FA_SOLID_FA_ARROW_DOWN]: this.$refs[FA_SOLID_FA_ARROW_DOWN].$el,
      })
      if (this.mobile && !this.readOnly) {
        getPositionControl(this.map.getControls()).setSwissZoom(12)
      } else {
        this.map.getView().setMinZoom(14)
        if (this.readOnly && this.initialZoom) {
          this.map.getView().setZoom(this.initialZoom)
        }
      }
      this.loadAvalanches()
      if (this.avalancheGeo || this.startZoneGeo || this.depositGeo) {
        const modifyDeleteControl = getModifyDeleteControl(
          this.map.getControls()
        )
        if (modifyDeleteControl) {
          modifyDeleteControl.activateModify()
        }
      }
      this.updateBaseLayer()
      this.updateAdditionalLayers()
    },
    async loadAvalanches() {
      // In den Aval-Details sollen nicht noch zusätzliche Lawinen angezeigt werden. Nur das Detail selber!
      if (this.readOnly) {
        return
      }
      const interval = this.getAvalancheLoadRange()
      const avalId = this.avalanche.id
      await this.$store.dispatch(
        'avalanche/loadAvalanchesByTriggerDateAndCreateDateTimeInterval',
        {
          interval: interval,
          createInterval: [interval[0], moment().add(10, 'minutes')],
        }
      )
      const avalanches = this.$store.state.avalanche.triggerDateAvalanches
      if (avalanches?.length < LIMIT_SHOW_AVALANCHES_IN_EDITMODE || !avalId) {
        this.map.addExistingFeatures(
          avalancheUtil.getAvalancheFeatures(avalanches),
          this.$props.avalanche.id
        )
      } else {
        this.$store.commit('avalanche/SET_TRIGGER_AVALANCHES', [])
      }
    },
    getAvalancheLoadRange() {
      // Der Bereich für den Lawinen bei der Lawineneingabe ab dem Referenztag geladen werden
      // Referenztag = heute: Es werden Lawinen von -(2x loadingRange) geladen
      // Referenztag <> heute: Es werden Lawinen von +/- loadingRange geladen
      const loadingRange = 2
      let loadingRangeBack = loadingRange
      let loadingRangeForward = loadingRange - 1
      const referenceDay1 = this.date ? moment(this.date) : moment()
      const referenceDay2 = this.date ? moment(this.date) : moment()
      const isReferenceDaySameActualDay = moment().isSame(referenceDay1, 'day')

      if (isReferenceDaySameActualDay) {
        loadingRangeBack = 2 * loadingRange - 1
        loadingRangeForward = 0
      }

      const startDay = referenceDay1
        .subtract(loadingRangeBack, 'days')
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
      const endDay = referenceDay2
        .add(loadingRangeForward, 'days')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)

      return [startDay, endDay]
    },
    softReset() {
      this.drawnSource.clear(true)
      this.loadAvalanches()
      getModifyDeleteControl(this.map.getControls()).setDisabled(true)
      getDrawControl(this.map.getControls()).setDisabled(false)
      this.coord = null
      this.avalancheGeo = null
      this.startZoneGeo = null
      this.depositGeo = null
      this.$forceUpdate()
    },
    removeAvalancheFeature() {
      this.$emit('avalancheGeo', null)
      this.$emit('startZoneGeo', null)
      this.$emit('depositGeo', null)
      this.$emit('avalancheLength', null)
      this.$emit('avalancheSize', null)
      this.$emit('startZoneAspect', null)
      this.$emit('startZoneElevation', null)
      this.$emit('depositElevation', null)
      this.avalancheGeo = this.$props.avalanche.avalancheGeo
      this.startZoneGeo = this.$props.avalanche.startZoneGeo
      this.depositGeo = this.$props.avalanche.depositGeo
    },
    removeStartZoneFeature() {
      this.$emit('startZoneGeo', null)
      this.$emit('startZoneAspect', null)
      this.$emit('startZoneElevation', null)
      this.startZoneGeo = this.$props.avalanche.startZoneGeo
    },
    removeDepositFeature() {
      this.$emit('depositGeo', null)
      this.$emit('depositElevation', null)
      this.depositGeo = this.$props.avalanche.depositGeo
    },
    // eslint-disable-next-line complexity
    async addFeature(geometry) {
      // !!! geometry is the original feature, transform only copies!
      const coordinates = geometry
        .clone()
        .transform('EPSG:21781', 'EPSG:4326')
        .getCoordinates()

      if (geometry instanceof LineString) {
        geometry.locationType = 'avalancheGeo'

        const lineString = { type: 'LineString', coordinates }
        this.$emit('avalancheGeo', lineString)
        this.setLength(geometry)

        const { first, last } = await GeoService.getFirstLast(lineString)
        const { higher, lower } =
          first.elevation > last.elevation
            ? { higher: first, lower: last }
            : { higher: last, lower: first }
        this.setExtremePoints(higher, lower, false)

        this.avalancheGeo = this.$props.avalanche.avalancheGeo
        this.map.drawPresentFeatures()
      } else if (geometry instanceof Polygon) {
        geometry.locationType = 'avalancheGeo'

        this.$emit('avalancheGeo', { type: 'Polygon', coordinates })
        this.setSize(geometry)

        const { lowest, highest } = await GeoService.getLowestHighest({
          type: 'LineString',
          coordinates: coordinates[0],
        })
        this.setExtremePoints(highest, lowest, true) // set also the length

        this.avalancheGeo = this.$props.avalanche.avalancheGeo
        this.map.drawPresentFeatures()
      } else if (
        geometry.locationType === 'startZoneGeo' ||
        this.drawingStartZone
      ) {
        geometry.locationType = 'startZoneGeo'

        const point = { type: 'Point', coordinates }
        this.$emit('startZoneGeo', point)

        const pointInfo = await GeoService.getPointInfo(point)
        this.setAltitude(pointInfo, 'startZone')
        this.setAspect(pointInfo)

        this.startZoneGeo = this.$props.avalanche.startZoneGeo
      } else if (
        geometry.locationType === 'depositGeo' ||
        this.drawingDeposit
      ) {
        geometry.locationType = 'depositGeo'

        const point = { type: 'Point', coordinates }
        this.$emit('depositGeo', point)

        const pointInfo = await GeoService.getPointInfo(point)
        this.setAltitude(pointInfo, 'deposit')

        this.depositGeo = this.$props.avalanche.depositGeo
      } else {
        geometry.locationType = 'multi'

        this.$emit('avalancheGeo', { type: 'Point', coordinates })

        this.coord = this.$props.avalanche.coord
      }
    },
    setLength(geometry) {
      const length = Math.round(geometry.getLength())
      this.$emit('avalancheLength', length)
    },
    setSize(geometry) {
      const area = geometry.getArea()
      let size
      if (area < 501) {
        size = 'ONE'
      } else if (area < 10001) {
        size = 'TWO'
      } else if (area < 80001) {
        size = 'THREE'
      } else if (area < 500001) {
        size = 'FOUR'
      } else {
        size = 'FIVE'
      }
      this.$emit('avalancheSize', size)
    },
    setExtremePoints(startZonePointInfo, depositPointInfo, withLength) {
      const startZonePoint = {
        type: 'Point',
        coordinates: [
          startZonePointInfo.longitude,
          startZonePointInfo.latitude,
        ],
      }
      this.$emit('startZoneGeo', startZonePoint)
      this.setAltitude(startZonePointInfo, 'startZone')
      this.setAspect(startZonePointInfo)
      this.startZoneGeo = this.$props.avalanche.startZoneGeo

      const depositPoint = {
        type: 'Point',
        coordinates: [depositPointInfo.longitude, depositPointInfo.latitude],
      }
      this.$emit('depositGeo', depositPoint)
      this.setAltitude(depositPointInfo, 'deposit')
      this.depositGeo = this.$props.avalanche.depositGeo

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let timeoutRef = setTimeout(() => {
        this.map.updateSize()
        timeoutRef = null
      }, 2)

      if (withLength) {
        const startZoneSwiss = new Point(startZonePoint.coordinates)
          .transform('EPSG:4326', 'EPSG:21781')
          .getCoordinates()
        const depositSwiss = new Point(depositPoint.coordinates)
          .transform('EPSG:4326', 'EPSG:21781')
          .getCoordinates()
        const rawDistance = Math.sqrt(
          Math.pow(startZoneSwiss[0] - depositSwiss[0], 2) +
            Math.pow(startZoneSwiss[1] - depositSwiss[1], 2)
        )
        const length = Math.round(rawDistance)
        this.$emit('avalancheLength', length)
      }
    },
    setAltitude(pointInfo, position) {
      if (position === 'startZone') {
        this.$emit('startZoneElevation', pointInfo.elevation)
      } else if (position === 'deposit') {
        this.$emit('depositElevation', pointInfo.elevation)
      }
    },
    setAspect(pointInfo) {
      this.$emit('startZoneAspect', pointInfo.aspect)
    },
    reset() {
      this.avalancheGeo = null
      this.startZoneGeo = null
      this.depositGeo = null
      this.coord = null
    },
    setStartZonePoint(val) {
      this.startZoneGeo = val
      this.map.drawPresentFeatures()
    },
    setDepositPoint(val) {
      this.depositGeo = val
      this.map.drawPresentFeatures()
    },
    updateBaseLayer() {
      for (const layerName of Object.values(BaseLayer)) {
        const visible = layerName === this.baseLayer
        this.map?.setCommonLayerVisibility(layerName, visible)
      }
    },
    updateAdditionalLayers() {
      for (const layerName of Object.values(AdditionalLayer)) {
        const visible = this.additionalLayers.includes(layerName)
        this.map?.setCommonLayerVisibility(layerName, visible)
      }
    },
  },
}
</script>

<style scoped>
.info-modal {
  white-space: pre-wrap;
}

.mapPanel :deep(.position) {
  top: 74px;
  left: 10px;
}

.mapPanel :deep(.position-mobile) {
  top: 5px;
  left: 5px;
  padding: 0;
}

.mapPanel :deep(.common-layers-control) {
  top: 132px;
  left: 8px;
  padding: 0;
}

.mapPanel :deep(.common-layers-control-mobile) {
  top: 38px;
  left: 5px;
  padding: 0;
}

.mapPanel :deep(canvas) {
  display: block !important;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.row {
  margin-left: 0;
  margin-top: 10px;
}
.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapPanel :deep(.active) {
  background-color: #007bff !important;
}

.mapPanel :deep(.ol-control) {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.mapPanel :deep(.ol-zoom) {
  width: 30px;
  padding: 0;
}

.mapPanel :deep(.ol-attribution) {
  height: 19px;
  width: 70px;
  display: block !important;
  padding: 0;
}

.mapPanel :deep(.ol-attribution ul) {
  padding: 2px;
}

.mapPanel :deep(.ol-attribution li) {
  float: left;
  width: max-content;
}

.mapPanel :deep(.ol-zoom button) {
  background-color: #fff !important;
  color: rgba(40, 40, 40, 0.7);
  padding-bottom: 3px;
}

.mapPanel :deep(.ol-zoom-in) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.mapPanel :deep(.draw-type) {
  top: 0.5em;
  right: 0.5em;
  padding: 0;
}

.mapPanel :deep(.modify-delete) {
  top: 8em;
  right: 0.5em;
  padding: 0;
}

.mapPanel :deep(.info-button) {
  background-color: #fff;
}

.mapPanel :deep(.single-position) {
  top: 12em;
  right: 0.5em;
  padding: 0;
}

.mapPanel :deep(.several-position) {
  top: 0.5em;
  right: 0.5em;
  padding: 0;
}

.mapPanel :deep(.icon) {
  color: #acacac;
}

.mapPanel :deep(button) {
  height: 28px;
  background-color: #fff;
  width: 28px;
  margin: 0;
  color: rgba(40, 40, 40, 0.7);
}

.mapPanel {
  height: 100%;
}

.mapPanel :deep(.draw-actions) {
  display: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
  right: 32px;
  font-size: 12px;
  white-space: nowrap;
}

.mapPanel :deep(.draw-actions-modify) {
  position: absolute;
  top: 0;
}

.mapPanel :deep(.draw-actions-delete) {
  position: absolute;
  top: 29px;
}

.mapPanel :deep(.action-style) {
  border: 2px solid transparent;
  width: max-content;
  padding: 2px;
  height: 28px;
  text-align: center;
  background-color: #c6c6c0;
  display: inline-block;
}

.mapPanel :deep(.action-left) {
  border: 2px solid transparent;
  border-top-left-radius: 4px;
  margin-right: 2px;
}

.mapPanel :deep(.action-middle) {
  margin-right: 2px;
}

.mapPanel :deep(.action-right) {
  border: 2px solid transparent;
  border-top-right-radius: 4px;
}

.mapPanel :deep(button:disabled) {
  background-color: #e4e4e0 !important;
}

.mapPanel :deep(button:focus) {
  outline: 0;
}

.mapPanel :deep(.position) {
  top: 70px;
  left: 0.5em;
  padding: 0;
  background-color: #fff;
  width: 31px;
}

.mapPanel :deep(.button-switzerland) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #fff;
}

.mapPanel :deep(.ol-hidden) {
  border: none !important;
}

.days-select {
  top: -10px;
  position: absolute;
  width: 208px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  float: right;
  border: 0 !important;
  left: 50%;
  transform: translateX(-50%);
}

.input-group-text {
  background-color: #e4e4e0 !important;
}

.two-finger-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  font-weight: bold;
  color: #6610f2;
}
</style>

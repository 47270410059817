import moment from 'moment'
import axios from 'axios'
import BaseService from '@/services/BaseService'
import ObserverGroupValidator from '@/validation/observerGroupValidator.js'
import { enums } from '@/components/I18N.js'

const CancelToken = axios.CancelToken
const CREATOR_SYSTEM_WORKBENCH = enums.creatorSystem[0]

// Helpers
function getTypeParameter(type) {
  if (Array.isArray(type)) {
    let typePara = ''
    for (let idx = 0; idx < type.length; idx++) {
      const typeEle = type[idx]
      if (idx > 0) {
        typePara = typePara.concat('&')
      }
      typePara = typePara.concat('type=' + typeEle)
    }
    return typePara
  } else {
    return 'type=' + type
  }
}

export default class observationService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('OBSERVATION_SERVICE_URL', 5000)
    return this.srv.getInstance()
  }
  static getObservationById(id) {
    return this.getInstance().get(id)
  }

  static getObservationsByUserAndCreatedDateWithinHours(
    userId,
    date,
    withinHours
  ) {
    // NOTE: withinHours is a number - amount of HOURS to go back and forwrd
    const startDate = moment(date).subtract(withinHours, 'hours')
    const rangeTill = 2 * withinHours
    return this.getInstance().get(
      '?userId=' +
        userId +
        '&creationDateTimeRange=' +
        moment(startDate).utc().format('YYYY-MM-DDTHH:mmPT') +
        rangeTill +
        'H'
    )
  }

  static getObservationsByUserAndDateRange(userId, upperDate, range, cancel) {
    // NOTE: range is a number - amount of days to go back
    return this.getInstance()
      .get(
        '?userId=' +
          userId +
          '&creationDateTimeRange=' +
          moment(upperDate)
            .utc()
            .subtract(range, 'days')
            .format('YYYY-MM-DDTHH:mmP') +
          range +
          'D',
        {
          cancelToken: new CancelToken(function executor(c) {
            if (cancel) {
              cancel.cancel = c
            }
          }),
        }
      )
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  /**
   * * Service with one active request
   */
  static getObservationsByObservationDateTimeRange(
    lowerDate,
    upperDate,
    type,
    cancel
  ) {
    // NOTE: observations to be filtered by 'type' if parameter specified
    if (
      type &&
      (!Array.isArray(type) || (Array.isArray(type) && type.length > 0))
    ) {
      return this.getInstance()
        .get(
          '?' +
            getTypeParameter(type) +
            '&observationDateTimeRange=' +
            moment(lowerDate).utc().format('YYYY-MM-DDTHH:mm') +
            '/' +
            moment(upperDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    } else {
      return this.getInstance()
        .get(
          '?observationDateTimeRange=' +
            moment(lowerDate).utc().format('YYYY-MM-DDTHH:mm') +
            '/' +
            moment(upperDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    }
  }

  static getLatestMeasures(timestamp, avalServiceIds, cancel) {
    const params = {
      timestamp: timestamp,
      avalServiceIds:
        avalServiceIds.length > 0
          ? avalServiceIds.reduce((f, s) => `${f},${s}`)
          : [],
    }
    return this.getInstance()
      .get('/getLatestMeasures', {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel.cancel = c
          }
        }),
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  static getMeasures(cancel) {
    return this.getInstance()
      .get('/getAllLatestMeasures', {
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel.cancel = c
          }
        }),
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  static getObservationsByCreationDateTimeRange(
    lowerDate,
    upperDate,
    type,
    cancel
  ) {
    let typeFilter = ''
    if (
      type &&
      (!Array.isArray(type) || (Array.isArray(type) && type.length > 0))
    ) {
      typeFilter = getTypeParameter(type) + '&'
    }
    return this.getInstance()
      .get(
        '?' +
          typeFilter +
          'creationDateTimeRange=' +
          moment(lowerDate).utc().format('YYYY-MM-DDTHH:mm') +
          '/' +
          moment(upperDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
        {
          cancelToken: new CancelToken(function executor(c) {
            if (cancel) {
              cancel.cancel = c
            }
          }),
        }
      )
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  static async addObservation(observation) {
    observation.creatorSystem = CREATOR_SYSTEM_WORKBENCH
    await ObserverGroupValidator.validateObservation(observation).then(
      (result) => {
        if (!result) {
          ObserverGroupValidator.rejectSave()
        }
      }
    )
    return this.getInstance().post('', observation)
  }

  static async addObservations(observations) {
    const promises = []
    observations.forEach((obs) => {
      obs.creatorSystem = CREATOR_SYSTEM_WORKBENCH
      promises.push(ObserverGroupValidator.validateObservation(obs))
    })
    await Promise.all(promises).then((results) => {
      results.some((result) => {
        if (!result) {
          ObserverGroupValidator.rejectSave()
        }
        // Ending forEach when returning true!
        return !result
      })
    })
    return this.getInstance().post('/observations', observations)
  }

  static updateObservation(observation) {
    return this.getInstance().put(observation.id, observation)
  }

  static getLatestTriggerings(avalServiceIds, cancel) {
    const params = {
      avalServiceIds:
        avalServiceIds.length > 0
          ? avalServiceIds.reduce((f, s) => `${f},${s}`)
          : [],
    }
    return this.getInstance()
      .get('/getLatestTriggerings', {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel.cancel = c
          }
        }),
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  static getJournalObservations(
    userId,
    avalServiceIds,
    upperDate,
    range,
    cancel
  ) {
    // NOTE: range is a number - amount of days to go back
    const params = {
      userId: userId,
      creationDateTimeRange:
        moment(upperDate)
          .utc()
          .subtract(range, 'days')
          .format('YYYY-MM-DDTHH:mmP') +
        range +
        'D',
      avalServiceId: this.getAvalServiceIdParameter(avalServiceIds),
    }

    return this.getInstance()
      .get('/getJournalObservations', {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel.cancel = c
          }
        }),
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info('Request canceled')
        } else {
          throw thrown
        }
      })
  }

  static getAvalServiceIdParameter(avalServiceIds) {
    if (avalServiceIds === undefined || avalServiceIds === null) {
      return ''
    }
    if (Array.isArray(avalServiceIds)) {
      let avalServiceIdPara = ''
      for (let idx = 0; idx < avalServiceIds.length; idx++) {
        const avalServiceIdsEle = avalServiceIds[idx]
        if (idx === 0) {
          avalServiceIdPara = avalServiceIdPara.concat(avalServiceIdsEle)
        } else {
          avalServiceIdPara = avalServiceIdPara.concat(',' + avalServiceIdsEle)
        }
      }
      return avalServiceIdPara
    } else {
      return avalServiceIds.toString()
    }
  }
}

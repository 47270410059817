<template>
  <div>
    <FilterMultiple :filterOptions="filterOptions"></FilterMultiple>
    <Legend v-if="legend" :legend="legend"></Legend>
    <SurfaceHoarLegend v-if="!legend"></SurfaceHoarLegend>
    <CommonLayersPopup></CommonLayersPopup>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal
      detId="detailModal"
      :obsIds="obsIds"
      :showEdit="updatableModal"
      :aggregate="true"
    />
  </div>
</template>

<script>
import Legend from '../Legend'
import { SurfaceNatureStyle } from '../styles/snowStyling/SurfaceNatureStyle'
import { SurfaceHoarStyle } from '../styles/snowStyling/SurfaceHoarStyle'
import SurfaceHoarLegend from './SurfaceHoarLegend'
import DetailModal from '../observation/DetailModal'
import moment from 'moment'
import { i18nMixin } from '../I18N'
import FilterMultiple from '../FilterMultiple'
import { i18n } from '@/plugins/i18n'
import { Canceler } from '@/scripts/requestUtil.js'
import CommonLayersPopup from '../CommonLayersPopup'
import { DateTime } from 'luxon'
import { MeasurementMapService } from '@/services/MeasurementMapService'
import { transformTimepointGeoJson } from '@/scripts/measurementMapTransformationUtil'
import { observationProjection } from '@/model/geoJsonModels'
import { round } from '@/scripts/numberUtils'
import emitter from '@/components/EventHub'
import { deepClone } from '@/scripts/common'

const canceler = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Surface',
  props: ['moment'],
  mixins: [i18nMixin],
  components: {
    CommonLayersPopup,
    // eslint-disable-next-line vue/no-reserved-component-names
    Legend,
    DetailModal,
    SurfaceHoarLegend,
    FilterMultiple,
  },

  data() {
    return {
      legend: null,
      obsIds: [],
      selected: 'surface.productVariantNature',
      updatableModal: false,
      snowSurfaceConditions: this.options('snow', 'snowSurfaceCondition'),
      filter: { filters: {} }, // current filter selection incl. productVariant and surface specific filters
      initialFilterValue: true,
      lastMoment: undefined,
      lastDatePreset: undefined,
      isLoading: false,
      isMounted: false,
    }
  },

  mounted() {
    this.filter.filters['surface.filters'] = [
      'surface.flat',
      'surface.north',
      'surface.south',
    ]
    this.isMounted = true
    this.refresh()
    /*eslint-disable complexity*/
    emitter.on('update::filter', (filter) => {
      let refresh = false
      if (
        filter &&
        filter.productVariant &&
        filter.productVariant.startsWith('surface.') &&
        (this.selected !== filter.productVariant || filter.restoreFilter)
      ) {
        this.selected = filter.productVariant
        this.filter.productVariant = filter.productVariant
        refresh = true
      }
      if (filter && filter.filters) {
        const surfaceFilters = {}
        for (const filterKey in filter.filters) {
          if (
            Object.prototype.hasOwnProperty.call(filter.filters, filterKey) &&
            filterKey.startsWith('surface.')
          ) {
            surfaceFilters[filterKey] = filter.filters[filterKey]
            if (!this.initialFilterValue) {
              refresh = true
            } else {
              this.initialFilterValue = false
            }
          }
          this.filter.filters = surfaceFilters
        }
      }
      if (refresh) {
        this.refresh()
      }
    })
    /*eslint-enable complexity*/

    emitter.on('repos::map', (flag) => {
      this.isLoading = flag && flag === true
    })
  },

  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },

  beforeUnmount() {
    emitter.off('update::filter')

    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },

  methods: {
    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },

    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },

    findAndShowObservation(observationId, featureCollection) {
      this.obsIds = []
      const index = featureCollection.features.findIndex((item) => {
        return item.properties.id === observationId
      })
      if (index > -1) {
        this.updatableModal = moment().isSame(
          moment(featureCollection.features[index].timestamp),
          'd'
        )
        this.obsIds.push(observationId)
        this.$bvModal.show('detailModal')
      }
    },

    getSurfaceFilterOptions() {
      return {
        items: [
          {
            key: 'surface.filters',
            label: '',
            options: [
              { value: 'surface.flat', text: i18n.global.t('snow.label.flat') },
              {
                value: 'surface.north',
                text: i18n.global.t('common.aspect.N'),
              },
              {
                value: 'surface.south',
                text: i18n.global.t('common.aspect.S'),
              },
            ],
          },
        ],
      }
    },

    moreDangerous(north, south) {
      const northIndex = this.snowSurfaceConditions.findIndex(
        (x) => x.value === north
      )
      const southIndex = this.snowSurfaceConditions.findIndex(
        (x) => x.value === south
      )
      return northIndex < southIndex
    },

    elaborateResults(self, fc) {
      const featureCollection = Object.assign(
        {},
        self.$store.state.snow.snowFeatureCollection
      )
      featureCollection.features = Object.assign(
        [],
        self.$store.state.snow.snowFeatureCollection.features
      )
      featureCollection.features = self.applySurfaceFilter(
        featureCollection.features,
        self.filter
      )
      if (fc) {
        const filteredMeasurementsFeatures =
          this.applySurfaceFilterOnMeasurements(fc.features, self.filter)
        featureCollection.features = featureCollection.features.concat(
          filteredMeasurementsFeatures
        )
      }
      const newFeatures = []
      /*eslint-disable complexity*/
      featureCollection.features.forEach((feature) => {
        const oldFeature = deepClone(feature)
        const surfaceCondition = oldFeature.properties.snowSurfaceCondition

        if (surfaceCondition && !surfaceCondition.hoar_size) {
          if (
            surfaceCondition.snowSurfaceConditionNorth &&
            surfaceCondition.snowSurfaceConditionSouth &&
            self.filter.filters['surface.filters'].includes('surface.north') &&
            self.filter.filters['surface.filters'].includes('surface.south')
          ) {
            const newFeature = deepClone(feature)
            oldFeature.properties.snowSurfaceCondition.valueToShow =
              surfaceCondition.snowSurfaceConditionNorth
            oldFeature.properties.snowSurfaceCondition.aspect =
              i18n.global.t('common.aspect.N')
            newFeature.properties.snowSurfaceCondition.valueToShow =
              surfaceCondition.snowSurfaceConditionSouth
            newFeature.properties.snowSurfaceCondition.aspect =
              i18n.global.t('common.aspect.S')
            if (
              this.moreDangerous(
                surfaceCondition.snowSurfaceConditionNorth,
                surfaceCondition.snowSurfaceConditionSouth
              )
            ) {
              oldFeature.properties.snowSurfaceCondition.index = 10
              newFeature.properties.snowSurfaceCondition.index = 5
            } else {
              oldFeature.properties.snowSurfaceCondition.index = 5
              newFeature.properties.snowSurfaceCondition.index = 10
            }
            newFeatures.push(newFeature)
          } else if (
            surfaceCondition.snowSurfaceConditionFlat &&
            self.filter.filters['surface.filters'].includes('surface.flat')
          ) {
            oldFeature.properties.snowSurfaceCondition.valueToShow =
              surfaceCondition.snowSurfaceConditionFlat
            oldFeature.properties.snowSurfaceCondition.aspect =
              i18n.global.t('snow.label.flat')
            oldFeature.properties.snowSurfaceCondition.index = 10
          } else if (
            surfaceCondition.snowSurfaceConditionNorth &&
            self.filter.filters['surface.filters'].includes('surface.north')
          ) {
            oldFeature.properties.snowSurfaceCondition.index = 10
            oldFeature.properties.snowSurfaceCondition.valueToShow =
              surfaceCondition.snowSurfaceConditionNorth
            oldFeature.properties.snowSurfaceCondition.aspect =
              i18n.global.t('common.aspect.N')
          } else {
            // south filter is active otherwise this would not be here but filtered out before
            oldFeature.properties.snowSurfaceCondition.index = 10
            oldFeature.properties.snowSurfaceCondition.valueToShow =
              surfaceCondition.snowSurfaceConditionSouth
            oldFeature.properties.snowSurfaceCondition.aspect =
              i18n.global.t('common.aspect.S')
          }
        }
        newFeatures.push(oldFeature)
      })
      /*eslint-enable complexity*/
      featureCollection.features = newFeatures
      const surfaceClickHandler = function (feature) {
        if (feature) {
          const index = featureCollection.features.findIndex((item) => {
            return item.properties.id === feature.get('id')
          })
          if (index > -1 && feature.get('snowSurfaceCondition')) {
            self.obsIds = []
            self.updatableModal = moment().isSame(
              moment(featureCollection.features[index].date),
              'd'
            )
            self.obsIds.push(feature.get('id'))
            self.$bvModal.show('detailModal')
          }
        }
      }
      const resolutionChangeHandler = function (resolution, layers, spider) {
        spider.resolutionChangeHandler(
          layers['snowSurfaceCondition'].vectorLayer,
          resolution
        )
      }
      emitter.emit('layer', {
        name: 'snowSurfaceCondition',
        features: featureCollection,
        styleFunction: this.style.styleFunction,
        onClick: surfaceClickHandler,
        supportFilter: true,
        onResolutionChange: resolutionChangeHandler,
      })
      emitter.emit('mouseOver', this.style.mouseOverFunction)
      if (self.$route.query.openObs) {
        self.findAndShowObservation(
          self.$route.query.openObs,
          featureCollection
        )
      }
    },

    applySurfaceFilter(features, filter) {
      let filteredFeatures = features
      // filter delivered features based on productVariant and filter selection
      if (filter && filteredFeatures) {
        if (filter.filters) {
          filteredFeatures = filteredFeatures.filter((feature) => {
            return (
              (feature.properties.snowSurfaceCondition
                .snowSurfaceConditionNorth &&
                filter.filters['surface.filters'].includes('surface.north')) ||
              (feature.properties.snowSurfaceCondition
                .snowSurfaceConditionSouth &&
                filter.filters['surface.filters'].includes('surface.south')) ||
              (feature.properties.snowSurfaceCondition
                .snowSurfaceConditionFlat &&
                filter.filters['surface.filters'].includes('surface.flat'))
            )
          })
        }
      }
      return filteredFeatures
    },

    applySurfaceFilterOnMeasurements(features, filter) {
      if (features && filter && filter.filters) {
        if (!filter.filters['surface.filters'].includes('surface.flat')) {
          return []
        }
      }
      return features
    },

    refresh() {
      this.isLoading = true

      console.debug('Cancel requests on load')
      this.cancelRequests()

      const self = this
      const cancelObj = { cancel: null }

      if (this.selected === 'surface.productVariantNature') {
        this.style = new SurfaceNatureStyle()
        this.legend = this.style.legend()
        this.loadSnowSurfaceConditionObservations(cancelObj).then(() => {
          self.elaborateResults(self)
          self.isLoading = false
        })
        canceler.addCancel(cancelObj.cancel)
      } else if (this.selected === 'surface.productVariantHoar') {
        this.style = new SurfaceHoarStyle()
        this.legend = null
        const pObservation =
          this.loadSnowSurfaceConditionObservations(cancelObj)
        canceler.addCancel(cancelObj.cancel)

        const cancelObj2 = { cancel: null }
        const pMeasurement = this.getSurfaceHoarGeoJson(cancelObj2)
        canceler.addCancel(cancelObj2.cancel)
        Promise.all([pObservation, pMeasurement]).then((result) => {
          self.elaborateResults(self, result[1])
          self.isLoading = false
        })
      }
    },
    async loadSnowSurfaceConditionObservations(cancelObj) {
      const timestampAsMoment = moment(this.$store.state.calendar.moment)
      return this.$store.dispatch('snow/loadSnowObservationsByDate', {
        param: 'snowSurfaceCondition',
        date: timestampAsMoment,
        cancel: cancelObj,
      })
    },
    async getSurfaceHoarGeoJson(cancelObj) {
      const timestampAsMoment = moment(this.$store.state.calendar.moment)
      const selectedDateTime = DateTime.fromISO(
        timestampAsMoment.toISOString()
      ).toUTC()

      const dataAtStationGeoJson =
        await MeasurementMapService.getDataAtTimepoint(
          'SH',
          selectedDateTime.toISO(),
          cancelObj
        )
      return transformTimepointGeoJson(
        dataAtStationGeoJson,
        selectedDateTime,
        observationProjection,
        (properties, value) => ({
          ...properties,
          hoar_size: value != null ? round(value, 0) : null,
        })
      )
    },
  },
  computed: {
    filterOptions() {
      // Gleiches Prob wie bei: SPASS-903 Visualisierung Lawinen default Filter abgewählt
      // Die Komonente muss erst gemounted sein, vorher darf diese computed property noch nichts zurückgeben!
      if (!this.isMounted) return null

      return this.getSurfaceFilterOptions()
    },
  },
}
</script>

<style scoped>
.symbol {
  height: 18px;
}

.title {
  padding-bottom: 10px;
}

.legend_mobile {
  top: 6.9em;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <b-row>
        <b-col>
          <b>{{ $t('ui.studyplotobservation') }}</b>
        </b-col>
        <b-col class="text-right" @click="datepicker = !datepicker">
          <span class="date">{{ formattedDate }}</span>
          &nbsp;&nbsp;<font-awesome-icon icon="fa-solid fa-calendar-alt" />
        </b-col>
      </b-row>
      <b-form class="dateForm" v-if="datepicker">
        <b-container>
          <b-row align-h="center">
            <calendar-component
              class="calendar-picker"
              v-model="dateString"
              name="datepicker"
              @update:model-value="setDate"
              :max="disabledDates.from"
              inline
              :show-icon="false"
            />
          </b-row>
        </b-container>
      </b-form>
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click="routeBack()" variant="warning">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="onSubmit()"
            :disabled="!contentState"
            variant="success"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowRainAltitude') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowRainAltitude')"
              />
            </template>
            <b-form-select
              id="snowpackInput5"
              :options="masl"
              size="sm"
              v-model="snowForm.snowRainAltitude"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('snow.label.snowSurfaceConditionFlat')"
                :info="$t('help.observation.snowSurfaceConditionFlat')"
              />
            </template>
            <b-form-select
              id="snowpackInput2"
              :options="snowSurfaceCondition"
              size="sm"
              v-model="snowForm.snowSurfaceCondition.snowSurfaceConditionFlat"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowlineNorth') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowlineNorth')"
              />
            </template>
            <b-form-select
              id="snowpackInput4"
              :options="masl"
              size="sm"
              v-model="snowForm.snowline.north"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('snow.label.snowlineSouth') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowlineSouth')"
              />
            </template>
            <b-form-select
              id="snowpackInput3"
              :options="masl"
              size="sm"
              v-model="snowForm.snowline.south"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <AvalancheSign v-model="avalancheForm" />
    </b-form>
  </b-card>
</template>

<script>
import { i18n } from '@/plugins/i18n'
import { i18nMixin } from '../I18N'
import moment from 'moment'
import CalendarComponent from '../calendar/CalendarComponent'
import InfoLabel from '../InfoLabel'
import AvalancheSign from '../dangersign/AvalancheSign'
import { StudyplotService } from '@/services/StudyplotService'
import { deepClone } from '@/scripts/common'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  components: { AvalancheSign, CalendarComponent, InfoLabel },
  mixins: [i18nMixin],
  props: ['code'],
  // props: { "code": any },
  data() {
    return {
      location: null,
      date: this.$store.state.observation.date,
      datepicker: false,
      disabledDates: {
        to: moment().add(-30, 'd').toDate(),
        from: moment().toDate(),
        dates: [],
      },
      snowForm: {
        snowSurfaceCondition: { snowSurfaceConditionFlat: null },
        snowRainAltitude: null,
        snowline: { south: null, north: null },
      },
      avalancheForm: {
        avalRelease: { previousDay: null, currentDay: null },
      },
      processing: null,
      new: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  mounted() {
    if (this.code) {
      if (this.$store.state.profile && this.$store.state.profile.studyplots) {
        StudyplotService.getStudyplotByCode(this.code)
          .then((result) => {
            this.location = result.location
          })
          .catch((e) => {
            console.log('Could not find studyplot :' + e)
          })
      }
    }
    if (this.existing) {
      this.new = false
      this.snowForm = deepClone(this.$store.state.observation.selected.snow)
      this.avalancheForm = deepClone(
        this.$store.state.observation.selected.dangersign
      )
      this.date = new Date(this.$store.state.observation.selected.date)
      this.location = this.$store.state.observation.selected.location
    }
  },
  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)
    this.reset()
  },
  computed: {
    masl() {
      return [
        { text: i18n.global.t('ui.select'), value: null },
        200,
        400,
        600,
        800,
        1000,
        1200,
        1400,
        1600,
        1800,
        2000,
        2200,
        2400,
        2600,
        2800,
        3000,
        3200,
        3400,
        3600,
        3800,
        4000,
        4200,
      ]
    },
    snowSurfaceCondition() {
      return this.options('snow', 'snowSurfaceCondition')
    },
    existing() {
      return this.$store.state.observation.selected
    },
    formattedDate() {
      return moment(this.date).format('DD.MM.YY')
    },
    // eslint-disable-next-line complexity
    contentState() {
      return !!(
        this.location &&
        (this.snowForm.snowSurfaceCondition.snowSurfaceConditionFlat ||
          this.snowForm.snowRainAltitude ||
          this.snowForm.snowline.south ||
          this.snowForm.snowline.north ||
          this.avalancheForm.avalRelease.currentDay !== null ||
          this.avalancheForm.avalRelease.previousDay !== null)
      )
    },
    dateString: {
      get() {
        return moment(this.date).format('DD.MM.YYYY')
      },
      set(val) {
        this.date = moment(val, 'DD.MM.YYYY').toDate()
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this
      this.preSubmitValidate()
      this.$store
        .dispatch('observation/submitObservation', {
          type: 'studyplotobservation',
          snow: this.snowForm,
          dangersign: this.avalancheForm,
          location: this.location,
        })
        .then(
          () => {
            if (this.avalancheForm.oldAvalanche) {
              this.$store.commit('avalanche/SET_REPORT_OLDER_AVAL', true)
              this.$store.commit(
                'avalanche/SET_REPORT_OLDER_AVAL_DATE',
                moment()
              )
            }
            self.processing = false
            self.routeBack()
          },
          (reason) => {
            console.error('Error submitting observation:' + reason)
            self.processing = false
          }
        )
    },
    routeBack() {
      if (this.routeOrigin.includes('/observation/list')) {
        if (this.new) {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
              openObs: this.$store.state.observation.selected.id,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    preSubmitValidate() {
      // need to transform bool to string for service
      for (const key in this.avalancheForm.avalRelease) {
        switch (this.avalancheForm.avalRelease[key]) {
          case true:
            this.avalancheForm.avalRelease[key] = 'true'
            break
          case false:
            this.avalancheForm.avalRelease[key] = 'false'
            break
          default: //
        }
      }
    },
    reset() {
      this.snowForm = {
        snowSurfaceCondition: { snowSurfaceConditionFlat: null },
        snowRainAltitude: null,
        snowline: { south: null, north: null },
      }
      this.avalancheForm = {
        avalRelease: { previousDay: null, currentDay: null },
      }
    },
    setDate(date) {
      if (date) {
        this.dateString = date
      }
      this.checkDate()
      this.datepicker = false
      this.$store.commit('observation/SET_DATE', this.date)
    },
    checkDate() {
      const ts = moment(this.date).startOf('day').unix() * 1000
      if (
        this.disabledDates.dates.find((d) => {
          return d.getTime() === ts
        })
      ) {
        // this.alert = this.$t('measurement.label.duplicate', { date: moment(this.date).format('DD.MM.YYYY') })
      } else {
        // this.alert = null
      }
    },
  },
}
</script>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.disabled {
  color: #acacac;
}
</style>

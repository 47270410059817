'use strict'

import { AbstractStyle } from '../AbstractStyle'
import { getAvalancheReleaseStyleArray } from '@/components/styles/avalancheStyling/AvalancheReleaseStyle'
import { getAvalancheStyleArray } from '@/components/styles/avalancheStyling/AvalancheStyle'
import FullCluster from '@/scripts/clustering/FullCluster'

export class AbstractAvalancheStyle extends AbstractStyle {
  styleFunction(feature, resolution, getLabelStyle, useInputMapStyling) {
    // special treatment for single features. original geometry has to be set in the style, not in the feature!
    if (
      feature.get('features').length < 2 &&
      resolution < FullCluster.CLUSTERING_THRESHOLD_AT_RESOLUTION
    ) {
      const originalFeature = feature.get('features')[0]
      const originalAval = originalFeature.get('avalanche')
      const geometry = originalFeature.get('geometry')

      const noClusterStyle = getAvalancheStyle(
        originalFeature,
        resolution,
        getLabelStyle,
        useInputMapStyling
      )
      if (noClusterStyle.length > 0) {
        const geoToSet = originalAval?.detailGeometry
          ? originalAval.detailGeometry
          : geometry
        noClusterStyle[noClusterStyle.length - 1].setGeometry(geoToSet)
      }
      return noClusterStyle
    } else {
      const clusterStyle = getAvalancheStyle(
        feature,
        resolution,
        getLabelStyle,
        useInputMapStyling
      )
      if (clusterStyle.length > 0) {
        // Sonst wird zusätzlich noch das Polygon angezeigt
        clusterStyle[clusterStyle.length - 1].setGeometry(undefined)
      }
      return clusterStyle
    }
  }

  prepareClusters(features, resolution) {
    // eslint-disable-next-line complexity
    features.forEach((feature) => {
      if (feature.get('features').length < 2 && resolution < 200) {
        const originalFeature = feature.get('features')[0]
        if (originalFeature.get('avalanche'))
          feature.set('avalanche', originalFeature.get('avalanche'))
        if (originalFeature.get('uid'))
          feature.set('uid', originalFeature.get('uid'))
        if (originalFeature.get('timestamp'))
          feature.set('timestamp', originalFeature.get('timestamp'))
        if (originalFeature.get('avalancheRelease'))
          feature.set(
            'avalancheRelease',
            originalFeature.get('avalancheRelease')
          )
      } else {
        const clusterObject = calculateClusterInfo(resolution, feature)
        if (clusterObject.value !== undefined) {
          feature.set('avalancheRelease', clusterObject)
        } else {
          feature.set('avalanche', clusterObject)
        }
      }
    })
  }
}

// eslint-disable-next-line complexity,no-var
const calculateClusterInfo = function (resolution, feature) {
  if (feature.get('features')) {
    const avalancheRelease = {}
    avalancheRelease.value = false
    const features = feature.get('features')
    const clusterAvalanche = {}
    let activityIndex = 0
    let dry = 0
    let wet = 0
    const sizeArray = [0, 0, 0, 0, 0]
    const triggerArray = []
    const avalancheTypes = []
    const noteTexts = []
    let minElevation = 0
    let maxElevation = 0
    let zIndex
    let nonReleaseFound = false
    if (
      features.length < 2 &&
      features[0].get('avalanche') &&
      features[0].get('avalanche').objectType === 'SINGLE_AVALANCHE'
    ) {
      clusterAvalanche.objectType = 'SINGLE_AVALANCHE'
    }
    // eslint-disable-next-line complexity
    features.forEach((item) => {
      const avalanche = item.get('avalanche')
      if (avalanche) {
        nonReleaseFound = true
        activityIndex += avalanche.activityIndex
        zIndex = avalanche.zIndex
        if (avalanche.avalancheMoisture === 'DRY') {
          dry += 1
        } else if (avalanche.avalancheMoisture === 'WET') {
          wet += 1
        }
        for (let j = 0; j < 5; j++) {
          sizeArray[j] += avalanche.sizeArray[j]
        }
        if (avalancheTypes.indexOf(avalanche.avalancheTypes[0]) < 0) {
          avalancheTypes.push(avalanche.avalancheTypes[0])
        }
        if (
          avalanche.triggerTypes[0] &&
          triggerArray.indexOf(avalanche.triggerTypes[0]) === -1
        ) {
          triggerArray.push(avalanche.triggerTypes[0])
        }
        if (avalanche.startZoneElevationRange) {
          if (
            avalanche.startZoneElevationRange[0] &&
            (avalanche.startZoneElevationRange[0] < minElevation ||
              !minElevation)
          ) {
            minElevation = avalanche.startZoneElevationRange[0]
          }
          if (
            avalanche.startZoneElevationRange[0] &&
            (avalanche.startZoneElevationRange[0] > maxElevation ||
              !maxElevation)
          ) {
            maxElevation = avalanche.startZoneElevationRange[0]
          }
          if (
            avalanche.startZoneElevationRange[1] &&
            (avalanche.startZoneElevationRange[1] < minElevation ||
              !minElevation)
          ) {
            minElevation = avalanche.startZoneElevationRange[1]
          }
          if (
            avalanche.startZoneElevationRange[1] &&
            (avalanche.startZoneElevationRange[1] > maxElevation ||
              !maxElevation)
          ) {
            maxElevation = avalanche.startZoneElevationRange[1]
          }
        }
        noteTexts.push(avalanche.noteTexts[0])
      } else if (
        item.get('avalancheRelease') &&
        item.get('avalancheRelease').value === true
      ) {
        avalancheRelease.value = true
      }
    })
    if (dry > 0 && wet === 0) {
      clusterAvalanche.avalancheMoisture = 'DRY'
    } else if (wet > 0 && dry === 0) {
      clusterAvalanche.avalancheMoisture = 'WET'
    } else if (wet > 0 && dry > 0) {
      clusterAvalanche.avalancheMoisture = 'MIXED'
    } else {
      clusterAvalanche.avalancheMoisture = 'UNKNOWN'
    }
    clusterAvalanche.activityIndex = Math.round(activityIndex * 100) / 100
    clusterAvalanche.triggerTypes = triggerArray
    clusterAvalanche.startZoneElevationRange = [minElevation, maxElevation]
    clusterAvalanche.sizeArray = sizeArray
    clusterAvalanche.avalancheTypes = avalancheTypes
    clusterAvalanche.noteTexts = noteTexts
    clusterAvalanche.zIndex = zIndex
    // if (clusterAvalanche.activityIndex > 0) { // NOTE (20190905/csz): activityIndex allowed to be null for public reply avalanches
    if (nonReleaseFound === true) {
      return clusterAvalanche
    } else {
      return avalancheRelease
    }
  }
}

const getAvalancheStyle = function (
  feature,
  resolution,
  getLabelStyle,
  useInputMapStyling
) {
  const avalanche = feature.get('avalanche')
  const avalancheRelease = feature.get('avalancheRelease')
  const geometry = feature.get('geometry')
  const styleArray = []
  if (avalanche) {
    if (!avalanche.avalancheMoisture) {
      // i.e. public reply avalanche
      avalanche.avalancheMoisture = 'UNKNOWN'
    }
    return getAvalancheStyleArray(
      feature,
      avalanche,
      geometry,
      getLabelStyle,
      useInputMapStyling
    )
  } else if (avalancheRelease !== undefined) {
    return getAvalancheReleaseStyleArray(avalancheRelease)
  } else {
    return styleArray
  }
}

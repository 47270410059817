import util from '@/scripts/util.js'
import { SINGLE_AVALANCHE, SEVERAL_AVALANCHE } from '@/scripts/const.js'

export const avalReportObsBuilderMixin = {
  computed: {},
  methods: {
    getAvalReportObsFromObsList(avals) {
      const avalPromises = []
      avals.forEach((aval) => {
        const avalpromise = this.getAvalReportObs(aval, aval.avalancheClass)
        if (avalpromise) {
          avalPromises.push(avalpromise)
        }
      })
      return avalPromises
    },
    getAvalReportObs(srcAval, avalClass) {
      return avalClass === SINGLE_AVALANCHE
        ? this.getSingleAvalReportObs(srcAval)
        : this.getSeveralAvalReportObs(srcAval)
    },
    getSingleAvalReportObs(srcAval) {
      const vm = this
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('avalanche/getSingleAvalanche', srcAval.id)
          .then((avalanche) => {
            resolve(vm.buildAvalReportObs(srcAval, avalanche, true))
          })
          .catch((err) => {
            const errorMsg =
              "avalanche with id '" +
              srcAval.id +
              "' could not be loaded (" +
              err +
              ')'
            console.error(errorMsg)
            reject(errorMsg)
          })
      })
    },
    getSeveralAvalReportObs(srcAval) {
      const vm = this
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('avalanche/getSeveralAvalanche', srcAval.id)
          .then((avalanche) => {
            resolve(vm.buildAvalReportObs(srcAval, avalanche, false))
          })
          .catch((err) => {
            const errorMsg =
              "avalanche with id '" +
              srcAval.id +
              "' could not be loaded (" +
              err +
              ')'
            console.error(errorMsg)
            reject(errorMsg)
          })
      })
    },
    buildAvalReportObs(srcAval, aval, isSingle) {
      aval.startZoneGeoText = null
      aval.depositGeoText = null
      aval.coordText = null
      if (aval.coord) {
        aval.coordText = util.convertGeoJsonToText(aval.coord)
      }
      if (aval.startZoneGeo) {
        aval.startZoneGeoText = util.convertGeoJsonToText(aval.startZoneGeo)
      }
      if (aval.depositGeo) {
        aval.depositGeoText = util.convertGeoJsonToText(aval.depositGeo)
      }
      return {
        id: aval.id,
        location: this.getAvalLocation(aval),
        type: 'avalanche',
        avalancheClass: isSingle ? SINGLE_AVALANCHE : SEVERAL_AVALANCHE,
        avalanche: aval,
        cTimestamp: srcAval.cTimestamp,
        date: srcAval.date,
        uid: srcAval.uid,
      }
    },
    getAvalLocation(aval) {
      return {
        coordinates: aval.coord
          ? aval.coord.coordinates
          : aval.avalancheGeo
          ? aval.avalancheGeo.coordinates
          : undefined,
        startPoint: aval.startZoneGeo?.coordinates,
        depositPoint: aval.depositGeo?.coordinates,
      }
    },
  },
}

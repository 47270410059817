import moment from 'moment'

// state
const state = {
  time: '00:00',
  date: moment().format('DD.MM.YYYY'),
  selector: 'time', // 'time' or 'date'
}

// getters
const getters = {
  getTime(state) {
    return state.time
  },
}

// actions
const actions = {
  setTime({ commit }, value) {
    commit('SET_TIME', value)
  },
  setDate({ commit }, value) {
    commit('SET_DATE', value)
  },
  setSelector({ commit }, selector) {
    commit('SET_SELECTOR', selector)
  },
}

// mutations
const mutations = {
  SET_TIME(state, value) {
    state.time = value
  },
  SET_DATE(state, value) {
    state.date = value
  },
  SET_SELECTOR(state, selector) {
    state.selector = selector
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

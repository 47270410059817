import { Control } from 'ol/control.js'
import emitter from '@/components/EventHub'

export class CommonLayersControl extends Control {
  private readonly mobile: boolean
  private visible: boolean

  constructor(mobile: boolean, layerIconEl: HTMLElement) {
    const element = document.createElement('div')
    super({ element: element })
    this.mobile = mobile
    this.visible = false

    const layersButton = document.createElement('button')
    layersButton.appendChild(layerIconEl)

    layersButton.addEventListener(
      'click',
      (event) => this.handleClickEvent(event),
      false
    )

    element.appendChild(layersButton)
    element.className = `ol-unselectable ol-control ${
      mobile ? 'common-layers-control-mobile' : 'common-layers-control'
    }`
  }

  private handleClickEvent(event: Event) {
    event.preventDefault()
    if (this.visible) {
      emitter.emit('showCommonLayers', false)
      this.visible = false
    } else {
      emitter.emit('showCommonLayers', true)
      this.visible = true
      if (this.mobile) {
        emitter.emit('showLegend', false)
        emitter.emit('showFilter', false)
        emitter.emit('showLayer', false)
      }
    }
  }
}

import { HumidityStylesValues } from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'
import Style from 'ol/style/Style'
import { Circle, Fill, Stroke } from 'ol/style'
import { AVAL_MOISTURE } from '@/components/styles/avalancheStyling/GeneralStyleFactory'
import {
  HUMIDITY_STYLES,
  HUMIDITY_STYLES_INPUT_MAP,
} from '@/components/styles/avalancheStyling/AvalancheCommonStyling'

const ACTIVITY_INDEXES_CLASS: Array<number> = [1, 2, 3, 4, 5, 6]

export default class ActivityIndexStyleFactory {
  private static instance: ActivityIndexStyleFactory | undefined = undefined
  private humidityStyles: Record<string, HumidityStylesValues>
  private humidityStylesInputMap: Record<string, HumidityStylesValues>
  private activityIndexStyleStroke = new Map<string, Stroke>()
  private activityIndexStyleFill = new Map<string, Fill>()
  private activityIndexStyle = new Map<string, Style>()
  private activityIndexStyleStrokeInputMap = new Map<string, Stroke>()
  private activityIndexStyleFillInputMap = new Map<string, Fill>()
  private activityIndexStyleInputMap = new Map<string, Style>()

  private constructor() {
    this.humidityStyles = HUMIDITY_STYLES
    this.humidityStylesInputMap = HUMIDITY_STYLES_INPUT_MAP
    ACTIVITY_INDEXES_CLASS.forEach((aIxCls) => {
      AVAL_MOISTURE.forEach((moisture) => {
        this.activityIndexStyleStroke.set(
          moisture,
          this.getActivityStyleStroke(
            HUMIDITY_STYLES[moisture as keyof typeof HUMIDITY_STYLES]
          )
        )
        this.activityIndexStyleStrokeInputMap.set(
          moisture,
          this.getActivityStyleStroke(
            HUMIDITY_STYLES_INPUT_MAP[
              moisture as keyof typeof HUMIDITY_STYLES_INPUT_MAP
            ]
          )
        )
        this.activityIndexStyleFill.set(
          moisture,
          this.getActivityStyleFill(
            HUMIDITY_STYLES[moisture as keyof typeof HUMIDITY_STYLES]
          )
        )
        this.activityIndexStyleFillInputMap.set(
          moisture,
          this.getActivityStyleFill(
            HUMIDITY_STYLES_INPUT_MAP[
              moisture as keyof typeof HUMIDITY_STYLES_INPUT_MAP
            ]
          )
        )
        this.activityIndexStyle.set(
          this.getActivityIdxKey(aIxCls, moisture),
          this.getActivityStyle(aIxCls, moisture, false)
        )
        this.activityIndexStyleInputMap.set(
          this.getActivityIdxKey(aIxCls, moisture),
          this.getActivityStyle(aIxCls, moisture, true)
        )
      })
    })
  }

  static getInstance(): ActivityIndexStyleFactory {
    this.instance = this.instance || new ActivityIndexStyleFactory()
    return this.instance
  }

  private getActivityIdxKey(activityIndex: number, avalMoisture: string) {
    return `${activityIndex.toString()}_${avalMoisture}`
  }

  public getActivityIndexStyle(
    activityIndex: number,
    avalMoisture: string,
    useInputMapStyling: boolean
  ): Style {
    let resultingStyle
    if (useInputMapStyling) {
      resultingStyle = this.activityIndexStyleInputMap.get(
        this.getActivityIdxKey(
          ActivityIndexStyleFactory.getClassForActivityIndex(activityIndex),
          avalMoisture
        )
      )
    } else {
      resultingStyle = this.activityIndexStyle.get(
        this.getActivityIdxKey(
          ActivityIndexStyleFactory.getClassForActivityIndex(activityIndex),
          avalMoisture
        )
      )
    }
    if (!resultingStyle) {
      const msg = `Zu den übergebenen Parameter, konnte kein Style gefunden werden. AI:${activityIndex}, MOISTURE:${avalMoisture}, useInputMapStyling:${useInputMapStyling},`
      console.error(msg)
      throw new Error(msg)
    }
    return resultingStyle
  }

  public static getRadiusForActivityIndex(activityIndexClass: number): number {
    return ActivityIndexStyleFactory.getRadiusForActivityClass(
      ActivityIndexStyleFactory.getClassForActivityIndex(activityIndexClass)
    )
  }
  private static getRadiusForActivityClass(activityClass: number): number {
    return activityClass * 4
  }

  private static getClassForActivityIndex(activityIndex: number) {
    if (activityIndex >= 10) {
      return 6
    } else if (activityIndex >= 8) {
      return 5
    } else if (activityIndex >= 5) {
      return 4
    } else if (activityIndex >= 2) {
      return 3
    } else if (activityIndex >= 0.5) {
      return 2
    } else {
      return 1
    }
  }

  private getActivityStyle(
    activityIndexClass: number,
    avalMoisture: string,
    useInputMapStyling: boolean
  ): Style {
    return new Style({
      image: new Circle({
        radius:
          ActivityIndexStyleFactory.getRadiusForActivityClass(
            activityIndexClass
          ),
        fill: useInputMapStyling
          ? this.activityIndexStyleFillInputMap.get(avalMoisture)
          : this.activityIndexStyleFill.get(avalMoisture),
        stroke: useInputMapStyling
          ? this.activityIndexStyleStrokeInputMap.get(avalMoisture)
          : this.activityIndexStyleStroke.get(avalMoisture),
      }),
      zIndex: 3,
    })
  }

  private getActivityStyleFill(style: HumidityStylesValues): Fill {
    return new Fill({
      color: style.stroke_color,
    })
  }

  private getActivityStyleStroke(style: HumidityStylesValues): Stroke {
    return new Stroke({
      color: style.stroke,
      width: 1,
    })
  }
}

'use strict'

import assessUtil from '@/scripts/assessmentUtil.js'
import store from '@/store/index'
import { i18n } from '@/plugins/i18n'

// Melungen
// Für THROW (unstranslated)
const COMMON_NO_SAVE =
  'Beobachtung/Lawine konnte nicht angelegt werden, da keine eindeutige observerGroup ermittelt werden konnte!'
// Für Modal (assetscode for translation)
const NO_SAVE = 'error.noSave'
const MULTIPLE_OBSERVERGROUPS = 'error.multipleObservergroups'
const MULTIPLE_OBSERVERGROUPS_DETAIL = 'error.multipleObservergroupsDetail'
const SERVER_ERROR = 'error.serverError'
const SERVER_ERROR_DETAIL = 'error.serverErrorDetail'

export default class ObserverGroupValidator {
  static validateObservation(obs) {
    if (
      obs &&
      // AssessmentContent hat nie eine observerGroup und braucht auch keine
      !assessUtil.isAssessmentObservation(obs, obs.type) &&
      !ObserverGroupValidator.hasObserverGroupTag(obs)
    ) {
      return ObserverGroupValidator.validateCommon(obs)
    }
    return Promise.resolve(true)
  }

  static validateAvalanche(avalanche) {
    if (avalanche && !ObserverGroupValidator.hasObserverGroupTag(avalanche)) {
      return ObserverGroupValidator.validateCommon(avalanche)
    }
    return Promise.resolve(true)
  }

  static validateCommon(element) {
    return store.dispatch('profile/loadUserGroups').then(
      () => {
        const myObserverGroups = store.state.profile.userGroups
        if (myObserverGroups && myObserverGroups.length === 1) {
          // Wenn es genau 1 UserGroups-Element gibt, dann kann dieses genommen werden (id=observerGroup)
          element.tags.observerGroup = myObserverGroups[0].id
          console.log(
            'Meldung wurde autom. mit element.tags.observerGroup=' +
              element.tags.observerGroup +
              'ergänzt.'
          )
          return true
        } else if (myObserverGroups && myObserverGroups.length > 1) {
          // Wenn mehrere ObserverGroups vorhanden sind, dann kann hier nicht mehr ermittelt werden, welche zu dieser Meldung gehört.
          // Dann muss dies mit einer Fehlermeldung mitgeteilt und der Speichervorgang unterbunden werden.
          const errorMsg = {
            message: getMainErrorMessage(MULTIPLE_OBSERVERGROUPS),
            details: i18n.global.t(MULTIPLE_OBSERVERGROUPS_DETAIL),
          }
          store.commit('SET_VALIDATION_EXCEPTION', errorMsg)
          console.error(errorMsg.message + ' ' + errorMsg.details)
          return false
        }
        // Wenn keine ObserverGroup vorhanden ist (oder eine leere) dann ist der Datensatz korrekt und kann gespeichert werden!
        return true
      },
      (error) => {
        const errorMsg = {
          message: getMainErrorMessage(SERVER_ERROR),
          details: i18n.global.t(SERVER_ERROR_DETAIL) + ' (' + error + ')',
        }
        store.commit('SET_VALIDATION_EXCEPTION', errorMsg)
        console.error(errorMsg.message + ' ' + errorMsg.details)
        return false
      }
    )
  }

  static hasObserverGroupTag(element) {
    return (
      element.tags.observerGroup !== null &&
      element.tags.observerGroup !== undefined
    )
  }

  static rejectSave() {
    throw COMMON_NO_SAVE
  }
}

function getMainErrorMessage(additionalAssetcode) {
  return i18n.global.t(NO_SAVE) + ' ' + i18n.global.t(additionalAssetcode)
}

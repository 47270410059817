import { AxiosError, AxiosResponse } from 'axios'
import { Avalanche } from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'
import avalancheService from '@/services/avalancheService'
import { SEVERAL_AVALANCHE } from '@/scripts/const'
import { FeatureLike } from 'ol/Feature'
import funcs from '@/scripts/avalancheInputMap/avalancheUtil'

class AvalancheGeometryLoader {
  private PARALLEL_LOADS = 15

  public async enrichVisibleAvalFeaturesWithGeometryElement(
    visibleFeatures: FeatureLike[]
  ): Promise<boolean> {
    let detailLoadPromises: Array<Promise<AxiosResponse>> = []
    const avalsForPromises: Map<string, Avalanche> = new Map()
    let detailsReloaded = false
    for (let idx = 0; idx < visibleFeatures.length; idx++) {
      const avalElem: Avalanche = visibleFeatures[idx].get('avalanche')
      if (!this.mustNotReloadGeometryElement(avalElem)) {
        detailLoadPromises.push(
          avalancheService.getSingleAvalanche(avalElem.id)
        )
        avalsForPromises.set(avalElem.id, avalElem)
      }
      if (
        this.isReadyToLoadAvalGeoElement(
          visibleFeatures,
          detailLoadPromises,
          idx
        )
      ) {
        detailsReloaded = await this.loadAvalDetails(
          detailLoadPromises,
          avalsForPromises
        )
        detailLoadPromises = []
        avalsForPromises.clear()
      }
    }
    return detailsReloaded
  }

  private mustNotReloadGeometryElement(avalElem: Avalanche) {
    return (
      avalElem == null ||
      avalElem.objectType === SEVERAL_AVALANCHE ||
      avalElem.detailGeometry ||
      avalElem.noAvalDetailFound
    )
  }

  private isReadyToLoadAvalGeoElement(
    visibleFeatures: FeatureLike[],
    detailLoadPromises: Array<Promise<AxiosResponse>>,
    idx: number
  ) {
    return (
      detailLoadPromises.length >= this.PARALLEL_LOADS ||
      (detailLoadPromises.length > 0 && idx === visibleFeatures.length - 1)
    )
  }

  private async loadAvalDetails(
    detailLoadPromises: Array<Promise<AxiosResponse>>,
    avalsForPromises: Map<string, Avalanche>
  ): Promise<boolean> {
    let reloadedDetails = 0
    try {
      const responses: Array<AxiosResponse> = await Promise.all(
        detailLoadPromises
      )
      for (let idx = 0; idx < responses.length; idx++) {
        const response: AxiosResponse = responses[idx]
        const detailFeatures = funcs.getAvalancheFeatures([
          response.data,
        ]).features
        const originalAval: Avalanche | undefined = avalsForPromises.get(
          response.data.id
        )
        if (detailFeatures.length === 1 && originalAval) {
          originalAval.detailGeometry = detailFeatures[0].get('geometry')
          originalAval.detailGeoType = detailFeatures[0]
            .get('geometry')
            .getType()
          avalsForPromises.delete(response.data.id)
          reloadedDetails++
        }
      }
      this.cleaningAvalsWithNoGeoInResponse(avalsForPromises)
    } catch (error) {
      this.handleAvalDetailLoadError(error, avalsForPromises)
    }
    return reloadedDetails > 0
  }

  private cleaningAvalsWithNoGeoInResponse(
    avalsForPromises: Map<string, Avalanche>
  ) {
    // Das kanns geben. Wenn bei einem Lawinendetail, keine Geometrie mit 'funcs.getAvalancheFeatures(...)'
    // zurückkommt. Dann dieses Detail aber auch nicht mehr laden.
    for (const leftAval of avalsForPromises.values()) {
      leftAval.noAvalDetailFound = true
    }
  }

  private handleAvalDetailLoadError(
    error: unknown,
    avalsForPromises: Map<string, Avalanche>
  ): void {
    const baseErrorMsg = 'Lawinendetail konnte nicht nachgeladen werden:'
    if (error instanceof AxiosError && error.config?.url) {
      const avalId = error.config.url.substring(
        error.config.url.lastIndexOf('/') + 1
      )
      const errorAval: Avalanche | undefined = avalsForPromises.get(avalId)
      if (errorAval) {
        console.error(baseErrorMsg, avalId)
        errorAval.reloadErrors++
        if (errorAval.reloadErrors >= 3) {
          errorAval.noAvalDetailFound = true
        }
      }
    } else {
      console.error(baseErrorMsg, error)
    }
  }
}
export default AvalancheGeometryLoader

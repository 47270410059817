import PrimeVue from 'primevue/config'
import { App } from 'vue'
import PrimevueCalendar from 'primevue/calendar'
import PrimevueRating from 'primevue/rating'

export function usePrimeVueComponents(app: App<Element>): void {
  app.use(PrimeVue)
  app.component('PrimevueCalendar', {
    ...PrimevueCalendar,
    compatConfig: { MODE: 3 },
  })
  app.component('PrimevueRating', {
    ...PrimevueRating,
    compatConfig: { MODE: 3 },
  })
}

import { enums } from '@/components/I18N'
import {
  ASPECTS,
  ZOOM_LEVEL_SHOW_ROSETTE,
} from '@/components/styles/dangersignStyling/AbstractCompassStyle'
import { SVG } from '@/components/styles/SVG'
import Icon from 'ol/style/Icon'
import { SnowpackStyle } from '@/components/styles/dangersignStyling/SnowpackStyle'
import { WHUMPF_CRACKS } from '@/scripts/const'
import Style from 'ol/style/Style'
import { WhumpfCrackStyle } from '@/components/styles/dangersignStyling/WhumpfCrackStyle'
import {
  NO_VALUE_CLASS,
  WHUMPF_CRACKS_STYLE_ELEMENTS,
} from '@/components/styles/dangersignStyling/whumpfCrackMetadata'

export const getFrequencyIdx = (whumpfCrackFeature) => {
  const frequencyArray = enums.dangersign.frequency
  for (let index = 0; index < frequencyArray.length; index++) {
    if (
      frequencyArray[index].localeCompare(whumpfCrackFeature.frequency) === 0
    ) {
      return index
    }
  }
  return 0
}

export const getWhumpfCrackStyle = (whumpfCrackFeature) => {
  const featureStyle = WHUMPF_CRACKS_STYLE_ELEMENTS.find(function (styleEle) {
    return styleEle.frequency.localeCompare(whumpfCrackFeature.frequency) === 0
  })
  return featureStyle !== undefined ? featureStyle : NO_VALUE_CLASS
}

// eslint-disable-next-line complexity
export const showRosette = (whumpfCrackFeature, resolution) => {
  // Kein Wumm, keine Rosette
  if (!whumpfCrackFeature) {
    return false
  }
  // Rosette nur auf Zoomstufe lokal anzeigen
  if (resolution > ZOOM_LEVEL_SHOW_ROSETTE) {
    return false
  }
  // Nur im Flachen gibts keine Rosette
  if (whumpfCrackFeature.flat === true) {
    return false
  }
  // Wenn die Rosette ausgefüllt wurde, dann wird sie angezeigt
  if (
    whumpfCrackFeature.east === null &&
    whumpfCrackFeature.north === null &&
    whumpfCrackFeature.south === null &&
    whumpfCrackFeature.west === null
  ) {
    return false
  }
  // Ansonsten muss eine Rosette gezeichnet werden
  return true
}

export const getExclamationSymbol = (whumpfCracksFeature) => {
  const whumpfCrackStyle = getWhumpfCrackStyle(whumpfCracksFeature).style
  const symbol = getExclamationColored(whumpfCrackStyle)
  const svg = new SVG('0 0 200 600')
  const wcScale = 0.7
  return new Icon({
    src: svg.createImage(symbol).src,
    scale: wcScale,
  })
}

const getExclamationColored = (whumpfCrackStyle) => {
  return (
    '<g style="fill:' +
    whumpfCrackStyle.fill +
    '"><path stroke="' +
    whumpfCrackStyle.stroke +
    '" stroke-width="' +
    whumpfCrackStyle.strokeWidth +
    '" d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"></path></g>'
  )
}

export const getLegendSymbol = (whumpfCracksFeature, onlyWhite) => {
  let style
  if (onlyWhite) {
    style = {
      fill: 'white',
      stroke: 'black',
      strokeWidth: 20,
    }
  } else {
    style = getWhumpfCrackStyle(whumpfCracksFeature).style
  }
  const symbol = getExclamationColored(style)
  const svg = new SVG('-100 0 550 550')

  return svg.createImage(symbol)
}

export const getPieColor = (feature, aspect, index, fill0, fill1) => {
  if (!feature[aspect]) return 'white'
  return feature[aspect][index] === 1
    ? fill1
    : feature[aspect][index] === 0
    ? fill0
    : 'white'
}

export const getStartIndex = (feature) => {
  // Search index of first value in all aspect arrays [1000müM, 1500müM, 2000müM, 2500müM, 3000müM, 3500müM, 4000müM]
  let startIndex = 4
  ASPECTS.forEach((aspect) => {
    if (feature[aspect]) {
      const index = feature[aspect].findIndex((v) => v !== null)
      if (index > -1) {
        startIndex = Math.min(startIndex, index)
      }
    }
  })
  return startIndex
}

export const getLegendRosettenSymbol = () => {
  const rosettenSVGCall = SnowpackStyle.prototype.getRosettenSVG
  return rosettenSVGCall({ east: null, north: null, south: null, west: null })
}

const exclamationStyleCache = new Map()
export const getCachedSymbolIfAvailable = (
  feature,
  resolution,
  onlyExclamation,
  altitudeLabel
) => {
  const getWuCrackSymb = WhumpfCrackStyle.prototype.getWhumpfCracksSymbol
  const wuCrackFeat = feature.get(WHUMPF_CRACKS)

  const whumpfCrackStyle = getWuCrackSymb(feature, resolution, onlyExclamation)

  const cacheKey = `${wuCrackFeat.frequency}`
  if (onlyExclamation) {
    if (exclamationStyleCache.has(cacheKey)) {
      return exclamationStyleCache.get(cacheKey)
    }
  }

  const newIconStyle = new Style({
    image: whumpfCrackStyle,
    text: onlyExclamation ? null : altitudeLabel,
  })

  if (onlyExclamation) {
    exclamationStyleCache.set(cacheKey, newIconStyle)
    console.debug('New iconStyleCache-size:', exclamationStyleCache.size)
  }

  return newIconStyle
}

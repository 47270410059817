'use strict'

import moment from 'moment'
import emitter from '@/components/EventHub'

// Beobachtungen:
// current: seit 00:00 Uhr => moment=2019-01-02T00:00 duration=P1D
// 1000:    seit 08:00 Uhr => moment=2019-01-02T10:00 duration=P1D
// 17:00:   seit 17:00 Uhr => moment=2019-01-01T17:00 duration=P1D

class Calendar {
  constructor(moment, preset) {
    this.moment = moment
    this.preset = preset
  }
  getMoment() {
    const ts =
      this.preset !== 'calendar' ? moment().startOf('minute') : this.moment
    emitter.emit('calendar::refresh', ts)
    return ts
  }
  getPresetMoment() {
    switch (this.preset) {
      case '1000':
        return moment(this.getMoment())
          .add(-10, 'hour')
          .startOf('day')
          .set('hour', 10)
      case '1700':
        return moment(this.getMoment())
          .add(-17, 'hour')
          .startOf('day')
          .set('hour', 17)
      default:
        return moment(this.getMoment()).startOf('day')
    }
  }
  getPresetInterval() {
    return this.getPresetMoment().format('YYYY-MM-DDTHH:mm') + 'P1D'
  }
  getInterval(days) {
    const start = moment(this.getMoment())
      .startOf('day')
      .add(1 - days, 'day')
    return start.format(`YYYY-MM-DDTHH:mmP${days}`) + 'D'
  }
}

export default Calendar

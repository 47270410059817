'use strict'
import { SVG } from '../SVG'
import { AbstractCompassStyle, ASPECTS } from './AbstractCompassStyle'
import { COLOR_GUENSTIG, COLOR_GUENSTIG_STROKE } from '../AbstractStyle'

// Dieser Rest Code wird noch von WhumpfCrackStyle.js zum zeichnen der Rosette verwendet
export class SnowpackStyle extends AbstractCompassStyle {
  getRosettenSVG(snowpackValue) {
    const svgCall = SnowpackStyle.prototype.getSVG
    const snowpackStyle = getSnowpackStyle(snowpackValue).style
    const svg = new SVG('-32 -32 64 64')
    return svg.createImage(
      svgCall(
        snowpackValue,
        SNOWPACK_STYLE_ELEMENTS[0].style.fill,
        snowpackStyle.fill
      )
    )
  }
  getSVG(feature) {
    const startIndex = getStartIndex(feature)
    const fill = {}
    ASPECTS.forEach((aspect) => {
      fill[aspect] = ['white', 'white', 'white']
      ;[0, 1, 2].forEach((i) => {
        fill[aspect][i] = getPieColor(feature, aspect, startIndex + i)
      })
    })
    return super.getColoredSVG(fill)
  }
}

const getSnowpackStyle = function (snowpackValue) {
  const worstType = getWorstType(snowpackValue)
  const featureStyle = SNOWPACK_STYLE_ELEMENTS.find(function (styleElement) {
    return styleElement.level === worstType
  })
  return featureStyle !== undefined ? featureStyle : NO_VALUE_CLASS
}
const getWorstType = function (snowpackValue) {
  let worstIndex = -1
  ASPECTS.forEach((aspect) => {
    if (snowpackValue[aspect]) {
      // laufe alle Indexe ab
      ;[0, 1, 2, 3, 4, 5].forEach((index) => {
        if (index > -1) {
          const newIndex = SNOWPACK_STYLE_ELEMENTS.findIndex(
            (v) => v.level === snowpackValue[aspect][index]
          )
          worstIndex = Math.max(worstIndex, newIndex)
        }
      })
    }
  })
  if (worstIndex > -1) {
    return SNOWPACK_STYLE_ELEMENTS[worstIndex].level
  } else {
    return null
  }
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------
const NO_VALUE_CLASS = {
  style: { fill: '#ffffff', stroke: '#122e42' },
  label: 'kein Wert',
}
const SNOWPACK_STYLE_ELEMENTS = [
  {
    level: 'FAVORABLE',
    style: { fill: COLOR_GUENSTIG, stroke: COLOR_GUENSTIG_STROKE },
  },
  { level: 'RATHER_FAVORABLE', style: { fill: '#feb24c', stroke: '#565859' } },
  {
    level: 'RATHER_UNFAVORABLE',
    style: { fill: '#fc4e2a', stroke: '#565859' },
  },
  { level: 'UNFAVORABLE', style: { fill: '#960020', stroke: '#480010' } },
]

// ----------------------------------------------------------------------------------------------------
//  Aspect SVG
// ----------------------------------------------------------------------------------------------------
const getPieColor = function (feature, aspect, i) {
  if (!feature[aspect]) return 'white'
  const featureStyle = SNOWPACK_STYLE_ELEMENTS.find(function (styleElement) {
    return styleElement.level === feature[aspect][i]
  })
  if (featureStyle) {
    return featureStyle.style.fill
  } else {
    return 'white'
  }
}

const getStartIndex = function (feature) {
  // Search index of first value in all aspect arrays [1000müM, 1500müM, 2000müM, 2500müM, 3000müM, 3500müM, 4000müM]
  let startIndex = 6
  ASPECTS.forEach((aspect) => {
    if (feature[aspect]) {
      const index = feature[aspect].findIndex((v) => v !== null)
      if (index > -1) {
        startIndex = Math.min(startIndex, index)
      }
    }
  })
  return startIndex
}

<template>
  <b-card footer-tag="footer" header-tag="header">
    <template #header>
      <Header
        v-if="!assFlag"
        ref="header"
        :title="$t('ui.attachment')"
        :obs="form"
        :initialLocation="location"
        :adaptMap="adaptMap"
      />
      <b-row v-if="assFlag">
        <b-col>
          <b>{{ $t('ui.attachment') }}</b>
        </b-col>
      </b-row>
    </template>
    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button variant="warning" @click="routeBack()">
            {{ $t('ui.cancel') }}
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button :disabled="!complete" variant="success" @click="onSubmit()"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <div>
      <AreaSectorInput
        :ldId="avalServiceId"
        :disableSector="true"
        v-model="form.hierarchy"
        v-if="assFlag === true"
      />
      <b-row v-if="assFlag === false">
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="locationState"
              aria-describedby="locationFeedback"
            />
            <b-form-invalid-feedback id="locationFeedback">
              {{ $t('msg.validObservationLocation') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              id="hierarchyFeedbackInput"
              hidden
              :state="hierarchyState"
              aria-describedby="hierarchyFeedback"
            />
            <b-form-invalid-feedback id="hierarchyFeedback">
              {{ $t('assessment.msg.validHierarchy') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              id="contentFeedbackInput"
              hidden
              :state="contentState"
              aria-describedby="contentFeedback"
            />
            <b-form-invalid-feedback id="contentFeedback">
              {{ $t('msg.validMinimalContent') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form @submit.prevent="onSubmit">
      <b-row style="margin-top: 10px; margin-bottom: 10px">
        <b-col cols="md-11">
          <InfoLabel
            :text="$t('observation.label.attachmentText')"
            :info="$t('help.common.upload')"
          />
        </b-col>
        <b-col cols="md-1">
          <b-button size="sm" variant="default" @click="uploading = !uploading">
            <font-awesome-icon icon="fa-solid fa-plus" />
          </b-button>
        </b-col>
      </b-row>

      <!-- Fileliste -->
      <b-row v-if="uploading">
        <b-col>
          <FileUpload
            @uploaded="uploaded"
            :locationPoint="this.$store.state.observation.location"
            :type="inputType"
            :restriction="form.confidential"
          />
        </b-col>
      </b-row>
      <b-row align-h="start" class="line">
        <b-col
          cols="lg-2 md-3"
          v-for="file in form.files"
          v-bind:key="file.id"
          class="label"
        >
          <b-img
            class="thumbnail"
            :src="file.thumbnail"
            :title="file.fileName"
          />
          <div>
            <span class="caption">
              <span class="fileMeta"
                >{{ $t('avalanche.document.fileDescription') }}:</span
              >
            </span>
            <textarea
              :id="file.id"
              type="text"
              v-model="file.description"
              class="editId"
            />
          </div>
          <b-button
            class="trash"
            size="sm"
            variant="warning"
            @click="markFileToRemove(form.files, file.id)"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
          </b-button>
        </b-col>
      </b-row>
      <!-- Kommentar -->
      <hr />
      <b-row>
        <b-col cols="md-12">
          <b-form-group label-cols-sm="4" label-cols-lg="3">
            <template #label>
              <InfoLabel
                :text="$t('common.comment')"
                :info="$t('help.observation.comment')"
              />
            </template>
            <b-form-textarea
              id="comment"
              v-model="form.comment"
              :rows="3"
              :state="commentState"
              aria-describedby="commentFeedback"
              size="lg"
            />
            <b-form-invalid-feedback id="commentFeedback">
              {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Rechte -->
      <b-row style="margin-top: 10px">
        <b-col sm="1">
          <b-form-checkbox
            id="rights"
            size="sm"
            v-model="form.confidential"
            :options="flag"
          />
        </b-col>
        <b-col sm="11">
          <span>{{ $t('observation.label.confidentialTextLess') }}</span>
        </b-col>
      </b-row>

      <br />
    </b-form>
  </b-card>
</template>

<script>
import Header from '../observation/Header'
import InfoLabel from '../InfoLabel'
import FileUpload from './FileUpload'
import { ATTACHMENT } from '@/scripts/const'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import { i18nMixin } from '../I18N'
import documentUtil, {
  RESTRICTED,
  NOT_RESTRICTED,
} from '../../scripts/documentUtil.js'
import util from '@/scripts/util.js'
import moment from 'moment'
import { inputFrmRouteBackMixin } from '@/components/observation/InputFrmRouteBackMixin.js'
import { deepClone } from '@/scripts/common'
import { beforeRouteEnter } from '@/components/routingConfig'
import { useStagedDocumentRemover } from '@/composables/useStagedDocumentRemover'
// https://github.com/lian-yue/vue-upload-component

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  components: {
    FileUpload,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    InfoLabel,
  },
  mixins: [i18nMixin, inputFrmRouteBackMixin],
  data() {
    return {
      assFlag: false,
      location: null,
      flag: this.options('common', 'flag', true),
      uploading: false,
      processing: null,
      formSubmitted: null,
      form: {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        files: [],
        docIds: [],
        confidential: false,
        comment: null,
      },
      adaptMap: false,
      new: true,
      originalFiles: [], // backup of origimal documents
    }
  },
  beforeMount() {
    // NOTE: capture event of navigating to a new URL and refresh -> synch document list
    window.addEventListener('beforeunload', this.checkDocumentState)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.checkDocumentState)

    this.reset()
  },
  beforeRouteEnter(to, from, next) {
    beforeRouteEnter(to, from, next)
  },
  beforeRouteLeave(to, from, next) {
    // NOTE (error handling): rollback potentially added/removed documents
    // NOTE: capture event of browser back and route changes -> synch document list
    this.checkDocumentState()

    this.$store.commit('observation/SET_SELECTED', null)

    next()
  },
  setup() {
    return useStagedDocumentRemover()
  },
  created() {
    if (this.$store.state.observation.selected) {
      this.location = deepClone(this.$store.state.observation.selected.location)
      this.new = false
    }
  },
  /*eslint-disable-next-line complexity*/
  async mounted() {
    if (this.$store.state.observation.selected) {
      const selectedForm = deepClone(
        this.$store.state.observation.selected.attachment
      )
      assessmentUtil.prepareAssessmentObservationInputForm(
        ATTACHMENT,
        this.$store.state.observation.selected,
        this.form
      )

      // re-fill into files for display purposes
      const promises = []
      if (selectedForm.docIds) {
        selectedForm.files = []
        selectedForm.docIds.forEach((docId) => {
          promises.push(
            this.$store
              .dispatch('document/getDocumentMetaWithThumbnail', { id: docId })
              .then((meta) => {
                // NOTE: for removed documents only metadata available, no thumbnail and no content - doesn't serve
                // to show and re-save them along w/ the observation. if observation saved afterwards, inconsistent
                // state (caused by a possible cancel instead of save after document removal) can be eliminated
                if (
                  typeof meta.removeDate === 'undefined' ||
                  !meta.removeDate
                ) {
                  selectedForm.files.push(meta)
                }
                documentUtil.sortDocumentArray(selectedForm.files)
              })
              .catch((err) => {
                // NOTE: add even if not known whether failed to load a normal or deleted document - otherwise, loss
                // of document for an observation possible if someone saves
                selectedForm.files.push({
                  id: docId,
                  thumbnailUrl: '',
                  url: '',
                  description: '',
                  mediaType: '',
                  thumbnail: null,
                })
                documentUtil.sortDocumentArray(selectedForm.files)
                console.error(
                  "document meta for docId '" +
                    docId +
                    "' could not be loaded, %o",
                  err
                )
              })
          )
        })
      }

      try {
        await Promise.all(promises)
        this.originalFiles = deepClone(selectedForm.files) // by entering the form, backup origimal documents if any here
      } catch (e) {
        console.error('could not set original files!', e)
        // not correct set but only way to avoid that otherwise form.files content be interpreted as new files which in case of a cancel would be deleted!
        this.originalFiles = selectedForm.files
      }

      this.form = selectedForm
      if (!this.form.files) {
        this.form.files = []
      }
    } else {
      if (this.assFlag) {
        const hierarchyMap = this.$store.state.assessmentObs.hierarchyMap
        if (
          util.existValidHierarchy(
            hierarchyMap.get(this.avalServiceId),
            this.$store.state.assessmentObs.date
          )
        ) {
          this.form.hierarchy = deepClone(
            hierarchyMap.get(this.avalServiceId).selection
          )
        }
        this.form.hierarchy.avalServiceId = this.avalServiceId
      }
    }
  },
  computed: {
    /*eslint-disable complexity*/
    complete() {
      // NOTE: at least one field must be filled
      if (!this.assFlag) {
        return (
          this.dateState === true &&
          this.locationState === true &&
          this.contentState === true &&
          this.commentState !== false
        )
      } else {
        return (
          this.dateState === true &&
          this.hierarchyState === true &&
          this.contentState === true &&
          this.commentState !== false
        )
      }
    },
    /*eslint-enable complexity*/
    dateState() {
      if (!this.$store.state.observation.date) {
        return false
      } else {
        return true
      }
    },
    locationState() {
      if (!this.$store.state.observation.location) {
        return false
      } else {
        return true
      }
    },
    hierarchyState() {
      if (!(this.form.hierarchy && this.form.hierarchy.areaId)) {
        return false
      } else {
        return true
      }
    },
    contentState() {
      if (
        (!this.form.files || this.form.files.length <= 0) &&
        !this.form.comment
      ) {
        return false
      } else {
        return true
      }
    },
    commentState() {
      if (!this.form.comment) {
        return null
      }
      return (
        this.form.comment &&
        this.form.comment.length >= 2 &&
        this.form.comment.length <= 500
      )
    },
    inputType() {
      return ATTACHMENT
    },
    avalServiceId() {
      return this.$store.getters['profile/getAvalServiceId']
    },
  },
  methods: {
    onSubmit() {
      const self = this
      this.formSubmitted = true
      // terminate the function if an async request is processing
      if (this.processing === true) {
        return
      }
      // set the async state
      this.processing = true
      this.removeFiles()

      if (this.complete) {
        // clear up fields before transmitting
        this.preSubmitValidate()
        const hierarchyClone = Object.assign({}, self.form.hierarchy)
        this.$store
          .dispatch(
            'observation/submitObservation',
            assessmentUtil.prepareAssessmentObservation(ATTACHMENT, {
              attachment: this.form,
              type: ATTACHMENT,
              location: this.location,
            })
          )
          .then(
            () => {
              if (self.assFlag && hierarchyClone) {
                const storeHierarchy =
                  self.$store.state.assessmentObs.hierarchyMap.get(
                    self.avalServiceId
                  )
                self.$store.commit('assessmentObs/SET_HIERARCHY', {
                  avalServiceId: self.avalServiceId,
                  hierarchy: {
                    selection: util.getConsolidatedHierarchySelection(
                      Object.assign({}, hierarchyClone),
                      storeHierarchy ? storeHierarchy.selection : null,
                      true
                    ),
                    date: moment(),
                  },
                })
              }
              // on success or failure reset the state
              self.processing = false
              self.routeBack()
            },
            (reason) => {
              // NOTE: no rollback of documents here, since this.form.files already removed as a prerequisite for save and
              // we do not want to introduce a 3rd shadow object...- therefore no reconstruction
              console.error('Error submitting observation:' + reason)
              self.processing = false
            }
          )
      } else {
        event.preventDefault()
        event.stopPropagation()
        window.scrollTo(0, 0) // NOTE: direct workaround
        this.processing = false
      }
    },
    routeBack() {
      this.routeBackMixedTypes(this.adaptMap, this.new, this.assFlag)
    },
    preSubmitValidate() {
      // Fürs Assessment/Upload-Dokumente die location aus der Area übernehmen
      if (!this.assFlag) {
        this.location = this.$store.state.observation.location
      } else {
        this.location = this.$store.getters['profile/getAreaLocationToAreaId'](
          this.form.hierarchy.areaId
        )
      }
      // make sure optional documents are updated w/ location info
      this.form.docIds = []
      this.form.files.forEach((file) => {
        file.restriction =
          this.form.confidential === true ? RESTRICTED : NOT_RESTRICTED
        if (util.isPoint(this.location)) {
          file.lon = this.location.coordinates[0]
          file.lat = this.location.coordinates[1]
        }
        this.$store.dispatch('document/updateDocumentMeta', file)
        this.form.docIds.push(file.id)
      })
      delete this.form.files
      this.form.files = []
    },
    reset() {
      this.formSubmitted = null
      this.form = {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        files: [],
        docIds: [],
        confidential: false,
        comment: null,
      }
    },
    checkDocumentState() {
      let lon = null
      let lat = null
      if (util.isPoint(this.location)) {
        lon = this.location.coordinates[0]
        lat = this.location.coordinates[1]
      }

      documentUtil.rollbackFiles(
        this.$store,
        this.originalFiles,
        this.form.files,
        {
          lon: lon,
          lat: lat,
          keywords: ['OBSERVATION'],
          eventDate: moment(),
          restriction:
            this.form.confidential === true ? RESTRICTED : NOT_RESTRICTED,
        }
      )
    },
    uploaded(files) {
      this.uploading = false
      if (files) {
        const vm = this
        documentUtil
          .getThumbnails(this.$store, files)
          .then(function (extendedFiles) {
            vm.form.files = vm.form.files.concat(extendedFiles)
            documentUtil.sortDocumentArray(vm.form.files)
          })
      }
    },
  },
}
</script>

<!--
Scoped Styles werden, wenn diese Komponente mit 'extends' wiederverwendet
wird, im Parent nicht mehr berücksichtigt. Daher wird dieser Teil ausgelagert
und in den Parents importiert. ACHTUNG: Hier trotzdem auch belassen/importieren.
Dies ist nötig, falls die Komponente, als 'Component-Child' verwendet wird!
-->
<style lang="css" scoped src="./Input.css"></style>

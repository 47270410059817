import VLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import { StyleLike } from 'ol/style/Style'
import { Options as VLayerOptions } from 'ol/source/Cluster'
import { Geometry } from 'ol/geom'
import Feature from 'ol/Feature'

class VectorLayer {
  vectorSource: VectorSource
  vectorLayer: VLayer<VectorSource>

  constructor(id: string, styleFunction: StyleLike | undefined) {
    this.vectorSource = new VectorSource({
      format: new GeoJSON(),
    })
    this.vectorLayer = new VLayer({
      id: id, // style and click handler need id, even though property is not defined on VLayer or VLayerOptions
      source: this.vectorSource,
      style: styleFunction,
    } as VLayerOptions)
  }
  getLayer(): VLayer<VectorSource> {
    return this.vectorLayer
  }
  getSource(): VectorSource {
    return this.vectorSource
  }
  addFeatures(
    data: ArrayBuffer | Document | Node | Record<string, unknown> | string
  ): void {
    this.vectorSource.clear(true)
    const features = new GeoJSON().readFeatures(data, {
      featureProjection: 'EPSG:21781',
    })
    this.vectorSource.addFeatures(features as Feature<Geometry>[])
  }
}

export default VectorLayer

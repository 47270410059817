import observationService from '@/services/observationService.js'
import util from '@/scripts/util.js'
import moment from 'moment'

// state
const state = {
  dangerFeatureCollection: [], // danger observations of selected date for map
}

// getters
const getters = {}

// actions
const actions = {
  loadDangerObservationFeaturesByObservationDateTimeRange(context, params) {
    const promises = []
    let individualError = false
    const handleError = function (error) {
      // NOTE (20190815/csz): Partial error (one of the services failed), therefore do not set feature
      //                      collection globally to empty -> problem if both fail individually and
      //                      not globally and collection remains in an old non-valid state...
      // context.commit('SET_DANGER_FEATURE_COLLECTION', [])
      individualError = true
      console.error(error)
      if (!(error.response && error.response.status === 401)) {
        context.commit('SET_EXCEPTION', error.message, { root: true })
      }
      return error
    }

    let lowerBound = null
    let upperBound = null
    let lowerBoundWithoutZone = null
    let upperBoundWithoutZone = null
    let periodString = null
    const interval = params.observationDateTimeRange.split('/')
    if (interval && interval.length === 2) {
      lowerBound = moment(interval[0]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
      upperBound = moment(interval[1]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
      lowerBoundWithoutZone = moment(interval[0]).format('YYYY-MM-DDTHH:mm')
      upperBoundWithoutZone = moment(interval[1]).format('YYYY-MM-DDTHH:mm:ss')
      periodString = moment.duration(upperBound.diff(lowerBound)).toISOString()
    } else {
      const datePeriod = params.observationDateTimeRange.split('P')
      periodString = 'P' + datePeriod[1]
      lowerBound = moment(datePeriod[0]).utc().format('YYYY-MM-DDTHH:mm:ssZ')
      lowerBoundWithoutZone = moment(datePeriod[0]).format('YYYY-MM-DDTHH:mm')
      upperBoundWithoutZone = moment(datePeriod[0])
        .add(moment.duration(periodString))
        .subtract(1, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    const cancelObj = { cancel: null }
    promises.push(
      observationService.getObservationsByObservationDateTimeRange(
        lowerBoundWithoutZone,
        upperBoundWithoutZone,
        'DANGER',
        cancelObj
      )
    )
    if (params.cancel) {
      params.cancel.addCancel(cancelObj.cancel)
    }

    // NOTE (20190813/csz): deliver partial results even if one of the services returns w/ error - better than no results at all
    return Promise.all(
      promises.map(function (promise) {
        return promise.catch(handleError)
      })
    )
      .then(function (res) {
        const featureCollection = {
          type: 'FeatureCollection',
          crs: { type: 'name', properties: { name: 'EPSG:21781' } },
          features: [],
        }
        res.forEach((result) => {
          if (result && result.data) {
            if (result.data.dataType === 'Observation') {
              // i.e. features delivered from measurement service
              featureCollection.features = featureCollection.features.concat(
                util.mapObservationsToFeatures('danger', result.data.data)
              ) // w/ geometry conversion
            }
          }
        })
        context.commit('SET_DANGER_FEATURE_COLLECTION', featureCollection)
        if (individualError !== true) {
          // i.e. all promises w/o error
          context.commit('SET_EXCEPTION', null, { root: true })
        }
        console.log('danger observations by date loaded')
      })
      .catch(function (error) {
        context.commit('SET_DANGER_FEATURE_COLLECTION', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

// mutations
const mutations = {
  SET_DANGER_FEATURE_COLLECTION(state, dangerFeatureCollection) {
    state.dangerFeatureCollection = dangerFeatureCollection
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

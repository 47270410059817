import { Layer } from 'ol/layer'
import {
  arialImageryTileLayer,
  colorMapTileLayer,
  slopeClassesTileLayer,
} from '@/scripts/layers/tileLayerUtil'
import { geoLayer } from '@/scripts/layers/GeoLayer'
import Border from '@/assets/border.json'
import Lakes from '@/assets/lakes.json'
import { AdditionalLayer, BaseLayer } from '@/model/map/commonLayers'

export function createCommonLayers(): { name: string; layer: Layer }[] {
  const colorMapLayer = colorMapTileLayer()
  const arialImageryLayer = arialImageryTileLayer()
  arialImageryLayer.setVisible(false)

  const borderLayer = geoLayer(Border, 4)
  const lakesLayer = geoLayer(Lakes, 1)

  const slopeClassesLayer = slopeClassesTileLayer()
  slopeClassesLayer.setVisible(false)

  return [
    { name: BaseLayer.COLOR_MAP, layer: colorMapLayer },
    { name: BaseLayer.ARIAL_IMAGERY, layer: arialImageryLayer },
    { name: 'baseLayer.alwaysVisible', layer: borderLayer },
    { name: 'baseLayer.alwaysVisible', layer: lakesLayer },
    { name: AdditionalLayer.SLOPE_CLASSES, layer: slopeClassesLayer },
  ]
}

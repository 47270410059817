<template>
  <b-card header-tag="header" footer-tag="footer" id="das" class="p-0">
    <template #header>
      <b-row>
        <b-col sm="12">
          <b>{{ $t('ui.moreAvalanches') }}</b>
        </b-col>
      </b-row>
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button type="warning" variant="warning" @click="routeBack()">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-center"> </b-col>
        <b-col class="text-right">
          <b-button @click="onSubmit" variant="success"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
          <!-- Abgangsdatum -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.triggerDate')"
                :info="$t('help.avalanches.triggerDate')"
              />
            </template>
            <calendar-component
              :class="{
                'calendar-picker': true,
                'invalid-comp-border': dateState === false,
              }"
              v-model="dateString"
              name="datepicker"
              @update:model-value="setDate"
              :max="dpOptions.maxDate"
            />
            <!-- TODO ec: to test -->
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Abgangszeitraum -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.severalTime')"
                :info="$t('help.avalanche.triggerTime')"
              />
            </template>
            <b-form-checkbox
              v-model="displayTimeAll"
              :indeterminate="displayTimeIndeterminate"
              aria-describedby="severalTimeInput"
              aria-controls="severalTimeInput"
              @change="toggleTimes"
            >
              {{ $t('avalanche.severalTime.FULL_TIME') }}
            </b-form-checkbox>
            <b-form-checkbox-group
              id="severalTimeInput"
              v-model="form.time"
              :options="severalTime"
              name="times"
              class="ml-4"
              aria-label="Individual times"
              stacked
            >
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form class="mapForm" style="height: 600px">
        <MapComponent
          v-if="
            !this.$store.state.avalanche.selected ||
            (this.$store.state.avalanche.selected && form.id)
          "
          :avalanche="form"
          :date="form.date"
          @avalancheGeo="setCoordinates"
          :adapt-map="adaptMap"
        >
        </MapComponent>
      </b-form>
      <br />

      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="locationState"
              aria-describedby="locationFeedback"
            />
            <b-form-invalid-feedback id="locationFeedback">
              {{ $t('msg.validLocation') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <!-- Lawinenfeuchte -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.avalancheMoisture')"
                :info="$t('help.avalanche.avalancheMoisture')"
              />
            </template>
            <b-form-select
              id="avalancheMoistureInput"
              size="sm"
              v-model="form.avalancheMoisture"
              :options="avalancheMoisture"
              :state="avalancheMoistureState"
              required
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Lawinenart -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.avalancheType')"
                :info="$t('help.avalanche.avalancheType')"
              />
            </template>
            <b-form-select
              id="typeInput"
              size="sm"
              v-model="form.avalancheType"
              :options="avalancheType"
              :state="avalancheTypeState"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <!-- Auslöseart -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.triggerType')"
                :info="$t('help.avalanche.triggerType')"
              />
            </template>
            <b-form-select
              id="triggerInput"
              size="sm"
              v-model="form.triggerType"
              :options="triggerType"
              :state="triggerTypeState"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <!-- Höhenlagen -->
          <b-form-group
            :invalid-feedback="startZoneElevationInvalidFeedback"
            :state="startZoneElevationState"
          >
            <template #label>
              <InfoLabel
                :text="
                  $t('avalanche.label.startZoneElevationRange') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.avalanches.startZoneElevationRange')"
              />
            </template>
            <b-input-group>
              <b-form-select
                size="sm"
                v-model.number="form.startZoneElevationRange[0]"
                :options="startZoneElevation"
                :state="startZoneElevationRange0State"
                required
              >
              </b-form-select>
              <b-form-select
                size="sm"
                v-model.number="form.startZoneElevationRange[1]"
                :options="startZoneElevation"
                :state="startZoneElevationRange1State"
                required
              >
              </b-form-select>
            </b-input-group>
            <b-form-input hidden :state="startZoneElevationState" />
          </b-form-group>
        </b-col>
        <b-col md="4" offset="2">
          <!-- Anrissmächtigkeiten -->
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('avalanche.label.fractureThicknessMean') +
                  ' [' +
                  $t('common.unit.centimeter') +
                  ']'
                "
                :info="$t('help.avalanches.fractureThicknessMeanSeveral')"
              />
            </template>
            <b-form-select
              size="sm"
              v-model="form.fractureThicknessMean"
              :options="fractureThicknessMean"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <!-- Lawinengrössen und Expositionen -->
          <b-form-group
            :invalid-feedback="startZoneAspectInvalidFeedback"
            :state="startZoneAspectState"
          >
            <template #label>
              <InfoLabel
                :text="$t('avalanche.label.startZoneAspectCount')"
                :info="$t('help.avalanches.avalancheSize')"
              />
            </template>
            <b-input-group disabled>
              <b-form-input class="label-column" size="sm" disabled />
              <b-form-input
                size="sm"
                placeholder="N"
                class="text-center number-column"
                disabled
              />
              <b-form-input
                size="sm"
                placeholder="E"
                class="text-center number-column"
                disabled
              />
              <b-form-input
                size="sm"
                placeholder="S"
                class="text-center number-column"
                disabled
              />
              <b-form-input
                size="sm"
                placeholder="W"
                class="text-center number-column"
                disabled
              />
            </b-input-group>
            <b-input-group>
              <b-form-input
                class="label-column"
                size="sm"
                :placeholder="$t('avalanche.avalancheSize.ONE')"
                disabled
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectNorth['ONE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectEast['ONE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectSouth['ONE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectWest['ONE']"
                class="text-center number-column"
              />
            </b-input-group>
            <b-input-group>
              <b-form-input
                class="label-column"
                size="sm"
                :placeholder="$t('avalanche.avalancheSize.TWO')"
                disabled
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectNorth['TWO']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectEast['TWO']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectSouth['TWO']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectWest['TWO']"
                class="text-center number-column"
              />
            </b-input-group>
            <b-input-group>
              <b-form-input
                class="label-column"
                size="sm"
                :placeholder="$t('avalanche.avalancheSize.THREE')"
                disabled
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectNorth['THREE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectEast['THREE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectSouth['THREE']"
                class="text-center number-column"
              />
              <b-form-input
                type="number"
                size="sm"
                min="1"
                max="1000"
                v-model.number="displayStartZoneAspectWest['THREE']"
                class="text-center number-column"
              />
            </b-input-group>
            <b-form-input hidden :state="startZoneAspectState" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row style="margin-top: 10px; margin-bottom: 10px">
        <b-col cols="md-11">
          <InfoLabel
            :text="$t('observation.label.attachmentText')"
            :info="$t('help.common.upload')"
          />
        </b-col>
        <b-col cols="md-1">
          <b-button size="sm" variant="default" @click="uploading = !uploading">
            <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>

      <!-- Fileliste -->
      <b-row v-if="uploading">
        <b-col>
          <FileUpload
            @uploaded="uploaded"
            :location-point="documentLocation"
            :type="inputType"
            :restriction="form.confidential"
          ></FileUpload>
          <!-- TODO: in dieser komponente ist description noch drin, für several braucht man das nicht! -->
        </b-col>
      </b-row>
      <b-row align-h="start" v-if="!uploading" class="line">
        <b-col
          cols="lg-2 md-3"
          v-for="file in form.files"
          :key="file.id"
          class="label"
        >
          <b-img
            class="thumbnail"
            :src="file.thumbnail"
            :title="file.fileName"
          />
          <div>
            <span class="caption"
              ><span class="fileMeta"
                >{{ $t('avalanche.document.fileDescription') }}:</span
              ></span
            >
            <textarea
              :id="file.id"
              type="text"
              v-model="file.description"
              class="editId"
            ></textarea>
          </div>
          <b-button
            class="trash"
            size="sm"
            variant="warning"
            @click="markFileToRemove(form.files, file.id)"
          >
            <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>

      <!-- Kommentar -->
      <hr />
      <b-row>
        <b-col cols="md-12">
          <b-form-group label-cols-sm="4" label-cols-lg="3">
            <template #label>
              <InfoLabel
                :text="$t('common.comment')"
                :info="$t('help.observation.comment')"
              />
            </template>
            <b-form-textarea
              id="comment"
              v-model="form.noteText"
              :rows="3"
              :state="noteTextState"
              aria-describedby="noteTextFeedback"
              size="lg"
            />
            <b-form-invalid-feedback id="noteTextFeedback">
              {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row style="margin-top: 10px">
        <b-col sm="1">
          <b-form-checkbox
            id="rights"
            size="sm"
            v-model="form.confidential"
            :options="flag"
          />
        </b-col>
        <b-col sm="11">
          <span>{{ $t('observation.label.confidentialTextLess') }}</span>
        </b-col>
      </b-row>
    </b-form>

    <br />
    <hr />
    <p class="card-text mb-3">
      {{ $t('avalanche.label.warnUseSingleAvalanche') }}
    </p>
  </b-card>
</template>

<script>
import MapComponent from './MapOL'
import { i18nMixin } from '../I18N'
import { v1 as uuidV1 } from 'uuid'
import FileUpload from '../attachment/FileUpload'
import { AVAL } from '@/scripts/const'
import documentUtil, {
  RESTRICTED,
  NOT_RESTRICTED,
} from '../../scripts/documentUtil.js'
import moment from 'moment'
import CalendarComponent from '../calendar/CalendarComponent'
import util from '@/scripts/util.js'
import InfoLabel from '../InfoLabel'
import { deepClone } from '@/scripts/common'
import { useStagedDocumentRemover } from '@/composables/useStagedDocumentRemover'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Input.Several',
  components: {
    MapComponent,
    FileUpload,
    InfoLabel,
    CalendarComponent,
  },
  mixins: [i18nMixin],
  data() {
    return {
      startZoneElevation: [
        '200',
        '400',
        '600',
        '800',
        '1000',
        '1200',
        '1400',
        '1600',
        '1800',
        '2000',
        '2200',
        '2400',
        '2600',
        '2800',
        '3000',
        '3200',
        '3400',
        '3600',
        '3800',
        '4000',
        '4200',
        '4400',
        '4600',
        '4800',
      ],
      fractureThicknessMean: [
        '10',
        '20',
        '30',
        '40',
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
        '110',
        '120',
        '130',
        '140',
        '150',
      ],
      flag: this.options('common', 'flag', true),
      uploading: false,
      showdatepicker: false,
      processing: null,
      formSubmitted: null,
      form: {
        id: null,
        date: null,
        time: [],
        coord: null,
        avalancheMoisture: null,
        avalancheType: null,
        triggerType: null,
        startZoneElevationRange: [null, null],
        fractureThicknessMean: null,
        tags: {
          observerGroup: null,
        },
        avalancheDocumentIds: [],
        files: [],
        noteText: null,
        confidential: null,
        version: 0,
      },
      displayTimeAll: false,
      displayTimeIndeterminate: false,
      displayStartZoneAspectNorth: {
        ONE: null,
        TWO: null,
        THREE: null,
      },
      displayStartZoneAspectEast: {
        ONE: null,
        TWO: null,
        THREE: null,
      },
      displayStartZoneAspectSouth: {
        ONE: null,
        TWO: null,
        THREE: null,
      },
      displayStartZoneAspectWest: {
        ONE: null,
        TWO: null,
        THREE: null,
      },
      routeFrom: null,
      adaptMap: false,
      new: true,
      originalFiles: [], // backup of origimal documents
      dpOptions: {
        showClose: true,
        maxDate: moment().endOf('day').toISOString(),
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
      if (
        from.path.includes('/observation/list') ||
        from.path.includes('/observation/avalanche')
      ) {
        vm.adaptMap = true
      }
    })
  },
  watch: {
    'form.time': function (newVal) {
      // Handle changes in individual times checkboxes
      if (newVal.length === 0) {
        this.displayTimeIndeterminate = false
        this.displayTimeAll = false
      } else if (
        newVal.length === this.enums('avalanche')['severalTime'].length
      ) {
        this.displayTimeIndeterminate = false
        this.displayTimeAll = true
      } else {
        this.displayTimeIndeterminate = true
        this.displayTimeAll = false
      }
    },
  },
  /*eslint-disable complexity*/
  setup() {
    return useStagedDocumentRemover()
  },
  async created() {
    if (this.$store.state.avalanche.selected) {
      this.new = false
      const selectedForm = deepClone(this.$store.state.avalanche.selected)

      // re-fill times
      if (selectedForm.time) {
        const ind = selectedForm.time.findIndex((elem) => {
          return elem === this.enums('avalanche')['severalTimeAll']
        })
        if (ind > -1) {
          this.displayTimeAll = true
          selectedForm.time.splice(ind, 1)
        } else {
          this.displayTimeAll = false
        }
      }

      // re-fill matrix from sizeAspectCounts
      if (selectedForm.sizeAspectCounts) {
        selectedForm.sizeAspectCounts.forEach((item) => {
          if (item.startZoneAspect === 'N') {
            this.displayStartZoneAspectNorth[item.avalancheSize] = item.count
          }
          if (item.startZoneAspect === 'E') {
            this.displayStartZoneAspectEast[item.avalancheSize] = item.count
          }
          if (item.startZoneAspect === 'S') {
            this.displayStartZoneAspectSouth[item.avalancheSize] = item.count
          }
          if (item.startZoneAspect === 'W') {
            this.displayStartZoneAspectWest[item.avalancheSize] = item.count
          }
        })
        delete selectedForm.sizeAspectCounts
      }

      // re-fill into files for display purposes
      const promises = []
      selectedForm.files = []
      if (selectedForm.avalancheDocumentIds) {
        selectedForm.avalancheDocumentIds.forEach((docId) => {
          promises.push(
            this.$store
              .dispatch('document/getDocumentMetaWithThumbnail', { id: docId })
              .then((meta) => {
                // NOTE: for removed documents only metadata available, no thumbnail and no content - doesn't serve
                // to show and re-save them along w/ the observation. if observation saved afterwards, inconsistent
                // state (caused by a possible cancel instead of save after document removal) can be eliminated
                if (
                  typeof meta.removeDate === 'undefined' ||
                  !meta.removeDate
                ) {
                  selectedForm.files.push(meta)
                }
              })
              .catch((err) => {
                // NOTE: add even if not known whether failed to load a normal or deleted document - otherwise, loss
                // of document for an observation possible if someone saves
                selectedForm.files.push({
                  id: docId,
                  thumbnailUrl: '',
                  url: '',
                  description: '',
                  mediaType: '',
                  thumbnail: null,
                })
                console.log(
                  "document meta for docId '" +
                    docId +
                    "' could not be loaded, %o",
                  err
                )
              })
          )
        })
      }

      try {
        await Promise.all(promises)
        this.originalFiles = deepClone(selectedForm.files) // by entering the form, backup origimal documents if any here
      } catch (e) {
        console.error('could not set original files!', e)
        // not correct set but only way to avoid that otherwise form.files content be interpreted as new files which in case of a cancel would be deleted!
        this.originalFiles = selectedForm.files
      }
      documentUtil.sortDocumentArray(selectedForm.files)

      this.form = selectedForm
      if (!this.form.files) {
        this.form.files = []
      }
    }
  },
  /*eslint-enable complexity*/

  beforeRouteLeave(to, from, next) {
    // NOTE (error handling): rollback potentially added/removed documents
    // NOTE: capture event of browser back and route changes -> synch document list
    this.checkDocumentState()

    // NOTE: this function needed to re-set the selected observation - before destroy on component called too late
    this.$store.commit('avalanche/SET_SELECTED', null)

    next()
  },
  beforeMount() {
    // NOTE: capture event of navigating to a new URL and refresh -> synch document list
    window.addEventListener('beforeunload', this.checkDocumentState)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.checkDocumentState)
    this.$store.commit('avalanche/SET_SELECTED', null)
    this.reset()
  },
  computed: {
    severalTime() {
      return this.options('avalanche', 'severalTime', true)
    },
    avalancheMoisture() {
      return this.options('avalanche', 'avalancheMoisture', false)
    },
    avalancheType() {
      return this.options('avalanche', 'avalancheType', false)
    },
    triggerType() {
      return this.options('avalanche', 'triggerType', false)
    },
    /*eslint-disable complexity*/

    complete() {
      return (
        this.dateState === true &&
        this.locationState === true &&
        this.avalancheMoistureState === true &&
        this.avalancheTypeState === true &&
        this.triggerTypeState === true &&
        this.startZoneElevationRange0State === true &&
        this.startZoneElevationRange1State === true &&
        this.startZoneAspectState === true &&
        this.startZoneElevationState !== false &&
        this.noteTextState !== false
      )
    },
    /*eslint-enable complexity*/
    locationState() {
      if (this.formSubmitted) {
        return !!this.form.coord
      } else {
        return null
      }
    },
    documentLocation() {
      // NOTE: location to be saved w/ documents if any
      return this.form.coord
    },
    dateState() {
      if (this.formSubmitted) {
        // NOTE: primitive - non correct regex - but better than w/o at all...
        return (
          this.notEmpty(this.form.date) &&
          this.form.date.match(
            /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
          ) !== null
        )
      } else {
        return null
      }
    },
    avalancheTypeState() {
      if (this.formSubmitted) {
        return !!this.form.avalancheType
      } else {
        return null
      }
    },
    avalancheMoistureState() {
      if (this.formSubmitted) {
        return !!this.form.avalancheMoisture
      } else {
        return null
      }
    },
    triggerTypeState() {
      if (this.formSubmitted) {
        return !!this.form.triggerType
      } else {
        return null
      }
    },
    startZoneElevationRange0State() {
      if (this.formSubmitted) {
        return (
          !!this.form.startZoneElevationRange[0] &&
          this.startZoneElevationState !== false
        )
      } else {
        return null
      }
    },
    startZoneElevationRange1State() {
      if (this.formSubmitted) {
        return (
          !!this.form.startZoneElevationRange[1] &&
          this.startZoneElevationState !== false
        )
      } else {
        return null
      }
    },
    startZoneElevationState() {
      if (this.formSubmitted) {
        return (
          !this.form.startZoneElevationRange[0] ||
          !this.form.startZoneElevationRange[1] ||
          Number(this.form.startZoneElevationRange[0]) <=
            Number(this.form.startZoneElevationRange[1])
        )
      } else {
        return null
      }
    },
    startZoneElevationInvalidFeedback() {
      if (this.startZoneElevationState === false) {
        return this.$i18n.t('msg.validElevationRange')
      } else {
        return ''
      }
    },
    startZoneAspectInvalidFeedback() {
      if (this.startZoneAspectState !== true) {
        return this.$i18n.t('msg.severalAspect', { min: '1', max: '1000' })
      } else {
        return ''
      }
    },
    /*eslint-disable complexity*/
    startZoneAspectState() {
      for (const key in this.displayStartZoneAspectNorth) {
        if (
          this.displayStartZoneAspectNorth[key] &&
          (this.displayStartZoneAspectNorth[key] <= 0 ||
            this.displayStartZoneAspectNorth[key] > 1000)
        ) {
          return false
        }
      }
      for (const key in this.displayStartZoneAspectEast) {
        if (
          this.displayStartZoneAspectEast[key] &&
          (this.displayStartZoneAspectEast[key] <= 0 ||
            this.displayStartZoneAspectEast[key] > 1000)
        ) {
          return false
        }
      }
      for (const key in this.displayStartZoneAspectSouth) {
        if (
          this.displayStartZoneAspectSouth[key] &&
          (this.displayStartZoneAspectSouth[key] <= 0 ||
            this.displayStartZoneAspectSouth[key] > 1000)
        ) {
          return false
        }
      }
      for (const key in this.displayStartZoneAspectWest) {
        if (
          this.displayStartZoneAspectWest[key] &&
          (this.displayStartZoneAspectWest[key] <= 0 ||
            this.displayStartZoneAspectWest[key] > 1000)
        ) {
          return false
        }
      }
      if (
        this.notEmpty(this.displayStartZoneAspectNorth['ONE']) ||
        this.notEmpty(this.displayStartZoneAspectNorth['TWO']) ||
        this.notEmpty(this.displayStartZoneAspectNorth['THREE']) ||
        this.notEmpty(this.displayStartZoneAspectEast['ONE']) ||
        this.notEmpty(this.displayStartZoneAspectEast['TWO']) ||
        this.notEmpty(this.displayStartZoneAspectEast['THREE']) ||
        this.notEmpty(this.displayStartZoneAspectSouth['ONE']) ||
        this.notEmpty(this.displayStartZoneAspectSouth['TWO']) ||
        this.notEmpty(this.displayStartZoneAspectSouth['THREE']) ||
        this.notEmpty(this.displayStartZoneAspectWest['ONE']) ||
        this.notEmpty(this.displayStartZoneAspectWest['TWO']) ||
        this.notEmpty(this.displayStartZoneAspectWest['THREE'])
      ) {
        return true
      } else {
        if (this.formSubmitted) {
          return false
        } else {
          return null
        }
      }
    },
    /*eslint-enable complexity*/
    noteTextState() {
      if (!this.form.noteText) {
        return null
      }
      return (
        this.form.noteText &&
        this.form.noteText.length >= 2 &&
        this.form.noteText.length <= 500
      )
    },
    inputType() {
      return AVAL
    },
    allowdatepicker() {
      return !util.isDateSupported()
    },
    dateString: {
      get() {
        if (this.form.date) {
          return moment(this.form.date).format('DD.MM.YYYY')
        } else {
          // Analog MeasureAssessmentInput.vue ==> nextPlanedDateString:
          return moment(null).format('DD.MM.YYYY')
        }
      },
      set(val) {
        if (val) {
          this.form.date = moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD')
        } else {
          this.form.date = null
        }
      },
    },
  },
  methods: {
    notEmpty(obj) {
      return !!obj && obj !== 'undefined' && obj !== ''
    },
    onSubmit() {
      // terminate the function if an async request is processing
      if (this.processing === true) {
        return
      }
      // set the async state
      this.processing = true

      this.formSubmitted = true

      this.removeFiles()

      if (this.complete) {
        this.preSubmitValidate()
        let avalPromise

        // transmit avalanche
        if (this.$store.state.avalanche.selected) {
          avalPromise = this.$store.dispatch(
            'avalanche/updateSeveralAvalanche',
            this.form
          )
        } else {
          avalPromise = this.$store.dispatch(
            'avalanche/addSeveralAvalanche',
            this.form
          )
        }
        const self = this
        avalPromise.then(
          () => {
            // on success or failure reset the state
            self.processing = false
            self.routeBack()
          },
          (reason) => {
            // NOTE: no rollback of documents here, since this.form.files already removed as a prerequisite for save and
            // we do not want to introduce a 3rd shadow object...- therefore no reconstruction
            console.error('Error submitting avalanche:' + reason)
            self.processing = false
          }
        )
      } else {
        event.preventDefault()
        event.stopPropagation()
        window.scrollTo(0, 0) // NOTE: direct workaround
        this.processing = false
      }
    },
    routeBack() {
      if (this.adaptMap) {
        if (this.new || this.routeOrigin.includes('/observation/avalanche')) {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
              openObs: this.$store.state.avalanche.selected.id,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    /*eslint-disable complexity*/

    preSubmitValidate() {
      // re-fill times
      if (this.displayTimeAll === true) {
        if (!this.form.time) {
          this.form.time = []
        }
        this.form.time = this.form.time.concat(
          this.enums('avalanche')['severalTimeAll']
        )
      }

      // fill document ids
      this.form.avalancheDocumentIds = []
      if (this.form.files) {
        this.form.files.forEach((file) => {
          file.restriction =
            this.form.confidential === true ? RESTRICTED : NOT_RESTRICTED
          if (util.isPoint(this.documentLocation.location)) {
            file.lon = this.documentLocation.coordinates[0]
            file.lat = this.documentLocation.coordinates[1]
          }
          this.$store.dispatch('document/updateDocumentMeta', file)
          this.form.avalancheDocumentIds.push(file.id)
        })
      }
      delete this.form.files

      delete this.form.avalancheClass // NOTE: flag might come from avalanche list queries

      // fill sizeAspectCounts
      this.form.sizeAspectCounts = []
      for (const key in this.displayStartZoneAspectNorth) {
        if (this.displayStartZoneAspectNorth[key]) {
          this.form.sizeAspectCounts.push({
            startZoneAspect: 'N',
            avalancheSize: key,
            count: this.displayStartZoneAspectNorth[key],
          })
        }
      }
      for (const key in this.displayStartZoneAspectEast) {
        if (this.displayStartZoneAspectEast[key]) {
          this.form.sizeAspectCounts.push({
            startZoneAspect: 'E',
            avalancheSize: key,
            count: this.displayStartZoneAspectEast[key],
          })
        }
      }
      for (const key in this.displayStartZoneAspectSouth) {
        if (this.displayStartZoneAspectSouth[key]) {
          this.form.sizeAspectCounts.push({
            startZoneAspect: 'S',
            avalancheSize: key,
            count: this.displayStartZoneAspectSouth[key],
          })
        }
      }
      for (const key in this.displayStartZoneAspectWest) {
        if (this.displayStartZoneAspectWest[key]) {
          this.form.sizeAspectCounts.push({
            startZoneAspect: 'W',
            avalancheSize: key,
            count: this.displayStartZoneAspectWest[key],
          })
        }
      }

      // NOTE (20190826/csz): need to set confidentiality flag explicitly since per default confidential=true if not set
      if (this.form.confidential !== true) {
        this.form.confidential = false
      }

      // fill tags
      if (!this.$store.state.avalanche.selected) {
        // NOTE: if coming from edit modus, no way to know under which group the user acts ->
        // observerGroup reflects group the avalanche was created under, cannot be modified later
        this.form.tags.observerGroup = this.$store.state.profile.observerGroup
          ? this.$store.state.profile.observerGroup
          : null // maxlen: 256
      }
      // adjust version if needed
      if (this.$store.state.avalanche.selected) {
        // this.form.version ++
      } else {
        this.form.id = uuidV1()
        delete this.form.version
      }
    },
    /*eslint-enable complexity*/

    reset() {
      this.formSubmitted = null
      this.form = {
        id: null,
        date: null,
        time: [],
        coord: null,
        avalancheMoisture: null,
        avalancheType: null,
        triggerType: null,
        startZoneElevationRange: [null, null],
        fractureThicknessMean: null,
        tags: {
          observerGroup: null,
        },
        avalancheDocumentIds: [],
        files: [],
        noteText: null,
        confidential: null,
        version: 0,
      }
      this.displayTimeAll = false
      this.displayTimeIndeterminate = false
      this.displayStartZoneAspectNorth = {
        ONE: null,
        TWO: null,
        THREE: null,
      }
      this.displayStartZoneAspectEast = {
        ONE: null,
        TWO: null,
        THREE: null,
      }
      this.displayStartZoneAspectSouth = {
        ONE: null,
        TWO: null,
        THREE: null,
      }
      this.displayStartZoneAspectWest = {
        ONE: null,
        TWO: null,
        THREE: null,
      }
    },
    toggleTimes(checked) {
      this.form.time = checked
        ? this.enums('avalanche')['severalTime'].slice()
        : []
    },
    setCoordinates(point) {
      this.form.coord = point
    },
    checkDocumentState() {
      let lon = null
      let lat = null
      if (
        this.documentLocation &&
        util.isPoint(this.documentLocation.location)
      ) {
        lon = this.documentLocation.location.coordinates[0]
        lat = this.documentLocation.location.coordinates[1]
      }

      documentUtil.rollbackFiles(
        this.$store,
        this.originalFiles,
        this.form.files,
        {
          lon: lon,
          lat: lat,
          keywords: ['OBSERVATION'],
          eventDate: moment(),
          restriction:
            this.form.confidential === true ? RESTRICTED : NOT_RESTRICTED,
        }
      )
    },
    uploaded(files) {
      this.uploading = false
      if (files) {
        const vm = this
        documentUtil
          .getThumbnails(this.$store, files)
          .then(function (extendedFiles) {
            vm.form.files = vm.form.files.concat(extendedFiles)
            documentUtil.sortDocumentArray(vm.form.files)
          })
      }
    },
    toggledatepicker() {
      this.showdatepicker = !this.showdatepicker
    },
    setDate(val) {
      this.dateString = val
      this.showdatepicker = false
    },
  },
}
</script>

<style>
.datecalendar {
  width: 100% !important;
}
</style>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
input[type='date'] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}
.modal-body {
  padding: 0 !important;
}
.form-control.is-invalid,
.custom-select.is-invalid {
  border-width: medium;
}
.card {
  margin-bottom: 10px;
}
.card-body {
  padding-bottom: 0;
}
.thumbnail {
  border: 1px solid grey;
  margin: 10px;
  box-shadow: 6px 6px 2px grey;
}
.trash {
  position: absolute;
  top: 15px;
  right: 20px;
}
.editId {
  resize: vertical !important;
  width: 90%;
  height: 5rem;
}
.editId:focus {
  width: 100%;
}
.fileMeta {
  font-weight: 600;
}

.line {
  font: 14px Arial, Helvetica, sans-serif;
}
.line .label {
  padding: 6px 0;
}
.caption {
  display: block;
}

.label-column {
  width: 28%;
}

.number-column {
  width: 18%;
}

.calendar-picker {
  width: 100%;
  min-height: 1.2em;
  max-height: 1.94em;
}
</style>

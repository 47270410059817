import moment from 'moment'
import observationService from '@/services/observationService.js'

// Helpers
function prepareForAdd(context, assessmentObservation) {
  assessmentObservation.uid = context.rootGetters.user.id
  assessmentObservation.date = moment(context.state.date).format('YYYY-MM-DD')
  assessmentObservation.tags = {}

  return assessmentObservation
}
function prepareForUpdate(assessmentObservation) {
  assessmentObservation.id = state.selected.id
  assessmentObservation.uid = state.selected.uid
  assessmentObservation.date = state.date
  assessmentObservation.version = state.selected.version
  assessmentObservation.tags = state.selected.tags

  return assessmentObservation
}

// state
const state = {
  assessmentObs: [],
  todaySectors: [],
  date: new Date(),
  selected: null,
  hierarchyMap: new Map(), // ld -> area, sector selections
}

// getters
const getters = {
  /*
    Gibt die neueste Sektorbeurteilung, des heutigen Tages, für die übergebene sectorId zurück
  */
  getLatestSectorToSectorId: (state) => (sectorId) => {
    return state.todaySectors
      .filter(
        (el) => el.hierarchy.sectorId && el.hierarchy.sectorId === sectorId
      )
      .reduce(
        (latest, item) => (latest.modifyDate > item.modifyDate ? latest : item),
        {}
      )
  },
}

// actions
const actions = {
  loadSectorsFromToday({ commit }, options) {
    commit('SET_TODAY_SECTORS', [])
    const startDate = moment().startOf('day')
    const endDate = moment(startDate)
      .add(24 * 60, 'minutes')
      .subtract(1, 'seconds')

    // Optimierungspotential: lade nur aktuellste Sektoren vom eigenen Lawinendienst: SPASS-159
    return observationService
      .getObservationsByObservationDateTimeRange(
        startDate,
        endDate,
        options.type.toUpperCase(),
        options.cancel
      )
      .then((result) => {
        if (!result) {
          return
        }
        let sectors = []
        if (result.data.data) {
          sectors = result.data.data
        }
        commit('SET_TODAY_SECTORS', sectors)
        commit('SET_EXCEPTION', null, { root: true })
        console.debug('sectors from today loaded...')
      })
      .catch(function (error) {
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
  submitAssessmentObs({ state, dispatch }, assessmentObservation) {
    // assessmentObservation could be an array
    if (state.selected) {
      assessmentObservation.creatorSystem = state.selected.creatorSystem
      return dispatch('updateAssessmentObservation', assessmentObservation)
    } else {
      if (Array.isArray(assessmentObservation)) {
        return dispatch('addAssessmentObservations', assessmentObservation)
      }
      return dispatch('addAssessmentObservation', assessmentObservation)
    }
  },
  addAssessmentObservations(context, assessmentObservation) {
    const preparedAssessmentObs = []
    assessmentObservation.forEach((assessObs) => {
      preparedAssessmentObs.push(prepareForAdd(context, assessObs))
    })

    return new Promise((resolve, reject) => {
      observationService
        .addObservations(preparedAssessmentObs)
        .then(function () {
          console.debug('assessment-observations added')
          context.commit('ADD_ASSESSMENT_OBSERVATIONS', preparedAssessmentObs)
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
  addAssessmentObservation(context, assessmentObservation) {
    // eslint-disable-next-line no-param-reassign
    assessmentObservation = prepareForAdd(context, assessmentObservation)

    return new Promise((resolve, reject) => {
      observationService
        .addObservation(assessmentObservation)
        .then(function () {
          console.debug('assessment-observation added')
          context.commit('ADD_ASSESSMENT_OBSERVATION', assessmentObservation)
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
  updateAssessmentObservation({ commit }, assessmentObservation) {
    // eslint-disable-next-line no-param-reassign
    assessmentObservation = prepareForUpdate(assessmentObservation)

    return new Promise((resolve, reject) => {
      observationService
        .updateObservation(assessmentObservation)
        .then(function () {
          console.debug('assessment-observation updated')
          commit('UPDATE_ASSESSMENT_OBSERVATION', assessmentObservation)
          commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  ADD_ASSESSMENT_OBSERVATION(state, assessmentObservation) {
    state.assessmentObs.push(assessmentObservation)
  },
  ADD_ASSESSMENT_OBSERVATIONS(state, assessmentObservation) {
    state.assessmentObs = state.assessmentObs.concat(assessmentObservation)
  },
  UPDATE_ASSESSMENT_OBSERVATION(state, assessmentObservation) {
    for (let i = 0; i < state.assessmentObs.length; i++) {
      if (state.assessmentObs[i].id === assessmentObservation.id) {
        state.assessmentObs[i] = assessmentObservation
        break
      }
    }
  },
  SET_DATE(state, date) {
    state.date = date
  },
  SET_SELECTED(state, selected) {
    state.selected = selected
  },
  SELECT(state, obs) {
    state.selected = obs
    state.date = obs.date
  },
  SET_HIERARCHY(state, params) {
    // set area, sector selection w/ date for avalanche safety service
    if (params) {
      state.hierarchyMap.set(params.avalServiceId, params.hierarchy)
    }
  },
  SET_TODAY_SECTORS(state, todaySectors) {
    state.todaySectors = todaySectors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

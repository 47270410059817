<template>
  <!-- Schneefallgrenze -->
  <div>
    <Legend :legend="legend"></Legend>
    <CommonLayersPopup></CommonLayersPopup>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal
      detId="detailModal"
      :obsIds="obsIds"
      :showEdit="updatableModal"
      :aggregate="true"
    />
  </div>
</template>

<script>
import MeasurementStyle from '../styles/snowStyling/MeasurementStyle'
import Legend from '../Legend'
import DetailModal from '../observation/DetailModal'
import moment from 'moment'
import util from '@/scripts/util.js'
import { Canceler } from '@/scripts/requestUtil.js'
import CommonLayersPopup from '../CommonLayersPopup'
import { DateTime } from 'luxon'
import { MeasurementMapService } from '@/services/MeasurementMapService'
import { transformTimepointGeoJson } from '@/scripts/measurementMapTransformationUtil'
import { observationProjection } from '@/model/geoJsonModels'
import emitter from '@/components/EventHub'

const Cancel = Canceler.getCancelObj()

export default {
  mounted() {
    this.refresh()

    emitter.on('repos::map', (flag) => {
      this.isLoading = flag && flag === true
    })
  },
  props: ['moment'],
  watch: {
    moment: function () {
      this.refresh()
    },
  },
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { CommonLayersPopup, Legend, DetailModal },

  data() {
    return {
      legend: MeasurementStyle('snowRainAltitude').legend('snowRainAltitude'),
      updatableModal: false,
      obsIds: [],
      isLoading: false,
      checkLoading: {
        countCheck: 0, // counting variable
        toCount: 2, // expected amount of counts
      },
    }
  },

  methods: {
    findAndShowObservation(observationId, featureCollection) {
      this.obsIds = []
      const index = featureCollection.features.findIndex((item) => {
        return item.properties.id === observationId
      })
      if (index > -1) {
        this.updatableModal = moment().isSame(
          moment(featureCollection.features[index].timestamp),
          'd'
        )
        this.obsIds.push(observationId)
        this.$bvModal.show('detailModal')
      }
    },

    refresh() {
      this.isLoading = true

      console.debug('Cancel requests on load')
      this.cancelRequests()

      const self = this
      const clickHandler = function (feature) {
        if (feature.get('network') === 'IMIS') {
          self.$refs.stationRef.show(feature.get('id'))
        }
        if (feature.get('network') === 'OBSERVER') {
          self.obsIds = [feature.get('id')]
          self.$bvModal.show('detailModal')
        }
      }
      const resolutionChangeHandler = function (resolution, layers, spider) {
        spider.resolutionChangeHandler(
          layers['snowRainAltitude'].vectorLayer,
          resolution
        )
      }
      let featureCollection = null
      const now = Object.assign({}, this.$store.state.calendar.moment)
      const startDate = moment(now).subtract(210, 'minutes')
      const cancelObj = { cancel: null }

      this.$store
        .dispatch('snow/loadSnowObservationsByDateTimeInterval', {
          param: 'snowRainAltitude',
          startDate: startDate,
          duration: 420,
          cancel: cancelObj,
        })
        .then(() => {
          featureCollection = this.aggregate(
            featureCollection,
            Object.assign({}, self.$store.state.snow.snowFeatureCollection)
          )
          emitter.emit('layer', {
            name: 'snowRainAltitude',
            features: featureCollection,
            styleFunction: MeasurementStyle('snowRainAltitude').styleFunction,
            onClick: clickHandler,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit(
            'mouseOver',
            MeasurementStyle('snowRainAltitude').mouseOverFunction
          )
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }
          self.verifyLoading()
        })
        .catch((reason) => {
          console.error('Catching promise (' + reason + ').')
          self.verifyLoading()
        })
      Cancel.addCancel(cancelObj.cancel)

      const cancelObj2 = { cancel: null }
      this.getSnowRainGeoJson()
        .then((measuredDataGeoJson) => {
          featureCollection = this.aggregate(
            featureCollection,
            measuredDataGeoJson
          )
          util.addSLFStationNameAndTimeStamp(featureCollection)
          emitter.emit('layer', {
            name: 'snowRainAltitude',
            features: featureCollection,
            styleFunction: MeasurementStyle('snowRainAltitude').styleFunction,
            onClick: clickHandler,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit(
            'mouseOver',
            MeasurementStyle('snowRainAltitude').mouseOverFunction
          )
          self.verifyLoading()
        })
        .catch((reason) => {
          console.error('Catching promise (' + reason + ').')
          self.verifyLoading()
        })
      Cancel.addCancel(cancelObj2.cancel)
    },

    /**
     * Check if all service calls are done
     */
    verifyLoading() {
      this.checkLoading.countCheck++
      if (this.checkLoading.countCheck >= this.checkLoading.toCount) {
        this.isLoading = false
        this.checkLoading.countCheck = 0
        this.checkLoading.toCount = 1
      }
    },

    aggregate(fc1, fc2) {
      let fcAggregated
      if (fc1) {
        fcAggregated = { ...fc1 }
        fcAggregated.features = fcAggregated.features.concat(fc2.features)
      } else {
        fcAggregated = { ...fc2 }
      }
      return fcAggregated
    },

    cancelRequests() {
      Cancel.cancelAll()
    },

    async getSnowRainGeoJson(cancelObj) {
      const timestampAsMoment = moment(this.$store.state.calendar.moment)
      const selectedDateTime = DateTime.fromISO(
        timestampAsMoment.toISOString()
      ).toUTC()

      const dataAtStationGeoJson =
        await MeasurementMapService.getDataAtTimepoint(
          'SNOWFALL_LIMIT',
          selectedDateTime.toISO(),
          cancelObj
        )
      return transformTimepointGeoJson(
        dataAtStationGeoJson,
        selectedDateTime,
        observationProjection,
        (properties, value) => ({ ...properties, snowRainAltitude: value })
      )
    },
  },

  beforeUnmount() {
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },
}
</script>

<style scoped>
img {
  width: 600px;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

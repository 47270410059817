//SOLID
export const FA_SOLID_FA_FILTER = 'fa-solid fa-filter'
export const FA_SOLID_FA_CROSSHAIRS = 'fa-solid fa-crosshairs'
export const FA_SOLID_FA_INFO_CIRCLE = 'fa-solid fa-info-circle'
export const FA_SOLID_FA_LAYER_GROUP = 'fa-solid fa-layer-group'
export const FA_SOLID_FA_LIST = 'fa-solid fa-list'
export const FA_SOLID_FA_EDIT = 'fa-solid fa-edit'
export const FA_SOLID_FA_ARROW_UP = 'fa-solid fa-arrow-up'
export const FA_SOLID_FA_ARROW_DOWN = 'fa-solid fa-arrow-down'

// REGULAR
export const FA_REGULAR_FA_TRASH_ALT = 'fa-regular fa-trash-alt'

// LIGHT
export const FA_LIGHT_FA_SENSOR_ALERT = 'fa-light fa-sensor-alert'

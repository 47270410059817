import observerService from '@/services/observerService.js'
import assessmentService from '@/services/assessmentService.js'
import Mapper from '@/model/assessment/Mapper'
import { KeycloakAuthentication } from '@/plugins/KeycloakAuthentication'
import { Jwt } from '@/plugins/jwt'

// state
const state = {
  userGroups: [], // user groups of logged in observer
  observerGroup: null, // selected group of observer
  studyplotIdToObserverGroup: null, // studyplot id to the selected observergroup
  studyplots: [],
  avalancheServices: [], // avalanche services where the user have membership { id, name }
  avalancheServiceId: null, // selected avalanche safety service of logged in user

  /** list of aval safety services for assessment */
  assmAvalServices: [],
  areas: {},
  sectors: {},
  objects: {},
  avalanchePaths: {},

  /** list of all ONLY ACTIVE aval safety services and sub objects
   * ==> see SET_ALL_AVALSERVICES to add other missing sub objects if needed
   */
  allActiveAvalServices: [],
  allActiveAreas: {},
  allActiveObjects: {},
}

// getters
const getters = {
  getObserverGroupName: (state) => (id) => {
    const userGroup = state.userGroups.find((userGroup) => userGroup.id === id)
    return userGroup ? userGroup.name : undefined
  },
  getObserverGroupId(state) {
    if (state.observerGroup && state.observerGroup !== null) {
      return state.observerGroup
    }
    return null
  },
  getAvalServiceId(state) {
    return state.avalancheServiceId
  },
  getAssmAvalServiceToId: (state) => (id) => {
    return state.assmAvalServices.find(
      (assmAvalService) => assmAvalService.id === id
    )
  },
  getAllObjectsToProtect(state) {
    return state.assmAvalServices
      .reduce((arr, curr) => arr.concat(curr.areas), [])
      .reduce((arr, curr) => arr.concat(curr.objects), [])
  },
  getObjectToProtect: (state) => (id) => {
    return getters.getAllObjectsToProtect(state).find((el) => el.id === id)
  },
  getAllAreas(state) {
    return state.assmAvalServices.reduce(
      (arr, curr) => arr.concat(curr.areas),
      []
    )
  },
  getAreasToAvalServiceId: (state) => (id) => {
    const ld = state.assmAvalServices.find(
      (assmAvalService) => assmAvalService.id === id
    )
    return ld ? ld.areas?.filter((el) => el.active) : []
  },
  getSectorsToAreaId: (state) => (id) => {
    const area = getters.getAllAreas(state).find((area) => area.id === id)
    if (area) {
      return area.sectors?.filter((el) => el.active)
    } else {
      return []
    }
  },
  getAssmAvalServiceToObjectId: (state) => (id) => {
    return state.assmAvalServices.find((ld) =>
      ld.areas.find((area) => area.objects.find((object) => object.id === id))
    )
  },
  getAreaWithId: (state) => (id) => {
    return getters.getAreaToId(state)(id)
  },
  getSectorWithId: () => (area, sectorId) => {
    if (area && area.sectors && area.sectors.length > 0) {
      return area.sectors.find((sector) => sector.id === sectorId)
    }
    return undefined
  },
  getAreaToId: (state) => (id) => {
    return getters.getAllAreas(state).find((area) => area.id === id)
  },
  getAreaLocationToAreaId: (state) => (id) => {
    const area = getters.getAreaToId(state)(id)
    return getPointFromLocation(area)
  },
  getSectorLocationToSectorId: (state) => (areaId, sectorId) => {
    const area = getters.getAreaToId(state)(areaId)
    const sector = getters.getSectorWithId(state)(area, sectorId)
    return getPointFromLocation(sector)
  },
  getObjectLocationToObjectId: (state) => (objectId) => {
    const targetObj = getters
      .getAllObjectsToProtect(state)
      .find((object) => object.id === objectId)
    return getPointFromLocation(targetObj)
  },
  getAllAvalServiceIds: (state) => () => {
    return state.assmAvalServices.map((fullAvalSaftyObj) => {
      return fullAvalSaftyObj.id
    })
  },
  getAllAvalanchePaths(state) {
    return state.assmAvalServices
      .reduce((acc, curr) => acc.concat(curr.areas), [])
      .reduce((acc, curr) => {
        return curr.avalanchePaths ? acc.concat(curr.avalanchePaths) : acc
      }, [])
  },
  getAssmAvalServiceToAvalanchePathId: (state) => (id) => {
    return state.assmAvalServices.find((ld) =>
      ld.areas.find((area) =>
        area.avalanchePaths.find((avalPath) => avalPath.id === id)
      )
    )
  },
  getAvalanchePathLocationToId: (state) => (avalanchePathId) => {
    const avalPathResult = getters
      .getAllAvalanchePaths(state)
      .find((avalPath) => avalPath.id === avalanchePathId)
    return getPointFromLocation(avalPathResult)
  },
  getAvalanchePath: (state) => (id) => {
    return getters.getAllAvalanchePaths(state).find((el) => el.id === id)
  },
  getAssmAvalServiceToAreaId: (state) => (id) => {
    return state.assmAvalServices.find((ld) =>
      ld.areas.find((area) => area.id === id)
    )
  },
}

// actions
const actions = {
  /** */
  loadUserGroups({ commit, rootGetters }) {
    commit('SET_USERGROUPS', [])
    return observerService
      .getUserGroupsForUser(rootGetters.user.id)
      .then((values) => {
        commit('SET_USERGROUPS', values.data.data)
        commit('SET_EXCEPTION', null, { root: true })
        console.log('observer user groups loaded...')
      })
      .catch(function (error) {
        console.error('observerService: ' + error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', 'observerService: ' + error.message, {
            root: true,
          })
        }
        throw error
      })
  },

  /**Load the assessment areas for current user and put them into store */
  loadAssessAvalSafetyServices({ commit }) {
    commit('SET_ASSMAVALSERVICES', [])
    commit('SET_ALL_AVALSERVICES', [])

    /* NOTE: preferred final way: use this code to go to avalanche safety service */
    return assessmentService
      .loadAreas()
      .then(function (response) {
        commit('SET_EXCEPTION', null, { root: true })
        commit('SET_ASSMAVALSERVICES', response.data)
        commit('SET_ALL_AVALSERVICES', response.data)
      })
      .catch(function (error) {
        console.error('assessmentService: ' + error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', 'assessmentService: ' + error.message, {
            root: true,
          })
        }
      })
  },

  setObserverGroupOrStudyplotOrAvalServiceId({ commit }, tabElement) {
    commit('SET_OBSERVERGROUP', undefined)
    commit('SET_STUDYPLOT_ID_TO_OBSERVERGROUP', undefined)
    commit('SET_ASSMAVALSERVICE', undefined)
    if (hasGroupId(tabElement)) {
      // ISSUE SLPPWB-556 Seiteneffekt: Gruppentab wird bei den Rückmeldungen nach dem Erstellen/Update nicht mehr ausgewählt
      // Es muss die GroupID abgelegt werden und nicht die ganze Gruppe. Seiteneffekt?
      // commit('SET_OBSERVERGROUP', this.state.profile.userGroups.find(group => group.id === tabElement.groupId))
      commit('SET_OBSERVERGROUP', tabElement.groupId)
    }
    if (hasStudyplotId(tabElement)) {
      commit('SET_STUDYPLOT_ID_TO_OBSERVERGROUP', tabElement.studyplotId)
    }
    if (hasLdId(tabElement)) {
      commit('SET_ASSMAVALSERVICE', tabElement.ldId)
    }
  },
}

// mutations
const mutations = {
  SET_USERGROUPS(state, userGroups) {
    state.userGroups = userGroups
    state.studyplots = []
    userGroups.forEach((group) => {
      state.studyplots = state.studyplots.concat(group.studyplots)
    })
  },

  SET_ASSMAVALSERVICES(state, assmAvalServices) {
    state.assmAvalServices = createAvalancheServices(
      state.avalancheServices,
      assmAvalServices
    )
    const areaArray = state.assmAvalServices.reduce(
      (arr, curr) => arr.concat(curr.areas),
      []
    )
    state.areas = areaArray.reduce((map, obj) => ((map[obj.id] = obj), map), {})
    state.sectors = areaArray
      .reduce((arr, curr) => arr.concat(curr.sectors), [])
      .reduce((map, obj) => ((map[obj.id] = obj), map), {})
    state.objects = areaArray
      .reduce((arr, curr) => arr.concat(curr.objects), [])
      .reduce((map, obj) => ((map[obj.id] = obj), map), {})
    state.avalanchePaths = areaArray
      .reduce((arr, curr) => arr.concat(curr.avalanchePaths), [])
      .reduce((map, obj) => ((map[obj.id] = obj), map), {})
  },

  /**Set current selected LD */
  SET_ASSMAVALSERVICE(state, ldId) {
    state.avalancheServiceId = ldId
  },

  SET_OBSERVERGROUP(state, observerGroup) {
    state.observerGroup = observerGroup
  },

  SET_STUDYPLOT_ID_TO_OBSERVERGROUP(state, studyplotIdToObserverGroup) {
    state.studyplotIdToObserverGroup = studyplotIdToObserverGroup
  },

  SET_AVALANCHE_SERVICE_MEMBERSHIP(state) {
    state.avalancheServices =
      new Jwt(KeycloakAuthentication.getKeycloakInstance())?.getArtifacts() ||
      []
  },

  SET_ALL_AVALSERVICES(state, allAvalServices) {
    state.allActiveAvalServices = createAllAvalancheServices(
      state,
      allAvalServices
    )
    const areaArray = state.allActiveAvalServices.reduce(
      (arr, curr) => arr.concat(curr.areas),
      []
    )
    state.allActiveAreas = areaArray.reduce(
      (map, obj) => ((map[obj.id] = obj), map),
      {}
    )
    state.allActiveObjects = areaArray
      .reduce((arr, curr) => arr.concat(curr.objects), [])
      .reduce((map, obj) => ((map[obj.id] = obj), map), {})
  },
}

// Helper-Functions
function createAvalancheServices(avalancheServices, areas) {
  return avalancheServices?.map((artifact) =>
    Mapper.mapAvalancheSafetyService(
      artifact.id,
      artifact.name,
      areas.filter((el) => artifact.id === el.avalServiceId)
    )
  )
}
function createAllAvalancheServices(state, areas) {
  const allActiveAvalServices = new Map()
  areas?.forEach((area) => {
    if (!allActiveAvalServices.has(area.avalServiceId)) {
      allActiveAvalServices.set(
        area.avalServiceId,
        Mapper.mapAvalancheSafetyService(
          area.avalServiceId,
          getAvalServiceNameIfExist(state, area.avalServiceId),
          areas.filter((el) => area.avalServiceId === el.avalServiceId)
        ).filter()
      )
    }
  })
  return Array.from(allActiveAvalServices.values())
}
function getAvalServiceNameIfExist(state, id) {
  const dummyName = 'AvalService with id: ' + id
  const avalService = getters.getAssmAvalServiceToId(state)(id)

  return avalService ? avalService.name : dummyName
}
function hasGroupId(tabElement) {
  return tabElement && tabElement.groupId && tabElement.groupId !== 'undefined'
}
function hasStudyplotId(tabElement) {
  return (
    tabElement &&
    tabElement.studyplotId &&
    tabElement.studyplotId !== 'undefined'
  )
}
function hasLdId(tabElement) {
  return tabElement && tabElement.ldId && tabElement.ldId !== 'undefined'
}

function getPointFromLocation(objWithLocation) {
  if (objWithLocation && objWithLocation.location) {
    const location = objWithLocation.location
    if (location.type === 'Point') {
      return location
    }
    if (location.type === 'Polygon') {
      return { type: 'Point', coordinates: location.coordinates[0][0] }
    }
    if (location.type === 'LineString') {
      return { type: 'Point', coordinates: location.coordinates[0] }
    }
  }
  return null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

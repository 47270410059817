'use strict'

import { SVG } from '../SVG'
import { i18n } from '@/plugins/i18n'
import { enums } from '../../I18N'
import Icon from 'ol/style/Icon'
import { AbstractCompassStyle, ASPECTS } from './AbstractCompassStyle'
import { GEFAHREN_KLASSE, Z_INDEX } from '../AbstractStyle'
import { WHUMPF_CRACKS } from '@/scripts/const.js'
import {
  getCachedSymbolIfAvailable,
  getExclamationSymbol,
  getFrequencyIdx,
  getLegendRosettenSymbol,
  getLegendSymbol,
  getPieColor,
  getStartIndex,
  getWhumpfCrackStyle,
  showRosette,
} from '@/components/styles/dangersignStyling/WhumpfCrackStyling'
import { WHUMPF_CRACKS_STYLE_ELEMENTS } from '@/components/styles/dangersignStyling/whumpfCrackMetadata'

export class WhumpfCrackStyle extends AbstractCompassStyle {
  // eslint-disable-next-line complexity
  styleFunction(feature, resolution, onlyExclamation) {
    if (!onlyExclamation) {
      // eslint-disable-next-line no-param-reassign
      onlyExclamation = false
    }

    const whumpfCracksFeature = feature.get(WHUMPF_CRACKS)
    if (
      whumpfCracksFeature === null ||
      whumpfCracksFeature === undefined ||
      whumpfCracksFeature.frequency === null ||
      whumpfCracksFeature.frequency === undefined
    ) {
      return
    }
    whumpfCracksFeature[Z_INDEX] = getFrequencyIdx(whumpfCracksFeature)

    const style = getCachedSymbolIfAvailable(
      feature,
      resolution,
      onlyExclamation,
      super.getAltitudeLabel(whumpfCracksFeature, resolution)
    )

    super.setZindexIfExists(whumpfCracksFeature, style)
    return [style]
  }

  legend() {
    return {
      title: 'dangersign.label.whumpfCracksShort',
      isRosette: true,
      items: [
        {
          label: i18n.global.t('dangersign.frequency.NONE'),
          symbol: getLegendSymbol({ frequency: enums.dangersign.frequency[0] }),
          isRosette: false,
        },
        {
          label: i18n.global.t('dangersign.frequency.RARELY'),
          symbol: getLegendSymbol({ frequency: enums.dangersign.frequency[1] }),
          isRosette: false,
        },
        {
          label: i18n.global.t('dangersign.frequency.OFTEN'),
          symbol: getLegendSymbol({ frequency: enums.dangersign.frequency[2] }),
          isRosette: false,
        },
        {
          label: i18n.global.t('snow.label.rosette'),
          symbol: getLegendRosettenSymbol(),
          isRosette: true,
        },
      ],
    }
  }
  getLegendExclamationWhite() {
    return getLegendSymbol(undefined, true)
  }
  // eslint-disable-next-line complexity
  mouseOverFunction(feature) {
    const whumpfCracksFeature = feature.get(WHUMPF_CRACKS)
    if (whumpfCracksFeature) {
      let frequency = ''
      if (
        whumpfCracksFeature.frequency &&
        whumpfCracksFeature.frequency.length > 0
      ) {
        frequency = i18n.global.t(
          'dangersign.frequency.' + whumpfCracksFeature.frequency
        )
      }
      let htmlString = super.getMouseOverTitel(feature) + frequency
      if (
        enums.dangersign.frequency[0].localeCompare(
          whumpfCracksFeature.frequency
        ) !== 0
      ) {
        if (whumpfCracksFeature.flat) {
          htmlString = htmlString + ', ' + i18n.global.t('dangersign.flat.true')
        }
        if (whumpfCracksFeature.extension) {
          htmlString =
            htmlString +
            ', ' +
            i18n.global.t(
              'dangersign.extension.' + whumpfCracksFeature.extension
            )
        }
      }
      return htmlString
    } else {
      return null
    }
  }
  getWhumpfCracksSymbol(feature, resolution, onlyExclamation) {
    const getRosettenSymb = WhumpfCrackStyle.prototype.getRosettenSymbol

    const whumpfCracksFeature = feature.get(WHUMPF_CRACKS)
    if (showRosette(whumpfCracksFeature, resolution) && !onlyExclamation) {
      return getRosettenSymb(whumpfCracksFeature)
    } else {
      return getExclamationSymbol(whumpfCracksFeature)
    }
  }
  getRosettenSymbol(whumpfCracksFeature) {
    const getSVGCall = WhumpfCrackStyle.prototype.getSVG

    const whumpfCrackStyle = getWhumpfCrackStyle(whumpfCracksFeature).style
    const svg = new SVG('-32 -32 64 64')
    return new Icon({
      src: svg.createImage(
        getSVGCall(
          whumpfCracksFeature,
          WHUMPF_CRACKS_STYLE_ELEMENTS[0].style.fill,
          whumpfCrackStyle.fill
        )
      ).src,
      scale: 1,
    })
  }
  getSVG(feature, fill0, fill1) {
    const startIndex = getStartIndex(feature)
    const fill = {}
    ASPECTS.forEach((aspect) => {
      fill[aspect] = ['white', 'white', 'white']
      ;[0, 1, 2].forEach((i) => {
        fill[aspect][i] = getPieColor(
          feature,
          aspect,
          startIndex + i,
          fill0,
          fill1
        )
      })
    })
    return super.getColoredSVG(fill)
  }
  getWuhmpfCracksZindexForGefahrIdx(featureCollection, gefahrIndex) {
    const featuresWithGefahrIndex = []
    if (featureCollection && gefahrIndex >= 0) {
      featureCollection.forEach((whumpfCrackFeature) => {
        const whumpfCrackData = whumpfCrackFeature.properties[WHUMPF_CRACKS]
        if (whumpfCrackData) {
          if (
            enums.dangersign.frequency[gefahrIndex].localeCompare(
              whumpfCrackData.frequency
            ) === 0
          ) {
            whumpfCrackData[Z_INDEX] = gefahrIndex
            whumpfCrackData[GEFAHREN_KLASSE] = gefahrIndex
            featuresWithGefahrIndex.push(whumpfCrackFeature)
          }
        }
      })
    }
    return featuresWithGefahrIndex
  }
}

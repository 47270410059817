<template>
  <div>
    <!-- Tabelle Warnleuchte zu Gebieten -->
    <b-table
      small
      hover
      bordered
      responsive
      sticky-header="300px"
      :items="area"
      :fields="warningLightFields"
      show-empty
      :empty-text="$t('measure.label.emptyState')"
      class="table-font t-area"
      tbody-tr-class="area-object-row"
      thead-class="hidden-header"
      :key="area.id + 't-wl'"
      @row-clicked="showMeasureDetails"
    >
      <template v-slot:head()="data">
        <span class="font-weight-normal">{{ data.label }}</span>
      </template>
      <template v-slot:cell(actions)="row">
        <div style="overflow: hidden; white-space: nowrap">
          <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
          <b-button
            size="sm"
            @click.stop="addMeasure(row.item, row.index, $event.target)"
            :title="$t('ui.edit')"
            class="mr-2 wrap-ic edit-button"
            style="display: inline-block"
          >
            <font-awesome-icon icon="fa-solid fa-edit"></font-awesome-icon>
          </b-button>
          <b-button
            size="sm"
            @click.stop="deleteMeasure(row.item, row.index, $event.target)"
            :title="$t('common.clear')"
            class="mr-2 wrap-ic delete-button"
            style="display: inline-block"
          >
            <font-awesome-icon
              icon="fa-regular fa-trash-alt"
            ></font-awesome-icon>
          </b-button>
        </div>
      </template>
      <template v-slot:cell(name)>
        <div>
          {{ $t('measure.label.warningLight') }}
        </div>
      </template>
      <template v-slot:[`cell(measure.validFrom)`]="data">
        {{ data.item.measure ? formatTimestamp(data.value, '') : '' }}
      </template>
      <template v-slot:[`cell(measure.validTo)`]="data">
        {{
          data.item.measure
            ? formatTimestamp(
                data.value,
                $t('measure.label.untilFurtherNotice')
              )
            : ''
        }}
      </template>
      <template v-slot:[`cell(measure.comment)`]="data">
        <div class="text-truncate text-ellipse">
          {{ data.value }}
        </div>
      </template>
    </b-table>
    <DetailModalAssessment
      det-id="WarnLightDetailModalAssessment"
      :assessment="assessment"
      :aggregate="false"
    />
  </div>
</template>

<script>
import { MeasureTableMixin } from './MeasureTableMixin.js'
import DetailModalAssessment from '@/components/assessment/detail/DetailModalAssessment'

export default {
  name: 'WarningLightTable',
  mixins: [MeasureTableMixin],
  components: { DetailModalAssessment },
  props: {
    area: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      warningLightFields: [
        {
          key: 'name',
          label: this.$t('measure.label.object'),
          class: 'table-object-cell',
        },
        {
          key: 'actualMeasure.measure',
          label: this.$t('measure.label.state'),
          class: 'table-state-cell',
          tdClass: this.findClass,
          formatter: (value, key, item) =>
            this.formatMeasure(item.class, value),
        },
        { key: 'actions', label: '', class: 'table-action-cell' },
        {
          key: 'measure.type',
          label: this.$t('measure.label.decision'),
          class: 'table-decision-cell',
          tdClass: this.findClass,
          formatter: (value, key, item) =>
            this.formatMeasure(item.class, value),
        },
        {
          key: 'measure.validFrom',
          label: this.$t('measure.label.validFrom'),
          class: 'table-from-cell',
        },
        {
          key: 'measure.validTo',
          label: this.$t('measure.label.validTo'),
          class: 'table-to-cell',
        },
        {
          key: 'measure.comment',
          label: this.$t('measure.label.comment'),
          class: 'table-comment-cell',
        },
      ],
    }
  },
  methods: {
    addMeasure(area) {
      this.$router.push({
        name: 'MeasureInput',
        params: { areaId: area.id },
      })
    },
    deleteMeasure(area) {
      this.$router.push({
        name: 'MeasureInput',
        params: { areaId: area.id, preset: 'WARNING_LIGHT_OFF' },
      })
    },
    showMeasureDetails(item) {
      if (item.measure) {
        this.assessment = this.$store.getters[
          'measure/getMeasureForDetailModal'
        ](item.measure.measureObs)
        this.$bvModal.show('WarnLightDetailModalAssessment')
      }
    },
  },
}
</script>

<style>
.hidden-header {
  display: none;
}
</style>

import Style from 'ol/style/Style'
import Circle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import RegularShape from 'ol/style/RegularShape'

export const SQUARE_SVG =
  '<rect width="25" height="15" style="stroke-width:1 stroke:black"/>'

export const NO_VALUE_CLASS = {
  style: { fill: '#ffffff', stroke: '#122e42' },
  label: 'kein Wert',
}

const noValueClass = { fill: '#ffffff', stroke: '#9c9c9c', rand: '#000000' }
export const NO_VALUE_STYLE = [
  new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({ color: noValueClass.fill }),
      stroke: new Stroke({ color: noValueClass.rand, width: 2 }),
    }),
  }),
  new Style({
    image: new RegularShape({
      fill: new Fill({ color: noValueClass.fill }),
      stroke: new Stroke({ color: noValueClass.stroke, width: 2 }),
      points: 4,
      radius: 3,
      radius2: 0,
      angle: Math.PI / 4,
    }),
  }),
]

export const CLASS_DEFINITION = {
  hn24h: [
    { t: 999, style: { fill: '#4d000e', stroke: '#260007' }, label: '>100' },
    {
      t: 100,
      style: { fill: '#610333', stroke: '#300119' },
      label: '80 - 100',
    },
    { t: 80, style: { fill: '#05508c', stroke: '#022846' }, label: '60 - 80' },
    { t: 60, style: { fill: '#0570b0', stroke: '#023858' }, label: '40 - 60' },
    { t: 40, style: { fill: '#3296b4', stroke: '#194B5A' }, label: '30 - 40' },
    { t: 30, style: { fill: '#53bd9f', stroke: '#295E4F' }, label: '20 - 30' },
    { t: 20, style: { fill: '#99f0b2', stroke: '#4C7859' }, label: '10 - 20' },
    { t: 10, style: { fill: '#cdffcd', stroke: '#667766' }, label: '5 - 10' },
    { t: 5, style: { fill: '#ffffff', stroke: '#777777' }, label: '0 - 5' },
  ],
  snowline: [
    { t: 9999, style: { fill: '#67001f', stroke: '#34000f' }, label: '>3200' },
    {
      t: 3200,
      style: { fill: '#980043', stroke: '#490022' },
      label: '2900 - 3200',
    },
    {
      t: 2900,
      style: { fill: '#ce1256', stroke: '#670628' },
      label: '2600 - 2900',
    },
    {
      t: 2600,
      style: { fill: '#e7298a', stroke: '#731545' },
      label: '2300 - 2600',
    },
    {
      t: 2300,
      style: { fill: '#df65b0', stroke: '#6f3358' },
      label: '2000 - 2300',
    },
    {
      t: 2000,
      style: { fill: '#c994c7', stroke: '#644763' },
      label: '1700 - 2000',
    },
    {
      t: 1700,
      style: { fill: '#d4b9da', stroke: '#6a5c6d' },
      label: '1400 - 1700',
    },
    {
      t: 1400,
      style: { fill: '#e7e1ef', stroke: '#737077' },
      label: '1100 - 1400',
    },
    { t: 1100, style: { fill: '#f7f4f9', stroke: '#7b7a7c' }, label: '<1100' },
  ],
  snowRainAltitude: [
    { t: 9999, style: { fill: '#49006a', stroke: '#033354' }, label: '>2600' },
    {
      t: 2600,
      style: { fill: '#7a0177', stroke: '#215064' },
      label: '2400 - 2600',
    },
    {
      t: 2400,
      style: { fill: '#ae017e', stroke: '#3e6763' },
      label: '2200 - 2400',
    },
    {
      t: 2200,
      style: { fill: '#dd3497', stroke: '#3e6763' },
      label: '2000 - 2200',
    },
    {
      t: 2000,
      style: { fill: '#f768a1', stroke: '#3e6763' },
      label: '1800 - 2000',
    },
    {
      t: 1800,
      style: { fill: '#fa9fb5', stroke: '#3e6763' },
      label: '1600 - 1800',
    },
    {
      t: 1600,
      style: { fill: '#fcc5c0', stroke: '#3e6763' },
      label: '1400 - 1600',
    },
    {
      t: 1400,
      style: { fill: '#fde0dd', stroke: '#5c715d' },
      label: '1200 - 1400',
    },
    { t: 1200, style: { fill: '#fff7f3', stroke: '#787c74' }, label: '<1200' },
  ],
}

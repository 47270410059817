import { createStore } from 'vuex'
import aggregation from './modules/aggregation'
import observation from './modules/observation'
import avalanche from './modules/avalanche'
import snow from './modules/snow'
import dangersign from './modules/dangersign'
import danger from './modules/danger'
import document from './modules/document'
import map from './modules/map'
import user from './modules/user'
import profile from './modules/profile'
import calendar from './modules/calendar'
import studyplot from './modules/studyplot'
import snowprofile from './modules/snowprofile'
import ticker from './modules/ticker'
import filter from './modules/filter'
import details from './modules/details'
import bulletin from './modules/bulletin'
import assessmentObs from './modules/assessmentObs'
import measure from './modules/measure'
import triggering from './modules/triggering.js'
import commonLayers from './modules/commonLayers'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    exception: null,
    validationException: null,
    mobilePlatform: null,
  },
  getters: {
    user: (state) => {
      return state.user.user
    },
    profile: (state) => {
      return state.profile
    },
    isSmallMobile() {
      return screen.width < 750
    },
  },
  actions: {},
  mutations: {
    SET_EXCEPTION(state, exception) {
      state.exception = exception
    },
    SET_VALIDATION_EXCEPTION(state, validationException) {
      state.validationException = validationException
    },
    SET_MOBILEPLATFORM(state, isMobile) {
      state.mobilePlatform = isMobile
    },
  },
  modules: {
    aggregation,
    observation,
    avalanche,
    map,
    user,
    profile,
    snow,
    dangersign,
    danger,
    document,
    calendar,
    studyplot,
    snowprofile,
    ticker,
    filter,
    details,
    bulletin,
    assessmentObs,
    measure,
    triggering,
    commonLayers,
  },
  strict: debug,
})

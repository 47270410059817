import Icon from 'ol/style/Icon'
import { DangerLevel, DangerProblem } from '@/model/dangerModels'
import { Aspect } from '@/model/commonModels'
import {
  MAP_DANGER_LEVEL_COLORS,
  MAP_DANGER_PROBLEM_COLORS,
  MAP_RADIUS_MAX,
  MAP_RADIUS_MIN,
  STYLE_ELEMENTS,
} from '@/components/styles/dangerStyling/dangerStylingConsts'
import Exposition from '@/components/styles/Exposition'
import { SVG } from '@/components/styles/SVG'
import Style from 'ol/style/Style'
import Circle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'

const iconStyleCache = new Map<string, Style>()
const smallCircleStyleCache = new Map<string, Style>()

const getMapArcLineSymbol = (
  problem: DangerProblem,
  aspectFrom: Aspect,
  aspectTo?: Aspect
): HTMLImageElement => {
  const symbol = document.createElement('g')
  const problemToStyle =
    problem != null ? STYLE_ELEMENTS[problem] : STYLE_ELEMENTS.UNKNOWNP
  symbol.setAttribute('style', problemToStyle)
  const exposition = new Exposition(aspectFrom, aspectTo)
  const pie = exposition.getPie(MAP_RADIUS_MAX, true)

  if (pie) {
    symbol.appendChild(pie)
  }

  const svg = new SVG('-32 -32 64 64')
  return svg.createImage(symbol.outerHTML)
}

const getMapArcFullSymbol = (
  level: DangerLevel,
  aspectFrom: Aspect,
  aspectTo?: Aspect
): HTMLImageElement => {
  const symbol = document.createElement('g')
  const levelToStyle =
    level != null ? STYLE_ELEMENTS[level] : STYLE_ELEMENTS.UNKNOWN
  symbol.setAttribute('style', levelToStyle)
  const exposition = new Exposition(aspectFrom, aspectTo)
  const pie = exposition.getPie(MAP_RADIUS_MAX, false)

  if (pie) {
    symbol.appendChild(pie)
  }

  const svg = new SVG('-32 -32 64 64')
  return svg.createImage(symbol.outerHTML)
}

const getIconCacheKey = (
  dangerLevelOrProblem: DangerLevel | DangerProblem,
  aspectFrom: Aspect,
  aspectTo: Aspect
): string => {
  const aspectKey = Exposition.isFullExposition(aspectFrom, aspectTo)
    ? 'full'
    : `${aspectFrom}${aspectTo}`
  return `${dangerLevelOrProblem}${aspectKey}`
}

export const getArcFullSymbolCached = (
  dangerLevel: DangerLevel,
  aspectFrom: Aspect,
  aspectTo: Aspect
): Style => {
  const cacheKey = getIconCacheKey(dangerLevel, aspectFrom, aspectTo)
  if (iconStyleCache.has(cacheKey)) {
    return iconStyleCache.get(cacheKey) as Style
  }
  const newIconStyle = new Style({
    image: new Icon({
      src: getMapArcFullSymbol(dangerLevel, aspectFrom, aspectTo).src,
    }),
  })
  iconStyleCache.set(cacheKey, newIconStyle)
  console.debug('New iconStyleCache-size:', iconStyleCache.size)
  return newIconStyle
}

export const getArcLineSymbolCached = (
  dangerProblem: DangerProblem,
  aspectFrom: Aspect,
  aspectTo: Aspect
): Style => {
  const cacheKey = getIconCacheKey(dangerProblem, aspectFrom, aspectTo)
  if (iconStyleCache.has(cacheKey)) {
    return iconStyleCache.get(cacheKey) as Style
  }
  const newIconStyle = new Style({
    image: new Icon({
      src: getMapArcLineSymbol(dangerProblem, aspectFrom, aspectTo).src,
    }),
  })
  iconStyleCache.set(cacheKey, newIconStyle)
  console.log('New iconStyleCache-size:', iconStyleCache.size)
  return newIconStyle
}

export const getSmallCircleSymbolCached = (
  dangerLevel: DangerLevel,
  dangerProblem: DangerProblem
): Style => {
  const cacheKey = `${dangerLevel}${dangerProblem}`
  if (smallCircleStyleCache.has(cacheKey)) {
    return smallCircleStyleCache.get(cacheKey) as Style
  }
  const smallCircle = new Circle({
    radius: MAP_RADIUS_MIN,
    fill: new Fill({
      color: dangerLevel
        ? MAP_DANGER_LEVEL_COLORS[dangerLevel]
        : MAP_DANGER_LEVEL_COLORS.UNKNOWN,
    }),
    stroke: new Stroke({
      color: dangerProblem
        ? MAP_DANGER_PROBLEM_COLORS[dangerProblem]
        : MAP_DANGER_PROBLEM_COLORS.UNKNOWN,
      width: 2,
    }),
  })
  const newIconStyle = new Style({ image: smallCircle })
  smallCircleStyleCache.set(cacheKey, newIconStyle)
  console.debug('New smallCircleStyleCache-size:', smallCircleStyleCache.size)
  return newIconStyle
}

<template>
  <div
    v-if="visible"
    :class="`common-layers-popup${mobile ? '-mobile' : ''} ${context}-context`"
  >
    <b-form-group
      :label="$t('commonLayers.baseLayer.title')"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="base-layer-radio-buttons"
        v-model="baseLayer"
        :options="baseLayerOptions"
        :aria-describedby="ariaDescribedby"
        name="radio-options"
        style="pointer-events: all"
        stacked
        @change="handleBaseLayerChange"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      :label="$t('commonLayers.additionalLayer.title')"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox-group
        id="additional-layers-checkboxes"
        v-model="additionalLayers"
        :options="additionalLayerOptions"
        :aria-describedby="ariaDescribedby"
        style="pointer-events: all"
        stacked
        @change="handleAdditionalLayersChange"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { AdditionalLayer, BaseLayer } from '@/model/map/commonLayers'
import { defineComponent, PropType } from 'vue'
import emitter from '@/components/EventHub'

export default defineComponent({
  name: 'CommonLayersPopup',
  props: {
    context: {
      type: String as PropType<'main' | 'input'>,
      default: () => 'main',
    },
  },
  data() {
    return {
      baseLayer: BaseLayer.COLOR_MAP as BaseLayer,
      additionalLayers: [] as AdditionalLayer[],
      visible: false,
    }
  },
  watch: {
    commonLayersWatchProperties: {
      handler: function () {
        this.onCommonLayersChange()
      },
      immediate: true,
    },
  },
  computed: {
    commonLayersWatchProperties() {
      return `${this.$store.state.commonLayers.baseLayer}|${this.$store.state.commonLayers.baseLayer}`
    },
    mobile(): boolean {
      return this.$store.state.mobilePlatform
    },
    baseLayerOptions() {
      const options: { value: string; text: string }[] = []
      for (const layer of Object.values(BaseLayer)) {
        options.push({
          value: layer,
          text: this.$t(`commonLayers.${layer}`).toString(),
        })
      }
      return options
    },
    additionalLayerOptions() {
      const options: { value: string; text: string }[] = []
      for (const layer of Object.values(AdditionalLayer)) {
        options.push({
          value: layer,
          text: this.$t(`commonLayers.${layer}`).toString(),
        })
      }
      return options
    },
  },
  mounted() {
    emitter.on('showCommonLayers', this.showCommonLayersFunc)
  },
  beforeUnmount() {
    // Genau diesen Listener entfernen. Wenn das zweite Argument nicht gesetzt wird, werden sonst
    // alle solchen Listener entfernt. Dies führt zu Problemen, wenn diese Komponente mehrmals verwendet wird.
    emitter.off('showCommonLayers', this.showCommonLayersFunc)
  },
  methods: {
    showCommonLayersFunc(show: boolean) {
      this.visible = show
    },
    handleBaseLayerChange(baseLayer: BaseLayer) {
      this.$store.commit('commonLayers/SET_BASE_LAYER', baseLayer)
    },
    handleAdditionalLayersChange(additionalLayers: AdditionalLayer[]) {
      this.$store.commit('commonLayers/SET_ADDITIONAL_LAYERS', additionalLayers)
    },
    onCommonLayersChange() {
      this.baseLayer = this.$store.state.commonLayers.baseLayer
      this.additionalLayers = [
        ...this.$store.state.commonLayers.additionalLayers,
      ]
    },
  },
})
</script>

<style scoped>
.common-layers-popup,
.common-layers-popup-mobile {
  border: 1px solid #929292;
  border-radius: 4px;
  opacity: 1;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  display: inline-block;
  position: absolute;
  padding: 10px 10px 0 10px;
  text-align: left;
  font-size: 0.875rem;
}

.common-layers-popup.main-context {
  top: 175px;
  right: 11px;
}

.common-layers-popup-mobile.main-context {
  top: 75px;
  right: 8px;
}

.common-layers-popup.input-context {
  top: 165px;
  left: 8px;
}

.common-layers-popup-mobile.input-context {
  top: 71px;
  left: 5px;
}

.common-layers-popup :deep(.form-group) {
  margin-bottom: 10px;
}

.common-layers-popup-mobile :deep(.form-group) {
  margin-bottom: 10px;
}

.common-layers-popup :deep(.col-form-label) {
  font-weight: bold;
}

.common-layers-popup-mobile :deep(.col-form-label) {
  font-weight: bold;
}
</style>

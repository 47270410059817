<template>
  <div>
    <!-- DetailHeader -->
    <b-row v-if="aval || (obsIds && obsIds.length > 0)">
      <b-col cols="12" md="12" class="detailHeader">
        <DetailHeader :headerInfos="headerInfos" />
      </b-col>
    </b-row>
    <!-- Card mit Detail -->
    <div v-for="(item, index) in components" :key="index">
      <div
        v-if="(aval && obs && obs.length > 0) || (obsIds && obsIds.length > 0)"
      >
        <b-card :ref="compCard + index" no-body class="mb-1">
          <b-card-header
            header-tag="header"
            class="p-1 aggregation-card-header"
            role="tab"
            @click="resizeSnow(item, index)"
            v-b-toggle="detail + index"
          >
            <b-row class="header">
              <b-col cols="1">
                <span class="when-opened">
                  <font-awesome-icon icon="fa-solid fa-chevron-up" />
                </span>
                <span class="when-closed">
                  <font-awesome-icon icon="fa-solid fa-chevron-down" />
                </span>
              </b-col>
              <b-col class="label text-left">
                {{ getDetailTitle(item.typ, null, obs[index]) }}
              </b-col>
              <b-col v-if="isEditable(obs[index], item)" class="text-right">
                <b-button
                  size="sm"
                  @click="editObservation(obs[index], item)"
                  variant="outline-warning"
                >
                  <font-awesome-icon icon="fa-solid fa-edit" />
                  {{ $t('ui.edit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-collapse :id="detail + index" :visible="true" role="tabpanel">
            <b-card-body>
              <b-container class="detail-container">
                <component
                  :ref="'component' + index"
                  class="p-0"
                  :is="item.comp"
                  :obs="obs[index]"
                />
              </b-container>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div v-else>
        <component v-if="aval" class="p-0" :is="item.comp" :aval="aval" />
      </div>
    </div>
  </div>
</template>

<script>
import DetailHeader from './DetailHeader.vue'
import moment from 'moment'
import observationService from '@/services/observationService.js'
import util from '../../scripts/util.js'
import { DETAIL_COMPONENTS } from '@/scripts/const'
import { i18n } from '@/plugins/i18n'
import { detailModalReportMixin } from '@/components/reporting/DetailModalReportMixin.js'
import { defineAsyncComponent, markRaw } from 'vue'

export default {
  name: 'ObservationDetail',
  components: {
    DetailHeader,
  },
  mixins: [detailModalReportMixin],
  props: ['obsIds', 'detId', 'showEdit', 'aggregate', 'aval'],
  data() {
    return {
      visible: false,
      components: [{ comp: 'default', typ: '' }],
      obs: [],
      user: undefined,
      isAdmin: this.$store.state.user.user.isAdmin,
      headerInfos: {
        // user: undefined,
        name: 'Beobachter unbekannt',
        phone: undefined,
        email: undefined,
        education: undefined,
        place: undefined,
        coord: undefined,
      },
      detail: 'detail',
      compCard: 'compCard',
      theModal: 'theModal',
      creationDate: undefined,
    }
  },
  created() {},
  mounted() {
    moment.locale(this.$i18n.locale)
    this.showOrAggregateObservation()
  },
  watch: {
    obsIds: {
      handler() {
        this.showOrAggregateObservation()
      },
      deep: true,
    },
    aval: function (val) {
      if (val) {
        this.showOrAggregateObservation()
      }
    },
    creationDate: function () {
      this.$emit('setHeader', {
        date: this.creationDate,
        name: this.headerInfos.name,
      })
    },
    'headerInfos.name': function () {
      this.$emit('setHeader', {
        date: this.creationDate,
        name: this.headerInfos.name,
      })
    },
  },
  updated: function () {
    const self = this
    this.$nextTick(function () {
      self.scrollToBeob()
    })
  },
  methods: {
    pdfReport() {
      try {
        this.setPdfReportingInProgress(true)
        const vm = this
        const report = this.getDetailReportBuilder(false)
        const reportObsPromise = this.getReportObs(this.obs, this.aval)
        reportObsPromise
          .then((result) => {
            if (!Array.isArray(result)) {
              return this.preLoadImages([result])
            }
            return this.preLoadImages(result)
          })
          .then((reportObs) => {
            report.buildReportDetails(
              reportObs,
              vm.getReportConfigFunctions(),
              false
            )
            vm.startPdfReporting(report.getModelAsJson())
          })
          .catch((err) => {
            this.setPdfReportingInProgress(false)
            this.showPrintError(err)
          })
      } catch (error) {
        this.setPdfReportingInProgress(false)
        this.showPrintError(error)
      }
    },
    getReportConfigFunctions() {
      return {
        detailTitel: this.getDetailTitle,
        headerLabel: this.mdetailDate,
        headerText: this.getHeaderName,
        areaName: this.getAreaName,
        sectorName: this.getSectorName,
        objectName: this.getObjectName,
        avalanchePathName: this.getAvalanchePathName,
      }
    },
    resizeSnow(item, index) {
      if (item.type === 'snow') {
        const snowComponent = this.$refs['component' + index]
        this.$nextTick(function () {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let timeoutRef = setTimeout(function () {
            snowComponent[0].setChartSize()
            timeoutRef = null
          }, 1)
        })
      }
    },
    /*eslint-disable complexity*/

    showOrAggregateObservation() {
      this.components = [{ comp: 'default', typ: '' }]
      this.obs = []
      const self = this
      if (this.obsIds && this.obsIds.length > 0) {
        if (this.obsIds.length > 1 || !this.aggregate) {
          this.loadMissingObservations()
        } else {
          observationService
            .getObservationById(this.obsIds[0])
            .then((result) => {
              const creationDate = moment(result.data.data.cTimestamp)
              const uid = result.data.data.uid
              const location = result.data.data.location
              const promises = []
              promises.push(
                this.$store.dispatch('aggregation/loadAggregatedObservations', {
                  uid,
                  creationDate,
                  location,
                })
              )
              promises.push(
                this.$store.dispatch('aggregation/loadAggregatedAvalanches', {
                  uid,
                  creationDate,
                  location,
                })
              )
              Promise.all(promises).then(() => {
                const aggregatedObs =
                  self.$store.state.aggregation.aggregatedObservations
                const avals = self.$store.state.aggregation.aggregatedAvalanches
                let index = 0
                aggregatedObs.forEach(function (observation) {
                  // Alle verschiedenen Observations anzeigen (Entsprechendes Detail wird dynamisch importiert)
                  self.obs.push(observation)
                  self.ermittleDetailTyp(observation, index)
                  index++
                })
                // Am Schluss noch das Lawinendetail anhängen
                if (avals.length > 0) {
                  self.obs.push(avals)
                  self.importComponent(DETAIL_COMPONENTS[4])
                }
                self.loadUserData()
                self.mdetailDate()
              })
            })
            .catch(function (error) {
              console.error(error)
            })
        }
      } else if (this.aval) {
        this.components = []
        this.importComponent(DETAIL_COMPONENTS[5])
        this.loadUserData()
        this.mdetailDate()
      }
    },
    /*eslint-enable complexity*/

    resetUserData() {
      this.headerInfos.name = 'Beobachter unbekannt'
      this.headerInfos.phone = undefined
      this.headerInfos.email = undefined
      this.headerInfos.education = undefined
      this.headerInfos.place = undefined
      this.headerInfos.coord = undefined
      this.user = undefined
    },
    getRelevantHeaderInfoForAvalOrObservation() {
      const relevantObsIndex = this.getRelevantObservationIndex()
      const headerData = { uid: undefined, coord: undefined, place: undefined }
      if (this.obs.length > 0) {
        headerData.uid = this.obs[relevantObsIndex].uid
        headerData.coord = this.obs[relevantObsIndex].location
        headerData.place = this.obs[relevantObsIndex].place
        headerData.creatorSystem = this.obs[relevantObsIndex].creatorSystem
      } else {
        // NOTE (csz): for single aval xGeo attributes, for several coord, for list aval avalancheGeo to test
        // NOTE (csz):
        //   userId delivered via aval service
        //   TODO: set coord from geo object: what is coord if this.aval.avalancheGeo not a Point but a Line or Polygon??
        //   no place attribute for avalanche
        headerData.uid = this.aval.userId
        headerData.coord = this.aval.coord
        headerData.place = undefined
        headerData.creatorSystem = this.aval.creatorSystem
      }
      return headerData
    },
    getRelevantObservationIndex() {
      return 0
    },
    /*eslint-disable complexity*/
    loadUserData(headerData) {
      // Find user details based on relevant observation
      this.resetUserData()
      const self = this
      if (
        this.obs.length <= 0 &&
        this.aval.locale <= 0 &&
        !headerData &&
        !headerData.uid
      ) {
        return
      }
      // eslint-disable-next-line no-param-reassign
      headerData =
        headerData === undefined
          ? this.getRelevantHeaderInfoForAvalOrObservation()
          : headerData
      if (headerData.uid) {
        this.user = this.$store.getters['user/getUserToId'](
          headerData.uid,
          headerData.creatorSystem
        )
      }
      if (!this.user && headerData.uid) {
        this.$store
          .dispatch('user/loadContactById', headerData.uid)
          .then(() => {
            self.user = self.$store.getters['user/getUserToIdOrDummyUser'](
              headerData.uid,
              headerData.creatorSystem
            )
            self.createHeaderInfos(headerData)
          })
          .catch(function (error) {
            console.error(error)
          })
      } else {
        this.createHeaderInfos(headerData)
      }
    },
    createHeaderInfos(headerData) {
      if (this.user) {
        this.headerInfos.name = this.user.firstName + ' ' + this.user.lastName
        if (this.isAdmin) {
          this.headerInfos.phone = this.user.mobile
            ? this.user.mobile
            : this.user.phone
          this.headerInfos.email = this.user.email
          this.headerInfos.education = this.user.education
            ? i18n.global.t('user.education.' + this.user.education)
            : null
        }
      }
      if (headerData && headerData.place) {
        this.headerInfos.place = headerData.place
      }
      if (
        typeof headerData.coord === 'string' ||
        headerData.coord instanceof String
      ) {
        this.headerInfos.coord = headerData.coord
      } else {
        this.headerInfos.coord = util.convertGeoJsonToText(headerData.coord)
      }
    },
    /*eslint-enable complexity*/
    loadMissingObservations() {
      let index = 0
      const self = this
      const missingObs = []
      const existingObs = []
      this.components = []
      // Missing Observation ID's ermitteln
      const getMissingObservations = new Promise(function (resolve) {
        self.obsIds.forEach(function (elementId) {
          const foundItem = self.$store.state.observation.observations.find(
            function (obsEle) {
              return obsEle.id === elementId
            }
          )
          if (!foundItem) {
            missingObs.push(elementId)
          } else {
            existingObs.push(foundItem)
          }
        })
        resolve()
      })
      getMissingObservations
        .then(() => {
          const promises = []
          missingObs.forEach(function (elementId) {
            promises.push(observationService.getObservationById(elementId))
          })
          // Fehlende Observation Holen
          Promise.all(promises).then((result) => {
            result.forEach(function (promisResult) {
              existingObs.push(promisResult.data.data)
            })
            self.obsIds.forEach(function (elementId) {
              const foundItem = existingObs.find(function (obsEle) {
                return obsEle.id === elementId
              })
              // Alle verschiedenen Observations anzeigen (Entsprechendes Detail wird dynamisch importiert)
              self.obs.push(foundItem)
              self.ermittleDetailTyp(foundItem, index)
              index++
            })
            self.loadUserData()
            self.mdetailDate()
          })
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    /*eslint-disable complexity*/
    ermittleDetailTyp(observation, index) {
      for (let i = 0; i < DETAIL_COMPONENTS.length; i++) {
        if (observation && observation.type === DETAIL_COMPONENTS[i]) {
          if (index === 0) {
            this.components = []
          }
          this.importComponent(DETAIL_COMPONENTS[i])
        }
      }
    },

    importComponent(compName) {
      let compImport
      let detailTyp
      let storeToSelect
      switch (compName) {
        case DETAIL_COMPONENTS[0]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../snow/Detail.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[0]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[1]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../dangersign/View.Details.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[1]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[2]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../danger/View.Details.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[2]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[3]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../studyplot/View.Details.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[3]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[4]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('../avalanche/View.Details.Aggregation.vue')
            )
          )
          detailTyp = DETAIL_COMPONENTS[4]
          // Lawinen sind von hier nicht editierbar. Es kann nur das entsprechende Detail geöffnet werden
          storeToSelect = null
          break
        case DETAIL_COMPONENTS[5]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../avalanche/DetailContent.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[5]
          // Lawinendetail sind von hier nicht editierbar. Wird im Lawinendetail abgehandelt
          storeToSelect = null
          break
        case DETAIL_COMPONENTS[6]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../attachment/View.Details.vue'))
          )
          detailTyp = DETAIL_COMPONENTS[6]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[7]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('../studyplotObservation/Detail.vue')
            )
          )
          detailTyp = DETAIL_COMPONENTS[7]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[8]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('../snowprofile/View.Details.vue')
            )
          )
          detailTyp = DETAIL_COMPONENTS[8]
          storeToSelect = null
          break
        default:
          compImport = 'default'
          detailTyp = DETAIL_COMPONENTS[0]
          break
      }
      this.components.push({
        comp: compImport,
        typ: detailTyp,
        storeToSel: storeToSelect,
      })
    },
    editObservation(observation, componentsItem) {
      if (componentsItem && componentsItem.storeToSel) {
        const studyplotId = this.getStudyplotId(observation)
        this.$store.commit(componentsItem.storeToSel + '/SELECT', observation)
        if (!studyplotId) {
          this.$store.commit(
            componentsItem.storeToSel + '/SET_LOCATION',
            observation.location
          )
        }
        this.$store.commit(
          'profile/SET_OBSERVERGROUP',
          this.getObserverGroupId(observation)
        )
        this.$store.commit(
          'profile/SET_STUDYPLOT_ID_TO_OBSERVERGROUP',
          studyplotId
        )
        this.$store.commit('SET_EXCEPTION', null)
        switch (componentsItem.typ) {
          case 'measurement':
            this.$router.push(
              `/observation/studyplot/${observation.measurement.studyplot}/${observation.measurement.type}`
            )
            break
          case 'studyplotobservation':
            this.$router.push(
              '/observation/studyplotobservation/' + studyplotId
            )
            break
          case 'danger':
            this.$router.push(
              '/observation/' +
                componentsItem.typ +
                (studyplotId ? '/' + studyplotId : '')
            )
            break
          default:
            this.$router.push('/observation/' + componentsItem.typ)
        }
        this.$bvModal.hide(this.detId)
      }
    },
    /*eslint-enable complexity*/

    getObserverGroupId(observation) {
      if (observation && observation.tags && observation.tags.observerGroup) {
        return observation.tags.observerGroup
      }
      return null
    },
    getStudyplotId(observation) {
      if (observation) {
        if (observation.measurement && observation.measurement.studyplot) {
          return observation.measurement.studyplot
        } else {
          if (observation.studyplotId) {
            return observation.studyplotId
          }
        }
      }
      return null
    },
    isEditable(observation, componentsItem) {
      return (
        this.isAllowedToEdit(observation) &&
        !this.isAvalanchesTyp(componentsItem)
      )
    },
    isAllowedToEdit(observation) {
      if (
        observation &&
        observation.management &&
        observation.management.canWrite
      ) {
        return observation.management.canWrite
      }
      return false
    },
    isAvalanchesTyp(componentsItem) {
      return componentsItem.typ === DETAIL_COMPONENTS[4]
    },
    getDetailTitle(itemTyp, dummy, obs) {
      if (!itemTyp) {
        return 'undefined'
      }
      let obsDateString
      if (
        obs?.date &&
        this.creationDate &&
        !this.isSameDate(obs.date, this.creationDate)
      ) {
        obsDateString = moment(obs.date).format('DD.MM.YYYY')
      }
      return (
        i18n.global.t('ui.' + itemTyp) +
        (obsDateString
          ? ' (' +
            i18n.global.t('mouseover.observation') +
            ': ' +
            obsDateString +
            ')'
          : '')
      )
    },
    isSameDate(dateOne, dateTwo) {
      return moment(dateOne, 'YYYY-MM-DD').isSame(
        moment(dateTwo, 'DD.MM.YYYY'),
        'day'
      )
    },
    /*eslint-disable complexity*/

    isVisible(index) {
      if (this.obs && this.obs.length === 1) {
        return true
      }
      if (
        this.obs &&
        this.obs.length > 0 &&
        this.obsIds &&
        this.obsIds.length > 0
      ) {
        return this.obs[index].id === this.obsIds[0]
      }
      return false
    },
    scrollToBeob() {
      if (this.obs && this.obs.length > 1) {
        let indexToScroll = 0
        for (let index = 0; index < this.obs.length; index++) {
          if (this.obs[index].id === this.obsIds[0]) {
            indexToScroll = index
            break
          }
        }
        if (
          indexToScroll > 0 &&
          this.$refs[this.theModal] &&
          this.$refs[this.theModal].$refs
        ) {
          this.$refs[this.theModal].$refs.body.scrollTop =
            this.$refs[this.compCard + indexToScroll.toString()][0].offsetTop
        }
      }
    },
    /*eslint-enable complexity*/

    getNewestCreationDate(observations) {
      let newestObs
      observations.forEach((obs) => {
        if (!newestObs || obs.cTimestamp > newestObs.cTimestamp) {
          newestObs = obs
        }
      })
      return newestObs
    },

    /*eslint-disable complexity*/
    mdetailDate() {
      if (this.obs && this.obs.length > 0) {
        this.creationDate = moment(
          this.getNewestCreationDate(this.obs).cTimestamp
        ).format('DD.MM.YYYY HH:mm')
      } else {
        if (this.aval && this.aval.creation) {
          this.creationDate = moment
            .utc(this.aval.creation)
            .local()
            .format('DD.MM.YYYY HH:mm')
        } else {
          this.creationDate = undefined
        }
      }
      return this.creationDate
    },
    /*eslint-enable complexity*/
  },
}
</script>

<!--
Scoped Styles werden, wenn diese Komponente mit 'extends' wiederverwendet
wird, im Parent nicht mehr berücksichtigt. Daher wird dieser Teil ausgelagert
und in den Parents importiert. ACHTUNG: Hier trotzdem auch belassen/importieren.
Dies ist nötig, falls die Komponente, als 'Component-Child' verwendet wird!
-->
<style lang="css" scoped src="./ObservationDetail.css"></style>

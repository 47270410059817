<template>
  <div>
    <component
      v-bind:is="singleAvalancheInputComponent"
      ref="dynamicComp"
      :adapt-map="adaptMap"
      :route-origin="routeOrigin"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Input.Single',
  data() {
    return {
      adaptMap: false,
      routeOrigin: undefined,
    }
  },
  computed: {
    singleAvalancheInputComponent() {
      if (this.isMobilePlatform === true) {
        return markRaw(
          defineAsyncComponent(() => import('../avalanche/Input.Mobile'))
        )
      } else {
        return markRaw(
          defineAsyncComponent(() => import('../avalanche/Input.Desktop'))
        )
      }
    },
    isMobilePlatform() {
      return this.$store.state.mobilePlatform
    },
  },
  // NOTE: beforeMount and beforeUnmount don't seem to be working here in a satisfactory manner -> included those individually in dynamic loaded components
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
      if (
        from.path.includes('/observation/list') ||
        from.path.includes('observation/avalanches')
      ) {
        vm.adaptMap = true
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // NOTE (error handling): rollback potentially added/removed documents
    // NOTE: capture event of browser back and route changes -> synch document list
    this.$refs.dynamicComp.checkDocumentState()

    // NOTE: this function needed to re-set the selected observation - before destroy on component called too late
    this.$store.commit('avalanche/SET_SELECTED', null)

    next()
  },
}
</script>

<style scoped></style>

'use strict'

import Style from 'ol/style/Style'
import { SizableSVG } from './SizableSVG'
import Icon from 'ol/style/Icon'

const getStyle = function () {
  return new Style({
    image: new Icon({
      src: getPinSymbol(30, '0 0 384 512', 'blue').src,
      anchor: [0.5, 1],
    }),
    zIndex: 10,
  })
}

const getPinSymbol = function (size, viewBox, color) {
  const pinSVG =
    '<path stroke="' +
    color +
    '" stroke-width="2" fill="' +
    color +
    '" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"/>'
  return getImage(pinSVG, viewBox, size)
}

const getImage = function (path, viewBox, size) {
  const symbol = '<g>' + path + '</g>'
  const svg = new SizableSVG(size, viewBox)
  return svg.createImage(symbol)
}

export default getStyle

'use strict'

import VLayer from 'ol/layer/Vector'

class ClusterVectorLayer {
  constructor(id, styleFunction, CLUSTER_DISTANCE, cluster, initialResolution) {
    this.styleFunction = styleFunction
    this.style = null
    this.source = cluster.source
    this.clusterSource = cluster
    this.clusterSource.resolution = initialResolution
    this.clusterSource.setDistance(CLUSTER_DISTANCE)
    this.clusters = new VLayer({
      id: id,
      source: this.clusterSource,
      style: this.styleFunction,
    })
  }
  getLayer() {
    return this.clusters
  }
  getSource() {
    return this.source
  }
  addFeatures(features) {
    this.clusterSource.clear(true)
    this.source.clear(true)
    this.source.addFeatures(features)
  }
  updateResolution(resolution) {
    this.clusterSource.resolution = resolution
  }
  setStyle(style) {
    this.style = style
    this.clusterSource.setStyle(style)
  }
  releaseMem() {
    this.source.clear(true)
    this.source = null
    this.styleFunction = null
    this.style = null
    this.clusterSource.clear(true)
    this.clusterSource.setOlMap(null)
    this.clusterSource.setStyle(null)
    this.clusterSource.releaseMem()
    this.clusterSource = null
    this.clusters.getSource().clear()
    this.clusters.setSource(null)
    this.clusters = null
  }
}
export default ClusterVectorLayer

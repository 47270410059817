import { i18n } from '@/plugins/i18n'
import Exposition from '@/components/styles/Exposition'
import { SVG } from '@/components/styles/SVG'
import { STYLE_ELEMENTS } from '@/components/styles/dangerStyling/dangerStylingConsts'
import { Aspect } from '@/model/commonModels'

const LEGEND_ITEM_MARGIN_RIGHT = 'margin-right: 0.8em;'
interface SymbolDefinition {
  size: string
  aspectFrom: Aspect
  aspectTo?: Aspect
  noContent?: boolean
  problemOrLevel?: string
  style?: string
}

export const getDangerLegend = function (): Record<string, unknown> {
  // NOTE (20200113/csz): according to YT#SLPPWB-826, 3- and 3 would become 3, 3+ would remain 3+, both w/ same color
  return {
    title: 'danger.label.productMainEstimate',
    tableStyle: 'width:12.5em;',
    items: [
      {
        label: 'danger.legend.aspectKnown',
        symbol: getLegendSymbol(
          {
            size: 'LEGEND_LARGE',
            aspectFrom: 'SW',
            aspectTo: 'SE',
          },
          '-20 -17 40 35'
        ),
        style: `${LEGEND_ITEM_MARGIN_RIGHT}margin-left: 0.35em;`,
      },
      {
        label: 'danger.legend.aspectUnknown',
        symbol: getLegendSymbol(
          {
            size: 'SMALL',
            aspectFrom: 'N',
            aspectTo: 'N',
          },
          '-29 -15 40 35'
        ),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.drySnow',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          noContent: true,
          problemOrLevel: 'DRY',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.wetSnow',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          noContent: true,
          problemOrLevel: 'WET',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.fullDepth',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          noContent: true,
          problemOrLevel: 'FULL_DEPTH',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: i18n.global.t('danger.legend.altitude', {
          above: i18n.global.t('danger.legend.above'),
          val: '2000',
        }),
        symbol: getLegendText('+20'),
      },
      {
        label: 'danger.legend.1',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: '1',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.2',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: '2',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.3',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: '3',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.4',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: '4',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.5',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: '5',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.legend.unknown',
        symbol: getLegendSymbol({
          size: 'LARGE',
          aspectFrom: 'NE',
          problemOrLevel: 'UNKNOWN',
        }),
        style: LEGEND_ITEM_MARGIN_RIGHT,
      },
      {
        label: 'danger.label.subdivision',
        symbol: getLegendText('-/=/+', '-0 -25 70 35', 35),
      },
    ],
  }
}

const getLegendSymbol = function (
  symbolDefinition: SymbolDefinition,
  svgBox = '-20 -25 40 35'
) {
  const symbol = document.createElement('g')
  symbol.setAttribute(
    'style',
    STYLE_ELEMENTS[symbolDefinition.problemOrLevel as string] ||
      STYLE_ELEMENTS.UNKNOWN
  )
  const exposition = new Exposition(
    symbolDefinition.aspectFrom,
    symbolDefinition.aspectTo
  )
  const pie = exposition.getPie(
    LEGEND_SCALE[symbolDefinition.size as string],
    symbolDefinition.noContent === true
  )
  if (pie) {
    symbol.appendChild(pie)
  }

  const svg = new SVG(svgBox)
  return svg.createImage(symbol.outerHTML)
}

const getLegendText = function (
  text: string,
  svgBox = '-15 -22 45 35',
  fontSize = 16
) {
  const t = document.createElement('text')
  t.innerHTML = text
  t.setAttribute('x', '0')
  t.setAttribute('y', '0')
  t.setAttribute('style', `font: ${fontSize}px Helvetica;`)

  const svg = new SVG(svgBox)
  return svg.createImage(t.outerHTML)
}

// Legend meta:
const LEGEND_SCALE: Record<string, number> = {
  SMALL: 10,
  MEDIUM: 15,
  LEGEND_LARGE: 18.5,
  LARGE: 20,
  UNKNOWN: 0,
}

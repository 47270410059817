import {
  COLOR_FAVORABLE,
  COLOR_GUENSTIG,
  COLOR_GUENSTIG_STROKE,
} from '@/components/styles/AbstractStyle'
import { Fill } from 'ol/style'

export const COLOR_FAVORABLE_FILL = new Fill({
  color: COLOR_FAVORABLE,
})

export const HUMIDITY_STYLES = {
  // stroke_color wurde schon anstatt fill semantisch falsch verwendet. Deshalb habe ich stroke für die Randfarbe eingeführt
  NO: {
    fill: 'rgba(5,113,176, 0.2)',
    stroke_color: COLOR_GUENSTIG,
    stroke_width: 3,
    stroke: COLOR_GUENSTIG_STROKE,
  },
  DRY: {
    fill: 'rgba(5,113,176, 0.2)',
    stroke_color: 'rgb(5,113,176)',
    stroke_width: 3,
    stroke: 'rgb(3,57,88)',
  },
  WET: {
    fill: 'rgba(202,0,32, 0.2)',
    stroke_color: 'rgb(202,0,32)',
    stroke_width: 3,
    stroke: 'rgb(101,0,16)',
  },
  MIXED: {
    fill: 'rgba(244,165,130, 0.2)',
    stroke_color: 'rgb(244,165,130)',
    stroke_width: 3,
    stroke: 'rgb(122,83,65)',
  },
  UNKNOWN: {
    fill: 'rgba(0,0,0, 0.2)',
    stroke_color: 'rgb(0,0,0)',
    stroke_width: 3,
    stroke: 'rgb(0,0,0)',
  },
}

const INPUT_STYLEMAP_COLORS = {
  fill: 'rgba(128,128,128,0.2)',
  stroke_color: 'rgb(128,128,128)',
  stroke_width: 3,
  stroke: 'rgb(128,128,128)',
}
export const HUMIDITY_STYLES_INPUT_MAP = {
  NO: INPUT_STYLEMAP_COLORS,
  DRY: INPUT_STYLEMAP_COLORS,
  WET: INPUT_STYLEMAP_COLORS,
  MIXED: INPUT_STYLEMAP_COLORS,
  UNKNOWN: INPUT_STYLEMAP_COLORS,
}

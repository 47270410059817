import moment from 'moment'
import { i18n } from '@/plugins/i18n'
import { MEASURE, OBJECT_MEASURE } from '@/scripts/const.js'
import observationService from '@/services/observationService.js'
import { v1 as uuidV1 } from 'uuid'
import { deepClone } from '@/scripts/common'

// Helpers
const PROP_WARNLIGHT = 'warningLight'

export function getWarningLight(measureObs, area) {
  const clonedMeasuresObs = deepClone(measureObs)
  let areaObject

  if (clonedMeasuresObs && clonedMeasuresObs.measure && area) {
    areaObject = {}
    areaObject.name =
      i18n.global.t('measure.label.warningLight') + ' (' + area.name + ')'
    areaObject.class = PROP_WARNLIGHT
    areaObject[MEASURE] = clonedMeasuresObs.measure
    areaObject[MEASURE].objectType = PROP_WARNLIGHT
    areaObject[MEASURE].class = PROP_WARNLIGHT
    areaObject.ts = clonedMeasuresObs.cTimestamp
    areaObject.cTimestamp = clonedMeasuresObs.cTimestamp
    areaObject.uid = clonedMeasuresObs.uid
  }
  return areaObject
}
function getNoMeasureCommon(measure) {
  return {
    id: uuidV1(),
    begin: moment(),
    measure: measure,
  }
}

// state
const state = {
  measures: [],
}

// getters
const getters = {
  /*
    Bereitet ein Array aus neuen Objekten auf. Ein solches einzelnes Objekt vereint einen Massnahmenentscheid und ein Schutzobjekt.
    Übergeben wird ein Array aus Massnahmenentscheiden
    Zurückgegeben wird ein Array mit den neuen Objekten
  */
  getMeasuresWithObjectsToProtect:
    (state, getters, rootState, rootGetters) => (latestMeasuresObs) => {
      const clonedLatestMeasuresObs = deepClone(latestMeasuresObs)
      const measures = []
      const objectsToProtect = deepClone(
        rootGetters['profile/getAllObjectsToProtect']
      )
      clonedLatestMeasuresObs.forEach((measureObs) => {
        if (measureObs) {
          const object = objectsToProtect.find(
            (object) => object.id === measureObs.hierarchy.objectId
          )
          if (object && measureObs.measure) {
            object[MEASURE] = measureObs.measure
            object[MEASURE].objectType = object.type
            object[MEASURE].class = object.class
            object.ts = measureObs.cTimestamp
            object.cTimestamp = measureObs.cTimestamp
            object.uid = measureObs.uid
            measures.push(object)
          }
        }
      })
      return measures
    },
  getMeasureForDetailModal:
    (state, getters, rootState, rootGetters) => (measureObs) => {
      if (!measureObs) {
        return {}
      }
      const objectToMeasures = getters['getMeasuresWithObjectsToProtect']([
        measureObs,
      ])
      const measureDetail = {
        id: measureObs.id,
        cTimestamp: moment(measureObs.cTimestamp).unix() * 1000,
        type: MEASURE,
        uid: measureObs.uid,
        measure: measureObs.measure,
        hierarchy: measureObs.hierarchy,
        location: measureObs.location,
        creatorSystem: measureObs.creatorSystem,
      }
      if (objectToMeasures && objectToMeasures.length > 0) {
        measureDetail[OBJECT_MEASURE] = objectToMeasures[0]
      } else {
        const area = rootGetters['profile/getAreaToId'](
          measureObs.hierarchy.areaId
        )
        measureDetail[OBJECT_MEASURE] = getWarningLight(measureObs, area)
      }
      return measureDetail
    },
  getNoMeasure() {
    return getNoMeasureCommon('NO_MEASURE')
  },
  getNoWarnlight() {
    return getNoMeasureCommon('WARNING_LIGHT_OFF')
  },
}

// actions
const actions = {
  addMeasure(context, value) {
    let location
    if (value.hierarchy.objectId) {
      location = context.rootGetters['profile/getObjectLocationToObjectId'](
        value.hierarchy.objectId
      )
    } else {
      location = context.rootGetters['profile/getAreaLocationToAreaId'](
        value.hierarchy.areaId
      )
    }
    const measureToAdd = prepareMeasure(value, location)

    return context.dispatch('assessmentObs/submitAssessmentObs', measureToAdd, {
      root: true,
    })
  },
  loadLatestMeasures({ commit, state }, params) {
    commit('SET_MEASURES', [])
    let timestamp = moment()
    if (params.timestamp) {
      timestamp = moment(params.timestamp)
    }
    timestamp = timestamp.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSS[Z]')
    return new Promise((resolve, reject) => {
      observationService
        .getLatestMeasures(timestamp, params.avalServiceIds, params.cancel)
        .then((latestMeasures) => {
          const measures = latestMeasures.data.data.map((el) => ({
            hierarchy:
              el.actualMeasure?.hierarchy || el.planedMeasure?.hierarchy,
            measure: el.planedMeasure?.measure,
            actualMeasure: el.actualMeasure?.measure,
            uid: el.planedMeasure?.uid,
            timestamp: el.timestamp,
            location: el.actualMeasure?.location || el.planedMeasure?.location,
          }))
          commit('SET_MEASURES', measures)
          commit('SET_EXCEPTION', null, { root: true })
          console.debug('latest measures loaded.')
          const latestReleasedMeasures = state.measures.reduceRight(
            (latest, item) =>
              latest.find(
                (el) =>
                  (el.hierarchy.areaId === undefined &&
                    el.hierarchy.objectId === item.hierarchy.objectId) ||
                  (el.hierarchy.objectId === undefined &&
                    el.hierarchy.areaId === item.hierarchy.areaId)
              )
                ? latest
                : [...latest, item],
            []
          )
          resolve(latestReleasedMeasures)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_MEASURES(state, measures) {
    state.measures = measures
  },
}

// Helper-Functions
function prepareMeasure(value, location) {
  const measureObs = {
    type: MEASURE,
  }
  const hierarchy = {
    objectId: value.hierarchy.objectId,
    areaId: value.hierarchy.areaId,
    avalServiceId: value.hierarchy.avalServiceId,
  }
  const measure = {
    measure: value.form.measure,
    begin: value.form.begin,
    end: value.form.end,
    executor: value.form.executor,
    reason: value.form.reason,
  }
  measure.reason = measure.reason === '' ? null : measure.reason

  measureObs.hierarchy = hierarchy
  measureObs.measure = measure
  measureObs.location = location

  return measureObs
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <b-row>
        <b-col>
          <b>{{ $t(`assessment.situation.label.title`) }}</b>
        </b-col>
      </b-row>
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button type="button" variant="warning" @click="routeBack(true)">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="onSubmit"
            type="button"
            variant="success"
            :disabled="!valid"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <AreaSectorInput
      :ldId="avalServiceId"
      :disableSector="true"
      v-model="form.hierarchy"
    />

    <div>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="hierarchyState"
              aria-describedby="hierarchyFeedback"
            />
            <b-form-invalid-feedback id="hierarchyFeedback">
              {{ $t('assessment.msg.validHierarchy') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.situation.label.hn1d') + ' [cm]'"
          >
            <b-form-input
              v-model="form.current.hn1d"
              type="text"
              pattern="[0-9]*"
              :state="hn1dState"
              aria-describedby="hn1dFeedback"
            />
            <b-form-invalid-feedback id="hn1dFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '200' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.forecast') +
              ' ' +
              $t('assessment.situation.label.hn1d') +
              ' [cm]'
            "
          >
            <b-form-input
              v-model="form.forecast.hn1d"
              type="text"
              pattern="[0-9]*"
              :state="hn1dfcState"
              aria-describedby="hn1dfcFeedback"
            />
            <b-form-invalid-feedback id="hn1dfcFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '200' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.situation.label.hn1dValley') + ' [cm]'"
          >
            <b-form-input
              id="inputHn1dValley"
              v-model="form.current.hn1dValley"
              type="text"
              pattern="[0-9]*"
              :state="hn1dValleyState"
              aria-describedby="hn1dValleyFeedback"
            />
            <b-form-invalid-feedback id="hn1dValleyFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '100' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.snowRainAltitude') +
              ' [' +
              $t('common.unit.meterAboveSeaLevel') +
              ']'
            "
          >
            <b-form-select
              :options="masl"
              v-model="form.current.snowRainAltitude"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.forecast') +
              ' ' +
              $t('assessment.situation.label.snowRainAltitude') +
              ' [' +
              $t('common.unit.meterAboveSeaLevel') +
              ']'
            "
          >
            <b-form-select
              :options="masl"
              v-model="form.forecast.snowRainAltitude"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            cols="md-6"
            :label="$t('assessment.situation.label.hn3d') + ' [cm]'"
          >
            <b-form-input
              v-model="form.current.hn3d"
              type="text"
              pattern="[0-9]*"
              :state="hn3dState"
              aria-describedby="hn3dFeedback"
            />
            <b-form-invalid-feedback id="hn3dFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.situation.label.hn3dValley') + ' [cm]'"
          >
            <b-form-input
              id="inputHn3dValley"
              v-model="form.current.hn3dValley"
              type="text"
              pattern="[0-9]*"
              :state="hn3dValleyState"
              aria-describedby="hn3dValleyFeedback"
            />
            <b-form-invalid-feedback id="hn3dValleyFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '300' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.situation.label.vw') + ' [km/h]'"
          >
            <b-form-input
              v-model="form.current.vw"
              type="text"
              pattern="[0-9]*"
              :state="vwState"
              aria-describedby="vwFeedback"
            />
            <b-form-invalid-feedback id="vwFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '200' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.forecast') +
              ' ' +
              $t('assessment.situation.label.vw') +
              ' [km/h]'
            "
          >
            <b-form-input
              v-model="form.forecast.vw"
              type="text"
              pattern="[0-9]*"
              :state="vwfcState"
              aria-describedby="vwfcFeedback"
            />
            <b-form-invalid-feedback id="vwfcFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '0', max: '200' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.situation.label.dw')">
            <b-form-select :options="aspects" v-model="form.current.dw" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.forecast') +
              ' ' +
              $t('assessment.situation.label.dw')
            "
          >
            <b-form-select :options="aspects" v-model="form.forecast.dw" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.situation.label.ta') + ' [°C]'">
            <b-form-input
              v-model="form.current.ta"
              type="text"
              pattern="-?[0-9]*"
              :state="taState"
              aria-describedby="taFeedback"
            />
            <b-form-invalid-feedback id="taFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '-50', max: '50' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.forecast') +
              ' ' +
              $t('assessment.situation.label.ta2k') +
              ' [°C]'
            "
          >
            <b-form-input
              v-model="form.forecast.ta2k"
              type="text"
              pattern="-?[0-9]*"
              :state="ta2kfcState"
              aria-describedby="ta2kfcFeedback"
            />
            <b-form-invalid-feedback id="ta2kfcFeedback">
              {{ $t('msg.selectValidValue') }}
              {{ $t('msg.onlyIntegerBetween', { min: '-50', max: '50' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.situation.label.snowpackStructure')"
          >
            <b-form-select
              id="selectSnowpackStructure"
              :options="snowpackStructures"
              v-model="form.current.snowpackStructure"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.situation.label.avalRelease')">
            <b-form-select
              :options="avalReleases"
              v-model="form.current.avalancheRelease"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('assessment.situation.label.avalRelease')">
            <b-form-select
              id="selectDangerTrends"
              :options="dangerTrends"
              v-model="form.forecast.dangerTrend"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.comment') +
              ' ' +
              $t('assessment.situation.label.current')
            "
          >
            <b-form-textarea
              id="comment"
              v-model="form.current.comment"
              :state="commentState"
              aria-describedby="commentFeedback"
              size="lg"
            />
            <b-form-invalid-feedback id="commentFeedback">
              {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="
              $t('assessment.situation.label.comment') +
              ' ' +
              $t('assessment.situation.label.forecast')
            "
          >
            <b-form-textarea
              id="commentfc"
              v-model="form.forecast.comment"
              :state="commentfcState"
              aria-describedby="commentfcFeedback"
              size="lg"
            />
            <b-form-invalid-feedback id="commentfcFeedback">
              {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { i18nMixin } from '@/components/I18N'
import AreaSectorInput from '@/components/assessment/AreaSectorInput'
import util from '@/scripts/util'
import moment from 'moment'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import { ASSESSMENT_SITUATION } from '@/scripts/const.js'
import { inputFrmRouteBackMixin } from '@/components/observation/InputFrmRouteBackMixin.js'
import { deepClone } from '@/scripts/common'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { AreaSectorInput },
  mixins: [i18nMixin, inputFrmRouteBackMixin],
  data() {
    return {
      form: {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        current: {
          hn1d: null,
          hn1dValley: null,
          hn3d: null,
          hn3dValley: null,
          snowRainAltitude: null,
          vw: null,
          dw: null,
          ta: null,
          snowpackStructure: null,
          avalancheRelease: null,
          comment: null,
        },
        forecast: {
          hn1d: null,
          snowRainAltitude: null,
          vw: null,
          dw: null,
          ta2k: null,
          dangerTrend: null,
          comment: null,
        },
      },
      processing: null,
      error: false,
      new: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  mounted() {
    if (this.$store.state.assessmentObs.selected) {
      this.new = false
      this.form = deepClone(this.$store.state.assessmentObs.selected.situation)
      assessmentUtil.prepareAssessmentObservationInputForm(
        ASSESSMENT_SITUATION,
        this.$store.state.assessmentObs.selected,
        this.form
      )
    } else {
      const hierarchyMap = this.$store.state.assessmentObs.hierarchyMap
      if (
        util.existValidHierarchy(
          hierarchyMap.get(this.avalServiceId),
          this.$store.state.assessmentObs.date
        )
      ) {
        this.form.hierarchy = deepClone(
          hierarchyMap.get(this.avalServiceId).selection
        )
      }
      this.form.hierarchy.avalServiceId = this.avalServiceId
    }
  },
  unmounted() {
    this.$store.commit('assessmentObs/SET_SELECTED', null)
    this.reset()
  },
  computed: {
    hn1dState() {
      return this.check(this.form.current.hn1d, 0, 200)
    },
    hn1dValleyState() {
      return this.check(this.form.current.hn1dValley, 0, 100)
    },
    hn1dfcState() {
      return this.check(this.form.forecast.hn1d, 0, 200)
    },
    hn3dState() {
      return this.check(this.form.current.hn3d, 0, 500)
    },
    hn3dValleyState() {
      return this.check(this.form.current.hn3dValley, 0, 300)
    },
    vwState() {
      return this.check(this.form.current.vw, 0, 200)
    },
    vwfcState() {
      return this.check(this.form.forecast.vw, 0, 200)
    },
    taState() {
      return this.check(this.form.current.ta, -50, 50)
    },
    ta2kfcState() {
      return this.check(this.form.forecast.ta2k, -50, 50)
    },
    commentState() {
      if (!this.form.current.comment) {
        return null
      }
      return (
        this.form.current.comment &&
        this.form.current.comment.length >= 2 &&
        this.form.current.comment.length <= 500
      )
    },
    commentfcState() {
      if (!this.form.forecast.comment) {
        return null
      }
      return (
        this.form.forecast.comment &&
        this.form.forecast.comment.length >= 2 &&
        this.form.forecast.comment.length <= 500
      )
    },
    hierarchyState() {
      if (!(this.form.hierarchy && this.form.hierarchy.areaId)) {
        return null
      } else {
        return true
      }
    },
    /*eslint-disable complexity*/
    valid() {
      return (
        this.hierarchyState &&
        this.nvl(this.hn1dState) &&
        this.nvl(this.hn1dValleyState) &&
        this.nvl(this.hn1dfcState) &&
        this.nvl(this.hn3dState) &&
        this.nvl(this.vwState) &&
        this.nvl(this.vwfcState) &&
        this.nvl(this.commentState) &&
        this.nvl(this.commentfcState) &&
        // mindestens 1 Wert
        (this.hn1dState ||
          this.hn1dValleyState ||
          this.hn1dfcState ||
          this.hn3dState ||
          this.hn3dValleyState ||
          this.form.snowRainAltitude ||
          this.form.forecast.snowRainAltitude ||
          this.vwState ||
          this.vwfcState ||
          this.form.dw ||
          this.form.forecast.dw ||
          this.taState ||
          this.form.current.snowpackStructure ||
          this.ta2kfcState ||
          this.form.current.avalancheRelease ||
          this.form.forecast.dangerTrend ||
          this.commentState ||
          this.commentfcState)
      )
    },
    /*eslint-enable complexity*/
    masl() {
      return [
        { text: this.$i18n.t('ui.select'), value: null },
        400,
        600,
        800,
        1000,
        1200,
        1400,
        1600,
        1800,
        2000,
        2200,
        2400,
        2600,
        2800,
        3000,
        3200,
        3400,
        3600,
        3800,
        4000,
        4200,
      ]
    },
    aspects() {
      return this.options('common', 'aspect')
    },
    snowpackStructures() {
      return this.options('assessment', 'snowpackStructure')
    },
    avalReleases() {
      return this.options('assessment', 'avalRelease')
    },
    dangerTrends() {
      return this.options('assessment', 'dangerTrend')
    },
    flag() {
      return this.options('common', 'flag')
    },
    avalServiceId() {
      return this.$store.getters['profile/getAvalServiceId']
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this

      const hierarchyClone = Object.assign({}, self.form.hierarchy)
      this.$store
        .dispatch(
          'assessmentObs/submitAssessmentObs',
          assessmentUtil.prepareAssessmentObservation(ASSESSMENT_SITUATION, {
            situation: this.form,
            type: ASSESSMENT_SITUATION,
            location: this.getLocationtoArea(),
          })
        )
        .then(
          () => {
            if (hierarchyClone) {
              const storeHierarchy =
                self.$store.state.assessmentObs.hierarchyMap.get(
                  self.avalServiceId
                )
              self.$store.commit('assessmentObs/SET_HIERARCHY', {
                avalServiceId: self.avalServiceId,
                hierarchy: {
                  selection: util.getConsolidatedHierarchySelection(
                    Object.assign({}, hierarchyClone),
                    storeHierarchy ? storeHierarchy.selection : null,
                    true
                  ),
                  date: moment(),
                },
              })
            }
            self.processing = false
            self.routeBack(false)
          },
          (reason) => {
            console.error('Error submitting assessment:' + reason)
            self.processing = false
          }
        )
    },
    getLocationtoArea() {
      return this.$store.getters['profile/getAreaLocationToAreaId'](
        this.form.hierarchy.areaId
      )
    },
    routeBack() {
      this.routeBackAssessmentTypes(this.new)
    },
    reset() {
      this.form = {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        current: {
          hn1d: null,
          hn1dValleyState: null,
          hn3d: null,
          hn3dValley: null,
          snowRainAltitude: null,
          vw: null,
          dw: null,
          ta: null,
          snowpackStructure: null,
          avalancheRelease: null,
          comment: null,
        },
        forecast: {
          hn1d: null,
          snowRainAltitude: null,
          vw: null,
          dw: null,
          ta2k: null,
          dangerTrend: null,
          comment: null,
        },
      }
    },
    check(value, min, max) {
      if (!value) return null
      if (!/^-?[\d]+$/.test(value)) return false
      return value >= min && value <= max && value % 1 === 0
    },
    nvl(value) {
      return value === null ? true : value
    },
  },
}
</script>

<style scoped></style>

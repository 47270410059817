'use strict'

/**
 * common functions to attachments and avalanches: thumbnail/image retrieval in forms and views
 */

export const MAX_FILE_SIZE = 100 // in MB!
export const RESTRICTED = 'LWD'
export const NOT_RESTRICTED = 'NONE'

export default {
  getThumbnails(store, files) {
    const promises = []
    files.forEach((file) => {
      if (!file.thumbnail) {
        promises.push(
          store
            .dispatch('document/fetchThumbnail', file)
            .then((x) => {
              file.thumbnail = x
            })
            .catch(() => {
              // empty base64: data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=
              file.thumbnail =
                'data:' +
                file.mediaType +
                ';base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='
            })
        )
      }
    })
    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then(() => {
          resolve(files)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async showDocument(store, file, confidential) {
    // independent document service to call
    return store
      .dispatch('document/fetchDocument', {
        id: file.id,
        mediaType: file.mediaType,
      })
      .then((attachData) => {
        // NOTE/csz: tiff only for download
        if (
          file.mediaType &&
          (!file.mediaType.startsWith('image') ||
            file.mediaType.localeCompare('image/tiff') === 0)
        ) {
          this.startDownloadInBrowser(attachData, file)
          return null
        } else {
          return attachData
            ? this.getAttachDetail(attachData, file, confidential)
            : {}
        }
      })
      .catch((err) => {
        console.error(err)
        if (!(err.response && err.response.status === 401)) {
          store.commit('SET_EXCEPTION', err.message, { root: true })
        }
        return null
      })
  },
  rollbackFiles(store, originalFiles, files) {
    // NOTE: IDs added must be removed from document store
    // NOTE: removed documents cannot be re-added since file content not available at this point
    // NOTE: files is normally undefined after save (preSubmitValidate called), therefore, in case of a submit, there will be no roll back here
    if (files && originalFiles) {
      const addedFiles = []
      // identify added documents
      files.forEach((file) => {
        if (!originalFiles.some((origFile) => origFile.id === file.id)) {
          addedFiles.push(file)
        }
      })
      addedFiles.forEach((elem) => {
        store
          .dispatch('document/deleteDocument', elem.id)
          .then(() => {
            console.log('rolled back added document after quitting page')
          })
          .catch((err) => {
            console.log(
              'rollback of added document after quitting page failed',
              err
            )
          })
      })
    }
  },
  /**
   * Order documents by file name
   * @param documents - the document array
   *  */
  sortDocumentArray(documents) {
    if (documents && documents instanceof Array && documents.length > 1) {
      documents.sort((a, b) => {
        let aa, bb
        if (a && a.fileName) {
          aa = a.fileName.toUpperCase()
        }
        if (b && b.fileName) {
          bb = b.fileName.toUpperCase()
        }
        return aa > bb
      })
    }
  },
  startDownloadInBrowser(attachData, file) {
    // Bestehende unveränderte Download-Implementierung
    const newBlob = new Blob([attachData], { type: file.mediaType })
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob)
      return
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = data
    link.download = file.fileName
    document.body.appendChild(link)
    link.click()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let timeoutRef = setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link)
      window.URL.revokeObjectURL(data)
      timeoutRef = null
    }, 100)
  },
  getAttachDetail(attachData, file, confidential) {
    return {
      title: file.fileName,
      id: file.id,
      description: file.description,
      imageSrc: attachData,
      confidential: confidential,
    }
  },
}

<template>
  <b-container>
    <b-card
      header-tag="header"
      footer-tag="footer"
      style="pointer-events: auto"
    >
      <template #header>
        <b-row>
          <b-col>
            <b>{{ $t('triggering.label.avalancheTriggeringFor') }}</b> "{{
              avalanchePath.name
            }}"
          </b-col>
        </b-row>
      </template>
      <template #footer>
        <b-row>
          <b-col class="text-left">
            <b-button
              type="button"
              variant="warning"
              @click="routeBack(true)"
              >{{ $t('ui.cancel') }}</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              ref="triggeringInputSendButton"
              @click="onSubmit"
              type="button"
              variant="success"
              :disabled="!valid"
              ><font-awesome-icon
                icon="fa-solid fa-paper-plane"
              ></font-awesome-icon>
              {{ $t('ui.send') }}</b-button
            >
          </b-col>
        </b-row>
      </template>

      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('triggering.label.blastingMethod')">
              <b-form-checkbox-group
                v-model="form.blastingMethod"
                :options="blastingMethods"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('triggering.label.plannedInput')">
              <calendar-component
                class="calendar-picker w-100"
                v-model="validPlanned"
                with-time
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('triggering.label.executor')">
              <b-form-textarea
                id="executor"
                v-model="form.executor"
                :rows="3"
                :spellcheck="false"
                :state="executorState"
                size="lg"
              />
              <b-form-invalid-feedback id="inputExecutortHelp">
                {{ $tc('msg.maxCharacters', 5000) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('triggering.label.reason')">
              <b-form-textarea
                id="reason"
                v-model="form.reason"
                :rows="3"
                :spellcheck="false"
                :state="reasonState"
                size="lg"
              />
              <b-form-invalid-feedback id="inputCommentHelp">
                {{ $t('msg.minMaxCharacters', { min: '2', max: '1000' }) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '../I18N'
import CalendarComponent from '../calendar/CalendarComponent'
import { routerMixin } from '@/router/RouterMixin'

const PLANNED_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
const INVALID_DATE = 'Invalid date'

export default {
  components: { CalendarComponent },
  props: ['avalanchePathId', 'areaId'],
  mixins: [i18nMixin, routerMixin],
  data() {
    return {
      hierarchy: {
        areaId: this.areaId,
        avalanchePathId: this.avalanchePathId,
      },
      form: {
        blastingMethod: [],
        planned: null,
        executor: null,
        reason: null,
      },
      processing: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  mounted() {
    this.$store
      .dispatch('triggering/loadLatestTriggerings', {
        avalServiceIds: this.avalServiceIds(this.avalanchePathId),
      })
      .then((items) => {
        const item = items.find(
          (el) => el.hierarchy.avalanchePathId === this.avalanchePathId
        )
        if (item) {
          this.hierarchy.avalanchePathId = item.hierarchy.avalanchePathId
          this.form = {
            blastingMethod: item.triggering.blastingMethod
              ? item.triggering.blastingMethod
              : [],
            planned: item.triggering.planned,
            executor: item.triggering.executor,
            reason: item.triggering.reason,
          }
        }
      })
  },
  computed: {
    reasonState() {
      if (!this.form.reason) {
        return null
      }
      return (
        this.form.reason &&
        this.form.reason.length >= 2 &&
        this.form.reason.length <= 1000
      )
    },
    executorState() {
      if (!this.form.executor) return null
      return this.form.executor.length <= 5000
    },
    /* eslint-disable complexity */
    valid() {
      return (
        this.form.blastingMethod &&
        this.form.blastingMethod.length > 0 &&
        moment(this.form.planned).isValid() &&
        (this.executorState === null ? true : this.executorState) &&
        (this.reasonState === null ? true : this.reasonState)
      )
    },
    /* eslint-enable complexity */
    blastingMethods() {
      return this.options('triggering', 'blastingMethods', true)
    },
    avalanchePath() {
      return this.$store.getters['profile/getAvalanchePath'](
        this.avalanchePathId
      )
    },
    validPlanned: {
      get() {
        return moment(this.form.planned).format(PLANNED_DATE_TIME_FORMAT)
      },
      set(val) {
        this.form.planned =
          val === INVALID_DATE
            ? null
            : moment(val, PLANNED_DATE_TIME_FORMAT).toDate()
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this
      this.$store
        .dispatch('triggering/addTriggering', {
          form: this.form,
          hierarchy: this.hierarchy,
        })
        .then(
          () => {
            self.processing = false
            self.routeBack(false)
          },
          (reason) => {
            console.error('Error submitting triggering:' + reason)
            self.processing = false
          }
        )
    },
    routeBack() {
      this.routeToWhenNeeded(this.routeOrigin)
    },
    reset() {
      this.hierarchy = {
        avalanchePathId: this.avalanchePathId,
      }
      this.form = {
        blastingMethod: [],
        planned: null,
        executor: null,
        reason: null,
      }
    },
    avalServiceIds(avalanchePathId) {
      const result =
        this.$store.getters['profile/getAssmAvalServiceToAvalanchePathId'](
          avalanchePathId
        )
      return result ? [result.id] : []
    },
  },
}
</script>

<style scoped></style>

<template>
  <b-modal
    ref="draggableModal"
    id="draggableModal"
    size="lg"
    scrollable
    no-fade
    hide-footer
    lazy
    header-class="modal-view-header"
    @shown="draggable"
    @hidden="hidden"
    modal-class="custom-modal align-top"
    :static="active"
    :hide-backdrop="active"
    :no-close-on-backdrop="true"
    style="pointer-events: none"
  >
    <template #modal-header>
      <b-container>
        <b-row>
          <b-col cols="4" class="text-left modal-detail-title-dark">
            {{ header.date }}
          </b-col>
          <b-col cols="6" lg="7" class="text-left modal-detail-title-mid">
            {{ header.name }}
          </b-col>
          <b-col cols="2" lg="1" class="text-right modal-detail-title-mid">
            <b-row>
              <b-col cols="6" class="pl-0 pr-2 text-right">
                <div v-if="showReportPrint" @click="reportPrint()">
                  <font-awesome-icon
                    class="modal-print-icon"
                    icon="fa-solid fa-file-pdf"
                    size="lg"
                  ></font-awesome-icon>
                </div>
                <b-spinner v-show="reportIsLoading" small />
              </b-col>
              <b-col cols="6" class="pl-1 pr-2">
                <div id="detailViewCloseIcon" @click="closeMe()">
                  <font-awesome-icon
                    class="modal-close-icon"
                    icon="fa-solid fa-times"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <ObservationDetail
      v-if="type === 'observation'"
      :obsIds="obs.obsIds"
      :aval="obs.aval"
      :aggregate="obs.aggregate"
      v-on:setHeader="header = $event"
      v-on:sendReportState="setReportState($event)"
      ref="commonObsChild"
    />
    <SnowprofileDetail
      v-if="type === 'snowprofile'"
      :aval="obs.aval"
      v-on:setHeader="setHeader"
      v-on:sendReportState="setReportState($event)"
    />
    <MeasurementDetail
      v-if="type === 'measurement'"
      :aval="obs.measurement"
      v-on:setHeader="header = $event"
      ref="measurementChild"
      v-on:sendReportState="setReportState($event)"
    />
    <AssessmentDetail
      v-if="type === 'assessment'"
      :assm="assessment"
      :aggregate="assessment.aggregate"
      v-on:setHeader="header = $event"
      ref="assChild"
      v-on:sendReportState="setReportState($event)"
    />
  </b-modal>
</template>

<script>
import $ from 'jquery'
import 'jquery-ui/ui/widgets/draggable'
import ObservationDetail from './observation/ObservationDetail'
import SnowprofileDetail from './snowprofile/SnowprofileDetail'
import MeasurementDetail from './studyplot/MeasurementDetail'
import AssessmentDetail from './assessment/detail/AssessmentDetail'
import moment from 'moment'
import { draggableModalReportMixin } from '@/components/reporting/DraggableModalReportMixin.js'
import emitter from '@/components/EventHub'

export default {
  components: {
    ObservationDetail,
    SnowprofileDetail,
    MeasurementDetail,
    AssessmentDetail,
  },
  mixins: [draggableModalReportMixin],
  name: 'DraggableModal',
  data() {
    return {
      active: !this.$store.state.mobilePlatform,
      header: { date: '', name: '' },
      isAdmin: this.$store.state.user.user.isAdmin,
      type: null,
      obs: {},
      station: {},
      assessment: {},
      previous: null,
      observer: null,
      reportIsLoading: false,
    }
  },
  watch: {
    type(value) {
      if (value) {
        this.initReportPrinting(value)
      }
    },
  },
  mounted() {
    emitter.on('showObservation', (val) => {
      this.aggregate = val.aggregate
      this.previous = this.obs
      this.obs = val
      this.type = 'observation'
      this.showDraggable()
    })
    emitter.on('showSnowprofile', (val) => {
      this.obs = val
      let snowProfCreateDate
      if (val && val.aval && val.aval[0] && val.aval[0].get('snowprofile')) {
        snowProfCreateDate = val.aval[0].get('snowprofile').obsDate
        snowProfCreateDate = moment(snowProfCreateDate, 'YYYY-MM-DD').format(
          'DD.MM.YYYY'
        )
      }
      this.header = { date: snowProfCreateDate, name: '' }
      this.type = 'snowprofile'
      this.showDraggable()
    })
    emitter.on('showMeasurement', (val) => {
      this.obs = val
      this.type = 'measurement'
      this.showDraggable()
    })
    emitter.on('showAssessment', (val) => {
      this.assessment = val
      this.type = 'assessment'
      this.showDraggable()
    })
    this.$router.beforeEach((to, from, next) => {
      this.type = null
      this.$refs.draggableModal.hide()
      next()
    })
  },
  methods: {
    setHeader(event) {
      this.header.name = event.name
    },
    draggable() {
      if (!this.active) return
      const offsetTop = $('.modal-content')[0].getBoundingClientRect().top
      $('.modal-content').draggable({
        handle: '.modal-header',
        containment: 'document',
        scroll: false,
        drag: (evt, ui) => {
          ui.position.top = Math.max(-offsetTop, ui.position.top)
        },
        stop: (evt, ui) => {
          this.$store.dispatch('details/setPosition', ui.position)
        },
      })

      if (this.$store.state.details.position) {
        $('.modal-content').css(this.$store.state.details.position)
      }

      this.observer = new MutationObserver(() => {
        const el = $('.modal-content')
        const marginBottom =
          $(window).height() - parseInt(el.css('top')) - el.height()
        if (marginBottom < 0) {
          const position = {
            top: $(window).height() - el.height() - offsetTop - 2,
            left: parseInt(el.css('left')),
          }
          el.css(position)
          this.$store.dispatch('details/setPosition', position)
        }
      })
      this.observer.observe($('.modal-content')[0], {
        attributes: true,
        childList: true,
        subtree: true,
      })
    },
    closeMe() {
      if (this.obs && this.obs.nested && this.previous) {
        this.obs = this.previous
        this.type = 'observation'
      } else {
        this.$refs.draggableModal.hide()
      }
    },
    hidden() {
      this.$router.replace({ query: undefined })
      this.observer && this.observer.disconnect()
      delete this.observer
    },
    showDraggable() {
      this.reportIsLoading = false
      this.$refs.draggableModal.show()
    },
    setReportState(event) {
      this.reportIsLoading = event.isWorking
    },
  },
}
</script>

<style scoped>
header.collapsed .when-opened,
header:not(.collapsed) .when-closed {
  display: none;
}
.aggregation-card-header {
  color: #ffffff;
  background: #337ab7;
}
.modal-detail-edit {
  margin-right: 10px;
}
.modal-detail-title-dark {
  color: #ffffff;
  background: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.modal-detail-title-mid {
  color: #ffffff;
  background: #337ab7;
  padding-top: 10px;
  padding-bottom: 10px;
}
.modal-close-icon {
  cursor: pointer;
}
.modal-print-icon {
  cursor: pointer;
}
</style>

<style>
.modal-header.modal-view-header {
  /* do not scope this! */
  padding: 0;
}
</style>

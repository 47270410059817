import BaseService from '@/services/BaseService'
import { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'
import { getCancelToken, WrappedAxiosCanceler } from '@/services/axiosUtils'
import { CardinalDirection } from '@/model/CardinalDirection'
import { CRS } from '@/model/geoJsonModels'
import { FeatureCollection, Point } from 'geojson'

export interface SnowWaterEquivalent {
  obsName: string
  obsGroup?: string | null // format: uuid
  obsDate: string // format: date-time
  country?: string | null // e.g. 'CH'
  locName?: string | null
  coordX: number // format: double
  coordY: number // format: double
  projection?: string | null // default EPSG:21781
  statAbk?: string | null
  snowDepth?: number | null // format: float
  waterEquiv?: number | null // format: integer
}

export interface DetailedProfile {
  profile: ProfileHeader
}

export interface ProfileHeader {
  id: number
  obsDate: string // format: date-time
  statAbk?: string | null
}

export type SDSGeoJson = FeatureCollection<Point, SDSProfileProperties> & {
  crs: CRS
}

export interface SDSProfileProperties {
  id: number
  userId: string // format: uuid
  obsDate: string // format: date-time
  type: 'ECT' | 'RB' | 'FLAT' | 'NOSTABTEST'
  stabilityLevel: 'WEAK' | 'MEDIUM' | 'STRONG'
  color: string // format: '#xxxxxx'
  fractureCloseToSurface: boolean
  elevation: number // format: integer
  aspect: CardinalDirection | 'FLACH' | null
  snowDepth: number // format: float
  ects: ECT[]
  rbs: RB[]
}

export interface ECT {
  layers: ECTLayer[]
}

export interface ECTLayer {
  stabClassification: number // format: integer
  score1: number // format: integer
  score2: number // format: integer
  layerDepth: number // format: float
}

export interface RB {
  score: number // format: integer
  fractureType: number // format: integer
  layerDepth: number // format: float
}

export class SnowprofilerService {
  private static _baseService?: BaseService

  private static get instance(): AxiosInstance {
    const baseService =
      this._baseService || new BaseService('SNOWPROFILER_SERVICE_URL', 5000)
    this._baseService = baseService
    return baseService.instance
  }

  public static async getSnowProfilesSDSByObservationDateTimeRange(
    observationDateTimeRange: string,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<SDSGeoJson> {
    const [dateTimePart, rangePart] = observationDateTimeRange.split('P')

    if (rangePart !== '1D') {
      throw new Error(`Unsupported date range '${rangePart}'`)
    }

    const parsedDateTime = DateTime.fromISO(dateTimePart, { zone: 'utc' })
    const startObsDate = parsedDateTime.startOf('day')
    const endObsDate = parsedDateTime.endOf('day')

    return this.getSnowProfilesSDS(
      startObsDate,
      endObsDate,
      wrappedAxiosCanceler
    )
  }

  public static async getSnowProfilesSDS(
    startObsDate: DateTime,
    endObsDate: DateTime,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<SDSGeoJson> {
    const startDate = startObsDate.toISO()
    const endDate = endObsDate.toISO()
    const result = await this.instance.get<SDSGeoJson>(
      `/profile/sds/geojson?startObsDate=${startDate}&endObsDate=${endDate}`,
      {
        cancelToken: getCancelToken(wrappedAxiosCanceler),
      }
    )
    return result.data
  }

  public static async getDetailSnowProfilesByUserDateTimeRange(
    userId: string,
    startObsDate: DateTime,
    endObsDate: DateTime,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<DetailedProfile[]> {
    if (!userId || !startObsDate || !endObsDate || startObsDate > endObsDate) {
      throw new TypeError('Detail snowprofile: paramters not valid !')
    }
    const startDate = startObsDate.toUTC().toISO()
    const endDate = endObsDate.toUTC().toISO()

    // Format='305D9C0FE8D7444BDC3B4E6C307B67EE'
    const cleanUserId = userId.replace(/-/g, '').toUpperCase()

    const result = await this.instance.get<DetailedProfile[]>(
      `/profile/detailedprofiles?startObsDate=${startDate}&endObsDate=${endDate}&userId=${cleanUserId}`,
      {
        cancelToken: getCancelToken(wrappedAxiosCanceler),
      }
    )
    return result.data
  }

  public static async postSnowWaterEquivalent(
    measurement: SnowWaterEquivalent
  ): Promise<number> {
    return this.instance.post('profile/snowwaterequivalent', measurement)
  }
}

<template>
  <div>
    <!-- DetailHeader -->
    <b-row v-if="assm">
      <b-col cols="12" md="12" class="detailHeader">
        <DetailHeader :headerInfos="headerInfos" />
      </b-col>
    </b-row>
    <!-- Card mit Detail -->
    <div v-for="(item, index) in components" :key="index">
      <b-card :ref="compCard + index" no-body class="mb-1">
        <b-card-header
          header-tag="header"
          class="p-1 card-header"
          role="tab"
          v-b-toggle="detail + index"
        >
          <b-row class="header">
            <b-col cols="1">
              <span class="when-opened">
                <font-awesome-icon icon="fa-solid fa-chevron-up" />
              </span>
              <span class="when-closed">
                <font-awesome-icon icon="fa-solid fa-chevron-down" />
              </span>
            </b-col>
            <b-col class="label text-left">{{
              getDetailTitle(item.typ, null, assms[index])
            }}</b-col>
            <b-col
              v-if="isAllowedToEdit(assms[index], item)"
              class="text-right"
            >
              <b-button
                size="sm"
                @click="editAssessment(assms[index], item)"
                variant="outline-warning"
              >
                <font-awesome-icon icon="fa-solid fa-edit" />{{ $t('ui.edit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse :id="detail + index" :visible="true" role="tabpanel">
          <b-card-body>
            <b-container class="detail-container">
              <component
                :ref="'component' + index"
                class="p-0"
                :is="item.comp"
                :assm="assms[index]"
              />
            </b-container>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import DetailHeader from '../../observation/DetailHeader'
import moment from 'moment'
import {
  DETAIL_COMPONENTS,
  ASSESSMENT_DECISION,
  TRIGGERING,
  MEASURE,
} from '@/scripts/const'
import { i18n } from '@/plugins/i18n'
import assessmentUtil from '../../../scripts/assessmentUtil.js'
import { detailModalReportMixin } from '@/components/reporting/DetailModalReportMixin.js'
import { defineAsyncComponent, markRaw } from 'vue'
import { useAssessmentAggregation } from '@/components/assessment/detail/useAssessmentAggregation'

export default {
  name: 'AssessmentDetail',
  components: {
    DetailHeader,
  },
  mixins: [detailModalReportMixin],
  props: ['assm', 'aggregate'],
  setup() {
    return useAssessmentAggregation()
  },
  data() {
    return {
      components: [{ comp: 'default', typ: '' }],
      user: undefined,
      isAdmin: this.$store.state.user.user.isAdmin,
      headerInfos: {
        name: 'Name unbekannt',
        phone: undefined,
        email: undefined,
        education: undefined,
        avalSafetyService: undefined,
      },
      assms: [],
      detail: 'detail',
      compCard: 'compCard',
      creationDate: undefined,
    }
  },
  async mounted() {
    moment.locale(this.$i18n.locale)
    await this.showAssessment()
  },
  watch: {
    assm: {
      async handler() {
        await this.showAssessment()
      },
      deep: true,
    },
    creationDate: function () {
      this.$emit('setHeader', {
        date: this.creationDate,
        name: this.headerInfos.name,
      })
    },
    'headerInfos.name': function () {
      this.$emit('setHeader', {
        date: this.creationDate,
        name: this.headerInfos.name,
      })
    },
  },
  methods: {
    pdfReport() {
      try {
        this.setPdfReportingInProgress(true)
        const vm = this
        const report = this.getDetailReportBuilder(false)
        const reportObsPromise = this.getReportObs(this.assms, undefined)
        reportObsPromise
          .then((result) => {
            if (!Array.isArray(result)) {
              return this.preLoadImages([result])
            }
            return this.preLoadImages(result)
          })
          .then((reportObs) => {
            report.buildReportDetails(
              reportObs,
              vm.getReportConfigFunctions(),
              false
            )
            vm.startPdfReporting(report.getModelAsJson())
          })
          .catch((err) => {
            this.setPdfReportingInProgress(false)
            this.showPrintError(err)
          })
      } catch (error) {
        this.setPdfReportingInProgress(false)
        this.showPrintError(error)
      }
    },
    getReportConfigFunctions() {
      return {
        detailTitel: this.getDetailTitle,
        headerLabel: this.getDetailDate,
        headerText: this.getHeaderName,
        areaName: this.getAreaName,
        sectorName: this.getSectorName,
        objectName: this.getObjectName,
        avalanchePathName: this.getAvalanchePathName,
      }
    },
    async showAssessment() {
      this.components = [{ comp: 'default', typ: '' }]
      this.assms = []
      const self = this
      if (this.assm && this.assm.assessment) {
        // Measure + Triggering hat auch Details, die nicht in der DB sind
        // Measure + Triggering werden immer einzeln angezeigt
        if (
          this.assm.assessment.type !== MEASURE &&
          this.assm.assessment.type !== TRIGGERING
        ) {
          // Der Rest wird aggregiert, falls verlangt
          const result =
            await this.loadSingleOrAggregatedAssessmentObservations(
              this.assm.assessment.id,
              this.assm.assessment.type,
              this.aggregate
            )
          if (result) {
            this.assms = self.assms.concat(result)
            this.assms.forEach((observation, idx) => {
              // Alle verschiedenen Assessment-Observations anzeigen (Entsprechendes Detail wird dynamisch importiert)
              self.getDetailTyp(observation, idx)
            })
            this.loadUserData()
            this.setDetailDate()
          }
        } else {
          self.assms.push(this.assm.assessment)
          self.showHeaderAndDetail()
        }
      }
    },
    showHeaderAndDetail() {
      this.getDetailTyp(this.assms[0], 0)
      this.loadUserData()
      this.setDetailDate()
    },
    resetUserData() {
      this.headerInfos.name = 'Name unbekannt'
      this.headerInfos.phone = undefined
      this.headerInfos.email = undefined
      this.headerInfos.education = undefined
      this.headerInfos.avalSafetyService = undefined
      this.user = undefined
    },
    getRelevantHeaderInfo() {
      const headerData = { uid: undefined, coord: undefined, place: undefined }
      if (this.assms.length > 0) {
        const content = this.assms[0]
        headerData.uid = content.uid
        headerData.creatorSystem = content.creatorSystem
        for (const prop in content) {
          if (assessmentUtil.isAssessmentType(prop)) {
            const avalService = this.$store.getters[
              'profile/getAssmAvalServiceToId'
            ](content.hierarchy.avalServiceId)
            if (avalService) {
              headerData.avalSafetyService = avalService.name
              return headerData
            }
          }
        }
      }
      return headerData
    },
    loadUserData(headerData) {
      // Find user details based on relevant assessment
      this.resetUserData()
      const self = this
      // eslint-disable-next-line no-param-reassign
      headerData =
        headerData === undefined ? this.getRelevantHeaderInfo() : headerData
      if (headerData.uid) {
        this.user = this.$store.getters['user/getUserToId'](
          headerData.uid,
          headerData.creatorSystem
        )
      }
      if (!this.user && headerData.uid) {
        this.$store
          .dispatch('user/loadContactById', headerData.uid)
          .then(() => {
            self.user = self.$store.getters['user/getUserToIdOrDummyUser'](
              headerData.uid,
              headerData.creatorSystem
            )
            self.createHeaderInfos(headerData)
          })
          .catch(function (error) {
            console.error(error)
          })
      } else {
        this.createHeaderInfos(headerData)
      }
    },
    /*eslint-disable complexity*/
    createHeaderInfos(headerData) {
      if (this.user) {
        this.headerInfos.name = this.user.firstName + ' ' + this.user.lastName
        if (this.isAdmin) {
          this.headerInfos.phone = this.user.mobile
            ? this.user.mobile
            : this.user.phone
          this.headerInfos.email = this.user.email
          this.headerInfos.education = this.user.education
            ? i18n.global.t('user.education.' + this.user.education)
            : null
        }
      }
      if (headerData && headerData.avalSafetyService) {
        this.headerInfos.avalSafetyService = headerData.avalSafetyService
      }
    },
    getDetailTyp(assm, index) {
      for (let i = 0; i < DETAIL_COMPONENTS.length; i++) {
        if (
          assm &&
          (assm.type === DETAIL_COMPONENTS[i] ||
            assm.type === ASSESSMENT_DECISION)
        ) {
          if (index === 0) {
            this.components = []
          }
          let detailComp = DETAIL_COMPONENTS[i]
          if (assessmentUtil.isAssessmentDecisionArea(assm)) {
            detailComp = DETAIL_COMPONENTS[9]
          }
          if (assessmentUtil.isAssessmentDecisionSector(assm)) {
            detailComp = DETAIL_COMPONENTS[10]
          }
          this.importComponent(detailComp)
        }
      }
    },
    importComponent(compName) {
      let compImport
      let detailTyp
      let storeToSelect
      switch (compName) {
        case DETAIL_COMPONENTS[2]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../../danger/View.Details'))
          )
          detailTyp = DETAIL_COMPONENTS[2]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[6]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../../attachment/View.Details'))
          )
          detailTyp = DETAIL_COMPONENTS[6]
          storeToSelect = 'observation'
          break
        case DETAIL_COMPONENTS[9]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('../measureassessment/View.AssessmentArea')
            )
          )
          detailTyp = DETAIL_COMPONENTS[9]
          storeToSelect = 'assessmentObs'
          break
        case DETAIL_COMPONENTS[10]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('../measureassessment/View.AssessmentSector')
            )
          )
          detailTyp = DETAIL_COMPONENTS[10]
          storeToSelect = 'assessmentObs'
          break
        case DETAIL_COMPONENTS[11]:
          compImport = markRaw(
            defineAsyncComponent(() =>
              import('@/components/assessment/situation/View.Details.vue')
            )
          )
          detailTyp = DETAIL_COMPONENTS[11]
          storeToSelect = 'assessmentObs'
          break
        case DETAIL_COMPONENTS[12]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../evaluation/View.Evaluation'))
          )
          detailTyp = DETAIL_COMPONENTS[12]
          storeToSelect = 'assessmentObs'
          break
        case DETAIL_COMPONENTS[13]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../../measure/View.Measure'))
          )
          detailTyp = DETAIL_COMPONENTS[13]
          storeToSelect = 'measure'
          break
        case DETAIL_COMPONENTS[14]:
          compImport = markRaw(
            defineAsyncComponent(() => import('../../measure/View.Triggering'))
          )
          detailTyp = DETAIL_COMPONENTS[14]
          storeToSelect = 'triggering'
          break
        default:
          compImport = 'default'
          detailTyp = DETAIL_COMPONENTS[0]
          break
      }
      this.components.push({
        comp: compImport,
        typ: detailTyp,
        storeToSel: storeToSelect,
      })
    },
    editAssessment(assessment, componentsItem) {
      if (componentsItem && componentsItem.storeToSel) {
        this.$store.commit(componentsItem.storeToSel + '/SELECT', assessment)
        this.$store.commit('SET_EXCEPTION', null)
        this.$store.commit(
          'profile/SET_ASSMAVALSERVICE',
          assessment.hierarchy.avalServiceId
        )

        switch (componentsItem.typ) {
          case DETAIL_COMPONENTS[9]:
          case DETAIL_COMPONENTS[10]:
            this.$router.push('/assessment/measureAssessment')
            break
          case DETAIL_COMPONENTS[11]:
            this.$router.push('/assessment/areaAssessment')
            break
          case DETAIL_COMPONENTS[12]:
            this.$router.push('/assessment/areaAssessment/section')
            break
          case DETAIL_COMPONENTS[13]:
            this.$router.push(`/measure/input/${assessment.id}`)
            break
          default:
            this.$router.push('/assessment/' + componentsItem.typ)
        }
        this.$bvModal.hide(this.detId)
      }
    },
    /*eslint-enable complexity*/
    isAllowedToEdit(assessment) {
      if (
        assessment &&
        assessment.management &&
        assessment.management.canWrite &&
        this.isMyAvalServiceId(assessment.hierarchy)
      ) {
        return assessment.management.canWrite
      }
      return false
    },
    isMyAvalServiceId(hierarchy) {
      const avalService = this.$store.getters['profile/getAssmAvalServiceToId'](
        hierarchy.avalServiceId
      )

      return avalService !== null && avalService !== undefined
    },
    getDetailTitle(itemTyp, dummy, assm) {
      if (!itemTyp) {
        return 'undefined'
      }
      let assmDateString
      if (assm && assm.date && !this.isSameDate(assm.date, this.creationDate)) {
        assmDateString = moment(assm.date).format('DD.MM.YYYY')
      }
      let typeName = i18n.global.t('assessment.ui.' + itemTyp)
      typeName = assessmentUtil.getAssessmentTypeName(itemTyp, assm, typeName)
      return (
        typeName +
        (assmDateString
          ? ' (' +
            i18n.global.t('assessment.shortName.assessment') +
            ': ' +
            assmDateString +
            ')'
          : '')
      )
    },
    isSameDate(dateOne, dateTwo) {
      return moment(dateOne, 'YYYY-MM-DD').isSame(
        moment(dateTwo, 'DD.MM.YYYY'),
        'day'
      )
    },
    getLatestAssessment(assessments) {
      // NOTE: only one element at this time...
      return assessments[0]
    },
    setDetailDate() {
      if (this.assm) {
        this.creationDate = this.detailDateFormatter(
          this.getLatestAssessment(this.assms)
        )
      } else {
        this.creationDate = undefined
      }
    },
    getDetailDate() {
      return this.creationDate
    },
    detailDateFormatter(assm) {
      return moment(assm.cTimestamp).format('DD.MM.YYYY HH:mm')
    },
  },
}
</script>

<style scoped>
header.collapsed .when-opened,
header:not(.collapsed) .when-closed {
  display: none;
}
.card-header {
  color: #ffffff;
  background: #337ab7;
}
</style>

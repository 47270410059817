'use strict'

import ReportElement from '@/model/report/ReportElement.js'

export default class Report {
  constructor(title, subtitle, avalancheServiceName, compactPdfPreparation) {
    this.compactPdfPreparation = compactPdfPreparation
    this.avalancheServiceName = avalancheServiceName
    this.title = title
    this.subtitle = subtitle
    this.elements = []
  }

  /*
    Erzeugt die Report-Rohdaten. Übergeben wird ein Array von allgemeinen
    Beobachtungen, wie z.B. im AssessmentDetail.vue od. ObservationDetail.vue
    Wird sortByTimestamp=true gesetzt, werden die Beobachtungen nach dem Timestamp neu sortiert
   */
  buildReportDetails(reportElements, reportConfigFuncs, sortByTimestamp) {
    let sortedEles = reportElements
    if (sortByTimestamp) {
      sortedEles = this.sortReportElements(reportElements)
    }
    this.elements = ReportElement.buildReportElements(
      sortedEles,
      reportConfigFuncs
    )
  }

  sortReportElements(reportElements) {
    return reportElements.sort((a, b) => a.cTimestamp - b.cTimestamp)
  }

  getModelAsJson() {
    const reportObj = {
      avalancheServiceName: this.avalancheServiceName,
      title: this.title,
      subtitle: this.subtitle,
      compactPdfPreparation: this.compactPdfPreparation,
      elements: [],
    }
    this.elements.forEach((ele) => {
      reportObj.elements.push(ele.getModelAsObj())
    })
    return JSON.stringify(reportObj)
  }
}

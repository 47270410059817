<template>
  <b-form-group
    id="avalservice-select-form-group"
    v-show="avalServOptions.length > 1"
    :label="$t('assessment.ui.avalSafetyServiceShort')"
    label-cols-sm="4"
    label-align-sm="right"
    label-size="sm"
    label-for="avalservice-select"
  >
    <b-form-select
      id="avalservice-select"
      size="sm"
      :value="selAvalService"
      :options="avalServOptions"
      @change="onAvalServiceChange"
    />
  </b-form-group>
</template>

<script lang="ts">
import { AvalancheSafetyService } from '@/model/assessment/interfaces/AvalancheSafetyService'
import { defineComponent, PropType } from 'vue'

interface AvalServiceOptions {
  value: string | null
  text: string
}

export default defineComponent({
  name: 'AvalServiceSelect',
  props: {
    loaded: {
      type: Boolean as PropType<boolean | null>,
      default: null,
      required: false,
    },
    selAvalService: {
      type: String as PropType<string | null>,
      default: null,
      required: false,
    },
  },
  watch: {
    loaded: {
      handler: function () {
        this.onSelectedStationChange()
      },
      immediate: true,
    },
  },
  computed: {
    avalServOptions(): AvalServiceOptions[] {
      const options: AvalServiceOptions[] = [{ value: null, text: '-' }]
      options.push(
        ...this.$store.state.profile.avalancheServices.map(
          (fullAvalSafetyObj: AvalancheSafetyService) => {
            return {
              value: fullAvalSafetyObj.id,
              text: fullAvalSafetyObj.name,
            }
          }
        )
      )
      return this.sortAvalServicesByName(options)
    },
  },
  methods: {
    async onSelectedStationChange(): Promise<void> {
      if (this.loaded) {
        // Nur bei loadad = true etwas nachen. Wenn der Dialog neu geladen wird
        // (Datumswechsel), muss das Filterkriteriem erneut ausgelöst werden.
        this.onAvalServiceChange(this.selAvalService)
      }
    },
    sortAvalServicesByName(selectOptions: AvalServiceOptions[]) {
      return selectOptions.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      )
    },
    onAvalServiceChange(value: string | null) {
      this.$emit('avalServiceChanged', value)
    },
  },
})
</script>

<style scoped></style>

/*eslint-disable max-lines*/
import { DateTime } from 'luxon'
import {
  BULLETIN_LEVDET_MINUS,
  BULLETIN_LEVDET_NEUTRAL,
  BULLETIN_LEVDET_PLUS,
  BULLETIN_TYPE_MAP,
} from './bulletinUtil'
import {
  Dangerlevel,
  SimpleBulletin,
  SimpleDangerRegion,
  SimpleDangerRegionCollection,
  BULLETIN_RATING_1,
  BULLETIN_RATING_2,
} from './modelV2'
import {
  DangerRegionCollection,
  AvalancheBulletin,
  DangerRating,
  ValidTime,
} from './modelV3'

export interface Response {
  data: Record<string, unknown> | SimpleDangerRegionCollection
  status: number
  statusText: string
  headers: object
  config: object
  request: object
}

export class ResponseError extends Error {
  response: Response

  constructor(r: Response) {
    super(r.statusText)
    this.response = r
  }
}

const AVAL_PROBLEMS = new Map([
  ['new_snow', 'NEW_SNOW'],
  ['wind_slab', 'SNOW_DRIFT'],
  ['persistent_weak_layers', 'OLD_SNOW'],
  ['wet_snow', 'WET_SNOW_AVALANCHE'],
  ['gliding_snow', 'GLIDING_AVALANCHE'],
  ['no_distinct_avalanche_problem', 'NO_DISTINCT_PATTERN'],
  ['favourable_situation', 'P_FAVOURABLE_SITUATION'],
])

const MAIN_VALUES2LEVEL = new Map([
  ['low', '1_LOW'],
  ['moderate', '2_MODERATE'],
  ['considerable', '3_CONSIDERABLE'],
  ['high', '4_HIGH'],
  ['very_high', '5_VERY_HIGH'],
])

const MAIN_VALUES2NUMBER = new Map([
  ['low', 1],
  ['moderate', 2],
  ['considerable', 3],
  ['high', 4],
  ['very_high', 5],
])

const SUBDIVISON2LEVEL_DETAIL = new Map([
  ['plus', BULLETIN_LEVDET_PLUS],
  ['minus', BULLETIN_LEVDET_MINUS],
  ['neutral', BULLETIN_LEVDET_NEUTRAL],
])

const SUBDIVISON2NUMBER = new Map([
  ['plus', 3],
  ['minus', 2],
  ['neutral', 1],
])

/**
 * Convert the given v3 respons into a V2 response.
 *
 * @param v3Response the v3 reponse object
 * @param rating the requested rating
 * @param timestamp the requested timestamp
 * @returns the correspondending v2 response with a reduced data set
 */
export function asV2Response(
  v3Response: Response,
  rating: number,
  timestamp: string
): Response {
  let drc = v3Response?.data as never as DangerRegionCollection

  if (!drc || !Array.isArray(drc.features) || drc.features.length === 0) {
    throw notFoundResponseError(v3Response)
  }

  drc = removeOutDatedDangerRegions(drc, timestamp)

  if (!hasDangerratingCollectionRating(drc, rating)) {
    throw notFoundResponseError(v3Response)
  }

  const v2: SimpleDangerRegionCollection = {
    type: 'FeatureCollection',
    features: [],
  }

  drc.features.forEach((dr) => {
    if (supportsAvalancheBulletinRating(dr.properties, rating)) {
      const sdr: SimpleDangerRegion = {
        type: dr.type,
        geometry: structuredClone(dr.geometry),
        id: dr.id + 1,
        properties: avalancheBulletin2SimpleBulletin(dr.properties, rating),
      }
      v2.features.push(sdr)
    }
  })

  return {
    data: v2,
    status: 200,
    statusText: 'OK',
    headers: {
      'Content-Type': 'application/json',
    },
    config: v3Response.config,
    request: v3Response.request,
  }
}

function avalancheBulletin2SimpleBulletin(
  bulletin: AvalancheBulletin,
  rating: number
): SimpleBulletin {
  const majorAvalProblems: string[] = []

  bulletin.avalancheProblems.forEach((ap) => {
    if (rating === BULLETIN_RATING_2 || ap.validTimePeriod === 'all_day') {
      const t = AVAL_PROBLEMS.get(ap.problemType)
      if (t) {
        majorAvalProblems.push(t)
      }
    }
  })

  const dr = toDangerLevel(bulletin.dangerRatings, rating)

  return {
    bulletin_type: BULLETIN_TYPE_MAP,
    valid_from: bulletin?.validTime?.startTime,
    valid_to: bulletin?.validTime?.endTime,
    next_update: bulletin?.nextUpdate,
    rating: {
      dangerlevel: dr,
      majorAvalProblems: majorAvalProblems,
    },
    fill:
      rating === BULLETIN_RATING_1
        ? bulletin.fillEarlier || bulletin.fill
        : bulletin.fill,
  }
}

function toDangerLevel(
  dangerRatings: DangerRating[],
  rating: number
): Dangerlevel {
  const dr =
    rating === BULLETIN_RATING_1
      ? dangerRatings.find((r) => r.validTimePeriod === 'all_day')
      : maxDangerRating(dangerRatings)

  if (!dr) {
    return { level: '' }
  }

  let s = dr?.customData?.CH?.subdivision
  if (s) {
    s = SUBDIVISON2LEVEL_DETAIL.get(s)
  }

  return {
    level: MAIN_VALUES2LEVEL.get(dr.mainValue) || '',
    level_detail: s,
  }
}

function maxDangerRating(dangerRatings: DangerRating[]): DangerRating {
  if (dangerRatings.length === 1) {
    return dangerRatings[0]
  }

  /*eslint-disable-next-line complexity */
  return dangerRatings.sort((a, b) => {
    const aln = MAIN_VALUES2NUMBER.get(a.mainValue) || 0
    const bln = MAIN_VALUES2NUMBER.get(b.mainValue) || 0
    let c = bln - aln

    if (c === 0) {
      const asn =
        SUBDIVISON2NUMBER.get(a?.customData?.CH?.subdivision || '') || 0
      const bsn =
        SUBDIVISON2NUMBER.get(b?.customData?.CH?.subdivision || '') || 0
      c = bsn - asn
    }

    return c
  })[0]
}

function removeOutDatedDangerRegions(
  drc: DangerRegionCollection,
  timestamp: string
): DangerRegionCollection {
  const t = DateTime.fromISO(timestamp)
  drc.features = drc.features.filter((dr) =>
    isInValidTime(dr.properties?.validTime, t)
  )
  return drc
}

function isInValidTime(validTime: ValidTime, timestamp: DateTime): boolean {
  if (!validTime || !validTime.endTime || !validTime.startTime) {
    return false
  }

  const s = DateTime.fromISO(validTime.startTime)
  const e = DateTime.fromISO(validTime.endTime)
  return s <= timestamp && timestamp <= e
}

/**
 * Returns true if at least one danger region constains a danger rating for
 * the requested rating.
 *
 * @param drc the danger region collection
 * @param rating the rating number
 */
function hasDangerratingCollectionRating(
  drc: DangerRegionCollection,
  rating: number
): boolean {
  for (let i = 0; i < drc.features.length; i++) {
    const bulletin = drc.features[i].properties

    if (hasAvalancheBulletinRating(bulletin, rating)) {
      return true
    }
  }

  return false
}

/**
 * Test if the bulletin has a explicite estimation for the specified rating.
 *
 * For rating === 1 at least on 'all_day' valid time period must exist.
 * For rating === 2 at least on 'later' valid time period must exist.
 *
 * @param bulletin the bulletin to test
 * @param rating the requested rating
 * @returns 'true' if the bulletin has an according rating, 'false' otherwise.
 */
function hasAvalancheBulletinRating(
  bulletin: AvalancheBulletin,
  rating: number
): boolean {
  if (!bulletin) {
    return false
  }

  const rs = bulletin.dangerRatings

  if (!Array.isArray(rs) || rs.length === 0) {
    return false
  }

  return !!(rating === BULLETIN_RATING_2
    ? rs.find((r) => 'later' === r.validTimePeriod)
    : rs.find((r) => 'all_day' === r.validTimePeriod))
}

/**
 * Test if the bulletin supports the spedified rating.
 *
 * For rating === 1 at least on 'all_day' estimation must exist.
 * For rating === 2 one estimation must exists. This can be a 'all_da' or a 'later' value.
 *
 * @param bulletin the bulletin to test
 * @param rating  the requested rating
 * @returns 'true' if the bulletin supports an according rating, 'false' otherwise.
 */
function supportsAvalancheBulletinRating(
  bulletin: AvalancheBulletin,
  rating: number
): boolean {
  if (!bulletin) {
    return false
  }

  const rs = bulletin.dangerRatings

  if (!Array.isArray(rs) || rs.length === 0) {
    return false
  }

  if (rating === BULLETIN_RATING_2) {
    return true
  }

  return !!rs.find((r) => 'all_day' === r.validTimePeriod)
}

function notFoundResponseError(v3Response: Response): ResponseError {
  return new ResponseError({
    data: { code: 404, type: 'error', message: 'Bulletin not found' },
    status: 404,
    statusText: 'Not Found',
    headers: {
      'Content-Type': 'application/json',
      'Content-Length': 58,
    },
    config: v3Response.config,
    request: v3Response.request,
  })
}

'use strict'

export default class ImageEntryBuilder {
  static buildImageEntries(obsEle) {
    const images = []
    if (
      obsEle &&
      obsEle[obsEle.type] &&
      obsEle[obsEle.type].imgMetas &&
      Array.isArray(obsEle[obsEle.type].imgMetas)
    ) {
      obsEle[obsEle.type].imgMetas.forEach((meta) => {
        const img = {
          title: meta.description ? meta.description : meta.fileName,
          src: meta.url,
        }
        images.push(img)
      })
    }
    return images
  }
}

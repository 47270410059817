<template>
  <div class="row">
    <div class="col-lg">
      <NewSnowLegend :legend="legend"></NewSnowLegend>
      <CommonLayersPopup></CommonLayersPopup>
    </div>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal detId="detailModal" :obsIds="obsIds" :aggregate="true" />
  </div>
</template>

<script>
import MeasurementStyle from '../styles/snowStyling/MeasurementStyle'
import NewSnowLegend from '../Legend'
import DetailModal from '../observation/DetailModal'
import moment from 'moment'
import { Canceler } from '@/scripts/requestUtil.js'
import CommonLayersPopup from '../CommonLayersPopup'
import { stationDetailMixin } from '@/components/snow/stationDetailMixin'
import emitter from '@/components/EventHub'

const Cancel = Canceler.getCancelObj()

export default {
  name: 'NewSnow',
  components: {
    CommonLayersPopup,
    NewSnowLegend,
    DetailModal,
  },
  mixins: [stationDetailMixin],
  mounted() {
    this.refresh()
    emitter.on('repos::map', (flag) => {
      this.isLoading = flag && flag === true
    })
  },

  beforeUnmount() {
    emitter.off('update::filter')
    emitter.off('repos::map')
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },

  props: ['moment'],
  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },
  data() {
    return {
      obsIds: [],
      lastMoment: undefined,
      lastDatePreset: undefined,
      isLoading: false,
    }
  },

  computed: {
    legend() {
      return MeasurementStyle('hn24h').legend('1d')
    },
  },

  methods: {
    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },

    findAndShowObservation(observationId, featureCollection) {
      this.obsIds = []
      const index = featureCollection.features.findIndex((item) => {
        return item.properties.id === observationId
      })
      if (index > -1) {
        this.updatableModal = moment().isSame(
          moment(featureCollection.features[index].timestamp),
          'd'
        )
        this.obsIds.push(observationId)
        this.$bvModal.show('detailModal')
      }
    },

    refresh() {
      this.isLoading = true
      console.debug('Cancel requests on load')
      this.cancelRequests()
      const self = this

      const clickHandler = this.getStationClickHandler(this)
      const resolutionChangeHandler = function (resolution, layers, spider) {
        spider.resolutionChangeHandler(layers['hn24h'].vectorLayer, resolution)
      }
      let featureCollection = null

      const cancelObj = { cancel: null }
      const startDate = moment(
        Object.assign({}, this.$store.state.calendar.moment)
      ).startOf('day')
      this.$store
        .dispatch('snow/loadSnowObservationsByDateTimeInterval', {
          param: 'hn24h',
          startDate: startDate,
          duration: 1440,
          cancel: cancelObj,
        })
        .then(() => {
          const snowFeatureCollection = Object.assign(
            {},
            self.$store.state.snow.snowFeatureCollection
          )
          featureCollection = this.aggregate(
            featureCollection,
            snowFeatureCollection
          )
          emitter.emit('layer', {
            name: 'hn24h',
            features: featureCollection,
            styleFunction: MeasurementStyle('hn24h').styleFunction,
            onClick: clickHandler,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit('mouseOver', MeasurementStyle('hn24h').mouseOverFunction)
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }
          self.isLoading = false
        })
        .catch((reason) => {
          console.error('Catching promise (' + reason + ').')
          self.isLoading = false
        })
      Cancel.addCancel(cancelObj.cancel)
    },

    aggregate(fc1, fc2) {
      let fcAggregated
      if (fc1) {
        fcAggregated = { ...fc1 }
        fcAggregated.features = fcAggregated.features.concat(fc2.features)
      } else {
        fcAggregated = { ...fc2 }
      }
      return fcAggregated
    },

    cancelRequests() {
      Cancel.cancelAll()
    },
  },
}
</script>

<style scoped>
img {
  width: 600px;
}
.b-form-group {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 10px 0 10px;
  border: #929292 1px solid;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #494949;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

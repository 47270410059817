import { FeatureLike } from 'ol/Feature'
import { DateTime } from 'luxon'
import { BulletinTime } from '@/model/dangerModels'
import { i18n, KnownLanguages } from '@/plugins/i18n'
import { EnvironmentService } from '@/services/EnvironmentService'
import { ExportedGlobalComposer } from 'vue-i18n'

export const dangerDetailMixin = {
  url: '',
  methods: {
    showDangerDetail(
      validFrom: DateTime,
      locale: KnownLanguages,
      bulletinRegionId: string | undefined,
      bulletinTime: BulletinTime
    ): void {
      if (!validFrom.isValid) {
        return
      }

      let stationUrl
      if (this.isWhiteriskBulletin(validFrom)) {
        stationUrl = this.getWhiteriskBulletinUrl(
          locale,
          validFrom,
          bulletinRegionId,
          bulletinTime
        )
      } else {
        stationUrl = this.getArchiveUrlFor(locale)
      }

      window.open(
        stationUrl,
        '_blank',
        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
      )
      dangerDetailMixin.url = stationUrl
    },
    getWhiteriskBulletinUrl(
      locale: string,
      validFrom: DateTime,
      bulletinRegionId: string | undefined,
      bulletinTime: BulletinTime
    ): string {
      const result = `${this.getWhiteriskBaseUrl()}/${locale}/conditions/bulletin?activeAt=${validFrom.toUTC()}&bulletinTime=${bulletinTime}`
      // TODO: (RegionId-1) ist ein Workarround um von der alten auf die neue Schnittstelle zu switchen (WB -> WR).
      // Wenn die WB auch auf der neuen Bulletin-Schnittstelle ist, kann/muss das -1 wieder entfernt werden
      return bulletinRegionId != null
        ? `${result}&regionId=${(Number(bulletinRegionId) - 1).toString()}`
        : result
    },
    getArchiveUrlFor(locale: KnownLanguages): string {
      i18n.global.locale = locale
      return `${this.getSLFBaseUrl()}/${locale}/${i18n.global.t(
        'danger.avalancheBulletinArchiveUrl'
      )}/`
    },
    isWhiteriskBulletin(validFrom: DateTime): boolean {
      // Ältere Bulletins, bezogen auf diesen Stichtag, werden nicht mehr korrekt
      // dargestellt. Daher wird in diesem Fall das Archiv angezeigt.
      return validFrom >= DateTime.fromISO('2023-09-18')
    },
    getDangerClickHandler(
      i18n: ExportedGlobalComposer,
      bulletinTime: BulletinTime
    ): (feature: FeatureLike | null | undefined) => void {
      return (feature: FeatureLike | null | undefined) => {
        if (!feature) {
          return
        }
        const validFrom: DateTime = DateTime.fromISO(feature.get('valid_from'))
        this.showDangerDetail(
          validFrom,
          i18n.locale as never as KnownLanguages,
          feature.getId()?.toString(),
          bulletinTime
        )
      }
    },
    getWhiteriskBaseUrl(): string {
      return EnvironmentService.getVariable('WHITERISK_URL')
    },
    getSLFBaseUrl(): string {
      return EnvironmentService.getVariable('SLF_URL')
    },
  },
}

<template><span /></template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'DetailModalAssessment',
  props: ['assessment', 'aggregate'],
  watch: {
    assessment: function () {
      emitter.emit('showAssessment', {
        assessment: this.assessment,
        aggregate: this.aggregate,
      })
    },
  },
}
</script>

<template>
  <b-container fluid style="pointer-events: all" :style="cssProps">
    <!-- Object w/ latest measures tables per avalanche service, area. No grouping by sector -->
    <b-card
      v-for="serviceObject in groupedObjects"
      :key="serviceObject.id"
      class="t-aval-safety-service"
    >
      <b-card-title>
        {{ serviceObject.name }}
      </b-card-title>

      <template
        v-for="area in sortedAreas(serviceObject.areas)"
        :key="area.id + 'bs'"
      >
        <br />
        <b-card-sub-title>
          {{ area.name }}
        </b-card-sub-title>
        <br />
        <b-table
          small
          hover
          bordered
          responsive
          sticky-header="300px"
          :items="area.avalanchePaths"
          :fields="avalanchePathFields"
          show-empty
          :empty-text="$t('measure.label.emptyState')"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="table-font t-area"
          tbody-tr-class="area-avalanche-path-row"
          head-variant="light"
          @row-clicked="showTriggeringDetails"
        >
          <template v-slot:head()="data">
            <span class="font-weight-normal">{{ data.label }}</span>
          </template>
          <template v-slot:[`cell(triggeringObs.triggering.planned)`]="data">
            {{ data.item.triggeringObs ? formatTimestamp(data.value, '') : ''
            }}<span v-show="notValidYet(data)" class="table-warning-text"
              >&nbsp;!</span
            >
          </template>
          <template v-slot:[`cell(triggeringObs.triggering.reason)`]="data">
            <div class="text-truncate text-ellipse">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div style="overflow: hidden; white-space: nowrap">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <b-button
                size="sm"
                @click.stop="
                  addTriggering(area, row.item, row.index, $event.target)
                "
                :title="$t('ui.edit')"
                class="mr-2 wrap-ic edit-button"
                style="display: inline-block"
              >
                <font-awesome-icon icon="fa-solid fa-edit"></font-awesome-icon>
              </b-button>
              <b-button
                :disabled="hasTriggeringObs(row.item)"
                size="sm"
                @click.stop="deleteTriggering(row.item)"
                :title="$t('triggering.label.suspension')"
                class="mr-2 wrap-ic delete-button"
                style="display: inline-block"
              >
                <font-awesome-icon
                  icon="fa-regular fa-trash-alt"
                ></font-awesome-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </b-card>

    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assessment"
      :aggregate="false"
    />
  </b-container>
</template>

<script>
import moment from 'moment'
import DetailModalAssessment from '@/components/assessment/detail/DetailModalAssessment'
import { COLOR_MEDIUM } from '../styles/AbstractStyle'
import util from '@/scripts/util.js'
import { Canceler } from '@/scripts/requestUtil.js'

const canceler = Canceler.getCancelObj()

export default {
  name: 'AvalancheTriggeringTable',
  components: { DetailModalAssessment },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      avalanchePathFields: [
        {
          key: 'name',
          label: this.$t('triggering.label.avalanchePath'),
          sortable: true,
          class: 'table-avalanche-path-cell',
          tdClass: (value, key, item) => {
            return this.findClass(item.triggeringObs)
          },
        },
        { key: 'actions', label: '', class: 'table-action-cell' },
        {
          key: 'triggeringObs.triggering.blastingMethod',
          label: this.$t('triggering.label.blastingMethod'),
          sortable: true,
          sortByFormatted: true,
          class: 'table-blasting-method-cell',
          formatter: this.measureFormatter,
        },
        {
          key: 'triggeringObs.triggering.planned',
          label: this.$t('triggering.label.planned'),
          sortable: true,
          sortByFormatted: this.sortFormatTS,
          class: 'table-planned-cell',
        },
        {
          key: 'triggeringObs.triggering.executor',
          label: this.$t('triggering.label.executor'),
          class: 'table-executor-cell',
        },
        {
          key: 'triggeringObs.triggering.reason',
          label: this.$t('triggering.label.reason'),
          class: 'table-reason-cell',
        },
      ],
      latestTriggerings: [],
      assessment: {},
      sortFormatTS: this.sortFormatTimestamp,
    }
  },
  mounted() {
    this.$store.dispatch('calendar/setActive', false)
    this.$store.dispatch('calendar/setPreset', 'current')
    this.refresh()
  },
  beforeUnmount() {
    this.$store.dispatch('calendar/setActive', true)
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },
  computed: {
    groupedObjects() {
      const assAvalserviceObjects =
        this.$store.state.profile.assmAvalServices.map((el) => el.filter())
      this.removeProps(assAvalserviceObjects, ['location'])
      const avalanchePathList = assAvalserviceObjects
        .reduce((acc, curr) => acc.concat(curr.areas), [])
        .reduce((acc, curr) => {
          return curr.avalanchePaths ? acc.concat(curr.avalanchePaths) : acc
        }, [])

      if (
        avalanchePathList &&
        avalanchePathList.length > 0 &&
        avalanchePathList[0] !== undefined
      ) {
        avalanchePathList.forEach((avalanchePath) => {
          const triggeringObs = this.latestTriggerings.find(
            (el) => el.hierarchy.avalanchePathId === avalanchePath.id
          )
          triggeringObs ? (avalanchePath.triggeringObs = triggeringObs) : null
        })
      }
      return assAvalserviceObjects
    },
    cssProps() {
      // NOTE: use rgba for edge w/ opacity; precondition: 6-char hex input
      // Source: https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/
      return {
        '--bg-yellow-color': util.convertHexToRgbA(COLOR_MEDIUM, 0.75),
        '--bg-yellow-R-color': this.hexPortion(COLOR_MEDIUM, 1, 2),
        '--bg-yellow-G-color': this.hexPortion(COLOR_MEDIUM, 3, 2),
        '--bg-yellow-B-color': this.hexPortion(COLOR_MEDIUM, 5, 2),
        /* the threshold at which colors are considered "light". Range: decimals from 0 to 1, recommended 0.5 - 0.6 */
        '--threshold': 0.5,
      }
    },
    avalServiceIds() {
      return this.$store.getters['profile/getAllAvalServiceIds']
    },
  },
  methods: {
    refresh() {
      this.cancelRequests()
      const cancelObj = { cancel: null }
      const params = {
        avalServiceIds: this.avalServiceIds(),
        cancel: cancelObj,
      }
      this.$store
        .dispatch('triggering/loadLatestTriggerings', params)
        .then((triggerings) => {
          this.latestTriggerings = triggerings
          canceler.addCancel(cancelObj.cancel)
        })
        .catch((e) => {
          console.error(
            "An exception occurred by loading 'triggering/loadLatestTriggerings': " +
              e
          )
          canceler.addCancel(cancelObj.cancel)
        })
    },
    addTriggering(area, avalanchePath) {
      this.$router.push({
        name: 'AvalancheTriggeringInput',
        params: { avalanchePathId: avalanchePath.id, areaId: area.id },
      })
    },
    measureFormatter(value, key, item) {
      let result = ''
      if (
        item.triggeringObs &&
        item.triggeringObs.triggering &&
        item.triggeringObs.triggering.blastingMethod
      ) {
        item.triggeringObs.triggering.blastingMethod.forEach((el) => {
          if (result.length > 0) {
            result = result.concat(', ')
          }
          result = result.concat(
            el ? this.$i18n.t(`triggering.blastingMethods.${el}`) : ''
          )
        })
      }
      return result
    },
    formatTimestamp(value, nullText) {
      return value ? moment(value).format('DD.MM.YYYY HH:mm') : nullText
    },
    // use this for formatting w/ secs
    sortFormatTimestamp(value) {
      return value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
    },
    removeProps(obj, props) {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          switch (typeof obj[prop]) {
            case 'object':
              if (props.indexOf(prop) > -1) {
                delete obj[prop]
              } else {
                this.removeProps(obj[prop], props)
              }
              break
            default:
              if (props.indexOf(prop) > -1) {
                delete obj[prop]
              }
              break
          }
        }
      }
    },
    notValidYet(data) {
      return data.item.measure && moment(data.value).isAfter(moment())
    },
    findClass(triggeringObs) {
      if (
        triggeringObs &&
        triggeringObs.triggering &&
        triggeringObs.triggering.blastingMethod &&
        triggeringObs.triggering.blastingMethod.length > 0
      ) {
        return 'bg-yellow'
      }
    },
    hexPortion(hexColor, fromInd, len) {
      return parseInt(hexColor.substr(fromInd, len), 16)
    },
    showTriggeringDetails(item) {
      if (item.triggeringObs && item.triggeringObs.triggering) {
        this.assessment = this.$store.getters[
          'triggering/getTriggeringForDetailModal'
        ](item.triggeringObs)
        this.$bvModal.show('detailModalAssessment')
      }
    },
    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },
    deleteTriggering(rowItem) {
      this.$router.push({
        name: 'AvalancheTriggeringDelete',
        params: { parent: JSON.stringify(rowItem.triggeringObs) },
      })
    },
    hasTriggeringObs(rowItem) {
      return (
        !rowItem.triggeringObs ||
        !rowItem.triggeringObs.triggering ||
        !rowItem.triggeringObs.triggering.blastingMethod
      )
    },
    sortedAreas(areaList) {
      return [...areaList].sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}
</script>

<style>
.table-font {
  font-size: 92%;
}
.table-warning-text {
  color: rgb(255, 0, 0);
  font-weight: bold;
}
.table-avalanche-path-cell {
  width: 280px;
}
.table-reason-cell {
  width: 370px;
}
.table-action-cell {
  width: 1px;
}
.text-ellipse {
  text-overflow: ellipsis;
  width: 350px;
}
.bg-yellow {
  background: var(--bg-yellow-color);
  --r: calc(var(--bg-yellow-R-color) * 0.2126);
  --g: calc(var(--bg-yellow-G-color) * 0.7152);
  --b: calc(var(--bg-yellow-B-color) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}
</style>

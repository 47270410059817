<template>
  <b-container class="container">
    <b-card
      :header="$t('ui.feedback.studyplotManagement.title')"
      :title="$t('ui.feedback.studyplotManagement.searchTitle')"
    >
      <b-row class="line">
        <b-col md="3" class="value">
          <b-form-group :label="$t('station.id')" label-cols-md="6">
            <b-form-select
              v-model="selectedStudyplotCode"
              :options="studyplotOptions"
              @change="changedStudyplotSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="7" class="label">
          <b-form-group
            :label="$t('ui.feedback.studyplotManagement.searchDate')"
            label-cols-md="2"
            class="studyplot-calendar-picker-container"
          >
            <calendar-component
              class="w-100"
              v-model="studyplotCalendarDate"
              :max="maxDate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="snowInputCode && snowInputDate" class="line">
        <b-col cols="12">
          <SnowInput
            :key="snowInputRecreationKey"
            :code="snowInputCode"
            type="morning"
            isManagmentMode="true"
            :createDate="snowInputDate"
            v-on:submitted="dataSubmitted()"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
// Wenn eine nicht TS-Komponente importiert wird, kann nicht mit TS gearbeitet werden.
// Daher macht es keinen Sinn die Class-Component-Syntax zu verwenden
import SnowInput from './Input'
import CalendarComponent from '@/components/calendar/CalendarComponent'
import { DateTime } from 'luxon'
import { StudyplotService } from '@/services/StudyplotService'

export default {
  name: 'StudyplotInputManagement',
  components: { SnowInput, CalendarComponent },
  data() {
    return {
      maxDate: DateTime.now().toISO(),
      selectedStudyplotCode: undefined,
      selectedStudyplotDate: DateTime.now(),
      snowInputCode: undefined,
      snowInputDate: undefined,
      snowInputRecreationKey: '',
    }
  },
  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)
  },
  computed: {
    studyplotOptions() {
      return this.$store.getters['studyplot/getActiveStudyplotCodes']
    },
    studyplotCalendarDate: {
      get() {
        return this.selectedStudyplotDate.toFormat('dd.MM.yyyy')
      },
      set(val) {
        this.selectedStudyplotDate = DateTime.fromFormat(val, 'dd.MM.yyyy')
        this.changedStudyplotSearch()
      },
    },
    isStudyplotSearchable() {
      return this.selectedStudyplotCode && this.selectedStudyplotDate
    },
  },
  methods: {
    changedStudyplotSearch() {
      this.handleStudyplotChange()
    },
    async handleStudyplotChange() {
      if (!this.isStudyplotSearchable) {
        return
      }
      this.$store.commit('observation/SET_SELECTED', null)
      this.snowInputCode = this.selectedStudyplotCode
      this.snowInputDate = this.selectedStudyplotDate.toFormat('dd.MM.y')
      const result = await this.loadStudyplot()
      if (result && result.length > 0) {
        if (result.length > 1) {
          const errorMsg = `Für die Station ${this.snowInputCode} und das Datim ${this.snowInputDate} wurden mehrere Messungen gefunden!!!`
          this.snowInputCode = ''
          this.snowInputDate = ''
          throw new Error(errorMsg)
        }
        this.$store.commit('observation/SELECT', { measurement: result[0] })
        this.snowInputRecreationKey = this.getStudyplotKey('UPDATE')
      } else {
        this.snowInputRecreationKey = this.getStudyplotKey('CREATE')
      }
    },
    async loadStudyplot() {
      try {
        return await StudyplotService.getMeasurementsToDate(
          this.selectedStudyplotCode,
          this.selectedStudyplotDate
        )
      } catch (error) {
        this.$store.commit('SET_EXCEPTION', error.message)
      }
    },
    getStudyplotKey(managementMode) {
      if (this.snowInputCode && this.snowInputDate) {
        return `${this.snowInputCode}_${this.snowInputDate}_${managementMode}`
      }
      return managementMode
    },
    dataSubmitted() {
      this.changedStudyplotSearch()
    },
  },
}
</script>

<style>
.studyplot-calendar-picker-container > div > div.col {
  align-self: center;
}
</style>

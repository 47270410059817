import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
export function beforeRouteEnter(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  next((vm) => {
    const extendedVm = vm as never as
      | Record<string, string>
      | Record<string, boolean>
    extendedVm.routeOrigin = from.fullPath
    if (from.path.includes('/observation/list')) {
      extendedVm.adaptMap = true
    }
  })
}

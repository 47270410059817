export const SURFACE_SVG = {
  SURFACE_HOAR_LARGE:
    '<polygon fill="red" points="-12,-11 12,-11 0,11"></polygon>',
  SURFACE_HOAR_SMALL: '<polygon fill="red" points="-6,-5 6,-5 0,5"></polygon>',
  LOOSE_NEW_SNOW:
    '<path d="M -10 0 H 10 0 M -7 -7 L 7 7 M 0 -10 V 0 10 M 7 -7 L -7 7" stroke="#33caff" stroke-width="2" fill="none"/>',
  LOOSE_FACETS:
    '<polygon fill="orange" points="-11,-11 11,-11 11,11 -11,11"></polygon>',
  MOIST: '<circle cx="0" cy="0" r="6" fill="blue"/>',
  HARD: '<polygon fill="black" points="-9,-3 9,-3 9,3 -9,3" stroke="white" stroke-width="1"></polygon>',
  BREAKABLE:
    '<polygon fill="white" points="-7,-9 7,-9 7,3 -7,3"></polygon><path d="M -6 0 H -2 M 6 0 H 2 M 0 -8 V 0 M 0 1 V 3" stroke="black" stroke-width="2" />',
  UNEVEN:
    '<polygon fill="white" points="-11,-9 11,-9 11,7 -11,7"></polygon><path d="M -10 -5 L -5 5 L 0 -5 L 5 5 L 10 -5" stroke="black" stroke-width="2" fill="none"/>',
  OTHER:
    '<polygon fill="white" points="-10,-8 10,-8 10,6 -10,6"></polygon><path d="M -10 -2 H 10 M -10 2 H 10 M -2.5 6 L 3.5 -6" stroke="black" stroke-width="2" fill="none"/>',
}

export const CLASS_DEFINITION = [
  {
    t: 'SURFACE_HOAR_LARGE',
    size: 32,
    scale: 2.1,
    label: 'snow.snowSurfaceCondition.SURFACE_HOAR_LARGE',
  },
  {
    t: 'SURFACE_HOAR_SMALL',
    size: 28,
    scale: 1.9,
    label: 'snow.snowSurfaceCondition.SURFACE_HOAR_SMALL',
  },
  {
    t: 'LOOSE_FACETS',
    size: 20,
    scale: 1.3,
    label: 'snow.snowSurfaceCondition.LOOSE_FACETS',
  },
  {
    t: 'LOOSE_NEW_SNOW',
    size: 24,
    scale: 1.6,
    label: 'snow.snowSurfaceCondition.LOOSE_NEW_SNOW',
  },
  {
    t: 'MOIST',
    size: 16,
    scale: 1.0,
    label: 'snow.snowSurfaceCondition.MOIST',
  },
  { t: 'HARD', size: 24, scale: 1.6, label: 'snow.snowSurfaceCondition.HARD' },
  {
    t: 'BREAKABLE',
    size: 20,
    scale: 1.3,
    label: 'snow.snowSurfaceCondition.BREAKABLE',
  },
  {
    t: 'UNEVEN',
    size: 16,
    scale: 1.0,
    label: 'snow.snowSurfaceCondition.UNEVEN',
  },
  {
    t: 'OTHER',
    size: 16,
    scale: 1.0,
    label: 'snow.snowSurfaceCondition.OTHER',
  },
]

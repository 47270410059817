'use strict'

import { i18n } from '@/plugins/i18n'
import ReportDefinitionreader from '@/model/report/ReportDefinitionreader.js'
import TableEntry from '@/model/report/TableEntry.js'
import TableEntryValueBuilder from '@/model/report/TableEntryValueBuilder.js'
import TableEntryLabelBuilder from '@/model/report/TableEntryLabelBuilder.js'
import obsTypeConverter from '@/scripts/obsTypeConverter.js'
import { AVALANCHE } from '@/scripts/const'
import funcs from '@/scripts/util'

export default class TableEntryBuilder {
  constructor(eleType, reportDef, obsEle, reportConfigFuncs) {
    this.reportDef = reportDef
    this.obsType = obsEle[eleType]
    this.reportConfigFuncs = reportConfigFuncs
  }

  /*
    Erzeugt aus den übergebenen Beobachtunegn die Tabellenelemente.
    Diese beinhalten die eigentlichen Key-Value-Paare
   */
  static buildTableEntries(obsEle, reportConfigFuncs) {
    const tables = []
    if (obsEle) {
      const tableEntryBuilder = new TableEntryBuilder(
        obsEle.type,
        ReportDefinitionreader.getReportDefinition(obsEle.type, obsEle),
        obsEle,
        reportConfigFuncs
      )
      tableEntryBuilder.reportDef[0].rows.forEach((row, idx) => {
        // Ausnahmen für den Typ wie z.B. 'decision' abhandeln
        const extendedType = obsTypeConverter.getSpecialTypeToCommonType(obsEle)
        const tableEntry = new TableEntry(
          tableEntryBuilder.getRowTitle(
            reportConfigFuncs.detailTitel(extendedType, null, obsEle),
            row
          )
        )
        tableEntry.rows = tableEntryBuilder.buildRowEntries(obsEle, idx)
        tables.push(tableEntry)
      })
    }
    return TableEntryBuilder.getNotEmptyTables(tables)
  }

  getRowTitle(firstTitle, row) {
    if (row && row.length > 0) {
      return i18n.global.t(row)
    }
    if (firstTitle && firstTitle.length > 0) {
      return firstTitle.replace(/(\r\n|\n|\r)/gm, '')
    }
    const errorMsg = 'Kein Detailtitel vorhanden'
    console.error(errorMsg)
    return errorMsg
  }

  buildRowEntries(obsEle, reprotDefIdx) {
    let rows = []
    if (obsEle && obsEle.type) {
      this.setHierarchyNames(obsEle)
      rows = this.getRows(obsEle, reprotDefIdx)
    }
    return rows
  }

  setHierarchyNames(obsEle) {
    if (obsEle && obsEle.hierarchy) {
      this.setAreaAndSectorName(obsEle)
      if (obsEle.hierarchy.objectId) {
        obsEle[obsEle.type].objectName = this.reportConfigFuncs.objectName(
          obsEle.hierarchy.objectId
        )
      }
      if (obsEle.hierarchy.avalanchePathId) {
        obsEle[obsEle.type].avalanchePathName =
          this.reportConfigFuncs.avalanchePathName(
            obsEle.hierarchy.avalanchePathId
          )
      }
    }
  }

  setAreaAndSectorName(obsEle) {
    if (obsEle.hierarchy.areaId) {
      let areaName = this.reportConfigFuncs.areaName(obsEle.hierarchy.areaId)
      if (obsEle.measure && obsEle.measure.objectType === 'warningLight') {
        areaName =
          i18n.global.t('measure.label.warningLight') + ' (' + areaName + ')'
      }
      obsEle[obsEle.type].areaName = `${areaName} (${this.getLocation(obsEle)})`
    }
    if (obsEle.hierarchy.sectorId) {
      obsEle[obsEle.type].sectorName = this.reportConfigFuncs.sectorName(
        obsEle.hierarchy.areaId,
        obsEle.hierarchy.sectorId
      )
    }
  }

  getRows(obsEle, reprotDefIdx) {
    const rows = []
    const detail = obsEle[obsEle.type]

    // Für alle nicht Assessmentmeldungen, ausser Lawinen, immer die Koordinaten anzeigen
    if (obsEle && obsEle.hierarchy == null && obsEle.type !== AVALANCHE) {
      rows.push({
        key: i18n.global.t('common.coord'),
        value: this.getLocation(obsEle),
      })
    }
    this.reportDef[reprotDefIdx].properties.forEach((key) => {
      this.createRow(reprotDefIdx, detail, key, rows)
    })
    return rows
  }

  createRow(reprotDefIdx, detail, key, rows) {
    const arrayProps = this.getArrayObject(detail, key)
    if (arrayProps?.length >= 1) {
      this.getRowsOfArrayObject(reprotDefIdx, arrayProps, rows, key)
    } else {
      const props = this.getRealkey(key).split('_')
      let objProp
      props.forEach((prop) => {
        objProp = this.getProperty(prop, objProp ? objProp : detail)
      })
      if (!this.isEmptyValue(objProp)) {
        rows.push(this.getRowOfProperty(objProp, key, reprotDefIdx))
      }
    }
  }

  getRowsOfArrayObject(reprotDefIdx, arrayProps, rows, key) {
    const arrayObjDef = this.reportDef[reprotDefIdx][key]
    arrayProps.forEach((arrProp, idx) => {
      rows.push({ key: '', value: '' })
      const subTitle = `${i18n.global.t(arrayObjDef.arrayTitle)}: ${(
        idx + 1
      ).toString()}`
      rows.push({ key: subTitle, value: '' })
      arrayObjDef.elements.forEach((key) => {
        this.createRow(reprotDefIdx, arrProp, key, rows)
      })
    })
  }

  isEmptyValue(objProp) {
    if (objProp == null) {
      return true
    }
    if (typeof objProp === 'string' && objProp.length <= 0) {
      return true
    }
    return Array.isArray(objProp) && objProp.length <= 0
  }

  getRealkey(key) {
    // Keyteile welche mit Doppelpunkt getrennt sind werden abgeschnitten. Nur so kann eine Property 2x verwendet werden
    return key.split(':')[0]
  }

  getProperty(key, detail) {
    if (key === '&') {
      return detail
    } else if (key.includes('&')) {
      const keys = key.split('&')
      return {
        [keys[0]]: detail[keys[0]],
        [keys[1]]: detail[keys[1]],
      }
    } else {
      return detail[key]
    }
  }

  getRowOfProperty(objProp, key, reprotDefIdx) {
    const propDef = this.reportDef[reprotDefIdx][key]
    const keyValue = {}
    keyValue.key = TableEntryLabelBuilder.getLabel(
      key,
      propDef,
      this.reportDef[reprotDefIdx]
    )
    keyValue.value = TableEntryValueBuilder.getValue(
      objProp,
      propDef,
      this.obsType
    )
    if (propDef.valueType) {
      keyValue.valueType = propDef.valueType
    }

    return keyValue
  }

  getArrayObject(detail, key) {
    const arrayProps = this.getRealkey(key).split('_[]')
    if (arrayProps.length > 1) {
      return detail[arrayProps[0]]
        ? detail[arrayProps[0]][arrayProps[1]]
        : undefined
    }
    return undefined
  }

  static getNotEmptyTables(tables) {
    const notEmptyTables = []
    tables.forEach((table) => {
      if (table.rows && table.rows.length > 0) {
        notEmptyTables.push(table)
      }
    })
    return notEmptyTables
  }

  getLocation(obsEle) {
    const resultingArray = []
    const transformedCoord = funcs.convertPointFromWGS84ToNewSwissCoordinates(
      obsEle?.location?.coordinates
    )
    transformedCoord.forEach((coord) => {
      // Anzahl Nachkommastellen der Schweizerkoordinaten (EPSG:2056) runden.
      resultingArray.push(coord.toFixed(0))
    })
    return resultingArray.toString().replaceAll(',', ', ')
  }
}

<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col class="value">
            <b-form-group>
              <template v-slot:label>
                <InfoLabel
                  :text="$t('measurement.label.hn') + ' [cm]'"
                  :info="$t('help.measurement.hn')"
                />
              </template>
              <b-form-input
                :disabled="!isBeobHNTimeserieIsActive"
                :value="modelValue.hn"
                type="text"
                :state="hnState"
                aria-describedby="hnFeedback"
                @input="update('hn', $event)"
              />
              <b-form-invalid-feedback id="hnFeedback">
                {{ $t('msg.selectValidValue') }} {{ $t('msg.validValuesHn') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col class="value">
            <b-form-group>
              <template v-slot:label>
                <InfoLabel
                  :text="$t('measurement.label.hnw') + ' [mm]'"
                  :info="$t('help.measurement.hnw')"
                />
              </template>
              <b-form-input
                :disabled="!isBeobHNWTimeserieIsActive"
                :value="modelValue.hnw"
                type="text"
                pattern="[0-9]*"
                :state="hnwState"
                aria-describedby="hnwFeedback"
                @input="update('hnw', $event)"
              />
              <b-form-invalid-feedback id="hnwFeedback">
                <span v-if="!isHnwInRange"
                  >{{ $t('msg.selectValidValue') }}
                  {{
                    $t('msg.onlyIntegerBetween', { min: '0', max: '200' })
                  }}</span
                >
                <span v-if="!isHnwValidForHnGreaterZero">{{
                  $t('msg.validationHnHnw')
                }}</span>
                <span v-if="!isHnwValidForHnEqualZero">{{
                  $t('msg.validationHnHnw3')
                }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col class="value">
            <b-form-group cols="md-6">
              <template v-slot:label>
                <InfoLabel
                  :text="$t('measurement.label.hs') + ' [cm]'"
                  :info="$t('help.measurement.hs')"
                />
              </template>
              <b-form-input
                :disabled="!isBeobHSTimeserieIsActive"
                :value="modelValue.hs"
                type="text"
                :state="hsState"
                aria-describedby="hsFeedback"
                @input="update('hs', $event)"
              />
              <b-form-invalid-feedback id="hsFeedback">
                {{ $t('msg.selectValidValue') }} {{ $t('msg.onlyInteger') }}
              </b-form-invalid-feedback>
              <b-form-text id="hsLiveHelp">
                {{ $t('measurement.label.previous') }}
                {{ previous }}&nbsp;cm
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InfoLabel from '@/components/InfoLabel.vue'
import { HnHnwHsStates } from './HnHnwHsStates'
import {
  hasStationAnActiveBeobTimeserie,
  HN_MEASURAND_CODE,
  HNW_MEASURAND_CODE,
  HS_MEASURAND_CODE,
} from '@/model/studyplotInput'
import { DateTime } from 'luxon'

export default {
  name: 'InputHnHnwHsSection',
  components: { InfoLabel },
  props: ['modelValue', 'previous', 'date', 'studyplotCode'],
  emits: ['update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  mixins: [HnHnwHsStates],
  data() {
    return {}
  },
  created() {},
  computed: {
    isBeobHNTimeserieIsActive() {
      return hasStationAnActiveBeobTimeserie(
        DateTime.fromJSDate(this.date),
        this.$store.getters['studyplot/getStudyplot'](this.studyplotCode),
        [HN_MEASURAND_CODE]
      )
    },
    isBeobHNWTimeserieIsActive() {
      return hasStationAnActiveBeobTimeserie(
        DateTime.fromJSDate(this.date),
        this.$store.getters['studyplot/getStudyplot'](this.studyplotCode),
        [HNW_MEASURAND_CODE]
      )
    },
    isBeobHSTimeserieIsActive() {
      return hasStationAnActiveBeobTimeserie(
        DateTime.fromJSDate(this.date),
        this.$store.getters['studyplot/getStudyplot'](this.studyplotCode),
        [HS_MEASURAND_CODE]
      )
    },
    hnState() {
      return this.getHnState(this.modelValue.hn)
    },
    hnwState() {
      return this.getHnwState(this.modelValue.hnw, this.modelValue.hn)
    },
    isHnwInRange() {
      return this.getIsHnwInRange(this.modelValue.hnw)
    },
    isHnwValidForHnGreaterZero() {
      return this.getIsHnwValidForHnGreaterZero(
        this.modelValue.hn,
        this.modelValue.hnw
      )
    },
    isHnwValidForHnEqualZero() {
      return this.getIsHnwValidForHnEqualZero(
        this.modelValue.hn,
        this.modelValue.hnw
      )
    },
    hsState() {
      return this.getHsState(this.modelValue.hs)
    },
  },
  methods: {
    update(key, value) {
      this.$emit('update:modelValue', { ...this.modelValue, [key]: value })
    },
  },
}
</script>

<style scoped></style>

'use strict'

import proj4 from 'proj4'

import 'ol/ol.css'

import { register } from 'ol/proj/proj4.js'
import Map from 'ol/Map'
import View from 'ol/View'
import Overlay from 'ol/Overlay'
import LegendControl from './controls/LegendControl.js'
import PositionControl from './controls/PositionControl.js'
import FilterControl from './controls/FilterControl.js'
import LayerControl from './controls/LayerControl.js'

import ClusterVectorLayer from './clustering/ClusterVectorLayer'
import VectorLayer from '../scripts/layers/VectorLayer'
import MapProjection from './MapProjection'
import store from '../store/index'
import PinchRotate from 'ol/interaction/PinchRotate.js'

import {
  DANGERSIGN_AVALANCHE,
  MEASURE,
  SNOWDRIFT,
  WHUMPF_CRACKS,
} from '@/scripts/const.js'
import FullCluster from './clustering/FullCluster'
import VectorSource from 'ol/source/Vector'
import Vector from 'ol/layer/Vector'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { zoomByDelta } from 'ol/interaction/Interaction.js'
import { SHOW_SPIDER_AT_RESOLUTION, Spider } from './clustering/Spider'
import { createCommonLayers } from '@/scripts/common/commonLayers'
import {
  createCommonControls,
  getAttributionControl,
} from '@/scripts/common/commonControls'
import { resolutionLimit } from '@/scripts/layers/tileLayerUtil'
import {
  FA_LIGHT_FA_SENSOR_ALERT,
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_FILTER,
  FA_SOLID_FA_LAYER_GROUP,
  FA_SOLID_FA_LIST,
} from '@/plugins/fontawesomeConsts'
import emitter from '@/components/EventHub'
import { toRaw } from 'vue'
import { warnregionLayer } from '@/scripts/layers/WarnregionLayer'

export const DEFAULT_CENTER = [660000, 180000]
export const DEFAULT_VIEW_EXTENT = [
  485071.54 - 20000,
  75346.36 - 20000,
  828515.78 + 20000,
  299941.84 + 20000,
]
export const CLUSTER_DISTANCE = 50
const DESKTOP_DEFAULT_ZOOM = 15
const MOBILE_DEFAULT_ZOOM = 12
const DESKTOP_HIT_TOLERANCE = 5
const MOBILE_HIT_TOLERANCE = 10
const INITIAL_TIMEOUT = 500

const CLICK_ZOOM_DELTA = 1
const CLICK_ZOOM_DURATION = 250
let defaultZoom = DESKTOP_DEFAULT_ZOOM
let defaultHitTolerance = DESKTOP_HIT_TOLERANCE

// Höchster zIndex wird zuoberst dargestellt
const Z_INDEX_LAYER_PINSOURCE = 1000
const Z_INDEX_LAYER_FEATURES = 800
const Z_INDEX_LAYER_BULLETIN = 500

const initMap = function (target, commonLayers, iconRefs) {
  legendControl = new LegendControl(
    store.state.mobilePlatform,
    iconRefs[FA_SOLID_FA_LIST]
  )
  positionControl = new PositionControl(
    view,
    'visualisation',
    store.state.mobilePlatform,
    iconRefs[FA_SOLID_FA_CROSSHAIRS]
  )
  filterControl = new FilterControl(
    store.state.mobilePlatform,
    iconRefs[FA_SOLID_FA_FILTER]
  )
  layerControl = new LayerControl(
    store.state.mobilePlatform,
    iconRefs[FA_LIGHT_FA_SENSOR_ALERT]
  )
  if (store.state.mobilePlatform) {
    defaultZoom = MOBILE_DEFAULT_ZOOM
    defaultHitTolerance = MOBILE_HIT_TOLERANCE
    positionControl.setSwissZoom(store.state.map.zoom || defaultZoom)
    view.setZoom(store.state.map.zoom || defaultZoom)
    view.setCenter(store.state.map.center || DEFAULT_CENTER)
    legendControl.visible = false
    filterControl.visible = false
    layerControl.visible = false
  } else {
    defaultZoom = DESKTOP_DEFAULT_ZOOM
    view.setMinZoom(defaultZoom)
    // Legende neu initial auch auf Desktop geschlossen / SLPPWB-804 /
    legendControl.visible = false
    filterControl.visible = true
    layerControl.visible = true
  }

  const controls = createCommonControls(
    store.state.mobilePlatform,
    iconRefs[FA_SOLID_FA_LAYER_GROUP]
  ).extend([legendControl, positionControl])

  return new Map({
    controls,
    layers: [...commonLayers, spiderLayer],
    target: target,
    view: view,
    moveTolerance: 5,
  })
}

// eslint-disable-next-line no-var
const initEventHandler = function (maplib) {
  // eslint-disable-next-line complexity
  maplib.map.on('click', function (evt) {
    // let tm = performance.now()
    const feature = maplib.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        // this is called only with the topmost feature
        feature.layerId = layer.get('id')
        return feature
      },
      {
        hitTolerance: defaultHitTolerance,
      }
    )
    if (feature) {
      const id = feature.layerId
      // its a cluster
      if (feature.get('features') && feature.get('features').length > 1) {
        zoomInLocally(evt, maplib.map)
      } else if (id && maplib.clickHandlers[id]) {
        evt.stopPropagation()
        emitter.emit('save-menu-state', id)
        emitter.emit('save-menu-state-filters', id)
        if (
          feature.get('features') &&
          feature.get('features')[0] &&
          feature.get('features')[0].get('avalanche')
        ) {
          store.commit(
            'observation/SET_MAPSELECTED_ID',
            feature.get('features')[0].get('avalanche').id
          )
        } else if (
          feature.get('features') &&
          feature.get('features')[0] &&
          feature.get('features')[0].get('avalancheRelease')
        ) {
          store.commit(
            'observation/SET_MAPSELECTED_ID',
            feature.get('features')[0].get('avalancheRelease').id
          )
        } else {
          store.commit('observation/SET_MAPSELECTED_ID', feature.get('id'))
        }
        maplib.clickHandlers[id](feature)
      }
    }
  })
  let ghostZoom = maplib.map.getView().getZoom()
  maplib.map.on('moveend', () => {
    if (maplib.avalanacheFullCluster instanceof FullCluster) {
      maplib.avalanacheFullCluster.recalculateCluster()
    }
    store.dispatch('map/setZoom', maplib.map.getView().getZoom())
    store.dispatch('map/setCenter', maplib.map.getView().getCenter())
    if (ghostZoom !== maplib.map.getView().getZoom()) {
      maplib.setAttributionVisibility()
      ghostZoom = maplib.map.getView().getZoom()
      maplib.resolutionChangeHandlers.forEach((fn) => {
        fn(maplib.map.getView().getResolution(), maplib.layers, maplib.spider)
      })
    }
    emitter.emit('repos::map', false)
  })
  maplib.map.on('movestart', () => {
    emitter.emit('repos::map', true)
  })
}

export class Maplib {
  constructor() {
    proj4.defs(
      'EPSG:21781',
      '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 ' +
        '+k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
    )
    register(proj4)
    // TODO: hoverHandlers not used at all;  why mouseOver and layer events have been separated from each other?
    // mouseOver should probably be layer-independent if based on several features from several layers..
    this.hoverHandlers = []
    this.moveHandlers = [] // NOTE: mouseOverHandlers listening to a pointermove event
    this.clickHandlers = {}
    this.resolutionChangeHandlers = []
    this.commonLayers = {}
    this.layers = {}
    this.auxiliaryLayers = {}
    this.pinSourceFeatures = null
    this.spider = {}
    this.timeout = INITIAL_TIMEOUT
    this.regionLayer = undefined
    this.warnRegionDate = undefined
    this.warnRegionDefinitionId = undefined
    this.avalanacheFullCluster = undefined
  }
  init(target, faIconRefs) {
    const namedCommonLayers = createCommonLayers()
    const commonLayers = {}
    for (const { name, layer } of namedCommonLayers) {
      commonLayers[name] = layer
    }
    this.commonLayers = commonLayers
    this.map = initMap(
      target,
      namedCommonLayers.map(({ layer }) => layer),
      faIconRefs
    )

    const rotateIndex = this.map
      .getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof PinchRotate
      })
    this.map.getInteractions().removeAt(rotateIndex)
    this.pinSourceFeatures = new VectorSource()
    const layerFeatures = new Vector({ source: this.pinSourceFeatures })
    layerFeatures.setZIndex(Z_INDEX_LAYER_PINSOURCE)
    this.map.addLayer(layerFeatures)
    const spiderLayer = this.map
      .getLayers()
      .getArray()
      .filter(function (layer) {
        return layer.get('id') === 'spider'
      })
    this.spider = new Spider(spiderLayer)
    initEventHandler(this)
    this.setAttributionVisibility()
  }
  addPin(pinFeature) {
    this.pinSourceFeatures.addFeature(pinFeature)
  }
  addLayer(name, style) {
    // keep legend initially closed on desktop / SLPPWB-804 /
    this.map
      .getControls()
      .getArray()
      .forEach((control) => {
        if (control instanceof LegendControl) {
          control.visible = false
        }
        if (
          (control instanceof FilterControl ||
            control instanceof LayerControl) &&
          store.state.mobilePlatform
        ) {
          control.visible = false
        }
      })
    // Alte Spiders entfernen SLPPWB-780 Spider nicht vollständig entfernt
    this.spider.resetSpiders()
    this.cleanNonAuxHandlers()
    this.removeLayers()
    let vectorLayer
    if (name === 'avalanche') {
      const initialResolution = this.map.getView().getResolution()
      this.avalanacheFullCluster = new FullCluster(
        {
          distance: CLUSTER_DISTANCE,
          source: new VectorSource({}),
        },
        this.map
      )
      vectorLayer = new ClusterVectorLayer(
        name,
        style,
        CLUSTER_DISTANCE,
        this.avalanacheFullCluster,
        initialResolution
      )
    } else {
      vectorLayer = new VectorLayer(name, style)
    }
    this.layers[name] = vectorLayer
    vectorLayer.getLayer().setZIndex(Z_INDEX_LAYER_FEATURES)
    this.map.addLayer(vectorLayer.getLayer())
  }
  removeLayers() {
    Object.keys(this.layers).forEach((key) => {
      this.map.removeLayer(this.layers[key].getLayer())
      this.avalanacheFullCluster?.releaseMem()
      this.avalanacheFullCluster = null
      if (this.layers[key] instanceof ClusterVectorLayer) {
        this.layers[key].releaseMem()
      }
      this.layers[key] = null
    })
    this.layers = {}
  }
  addAuxiliaryLayer(name, style, inferior) {
    const vectorLayer = new VectorLayer(name, style)
    vectorLayer.getLayer().setZIndex(Z_INDEX_LAYER_BULLETIN)
    this.auxiliaryLayers[name] = vectorLayer
    // NOTE (20200203/csz): if rapidly called add/remove over a longer period of time,
    // it can happen that layer added twice... therefore, check it here once again...
    // NOTE (20200210/csz): above should not be the case any more but you never know..
    this.map.removeLayer(this.auxiliaryLayers[name].getLayer())
    if (inferior) {
      this.map.getLayers().insertAt(0, vectorLayer.getLayer())
    } else {
      this.map.addLayer(vectorLayer.getLayer())
    }
  }
  removeAuxiliaryLayer(name) {
    if (this.auxiliaryLayers[name]) {
      this.map.removeLayer(this.auxiliaryLayers[name].getLayer())
      delete this.clickHandlers[name]
      delete this.auxiliaryLayers[name]
    }
  }
  removeAuxiliaryLayers() {
    Object.keys(this.auxiliaryLayers).forEach((key) => {
      this.map.removeLayer(this.auxiliaryLayers[key].getLayer())
      delete this.clickHandlers[key]
    })
    this.auxiliaryLayers = {}
  }
  // eslint-disable-next-line complexity
  addFeatures(layer, data) {
    if (!data.features) {
      return
    }
    if (layer === 'avalanche') {
      data.features = data.features.filter(
        (f) => f.get('geometry').getCoordinates().length > 0
      )
      this.layers[layer].addFeatures(data.features)
    } else {
      data.features = data.features.filter(
        (f) => f.geometry && f.geometry.coordinates.length > 0
      )
      this.layers[layer].addFeatures(data)
      // Hier wurde für den Spider auf eine andere Resolution geprüft als in Spider.js. Darum neu eine Konstante gemacht
      // SLPPWB-779 Spider erst aktiv nach Zoomstufenänderung
      if (
        layer !== 'hs' &&
        layer !== 'bulletin' &&
        this.map.getView().getResolution() < SHOW_SPIDER_AT_RESOLUTION
      ) {
        this.spider.displayOverlappingGroups(
          this.layers[layer].getSource().getFeatures(),
          this.layers[layer].vectorLayer,
          this.map.getView().getResolution()
        )
      } else {
        this.spider.resetSpiders()
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let timeoutRef = setTimeout(() => {
      this.map.updateSize()
      if (this.layers[layer]) {
        this.layers[layer].getSource().changed()
      }
      timeoutRef = null
    }, 100)
  }
  addAuxiliaryFeatures(layer, data) {
    if (!data.features) {
      return
    }

    data.features = data.features.filter(
      (f) => f.geometry && f.geometry.coordinates.length > 0
    )
    this.auxiliaryLayers[layer].addFeatures(data)
  }
  addMouseOver(mouseOverFunction) {
    const container = document.getElementById('popup')
    const content = document.getElementById('popup-content')
    const overlay = new Overlay({
      element: container,
      /* autoPan: true,
      autoPanAnimation: {
        duration: 250
      } */
      autoPan: false,
    })
    // Mehrere mouseover overlays verursachen eine Verschiebung der Karte, wenn das Popup angezeigt wird.
    this.map.getOverlays().clear()
    this.map.addOverlay(overlay)
    const self = this

    const mouseoverPrecision = 100
    let lastEvent = null
    let timeout = null

    // eslint-disable-next-line complexity
    const moveHandler = function (evt) {
      // NOTE (20200213/csz): w/o timeout freeze in Edge if multiple layers..
      // NOTE: if movements to be judged too rough, use timeout solution for edge only
      // and go ahead w/o timeout for other browsers
      if (
        evt.originalEvent.pointerType !== 'mouse' ||
        evt.dragging ||
        timeout
      ) {
        if (evt.originalEvent.pointerType !== 'mouse') {
          evt.preventDefault()
        }
        if (evt.dragging) {
          if (container) {
            container.style.display = 'none'
          }
        }
        timeout && (lastEvent = evt)
        return
      }

      timeout = setTimeout(function () {
        timeout = null
        handleMouseOver(
          mouseOverFunction,
          container,
          content,
          overlay,
          self,
          lastEvent
        )
      }, mouseoverPrecision)

      handleMouseOver(mouseOverFunction, container, content, overlay, self, evt)
    }

    // NOTE: remove not needed listeners for 'pointermove' event - use only one mouseover function
    // otherwise, ALL registered listener functions will be carried out
    this.moveHandlers.forEach((moveHandler) => {
      this.map.un('pointermove', moveHandler)
    })
    this.moveHandlers = []
    this.map.on('pointermove', moveHandler)
    this.moveHandlers.push(moveHandler)
  }
  addInteraction(interaction) {
    this.map.addInteraction(interaction)
  }
  home() {
    this.map.getView().setCenter(DEFAULT_CENTER)
    this.map.getView().setZoom(defaultZoom)
  }
  showLayer(layer) {
    const layers = this.layers
    Object.keys(layers).forEach(function (k) {
      layers[k].getLayer().setVisible(k === layer)
    })
  }
  showAuxiliaryLayer(layer) {
    // NOTE: supposed that aux layers not shown at the same time
    const layers = this.auxiliaryLayers // Object.assign({}, this.auxiliaryLayers)
    Object.keys(layers).forEach(function (k) {
      layers[k].getLayer().setVisible(k === layer)
    })
  }
  setCommonLayerVisibility(layerName, visible) {
    this.commonLayers[layerName].setVisible(visible)
  }
  addHoverHandler(fn) {
    this.hoverHandlers.push(fn)
  }
  addClickHandler(name, fn) {
    this.clickHandlers[name] = fn
  }
  cleanNonAuxHandlers() {
    this.resolutionChangeHandlers.splice(
      0,
      this.resolutionChangeHandlers.length
    )
    for (const member in this.clickHandlers) {
      if (this.layers[member]) {
        delete this.clickHandlers[member]
      } // NOTE: layers will be removed afterwards...
    }
  }
  addResolutionChangeHandler(fn) {
    this.resolutionChangeHandlers = [fn]
  }
  addFilterControl() {
    const fControl = this.map
      .getControls()
      .getArray()
      .find((control) => {
        return control.id === filterControl.id
      })
    if (!fControl) {
      this.map.addControl(filterControl)
    }
  }
  removeFilterControl() {
    toRaw(this.map).removeControl(filterControl)
  }
  addLayerControl() {
    const layerCtrl = this.map
      .getControls()
      .getArray()
      .find((control) => {
        return control.id === layerControl.id
      })
    if (!layerCtrl) {
      this.map.addControl(layerControl)
    }
  }
  removeLayerControl() {
    toRaw(this.map).removeControl(layerControl)
  }
  recenter() {
    this.map.getView().setCenter(store.state.map.center || DEFAULT_CENTER)
    this.map.getView().setZoom(store.state.map.zoom || defaultZoom)
  }
  loadAndAddWarnregionLayerToDate() {
    const actualDate = store.getters['calendar/getMomentAsLuxon'].toISODate()
    if (!this.warnRegionDate || actualDate !== this.warnRegionDate) {
      store.dispatch('map/loadWarnRegions', actualDate).then(() => {
        const geojsonObject = {
          type: 'FeatureCollection',
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          features: store.state.map.regions.features,
        }
        // Remove old regionlayer and add the new one
        const warnRegionFeatures = store.state.map.regions.features
        if (warnRegionFeatures && warnRegionFeatures.length > 0) {
          const actWarnRegionId =
            warnRegionFeatures[0].properties.warnregion_def_id
          if (
            !this.warnRegionDefinitionId ||
            actWarnRegionId !== this.warnRegionDefinitionId
          ) {
            if (this.regionLayer) {
              this.map.removeLayer(this.regionLayer)
              this.regionLayer = undefined
            }
            this.warnRegionDate = actualDate
            this.warnRegionDefinitionId = actWarnRegionId
            this.regionLayer = warnregionLayer(geojsonObject)
            this.map.addLayer(this.regionLayer)
          }
        }
      })
    }
  }
  setAttributionVisibility() {
    if (this.map.getView().getResolution() < resolutionLimit) {
      getAttributionControl(this.map.getControls()).element.classList.remove(
        'ol-hidden'
      )
    } else {
      getAttributionControl(this.map.getControls()).element.classList.add(
        'ol-hidden'
      )
    }
  }
}
const view = new View({
  projection: new MapProjection().getOldProjection(),
  center: DEFAULT_CENTER,
  zoom: DESKTOP_DEFAULT_ZOOM,
  zoomFactor: 1.5,
  minZoom: 11,
  maxZoom: 31,
  extent: DEFAULT_VIEW_EXTENT,
  constrainOnlyCenter: true,
})

// eslint-disable-next-line no-var
const spiderLayer = new Vector({
  id: 'spider',
  source: new VectorSource({
    features: [],
  }),
  style: [
    new Style({
      stroke: new Stroke({
        width: 2,
        color: [51, 51, 51, 1],
      }),
      zIndex: 2,
    }),
  ],
  updateWhileAnimating: true,
})

let legendControl
let positionControl
let filterControl
let layerControl

let hoveredFeature = null

// eslint-disable-next-line complexity
const handleMouseOver = function (
  mouseOverFunction,
  container,
  content,
  overlay,
  self,
  evt
) {
  // NOTE: reaction on most upper visible feature
  if (evt) {
    const coordinate = evt.coordinate
    const pixel = self.map.getEventPixel(evt.originalEvent)

    if (hoveredFeature) {
      hoveredFeature.set('isHovered', false)
      hoveredFeature.setStyle(undefined)
    }

    const feature = self.map.forEachFeatureAtPixel(
      pixel,
      function (feature, layer) {
        feature.layerId = layer.get('id')
        hoveredFeature = feature
        return feature // get the first one
      },
      {
        // eslint-disable-next-line complexity
        layerFilter: function (layer) {
          // Achtung: hat früher auch für "layer.get('id') === undefined" true zurückgegeben
          return (
            layer.get('id') === 'avalanche' ||
            layer.get('id') === SNOWDRIFT ||
            layer.get('id') === MEASURE ||
            layer.get('id') === WHUMPF_CRACKS ||
            ['danger', 'problem'].includes(layer.get('id')) ||
            layer.get('id') === 'snowSurfaceCondition' ||
            layer.get('id') === 'snowline' ||
            layer.get('id') === DANGERSIGN_AVALANCHE ||
            ['bulletin.SECOND_DANGERMAP', 'bulletin.FIRST_DANGERMAP'].includes(
              layer.get('id')
            ) ||
            ['hn24h', 'hs', 'ps', 'snowRainAltitude'].includes(layer.get('id'))
          )
        },
      }
    )
    if (feature) {
      if (container) {
        container.style.display = 'block'
      }
      content.innerHTML = mouseOverFunction(feature)
      const offset = self.map.getView().getResolution() * 2
      // NOTE: in case of autoPan switched off (i.e. map doesn't move and when popup doesn't fit into view it is cut off),
      // calculation of overlay position instead of setPosition line below:

      // variant 1: simple popup positioning when autoPan true
      // overlay.setPosition([coordinate[0] + offset, coordinate[1] + offset])

      // variant 2: calculate popup positioning (above/below, left/right) when autoPan false
      const mapDiv = document.getElementById('mapPanel')
      let popupOffsetX
      let popupOffsetY
      if (pixel[0] + container.offsetWidth > mapDiv.offsetWidth) {
        popupOffsetX =
          self.map.getCoordinateFromPixel([
            pixel[0] - container.offsetWidth - 2,
            pixel[1],
          ])[0] + offset
      } else {
        popupOffsetX = coordinate[0] + offset
      }
      if (pixel[1] + container.offsetHeight > mapDiv.offsetHeight) {
        popupOffsetY =
          self.map.getCoordinateFromPixel([
            pixel[0],
            pixel[1] - container.offsetHeight,
          ])[1] - offset
      } else {
        popupOffsetY = coordinate[1] + offset
      }
      overlay.setPosition([popupOffsetX, popupOffsetY])
    } else {
      if (container) {
        container.style.display = 'none'
      }
      if (content) {
        content.innerHTML = ''
      }
    }
  }
}
const zoomInLocally = function (mapBrowserEvent, map) {
  const browserEvent = /** @type {MouseEvent} */ (mapBrowserEvent.originalEvent)
  const anchor = mapBrowserEvent.coordinate
  const delta = browserEvent.shiftKey ? -CLICK_ZOOM_DELTA : CLICK_ZOOM_DELTA
  const view = map.getView()
  zoomByDelta(view, delta, anchor, CLICK_ZOOM_DURATION)
  mapBrowserEvent.preventDefault()
  return false
}

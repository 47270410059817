import { components as observationComps } from '@/model/generated/observationApi'

export interface VmExtension {
  routeOrigin: string
  adaptMap?: boolean
}

export const ASPECTS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'] as const
export type Aspect = (typeof ASPECTS)[number]

export type ObservationDto = observationComps['schemas']['ObservationDto']

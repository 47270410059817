<template>
  <!-- MeasureAssessment -->
  <b-card
    footer-tag="footer"
    :header="$t('assessment.measureAssessment.label.measuresAssessmentTitle')"
  >
    <!-- Footer der Card -->
    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click="routeBack()" variant="warning">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="areas && areas.length > 0"
            @click="onSubmit()"
            variant="success"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>
    <!-- Content/Body der Card -->
    <!-- Feedback über invalide Eingaben -->
    <div v-if="isComplete === false || !areas || areas.length <= 0">
      <b-row>
        <b-col lg="12">
          <b-form-group>
            <b-form-input
              hidden
              :state="completeState"
              aria-describedby="contentFeedback"
            />
            <b-form-invalid-feedback id="contentFeedback">
              {{ $t('assessment.measureAssessment.msg.validMinimalContent') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              hidden
              :state="areas && areas.length > 0"
              aria-describedby="areaFeedback"
            />
            <b-form-invalid-feedback id="areaFeedback">
              {{ $t('assessment.measureAssessment.msg.noArea') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- Gebietsbeurteilung oder Sektorbeurteilung pro Gebiet des entsprechenden Lawinendienstes -->
    <b-form v-if="areas && areas.length > 0" @submit.prevent="onSubmit">
      <div v-for="(area, index) in areas" v-bind:key="area.id">
        <b-card header-tag="header" header-bg-variant="Light">
          <template v-slot:header>
            <!-- Gebietstitel und Auswahl (gesamtes Gebiet/Sektor) -->
            <span class="mr-3">{{ area.name }}</span>
            <b-form-radio-group
              v-if="isNew"
              :id="'btn-radios-id' + index"
              :name="'btn-radios-name' + index"
              size="sm"
              buttons
              button-variant="outline-secondary"
              v-model="selAreaSectors[index]"
              :options="areaSectors"
            >
            </b-form-radio-group>
          </template>

          <!-- Felder für Gesamtes Gebiet -->
          <b-row v-if="selAreaSectors[index] === AREA_CONST">
            <b-col md="6">
              <b-form-group
                :label="$t('assessment.measureAssessment.label.action')"
              >
                <b-form-select
                  :id="'select-combo-id' + index"
                  :options="flag"
                  v-model="forms[index].measure"
                  @change="changedMeasureDecision"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                :label="$t('assessment.measureAssessment.label.comment')"
              >
                <b-form-textarea
                  :id="'comment' + index.toString()"
                  v-model="forms[index].comment"
                  :rows="3"
                  :state="commentStateArea(index)"
                  aria-describedby="commentFeedback"
                  size="lg"
                />
                <b-form-invalid-feedback id="commentFeedback">
                  {{
                    $t('msg.minMaxCharacters', {
                      min: minCommentLength.toString(),
                      max: maxCommentLength.toString(),
                    })
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Felder für die einzelnen Sektoren -->
          <div
            v-else
            v-for="(sector, idx) in area.sectors"
            v-bind:key="sector.id"
          >
            <div
              :title="
                getHazard(forms[index].sectorForms[idx])
                  ? getHazard(forms[index].sectorForms[idx])[1]
                  : null
              "
              class="sector-title mb-2"
            >
              {{
                $t('assessment.common.label.sector') +
                ': ' +
                area.sectors[idx].name
              }}
              <span v-if="getHazard(forms[index].sectorForms[idx])">
                {{ ' ( '
                }}<i
                  :style="iconStyle"
                  :class="getHazard(forms[index].sectorForms[idx])[0]"
                ></i
                >{{ ' ' + getHazard(forms[index].sectorForms[idx])[2] + ' )' }}
              </span>
            </div>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('assessment.measureAssessment.label.action')"
                >
                  <b-form-select
                    :id="'select-combo-id' + index + '-' + idx"
                    :options="flag"
                    v-model="forms[index].sectorForms[idx].measure"
                    @change="changedMeasureDecision"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  :label="$t('assessment.measureAssessment.label.comment')"
                >
                  <b-form-textarea
                    :id="'comment' + index.toString() + '-' + idx.toString()"
                    :rows="3"
                    v-model="forms[index].sectorForms[idx].comment"
                    :state="commentStateSector(index, idx)"
                    aria-describedby="commentFeedback"
                    size="lg"
                  />
                  <b-form-invalid-feedback id="commentFeedback">
                    {{
                      $t('msg.minMaxCharacters', {
                        min: minCommentLength.toString(),
                        max: maxCommentLength.toString(),
                      })
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="hr-sector" v-if="idx + 1 < area.sectors.length" />
          </div>
        </b-card>
      </div>
      <b-row>
        <b-col md="6" xl="3">
          <b-form-group
            :label="
              $t('assessment.measureAssessment.label.nextPlanedAssessment')
            "
          >
            <calendar-component
              class="calendar-picker w-100"
              v-model="nextPlanedDateString"
              withTime
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { i18nMixin, enums } from '../../I18N'
import { i18n } from '@/plugins/i18n'
import moment from 'moment'
import { ASSESSMENT_DECISION, ASSESSMENT_SECTOR } from '@/scripts/const.js'
import { mapState } from 'vuex'
import { Canceler } from '@/scripts/requestUtil.js'
import {
  COLOR_FAVORABLE,
  COLOR_MEDIUM,
} from '@/components/styles/AbstractStyle.js'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import CalendarComponent from '@/components/calendar/CalendarComponent'
import { inputFrmRouteBackMixin } from '@/components/observation/InputFrmRouteBackMixin.js'
import { deepClone } from '@/scripts/common'

const MIN_COMMENT_LENGTH = 2
const MAX_COMMENT_LENGTH = 500
const AREA = enums.assessment.areaSectors[0]
const SECTORS = enums.assessment.areaSectors[1]
const HAZARD_TARGET = 'hazard'

const Cancel = Canceler.getCancelObj()

export default {
  name: 'MeasureAssessmentInput',
  components: { CalendarComponent },
  mixins: [i18nMixin, inputFrmRouteBackMixin],
  props: [],
  data() {
    return {
      AREA_CONST: AREA,
      SECTORS_CONST: SECTORS,
      minCommentLength: MIN_COMMENT_LENGTH,
      maxCommentLength: MAX_COMMENT_LENGTH,
      areas: [],
      selAreaSectors: [],
      forms: [],
      isNew: true,
      processing: null,
      isComplete: null,
      nextPlanedAssessment: {
        date: undefined,
        time: undefined,
      },
    }
  },
  computed: {
    flag() {
      return this.options('common', 'flag', false)
    },
    areaSectors() {
      return this.options('assessment', 'areaSectors', true)
    },
    completeState() {
      return this.isComplete
    },
    ...mapState('profile', ['assmAvalServices', 'avalancheServiceId']),
    iconStyle() {
      return {
        '--icon-color-wb-green': COLOR_FAVORABLE,
        '--icon-color-wb-yellow': COLOR_MEDIUM,
      }
    },
    nextPlanedDateString: {
      get() {
        const planedDate = this.nextPlanedAssessment.date
        const planedTime = this.nextPlanedAssessment.time

        if (planedDate && planedTime) {
          return moment(planedDate + 'T' + planedTime).format(
            'DD.MM.YYYY HH:mm'
          )
        }
        if (planedDate && !planedTime) {
          return moment(planedDate).format('DD.MM.YYYY HH:mm')
        }
        // Infolge SPASS-393 nötig. Sieht unschön aus, aber anderes konnte ich diese 'CalendarComponent'
        // nicht überzeugen, das Datumsfeld initial leer zu lassen.
        // Dazu müsste man wohl die 'CalendarComponent' refactoren
        return moment(null).format('DD.MM.YYYY HH:mm')
      },
      set(val) {
        if (val) {
          const nextPlanedMoment = moment(val, 'DD.MM.YYYY HH:mm')

          this.nextPlanedAssessment.date = nextPlanedMoment.format('YYYY-MM-DD')
          this.nextPlanedAssessment.time = nextPlanedMoment.format('HH:mm:ss')
        } else {
          this.nextPlanedAssessment.date = null
          this.nextPlanedAssessment.time = null
        }
      },
    },
  },
  watch: {
    avalancheServiceId: function (newValue) {
      if (newValue && newValue.length > 0) {
        this.initAreasAndForms()
      }
    },
    assmAvalServices: {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.initAreasAndForms()
        }
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  created() {
    if (this.$store.state.assessmentObs.selected) {
      this.isNew = false
    } else {
      this.initAreasAndForms()
    }
  },
  mounted() {
    if (this.$store.state.assessmentObs.selected) {
      this.initUpdateForms()
    }
    this.loadHazardsToSectors()
  },
  unmounted() {
    this.$store.commit('assessmentObs/SET_SELECTED', null)
    this.reset()
  },
  methods: {
    initUpdateForms() {
      let form
      if (
        assessmentUtil.isAssessmentDecisionArea(
          this.$store.state.assessmentObs.selected
        )
      ) {
        this.selAreaSectors.push(AREA)
        form = deepClone(
          this.$store.state.assessmentObs.selected[ASSESSMENT_DECISION]
        )
        form.hierarchy = deepClone(
          this.$store.state.assessmentObs.selected.hierarchy
        )
        this.areas.push(
          deepClone(
            this.$store.getters['profile/getAreaWithId'](form.hierarchy.areaId)
          )
        )
        this.forms.push(form)
        this.setNextPlanedAssessment(form.nextAssessment)
      } else if (
        assessmentUtil.isAssessmentDecisionSector(
          this.$store.state.assessmentObs.selected
        )
      ) {
        this.selAreaSectors.push(SECTORS)
        const sectorForm = deepClone(
          this.$store.state.assessmentObs.selected[ASSESSMENT_DECISION]
        )
        sectorForm.hierarchy = deepClone(
          this.$store.state.assessmentObs.selected.hierarchy
        )
        this.areas.push(
          deepClone(
            this.$store.getters['profile/getAreaWithId'](
              sectorForm.hierarchy.areaId
            )
          )
        )
        const sectorToEdit = deepClone(
          this.$store.getters['profile/getSectorWithId'](
            this.areas[0],
            sectorForm.hierarchy.sectorId
          )
        )
        this.areas[0].sectors = []
        this.areas[0].sectors.push(sectorToEdit)
        form = { sectorForms: [] }
        form.sectorForms.push(sectorForm)
        this.forms.push(form)
        this.setNextPlanedAssessment(sectorForm.nextAssessment)
      }
    },
    initAreasAndForms() {
      if (this.isNew) {
        const assmAvalServiceToId = this.$store.getters[
          'profile/getAssmAvalServiceToId'
        ](this.avalancheServiceId)
        if (assmAvalServiceToId) {
          this.areas = assmAvalServiceToId.filter().areas
          if (this.areas && this.areas.length > 0) {
            this.areas.forEach((area) => {
              this.selAreaSectors.push(AREA)
              this.forms.push(this.getInitialForm(area))
            })
          }
        }
      }
    },
    getInitialForm(area) {
      const form = {
        hierarchy: { areaId: area.id, sectorId: null, avalServiceId: null },
        measure: null,
        comment: undefined,
        nextAssessment: undefined,
        sectorForms: [],
      }
      area.sectors.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      area.sectors.forEach((sector) => {
        const sectorForm = {
          hierarchy: {
            areaId: area.id,
            sectorId: sector.id,
            avalServiceId: null,
          },
          measure: null,
          comment: undefined,
          nextAssessment: undefined,
        }
        form.sectorForms.push(sectorForm)
      })
      return form
    },
    loadHazardsToSectors() {
      // Die Sektorbeurteilungen für die spätere Anzeige der Gefährdung laden
      const cancelObj = { cancel: null }
      this.$store
        .dispatch('assessmentObs/loadSectorsFromToday', {
          type: ASSESSMENT_SECTOR,
          cancel: cancelObj,
        })
        .then(() => {
          this.forms.forEach((areaForm) => {
            if (areaForm.sectorForms) {
              areaForm.sectorForms.forEach((sectorForm) => {
                const latestSectorAssessmentObs = this.$store.getters[
                  'assessmentObs/getLatestSectorToSectorId'
                ](sectorForm.hierarchy.sectorId)
                const latestSector = latestSectorAssessmentObs.evaluation
                if (latestSector && latestSector[HAZARD_TARGET]) {
                  sectorForm[HAZARD_TARGET] = latestSector[HAZARD_TARGET]
                  sectorForm.hazardTargetDate =
                    latestSectorAssessmentObs.modifyDate
                }
              })
            }
          })
        })
        .catch((reason) => {
          console.error('Error loading sectors from today: (' + reason + ').')
        })
      Cancel.addCancel(cancelObj.cancel)
    },
    commentStateArea(idx) {
      if (!this.forms[idx] || !this.forms[idx].comment) {
        return null
      }
      return (
        this.forms[idx].comment.length >= MIN_COMMENT_LENGTH &&
        this.forms[idx].comment.length <= MAX_COMMENT_LENGTH
      )
    },
    commentStateSector(idxArea, idxSector) {
      if (
        !this.forms[idxArea] ||
        !this.forms[idxArea].sectorForms[idxSector] ||
        !this.forms[idxArea].sectorForms[idxSector].comment
      ) {
        return null
      }
      const sectorComment = this.forms[idxArea].sectorForms[idxSector].comment
      return (
        sectorComment.length >= MIN_COMMENT_LENGTH &&
        sectorComment.length <= MAX_COMMENT_LENGTH
      )
    },
    onSubmit() {
      if (this.completeAndValid()) {
        if (this.processing === true) {
          return
        }
        this.processing = true
        const self = this
        let parameter = this.prepareAssessmentObsForSubmit()
        if (!this.isNew) {
          parameter = parameter[0]
        }
        this.$store
          .dispatch('assessmentObs/submitAssessmentObs', parameter)
          .then(
            () => {
              self.processing = false
              self.routeBack()
            },
            (reason) => {
              console.error('Error submitting MeasureAssessmentInput:' + reason)
              self.processing = false
            }
          )
      }
    },
    // eslint-disable-next-line complexity
    completeAndValid() {
      // Mindestens ein Gebiets- oder Sektorentscheid muss ausgefüllt werden
      let countComplete = 0
      let isSet = 0
      let isValid = true
      for (let idx = 0; idx < this.forms.length; idx++) {
        if (this.selAreaSectors[idx] === AREA) {
          isSet = this.forms[idx].measure !== null ? 1 : 0
          countComplete += isSet
          isValid = this.isItemValid(isValid, true, idx, undefined)
        } else {
          for (
            let innerIx = 0;
            innerIx < this.forms[idx].sectorForms.length;
            innerIx++
          ) {
            isSet =
              this.forms[idx].sectorForms[innerIx].measure !== null ? 1 : 0
            countComplete += isSet
            isValid = this.isItemValid(isValid, false, idx, innerIx)
          }
        }
      }
      this.isComplete = countComplete > 0
      return this.isComplete && isValid
    },
    // eslint-disable-next-line complexity
    prepareAssessmentObsForSubmit() {
      const nexPlanedAss = this.getNexPlanedAssessmentDateTime()
      const measureAssessObs = []
      let submitForm

      for (let idx = 0; idx < this.forms.length; idx++) {
        const form = this.forms[idx]
        if (this.selAreaSectors[idx] === AREA && form.measure !== null) {
          // Ausgefüllte Gebietsbeurteilung
          form.sectorForms = undefined
          form.nextAssessment = nexPlanedAss
          submitForm = {
            [ASSESSMENT_DECISION]: form,
            type: ASSESSMENT_DECISION,
          }
          submitForm.hierarchy = form.hierarchy
          submitForm.hierarchy.avalServiceId = this.avalancheServiceId
          submitForm[ASSESSMENT_DECISION].hierarchy = undefined
          if (submitForm[ASSESSMENT_DECISION].comment === '') {
            submitForm[ASSESSMENT_DECISION].comment = null
          }
          submitForm.location = this.getLocationtoArea(
            submitForm.hierarchy.areaId
          )
          measureAssessObs.push(submitForm)
        } else if (this.selAreaSectors[idx] === SECTORS) {
          for (
            let innerIx = 0;
            innerIx < this.forms[idx].sectorForms.length;
            innerIx++
          ) {
            const sectorForm = this.forms[idx].sectorForms[innerIx]
            if (sectorForm.measure !== null) {
              // Ausgefüllte Sektorbeurteilung
              sectorForm.nextAssessment = nexPlanedAss
              submitForm = {
                [ASSESSMENT_DECISION]: sectorForm,
                type: ASSESSMENT_DECISION,
              }
              submitForm.hierarchy = sectorForm.hierarchy
              submitForm.hierarchy.avalServiceId = this.avalancheServiceId
              submitForm[ASSESSMENT_DECISION].hierarchy = undefined
              if (submitForm[ASSESSMENT_DECISION].comment === '') {
                submitForm[ASSESSMENT_DECISION].comment = null
              }
              submitForm.location = this.getLocationtoSector(
                submitForm.hierarchy.areaId,
                submitForm.hierarchy.sectorId
              )
              measureAssessObs.push(submitForm)
            }
          }
        }
      }
      return measureAssessObs
    },
    /*eslint-enable complexity*/
    getLocationtoArea(areaId) {
      return this.$store.getters['profile/getAreaLocationToAreaId'](areaId)
    },
    getLocationtoSector(areaId, sectorId) {
      return this.$store.getters['profile/getSectorLocationToSectorId'](
        areaId,
        sectorId
      )
    },
    changedMeasureDecision() {
      this.isComplete = null
    },
    getNexPlanedAssessmentDateTime() {
      const planedDate = this.nextPlanedAssessment.date
      const planedTime = this.nextPlanedAssessment.time

      if (planedDate && planedTime) {
        return moment(planedDate + 'T' + planedTime)
      }
      if (planedDate && !planedTime) {
        return moment(planedDate)
      }
      return null
    },
    getHazard(sectorForm) {
      if (sectorForm && sectorForm[HAZARD_TARGET]) {
        const hazardArray = []
        let hazardIconClass = ''

        switch (sectorForm[HAZARD_TARGET]) {
          case enums.assessment.hazard[0]:
            hazardIconClass = 'fa-solid fa-check-circle'
            break
          case enums.assessment.hazard[1]:
            hazardIconClass = 'fa-solid fa-exclamation-circle'
        }

        hazardArray.push(hazardIconClass)
        hazardArray.push(
          i18n.global.t('assessment.sector.label.title') +
            ': ' +
            i18n.global.t('assessment.hazard.' + sectorForm[HAZARD_TARGET])
        )
        hazardArray.push(
          moment(sectorForm.hazardTargetDate).format('D.M.YYYY HH:mm')
        )

        return hazardArray
      }
      return null
    },
    setNextPlanedAssessment(nextPlanedAssessmentDate) {
      if (nextPlanedAssessmentDate) {
        const nextPlanedMoment = moment.utc(nextPlanedAssessmentDate).local()
        this.nextPlanedAssessment.date = nextPlanedMoment.format('YYYY-MM-DD')
        this.nextPlanedAssessment.time = nextPlanedMoment.format('HH:mm:ss')
      }
    },
    routeBack() {
      this.routeBackAssessmentTypes(this.isNew)
    },
    reset() {
      this.areas = []
      this.selAreaSectors = []
      this.forms = []
      this.isNew = true
      this.processing = null
      this.isComplete = null
      this.nextPlanedAssessment = {}
    },
    isItemValid(oldIsValid, isArea, areaIdx, sectorIdx) {
      if (!oldIsValid) {
        return oldIsValid
      }
      if (isArea) {
        return !(this.commentStateArea(areaIdx) === false)
      } else {
        return !(this.commentStateSector(areaIdx, sectorIdx) === false)
      }
    },
  },
}
</script>

<style scoped>
.fa-exclamation-circle {
  color: var(--icon-color-wb-yellow);
}
.fa-check-circle {
  color: var(--icon-color-wb-green);
}
.label {
  font: bold 12px Helvetica;
}
.sector-title {
  font-weight: bold;
}
.card {
  margin-bottom: 10px;
}
.card-body {
  padding-bottom: 0;
}
:deep(.card-header) {
  font-weight: bolder;
  padding-left: 10px;
  padding-right: 10px;
}
.hr-sector {
  border: 0;
  width: 100%;
  height: 2px;
  background-color: lightgray;
}
.form-control.is-invalid,
.custom-select.is-invalid {
  border-width: medium;
}
</style>

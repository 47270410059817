<template>
  <div>
    <Exception />
    <DraggableModal />
    <Feedback />
    <div id="accordion">
      <Navigation @moment="onMomentChange" />
      <MapComponent :layer="layer" />
    </div>
    <div class="main-content">
      <router-view :moment="moment"></router-view>
    </div>
  </div>
</template>

<script>
import emitter from './components/EventHub'
import moment from 'moment'
import Navigation from './components/Navigation'
import DraggableModal from './components/DraggableModal'
import MapComponent from './components/MapComponent'
import Exception from './components/Exception'
import Feedback from '@/components/observation/feedback/Feedback.vue'

export default {
  name: 'App',
  components: {
    Feedback,
    Navigation,
    MapComponent,
    Exception,
    DraggableModal,
  },
  data() {
    return {
      layer: null,
      moment: moment(),
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.unloadHandler)
  },
  created() {
    if (this.isMobile() === true) {
      this.$store.commit('SET_MOBILEPLATFORM', true)
    } else {
      this.$store.commit('SET_MOBILEPLATFORM', false)
    }
  },
  mounted() {
    // Layer muss auf false gesetzt werden, da dies sonst bei F5/Browser-Refresh nicht passiert. Wird sonst beim routen in main.ts gemacht
    emitter.emit('layer', false)
    // get observer groups once user set (to customize menu tabs and to pass over info along w/ avalanche)
    this.loadAppData()
    // prevent user from dragdropping files outside of the dedicated drag&drop area (this would open the file in the browser,
    // a return only w/ browser back button thus loosing info on forms)
    this.disableGeneralDragDrop()
  },
  methods: {
    onMomentChange: function (value) {
      this.moment = value
    },
    isMobile() {
      let check = false
      const funk = function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        ) {
          check = true
        }
      }
      funk(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    loadAppData() {
      let uid = this.$store.state.user.user.id
      // Temp fix for deep link problem: settings/profile/768c9e05-7baa-477f-9b3d-1a3d292ae0d0 => settings/profile/768c9e05-7baa-477f-9b3d-1a3d292ae0d0&state=5744895a...
      uid = uid.substr(0, 36)

      const promises = []
      promises.push(this.$store.dispatch('profile/loadUserGroups'))
      promises.push(this.$store.dispatch('user/loadContactById', uid))
      promises.push(
        this.$store.dispatch('profile/loadAssessAvalSafetyServices')
      )
      promises.push(this.$store.dispatch('studyplot/loadStudyplots'))

      // Usergroups, Users, detaillierter eigener User und Lawinendienste laden
      Promise.all(promises).then(
        (responses) => {
          // Default locale setzen
          if (
            responses &&
            responses[1] &&
            responses[1].data &&
            responses[1].data.language
          ) {
            this.$store.commit(
              'user/SET_USER_LOCALE',
              responses[1].data.language
            )
            console.log('Set default language: ' + responses[1].data.language)
          }
          // laod last location from yesterday
          this.$store.dispatch('observation/getLastLocationFromYesterday')
        },
        (reason) => {
          console.error('Error loading AppStart data' + reason)
        }
      )
    },
    disableGeneralDragDrop() {
      const dropzoneIdII = 'dropzoneII'

      window.addEventListener('dragenter', function (e) {
        if (e.target.id !== dropzoneIdII) {
          e.preventDefault()
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }
      })

      window.addEventListener('dragover', function (e) {
        if (e.target.id !== dropzoneIdII) {
          e.preventDefault()
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }
      })

      window.addEventListener('drop', function (e) {
        if (e.target.id !== dropzoneIdII) {
          e.preventDefault()
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }
      })
    },
    unloadHandler() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
        console.debug('Reload initiated...')
      }
    },
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.collapsing {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  -webkit-transition: none !important;
}
.vdp-datepicker__calendar {
  left: -75px;
}
.main-content {
  margin-top: 80px;
}
body {
  overscroll-behavior: contain;
}

@media print {
  /* on modal open bootstrap adds class "modal-open" to body, so you can handle that case and hide body */
  body.modal-open,
  body.modal-open .btn-outline-warning,
  body.modal-open .when-opened,
  body.modal-open .when-closed {
    visibility: hidden;
  }

  body.modal-open .main-content {
    display: none !important;
  }

  body.modal-open .modal,
  body.modal-open .modal-detail-title-dark {
    visibility: visible;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
  body.modal-open .modal {
    display: contents !important;
  }
  body.modal-open .modal-dialog {
    max-width: 100%;
  }

  body.modal-open .card {
    page-break-inside: avoid;
  }
}
</style>

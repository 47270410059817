import mitt from 'mitt'

type Events = {
  showCommonLayers: boolean
  showLegend: boolean
  showFilter: boolean
  showLayer: boolean
  layer: Record<string, unknown>
}

const emitter = mitt<Events>()
export default emitter

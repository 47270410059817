<template><span /></template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'DetailModal',
  props: ['obsIds', 'detId', 'showEdit', 'aggregate', 'aval', 'nested'],
  watch: {
    obsIds: {
      handler(newValue) {
        if (newValue != null && newValue.length > 0) {
          emitter.emit('showObservation', {
            obsIds: this.obsIds,
            aval: this.aval,
            aggregate: this.aggregate,
            nested: this.nested,
          })
        }
      },
      deep: true,
    },
    aval: {
      handler(newValue) {
        if (newValue != null && Object.keys(newValue).length !== 0) {
          emitter.emit('showObservation', {
            obsIds: this.obsIds,
            aval: this.aval,
            aggregate: this.aggregate,
            nested: this.nested,
          })
        }
      },
      deep: true,
    },
  },
}
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`common-layers-popup${_ctx.mobile ? '-mobile' : ''} ${_ctx.context}-context`)
      }, [
        _createVNode(_component_b_form_group, {
          label: _ctx.$t('commonLayers.baseLayer.title')
        }, {
          default: _withCtx(({ ariaDescribedby }) => [
            _createVNode(_component_b_form_radio_group, {
              id: "base-layer-radio-buttons",
              modelValue: _ctx.baseLayer,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.baseLayer) = $event)),
              options: _ctx.baseLayerOptions,
              "aria-describedby": ariaDescribedby,
              name: "radio-options",
              style: {"pointer-events":"all"},
              stacked: "",
              onChange: _ctx.handleBaseLayerChange
            }, null, 8, ["modelValue", "options", "aria-describedby", "onChange"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_b_form_group, {
          label: _ctx.$t('commonLayers.additionalLayer.title')
        }, {
          default: _withCtx(({ ariaDescribedby }) => [
            _createVNode(_component_b_form_checkbox_group, {
              id: "additional-layers-checkboxes",
              modelValue: _ctx.additionalLayers,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.additionalLayers) = $event)),
              options: _ctx.additionalLayerOptions,
              "aria-describedby": ariaDescribedby,
              style: {"pointer-events":"all"},
              stacked: "",
              onChange: _ctx.handleAdditionalLayersChange
            }, null, 8, ["modelValue", "options", "aria-describedby", "onChange"])
          ]),
          _: 1
        }, 8, ["label"])
      ], 2))
    : _createCommentVNode("", true)
}
<template>
  <div id="menu" class="container-fluid">
    <div v-if="isSelectedTitleVisible" class="title">
      <b-row class="titleTop">{{ titelRow1 }}</b-row>
      <b-row v-if="alternateSubtitle === ''" class="titleBottom">{{
        titelRow2
      }}</b-row>
      <b-row v-if="alternateSubtitle !== ''" class="titleBottom">{{
        alternateSubtitle
      }}</b-row>
    </div>
    <div class="row">
      <div class="col menu">
        <div class="btn-toolbar" role="toolbar">
          <div class="btn-group-vertical btn-group-sm" role="group">
            <li v-if="!noCalendar" class="btn text-left" active-class="active">
              <div class="nav-link" @click="isVisible = !isVisible">
                <font-awesome-icon
                  icon="fa-solid fa-calendar-alt"
                  fixed-width
                ></font-awesome-icon>
                <span class="d-none d-sm-inline"
                  >&emsp;{{ $t('ui.calendar.title') }}</span
                >
              </div>
              <b-collapse id="collapse-calendar" :visible="calVisible">
                <Calendar></Calendar>
              </b-collapse>
            </li>
            <li
              class="btn text-left"
              active-class="active"
              v-for="route in mainRoutes"
              v-bind:key="
                route.meta && route.meta.main ? route.meta.main : route.id
              "
              role="tablist"
            >
              <router-link
                v-if="
                  isSimple(route) &&
                  (!route.onlyAdmin || isAdmin) &&
                  (!route.onlyAvalancheService || isMemberOfLD) &&
                  (!route.meta?.hideOnMobile || !isMobile)
                "
                :to="route.path"
                class="nav-link"
                active-class="active"
              >
                <a @click="closeVariants">
                  <font-awesome-icon
                    fixed-width
                    :icon="route.class || 'fa-solid fa-times'"
                  />
                </a>
                <span @click="closeVariants" class="d-none d-sm-inline"
                  >&emsp;{{ $t(route.name) }}
                  <font-awesome-icon
                    v-show="route.isExternal"
                    :icon="['fas', 'up-right-from-square']"
                  />
                </span>
              </router-link>
              <span @click="subMenuEntryActivate(route)">
                <MenuVariant
                  v-if="
                    isMainVariant(route) &&
                    (!route.onlyAdmin || isAdmin) &&
                    (!route.onlyAvalancheService || isMemberOfLD)
                  "
                  @subEntryChanged="showSubMenuEntry"
                  @reactivateMainRoute="reactivateTitle"
                  :ref="'variant' + route.meta.main"
                  id="key"
                  :routes="mainVariantRouteMap[route.meta.main]"
                />
              </span>
            </li>
          </div>
        </div>
      </div>
      <div class="col overlay">
        <router-view
          :moment="this.$store.getters['calendar/getCalendar'].getMoment()"
          :calendar="this.$store.getters['calendar/getCalendar']"
        >
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import MenuVariant from './MenuVariant'
import Calendar from './Calendar'
import emitter from '@/components/EventHub'

const DATE_TIME_FORMAT_VIEW_TITLE = 'DD.MM.YY HH:mm'
const DATE_FORMAT_VIEW_TITLE = 'DD.MM.YY'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Menu',
  components: { MenuVariant, Calendar },
  props: ['routes', 'noCalendar'],
  data() {
    return {
      mainVariantRouteMap: {},
      mainRoutes: [],
      selectedTitle: '',
      isVisible: false,
      submenuTitle: '',
      showSubmenu: true,
      titleProp: {}, // title properties set by 'update::mapSubtitle' event
      submenuToReactivate: undefined,
      isAdmin: this.$store.state.user.user.isAdmin,
      isMemberOfLD: this.$store.state.profile.assmAvalServices.length,
    }
  },

  watch: {
    $route(to) {
      this.closeVariants()
      if (!this.isSimple(to)) {
        this.subMenuEntryActivate(to)
      }
    },
  },

  beforeUnmount() {
    this.$store.dispatch('calendar/setVisible', this.isVisible)

    emitter.off('update::mapSubtitle')
  },
  created() {
    this.mainVariantRouteMap = {}
    let routeKey
    /*eslint-disable complexity*/
    this.routes.forEach((route) => {
      if (route.meta && route.meta.main) {
        routeKey = route.meta.main
        if (
          Object.prototype.hasOwnProperty.call(
            this.mainVariantRouteMap,
            routeKey
          )
        ) {
          this.mainVariantRouteMap[routeKey].push(route)
        } else {
          this.mainVariantRouteMap[routeKey] = [route]
        }
        if (!route.meta.variant) {
          this.mainRoutes.push(route)
        }
      } else {
        // Simple route
        this.mainRoutes.push(route)
        if (route.isDefaultRoute && !this.$route.query.openObs) {
          this.$nextTick(() => {
            this.$router.push(route.path)
          })
        }
      }
    })
    /*eslint-enable complexity*/
    this.setSelectedTitleAndVisibility()
  },
  mounted() {
    this.isVisible = this.$store.getters['calendar/isVisible']
    const vm = this
    emitter.on('update::mapSubtitle', (titleProp) => {
      vm.titleProp = titleProp
    })
  },
  updated() {
    this.setSelectedTitleAndVisibility()
  },
  computed: {
    /*eslint-disable complexity*/
    titelRow2() {
      if (this.titelRow1) {
        const since = ' ' + this.$i18n.t('ui.calendar.since')
        const calendar = this.$store.getters['calendar/getCalendar']
        const calPreset = calendar.preset
        const calendarMoment = calendar.moment
        if (!this.selectedTitle.startsWith('avalanche.')) {
          if (this.selectedTitle.includes('bulletin')) {
            return ''
          } else {
            switch (calPreset) {
              case 'current':
                return calendarMoment.format(DATE_TIME_FORMAT_VIEW_TITLE)
              case '1000':
                return (
                  calendarMoment.format(DATE_FORMAT_VIEW_TITLE) +
                  since +
                  ' 10:00'
                )
              case '1700': {
                let displayDay = calendarMoment.format(DATE_FORMAT_VIEW_TITLE)
                if (moment().hours() < 17) {
                  displayDay = moment(calendarMoment)
                    .subtract(1, 'days')
                    .format(DATE_FORMAT_VIEW_TITLE)
                }
                return displayDay + since + ' 17:00'
              }
              case 'calendar':
                return calendarMoment.format(DATE_TIME_FORMAT_VIEW_TITLE)
              default:
                return ''
            }
          }
        } else {
          let displayDay = calendarMoment.format(DATE_TIME_FORMAT_VIEW_TITLE)
          if (this.submenuTitle === 'avalanche.yesterday') {
            displayDay = moment(calendarMoment)
              .subtract(1, 'days')
              .format(DATE_FORMAT_VIEW_TITLE)
          }
          return displayDay
        }
      }
      return ''
    },
    alternateSubtitle() {
      /*Must return a value!*/
      if (this.titleProp) {
        if (this.titleProp.method === 'overwrite') {
          return this.titleProp.subtitle
        } else {
          if (this.titleProp.method === 'append') {
            return (
              this.titelRow2 +
              (this.titleProp.subtitle && this.titleProp.subtitle !== ''
                ? (this.titelRow2 ? ', ' : '') + this.titleProp.subtitle
                : '')
            )
          } else {
            return ''
          }
        }
      }
      return ''
    },
    /*eslint-enable complexity*/
    titelRow1() {
      let row1 = this.$i18n.t(this.selectedTitle)
      if (
        row1 &&
        !this.selectedTitle.startsWith('avalanche.') &&
        this.submenuTitle
      ) {
        row1 += ' ' + this.$i18n.t('pageTitel.' + this.submenuTitle)
      }
      return row1
    },
    calVisible() {
      const isCalendarActive = this.$store.state.calendar.active
      return this.isVisible && isCalendarActive
    },
    isSelectedTitleVisible() {
      return !this.$route.meta?.isTitleRowInvisible
    },
    isMobile() {
      return this.$store.getters.isSmallMobile
    },
  },
  methods: {
    setSelectedTitleAndVisibility() {
      if ((this.$route.name || '').includes('.')) {
        this.selectedTitle = this.$route.name
      }
    },
    isSimple(route) {
      return (
        (route.meta && !route.meta.main && !route.meta.variant) || !route.meta
      )
    },
    isMainVariant(route) {
      return route.meta && route.meta.main && !route.meta.variant
    },
    closeVariants() {
      this.submenuToReactivate = undefined
      this.showSubmenu = false
      this.submenuTitle = ''
      for (const ref in this.$refs) {
        const variant = this.$refs[ref][0]
        variant.close()
      }
    },
    subMenuEntryActivate(routeName) {
      this.showSubmenu = true
      this.submenuToReactivate = undefined
      this.showSubMenuEntry(routeName)
    },
    showSubMenuEntry(routeName) {
      if (
        this.showSubmenu &&
        (!this.submenuToReactivate || routeName === this.submenuToReactivate)
      ) {
        if (routeName.name === this.$route.name) {
          this.submenuTitle = this.$store.getters[
            'filter/getSubmenuEntryToKey'
          ](routeName.name)
        }
      }
    },
    reactivateTitle(mainRoute) {
      this.submenuToReactivate = mainRoute
    },
  },
}
</script>

<style scoped>
#menu .btn-toolbar {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
}
#menu .nav-link {
  background: none;
  border: none;
  padding: 0;
  margin: 10px;
  color: #929292 !important;
}
#menu .nav-link.active {
  font-weight: bold;
  color: #494949 !important;
}
#menu {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.row {
  margin-top: 5px;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.overlay {
  pointer-events: none;
  padding-left: 0;
  padding-right: 0;
  width: auto;
  /* position: static; */
  height: calc(100vh - 80px);
}

.menu {
  flex-basis: 17em;
  flex-grow: 0;
  height: 100%;
}

.title {
  position: absolute;
  z-index: 20;
  left: 50%;
  display: inline-block;
  transform: translate(-50%, 0);
  white-space: nowrap;
  background-color: white;
  padding-left: 2px;
  padding-right: 2px;
}
.titleBottom {
  font-size: small;
  font-weight: normal;
  margin-top: 0;
}
.titleTop {
  font-size: medium;
  font-weight: bold;
}

.menu {
  padding-right: 0;
}

@media (max-width: 36em) {
  .menu {
    padding: 0;
    max-width: fit-content;
    z-index: 1020;
  }
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: auto;
}
</style>

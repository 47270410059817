import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_form_group = _resolveComponent("b-form-group")!

  return _withDirectives((_openBlock(), _createBlock(_component_b_form_group, {
    id: "avalservice-select-form-group",
    label: _ctx.$t('assessment.ui.avalSafetyServiceShort'),
    "label-cols-sm": "4",
    "label-align-sm": "right",
    "label-size": "sm",
    "label-for": "avalservice-select"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_form_select, {
        id: "avalservice-select",
        size: "sm",
        value: _ctx.selAvalService,
        options: _ctx.avalServOptions,
        onChange: _ctx.onAvalServiceChange
      }, null, 8, ["value", "options", "onChange"])
    ], undefined, true),
    _: 1
  }, 8, ["label"])), [
    [_vShow, _ctx.avalServOptions.length > 1]
  ])
}
<template>
  <!-- Gefahrenzeichen -->
  <b-card footer-tag="footer" header-tag="header">
    <template #header>
      <Header
        ref="header"
        :title="$t('ui.dangersign')"
        :obs="form"
        :initialLocation="location"
        :adaptMap="adaptMap"
      />
    </template>
    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click="routeBack()" variant="warning">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button @click="onSubmit()" variant="success"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <div>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="locationState"
              aria-describedby="locationFeedback"
            />
            <b-form-invalid-feedback id="locationFeedback">
              {{ $t('msg.validObservationLocation') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="combinedRequiredState"
              aria-describedby="contentFeedback"
            />
            <b-form-invalid-feedback id="contentFeedback">
              {{ $t('msg.validMinimalContent') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="6">
          <b-form-group :label="$t('observation.label.origin')">
            <b-form-select v-model="form.origin" size="sm" :options="origin" />
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('dangersign.label.whumpfCracksFrequency')"
                :info="$t('help.observation.whumpfCracksFrequency')"
              />
            </template>
            <b-form-select
              id="whumpfCracksFrequency"
              size="sm"
              :options="frequency"
              :state="combinedRequiredState"
              v-model="form.whumpfCracks.frequency"
              @input="clearDependentFrequencyFields"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('dangersign.label.whumpfCracksFlat')"
                :info="$t('help.observation.whumpfCracksFlat')"
              />
            </template>
            <b-form-checkbox
              id="whumpfCracksFlat"
              size="sm"
              v-model="form.whumpfCracks.flat"
              :disabled="
                form.whumpfCracks.frequency === 'NONE' ||
                form.whumpfCracks.frequency === null
              "
            >
              {{ $t('dangersign.flat.true') }}
            </b-form-checkbox>

            <b-container
              style="margin-top: 10px; padding: 0"
              v-show="
                form.whumpfCracks.frequency !== 'NONE' &&
                form.whumpfCracks.frequency !== null &&
                form.whumpfCracks.flat !== true
              "
            >
              <b-row align-v="center">
                <b-col cols="8" md="6" style="padding-right: 0">
                  <Compass
                    class="compass"
                    ref="compass"
                    :selection="selection"
                    v-model="form.whumpfCracks"
                    :colors="colors"
                  />
                </b-col>
                <b-col cols="4" md="6" style="padding-left: 0">
                  <b-button
                    @click="changeLevel(1)"
                    v-model:pressed="compassLevel[1]"
                    :key="compassLevel[1] + '-1'"
                    size="sm"
                    variant="compass-1"
                    class="compass-button"
                    :style="styleObject"
                  >
                    {{ $t('common.flag.true') }}
                  </b-button>
                  <b-button
                    @click="changeLevel(0)"
                    v-model:pressed="compassLevel[0]"
                    :key="compassLevel[0] + '-0'"
                    size="sm"
                    variant="compass-0"
                    class="compass-button"
                    :style="styleObject"
                  >
                    {{ $t('common.flag.false') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('dangersign.label.whumpfCracksExtension')"
                :info="$t('help.observation.whumpfCracksExtension')"
              />
            </template>
            <b-form-select
              id="whumpfCracksExtension"
              :options="extension"
              size="sm"
              v-model="form.whumpfCracks.extension"
              :disabled="
                form.whumpfCracks.frequency === 'NONE' ||
                form.whumpfCracks.frequency === null
              "
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('dangersign.label.snowdriftSize')"
                :info="$t('help.observation.snowdriftSize')"
              />
            </template>
            <b-form-select
              id="snowdriftSize"
              :options="size"
              size="sm"
              :state="combinedRequiredState"
              v-model="form.snowdrift.size"
              @input="clearDependentSnowdriftSizeFields"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="
                  $t('dangersign.label.snowdriftAltitude') +
                  ' [' +
                  $t('common.unit.meterAboveSeaLevel') +
                  ']'
                "
                :info="$t('help.observation.snowdriftAltitude')"
              />
            </template>
            <b-form-select
              id="snowdriftAltitude"
              :options="altitude"
              size="sm"
              v-model="form.snowdrift.altitude"
              :disabled="
                form.snowdrift.size === 'NONE' || form.snowdrift.size === null
              "
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template #label>
              <InfoLabel
                :text="$t('dangersign.label.snowdriftDistribution')"
                :info="$t('help.observation.snowdriftDistribution')"
              />
            </template>
            <b-form-select
              id="snowdriftDistribution"
              :options="distribution"
              size="sm"
              v-model="form.snowdrift.distribution"
              :disabled="
                form.snowdrift.size === 'NONE' || form.snowdrift.size === null
              "
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr v-if="areAvalSignsVisible" />
      <AvalancheSign
        @avalSignsVisibilityUpdated="updateAvalSignsVisibilityUpdated"
        v-model="form"
        :combinedRequiredState="combinedRequiredState"
      />
    </b-form>
  </b-card>
</template>

<script>
import Header from '../observation/Header'
import InfoLabel from '../InfoLabel'
import Compass from '../observation/Compass'
import { i18nMixin } from '../I18N'
import moment from 'moment'
import util from '../../scripts/util.js'
import { GEFAHREN_INDEX_COLORS } from '../styles/AbstractStyle'
import AvalancheSign from './AvalancheSign'
import { deepClone } from '@/scripts/common'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Input',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    Compass,
    AvalancheSign,
    InfoLabel,
  },
  mixins: [i18nMixin],
  data() {
    return {
      location: null,
      selection: null,
      compassLevel: [false, false],
      form: {
        oldAvalanche: null,
        origin: null,
        whumpfCracks: {
          frequency: null,
          extension: null,
          flat: null,
          north: null,
          east: null,
          south: null,
          west: null,
        },
        snowdrift: { size: null, altitude: null, distribution: null },
        avalRelease: { previousDay: null, currentDay: null },
      },
      processing: null,
      formSubmitted: null,
      areAvalSignsVisible: false,
      adaptMap: false,
      new: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
      if (from.path.includes('/observation/list')) {
        vm.adaptMap = true
      }
    })
  },
  created() {
    if (this.$store.state.observation.selected) {
      this.location = deepClone(this.$store.state.observation.selected.location)
      this.new = false
    }
  },
  mounted() {
    if (this.$store.state.observation.selected) {
      this.form = deepClone(this.$store.state.observation.selected.dangersign)
    }
    // if in edit mode within 15minutes w/ field not filled but set in the meantime in another form, the proposal will be active
    // check whether origin has already been set for selected date
    if (
      !this.form.origin &&
      util.existValidOrigin(
        this.$store.state.observation.origin,
        this.$store.state.observation.date
      )
    ) {
      this.form.origin = this.$store.state.observation.origin.selection
    }
  },
  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)
    this.reset()
  },
  methods: {
    onSubmit() {
      this.formSubmitted = true
      this.preSubmitValidate()

      if (this.complete()) {
        if (this.processing === true) {
          return
        }
        this.processing = true

        const self = this
        this.$store
          .dispatch('observation/submitObservation', {
            dangersign: this.form,
            type: 'dangersign',
          })
          .then(
            () => {
              // origin will only be set if not set yet within the last 15 minutes
              // NOTE (20190809/csz): re-definition possible
              // if (!util.existValidOrigin(this.$store.state.observation.origin, this.$store.state.observation.date) && this.form.origin) {
              if (this.form.origin) {
                this.$store.commit('observation/SET_ORIGIN', {
                  selection: this.form.origin,
                  obsdate: this.$store.state.observation.date,
                  cdatetime: moment().valueOf(),
                })
              }
              if (this.form.oldAvalanche === true) {
                this.$store.commit('avalanche/SET_REPORT_OLDER_AVAL', true)
                this.$store.commit(
                  'avalanche/SET_REPORT_OLDER_AVAL_DATE',
                  moment()
                )
              }
              self.processing = false
              self.routeBack()
            },
            (reason) => {
              console.error('Error submitting observation:' + reason)
              self.processing = false
            }
          )
      }
    },
    routeBack() {
      if (this.adaptMap) {
        if (this.new) {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetObsGroupId:
                this.$store.getters['profile/getObserverGroupId'],
              openObs: this.$store.state.observation.selected.id,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    preSubmitValidate() {
      // need to transform bool to string for service
      for (const key in this.form.avalRelease) {
        switch (this.form.avalRelease[key]) {
          case true:
            this.form.avalRelease[key] = 'true'
            break
          case false:
            this.form.avalRelease[key] = 'false'
            break
          default: //
        }
      }
    },
    reset() {
      this.formSubmitted = null
      this.form = {
        whumpfCracks: {
          frequency: null,
          extension: null,
          flat: null,
          north: null,
          east: null,
          south: null,
          west: null,
        },
        snowdrift: { size: null, altitude: null, distribution: null },
        avalRelease: { previousDay: null, currentDay: null },
      }
    },
    changeLevel(level) {
      const org = this.compassLevel[level]
      this.compassLevel = [false, false]
      this.compassLevel[level] = org
      this.selection = this.compassLevel.findIndex((v) => {
        return v
      })
      if (this.selection === -1) {
        this.selection = null
      }
    },
    updateAvalSignsVisibilityUpdated(eventData) {
      this.areAvalSignsVisible = eventData
    },
    complete() {
      return (
        this.combinedRequiredState === true &&
        this.$store.state.observation.location !== null &&
        this.$store.state.observation.date !== null
      )
    },
    clearDependentFrequencyFields() {
      if (
        this.form.whumpfCracks.frequency === null ||
        this.form.whumpfCracks.frequency === 'NONE'
      ) {
        this.form.whumpfCracks.flat = null
        this.form.whumpfCracks.extension = null
        this.form.whumpfCracks.north = null
        this.form.whumpfCracks.east = null
        this.form.whumpfCracks.south = null
        this.form.whumpfCracks.west = null
      }
    },
    clearDependentSnowdriftSizeFields() {
      if (
        this.form.snowdrift.size === null ||
        this.form.snowdrift.size === 'NONE'
      ) {
        this.form.snowdrift.altitude = null
        this.form.snowdrift.distribution = null
      }
    },
  },
  computed: {
    frequency() {
      return this.options('dangersign', 'frequency')
    },
    extension() {
      return this.options('dangersign', 'extension')
    },
    flat() {
      return this.options('dangersign', 'flat')
    },
    size() {
      return this.options('dangersign', 'snowdriftsize')
    },
    distribution() {
      return this.options('dangersign', 'distribution')
    },
    avalanche() {
      return this.options('common', 'flag').concat(
        this.options('dangersign', 'avalancheObservation', true)
      )
    },
    oldAvalanche() {
      return this.options('common', 'flag', null)
    },
    origin() {
      return this.options('observation', 'origin')
    },
    altitude() {
      return [
        { text: this.$i18n.t('ui.select'), value: null },
        1000,
        1200,
        1400,
        1600,
        1800,
        2000,
        2200,
        2400,
        2600,
        2800,
        3000,
        3200,
        3400,
        3600,
        3800,
        4000,
        4200,
      ]
    },
    locationState() {
      if (this.formSubmitted) {
        return this.$store.state.observation.location
      } else {
        return null
      }
    },
    combinedRequiredState() {
      if (this.formSubmitted) {
        return (
          this.form.whumpfCracks.frequency !== null ||
          this.form.snowdrift.size !== null ||
          this.form.avalRelease.previousDay !== null ||
          this.form.avalRelease.currentDay !== null
        )
      } else {
        return null
      }
    },
    styleObject() {
      return {
        '--color-yes': util.frequencyColor(
          false,
          this.form.whumpfCracks.frequency,
          GEFAHREN_INDEX_COLORS
        ),
        '--border-color-yes': util.frequencyColor(
          true,
          this.form.whumpfCracks.frequency,
          GEFAHREN_INDEX_COLORS
        ),
        '--color-no': GEFAHREN_INDEX_COLORS[0],
        '--border-color-no': util.colorLuminance(GEFAHREN_INDEX_COLORS[0], 0.1),
      }
    },
    colors() {
      return [
        GEFAHREN_INDEX_COLORS[0],
        util.frequencyColor(
          false,
          this.form.whumpfCracks.frequency,
          GEFAHREN_INDEX_COLORS
        ),
      ]
    },
  },
}
</script>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.label {
  font: bold 12px Helvetica;
}
.modal-body {
  padding: 0 !important;
}
.card {
  margin-bottom: 10px;
}
.card-body {
  padding-bottom: 0;
}
.card-header {
  padding-left: 10px;
  padding-right: 10px;
}
.compass-button {
  width: 100%;
  margin-bottom: 5px;
}
.btn-compass-0 {
  color: var(--color-no);
  background-color: transparent;
  background-image: none;
  border-color: var(--color-no);
}
.btn-compass-0:hover {
  color: white;
  background-color: var(--color-no);
}
.btn-compass-0.active {
  color: white;
  background-color: var(--color-no);
  box-shadow: 0 0 0 0.2rem var(--border-color-no);
}
.btn-compass-1 {
  color: var(--color-yes);
  background-color: transparent;
  background-image: none;
  border-color: var(--color-yes);
}
.btn-compass-1:hover {
  color: white;
  background-color: var(--color-yes);
}
.btn-compass-1.active {
  color: white;
  background-color: var(--color-yes);
  box-shadow: 0 0 0 0.2rem var(--border-color-yes);
}
.form-control.is-invalid,
.custom-select.is-invalid {
  border-width: medium;
}
</style>

import moment from 'moment'

import Calendar from '../../scripts/Calendar'
import { DateTime } from 'luxon'

// state
const state = {
  moment: moment().startOf('minute'),
  preset: 'current', // current, 1000, 1700
  visible: false,
  active: true,
}

// getters
const getters = {
  getCalendar(state) {
    return new Calendar(state.moment, state.preset)
  },
  getMomentAsLuxon(state) {
    return DateTime.fromISO(state.moment.toISOString())
  },
  isVisible(state) {
    return state.visible
  },
}

// actions
const actions = {
  setMoment({ commit }, value) {
    commit('SET_MOMENT', value)
  },
  setPreset({ commit }, preset) {
    commit('SET_PRESET', preset)
  },
  setVisible({ commit }, flag) {
    commit('SET_VISIBLE', flag)
  },
  setActive({ commit }, flag) {
    commit('SET_ACTIVE', flag)
  },
}

// mutations
const mutations = {
  SET_MOMENT(state, value) {
    state.moment = value
  },
  SET_PRESET(state, preset) {
    state.preset = preset
  },
  SET_VISIBLE(state, flag) {
    state.visible = flag
  },
  SET_ACTIVE(state, value) {
    state.active = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

// eslint-disable-next-line complexity
export const getSizeArray = function (avalanche) {
  const presentSizes = [0, 0, 0, 0, 0]
  if (avalanche.avalancheSize) {
    switch (avalanche.avalancheSize) {
      case 'ONE':
        presentSizes[0] = 1
        break
      case 'TWO':
        presentSizes[1] = 1
        break
      case 'THREE':
        presentSizes[2] = 1
        break
      case 'FOUR':
        presentSizes[3] = 1
        break
      case 'FIVE':
        presentSizes[4] = 1
        break
      default:
        break
    }
  } else {
    if (avalanche.sizeAspectCounts && avalanche.sizeAspectCounts.length > 0) {
      // eslint-disable-next-line complexity
      avalanche.sizeAspectCounts.forEach((countObject) => {
        switch (countObject.avalancheSize) {
          case 'ONE':
            if (typeof countObject.count === 'number') {
              presentSizes[0] += countObject.count
            } else {
              presentSizes[0] += parseInt(countObject.count)
            }
            break
          case 'TWO':
            if (typeof countObject.count === 'number') {
              presentSizes[1] += countObject.count
            } else {
              presentSizes[1] += parseInt(countObject.count)
            }
            break
          case 'THREE':
            if (typeof countObject.count === 'number') {
              presentSizes[2] += countObject.count
            } else {
              presentSizes[2] += parseInt(countObject.count)
            }
            break
          case 'FOUR':
            if (typeof countObject.count === 'number') {
              presentSizes[3] += countObject.count
            } else {
              presentSizes[3] += parseInt(countObject.count)
            }
            break
          case 'FIVE':
            if (typeof countObject.count === 'number') {
              presentSizes[4] += countObject.count
            } else {
              presentSizes[4] += parseInt(countObject.count)
            }
            break
          default:
            break
        }
      })
      return presentSizes
    }
    if (avalanche.details && avalanche.details.length > 0) {
      // eslint-disable-next-line complexity
      avalanche.details.forEach((countObject) => {
        switch (countObject.avalancheSize) {
          case 'ONE':
            if (typeof countObject.count === 'number') {
              presentSizes[0] += countObject.count
            } else {
              presentSizes[0] += parseInt(countObject.count)
            }
            break
          case 'TWO':
            if (typeof countObject.count === 'number') {
              presentSizes[1] += countObject.count
            } else {
              presentSizes[1] += parseInt(countObject.count)
            }
            break
          case 'THREE':
            if (typeof countObject.count === 'number') {
              presentSizes[2] += countObject.count
            } else {
              presentSizes[2] += parseInt(countObject.count)
            }
            break
          case 'FOUR':
            if (typeof countObject.count === 'number') {
              presentSizes[3] += countObject.count
            } else {
              presentSizes[3] += parseInt(countObject.count)
            }
            break
          case 'FIVE':
            if (typeof countObject.count === 'number') {
              presentSizes[4] += countObject.count
            } else {
              presentSizes[4] += parseInt(countObject.count)
            }
            break
          default:
            break
        }
      })
      return presentSizes
    }
  }
  return presentSizes
}

import { Tile as TileLayer } from 'ol/layer'
import { XYZ as XYZSource } from 'ol/source'
import TileGrid from 'ol/tilegrid/TileGrid'

// until this resolution, we show the swisstopo maps, for anything with a bigger resolution, we show lakes and borders
export const resolutionLimit = 160

const resolutions = [
  4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000,
  750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5,
]

function getTileLayer(
  layer: string,
  time: string,
  fileFormat: string,
  opacity?: number,
  maxResolution?: number
): TileLayer<XYZSource> {
  return new TileLayer({
    source: new XYZSource({
      attributions: '&copy; Swisstopo',
      url: `https://wmts.geo.admin.ch/1.0.0/${layer}/default/${time}/21781/{z}/{y}/{x}.${fileFormat}`,
      projection: 'EPSG:21781',
      tileGrid: new TileGrid({
        origin: [420000, 350000],
        tileSize: 256,
        resolutions,
      }),
    }),
    opacity,
    maxResolution,
  })
}

export function colorMapTileLayer(): TileLayer<XYZSource> {
  return getTileLayer(
    'ch.swisstopo.pixelkarte-farbe',
    'current',
    'jpeg',
    0.5,
    resolutionLimit
  )
}

export function arialImageryTileLayer(): TileLayer<XYZSource> {
  return getTileLayer(
    'ch.swisstopo.swissimage-product',
    'current',
    'jpeg',
    0.5,
    resolutionLimit
  )
}

export function slopeClassesTileLayer(): TileLayer<XYZSource> {
  return getTileLayer(
    'ch.swisstopo.hangneigung-ueber_30',
    'current',
    'png',
    0.35,
    resolutionLimit
  )
}

'use strict'

import { Aspect, ASPECTS } from '@/model/commonModels'

interface ExpositionItem {
  startAngle: number
  endAngle: number
}

function createPoint(angleDegree: number, radius: number): number[] {
  const roundFloat = (value: number): number => Math.round(value * 100) / 100

  const angleRadiant = (180 - angleDegree) * (Math.PI / 180)
  return [
    roundFloat(Math.sin(angleRadiant) * radius),
    roundFloat(Math.cos(angleRadiant) * radius),
  ]
}

function createPiePath(
  startAngleDegree: number,
  endAngleDegree: number,
  arcOnly: boolean,
  startPoint: number[],
  endPoint: number[],
  radius: number
) {
  const largeArchFlag =
    (endAngleDegree > startAngleDegree &&
      endAngleDegree - startAngleDegree > 180) ||
    (startAngleDegree > endAngleDegree &&
      startAngleDegree - endAngleDegree < 180)
      ? '1'
      : '0'
  const path = arcOnly
    ? `M ${startPoint.join(
        ' '
      )} A ${radius} ${radius} 0 ${largeArchFlag} 1 ${endPoint}`
    : `M0,0 L${startPoint.join(
        ','
      )} A${radius},${radius} 0 ${largeArchFlag},1 ${endPoint.join(',')} Z`
  return path
}

export default class Exposition {
  exposition: ExpositionItem

  constructor(aspectFrom: Aspect, aspectTo?: Aspect) {
    this.exposition = Exposition.calculateExpositionAngles(aspectFrom, aspectTo)
  }
  getPie(radius: number, arcOnly: boolean): HTMLElement | undefined {
    try {
      return Exposition.createPie(
        this.exposition.startAngle,
        this.exposition.endAngle,
        radius,
        false,
        arcOnly
      )
    } catch (e) {
      console.error(e)
    }
  }

  private static normalizeAngle(angle: number): number {
    return (angle + 360) % 360
  }

  public static isFullExposition(
    aspectFrom: Aspect,
    aspectTo?: Aspect
  ): boolean {
    const fromIndex = ASPECTS.indexOf(aspectFrom)
    const toIndex = aspectTo ? ASPECTS.indexOf(aspectTo) : undefined

    const isFullExposition =
      fromIndex - 1 === toIndex ||
      fromIndex === toIndex ||
      (fromIndex === 0 && toIndex === ASPECTS.length - 1)

    return !!aspectTo && isFullExposition
  }

  public static calculateExpositionAngles(
    aspectFrom: Aspect,
    aspectTo?: Aspect
  ): ExpositionItem {
    const fromIndex = ASPECTS.indexOf(aspectFrom)
    const toIndex = aspectTo ? ASPECTS.indexOf(aspectTo) : undefined

    if (aspectTo && Exposition.isFullExposition(aspectFrom, aspectTo)) {
      return { startAngle: 0, endAngle: 360 }
    }

    const halfExpositionSize = 360 / 16

    const startAngle = Exposition.normalizeAngle(
      (fromIndex * 2 - 1) * halfExpositionSize
    )

    let endAngle
    if (!toIndex) {
      endAngle = Exposition.normalizeAngle(startAngle + 2 * halfExpositionSize)
    } else {
      endAngle = Exposition.normalizeAngle(
        (toIndex * 2 + 1) * halfExpositionSize
      )
    }

    return { startAngle, endAngle }
  }

  public static createPie(
    startAngleDegree: number,
    endAngleDegree: number,
    radius: number,
    borderless: boolean,
    arcOnly: boolean
  ): HTMLElement | undefined {
    if (Math.abs(endAngleDegree - startAngleDegree) === 0) return
    let pie
    if (Math.abs(endAngleDegree - startAngleDegree) >= 360) {
      pie = document.createElement('circle')
      pie.setAttribute('r', `${radius}`)
    } else {
      pie = document.createElement('path')
      const startPoint = createPoint(startAngleDegree, radius)
      const endPoint = createPoint(endAngleDegree, radius)
      const path = createPiePath(
        startAngleDegree,
        endAngleDegree,
        arcOnly,
        startPoint,
        endPoint,
        radius
      )
      pie.setAttribute('d', path)
    }
    if (borderless) {
      pie.setAttribute('style', 'stroke:none')
    }
    return pie
  }
}

import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_primevue_calendar = _resolveComponent("primevue-calendar")!

  return (_openBlock(), _createBlock(_component_primevue_calendar, {
    ref: "pv-calendar-ref",
    name: "datepicker",
    modelValue: _ctx.modelJSDate,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelJSDate) = $event)),
      _ctx.onInput
    ],
    showTime: _ctx.isWithTime,
    "show-seconds": false,
    "step-minute": 5,
    hourFormat: "24",
    "show-icon": _ctx.showIcon,
    showOnFocus: true,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.calendarClick())),
    "min-date": _ctx.minJSDate,
    "max-date": _ctx.maxJSDate,
    inline: _ctx.isInline,
    "date-format": "dd.mm.yy",
    touchUI: _ctx.isMobile,
    "base-z-index": 99999,
    inputClass: "pv-calendar-input",
    class: _normalizeClass(_ctx.isInline ? '' : 'pv-calendar'),
    appendTo: "body",
    disabled: _ctx.disabled,
    "disabled-dates": _ctx.disabledJSDates
  }, null, 8, ["modelValue", "showTime", "show-icon", "onUpdate:modelValue", "min-date", "max-date", "inline", "touchUI", "class", "disabled", "disabled-dates"]))
}
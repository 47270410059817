<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b>{{ title }}</b>
      </b-col>
      <b-col cols="6" class="symbols">
        <div class="symbol-group">
          <!-- order is inverted as flex-direction: row-reverse is used to keep the elements on the right, see class symbol-group -->
          <div class="symbol-column icon infospan">
            <span @click="show">
              <font-awesome-icon class="icon" icon="fa-solid fa-info-circle" />
            </span>
            <b-modal
              ref="info-modal"
              :title="modalTitle"
              hide-footer
              no-fade
              centered
            >
              <div class="info-modal">{{ modalText }}</div>
            </b-modal>
          </div>
          <div
            class="symbol-column map-symbol"
            @click="toggleMap"
            v-if="!disableMap"
          >
            <font-awesome-icon icon="fa-solid fa-map-marked-alt right" />
          </div>
          <div class="symbol-column-date" @click="toggleDatepicker">
            <span class="date">{{ formattedDate }}</span>
            &nbsp;&nbsp;
            <font-awesome-icon icon="fa-solid fa-calendar-alt" />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-form class="dateForm" v-if="datepicker">
      <b-container>
        <b-row align-h="center">
          <CalendarComponent
            v-model="dateString"
            name="datepicker"
            @update:model-value="setDate"
            :max="disabledDates"
            inline
            :show-icon="false"
          />
        </b-row>
      </b-container>
    </b-form>

    <b-form v-show="map" class="mapForm" style="height: 680px">
      <MapComponent
        ref="mapComponent"
        :obs="obs"
        @coord="setCoord"
        :adapt-map="adaptMap"
      />
      <b-row>
        <div class="col-4">
          {{ $t('common.coord') }}
        </div>
        <div class="col-8">
          <b-form-group>
            <b-form-input
              size="sm"
              v-model="locationText"
              :state="locationState"
              aria-describedby="coordFeedback"
            />
            <b-form-invalid-feedback id="coordFeedback">
              {{ $t('msg.validCoordinate') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-row>
      <b-row>
        <div class="col-4">
          <b-button
            variant="warning"
            :disabled="!hasLocationFromYesterday"
            @click="setLocationFromYesterday"
            ><font-awesome-icon icon="fa-solid fa-map-pin"></font-awesome-icon
            >&nbsp;{{ $t('ui.fromPreviousDay') }}</b-button
          >
        </div>
        <div class="col-8 text-right">
          <b-button
            :disabled="!location"
            variant="success"
            @click="closeLocation"
            >OK</b-button
          >
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import moment from 'moment'
import CalendarComponent from '../calendar/CalendarComponent'

import MapComponent from '../observation/MapOL'
import Point from 'ol/geom/Point'
import { i18n } from '@/plugins/i18n'
import { getDrawControl } from '@/scripts/avalancheInputMap/avalancheInputControls'
import emitter from '@/components/EventHub'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Header',
  components: {
    MapComponent,
    CalendarComponent,
  },
  props: ['title', 'disableMap', 'obs', 'initialLocation', 'adaptMap'],
  data() {
    return {
      date: this.$store.state.observation.date,
      datepicker: false,
      map: !this.$store.state.observation.location && !this.disableMap,
      disabledDates: moment().toISOString(),
      locationText: '',
      mapState: null,
      location: null,
    }
  },
  computed: {
    modalTitle: function () {
      if (!this.disableMap) {
        return i18n.global.t('help.header.title')
      } else {
        return i18n.global.t('help.header.title')
      }
    },
    modalText: function () {
      if (!this.disableMap) {
        return i18n.global.t('help.observation.date')
      } else {
        return i18n.global.t('help.observation.date')
      }
    },
    dateString: {
      get() {
        return moment(this.date).format('DD.MM.YYYY')
      },
      set(val) {
        this.date = moment(val, 'DD.MM.YYYY').toDate()
      },
    },
    formattedDate: function () {
      return moment(this.date).format('DD.MM.YY')
    },
    locationState() {
      if (!this.locationText) {
        return null
      }
      return (
        this.locationText &&
        this.locationText.match(
          /^(2[0-9]{6}(\.[0-9]{1,3})?\/1[0-9]{6}(\.[0-9]{1,3})?)$/
        ) !== null
      )
    },
    hasLocationFromYesterday() {
      return (
        this.$store.state.observation.lastLocationFromYesterday !== undefined
      )
    },
    hasInitialLocation() {
      return this.initialLocation == null
    },
    useLastLocationFromStore() {
      const obsStore = this.$store.state.observation

      return (
        this.hasInitialLocation &&
        obsStore.location &&
        obsStore.locationDate === obsStore.date
      )
    },
  },
  watch: {
    locationText: function () {
      if (this.locationState) {
        this.setCoord(this.convertFromTextToGeoJson(this.locationText))
      } else if (this.locationText === '') {
        this.setCoord(null)
      }
    },
  },
  mounted() {
    if (this.initialLocation) {
      this.location = this.initialLocation
      this.recenterMap(this.location)
    } else if (this.useLastLocationFromStore) {
      this.location = this.$store.state.observation.location
      this.recenterMap(this.location)
    }
    this.setCoord(this.location)
    emitter.on('drawLocation', (location) => {
      this.setCoord(location)
    })
  },
  beforeUnmount() {
    emitter.off('drawLocation')
  },
  methods: {
    show() {
      this.$refs['info-modal'].show()
    },
    closeLocation() {
      this.map = false
      window.scrollTo(0, 0) // YT#SLPPWB-387
    },
    recenterMap(location) {
      const positionPoint = new Point([
        location.coordinates[0],
        location.coordinates[1],
      ])
      const transformedPoint = positionPoint
        .transform('EPSG:4326', 'EPSG:21781')
        .getCoordinates()
      this.$refs.mapComponent.map.getView().setCenter(transformedPoint)
      this.$refs.mapComponent.map.getView().setZoom(24)
    },
    toggleDatepicker() {
      this.datepicker = !this.datepicker
      if (this.datepicker) {
        this.mapState = this.map
        this.map = false
      } else {
        this.map = this.mapState
      }
    },
    toggleMap() {
      this.map = !this.map
      this.datepicker = false
      this.$nextTick(function () {
        this.$refs.mapComponent.map.updateSize()
      })
    },
    setDate(val) {
      this.dateString = val
      this.datepicker = false
      this.$store.commit('observation/SET_DATE', this.date)
      this.map = this.mapState
    },
    setCoord(coord) {
      if (coord) {
        const positionPoint = new Point([
          coord.coordinates[0],
          coord.coordinates[1],
        ])
        // prepare point for text
        const transformedPoint = positionPoint
          .transform('EPSG:4326', 'EPSG:2056')
          .getCoordinates()
        this.locationText =
          Math.round(transformedPoint[0]).toString() +
          '/' +
          Math.round(transformedPoint[1])
        this.location = coord
        this.$refs.mapComponent.setCoord(this.location)
        this.$store.commit('observation/SET_LOCATION', this.location)
        this.$store.commit('observation/SET_LOCATION_DATE', this.date)
      } else {
        this.location = null
        this.$refs.mapComponent.setCoord(null)
        this.coordText = ''
      }
    },
    setRegion() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.setPosition,
          this.showError
        )
      } else {
        alert(i18n.global.t('msg.locationNotSupported'))
      }
    },
    setPosition(position) {
      const pointJson = {
        type: 'Point',
        coordinates: [position.coords.longitude, position.coords.latitude],
      }
      this.setCoord(pointJson)
      getDrawControl(this.$refs.mapComponent.map.getControls()).removeDraw(
        this.$refs.mapComponent
      )
      this.zoomToPoint()
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert(i18n.global.t('msg.locationDenied'))
          break
        case error.POSITION_UNAVAILABLE:
          alert(i18n.global.t('msg.locationNotAvailable'))
          break
        case error.TIMEOUT:
          alert(i18n.global.t('msg.locationTimeOut'))
          break
        case error.UNKNOWN_ERROR:
          alert(i18n.global.t('msg.locationUnknown: ') + error.message)
          break
      }
    },
    convertFromTextToGeoJson(geometryText) {
      if (geometryText && geometryText !== 'undefined') {
        const coords = geometryText.split('/')
        if (coords && coords.length === 2) {
          const geoPoint = new Point([parseInt(coords[0]), parseInt(coords[1])])
          const transformedCoord = geoPoint
            .transform('EPSG:2056', 'EPSG:4326')
            .getCoordinates()
          return {
            type: 'Point',
            coordinates: [transformedCoord[0], transformedCoord[1]],
          }
        }
      }
      return null
    },
    setLocationFromYesterday() {
      this.setCoord(this.$store.state.observation.lastLocationFromYesterday)
      this.zoomToPoint()
    },
    zoomToPoint() {
      const positionPoint = new Point([
        this.location.coordinates[0],
        this.location.coordinates[1],
      ])
      const transformedPoint = positionPoint
        .transform('EPSG:4326', 'EPSG:21781')
        .getCoordinates()
      this.$refs.mapComponent.map.getView().setCenter(transformedPoint)
      this.$refs.mapComponent.map.getView().setZoom(24)
    },
  },
}
</script>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.datepicker {
  visibility: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
}

.dateForm,
.mapForm {
  margin-top: 10px;
}

.infospan {
  padding-left: 10px;
  float: right;
  padding-right: 0 !important;
}

.symbol-column {
  padding-right: 10px;
  padding-left: 10px;
}

.symbol-column-date {
  padding-right: 10px;
}

.symbols {
  padding-right: 0;
  padding-left: 0;
}

.mapForm {
  padding-left: 0;
  padding-right: 0;
}

.icon {
  color: #acacac;
}

.symbol-group {
  display: flex;
  flex-direction: row-reverse;
}

.info-modal {
  white-space: pre-wrap;
}
</style>

<template>
  <b-container fluid style="pointer-events: all" :style="cssProps">
    <!-- Object w/ latest measures tables per avalanche service, area. No grouping by sector -->
    <b-card
      v-for="serviceObject in groupedObjects"
      :key="serviceObject.id"
      class="t-aval-safety-service"
    >
      <b-card-title>
        {{ serviceObject.name }}
      </b-card-title>

      <template
        v-for="area in sortedAreas(serviceObject.areas)"
        :key="area.id + 'bs'"
      >
        <br />
        <b-card-sub-title>
          {{ area.name }}
        </b-card-sub-title>
        <br />

        <!-- Tabelle Warnleuchten zu Gebieten -->
        <WarningLightTable v-if="area.warningLight" :area="[area]" />

        <!-- Tabelle Objekte zu Gebieten -->
        <b-table
          small
          hover
          bordered
          responsive
          sticky-header="300px"
          :items="area.objects"
          :fields="objectMeasureFields"
          show-empty
          :empty-text="$t('measure.label.emptyState')"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="table-font t-area"
          tbody-tr-class="area-object-row"
          head-variant="light"
          @row-clicked="showMeasureDetails"
        >
          <template v-slot:head()="data">
            <span class="font-weight-normal">{{ data.label }}</span>
          </template>
          <template v-slot:[`cell(measure.validFrom)`]="data">
            {{ data.item.measure ? formatTimestamp(data.value, '') : '' }}
          </template>
          <template v-slot:[`cell(measure.validTo)`]="data">
            {{
              data.item.measure
                ? formatTimestamp(
                    data.value,
                    $t('measure.label.untilFurtherNotice')
                  )
                : ''
            }}
          </template>
          <template v-slot:[`cell(measure.comment)`]="data">
            <div class="text-truncate text-ellipse">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div style="overflow: hidden; white-space: nowrap">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <b-button
                size="sm"
                @click.stop="
                  addMeasure(area, row.item, row.index, $event.target)
                "
                :title="$t('ui.edit')"
                class="mr-2 wrap-ic edit-button"
                style="display: inline-block"
              >
                <font-awesome-icon icon="fa-solid fa-edit"></font-awesome-icon>
              </b-button>
              <b-button
                size="sm"
                @click.stop="
                  deleteMeasure(area, row.item, row.index, $event.target)
                "
                :title="$t('common.clear')"
                class="mr-2 wrap-ic delete-button"
                style="display: inline-block"
              >
                <font-awesome-icon
                  icon="fa-regular fa-trash-alt"
                ></font-awesome-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </b-card>

    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assessment"
      :aggregate="false"
    />
  </b-container>
</template>

<script>
import moment from 'moment'
import DetailModalAssessment from '@/components/assessment/detail/DetailModalAssessment'
import {
  COLOR_FAVORABLE,
  COLOR_MEDIUM,
  COLOR_UNFAVORABLE,
} from '../styles/AbstractStyle'
import util from '@/scripts/util.js'
import { Canceler } from '@/scripts/requestUtil.js'
import WarningLightTable from '@/components/measure/WarningLightTable.vue'
import { MeasureTableMixin } from './MeasureTableMixin.js'

const canceler = Canceler.getCancelObj()

export default {
  name: 'MeasureTable',
  components: { DetailModalAssessment, WarningLightTable },
  mixins: [MeasureTableMixin],
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      objectMeasureFields: [
        {
          key: 'name',
          label: this.$t('measure.label.object'),
          sortable: true,
          class: 'table-object-cell',
        },
        {
          key: 'actualMeasure.measure',
          label: this.$t('measure.label.state'),
          sortable: true,
          sortByFormatted: true,
          class: 'table-state-cell',
          tdClass: this.findClass,
          formatter: (value, key, item) =>
            this.formatMeasure(item.class, value),
        },
        {
          key: 'actions',
          label: '',
          class: 'table-action-cell',
          thClass: 'table-action-header',
        },
        {
          key: 'measure.type',
          label: this.$t('measure.label.decision'),
          sortable: true,
          sortByFormatted: true,
          class: 'table-decision-cell',
          tdClass: this.findClass,
          formatter: (value, key, item) =>
            this.formatMeasure(item.class, value),
        },
        {
          key: 'measure.validFrom',
          label: this.$t('measure.label.validFrom'),
          sortable: true,
          sortByFormatted: this.sortFormatTS,
          class: 'table-from-cell',
        },
        {
          key: 'measure.validTo',
          label: this.$t('measure.label.validTo'),
          sortable: true,
          sortByFormatted: this.sortFormatTS,
          class: 'table-to-cell',
        },
        {
          key: 'measure.comment',
          label: this.$t('measure.label.comment'),
          class: 'table-comment-cell',
        },
      ],
      latestMeasures: [],
      sortFormatTS: this.sortFormatTimestamp,
    }
  },
  mounted() {
    this.$store.dispatch('calendar/setActive', false)
    this.$store.dispatch('calendar/setPreset', 'current')
    this.refresh()
  },
  beforeUnmount() {
    this.$store.dispatch('calendar/setActive', true)
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },
  computed: {
    groupedObjects() {
      const assAvalserviceObjects =
        this.$store.state.profile.assmAvalServices.map((el) => el.filter())
      this.removeProps(assAvalserviceObjects, ['location'])
      this.prepareAreasWithWarningLightMeasure(assAvalserviceObjects)
      const objectList = assAvalserviceObjects
        .reduce((acc, curr) => acc.concat(curr.areas), [])
        .reduce((acc, curr) => acc.concat(curr.objects), [])
      // undefined if objects undefined...
      if (objectList && objectList.length > 0 && objectList[0] !== undefined) {
        objectList.forEach((object) => {
          const measureObs = this.latestMeasures.find(
            (el) => el.hierarchy.objectId === object.id
          )
          // Es könnte auch eine Warnleuchten-Measure sein, welche mit der areaId verknüpft ist.
          // Daher hier nicht zwingend ein Match.
          if (measureObs) {
            object.measure = this.convertMeasureObsToOldStructure(measureObs)
            object.actualMeasure = measureObs.actualMeasure
          } else {
            // Wenn für ein Objekt keine aktuelle und keine geplante Massnahme vorhanden ist,
            // dann muss die Massnahme 'Keine Massnahme' angezeigt werden.
            object.actualMeasure = this.$store.getters['measure/getNoMeasure']
          }
        })
      }
      return assAvalserviceObjects
    },
    cssProps() {
      // NOTE: use rgba for edge w/ opacity
      // NOTE: precondition: 6-char hex input
      // Source: https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/
      return {
        '--bg-green-color': util.convertHexToRgbA(COLOR_FAVORABLE, 0.75),
        '--bg-yellow-color': util.convertHexToRgbA(COLOR_MEDIUM, 0.75),
        '--bg-red-color': util.convertHexToRgbA(COLOR_UNFAVORABLE, 0.75),
        '--bg-green-R-color': this.hexPortion(COLOR_FAVORABLE, 1, 2),
        '--bg-green-G-color': this.hexPortion(COLOR_FAVORABLE, 3, 2),
        '--bg-green-B-color': this.hexPortion(COLOR_FAVORABLE, 5, 2),
        '--bg-yellow-R-color': this.hexPortion(COLOR_MEDIUM, 1, 2),
        '--bg-yellow-G-color': this.hexPortion(COLOR_MEDIUM, 3, 2),
        '--bg-yellow-B-color': this.hexPortion(COLOR_MEDIUM, 5, 2),
        '--bg-red-R-color': this.hexPortion(COLOR_UNFAVORABLE, 1, 2),
        '--bg-red-G-color': this.hexPortion(COLOR_UNFAVORABLE, 3, 2),
        '--bg-red-B-color': this.hexPortion(COLOR_UNFAVORABLE, 5, 2),
        /* the threshold at which colors are considered "light".
            Range: decimals from 0 to 1, recommended 0.5 - 0.6 */
        '--threshold': 0.5,
      }
    },
    avalServiceIds() {
      return this.$store.getters['profile/getAllAvalServiceIds']
    },
  },
  methods: {
    refresh() {
      this.cancelRequests()
      const cancelObj = { cancel: null }
      const params = {
        avalServiceIds: this.avalServiceIds(),
        cancel: cancelObj,
      }
      this.$store
        .dispatch('measure/loadLatestMeasures', params)
        .then((measures) => {
          this.latestMeasures = measures
          canceler.addCancel(cancelObj.cancel)
        })
        .catch((e) => {
          console.error(
            "An exception occurred by loading 'measure/loadLatestMeasures': " +
              e
          )
          canceler.addCancel(cancelObj.cancel)
        })
    },
    addMeasure(area, object) {
      this.$router.push({
        name: 'MeasureInput',
        params: { objectId: object.id, areaId: area.id },
      })
    },
    deleteMeasure(area, object) {
      this.$router.push({
        name: 'MeasureInput',
        params: { objectId: object.id, preset: 'NO_MEASURE', areaId: area.id },
      })
    },
    // use this for formatting w/ secs
    sortFormatTimestamp(value) {
      return value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
    },
    removeProps(obj, props) {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          switch (typeof obj[prop]) {
            case 'object':
              if (props.indexOf(prop) > -1) {
                delete obj[prop]
              } else {
                this.removeProps(obj[prop], props)
              }
              break
            default:
              if (props.indexOf(prop) > -1) {
                delete obj[prop]
              }
              break
          }
        }
      }
    },
    hexPortion(hexColor, fromInd, len) {
      return parseInt(hexColor.substr(fromInd, len), 16)
    },
    showMeasureDetails(item) {
      if (item.measure) {
        this.assessment = this.$store.getters[
          'measure/getMeasureForDetailModal'
        ](item.measure.measureObs)
        this.$bvModal.show('detailModalAssessment')
      }
    },
    convertMeasureObsToOldStructure(measureObs) {
      if (measureObs?.measure) {
        return {
          objectId: measureObs.hierarchy.objectId,
          areaId: measureObs.hierarchy.areaId,
          type: measureObs.measure.measure,
          validFrom: measureObs.measure.begin
            ? moment(measureObs.measure.begin)
            : undefined,
          validTo: measureObs.measure.end
            ? moment(measureObs.measure.end)
            : undefined,
          comment: measureObs.measure.reason,
          uid: measureObs.uid,
          timestamp: measureObs.timestamp,
          measureObs: measureObs,
        }
      }
      return undefined
    },
    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },
    prepareAreasWithWarningLightMeasure(assAvalserviceObjects) {
      const areaList = assAvalserviceObjects.reduce(
        (acc, curr) => acc.concat(curr.areas),
        []
      )
      // undefined if area undefined...
      if (areaList && areaList.length > 0 && areaList[0] !== undefined) {
        areaList.forEach((area) => {
          const measureObs = this.latestMeasures.find(
            (el) => !el.hierarchy.objectId && el.hierarchy.areaId === area.id
          )
          // Es könnte auch eine Object-Measure sein, welche mit der areaId verknüpft ist.
          // Daher hier nicht zwingend ein Match.
          if (measureObs) {
            area.measure = this.convertMeasureObsToOldStructure(measureObs)
            area.actualMeasure = measureObs.actualMeasure
          } else {
            // Wenn für eine Warnleuchte keine aktuelle und keine geplante Massnahme vorhanden ist,
            // dann muss die Massnahme 'WARNINGLIGHT_OFF' angezeigt werden.
            area.actualMeasure = this.$store.getters['measure/getNoWarnlight']
          }
          area.class = 'warningLight'
        })
      }
    },
    sortedAreas(areaList) {
      return [...areaList].sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}
</script>

<style>
/* Styledefinition wird auch von der hier eingebetteten WarningLightTable.vue gebraucht !!!!
   Als mit dem scope aufpassen! */
.table-font {
  font-size: 92%;
}
.table-object-cell {
  width: 200px;
}
.table-state-cell {
  width: 250px;
}
.table-decision-cell {
  width: 250px;
}
.table-comment-cell {
  width: 370px;
}
.table-bordered .table-action-cell,
.table .thead-light .table-action-header {
  width: 1px;
  border-right: 2px solid black;
}
.text-ellipse {
  text-overflow: ellipsis;
  width: 350px;
}
.bg-green {
  background: var(--bg-green-color);
  /* calculates perceived lightness using the sRGB Luma method
    Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
  --r: calc(var(--bg-green-R-color) * 0.2126);
  --g: calc(var(--bg-green-G-color) * 0.7152);
  --b: calc(var(--bg-green-B-color) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);
  /* shows either white or black color depending on perceived darkness */
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}
.bg-yellow {
  background: var(--bg-yellow-color);
  --r: calc(var(--bg-yellow-R-color) * 0.2126);
  --g: calc(var(--bg-yellow-G-color) * 0.7152);
  --b: calc(var(--bg-yellow-B-color) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}
.bg-red {
  background: var(--bg-red-color);
  --r: calc(var(--bg-red-R-color) * 0.2126);
  --g: calc(var(--bg-red-G-color) * 0.7152);
  --b: calc(var(--bg-red-B-color) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}
</style>

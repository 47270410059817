import { Control } from 'ol/control.js'
import { Collection } from 'ol'
import ModifyDeleteControl from '@/scripts/avalancheInputMap/modifyDeleteControl/ModifyDeleteControl'
import DrawControl from '@/scripts/avalancheInputMap/DrawControl'

export function getModifyDeleteControl(
  controls: Collection<Control>
): ModifyDeleteControl | undefined {
  return controls
    .getArray()
    .find((control) => control instanceof ModifyDeleteControl) as
    | ModifyDeleteControl
    | undefined
}

export function getDrawControl(
  controls: Collection<Control>
): DrawControl | undefined {
  return controls
    .getArray()
    .find((control) => control instanceof DrawControl) as
    | DrawControl
    | undefined
}

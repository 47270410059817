'use strict'

import { enums } from '../../I18N'
import { i18n } from '@/plugins/i18n'

const funcs = {
  /* eslint-disable complexity */
  getMouseOverHtml(snowprofileFeature) {
    let htmlString = ''
    if (snowprofileFeature.elevation) {
      htmlString = 'Höhe: ' + snowprofileFeature.elevation + 'm'
    }
    if (snowprofileFeature.aspect) {
      htmlString += htmlString && htmlString.length > 0 ? ', ' : ''
      htmlString += 'Exposition: ' + snowprofileFeature.aspect
    }
    htmlString += htmlString && htmlString.length > 0 ? '<br />' : ''
    htmlString += funcs.getRBMouseOver(snowprofileFeature)
    htmlString += funcs.getECTMouseOver(snowprofileFeature)

    return htmlString
  },

  getECTMouseOver(snowprofileFeature) {
    let ectText = ''
    let ect1
    let ect2
    snowprofileFeature.ects.forEach((layer) => {
      // SLPPWB-934: Zeilenumbruch anstatt Semikolon von T.Stucki gewünscht
      let newLine = false
      if (ectText && ectText.length > 0) {
        ectText += '<br />'
        newLine = true
      }
      layer.layers.forEach((ect) => {
        const ectParts = []
        if (ectText && ectText.length > 0 && !newLine) {
          ectText += '; '
        }
        newLine = false
        ect1 = ect.score1 || ect.score1 === 0 ? ect.score1 : '-'
        ect2 = ect.score2 || ect.score2 === 0 ? ect.score2 : '-'
        ect1 = ect1 === 31 ? 'nf' : ect1
        ect2 = ect2 === 32 ? 'pp' : ect2
        ect2 = ect2 === 33 ? 'np' : ect2

        if (ect1 === 'nf') {
          ectParts.push('ECT ' + ect1)
        } else {
          ectParts.push('ECT ' + ect1 + ' / ' + ect2)
        }

        if (snowprofileFeature.snowDepth && ect.layerDepth) {
          ectParts.push(
            'Slab=' +
              (snowprofileFeature.snowDepth - ect.layerDepth).toString() +
              ' cm'
          )
        }
        ectText += funcs.getCommaSeperatedStringFromParts(ectParts)
      })
    })
    return ectText
  },

  getRBMouseOver(snowprofileFeature) {
    const NO_TRIGGER = 7
    let rbText = ''
    snowprofileFeature.rbs.forEach((rb) => {
      const rbParts = []
      if (rbText && rbText.length > 0) {
        // SLPPWB-934: Zeilenumbruch anstatt Semikolon von T.Stucki gewünscht
        rbText += '<br />'
      }
      if (rb.score) {
        rbParts.push('RB ' + rb.score.toString())
      }
      if (rb.fractureType && rb.score !== NO_TRIGGER) {
        rbParts.push(
          i18n.global.t(
            'snowprofile.RBStability.' +
              enums.snowprofile.RBStability[rb.fractureType - 1]
          )
        )
      }
      if (
        snowprofileFeature.snowDepth &&
        rb.layerDepth &&
        rb.score !== NO_TRIGGER
      ) {
        rbParts.push(
          'Slab=' +
            (snowprofileFeature.snowDepth - rb.layerDepth).toString() +
            ' cm'
        )
      }
      rbText += funcs.getCommaSeperatedStringFromParts(rbParts)
    })
    if (rbText && rbText.length > 0) {
      rbText += '<br />'
    }
    return rbText
  },
  /* eslint-enable complexity */

  getCommaSeperatedStringFromParts(parts) {
    let part = ''
    parts.forEach((element) => {
      if (part && part.length > 0) {
        part += ', '
      }
      part += element
    })
    return part
  },
}

export default funcs

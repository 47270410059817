'use strict'

import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import store from '../../store/index'

/**
 *  smaller resolutions will have labels
 */
const MOBILE_LABEL_TRESHOLD = 358
const COMMON_TEXT = new Text({
  text: '',
  font: '12px sans-serif',
  fill: new Fill({
    color: '#000',
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 3,
  }),
})

class TextLabel {
  constructor(minZoom) {
    this.minZoom = minZoom || MOBILE_LABEL_TRESHOLD
  }
  get(text, resolution) {
    if (store.state.mobilePlatform && (resolution > this.minZoom || !text)) {
      return
    }
    COMMON_TEXT.setText(text)
    // Wenn der Offset in der Konstanten gesetzt wird, wandert der Text bei der Lawinengefahr langsam noch oben???
    COMMON_TEXT.setOffsetY(-12)
    return COMMON_TEXT
  }
}

export default TextLabel

<template>
  <b-popover
    target="journal-report-button"
    triggers="click"
    v-model:show="showCompactPdfPreparationPopover"
    placement="auto"
    @show="showPopupIfNecessary"
  >
    <template #title>
      <b-button
        @click="showCompactPdfPreparationPopover = false"
        class="close"
        aria-label="Close"
      >
        <span class="d-inline-block" aria-hidden="true">&times;</span>
      </b-button>
      {{ t('common.reporting.compactReportPreparationTitle') }}
    </template>
    <div>
      <b-button
        @click="emitIsCompactPdfPreparation(true)"
        size="sm"
        variant="secondary"
        class="mr-1"
        >{{ firstCharToUppercase(t('common.flag.true')) }}</b-button
      >
      <b-button
        @click="emitIsCompactPdfPreparation(false)"
        size="sm"
        variant="secondary"
        >{{ firstCharToUppercase(t('common.flag.false')) }}</b-button
      >
    </div>
  </b-popover>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { i18n } from '@/plugins/i18n'

const t = i18n.global.t
const emit = defineEmits(['compactPdfPreparation'])
const props = defineProps({
  hasAvalancheServiceName: Boolean,
})
const showCompactPdfPreparationPopover = ref(false)

const emitIsCompactPdfPreparation = (isCompactPreparation: boolean) => {
  showCompactPdfPreparationPopover.value = false
  emit('compactPdfPreparation', isCompactPreparation)
}

const showPopupIfNecessary = (event: Event) => {
  if (!props.hasAvalancheServiceName) {
    event.preventDefault()
    emitIsCompactPdfPreparation(false)
  }
}

const firstCharToUppercase = (strVal: string) => {
  return strVal.charAt(0).toUpperCase() + strVal.slice(1)
}
</script>

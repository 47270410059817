'use strict'

export class SizableSVG {
  constructor(size, viewBox) {
    const svgDef =
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="' +
      size +
      'px" height="' +
      size +
      'px" xml:space="preserve" viewBox="'
    this.viewBox = viewBox || '-25 -25 50 50'
    this.svg = svgDef + this.viewBox + '">'
  }
  createImage(el) {
    const img = new Image()
    img.src = 'data:image/svg+xml,' + escape(this.svg + el + '</svg>')
    return img
  }
}

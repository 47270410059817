'use strict'

import BaseObject from './BaseObject'

export default class Path extends BaseObject {
  constructor(id, name, location, active, type) {
    super(id, name, location, active)
    this.class = 'path'
    this.type = type
  }
  static clone(o) {
    return new this(o.id, o.name, o.location, o.active, o.type)
  }
}

import Style from 'ol/style/Style'
import TextLabel from '../TextLabel'
import { AbstractStyle } from '../AbstractStyle'
import moment from 'moment'
import { getDangerLegend } from '@/components/styles/dangerStyling/dangerLegend'
import {
  PLUS_STYLE,
  MAP_RADIUS_MAX,
  MAP_RADIUS_MIN,
  TRIANGLE_STYLE,
  MINUS_STYLE,
  NEUTRAL_STYLE,
} from '@/components/styles/dangerStyling/dangerStylingConsts'
import { getMouseOverText } from '@/components/styles/dangerStyling/dangerMouseOver'
import { DANGER_SUBDIVISIONS, DangerDto } from '@/model/dangerModels'
import { FeatureLike } from 'ol/Feature'
import Text from 'ol/style/Text'
import {
  getArcFullSymbolCached,
  getArcLineSymbolCached,
  getSmallCircleSymbolCached,
} from '@/components/styles/dangerStyling/dangerStyleCache'

export class DangerStyle extends AbstractStyle {
  constructor(locale: string) {
    super(locale)
    moment.locale(locale)
  }
  styleFunction(feature: FeatureLike, resolution: number): Style[] {
    const dangerFeat: DangerDto = feature.get('danger')
    const styles = getShapeStyles(dangerFeat)
    if (styles && styles.length > 0) {
      const altitudeText = getMapAltitudeText(dangerFeat, resolution)
      const detailSymbol = getSubdivisionSymbol(dangerFeat, altitudeText)
      if (detailSymbol != null) {
        styles.push(...detailSymbol)
      } else {
        styles.push(
          new Style({
            image: TRIANGLE_STYLE,
            text: altitudeText,
            zIndex: Infinity,
          })
        )
      }
    }
    return styles
  }

  legend(): Record<string, unknown> {
    return getDangerLegend()
  }
  mouseOverFunction(feature: FeatureLike): string {
    return getMouseOverText(feature, super.getMouseOverTitel(feature))
  }
}

const getShapeStyles = function (danger: DangerDto) {
  const shapeStyles = []
  let pie
  let arc = null

  if (!(danger.aspectFrom && danger.aspectTo)) {
    // no aspect provided, provide small radius circle
    shapeStyles.push(getSmallCircleSymbolCached(danger.level, danger.problem))
  } else {
    // aspect provided -> large radius circle
    pie = getArcFullSymbolCached(
      danger.level,
      danger.aspectFrom,
      danger.aspectTo
    )
    arc = getArcLineSymbolCached(
      danger.problem,
      danger.aspectFrom,
      danger.aspectTo
    )

    shapeStyles.push(pie)
    shapeStyles.push(arc)
  }

  return shapeStyles
}

// eslint-disable-next-line complexity
const getMapAltitudeText = function (
  danger: DangerDto,
  resolution: number
): Text | undefined {
  const textLabel = new TextLabel()
  let text = ''
  if (danger) {
    switch (danger.altitudeAbove) {
      case true:
        text = text + '+'
        break
      case false:
        text = text + '-'
        break
    }
    if (danger.altitudeValue) {
      text = text + Math.round(danger.altitudeValue / 100)
    }
  }
  const olText: Text | undefined = textLabel.get(text, resolution)
  if (olText) {
    if (getMapRadius(danger) === MAP_RADIUS_MAX) {
      olText.setOffsetY(
        olText.getOffsetY() - (MAP_RADIUS_MAX - MAP_RADIUS_MIN + 1)
      )
    } else {
      olText.setOffsetY(olText.getOffsetY() - 5)
    }
  }
  return olText
}

const getSubdivisionSymbol = function (
  danger: DangerDto,
  altitudeText: Text | undefined
) {
  const SUBDIVISION_STYLES = {
    [DANGER_SUBDIVISIONS.MINUS]: [MINUS_STYLE],
    [DANGER_SUBDIVISIONS.NEUTRAL]: NEUTRAL_STYLE,
    [DANGER_SUBDIVISIONS.PLUS]: [PLUS_STYLE],
  }
  if (danger.subdivision != null && altitudeText) {
    SUBDIVISION_STYLES[danger.subdivision][0].setText(altitudeText)
    SUBDIVISION_STYLES[danger.subdivision][0].setZIndex(Infinity)
    return SUBDIVISION_STYLES[danger.subdivision]
  }
  return null
}

const getMapRadius = function (danger: DangerDto) {
  return danger.aspectFrom || danger.aspectTo ? MAP_RADIUS_MAX : MAP_RADIUS_MIN
}

import { ComponentPublicInstance, Ref, ref } from 'vue'
import { InfoService } from '@/services/InfoService'
interface StagedDocumentRemover {
  markFileToRemove: (files: Array<{ id: string }>, id: string) => void
  removeFiles: () => void
  initializeRemover: (componentInstance: ComponentPublicInstance) => void
}

const vm: Ref<ComponentPublicInstance | undefined> = ref()

export function useStagedDocumentRemover(): StagedDocumentRemover {
  const filesToRemove = ref<string[]>([])

  const initializeRemover = (
    componentInstance: ComponentPublicInstance
  ): void => {
    vm.value = componentInstance
  }
  const markFileToRemove = (files: Array<{ id: string }>, id: string): void => {
    if (!vm.value) throw new Error('vm is undefined')

    if (files.some((x) => x.id === id)) {
      filesToRemove.value.push(id)
      files.splice(
        files.findIndex((x) => x.id === id),
        1
      )
    }
    vm.value.$forceUpdate()
  }
  const removeFiles = (): void => {
    if (!vm.value) throw new Error('vm is undefined')
    if (!(filesToRemove.value?.length > 0)) return

    try {
      for (const id of filesToRemove.value) {
        vm.value.$store.dispatch('document/deleteDocument', id).catch((err) => {
          console.log(
            "document for docId '" + id + "' could not be deleted, %o",
            err
          )
          InfoService.showWarningMessage({
            titleKey: 'fileupload.label.deleteFile',
            messageKey: 'fileupload.label.fileNotRemoved',
            messageValues: { id },
            autoHide: false,
          })
        })
      }
    } finally {
      filesToRemove.value = []
      vm.value.$forceUpdate()
    }
  }
  return { markFileToRemove, removeFiles, initializeRemover }
}

/* eslint-disable */
'use strict';

import { DangerStyle } from './DangerStyle'
import { ProblemStyle } from './ProblemStyle'
import {BulletinStyle} from './BulletinStyle'
import moment from 'moment'

/**
 * Chooses right mouseOver function based on provided feature type. To be used when main (top most) layer and auxiliary layer
 * features to be displayed at the same time. This could be solved like w/ clickhandlers, i.e. handler belongs to layer (hover)
 * but at this stage, this dependency is not desirable /particularly when wanted to display a mouseover w/ details from several
 * features/layers/
 */
export class DistributionAuxStyle {
  constructor (locale) {
    moment.locale(locale)
  }

  mouseOverFunction (feature) {
    // check for possible main layer first before going on w/ auxiliary layer variants
    if (feature) {
      if (feature.layerId === 'danger') {
        return new DangerStyle(moment.locale()).mouseOverFunction(feature)
      }
      if (feature.layerId === 'problem') {
        return new ProblemStyle(moment.locale()).mouseOverFunction(feature)
      }
      if (['bulletin.SECOND_DANGERMAP', 'bulletin.FIRST_DANGERMAP'].includes(feature.layerId)) {
        return new BulletinStyle(moment.locale()).mouseOverFunction(feature)
      }
    }
  }
}

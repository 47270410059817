<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <b-row>
        <b-col>
          <b>{{ $t(`assessment.sector.label.title`) }}</b>
        </b-col>
      </b-row>
    </template>
    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button type="button" variant="warning" @click="routeBack(true)">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="onSubmit"
            type="button"
            variant="success"
            :disabled="!valid"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>
    <AreaSectorInput :ldId="avalServiceId" v-model="form.hierarchy" />

    <div>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-input
              hidden
              :state="hierarchyState"
              aria-describedby="hierarchyFeedback"
            />
            <b-form-invalid-feedback id="hierarchyFeedback">
              {{ $t('assessment.msg.validHierarchy') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.sector.label.roughness')">
            <b-form-select
              :id="'select-roughness'"
              :options="roughness"
              v-model="form.roughness"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('assessment.sector.label.accumulation')">
            <b-form-select
              :id="'select-accumulation'"
              :options="accumulation"
              v-model="form.accumulation"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.sector.label.effectiveness')">
            <b-form-select
              :id="'select-effectiveness'"
              :options="effectiveness"
              v-model="form.effectiveness"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('assessment.sector.label.criticalAvalancheSize')"
          >
            <b-form-select
              :id="'select-criticalAvalancheSize'"
              :options="criticalAvalancheSize"
              v-model="form.criticalAvalancheSize"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('assessment.sector.label.breakingProbability')"
          >
            <b-form-select
              :id="'select-breakingProbability'"
              :options="breakingProbability"
              v-model="form.breakingProbability"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('assessment.sector.label.hazard')">
            <b-form-select
              :id="'select-hazard'"
              :options="hazard"
              v-model="form.hazard"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('assessment.sector.label.comment')">
            <b-form-textarea
              id="evalComment"
              v-model="form.comment"
              :state="commentState"
              aria-describedby="evalCommentFeedback"
              size="lg"
            />
            <b-form-invalid-feedback id="evalCommentFeedback">
              {{ $t('msg.minMaxCharacters', { min: '2', max: '500' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { i18nMixin } from '../../I18N'
import AreaSectorInput from '../AreaSectorInput'
import util from '../../../scripts/util'
import moment from 'moment'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import { ASSESSMENT_SECTOR } from '@/scripts/const.js'
import { inputFrmRouteBackMixin } from '@/components/observation/InputFrmRouteBackMixin.js'
import { deepClone } from '@/scripts/common'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { AreaSectorInput },
  mixins: [i18nMixin, inputFrmRouteBackMixin],
  data() {
    return {
      form: {
        hierarchy: { areaId: null, sectorId: null, avalServiceId: null },
        roughness: null,
        accumulation: null,
        effectiveness: null,
        hazard: null,
        comment: null,
        breakingProbability: null,
        criticalAvalancheSize: null,
      },
      processing: null,
      error: false,
      new: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  mounted() {
    if (this.$store.state.assessmentObs.selected) {
      this.new = false
      this.form = deepClone(this.$store.state.assessmentObs.selected.evaluation)
      assessmentUtil.prepareAssessmentObservationInputForm(
        ASSESSMENT_SECTOR,
        this.$store.state.assessmentObs.selected,
        this.form
      )
    } else {
      const hierarchyMap = this.$store.state.assessmentObs.hierarchyMap
      if (
        util.existValidHierarchy(
          hierarchyMap.get(this.avalServiceId),
          this.$store.state.assessmentObs.date
        )
      ) {
        this.form.hierarchy = deepClone(
          hierarchyMap.get(this.avalServiceId).selection
        )
      }
      this.form.hierarchy.avalServiceId = this.avalServiceId
    }
  },
  unmounted() {
    this.$store.commit('assessmentObs/SET_SELECTED', null)
    this.reset()
  },
  computed: {
    hierarchyState() {
      if (
        !(
          this.form.hierarchy &&
          this.form.hierarchy.areaId &&
          this.form.hierarchy.sectorId
        )
      ) {
        return null
      } else {
        return true
      }
    },
    /*eslint-disable complexity*/
    valid() {
      return (
        this.hierarchyState &&
        (this.form.roughness ||
          this.form.accumulation ||
          this.form.effectiveness ||
          this.form.hazard ||
          this.form.comment ||
          this.form.breakingProbability ||
          this.form.criticalAvalancheSize) &&
        this.commentState !== false
      )
    },
    /*eslint-enable complexity*/
    roughness() {
      return this.options('assessment', 'roughness')
    },
    accumulation() {
      return this.options('assessment', 'accumulation')
    },
    hazard() {
      return this.options('assessment', 'hazard')
    },
    effectiveness() {
      return this.options('assessment', 'effectiveness')
    },
    flag() {
      return this.options('common', 'flag')
    },
    avalServiceId() {
      return this.$store.getters['profile/getAvalServiceId']
    },
    commentState() {
      if (!this.form.comment) {
        return null
      }
      return (
        this.form.comment &&
        this.form.comment.length >= 2 &&
        this.form.comment.length <= 500
      )
    },
    breakingProbability() {
      return this.options('assessment', 'breakingProbability')
    },
    criticalAvalancheSize() {
      return this.options('assessment', 'criticalAvalancheSize')
    },
  },
  methods: {
    onSubmit() {
      if (this.processing === true) {
        return
      }
      if (!this.form.hierarchy.sectorId) {
        alert(this.$i18n.t('msg.selectValidValue'))
        return
      }

      this.processing = true

      const self = this

      const hierarchyClone = Object.assign({}, self.form.hierarchy)
      this.$store
        .dispatch(
          'assessmentObs/submitAssessmentObs',
          assessmentUtil.prepareAssessmentObservation(ASSESSMENT_SECTOR, {
            evaluation: this.form,
            type: ASSESSMENT_SECTOR,
            location: this.getLocationtoArea(),
          })
        )
        .then(
          () => {
            if (hierarchyClone) {
              const storeHierarchy =
                self.$store.state.assessmentObs.hierarchyMap.get(
                  self.avalServiceId
                )
              self.$store.commit('assessmentObs/SET_HIERARCHY', {
                avalServiceId: self.avalServiceId,
                hierarchy: {
                  selection: util.getConsolidatedHierarchySelection(
                    Object.assign({}, hierarchyClone),
                    storeHierarchy ? storeHierarchy.selection : null,
                    false
                  ),
                  date: moment(),
                },
              })
            }
            self.processing = false
            self.routeBack(false)
          },
          (reason) => {
            console.error('Error submitting assessment:' + reason)
            self.processing = false
          }
        )
    },
    getLocationtoArea() {
      return this.$store.getters['profile/getAreaLocationToAreaId'](
        this.form.hierarchy.areaId
      )
    },
    routeBack() {
      this.routeBackAssessmentTypes(this.new)
    },
    reset() {
      this.form = {
        hierarchy: { avalServiceId: null, areaId: null, sectorId: null },
        roughness: null,
        accumulation: null,
        effectiveness: null,
        hazard: null,
        comment: null,
        breakingProbability: null,
        criticalAvalancheSize: null,
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <b-navbar
    toggleable="xl"
    fixed="top"
    :class="`environment_${environmentName}`"
  >
    <b-navbar-toggle target="nav_collapse" variant="dark"></b-navbar-toggle>

    <b-navbar-brand class="d-block d-xl-none">
      <b-button class="btn btn-sm btn-add" @click="openInputModal">
        <font-awesome-icon icon="fa-solid fa-plus" />
      </b-button>
    </b-navbar-brand>
    <b-navbar-brand to="/">
      <img src="../assets/slflogo.svg" />
    </b-navbar-brand>

    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav>
        <b-nav-item
          to="/snow/newsnow"
          replace
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon
            class="icon"
            icon="fa-solid fa-snowflake"
            fixed-width
          />{{ $t('ui.snow') }}
        </b-nav-item>
        <b-nav-item
          to="/dangersign/dangersignAvalanche"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon
            class="icon"
            icon="fa-solid fa-exclamation-triangle"
          />{{ $t('ui.dangersign') }}
        </b-nav-item>
        <b-nav-item
          to="/avalanche/map"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon class="icon" icon="fa-solid fa-mountain" />{{
            $t('ui.avalanches')
          }}
        </b-nav-item>
        <b-nav-item
          to="/danger/danger"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon class="icon" icon="fa-solid fa-flag-checkered" />{{
            $t('ui.danger')
          }}
        </b-nav-item>
        <b-nav-item
          to="/ticker"
          v-if="$store.state.user.user.isAdmin"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon
            class="icon"
            icon="fa-solid fa-broadcast-tower"
          />Ticker
        </b-nav-item>
        <b-nav-item
          to="/observation/list"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon class="icon" icon="fa-solid fa-inbox" />{{
            $t('ui.journal')
          }}
        </b-nav-item>
        <b-nav-item class="nav-item-add">
          <b-button
            class="btn btn-sm btn-add d-none d-xl-block"
            @click="openInputModal"
          >
            <font-awesome-icon icon="fa-solid fa-plus" />
          </b-button>
        </b-nav-item>
        <b-nav-item
          :to="measureUrl"
          @click="(e) => e.target.classList.toggle('active')"
        >
          <font-awesome-icon class="icon" icon="fa-solid fa-ban" />{{
            $t('measure.label.measures')
          }}
        </b-nav-item>
        <b-nav-item-dropdown id="nav_settings">
          <template #button-content>
            <font-awesome-icon class="icon" icon="fa-solid fa-cog" />{{
              $t('ui.settings')
            }}
          </template>
          <b-dropdown-item to="/settings/profile">
            <font-awesome-icon class="icon" icon="fa-solid fa-user" />{{
              $t('ui.profile')
            }}
          </b-dropdown-item>
          <b-dropdown-item
            href="/observer"
            v-if="$store.state.profile.userGroups.length > 0"
          >
            <font-awesome-icon
              class="icon"
              icon="fa-solid fa-hand-holding-usd"
            />{{ $t('ui.compensation') }}
          </b-dropdown-item>
          <div v-if="avalServices">
            <b-dropdown-item
              v-for="avalService in avalServices"
              :key="avalService.id"
              :href="getAssessmentManagementUrl(avalService.id)"
            >
              <font-awesome-icon class="icon" icon="fa-solid fa-sliders-h" />{{
                'Assessment ' + avalService.name
              }}
            </b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown id="nav_settings">
          <template #button-content>
            <font-awesome-icon class="icon" icon="fa-solid fa-globe" />{{
              locale
            }}
          </template>
          <b-dropdown-item @click="changeLocal('de')">de</b-dropdown-item>
          <b-dropdown-item @click="changeLocal('fr')">fr</b-dropdown-item>
          <b-dropdown-item @click="changeLocal('it')">it</b-dropdown-item>
          <b-dropdown-item @click="changeLocal('en')">en</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item @click="logout">
          <font-awesome-icon class="icon" icon="fa-solid fa-sign-out-alt" />{{
            $t('ui.logout')
          }}
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'
import { KeycloakAuthentication } from '@/plugins/KeycloakAuthentication'
import { EnvironmentService } from '@/services/EnvironmentService'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Navigation',
  components: {},
  data() {
    return {
      src: '',
      locale: 'de',
    }
  },
  watch: {
    userLocale: function () {
      this.changeLocal(this.userLocale)
    },
  },
  computed: {
    ...mapState('user', ['userLocale']),
    user() {
      return this.$store.state.user.user.username
    },
    avalServices() {
      const avalServices = this.$store.state.profile.assmAvalServices
      return avalServices && avalServices.length > 0 ? avalServices : []
    },
    measureUrl() {
      if (this.$store.state.profile.assmAvalServices.length && !this.isMobile) {
        return '/measure/list'
      } else {
        return '/measure/measure'
      }
    },
    isMobile() {
      return this.$store.getters.isSmallMobile
    },
    environmentName() {
      return EnvironmentService.getOptionalVariable('ENVIRONMENT_NAME')
    },
  },
  methods: {
    openInputModal() {
      this.$router.push('/observation/list?o=1')
      this.$root.$emit('bv::show::modal', 'obsModal')
    },
    logout() {
      if (KeycloakAuthentication.getKeycloakLogoutUrl()) {
        location.replace(KeycloakAuthentication.getKeycloakLogoutUrl())
      }
    },
    changeLocal(local) {
      this.locale = local
      this.$i18n.locale = local
    },
    getAssessmentManagementUrl(avalServiceId) {
      return (
        EnvironmentService.getVariable('ASSESSMENT_GUI_URL') +
        '/#' +
        avalServiceId +
        '/areas'
      )
    },
  },
}
</script>

<style scoped>
.icon {
  width: 20px;
  margin-right: 8px;
}
.navbar {
  background-color: #ffffff;
}
.navbar.environment_DEVELOPMENT {
  background-color: #eefff2;
}
.navbar.environment_STAGING {
  background-color: #ffffe6;
}
.navbar-brand {
  padding: 0;
}
.navbar-brand img {
  margin-left: 10px;
  height: 30px;
  width: 30px;
}
.navbar-brand button {
  margin-left: 20px;
}
:deep(a.nav-link, #nav_settings) {
  font-size: 16px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
:deep(a.router-link-active:first-child) {
  background-color: #eeeeee;
  border-radius: 4px;
}
.form-group {
  margin-bottom: 0;
}
.nav-add .nav-link {
  padding: 5px;
}
.fa-stack {
  height: 15px;
  width: 15px;
  top: 3px;
}
.fa-wrapper {
  position: relative;
  color: #1b5c85 !important;
}
.fa-mobile-alt {
  font-size: 10px;
}
.fa-reply {
  position: absolute;
  left: -11px;
}
.utils {
  min-width: 40px !important;
}
.version {
  font: bold 14px Helvetica;
  color: #929292 !important;
}
.btn-add {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  border-radius: 15px;
  width: 30px;
  height: 30px;
}
:deep(.fa-plus) {
  color: white !important;
}
.nav-item-add > :deep(a) {
  padding: 3px;
}
</style>

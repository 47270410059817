'use strict'

export default class TableEntry {
  constructor(tableTitle) {
    this.tableTitle = tableTitle
    this.rows = []
  }

  getModelAsObj() {
    return {
      title: this.tableTitle,
      rows: this.rows,
    }
  }
}

import util from '../scripts/util.js'
import avalancheUtil from '../scripts/avalancheInputMap/avalancheUtil.js'

const radiusInMeters = 5000

export default {
  getObservationsWithinRadius(
    centerWGS84,
    observations,
    isAssessmentAggregation = false
  ) {
    if (observations && Array.isArray(observations)) {
      const obsWithinRadius = []
      observations.forEach((obs) => {
        if (
          util.isWGS84PointInCircle(centerWGS84, radiusInMeters, obs.location)
        ) {
          if (
            !isAssessmentAggregation &&
            !util.isAssessmentContent(obs, obs.type)
          ) {
            obsWithinRadius.push(obs)
          }
          if (
            isAssessmentAggregation &&
            util.isAssessmentContent(obs, obs.type)
          ) {
            obsWithinRadius.push(obs)
          }
        }
      })
      return obsWithinRadius
    }
    return observations
  },
  getAvalanchesWithinRadius(centerWGS84, avalanches) {
    if (avalanches && Array.isArray(avalanches)) {
      const avalWithinRadius = []
      avalanches.forEach((aval) => {
        const avalPoint = avalancheUtil.getPointOfAval(aval)
        if (
          avalPoint &&
          Array.isArray(avalPoint.flatCoordinates) &&
          avalPoint.flatCoordinates.length > 1
        ) {
          if (
            util.isSwissPointInCircle(
              centerWGS84,
              radiusInMeters,
              avalPoint.flatCoordinates
            )
          ) {
            avalWithinRadius.push(aval)
          }
        }
      })
      return avalWithinRadius
    }
    return avalanches
  },
}

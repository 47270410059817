import { i18n } from '@/plugins/i18n'
import store from '@/store'
import {
  SnowprofilerQueryParameter,
  FeedbackTabItem,
} from '@/components/observation/feedback/models'
import { Studyplot } from '@/services/StudyplotService'
import { objectToBase64 } from '@/scripts/common'

export type FeedbackReturnType = {
  getExternalFeedbackUrl: (
    externalUrl: string,
    path: string,
    tabItem: FeedbackTabItem
  ) => string
}

export const feedback = (): FeedbackReturnType => {
  const getExternalFeedbackUrl = (
    externalUrl: string,
    path: string,
    tabItem: FeedbackTabItem
  ): string => {
    if (externalUrl == null) {
      return ''
    }
    const externalLink = `${externalUrl}${path.replace(
      /{lang}/,
      i18n.global.locale
    )}`

    let queryParameter: SnowprofilerQueryParameter =
      getCommonQueryParameter(tabItem)
    if (tabItem.isStudyplot) {
      queryParameter = getStudyplotQueryParameter(tabItem, queryParameter)
    }

    return `${externalLink}?params=${objectToBase64(queryParameter)}`
  }

  const getCommonQueryParameter = (
    tabItem: FeedbackTabItem
  ): SnowprofilerQueryParameter => {
    return {
      isLockedLocation: tabItem.isStudyplot,
      tags: {
        observerGroupId: tabItem.groupId,
        observerGroupName: store.getters['profile/getObserverGroupName'](
          tabItem.groupId
        ),
      },
      location: store.getters['observation/getCurrentLocationCoordinates'],
    }
  }

  const getStudyplotQueryParameter = (
    tabItem: FeedbackTabItem,
    studyplotQueryParameter: SnowprofilerQueryParameter
  ): SnowprofilerQueryParameter => {
    const studyplot: Studyplot | undefined = store.getters[
      'studyplot/getStudyplot'
    ](tabItem.studyplotId)

    if (studyplot != null) {
      studyplotQueryParameter.location = studyplot.location.coordinates
      studyplotQueryParameter.locationName = `${studyplot.label} ${tabItem.studyplotId}`
      studyplotQueryParameter.elevation = studyplot.altitude
      studyplotQueryParameter.exposition = undefined
      studyplotQueryParameter.slopeAngle = 0
      studyplotQueryParameter.tags.stationId = studyplot.stationId?.toString()
      studyplotQueryParameter.tags.stationCode = studyplot.stationCode
      studyplotQueryParameter.tags.stationLabel = studyplot.label
    }
    return studyplotQueryParameter
  }

  return { getExternalFeedbackUrl }
}

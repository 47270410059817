'use strict'
import { Fill, Stroke, Style } from 'ol/style.js'
import { SizableSVG } from '../SizableSVG'
import Icon from 'ol/style/Icon'

export class AvalancheDrawStyle {
  styleFunction(feature, resolution) {
    const styleArray = []
    const yOffset = 0.066
    const geometry = feature.getGeometry()
    const type = geometry.getType()
    const size = arrowSize(resolution)
    let color = 'rgb(255,0,0)'
    if (type === 'Point') {
      if (geometry.computed) color = 'rgba(255,0,0,0.5)'
      if (geometry.locationType === 'startZoneGeo') {
        const arrowUp = new Style({
          image: new Icon({
            src: getArrowUpSymbol(size, '0 0 448 512', color).src,
            anchor: [0.5, yOffset],
          }),
          zIndex: 10,
        })
        styleArray.push(arrowUp)
      } else if (geometry.locationType === 'depositGeo') {
        const arrowDown = new Style({
          image: new Icon({
            src: getArrowDownSymbol(size, '0 0 448 512', color).src,
            anchor: [0.5, 0.933],
          }),
          zIndex: 10,
        })
        styleArray.push(arrowDown)
      } else {
        const cross = new Style({
          image: new Icon({
            src: getCrossSymbol(size, '0 0 320 512', color).src,
          }),
          zIndex: 10,
        })
        styleArray.push(cross)
      }
    } else {
      const generalStyle = new Style({
        zIndex: 10,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.2)',
        }),
        stroke: new Stroke({
          color: color,
          width: 2,
        }),
      })
      styleArray.push(generalStyle)
    }

    return styleArray
  }
}

const arrowSize = function (resolution) {
  return 54.578 * Math.pow(resolution, -0.287)
}

const getCrossSymbol = function (size, viewBox, color) {
  const crossSVG =
    '<path stroke="' +
    color +
    '" stroke-width="2" fill="' +
    color +
    '" d="M207.6 256 l107.72 -107.72 c6.23 -6.23 6.23 -16.34 0 -22.58 l-25.03 -25.03 c-6.23 -6.23 -16.34 -6.23 -22.58 0 L160 208.4 52.28 100.68 c-6.23 -6.23 -16.34 -6.23 -22.58 0 L4.68 125.7 c-6.23 6.23 -6.23 16.34 0 22.58 L112.4 256 4.68 363.72 c-6.23 6.23 -6.23 16.34 0 22.58 l25.03 25.03 c6.23 6.23 16.34 6.23 22.58 0 L160 303.6 l107.72 107.72 c6.23 6.23 16.34 6.23 22.58 0 l25.03 -25.03 c6.23 -6.23 6.23 -16.34 0 -22.58 L207.6 256z"/>'
  return getImage(crossSVG, viewBox, size)
}

const getArrowUpSymbol = function (size, viewBox, color) {
  const arrowUpSVG =
    '<path stroke="' +
    color +
    '" stroke-width="2" fill="' +
    color +
    '" d="M6.101 261.899L25.9 281.698c4.686 4.686 12.284 4.686 16.971 0L198 126.568V468c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V126.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 35.515c-4.686-4.686-12.284-4.686-16.971 0L6.101 244.929c-4.687 4.686-4.687 12.284 0 16.97z"/>'
  return getImage(arrowUpSVG, viewBox, size)
}

const getArrowDownSymbol = function (size, viewBox, color) {
  const arrowDownSVG =
    '<path stroke="' +
    color +
    '" stroke-width="2" fill="' +
    color +
    '" d="M441.9 250.1l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L250 385.4V44c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4L42.9 230.3c-4.7-4.7-12.3-4.7-17 0L6.1 250.1c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"/>'
  return getImage(arrowDownSVG, viewBox, size)
}

const getImage = function (path, viewBox, size) {
  const symbol = '<g>' + path + '</g>'
  const svg = new SizableSVG(size, viewBox)
  return svg.createImage(symbol)
}

<template>
  <Menu :moment="moment" :routes="routes" noCalendar="true"></Menu>
</template>

<script>
import Menu from '../Menu.vue'
import MeasureMap from './MeasureMap.vue'
import MeasureTable from './MeasureTable.vue'
import Input from './MeasureInput.vue'
import AvalancheTriggeringTable from './AvalancheTriggeringTable.vue'
import TriggeringInput from './AvalancheTriggeringInput.vue'
import TriggeringDelete from './AvalancheTriggeringDelete.vue'

export const Routes = [
  {
    name: 'measure.label.measureTable',
    path: '/measure/list',
    component: MeasureTable,
    meta: { map: false, isTitleRowInvisible: true },
    class: 'fa-solid fa-table',
    onlyAvalancheService: true,
  },
  {
    name: 'measure.label.measuresMap',
    path: '/measure/measure',
    component: MeasureMap,
    meta: { map: true },
    class: 'fa-solid fa-ban',
  },
  {
    name: 'MeasureInput',
    path: '/measure/input/:areaId/:objectId?/:preset?',
    component: Input,
    meta: { map: false, isTitleRowInvisible: true },
    props: true,
    type: 'input',
  },
  {
    name: 'triggering.label.avalancheTriggeringTable',
    path: '/measure/triggeringList',
    component: AvalancheTriggeringTable,
    meta: { map: false, isTitleRowInvisible: true },
    class: 'fa-solid fa-table',
    onlyAvalancheService: true,
  },
  {
    name: 'AvalancheTriggeringInput',
    path: '/measure/triggeringInput/:avalanchePathId/:areaId',
    component: TriggeringInput,
    meta: { map: false, isTitleRowInvisible: true },
    props: true,
    type: 'input',
  },
  {
    name: 'AvalancheTriggeringDelete',
    path: '/measure/triggeringDelete/:parent',
    component: TriggeringDelete,
    meta: { map: false, isTitleRowInvisible: true },
    props: true,
    type: 'input',
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Measure',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Menu },
  data() {
    return {
      routes: Routes.filter((el) => el.type !== 'input'),
    }
  },
}
</script>

<style scoped></style>

<template>
  <b-container>
    <b-row align-v="center">
      <b-col cols="2" v-if="!readonly">
        <b-button-group vertical size="sm">
          <b-button @click="inc(500)">
            <font-awesome-icon icon="fa-solid fa-arrow-up" />
          </b-button>
          <b-button @click="inc(-500)">
            <font-awesome-icon icon="fa-solid fa-arrow-down" />
          </b-button>
        </b-button-group>
      </b-col>
      <b-col cols="10" class="text-left">
        <!--
          '@expositionclick' musste hier komplett klein geschrieben und auch das main.ts angepasst werden
          Siehe: https://github.com/ionic-team/stencil/issues/2804
        -->
        <slf-radar
          ref="radar"
          :readonly="readonly"
          :data="values"
          :label1="height + 500"
          :label2="height"
          @expositionclick="onExpositionClick"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const COLORS = ['#868E96', '#28A745', '#FFC107', '#007BFF', '#DC3545']
const EXPOS = ['west', 'south', 'east', 'north']

const EXPOS_MAPPER = {
  north: 'N',
  east: 'E',
  south: 'S',
  west: 'W',
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Compass',
  props: ['modelValue', 'selection', 'colors', 'readonly'],
  emits: ['update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      height: null,
      computeHeight: true,
      startindex: 1,
      isSelecting: false,
      values: null,
      myData: this.modelValue,
    }
  },
  watch: {
    modelValue(v) {
      this.myData = v
    },
    myData: {
      deep: true,
      handler() {
        if (this.computeHeight) {
          this.height = 1000 + this.getStartIndex(this.myData) * 500
        } else {
          this.height = 1500
        }
        this.updateValues()
      },
    },
  },
  mounted() {
    if (this.computeHeight) {
      this.height = 1000 + this.getStartIndex(this.myData) * 500
    } else {
      this.height = 1500
    }
    this.updateValues()
  },
  methods: {
    getStartIndex(data) {
      let startIndex = this.startindex
      if (data && !this.isSelecting) {
        let startIndexFound = false
        // Search index of first value in all aspect arrays [1000müM, 1500müM, 2000müM, 2500müM, 3000müM, 3500müM, 4000müM]
        startIndex = 5
        EXPOS.forEach((aspect) => {
          if (data[aspect]) {
            const index = data[aspect].findIndex((v) => v !== null)
            if (index > -1) {
              startIndexFound = true
              startIndex = Math.min(startIndex, index)
            }
          }
        })
        if (!startIndexFound) startIndex = 1
      }
      this.startindex = startIndex
      return startIndex
    },
    inc(v) {
      this.height = Math.max(Math.min(2500, this.height + v), 1000)
      this.computeHeight = false
      // Delete all values out of range [ height, height+500, height+1000 ]
      const idx = this.height / 500 - 2
      EXPOS.forEach((e) => {
        if (!this.myData[e]) return
        for (let i = 0; i < idx; i++) {
          this.myData[e][i] = null
        }
        for (let j = 6; j > idx + 2; j--) {
          this.myData[e][j] = null
        }
      })
      this.updateValues()
    },
    toggle(i, e) {
      this.isSelecting = true
      if (this.readonly) return
      const idx = this.height / 500 + (i - 2)
      const expo = e
      if (!this.myData[expo]) {
        this.myData[expo] = [null, null, null, null, null, null]
      }

      this.myData[expo][idx] === this.selection
        ? (this.myData[expo][idx] = null)
        : (this.myData[expo][idx] = this.selection)

      this.$emit('update:modelValue', this.myData)
      this.$forceUpdate()
    },
    onExpositionClick(evt) {
      this.computeHeight = true
      const i = evt.detail.position
      const e = evt.detail.exposition
      const expo = Object.keys(EXPOS_MAPPER).find(
        (key) => EXPOS_MAPPER[key] === e
      )
      this.toggle(i, expo)
      this.computeHeight = false
      this.$refs.radar.refresh()
      this.updateValues()
    },
    updateValues() {
      const result = Object.assign(
        {},
        ...['N', 'W', 'S', 'E'].map((e) => ({
          [e]: Array(3).map(() => '#FFFFFF'),
        }))
      )

      const colors = this.colors ? this.colors : COLORS

      for (let i = 0; i < 3; i++) {
        const idx = this.height / 500 + (i - 2)

        EXPOS.forEach((e) => {
          if (this.myData[e]) {
            const value = this.myData[e][idx]
            const radarExpo = EXPOS_MAPPER[e]
            result[radarExpo][i] = value != null ? colors[value] : '#FFFFFF'
          }
        })
      }
      this.values = result
    },
  },
}
</script>

'use strict'

import {
  STUDYPLOTOBSERVATION_MORNING,
  MEASUREMENT,
  ASSESSMENT_DECISION,
  ASSESSMENT_DECISION_AREA,
  ASSESSMENT_DECISION_SECTOR,
} from '@/scripts/const.js'
import assessmentUtil from '@/scripts/assessmentUtil.js'

const funcs = {
  // Handelt Ausnahmen für sezielle ObsTypes ab
  getCommonTypeFromSpecialType(type) {
    switch (type) {
      case STUDYPLOTOBSERVATION_MORNING:
        return MEASUREMENT
      case ASSESSMENT_DECISION_AREA:
        return ASSESSMENT_DECISION
      case ASSESSMENT_DECISION_SECTOR:
        return ASSESSMENT_DECISION
      default:
        return type
    }
  },

  getSpecialTypeToCommonType(obsEle) {
    if (!obsEle || !obsEle.type) {
      return undefined
    }
    switch (obsEle.type) {
      case ASSESSMENT_DECISION:
        if (assessmentUtil.isAssessmentDecisionArea(obsEle)) {
          return ASSESSMENT_DECISION_AREA
        }
        if (assessmentUtil.isAssessmentDecisionSector(obsEle)) {
          return ASSESSMENT_DECISION_SECTOR
        }
        return obsEle.type
      default:
        return obsEle.type
    }
  },
}

export default funcs

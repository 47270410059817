<template>
  <div>
    <Legend v-if="legend" :legend="legend" class="measure-legend" />
    <CommonLayersPopup />
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..." />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Legend from '../Legend'
import MeasureStyle from '@/components/styles/MeasureStyle'
import { Canceler } from '@/scripts/requestUtil.js'
import observationService from '@/services/observationService'
import Measure from '@/model/measure/Measure'
import MeasureAreas from '@/components/measure/MeasureAreas'
import CommonLayersPopup from '../CommonLayersPopup'
import emitter from '@/components/EventHub'

const canceler = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { CommonLayersPopup, Legend },
  props: ['moment'],
  data() {
    return {
      objects: this.$store.state.profile.allActiveObjects,
      legend: MeasureStyle().legend(),
      isLoading: false,
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.isLoading = true
      this.cancelRequests()
      const cancelObj = { cancel: null }

      const lowerDate = moment().format('YYYY-MM-DD')
      const upperDate = moment().format()
      observationService
        .getMeasures(cancelObj)
        .then((response) => {
          if (!response) return
          const measures = response.data.data
          const features = this.createFeatures(measures)
          observationService
            .getObservationsByObservationDateTimeRange(
              lowerDate,
              upperDate,
              'DECISION',
              cancelObj
            )
            .then((response) => {
              const observations = response.data.data
              const areas = new MeasureAreas()
                .setDecisions(observations)
                .filterByDecisions(observations)
                .filterByMeasures(measures)
                .getFeatures()

              emitter.emit('layer', {
                name: 'measure',
                features: this.createFeatureCollection(features.concat(areas)),
                styleFunction: MeasureStyle().styleFunction,
              })

              const mouseOverFunction = MeasureStyle().mouseOverFunction
              emitter.emit('mouseOver', mouseOverFunction)
              this.isLoading = false
            })
        })
        .catch((e) => {
          console.error('Catch request getMeasures: ' + e)
          this.$store.commit('SET_EXCEPTION', e.message, { root: true })
          this.isLoading = false
        })
      canceler.addCancel(cancelObj.cancel)
    },
    createFeatureCollection(features) {
      return { type: 'FeatureCollection', features }
    },
    createFeatures(measures) {
      return measures
        .filter((el) => el.objectId !== null)
        .map((el) => ({
          type: 'Feature',
          geometry: el.actualMeasure.location,
          properties: {
            objectName: this.getObjectName(el.actualMeasure),
            objectType: this.getObjectType(el.actualMeasure),
            actual: el.actualMeasure?.measure?.measure,
            planned: el.planedMeasure?.measure?.measure,
            plannedMeasure: this.createMeasure(el.planedMeasure),
          },
        }))
    },
    createMeasure(o) {
      if (!o?.measure) return null
      return new Measure(
        o.measure.id,
        this.getObjectClass(o),
        o.measure.begin,
        o.measure.end,
        o.measure.measure
      )
    },
    getObjectName(measure) {
      const id = measure.hierarchy?.objectId
      return this.objects[id]?.name || '-'
    },
    getObjectClass(measure) {
      const id = measure.hierarchy?.objectId
      return this.objects[id]?.class
    },
    getObjectType(measure) {
      const id = measure.hierarchy?.objectId
      return this.objects[id]?.type
    },
    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },
  },
  beforeUnmount() {
    this.cancelRequests()
  },
}
</script>

<style scoped>
.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
.measure-legend {
  width: fit-content;
}
</style>

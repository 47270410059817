import observationService from '@/services/observationService'
import assessmentUtil from '@/scripts/assessmentUtil'
import rootStore from '@/store/index'
import aggregationStore from '@/store/modules/aggregation'
import { ObservationDto } from '@/model/commonModels'

interface UseAssessmentAggregation {
  loadSingleOrAggregatedAssessmentObservations: (
    baseAssessmentId: string,
    assessmentType: string,
    aggregate: boolean
  ) => Promise<ObservationDto[] | undefined>
}

export const useAssessmentAggregation = (): UseAssessmentAggregation => {
  const loadAggregatedAssessmentObservations = async (
    baseAssessmentId: string,
    assessmentType: string,
    aggregate: boolean
  ) => {
    return await getAggregatedAssessmentObservations(
      baseAssessmentId,
      assessmentType,
      aggregate
    )
  }
  return {
    loadSingleOrAggregatedAssessmentObservations:
      loadAggregatedAssessmentObservations,
  }
}

const getAggregatedAssessmentObservations = async (
  baseAssessmentId: string,
  assessmentType: string,
  aggregate: boolean
): Promise<ObservationDto[] | undefined> => {
  try {
    const result = (
      await observationService.getObservationById(baseAssessmentId)
    ).data.data
    assessmentUtil.prepareAssessmentObservationResult(assessmentType, result)

    if (!aggregate) {
      return [result]
    }

    await rootStore.dispatch('aggregation/loadAggregatedObservations', {
      uid: result.uid,
      creationDate: result.cTimestamp,
      location: result.location,
      isAssessmentAggregation: true,
    })
    return aggregationStore.state.aggregatedObservations as ObservationDto[]
  } catch (e) {
    console.error(e)
  }
}

// eslint-disable-next-line complexity
export const computeIndex = function (avalanche) {
  const triggerFactor = TRIGGER_FACTOR[avalanche.triggerType || 'UNKNOWN']
  if (avalanche.avalancheSize) {
    return triggerFactor * SIZE_FACTOR[avalanche.avalancheSize]
  } else {
    let sum = 0
    const sizes = []
    if (avalanche.sizeAspectCounts && avalanche.sizeAspectCounts.length > 0) {
      // i.e. several get
      avalanche.sizeAspectCounts.forEach((countObject) => {
        let i = 0
        for (i; i < countObject.count; i++) {
          sizes.push(countObject.avalancheSize)
        }
      })
    }
    if (avalanche.details && avalanche.details.length > 0) {
      // i.e. list aval
      avalanche.details.forEach((countObject) => {
        let i = 0
        for (i; i < countObject.count; i++) {
          if (countObject.avalancheSize) {
            sizes.push(countObject.avalancheSize)
          }
        }
      })
    }
    sizes.forEach((size) => {
      sum += triggerFactor * SIZE_FACTOR[size]
    })
    return sum === 0 ? 0.01 : Math.ceil(sum * 100) / 100
  }
}

const SIZE_FACTOR = {
  UNKNOWN: 0.005,
  ONE: 0.01,
  TWO: 0.1,
  THREE: 1,
  FOUR: 10,
  FIVE: 10,
}
const TRIGGER_FACTOR = {
  NATURAL: 1,
  PERSON: 0.5,
  EXPLOSIVE: 0.2,
  SNOW_GROOMER: 0.2,
  UNKNOWN: 0.8,
  OTHER: 0.8,
}

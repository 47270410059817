'use strict'

/**
 * common functions to bulletin data
 */
export const BULLETIN_TYPE_MAP = 'AvalancheMapBulletin'
export const BULLETIN_TYPE_TXT = 'AvalancheTextBulletin'
export const BULLETIN_LEVDET_MINUS = 'LEVDET_MINUS'
export const BULLETIN_LEVDET_NEUTRAL = 'LEVDET_NEUTRAL'
export const BULLETIN_LEVDET_PLUS = 'LEVDET_PLUS'

export default {
  /**
   * Checks whether content a text bulletin
   *
   * @param data            bulletin data
   * @param isGeoJsonFormat determines structure to be examined (avalanche-bulletin or avalanche-bulletin-geojson query result)
   */
  // eslint-disable-next-line complexity
  isTxtBulletin(data, isGeoJsonFormat) {
    if (isGeoJsonFormat) {
      // NOTE: supposed that all features of same type
      return (
        data &&
        data.features &&
        data.features.length > 0 &&
        data.features[0].properties &&
        data.features[0].properties.bulletin_type === BULLETIN_TYPE_TXT
      )
    } else {
      return data && data.bulletin_type === BULLETIN_TYPE_TXT
    }
  },

  /**
   * Checks whether content a map bulletin
   *
   * @param data            bulletin data
   * @param isGeoJsonFormat determines structure to be examined (avalanche-bulletin or avalanche-bulletin-geojson query result)
   */
  // eslint-disable-next-line complexity
  isMapBulletin(data, isGeoJsonFormat) {
    if (isGeoJsonFormat) {
      // NOTE: supposed that all features of same type
      return (
        data &&
        data.features &&
        data.features.length > 0 &&
        data.features[0].properties &&
        data.features[0].properties.bulletin_type === BULLETIN_TYPE_MAP
      )
    } else {
      return data && data.bulletin_type === BULLETIN_TYPE_MAP
    }
  },

  /**
   * Whether bulletin has specified rating (1st or 2nd)
   *
   * @param data            bulletin data
   * @param isGeoJsonFormat determines structure to be examined (avalanche-bulletin or avalanche-bulletin-geojson query result)
   * @param rating          1 or 2 for 1st resp. 2nd
   * @returns {boolean} whether rating present or not
   */
  hasBulletinMapRating(data, isGeoJsonFormat, rating) {
    if (this.isMapBulletin(data, isGeoJsonFormat)) {
      const mapBulletin = isGeoJsonFormat
        ? data.features[0].properties
        : data.avalanche_map_bulletin
      if (mapBulletin) {
        return isGeoJsonFormat
          ? mapBulletin['rating' + rating] !== null
          : mapBulletin['rating_' + rating] !== null
      }
    }
    return false
  },

  /**
   * Gets validity date from bulletin
   *
   * @param data            bulletin data
   * @param isGeoJsonFormat determines structure to be examined (avalanche-bulletin or avalanche-bulletin-geojson query result)
   */
  // eslint-disable-next-line complexity
  getBulletinValidFrom(data, isGeoJsonFormat) {
    if (isGeoJsonFormat) {
      return data &&
        data.features &&
        data.features.length > 0 &&
        data.features[0].properties
        ? data.features[0].properties.valid_from
        : null
    } else {
      if (data) {
        return data.valid_from
      }
      return null
    }
  },

  /**
   * Transforms danger level detail text delivered by service into string to be displayed
   *
   * NOTE: danger level detail only available if sufficient rights
   * NOTE: text displayed on bulletin style / modal depends on returned text: for admins
   *      display = as well, while for normal users do not display it
   *
   * @param dangerLevelDetailProp level detail delivered by API
   * @returns {string} level detail to be displayed
   */
  getDangerLevelDetailText(dangerLevelDetailProp) {
    switch (dangerLevelDetailProp) {
      case BULLETIN_LEVDET_MINUS:
        return '-'
      case BULLETIN_LEVDET_NEUTRAL:
        return '='
      case BULLETIN_LEVDET_PLUS:
        return '+'
      default:
        return ''
    }
  },
}

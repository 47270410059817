<template>
  <b-container class="pane smooth">
    <router-view :moment="moment"></router-view>
  </b-container>
</template>

<script>
import List from './list/List.vue'
import SnowInput from '../snow/Input'
import DangerSignInput from '../dangersign/Input'
import DangerInput from '../danger/Input'
import AvalancheSingleInput from '../avalanche/Input.Single'
import AvalancheSeveralInput from '../avalanche/Input.Several'
import AttachmentInput from '../attachment/Input'
import StudyplotInput from '../studyplot/studyplotInput/Input'
import StudyplotSummerInput from '../studyplot/studyplotInput/InputSummer'
import ObservationInput from '../studyplotObservation/Input'
import StudyplotInputManagement from '@/components/studyplot/studyplotInput/InputManagement'

import SituationInput from '../assessment/situation/SituationInput'
import EvaluationInput from '../assessment/evaluation/EvaluationInput'
import AssessmentDangerInput from '../assessment/DangerInput'
import MeasureAssessmentInput from '../assessment/measureassessment/MeasureAssessmentInput'
import AssessmentAttachmentInput from '../assessment/AttachmentInput.vue'

import { i18n } from '@/plugins/i18n'

export const Routes = [
  { name: 'Liste', path: '/observation/list', component: List },
  {
    name: i18n.global.t('ui.feedback.snow'),
    path: '/observation/snow',
    component: SnowInput,
  },
  {
    name: 'Gefahreinzeichen',
    path: '/observation/dangersign',
    component: DangerSignInput,
  },
  {
    name: 'Lawinengefahr',
    path: '/observation/danger/:code?',
    component: DangerInput,
    props: true,
  },
  {
    name: i18n.global.t('ui.feedback.avalanche'),
    path: '/observation/avalanche',
    component: AvalancheSingleInput,
    meta: { scrollToTop: true },
  },
  {
    name: 'Mehrere Lawinen',
    path: '/observation/avalanches',
    component: AvalancheSeveralInput,
    meta: { scrollToTop: true },
  },
  {
    name: 'Anhänge',
    path: '/observation/attachment',
    component: AttachmentInput,
  },
  {
    name: 'Messfeld',
    path: '/observation/studyplot/:code',
    component: StudyplotInput,
    props: true,
  },
  {
    name: i18n.global.t('ui.studyplotobservation'),
    path: '/observation/studyplotobservation/:code',
    component: ObservationInput,
    props: true,
  },
  {
    name: 'Messung',
    path: '/observation/studyplot/:code/:type',
    component: StudyplotInput,
    props: true,
  },
  {
    name: 'Sommer Messung',
    path: '/observation/studyplotSummer/:code/:type',
    component: StudyplotSummerInput,
    props: true,
  },

  {
    name: 'Situation',
    path: '/assessment/areaAssessment',
    component: SituationInput,
  },
  {
    name: 'Sector',
    path: '/assessment/areaAssessment/section',
    component: EvaluationInput,
  },
  {
    name: 'MeasureAssessment',
    path: '/assessment/measureAssessment',
    component: MeasureAssessmentInput,
  },
  {
    name: 'AssessmentDanger',
    path: '/assessment/danger',
    component: AssessmentDangerInput,
  },
  {
    name: 'AssessmentAttachment',
    path: '/assessment/attachment',
    component: AssessmentAttachmentInput,
  },
  {
    name: i18n.global.t('ui.feedback.studyplotManagement.title'),
    path: '/studyplotManagement',
    component: StudyplotInputManagement,
    props: true,
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Observation',
  props: ['moment'],
}
</script>

<style scoped>
.nav-link {
  color: #1b5c85 !important;
}
.nav-link.active {
  border: 2px solid #1b5c85;
  border-radius: 4px;
}
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.pane {
  margin-top: 15px;
  height: 90vh;
  /* overflow-y: auto; */
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
/*
NOTE (20190917/csz):
I deactivated overflow-y below as it prevented the forms from jumping up to the top in case of a validation error.
No difference in map zoom / scrolling behaviour on mobiles but difference in scroll behaviour on desktop (scroll on the very right or
on the right hand side of the form).
*/
.smooth {
  /* overflow-y: hidden; */
  -webkit-overflow-scrolling: touch;
}
</style>

import { FeatureLike } from 'ol/Feature'

interface OuterContext {
  $bvModal: { show: (param: string) => void }
  obsIds: Array<string>
}

export const stationDetailMixin = {
  methods: {
    getStationClickHandler(
      outerContext: OuterContext
    ): (feature: FeatureLike | null | undefined) => void {
      return (feature: FeatureLike | null | undefined) => {
        if (!feature) {
          return
        }
        if (feature.get('network') === 'OBSERVER') {
          outerContext.obsIds = [feature.get('id')]
          outerContext.$bvModal.show('detailModal')
        }
      }
    },
  },
}

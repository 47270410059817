<template>
  <b-container>
    <b-card
      style="pointer-events: auto"
      header-tag="header"
      footer-tag="footer"
    >
      <template #header>
        <b-row>
          <b-col>
            <b>{{ $t('measure.label.measure') }}</b> "{{ headerName }}"
          </b-col>
        </b-row>
      </template>

      <template #footer>
        <b-row>
          <b-col class="text-left">
            <b-button
              type="button"
              variant="warning"
              @click="routeBack(true)"
              >{{ $t('ui.cancel') }}</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              ref="measureInputSendButton"
              @click="onSubmit"
              type="button"
              variant="success"
              :disabled="!valid"
            >
              <font-awesome-icon icon="fa-solid fa-paper-plane" />
              {{ $t('ui.send') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="measureFormGroup"
              :disabled="preset !== undefined"
              :label="$t('measure.label.measure')"
            >
              <b-form-select
                :options="measureTypes"
                v-model="form.measure"
                @change="whenDecisionChanges"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              id="startDateFormGroup"
              :disabled="preset !== undefined"
              :label="$t('measure.label.begin')"
            >
              <b-form-radio-group
                v-model="validFrom.state"
                aria-controls="ex-disabled-readonly"
              >
                <b-form-radio :value="false">
                  {{ $t('measure.label.asOfNow') }}
                </b-form-radio>
                <span id="validFrom" v-show="showValidFrom">
                  <b-form-radio :value="true">
                    {{ $t('measure.label.validFrom') }}
                  </b-form-radio>
                </span>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group>
              <calendar-component
                v-show="showValidFrom"
                class="calendar-picker w-100"
                v-model="validFromDate"
                with-time
                :disabled="!validFrom.state"
              />
            </b-form-group>
          </b-col>

          <b-col id="endDateForm" md="6" v-if="acceptEndDate">
            <b-form-group :label="$t('measure.label.end')">
              <b-form-radio-group
                v-model="validTo.state"
                aria-controls="ex-disabled-readonly"
              >
                <b-form-radio :value="false">
                  {{ $t('measure.label.untilFurtherNotice') }}
                </b-form-radio>
                <b-form-radio :value="true">
                  {{ $t('measure.label.validTo') }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group>
              <calendar-component
                class="calendar-picker w-100"
                v-model="validToDate"
                with-time
                :disabled="!validTo.state"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('measure.label.comment')">
              <b-form-textarea
                id="comment"
                v-model="form.reason"
                :rows="3"
                :spellcheck="false"
                :state="commentState"
                size="lg"
              />
              <b-form-invalid-feedback id="inputCommentHelp">
                {{ $t('msg.minMaxCharacters', { min: '2', max: '1000' }) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '../I18N'
import CalendarComponent from '../calendar/CalendarComponent'
import { routerMixin } from '@/router/RouterMixin'

export default {
  components: { CalendarComponent },
  props: {
    objectId: {
      type: String,
      required: false,
    },
    areaId: {
      type: String,
      required: false,
    },
    preset: {
      type: String,
      required: false,
    },
  },
  mixins: [i18nMixin, routerMixin],
  data() {
    return {
      hierarchy: {
        avalServiceId: null,
        objectId: this.objectId,
        areaId: this.areaId,
      },
      form: {
        measure: this.preset,
        begin: null,
        end: null,
        reason: null,
      },
      validTo: {
        date: null,
        time: null,
        state: false,
      },
      validFrom: {
        date: null,
        time: null,
        state: false,
      },
      processing: null,
      idTyp: null,
      actualMeasureDecision: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },
  mounted() {
    this.setAvalServiceId()
    if (this.preset) return
    this.$store
      .dispatch('measure/loadLatestMeasures', {
        avalServiceIds: [this.hierarchy.avalServiceId],
      })
      .then((items) => {
        const item = items.find(
          (el) => el.hierarchy[this.idTyp] === this[this.idTyp]
        )
        if (item?.measure) {
          this.hierarchy[this.idTyp] = item.hierarchy[this.idTyp]
          this.form = {
            measure: item.measure.measure,
            begin: item.measure.begin,
            end: item.measure.end,
            reason: item.measure.reason,
          }
          if (item.measure.begin) {
            this.validFrom.date = moment(item.measure.begin).format(
              'YYYY-MM-DD'
            )
            this.validFrom.time = moment(item.measure.begin).format('HH:mm')
            this.validFrom.state = true
          }
          if (item.measure.end) {
            this.validTo.date = moment(item.measure.end).format('YYYY-MM-DD')
            this.validTo.time = moment(item.measure.end).format('HH:mm')
            this.validTo.state = true
          }
        }
        this.setActualMeasureDecision(item)
      })
  },
  computed: {
    commentState() {
      if (!this.form.reason) {
        return null
      }
      return (
        this.form.reason &&
        this.form.reason.length >= 2 &&
        this.form.reason.length <= 1000
      )
    },
    valid() {
      return (
        this.form.measure &&
        this.validComment &&
        this.isValidFrom &&
        this.isValidTo
      )
    },
    validComment() {
      return this.commentState === null ? true : this.commentState
    },
    isValidFrom() {
      return (
        !this.validFrom.state || (this.validFrom.date && this.validFrom.time)
      )
    },
    isValidTo() {
      return !this.validTo.state || (this.validTo.date && this.validTo.time)
    },
    measureTypes() {
      if (this.objectId) {
        return this.options(
          'measure',
          this.$store.getters['profile/getObjectToProtect'](this.objectId).class
        )
      }
      return this.options('measure', 'warningLight')
    },
    headerName() {
      if (this.objectId) {
        return this.$store.getters['profile/getObjectToProtect'](this.objectId)
          .name
      }
      return (
        this.$i18n.t('measure.label.warningLight') +
        ' ' +
        this.$store.getters['profile/getAreaToId'](this.areaId).name
      )
    },
    validFromDate: {
      get() {
        return (
          this.validFrom.time
            ? moment(this.validFrom.date + 'T' + this.validFrom.time)
            : moment(this.validFrom.date)
        ).format('DD.MM.YYYY HH:mm')
      },
      set(val) {
        const parsedDate = moment(val, 'DD.MM.YYYY HH:mm')

        this.validFrom.date = parsedDate.format('YYYY-MM-DD')
        this.validFrom.time = parsedDate.format('HH:mm')
      },
    },
    validToDate: {
      get() {
        return (
          this.validTo.time
            ? moment(this.validTo.date + 'T' + this.validTo.time)
            : moment(this.validTo.date)
        ).format('DD.MM.YYYY HH:mm')
      },
      set(val) {
        const nextPlanedMoment = moment(val, 'DD.MM.YYYY HH:mm')

        this.validTo.date = nextPlanedMoment.format('YYYY-MM-DD')
        this.validTo.time = nextPlanedMoment.format('HH:mm')
      },
    },
    acceptEndDate() {
      return !['NO_MEASURE', 'WARNING_LIGHT_OFF'].includes(this.form.measure)
    },
    showValidFrom() {
      if (!this.form.measure) {
        return true
      }
      return this.form.measure !== this.actualMeasureDecision
    },
  },
  methods: {
    /*eslint-disable complexity*/
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      const self = this
      this.form.begin =
        this.validFrom.state && this.validFrom.date && this.validFrom.time
          ? moment(this.validFrom.date + 'T' + this.validFrom.time)
          : moment()
      this.form.end =
        this.acceptEndDate &&
        this.validTo.state &&
        this.validTo.date &&
        this.validTo.time
          ? moment(this.validTo.date + 'T' + this.validTo.time)
          : null
      this.$store
        .dispatch('measure/addMeasure', {
          form: this.form,
          hierarchy: this.hierarchy,
        })
        .then(
          () => {
            self.processing = false
            self.routeBack(false)
          },
          (reason) => {
            console.error('Error submitting measure:' + reason)
            self.processing = false
          }
        )
    },
    /*eslint-enable complexity*/
    routeBack() {
      this.routeToWhenNeeded(this.routeOrigin)
    },
    reset() {
      this.hierarchy = {
        avalServiceId: null,
        objectId: this.objectId,
        areaId: this.areaId,
      }
      this.form = {
        measure: null,
        begin: null,
        end: null,
        reason: null,
      }
      this.idTyp = null
    },
    setAvalServiceId() {
      let result
      if (this.objectId) {
        result = this.$store.getters['profile/getAssmAvalServiceToObjectId'](
          this.objectId
        )
        this.idTyp = 'objectId'
      } else {
        result = this.$store.getters['profile/getAssmAvalServiceToAreaId'](
          this.areaId
        )
        this.idTyp = 'areaId'
      }
      this.hierarchy.avalServiceId = result.id
    },
    setActualMeasureDecision(item) {
      this.actualMeasureDecision = item?.actualMeasure?.measure
      if (!this.actualMeasureDecision) {
        const noMeasureGetter =
          this.idTyp === 'objectId'
            ? 'measure/getNoMeasure'
            : 'measure/getNoWarnlight'

        this.actualMeasureDecision =
          this.$store.getters[noMeasureGetter].measure
      }
    },
    whenDecisionChanges() {
      if (!this.showValidFrom) {
        this.validFrom.state = false
      }
    },
  },
}
</script>

<style scoped></style>

import moment from 'moment'
import { SnowprofilerService } from '@/services/SnowprofilerService.ts'
import { i18n } from '@/plugins/i18n'
import { DateTime } from 'luxon'
import { EnvironmentService } from '@/services/EnvironmentService'

// state
const state = {
  snowprofileObsFeatures: [],
}

// getters
const getters = {}

// actions
const actions = {
  async loadSnowprofileObsFeatureByDaterangeOptUser(context, params) {
    console.log('loadSnowprofileObsFeatureByDaterangeOptUser')
    try {
      const sdsGeoJson =
        await SnowprofilerService.getSnowProfilesSDSByObservationDateTimeRange(
          params.obsDate,
          params.cancel
        )
      if (!sdsGeoJson) {
        return
      }

      const features = sdsGeoJson.features.map((sdsFeature) => {
        const sdsProperties = sdsFeature.properties
        const obsDateTime = DateTime.fromISO(sdsProperties.obsDate)
        return {
          ...sdsFeature,
          properties: {
            id: sdsProperties.id,
            uid: sdsProperties.userId,
            cTimestamp: obsDateTime.toSeconds(),
            oDate: obsDateTime.toISODate(),
            snowprofile: sdsProperties,
          },
        }
      })
      const featureCollection = { ...sdsGeoJson, features }

      context.commit('SET_SNOWPROFILE_OBS_FEATURES', featureCollection)
      context.commit('SET_EXCEPTION', null, { root: true })
      console.log('snowprofile observations loaded')
    } catch (error) {
      context.commit('SET_SNOWPROFILE_OBS_FEATURES', [])
      console.error('Snowprofile: ' + error.message)
      if (!(error.response && error.response.status === 401)) {
        context.commit('SET_EXCEPTION', 'Snowprofile: ' + error.message, {
          root: true,
        })
      }
    }
  },

  /**
   * Add snow-water-equivalent (hsw) entry to the snowprofiler
   * @param param
   */
  addSnowWaterEquivalent(context, param) {
    return new Promise((resolve, reject) => {
      SnowprofilerService.postSnowWaterEquivalent(param)
        .then(function () {
          console.debug('hsw measurement added to snowprofiler')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error('Snowprofile: ' + error)
          if (!(error.response && error.response.status === 401)) {
            if (error.response && error.response.status === 409) {
              context.commit(
                'SET_EXCEPTION',
                i18n.global.t('measurement.label.duplicate', {
                  date: moment(param.date).format('DD.MM.YYYY'),
                }),
                { root: true }
              )
            } else {
              context.commit('SET_EXCEPTION', 'Snowprofile: ' + error.message, {
                root: true,
              })
            }
          }
          reject(error)
        })
    })
  },

  /**
   * Get snow profiles (with links to gui)
   * @param param {from, to, userid, cancel}
   * @returns Array of snowprofiles
   */
  getDetailSnowProfiles(context, param) {
    return SnowprofilerService.getDetailSnowProfilesByUserDateTimeRange(
      param.userId,
      param.startObsDate,
      param.endObsDate,
      param.cancel
    )
      .then((snowProfiles) => {
        if (!snowProfiles) {
          return
        }

        const profiles = []
        snowProfiles.forEach((snowprofile) => {
          snowprofile.url =
            EnvironmentService.getVariable('SNOWPROFILER_GUI_URL') +
            '/faces/jsp/editViewProfile.jspx?displayType=Ramm&profileId=' +
            snowprofile.profile.id
          profiles.push(snowprofile)
        })
        context.commit('SET_DETAIL_SNOWPROFILES', profiles)
        context.commit('SET_EXCEPTION', null, { root: true })

        return profiles
      })
      .catch(function (error) {
        context.commit('SET_DETAIL_SNOWPROFILES', [])
        console.error('Snowprofile: ' + error.message)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', 'Snowprofile: ' + error.message, {
            root: true,
          })
        }
        return []
      })
  },
}

// mutations
const mutations = {
  SET_SNOWPROFILE_OBS_FEATURES(state, snowprofileObsFeatures) {
    state.snowprofileObsFeatures = snowprofileObsFeatures
  },

  SET_DETAIL_SNOWPROFILES(state, profiles) {
    state.detailSnowProfiles = profiles
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

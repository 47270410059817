export const BULLETIN_RATING_1 = 1
export const BULLETIN_RATING_2 = 2

/**
 * V2 Bulletin response data route object
 * It is a geojson feature collection, containing danger regions as feature.
 */
export interface SimpleDangerRegionCollection {
  type: string
  features: SimpleDangerRegion[]
}

/**
 * V2 danger region
 * This is a geojson feature, with the bulletin as property.
 */
export interface SimpleDangerRegion {
  type: string
  geometry: object
  id: number
  properties: SimpleBulletin
}

/**
 * V2 bulletin for a danger region
 */
export interface SimpleBulletin {
  bulletin_type: string
  valid_from: string
  valid_to: string
  next_update?: string
  rating: Rating
  fill: string
}

/**
 * Bulletin danger level.
 */
export interface Dangerlevel {
  level: string
  level_detail?: string
}

/**
 * Bulletin danger rating
 */
export interface Rating {
  dangerlevel: Dangerlevel
  majorAvalProblems: string[]
}

'use strict'

import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import { SVG } from '../SVG'
import { AbstractStyle } from '../AbstractStyle'
import {
  CLASS_DEFINITION,
  SURFACE_SVG,
} from '@/components/styles/snowStyling/surfaceNatureMetadata'

const surfaceNatureStyleCache = new Map()

export class SurfaceNatureStyle extends AbstractStyle {
  styleFunction(feature) {
    const surfaceObject = feature.get('snowSurfaceCondition')
    if (!surfaceObject) return
    const value = surfaceObject.valueToShow
    const level = surfaceObject.index
    const cacheKey = `${value}`
    let surfaceNatureStyle

    if (surfaceNatureStyleCache.has(cacheKey)) {
      surfaceNatureStyle = surfaceNatureStyleCache.get(cacheKey)
    } else {
      surfaceNatureStyle = new Style({
        image: new Icon({
          src: getSymbol(value).src,
        }),
      })
      surfaceNatureStyleCache.set(cacheKey, surfaceNatureStyle)
    }
    surfaceNatureStyle.setZIndex(level)
    return [surfaceNatureStyle]
  }
  legend() {
    const legend = {
      title: 'snow.label.surfaceNature',
    }
    legend.items = CLASS_DEFINITION.slice().map((v) => {
      return { label: v.label, symbol: getSymbol(v.t, '-15 -15 30 30') }
    })
    return legend
  }
  mouseOverFunction(feature) {
    const snowSurfaceCondition = feature.get('snowSurfaceCondition')
    if (
      snowSurfaceCondition &&
      (snowSurfaceCondition.snowSurfaceConditionNorth ||
        snowSurfaceCondition.snowSurfaceConditionSouth ||
        snowSurfaceCondition.snowSurfaceConditionFlat)
    ) {
      let htmlString = super.getMouseOverTitel(feature)
      htmlString = htmlString.concat(
        '<div>' + snowSurfaceCondition.aspect + '<div>'
      )
      return htmlString
    } else {
      return null
    }
  }
}

const getSymbol = function (value, viewbox) {
  const symbol = '<g>' + SURFACE_SVG[value] + '</g>'
  const svg = new SVG(viewbox)
  return svg.createImage(symbol)
}

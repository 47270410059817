import Style from 'ol/style/Style'
import {
  Avalanche,
  GetLabelStyleFunction,
} from '@/components/styles/avalancheStyling/AvalancheCommonStyleElements'
import { FeatureLike } from 'ol/Feature'
import { Geometry } from 'ol/geom'
import GeneralStyleFactory from '@/components/styles/avalancheStyling/GeneralStyleFactory'
import ActivityIndexStyleFactory from '@/components/styles/avalancheStyling/ActivityIndexStyleFactory'

export const getAvalancheStyleArray = function (
  feature: FeatureLike,
  avalanche: Avalanche,
  geometry: Geometry,
  getLabelStyle: GetLabelStyleFunction,
  useInputMapStyling: boolean
): Array<Style> {
  const styleArray: Array<Style> = []
  let textOffsetY = -6

  if (
    (geometry.getType() === 'Point' && !avalanche.detailGeoType) ||
    avalanche.detailGeoType === 'Point'
  ) {
    const radius = ActivityIndexStyleFactory.getRadiusForActivityIndex(
      avalanche.activityIndex
    )
    textOffsetY = -(radius + 4)
    styleArray.push(
      ActivityIndexStyleFactory.getInstance().getActivityIndexStyle(
        avalanche.activityIndex,
        avalanche.avalancheMoisture,
        useInputMapStyling
      )
    )
  }

  addLabelStyleIfExists(
    styleArray,
    getLabelStyle,
    avalanche,
    geometry,
    textOffsetY
  )

  styleArray.push(
    GeneralStyleFactory.getInstance().getGeneralStyleToAvalMoisture(
      avalanche.avalancheMoisture,
      useInputMapStyling
    )
  )
  return styleArray
}

const addLabelStyleIfExists = function (
  styleArray: Array<Style>,
  getLabelStyle: GetLabelStyleFunction,
  avalanche: Avalanche,
  geometry: Geometry,
  textOffsetY: number
) {
  const labelStyle = getLabelStyle(avalanche, geometry, textOffsetY, 0)
  if (labelStyle) {
    styleArray.push(labelStyle)
  }
}

<template>
  <div
    id="filterSelect"
    v-if="visible && $props.filterOptions"
    class="label"
    :class="{ filteropt: !mobile, filteropt_mobile: mobile }"
  >
    <span v-if="filterOptions.title"
      ><b>{{ filterOptions.title }}</b></span
    >
    <b-form-group
      v-for="entry in filterOptions.items"
      :key="entry.key"
      :label="entry.label"
      style="pointer-events: all"
      label-class="font-weight-bold"
      class="filter-group"
    >
      <b-form-checkbox-group
        :id="entry.key"
        v-model="selectedFilters[entry.key]"
        :options="entry.options"
        :name="entry.key"
        @change="updateFilter"
        stacked
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'FilterMultiple',
  props: ['filterOptions'],
  watch: {
    filterOptions: {
      handler(newVal, oldVal) {
        // initialize w/ all options selected
        if (oldVal === 'undefined' || oldVal === null) {
          const vm = this
          if (newVal && newVal.items) {
            newVal.items.forEach((item) => {
              const all = []
              item.options.forEach((option) => {
                all.push(option.value)
              })
              vm.selectedFilters[item.key] = all
              const array = item.key.split('.')
              const name = array[0].concat(array[1])
              if (vm.$store.state.filter[name] !== undefined) {
                vm.selectedFilters[item.key] = vm.$store.state.filter[name]
                vm.$store.dispatch('filter/resetFilter', name)
              }
            })
            this.updateFilter()
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      selectedFilters: {},
      mobile: false,
      filterName: undefined,
    }
  },
  beforeUnmount() {
    emitter.off('showFilter')
    emitter.off('save-menu-state-filters')
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      this.visible = true
    } else {
      this.mobile = true
    }
  },
  mounted() {
    const vm = this
    emitter.on('showFilter', (show) => {
      show ? (vm.visible = true) : (vm.visible = false)
    })
    emitter.on('save-menu-state-filters', () => {
      if (vm.selectedFilters) {
        Object.keys(vm.selectedFilters).forEach(function (key) {
          vm.$store.dispatch('filter/setFilter', {
            name: key,
            value: vm.selectedFilters[key],
          })
        })
      }
    })
  },
  methods: {
    updateFilter() {
      // NOTE: watch wouldn't be good since need to react on user modifications only
      this.$nextTick(function () {
        emitter.emit('update::filter', { filters: this.selectedFilters })
      })
    },
  },
}
</script>

<style scoped>
.filteropt {
  top: 3.6em;
  border: 1px solid #929292;
  border-radius: 4px;
  width: 14em;
  opacity: 1;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  display: inline-block;
  position: relative;
  padding: 10px;
}

.filteropt_mobile {
  opacity: 1;
  border: 1px solid #929292;
  border-radius: 4px;
  position: absolute;
  top: 12em;
  right: 0.6em;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  padding: 5px 5px 0;
  display: inline-block;
  max-width: 14em;
}

.label {
  font-size: 12px;
}

.filteropt .filter-group {
  margin-bottom: 0.5rem;
}
</style>

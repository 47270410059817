<template>
  <div>
    <Legend :legend="legend"></Legend>
    <CommonLayersPopup></CommonLayersPopup>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal
      detId="detailModal"
      :obsIds="obsIds"
      :showEdit="updatableModal"
      :aggregate="true"
    />
  </div>
</template>

<script>
import MeasurementStyle from '../styles/snowStyling/MeasurementStyle'
import Legend from '../Legend'
import moment from 'moment'
import DetailModal from '../observation/DetailModal'
import { Canceler } from '@/scripts/requestUtil.js'
import CommonLayersPopup from '../CommonLayersPopup'
import emitter from '@/components/EventHub'

const canceler = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snowline',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { CommonLayersPopup, Legend, DetailModal },
  data() {
    return {
      legend: null,
      obsIds: [],
      updatableModal: false,
      selected: 'snowline.productVariantNorth',
      lastMoment: undefined,
      lastDatePreset: undefined,
      isLoading: false,
    }
  },

  mounted() {
    this.refresh()
    emitter.on('update::filter', (filter) => {
      if (
        filter &&
        filter.productVariant &&
        filter.productVariant.startsWith('snowline.') &&
        (this.selected !== filter.productVariant || filter.restoreFilter)
      ) {
        this.selected = filter.productVariant
        this.refresh()
      }
    })
    emitter.on('repos::map', (flag) => {
      if (flag && flag === true) {
        this.isLoading = true
      } else {
        this.isLoading = false
      }
    })
  },

  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },

  beforeUnmount() {
    emitter.off('update::filter')

    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },

  methods: {
    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },

    findAndShowObservation(observationId, featureCollection) {
      this.obsIds = []
      const index = featureCollection.features.findIndex((item) => {
        return item.properties.id === observationId
      })
      if (index > -1) {
        this.updatableModal = moment().isSame(
          moment(featureCollection.features[index].timestamp),
          'd'
        )
        this.obsIds.push(observationId)
        this.$bvModal.show('detailModal')
      }
    },

    refresh() {
      this.isLoading = true

      console.debug('Cancel requests on load')
      this.cancelRequests()

      const legend = {}
      legend.items = MeasurementStyle('snowline').legend()
      legend.title = this.getLegendTitle()
      this.legend = legend
      this.$forceUpdate()
      this.style = new MeasurementStyle('snowline')
      this.styleFunction = this.style.styleFunction
      const self = this
      const now = Object.assign({}, this.$store.state.calendar.moment)
      const cancelObj = { cancel: null }

      this.$store
        .dispatch('snow/loadSnowObservationsByDate', {
          param: 'snowline',
          date: moment(now),
          cancel: cancelObj,
        })
        .then(() => {
          const featureCollection = Object.assign(
            {},
            self.$store.state.snow.snowFeatureCollection
          )
          const exposition =
            self.selected === 'snowline.productVariantNorth' ? 'north' : 'south'
          featureCollection.features = featureCollection.features.filter(
            (observation) =>
              observation.properties.snowline &&
              observation.properties.snowline[exposition]
          )
          const reducedFeatures = []
          featureCollection.features.forEach((observation) => {
            const reducedFeature = Object.assign({}, observation)
            reducedFeature.properties = Object.assign(
              {},
              observation.properties
            )
            reducedFeature.properties.snowline =
              observation.properties.snowline[exposition]
            reducedFeatures.push(reducedFeature)
          })
          featureCollection.features = reducedFeatures
          const snowLineClickHandler = function (feature) {
            self.obsIds = []
            if (feature) {
              const index = featureCollection.features.findIndex((item) => {
                return item.properties.id === feature.get('id')
              })
              if (index > -1) {
                self.updatableModal = moment().isSame(
                  moment(featureCollection.features[index].date),
                  'd'
                )
                self.obsIds.push(feature.get('id'))
                self.$bvModal.show('detailModal')
              }
            }
          }
          const resolutionChangeHandler = function (
            resolution,
            layers,
            spider
          ) {
            spider.resolutionChangeHandler(
              layers['snowline'].vectorLayer,
              resolution
            )
          }
          emitter.emit('layer', {
            name: 'snowline',
            features: featureCollection,
            styleFunction: this.styleFunction,
            onClick: snowLineClickHandler,
            style: this.style,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit('mouseOver', this.style.mouseOverFunction)
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }

          self.isLoading = false
        })
        .catch((e) => {
          console.error('Catch request loadSnowObservationsByDate: ' + e)
          alert(e)
          self.isLoading = false
        })
      canceler.addCancel(cancelObj.cancel)
    },

    getLegendTitle() {
      const title = this.$i18n.t(
        this.selected === 'snowline.productVariantNorth'
          ? 'snow.label.snowlineNorthShort'
          : 'snow.label.snowlineSouthShort'
      )
      return { title: title, unit: 'common.unit.meterAboveSeaLevel' }
    },

    cancelRequests() {
      canceler.cancelAll()
    },
  },
}
</script>

<style scoped>
.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

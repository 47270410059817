'use strict'

import { Control } from 'ol/control.js'
import Point from 'ol/geom/Point'
import { i18n } from '@/plugins/i18n'
import { DEFAULT_CENTER } from '@/scripts/Map.js'
import emitter from '@/components/EventHub'

export default class PositionControl extends Control {
  constructor(view, draw, mobile, positionIconEl) {
    const element = document.createElement('div')
    super({
      element: element,
    })
    this.view = view
    const self = this

    if (!mobile) {
      this.swissZoom = 15
      const clickedSwitzerland = function (event) {
        event.preventDefault()
        view.setZoom(self.swissZoom)
        view.setCenter(DEFAULT_CENTER)
      }
      const buttonSwitzerland = document.createElement('button')
      buttonSwitzerland.innerHTML =
        '<img src="./static/avalanche/Icon_Schweiz_300_hoch.png" width="29" height="29">'
      buttonSwitzerland.id = 'switzerlandButton'
      buttonSwitzerland.className = 'button-switzerland'
      buttonSwitzerland.addEventListener('click', clickedSwitzerland, false)
      element.appendChild(buttonSwitzerland)
    }

    const clickedGPS = function (event) {
      event.preventDefault()
      if (navigator.geolocation) {
        console.log('geolocation exists')
        navigator.geolocation.getCurrentPosition(setPosition, showError)
      } else {
        alert(i18n.global.t('msg.locationNotSupported'))
      }
    }

    function setPosition(position) {
      const positionPoint = new Point([
        position.coords.longitude,
        position.coords.latitude,
      ])
      const transformedPoint = positionPoint
        .transform('EPSG:4326', 'EPSG:21781')
        .getCoordinates()
      view.setCenter(transformedPoint)
      view.setZoom(24)
      switch (draw) {
        case 'observation':
          emitter.emit('drawLocation', {
            coordinates: [position.coords.longitude, position.coords.latitude],
          })
          break
        case 'visualisation':
          emitter.emit('drawPositionVisualisation', {
            coordinates: transformedPoint,
          })
          break
        default:
      }
    }

    function showError(error) {
      console.log(JSON.stringify(error))
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert(i18n.global.t('msg.locationDenied'))
          break
        case error.POSITION_UNAVAILABLE:
          alert(i18n.global.t('msg.locationNotAvailable'))
          break
        case error.TIMEOUT:
          alert(i18n.global.t('msg.locationTimeOut'))
          break
        case error.UNKNOWN_ERROR:
          alert(i18n.global.t('msg.locationUnknown: ') + error.message)
          break
      }
    }

    const buttonGPS = document.createElement('button')
    buttonGPS.appendChild(positionIconEl)

    buttonGPS.addEventListener('click', clickedGPS, false)

    element.appendChild(buttonGPS)
    if (mobile) {
      element.className = 'ol-unselectable ol-control position-mobile'
    } else {
      element.className = 'ol-unselectable ol-control position'
    }
  }
  setSwissZoom(zoom) {
    this.swissZoom = zoom
  }
}

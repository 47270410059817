<template>
  <div class="row">
    <div class="col-lg">
      <Legend :legend="legend"></Legend>
      <CommonLayersPopup></CommonLayersPopup>
    </div>
    <div v-if="isLoading === true" class="abstract-map-spinner">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <DetailModal
      detId="detailModal"
      :obsIds="obsIds"
      :aggregate="aggregate"
      :aval="aval"
    />
    <DetailModalSnowprofile
      detId="detailModalSnowprofile"
      :aval="detailValue"
    />
  </div>
</template>

<script>
import Legend from '../Legend'
import DetailModal from '../observation/DetailModal'
import DetailModalSnowprofile from '../snowprofile/DetailModalSnowprofile'
import moment from 'moment'
import CommonLayersPopup from '../CommonLayersPopup'
import emitter from '@/components/EventHub'

export default {
  mounted() {
    this.refresh()
  },
  props: ['moment', 'calendar'],
  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
    calendarPresetInterval: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },
  components: {
    CommonLayersPopup,
    // eslint-disable-next-line vue/no-reserved-component-names
    Legend,
    DetailModal,
    DetailModalSnowprofile,
  },
  data() {
    return {
      style: undefined, // SetByChild
      typ: undefined, // SetByChild
      legend: undefined, // SetByChild
      obsIds: [],
      aval: null,
      detailValue: null,
      aggregate: false,
      isShowingDetail: false,
      isLoading: false,
      lastMoment: undefined,
      lastDatePreset: undefined,
    }
  },
  created() {
    emitter.on('repos::map', (flag) => {
      if (flag && flag === true) {
        this.isLoading = true
      } else {
        this.isLoading = false
      }
    })
  },
  beforeUnmount() {
    emitter.off('repos::map')
  },
  computed: {
    calendarPresetInterval() {
      return this.calendar.getPresetInterval()
    },
  },
  methods: {
    findAndShowObservation(observationId, featureCollection) {
      this.obsIds = []
      const index = featureCollection.features.findIndex((item) => {
        if (item.properties) {
          return item.properties.id === observationId
        } else if (item.avalanche) {
          return item.avalanche.id === observationId
        } else if (item.get('id')) {
          return item.get('id') === observationId
        } else {
          return false
        }
      })
      if (index > -1) {
        this.updatableModal = moment().isSame(
          moment(featureCollection.features[index].timestamp),
          'd'
        )
        this.obsIds.push(observationId)
        this.$bvModal.show('detailModal')
      }
    },
    setIndividualProps() {
      // Implement this Methos in your extending component!
      // This one WON'T be executed
    },
    getObservationData() {
      // Implement this Methos in your extending component!
      // This one WON'T be executed
    },
    setSpecialClickHandler() {
      // Implement this Methos in your extending component, or let it empty for using universal clickHandler
    },
    refresh() {
      this.isLoading = true

      const self = this
      this.setIndividualProps()
      const styleFunction = this.style.styleFunction

      this.getObservationData(this.calendar.getPresetInterval())
        .then((result) => {
          const featureCollection = result
          emitter.emit('layer', {
            name: self.typ,
            features: featureCollection,
            styleFunction: styleFunction,
            onClick: self.getClickHandler(featureCollection),
            style: this.style,
            onResolutionChange: this.needsResolutionChangeHandler()
              ? self.getResolutionChangeHandler(self.typ)
              : null,
          })
          emitter.emit('mouseOver', self.style.mouseOverFunction)
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },
    getClickHandler(featureCollection) {
      const specialClickHandlerFunction =
        this.setSpecialClickHandler(featureCollection)
      if (specialClickHandlerFunction) {
        return specialClickHandlerFunction
      }
      return this.getUniversalClickHandler(featureCollection)
    },
    getResolutionChangeHandler(layerName) {
      return function (resolution, layers, spider) {
        spider.resolutionChangeHandler(
          layers[layerName].vectorLayer,
          resolution
        )
      }
    },
    getUniversalClickHandler(featureCollection) {
      const self = this
      return function (feature) {
        self.obsIds = []
        const featureTyp = feature.get(self.typ)
        if (featureTyp) {
          const foundFeature = featureCollection.features.find((item) => {
            return item.properties.id === feature.get('id')
          })
          if (foundFeature) {
            self.obsIds.push(feature.get('id'))
            self.$bvModal.show('detailModal')
          }
        }
      }
    },
    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },
  },
}
</script>

<!--
Scoped Styles werden, wenn diese Komponente mit 'extends' wiederverwendet
wird, im Parent nicht mehr berücksichtigt. Daher wird dieser Teil ausgelagert
und in den Parents importiert. ACHTUNG: Hier trotzdem auch belassen/importieren.
Dies ist nötig, falls die Komponente, als 'Component-Child' verwendet wird!
-->
<style lang="css" scoped src="./AbstractMapView.css"></style>

// state
const state = {
  avalanche: undefined,
  snowpack: undefined,
  surface: undefined,
  snowline: undefined,
  newsnow: undefined,
  danger: undefined,
  problem: undefined,
  dangerorigin: undefined,
  avalancheavalancheType: undefined,
  avalanchetriggerType: undefined,
  snowpackFilter: undefined,
  surfacefilters: undefined,
  currentSubMenu: undefined,
  selectedSubMenusEntries: undefined,
}

// getters
const getters = {
  getSubmenuEntryToKey: (state) => (key) => {
    if (state.selectedSubMenusEntries) {
      return state.selectedSubMenusEntries.get(key)
    }
  },
}

// actions
const actions = {
  updateSubmenuEntry({ commit }, submenuEntry) {
    commit('SET_SELECTED_SUBMENUENTRY', submenuEntry)
  },
  // eslint-disable-next-line complexity
  setVariant({ commit }, variant) {
    if (variant.includes('avalanche')) {
      commit('SET_AVALANCHE_VARIANT', variant)
    } else if (variant.includes('snowpack')) {
      commit('SET_SNOWPACK_VARIANT', variant)
    } else if (variant.includes('surface')) {
      commit('SET_SURFACE_VARIANT', variant)
    } else if (variant.includes('snowline')) {
      commit('SET_SNOWLINE_VARIANT', variant)
    } else if (variant.includes('newsnow')) {
      commit('SET_NEWSNOW_VARIANT', variant)
    } else if (variant.includes('danger')) {
      commit('SET_DANGER_VARIANT', variant)
    } else if (variant.includes('problem')) {
      commit('SET_PROBLEM_VARIANT', variant)
    }
  },
  setFilter({ commit }, filter) {
    console.log('####Set Filter#####')
    if (filter.name.includes('snowpack')) {
      commit('SET_SNOWPACK_FILTER', filter.value)
    } else if (filter.name.includes('surface')) {
      commit('SET_SURFACE_FILTER', filter.value)
    } else if (filter.name === 'danger.origin') {
      commit('SET_DANGERORIGIN_FILTER', filter.value)
    } else if (filter.name === 'avalanche.avalancheType') {
      commit('SET_AVALANCHEAVALANCHETYPE_FILTER', filter.value)
    } else if (filter.name === 'avalanche.triggerType') {
      commit('SET_AVALANCHETRIGGERTYPE_FILTER', filter.value)
    }
  },
  // eslint-disable-next-line complexity
  resetVariant({ commit }, variant) {
    if (variant.includes('avalanche')) {
      commit('SET_AVALANCHE_VARIANT', undefined)
    } else if (variant.includes('snowpack')) {
      commit('SET_SNOWPACK_VARIANT', undefined)
    } else if (variant.includes('surface')) {
      commit('SET_SURFACE_VARIANT', undefined)
    } else if (variant.includes('snowline')) {
      commit('SET_SNOWLINE_VARIANT', undefined)
    } else if (variant.includes('newsnow')) {
      commit('SET_NEWSNOW_VARIANT', undefined)
    } else if (variant.includes('danger')) {
      commit('SET_DANGER_VARIANT', undefined)
    } else if (variant.includes('problem')) {
      commit('SET_PROBLEM_VARIANT', undefined)
    }
  },
  resetAllVariants({ commit }) {
    commit('SET_AVALANCHE_VARIANT', undefined)
    commit('SET_SNOWPACK_VARIANT', undefined)
    commit('SET_SURFACE_VARIANT', undefined)
    commit('SET_SNOWLINE_VARIANT', undefined)
    commit('SET_NEWSNOW_VARIANT', undefined)
    commit('SET_DANGER_VARIANT', undefined)
    commit('SET_PROBLEM_VARIANT', undefined)
  },
  resetFilter({ commit }, filter) {
    if (filter.includes('snowpack')) {
      commit('SET_SNOWPACK_FILTER', undefined)
    } else if (filter.includes('surface')) {
      commit('SET_SURFACE_FILTER', undefined)
    } else if (filter === 'dangerorigin') {
      commit('SET_DANGERORIGIN_FILTER', undefined)
    } else if (filter === 'avalancheavalanchetype') {
      commit('SET_AVALANCHEAVALANCHETYPE_FILTER', undefined)
    } else if (filter === 'avalanchetriggertype') {
      commit('SET_AVALANCHETRIGGERTYPE_FILTER', undefined)
    }
  },
}

// mutations
const mutations = {
  SET_AVALANCHE_VARIANT(state, avalancheVariant) {
    state.avalanche = avalancheVariant
  },
  SET_SNOWPACK_VARIANT(state, snowpackVariant) {
    state.snowpack = snowpackVariant
  },
  SET_SURFACE_VARIANT(state, surfaceVariant) {
    state.surface = surfaceVariant
  },
  SET_SNOWLINE_VARIANT(state, snowlineVariant) {
    state.snowline = snowlineVariant
  },
  SET_NEWSNOW_VARIANT(state, newsnowVariant) {
    state.newsnow = newsnowVariant
  },
  SET_DANGER_VARIANT(state, dangerVariant) {
    state.danger = dangerVariant
  },
  SET_PROBLEM_VARIANT(state, problemVariant) {
    state.problem = problemVariant
  },
  SET_SNOWPACK_FILTER(state, snowpackFilter) {
    state.snowpackFilter = snowpackFilter
  },
  SET_SURFACE_FILTER(state, surfaceFilter) {
    state.surfacefilters = surfaceFilter
  },
  SET_DANGERORIGIN_FILTER(state, dangerFilter) {
    state.dangerorigin = dangerFilter
  },
  SET_AVALANCHEAVALANCHETYPE_FILTER(state, filter) {
    state.avalancheavalancheType = filter
  },
  SET_AVALANCHETRIGGERTYPE_FILTER(state, filter) {
    state.avalanchetriggerType = filter
  },
  SET_CURRENT_SUBMENU(state, currentSubMenu) {
    state.currentSubMenu = currentSubMenu
  },
  SET_SELECTED_SUBMENUENTRY(state, submenuEntry) {
    if (!state.selectedSubMenusEntries) {
      state.selectedSubMenusEntries = new Map()
    }
    state.selectedSubMenusEntries.set(submenuEntry.key, submenuEntry.value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div id="app">
    <div class="container">
      <!--UPLOAD-->
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="(isInitial || isSaving) && descriptionState !== false"
      >
        <div class="dropbox" id="dropzone">
          <input
            type="file"
            multiple
            name="images"
            :disabled="isSaving"
            @change="filesChangeEvent($event.target.files)"
            class="input-file"
            id="dropzoneII"
          />
          <template v-if="isInitial">
            <p
              v-if="isDragDropSupport"
              v-html="$t('fileupload.label.chooseFiles')"
            ></p>
            <p v-else v-html="$t('fileupload.label.chooseFilesBasic')"></p>
          </template>
          <p v-if="isSaving">
            {{ $tc('fileupload.label.uploading', fileCount) }}
          </p>
        </div>
      </form>
      <b-form-group>
        <template #label>
          <InfoLabel
            :text="$t('fileupload.label.description')"
            :info="$t('help.avalanche.description')"
          />
        </template>
        <b-form-input
          id="description"
          size="sm"
          v-model="uploadDescription"
          :rows="3"
          :state="descriptionState"
          aria-describedby="descriptionFeedback"
        />
        <b-form-invalid-feedback id="descriptionFeedback">
          {{ $t('msg.maxCharacters', { count: '500' }) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>{{ $t('fileupload.label.failure') }}</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">{{
            $t('fileupload.label.retry')
          }}</a>
        </p>
        <p>{{ uploadError }}</p>
      </div>
    </div>
  </div>
</template>

<!-- Javascript -->
<script>
import moment from 'moment'
import { i18n } from '@/plugins/i18n'
import {
  MAX_FILE_SIZE,
  RESTRICTED,
  NOT_RESTRICTED,
} from '@/scripts/documentUtil'
import InfoLabel from '../InfoLabel'
import { AVAL } from '@/scripts/const'
import util from '@/scripts/util.js'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'App',
  components: { InfoLabel },
  props: ['locationPoint', 'type', 'restriction'], // selected position from wrapping form, might be null
  data() {
    return {
      uploadedFiles: [],
      uploadDescription: null,
      uploadError: null,
      currentStatus: null,
      fileCount: null,
    }
  },
  mounted() {
    this.reset()
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    isDragDropSupport() {
      // NOTE: using browser check rather than feature detection since w/ latter it reports drag&drop support available which is not the case...
      return !(
        navigator.userAgent.indexOf('Edge') >= 0 ||
        this.$store.state.mobilePlatform
      )
    },
    descriptionState() {
      if (!this.uploadDescription) {
        return null
      }
      return this.uploadDescription && this.uploadDescription.length <= 500
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadDescription = null
      this.uploadError = null
    },
    save(fileList, lon, lat, description, keywords, eventDate, restriction) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING
      this.$store
        .dispatch('document/uploadDocuments', {
          files: fileList,
          lon: lon,
          lat: lat,
          description: description,
          keywords: keywords,
          eventDate: eventDate,
          restriction: restriction,
        })
        .then((x) => {
          this.uploadedFiles = [].concat(x)
          this.currentStatus = STATUS_SUCCESS
          this.$emit('uploaded', this.uploadedFiles)
        })
        .catch((err) => {
          console.error(err.response ? err.response : err.message)
          // NOTE: display only one property instead of whole error response
          const storedException = this.$store.state.exception
          this.uploadError =
            storedException && storedException.message
              ? storedException.message +
                (storedException.details ? ' ' + storedException.details : '')
              : storedException
          this.currentStatus = STATUS_FAILED
        })
    },
    filesChangeEvent(files) {
      this.filesChange(files)
      this.fileCount = files.length
    },
    // eslint-disable-next-line complexity
    filesChange(fileList) {
      // handle file changes
      if (!fileList || !fileList.length) return

      const sumSize = this.calculateFileSize(fileList)

      if (sumSize > MAX_FILE_SIZE * 1024 * 1024) {
        this.currentStatus = STATUS_FAILED
        this.uploadError = i18n.global.t('msg.validFileSize', {
          maxsize: MAX_FILE_SIZE,
        })
      } else {
        let lon = null
        let lat = null
        if (util.isPoint(this.locationPoint)) {
          lon = this.locationPoint.coordinates[0]
          lat = this.locationPoint.coordinates[1]
        }
        const keywords = this.type === AVAL ? ['AVALANCHE'] : ['OBSERVATION']
        this.save(
          fileList,
          lon,
          lat,
          this.uploadDescription,
          keywords,
          moment(),
          this.restriction === true ? RESTRICTED : NOT_RESTRICTED
        ) // TODO: event date: beobachtungsdatum und nicht meldedatumzeit, wenn nicht gleicher tag hier korrigieren
      }
    },
    calculateFileSize(fileList) {
      let sum = 0
      const len = fileList.length
      let file = null

      for (let i = 0; i < len; i++) {
        file = fileList[i]
        sum += file.size
      }
      return sum
    },
  },
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.img-thumbnail {
  width: 100px;
}
</style>

'use strict'

import Map from 'ol/Map'
import View from 'ol/View'
import VectorLayer from 'ol/layer/Vector'
import ModifyDeleteControl from './modifyDeleteControl/ModifyDeleteControl.ts'
import InfoControl from './InfoControl.js'
import DrawControl from './DrawControl.js'
import avalancheUtil from '../../scripts/avalancheInputMap/avalancheUtil.js'
import ClusterVectorLayer from '../clustering/ClusterVectorLayer'
import VectorSource from 'ol/source/Vector'
import FullCluster from '../clustering/FullCluster'
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom.js'
import PositionControl from '../controls/PositionControl.js'
import PinchRotate from 'ol/interaction/PinchRotate.js'
import DragZoom from 'ol/interaction/DragZoom.js'
import { AvalancheStyleInput } from '@/components/styles/avalancheStyling/AvalancheStyleInput'
import { AvalancheDrawStyle } from '@/components/styles/avalancheStyling/AvalancheDrawStyle'
import Draw from 'ol/interaction/Draw.js'
import { createCommonLayers } from '../common/commonLayers'
import {
  createCommonControls,
  getAttributionControl,
  getZoomControl,
} from '../common/commonControls'
import {
  getDrawControl,
  getModifyDeleteControl,
} from './avalancheInputControls'
import { resolutionLimit } from '../layers/tileLayerUtil'
import {
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_INFO_CIRCLE,
  FA_SOLID_FA_LAYER_GROUP,
} from '@/plugins/fontawesomeConsts'
import { DEFAULT_VIEW_EXTENT } from '@/scripts/Map'

export default class AvalancheMap extends Map {
  // eslint-disable-next-line complexity
  constructor(mapComponent, defaultZoom, target, faIconRefs) {
    const DEFAULT_CENTER = mapComponent.$store.state.map.center || [
      660000, 180000,
    ]
    const DEFAULT_ZOOM = mapComponent.$store.state.map.zoom || defaultZoom
    const CLUSTER_DISTANCE = 50

    const drawnItems = new VectorLayer({
      source: mapComponent.drawnSource,
      style: new AvalancheDrawStyle().styleFunction,
      zIndex: 20,
    })

    const style = new AvalancheStyleInput()
    const styleFunction = style.styleFunction
    const preexistingItemsWrapper = new ClusterVectorLayer(
      'preexisting',
      styleFunction,
      CLUSTER_DISTANCE,
      new FullCluster(
        {
          distance: CLUSTER_DISTANCE,
          geometryFunction: avalancheUtil.getPointOfFeature,
          source: new VectorSource({}),
        },
        null
      )
    )
    preexistingItemsWrapper.setStyle(style)
    mapComponent.preexistingSource = preexistingItemsWrapper.getSource()

    const view = new View({
      projection: mapComponent.projection,
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
      zoomFactor: 1.5,
      minZoom: 11,
      maxZoom: 35,
      extent: DEFAULT_VIEW_EXTENT,
      constrainOnlyCenter: true,
    })

    const additionalControls = []
    if (!mapComponent.readOnly) {
      const positionControl = new PositionControl(
        view,
        'avalanche',
        mapComponent.mobile,
        faIconRefs[FA_SOLID_FA_CROSSHAIRS]
      )
      const infoControl = new InfoControl(
        mapComponent,
        faIconRefs[FA_SOLID_FA_INFO_CIRCLE]
      )
      additionalControls.push(positionControl, infoControl)
      if (!mapComponent.multi) {
        infoControl.element.classList.add('single-position')
        const modifyDeleteControl = new ModifyDeleteControl(
          mapComponent,
          faIconRefs
        )
        const drawControl = new DrawControl(
          mapComponent,
          modifyDeleteControl,
          faIconRefs
        )
        modifyDeleteControl.setDrawControl(drawControl)
        additionalControls.push(drawControl, modifyDeleteControl)
      } else {
        infoControl.element.classList.add('several-position')
      }
    }
    const controls = createCommonControls(
      mapComponent.mobile,
      faIconRefs[FA_SOLID_FA_LAYER_GROUP]
    ).extend(additionalControls)

    const namedCommonLayers = createCommonLayers()
    const commonLayers = {}
    for (const { name, layer } of namedCommonLayers) {
      commonLayers[name] = layer
    }
    const commonLayerArray = namedCommonLayers.map(({ layer }) => layer)

    const options = {
      controls: controls,
      theme: null,
      layers: [
        ...commonLayerArray,
        drawnItems,
        preexistingItemsWrapper.getLayer(),
      ],
      target: target,
      view: view,
      renderer: 'canvas',
    }
    super(options)
    this.layers = options.layers
    preexistingItemsWrapper.clusterSource.setOlMap(this)
    this.commonLayers = commonLayers
    if (!mapComponent.readOnly && mapComponent.multi) {
      this.addDrawInteraction(mapComponent)
    }

    this.mapComponent = mapComponent
    this.mapComponent.showTwoFingerMessage = false
    this.drawPresentFeatures()
    const centralPoint = this.getCentralPointOfPresentFeatures()
    if (centralPoint) {
      if (!mapComponent.readOnly && mapComponent.adaptMap) {
        this.mapComponent.$store.dispatch('map/setCenter', centralPoint)
        this.mapComponent.$store.dispatch('map/setZoom', 20)
      }
      this.getView().setCenter(centralPoint)
      this.getView().setZoom(20)
    }
    const features = mapComponent.drawnSource.getFeatures()
    const modifyDeleteControl = getModifyDeleteControl(this.getControls())
    if (features.length > 0 && modifyDeleteControl && !mapComponent.multi) {
      modifyDeleteControl.setDisabled(false)
    }

    this.preexistingItemsWrapper = preexistingItemsWrapper
    this.preexistingItemsWrapper.updateResolution(
      super.getView().getResolution()
    )

    this.setAttributionVisibility()

    let ghostZoom = view.getZoom()
    // eslint-disable-next-line complexity
    this.on('moveend', () => {
      this.preexistingItemsWrapper.clusterSource.recalculateCluster()

      if (!mapComponent.readOnly && mapComponent.adaptMap) {
        mapComponent.$store.dispatch('map/setCenter', view.getCenter())
        mapComponent.$store.dispatch('map/setZoom', 25)
      }
      if (ghostZoom !== view.getZoom()) {
        this.setAttributionVisibility()
        ghostZoom = view.getZoom()
        const zoomControl = getZoomControl(super.getControls())
        if (zoomControl) {
          zoomControl.element.children[1].disabled =
            view.getZoom() === view.getMinZoom()
          zoomControl.element.children[0].disabled =
            view.getZoom() === view.getMaxZoom()
        }
        this.preexistingItemsWrapper.updateResolution(
          super.getView().getResolution()
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let timeoutRef = setTimeout(() => {
          this.updateSize()
          timeoutRef = null
        }, 10)
      }
    })

    // do not zoom on drag, as its confusing
    const dragZoomIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof DragZoom
      })
    this.getInteractions().removeAt(dragZoomIndex)

    // remove zoom on double click which is a problem when terminating a lineString
    const dbClickIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof DoubleClickZoom
      })
    this.getInteractions().removeAt(dbClickIndex)

    // remove rotate with two fingers
    const rotateIndex = this.getInteractions()
      .getArray()
      .findIndex(function (element) {
        return element instanceof PinchRotate
      })
    this.getInteractions().removeAt(rotateIndex)
  }
  setAttributionVisibility() {
    if (super.getView().getResolution() < resolutionLimit) {
      getAttributionControl(super.getControls()).element.classList.remove(
        'ol-hidden'
      )
    } else {
      getAttributionControl(super.getControls()).element.classList.add(
        'ol-hidden'
      )
    }
  }
  // eslint-disable-next-line complexity
  getCentralPointOfPresentFeatures() {
    let centralPoint
    if (
      this.mapComponent.avalancheGeo ||
      this.mapComponent.coord ||
      this.mapComponent.depositGeo ||
      this.mapComponent.startZoneGeo
    ) {
      const features = this.mapComponent.drawnSource.getFeatures()
      if (features && features.length > 0) {
        if (this.mapComponent.coord) {
          centralPoint = features[0].getGeometry().getCoordinates()
        } else if (this.mapComponent.avalancheGeo) {
          centralPoint = avalancheUtil.getCenter(
            features[0].getGeometry().getExtent()
          )
        } else if (
          this.mapComponent.startZoneGeo &&
          this.mapComponent.depositGeo
        ) {
          centralPoint = features[0].getGeometry().getCoordinates()
        } else if (
          this.mapComponent.startZoneGeo ||
          this.mapComponent.depositGeo
        ) {
          centralPoint = features[0].getGeometry().getCoordinates()
        }
      }
    }
    return centralPoint
  }
  // eslint-disable-next-line complexity
  drawPresentFeatures() {
    this.mapComponent.drawnSource.clear(true)
    if (
      this.mapComponent.avalancheGeo ||
      this.mapComponent.coord ||
      this.mapComponent.depositGeo ||
      this.mapComponent.startZoneGeo
    ) {
      const features = this.addFeaturesOfAvalancheToSource(
        this.mapComponent,
        this.mapComponent.drawnSource
      )
      if (features && features.length > 0) {
        const modifyDeleteControl = getModifyDeleteControl(this.getControls())
        if (modifyDeleteControl && !this.mapComponent.multi) {
          modifyDeleteControl.setDisabled(false)
        }
      }
    }
    if (!this.mapComponent.readOnly && !this.mapComponent.multi) {
      getDrawControl(this.getControls()).adaptButtonDisabling(this.mapComponent)
    }
  }
  addExistingFeatures(data, avalancheId) {
    data.features = data.features.filter((f) => {
      const avalanche = f.get('avalanche')
      return avalanche.id !== avalancheId
    })
    this.preexistingItemsWrapper.addFeatures(data.features)
  }
  addDrawInteraction(mapComponent) {
    this.draw = new Draw({
      source: mapComponent.drawnSource,
      type: 'Point',
    })
    this.draw.on('drawend', function (e) {
      const geometry = e.feature.getGeometry()
      mapComponent.drawnSource.clear(true)
      mapComponent.addFeature(geometry)
    })
    this.addInteraction(this.draw)
    mapComponent.drawingPoint = true
  }
  // eslint-disable-next-line complexity
  addFeaturesOfAvalancheToSource(mapComponent, source) {
    const features = []
    let avalancheGeo = false
    if (
      mapComponent.avalancheGeo &&
      mapComponent.avalancheGeo.type === 'LineString'
    ) {
      const avalancheLineFeature = avalancheUtil.addLine(
        source,
        mapComponent.avalancheGeo.coordinates,
        this.mapComponent.projection
      )
      avalancheLineFeature.getGeometry().locationType = 'avalancheGeo'
      features.push(avalancheLineFeature)
      avalancheGeo = true
    }
    if (
      mapComponent.avalancheGeo &&
      mapComponent.avalancheGeo.type === 'Polygon'
    ) {
      const avalanchePolygonFeature = avalancheUtil.addPolygon(
        source,
        mapComponent.avalancheGeo.coordinates,
        this.mapComponent.projection
      )
      avalanchePolygonFeature.getGeometry().locationType = 'avalancheGeo'
      features.push(avalanchePolygonFeature)
      avalancheGeo = true
    }
    if (mapComponent.startZoneGeo) {
      const startZoneFeature = avalancheUtil.addCircle(
        source,
        mapComponent.startZoneGeo.coordinates,
        this.mapComponent.projection
      )
      startZoneFeature.getGeometry().locationType = 'startZoneGeo'
      if (avalancheGeo) startZoneFeature.getGeometry().computed = true
      features.push(startZoneFeature)
    }
    if (mapComponent.depositGeo) {
      const depositFeature = avalancheUtil.addCircle(
        source,
        mapComponent.depositGeo.coordinates,
        this.mapComponent.projection
      )
      depositFeature.getGeometry().locationType = 'depositGeo'
      if (avalancheGeo) depositFeature.getGeometry().computed = true
      features.push(depositFeature)
    }
    if (mapComponent.coord) {
      const avalanchePointFeature = avalancheUtil.addCircle(
        source,
        mapComponent.coord.coordinates,
        this.mapComponent.projection
      )
      avalanchePointFeature.getGeometry().locationType = 'multi'
      features.push(avalanchePointFeature)
    }
    return features
  }

  setCommonLayerVisibility(layerName, visible) {
    this.commonLayers[layerName].setVisible(visible)
  }

  releaseMem() {
    this.layers.forEach((layer) => {
      this.removeLayer(layer)
      if (layer instanceof ClusterVectorLayer) {
        layer.releaseMem()
      }
    })
    this.layers = []
    this.mapComponent = null
    this.draw = null
    this.commonLayers = null
    this.preexistingItemsWrapper.releaseMem()
    this.preexistingItemsWrapper = null
  }
}

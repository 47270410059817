'use strict'

export const SNOW = 'snow'
export const DANGERSIGN = 'dangersign'
export const DANGER = 'danger'
export const MEASUREMENT = 'measurement'
export const AVALANCHES = 'avalanches'
export const AVALANCHE = 'avalanche'
export const AVAL = 'aval'
export const ATTACHMENT = 'attachment'

export const LDREGION = 'region'
export const ASSESSMENT_SITUATION = 'situation'
export const ASSESSMENT_SECTOR = 'evaluation'
export const MEASURE = 'measure'
export const OBJECT_MEASURE = 'objectMeasure'
export const ASSESSMENT_DECISION_AREA = 'assessmentDecisionArea'
export const ASSESSMENT_DECISION_SECTOR = 'assessmentDecisionSector'
export const ASSESSMENT_DECISION = 'decision'
export const TRIGGERING = 'triggering'
export const AVALPATH_TO_TRIGGERING = 'avalPathToTriggering'

export const ASSESSMENT_TYPES = [
  DANGER,
  ATTACHMENT,
  ASSESSMENT_SITUATION,
  ASSESSMENT_SECTOR,
  MEASURE,
  ASSESSMENT_DECISION,
  TRIGGERING,
]

export const STUDYPLOTOBSERVATION = 'studyplotobservation'
export const STUDYPLOTOBSERVATION_DANGER = 'danger'
export const STUDYPLOTOBSERVATION_MORNING = 'morning'
export const STUDYPLOTOBSERVATION_SUMMER = 'summer'
export const STUDYPLOTOBSERVATION_MANAGEMENT = 'studyplotManagement'

export const SNOWPROFILE = 'snowprofile'
export const SNOW_PROFILE = SNOWPROFILE
export const SNOWDRIFT = 'snowdrift'
export const DANGERSIGN_AVALANCHE = 'dangersignAvalanche'
export const WHUMPF_CRACKS = 'whumpfCracks'

// Alle möglichen Details, die im DetailModal dargestellt werden können. Zudem entspricht die Sortiereung der Reihenfolge hier
// Reihenfolge nicht verändern!!!
export const DETAIL_COMPONENTS = [
  SNOW,
  DANGERSIGN,
  DANGER,
  MEASUREMENT,
  AVALANCHES,
  AVAL,
  ATTACHMENT,
  STUDYPLOTOBSERVATION,
  SNOWPROFILE,
  ASSESSMENT_DECISION_AREA,
  ASSESSMENT_DECISION_SECTOR,
  ASSESSMENT_SITUATION,
  ASSESSMENT_SECTOR,
  MEASURE,
  TRIGGERING,
]
// Dieser Array definiert die Anzeigereihenfolge im DetailModal und kann beliebig verändert werden
export const DETAIL_COMPONENTS_SORT = [
  SNOW,
  DANGERSIGN,
  DANGER,
  STUDYPLOTOBSERVATION,
  MEASUREMENT,
  ATTACHMENT,
  AVALANCHES,
]
export const SINGLE_AVALANCHE = 'SINGLE_AVALANCHE'
export const SEVERAL_AVALANCHE = 'SEVERAL_AVALANCHE'

export const CODE_PRODUCTVARIANT_OLDSNOW = 'problem.productVariantOldSnow'

// Default I18N observation labels
export const I18N = {
  [SNOW]: 'ui.feedback.snow',
  [DANGERSIGN]: 'ui.feedback.dangersign',
  [DANGER]: 'ui.feedback.danger',
  [AVALANCHE]: 'ui.feedback.avalanche',
  [ATTACHMENT]: 'ui.attachment',
  [STUDYPLOTOBSERVATION]: 'ui.studyplotobservation',
  [STUDYPLOTOBSERVATION_MORNING]: 'ui.feedback.measurement.morning',
  [SNOWPROFILE]: 'ui.snowprofile',
  [ASSESSMENT_SITUATION]: 'assessment.menu.createAssessment',
  [ASSESSMENT_SECTOR]: 'assessment.menu.section',
  [MEASURE]: 'assessment.shortName.action',
  [ASSESSMENT_DECISION_AREA]:
    'assessment.measureAssessment.label.assessmentArea',
  [ASSESSMENT_DECISION_SECTOR]:
    'assessment.measureAssessment.label.assessmentSector',
  [TRIGGERING]: 'assessment.ui.triggering',
}

<template>
  <div class="snowmap-container">
    <div v-for="(height, idx) in heightList" :key="idx" class="d-flex">
      <span class="snowmap-label">{{ height }}m</span>
      <Primevue-Rating
        :stars="snowDepthList.length"
        :modelValue="getRating(idx)"
        @change="setRating(idx, $event.value)"
      >
        <template #onicon>
          <img src="../../assets/icons/snowmap/onblock.png" />
        </template>
        <template #officon>
          <img src="../../assets/icons/snowmap/offblock.png" />
        </template>
      </Primevue-Rating>
    </div>
    <div class="snowmap-unit-container d-flex align-items-center">
      <span
        v-for="depth in snowDepthList"
        :key="depth"
        class="snowmap-unit d-inline-block text-center font-weight-bold"
      >
        {{ depth }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SnowmapComponent',
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<number, number | null>>,
      required: true,
      validator: (value: Record<number, number | null>) =>
        Object.values(value).every((v) => typeof v === 'number' || v === null),
    },
  },
  data() {
    return {
      iconWidth: 36,
      iconHeight: 18,
      heightList: [4000, 3500, 3000, 2500, 2000, 1500, 1000],
      snowDepthList: [0, 5, 10, 20, 30, 40, 50, 60, 70],
    }
  },
  emits: ['update:modelValue'],
  methods: {
    getRating(idx: number): number {
      const height = this.heightList[idx]

      const value =
        typeof this.modelValue[height] === 'number'
          ? (this.modelValue[height] as number)
          : undefined

      const valueIdx =
        value !== undefined ? this.snowDepthList.indexOf(value) : -1
      return valueIdx + 1
    },
    setRating(idx: number, value: number) {
      const copy = Object.assign({}, this.modelValue)
      copy[this.heightList[idx]] = this.snowDepthList[value - 1]

      this.$emit('update:modelValue', copy)
    },
  },
})
</script>

<style scoped>
.snowmap-container {
  min-width: 22rem;
}

:deep(.p-rating) {
  gap: 0.14rem;
}

:deep(.p-rating-item:first-child) {
  padding-right: 0.5rem;
}

.snowmap-label {
  font: bold 0.75rem Helvetica;
  margin: 0.25rem 1.5rem 0.25rem 2.5rem;
}

.snowmap-unit-container {
  padding-left: 8.1rem;
  gap: 0.14rem;
}

.snowmap-unit {
  width: 2.25rem;
  font-size: 0.875rem;
}

@media (max-width: 1200px) {
  .snowmap-label {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .snowmap-unit-container {
    padding-left: 5.6rem;
  }
}

@media (max-width: 576px) {
  .snowmap-label {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .snowmap-unit-container {
    padding-left: 4.6rem;
  }

  :deep(.p-rating-item img) {
    width: 1.5rem;
  }

  .snowmap-unit {
    width: 1.5rem;
  }
}
</style>

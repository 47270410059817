import {
  allAspects,
  Aspect,
  Point,
  WindRoseDimensions,
} from '@/components/eurose/WindEuroseModels'
import { computed, ref, Ref } from 'vue'

interface WindEuroseController {
  dimensions: Ref<WindRoseDimensions>
  labelPositions: Ref<Point[]>
  viewBox: Ref<string>

  getAspectPath(aspectIndex: number): string

  isActiveAspect(aspect: Aspect): boolean
}

export function getAspectRange(start: Aspect, end: Aspect): Aspect[] {
  if (start === end) return [...allAspects]

  const startIdx = allAspects.indexOf(start)
  const endIdx = allAspects.indexOf(end)

  if (endIdx < startIdx) {
    return allAspects.filter((_, i) => i <= endIdx || i >= startIdx)
  } else {
    return allAspects.filter((_, i) => i <= endIdx && i >= startIdx)
  }
}

export function useWindEurose(aspects: Ref<Aspect[]>): WindEuroseController {
  for (const aspect of aspects.value) {
    if (!allAspects.includes(aspect))
      throw new Error(`Invalid aspect ${aspect}`)
  }

  const dimensions = ref<WindRoseDimensions>(new WindRoseDimensions(400))

  const labelPositions = computed(() => calculateLabelPositions())
  const windrosePath = computed(() => calculateWindRosePath())
  const viewBox = computed(
    () => `0 0 ${dimensions.value.size} ${dimensions.value.size}`
  )

  const getAspectPath = (aspectIndex: number) => {
    const left = getLeftPointForAspect(aspectIndex)
    const outer = getOuterPointForAspect(aspectIndex)
    const right = getRightPointForAspect(aspectIndex)
    return `M ${dimensions.value.center},${dimensions.value.center} L ${left.x},${left.y} L ${outer.x},${outer.y} L ${right.x},${right.y} Z`
  }

  const isActiveAspect = (aspect: Aspect): boolean =>
    aspects.value.includes(aspect)

  const centerPointIndex = (aspectId: number): number => aspectId * 2

  function getLeftPointForAspect(aspectId: number): Point {
    const centerPointIdx = centerPointIndex(aspectId)
    const index =
      centerPointIdx === 0 ? windrosePath.value.length - 1 : centerPointIdx - 1
    return windrosePath.value[index]
  }

  function getOuterPointForAspect(aspectId: number): Point {
    return windrosePath.value[centerPointIndex(aspectId)]
  }

  function getRightPointForAspect(aspectId: number): Point {
    return windrosePath.value[
      (centerPointIndex(aspectId) + 1) % windrosePath.value.length
    ]
  }

  function calculateLabelPositions(): Point[] {
    const northRotation = -Math.PI / 2
    const allAspectsIndexRange = Array.from(
      { length: allAspects.length },
      (_, i) => i
    )
    return allAspectsIndexRange.map((i) => {
      const alpha = ((2 * Math.PI) / allAspects.length) * i + northRotation
      return {
        x:
          dimensions.value.center +
          Math.cos(alpha) * dimensions.value.labelDistance,
        y:
          dimensions.value.center +
          Math.sin(alpha) * dimensions.value.labelDistance,
      }
    })
  }

  function calculateWindRosePath(): Point[] {
    const numberOfPoints = 2 * allAspects.length
    const northRotation = -Math.PI / 2
    const allAspectsIndexRange = Array.from(
      { length: numberOfPoints },
      (_, i) => i
    )
    return allAspectsIndexRange.map((i) => {
      // alternate between short and long distance, start with long distance
      const centerDistance =
        i % 2 === 0
          ? dimensions.value.centerDistanceLong
          : dimensions.value.centerDistanceShort

      const alpha = ((2 * Math.PI) / numberOfPoints) * i + northRotation

      return {
        x: dimensions.value.center + Math.cos(alpha) * centerDistance,
        y: dimensions.value.center + Math.sin(alpha) * centerDistance,
      }
    })
  }

  return {
    dimensions,
    labelPositions,
    viewBox,
    getAspectPath,
    isActiveAspect,
  } as WindEuroseController
}

import { Control } from 'ol/control.js'
import Modify from 'ol/interaction/Modify.js'
import Select from 'ol/interaction/Select.js'
import Snap from 'ol/interaction/Snap.js'
import { getModifyInteraction } from '@/scripts/avalancheInputMap/modifyDeleteControl/modifyInteractions'
import { getDeleteInteraction } from '@/scripts/avalancheInputMap/modifyDeleteControl/deleteInteractions'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import { AvalancheMapOl } from '@/components/avalanche/MapOLInterface'
import DrawControl from '@/scripts/avalancheInputMap/DrawControl'
import {
  FA_REGULAR_FA_TRASH_ALT,
  FA_SOLID_FA_EDIT,
} from '@/plugins/fontawesomeConsts'
import { toRaw } from 'vue'
import AvalancheMap from '@/scripts/avalancheInputMap/AvalancheMap'

export default class ModifyDeleteControl extends Control {
  private readonly avalMapComponent: AvalancheMapOl
  private readonly modifyDeleteDiv: HTMLDivElement
  private readonly buttonModify: HTMLButtonElement
  private readonly buttonDelete: HTMLButtonElement
  private readonly snapInteraction: Snap | undefined = undefined
  private modifyInteraction: Modify | undefined = undefined
  private selectInteraction: Select | undefined = undefined
  private modifyClickEvent: ((event: Event | undefined) => void) | undefined =
    undefined
  private deleteClickEvent: ((event: Event) => void) | undefined = undefined
  private drawControl: DrawControl | undefined

  constructor(
    avalMapComponent: AvalancheMapOl,
    faIconRefs: Record<string, HTMLElement>
  ) {
    const modifyDeleteDiv = document.createElement('div')
    modifyDeleteDiv.className = 'modify-delete ol-unselectable ol-control'
    super({ element: modifyDeleteDiv })

    this.avalMapComponent = avalMapComponent
    this.modifyDeleteDiv = modifyDeleteDiv
    this.buttonModify = document.createElement('button')
    this.buttonDelete = document.createElement('button')
    this.initHTMLButtons(faIconRefs)
    this.addModifyClickEvent()
    this.addDeleteClickEvent()
    this.modifyInteraction = getModifyInteraction(this)
    const { snapInteraction, selectInteraction } = getDeleteInteraction(this)
    this.snapInteraction = snapInteraction
    this.selectInteraction = selectInteraction
  }

  public setDrawControl(drawControl: DrawControl): void {
    this.drawControl = drawControl
  }
  public getAvalMapComponent(): AvalancheMapOl {
    return this.avalMapComponent
  }
  public getAvalMapCompDrawnSrc(): VectorSource | undefined {
    const drawnSource = this.avalMapComponent.drawnSource
    return drawnSource != null ? drawnSource : undefined
  }
  public getAvalMapCompDrawnSrcFeatures(): Feature[] {
    const drawnSource = this.avalMapComponent.drawnSource
    return drawnSource?.getFeatures() != null ? drawnSource.getFeatures() : []
  }
  public getAvalMapCompOlMap(): AvalancheMap {
    return toRaw(this.avalMapComponent.map)
  }
  public setDisabled(disabled: boolean): void {
    const modifyDeleteButtonIds = ['modifyButton', 'deleteButton']
    modifyDeleteButtonIds.forEach((id) => {
      const element = document.getElementById(id)
      if (element instanceof HTMLButtonElement) {
        element.disabled = disabled
      }
    })
  }
  public setNotActiveAndRemoveInteractions(): void {
    this.removeAllButtonActivity()
    this.removeAllInteractions()
  }
  public removeAllButtonActivity(): void {
    this.buttonModify.classList.remove('active')
    this.buttonDelete.classList.remove('active')
  }
  public activateModify(): void {
    if (this.modifyClickEvent) {
      this.modifyClickEvent(undefined)
    }
  }
  private initHTMLButtons(faIconRefs: Record<string, HTMLElement>): void {
    this.buttonModify.disabled = true
    this.buttonModify.appendChild(faIconRefs[FA_SOLID_FA_EDIT])
    this.buttonModify.className = 'modify-button'
    this.buttonModify.id = 'modifyButton'

    this.buttonDelete.disabled = true
    this.buttonDelete.appendChild(faIconRefs[FA_REGULAR_FA_TRASH_ALT])
    this.buttonDelete.className = 'delete-button'
    this.buttonDelete.id = 'deleteButton'

    this.modifyDeleteDiv.appendChild(this.buttonModify)
    this.modifyDeleteDiv.appendChild(this.buttonDelete)
  }
  private addModifyClickEvent() {
    if (this.modifyClickEvent) {
      this.buttonModify.removeEventListener(
        'click',
        this.modifyClickEvent,
        false
      )
    }
    this.modifyClickEvent = (event: Event | undefined) => {
      this.drawControl?.setNotActiveAndRemoveInteractions()
      event?.preventDefault()
      this.addModifyInteraction()
      this.buttonModify.classList.add('active')
      this.buttonDelete.classList.remove('active')
    }
    this.buttonModify.addEventListener('click', this.modifyClickEvent, false)
  }
  private addDeleteClickEvent() {
    if (this.deleteClickEvent) {
      this.buttonDelete.removeEventListener(
        'click',
        this.deleteClickEvent,
        false
      )
    }
    this.deleteClickEvent = (event: Event) => {
      this.drawControl?.setNotActiveAndRemoveInteractions()
      event.preventDefault()
      this.addDeleteInteractions()
      this.buttonDelete.classList.add('active')
      this.buttonModify.classList.remove('active')
    }
    this.buttonDelete.addEventListener('click', this.deleteClickEvent, false)
  }
  public addModifyInteraction(): void {
    this.removeAllInteractions()
    if (this.modifyInteraction != null) {
      this.modifyInteraction = getModifyInteraction(this)
      this.getAvalMapCompOlMap().addInteraction(this.modifyInteraction)
    }
  }
  private addDeleteInteractions() {
    this.removeAllInteractions()
    if (this.selectInteraction != null) {
      // Neue Select-Interaktion erstellen, sonst wird noch eine vorher gelöschte angezeigt (Wohl ein OL-Bug)
      this.selectInteraction = getDeleteInteraction(this).selectInteraction
      this.getAvalMapCompOlMap().addInteraction(this.selectInteraction)
    }
    if (this.snapInteraction != null) {
      this.getAvalMapCompOlMap().addInteraction(this.snapInteraction)
    }
  }
  public removeAllInteractions(): void {
    if (this.selectInteraction) {
      this.getAvalMapCompOlMap().removeInteraction(this.selectInteraction)
    }
    if (this.snapInteraction) {
      this.getAvalMapCompOlMap().removeInteraction(this.snapInteraction)
    }
    if (this.modifyInteraction) {
      this.getAvalMapCompOlMap().removeInteraction(this.modifyInteraction)
    }
  }
}

'use strict'
import Style from 'ol/style/Style'
import { SVG } from '../SVG'
import Icon from 'ol/style/Icon'
import {
  AbstractStyle,
  GEFAHREN_INDEX_COLORS,
  GEFAHREN_INDEX_COLORS_STROKE,
  GEFAHREN_INDEX_TEXT_COLORS,
  GEFAHREN_KLASSE,
  Z_INDEX,
} from '../AbstractStyle'
import SnowprofileMouseOver from './SnowprofileMouseOver'

export class SnowprofileStyle extends AbstractStyle {
  snowprofRectangleStyleCache = new Map()

  getCacheKey(feature) {
    const snowProfileFeature = feature.get(SNOWPROFILE)
    return `${snowProfileFeature.fractureCloseToSurface}${snowProfileFeature.dangerClass}${snowProfileFeature.type}`
  }

  styleFunction(feature) {
    const cacheKey = this.getCacheKey(feature)

    if (this.snowprofRectangleStyleCache.has(cacheKey)) {
      return this.snowprofRectangleStyleCache.get(cacheKey)
    }

    const snowprofileCompositeSymb = this.getSnowprofileCompositeSymbol(feature)
    const imageStyle = new Style({
      image: new Icon({
        img: snowprofileCompositeSymb,
      }),
    })
    this.snowprofRectangleStyleCache.set(cacheKey, [imageStyle])
    super.setZindexIfExists(feature.get(SNOWPROFILE), imageStyle)

    return [imageStyle]
  }

  mouseOverFunction(feature) {
    const snowprofileFeature = feature.get(SNOWPROFILE)
    if (snowprofileFeature) {
      let htmlString = super.getMouseOverTitel(feature)
      htmlString += SnowprofileMouseOver.getMouseOverHtml(snowprofileFeature)
      return htmlString
    } else {
      return null
    }
  }

  getSnowprofileCompositeSymbol(feature) {
    const sdsProperties = feature.get(SNOWPROFILE)

    const canvasWidth = sdsProperties.fractureCloseToSurface
      ? RUTSCHBLOCK_RECTANGLE_WIDTH_SMALL
      : RUTSCHBLOCK_RECTANGLE_WIDTH
    const canvasHeight = sdsProperties.fractureCloseToSurface
      ? RUTSCHBLOCK_RECTANGLE_HEIGHT_SMALL
      : RUTSCHBLOCK_RECTANGLE_HEIGHT

    return this.getRutschblockRectangle(
      canvasWidth,
      canvasHeight,
      GEFAHREN_INDEX_COLORS[sdsProperties.dangerClass],
      GEFAHREN_INDEX_COLORS_STROKE[sdsProperties.dangerClass],
      GEFAHREN_INDEX_TEXT_COLORS[sdsProperties.dangerClass],
      sdsProperties.type === 'ECT'
    )
  }

  getRutschblockRectangle(width, height, color, strokeColor, textColor, isEct) {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = color
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.lineWidth = 1
    ctx.strokeStyle = strokeColor
    ctx.strokeRect(0, 0, canvas.width, canvas.height)
    if (isEct) {
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = textColor
      ctx.font = '12px Arial'
      ctx.fillText('ECT', canvas.width / 2, canvas.height / 2 + 1.5)
    }
    return canvas
  }

  getSvgRutschblock(width, height, isEct) {
    let ectText = ''
    if (isEct) {
      ectText =
        '<text x="2" y="10" fill="black" font-size="10" font-weight="bold">ECT</text>'
    }
    const symbol =
      '<g><rect width="' +
      width +
      '" height="' +
      height +
      '" style="fill:white;stroke-width:1;stroke:black" /></g>' +
      ectText
    const svg = new SVG('0 -2 25 15')

    return svg.createImage(symbol)
  }

  getLegendRectangles() {
    const legends = []
    legends[0] = this.getSvgRutschblock(
      RUTSCHBLOCK_SVG_RECTANGLE_WIDTH,
      RUTSCHBLOCK_SVG_RECTANGLE_HEIGHT,
      false
    )
    legends[1] = this.getSvgRutschblock(
      RUTSCHBLOCK_SVG_RECTANGLE_WIDTH_SMALL,
      RUTSCHBLOCK_SVG_RECTANGLE_HEIGHT_SMALL,
      false
    )
    legends[2] = this.getSvgRutschblock(
      RUTSCHBLOCK_SVG_RECTANGLE_WIDTH,
      RUTSCHBLOCK_SVG_RECTANGLE_HEIGHT,
      true
    )

    return legends
  }

  getSnowprofileZindexForGefahrIdx(featureCollection, gefahrIndex) {
    const featuresWithGefahrIndex = []
    if (featureCollection && gefahrIndex >= 0) {
      featureCollection.forEach((snowprofileFeature) => {
        const sdsProperties = snowprofileFeature.properties[SNOWPROFILE]
        if (sdsProperties) {
          if (this.isFeatureOfDangerIndex(sdsProperties, gefahrIndex)) {
            sdsProperties[Z_INDEX] = gefahrIndex
            sdsProperties[GEFAHREN_KLASSE] = gefahrIndex
            featuresWithGefahrIndex.push(snowprofileFeature)
          }
        }
      })
    }
    return featuresWithGefahrIndex
  }

  isFeatureOfDangerIndex(sdsProperties, gefahrIndex) {
    const gefahrIndexToStabilityLevel = ['STRONG', 'MEDIUM', 'WEAK']
    const stabilityLevel = gefahrIndexToStabilityLevel[gefahrIndex]

    return stabilityLevel === sdsProperties.stabilityLevel
  }
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------
export const SNOWPROFILE = 'snowprofile'
const RUTSCHBLOCK_RECTANGLE_WIDTH = 40
const RUTSCHBLOCK_RECTANGLE_HEIGHT = RUTSCHBLOCK_RECTANGLE_WIDTH / 2
const RUTSCHBLOCK_RECTANGLE_WIDTH_SMALL = 30
const RUTSCHBLOCK_RECTANGLE_HEIGHT_SMALL = RUTSCHBLOCK_RECTANGLE_WIDTH_SMALL / 2
const RUTSCHBLOCK_SVG_RECTANGLE_WIDTH = 25
const RUTSCHBLOCK_SVG_RECTANGLE_HEIGHT = RUTSCHBLOCK_SVG_RECTANGLE_WIDTH / 2
const RUTSCHBLOCK_SVG_RECTANGLE_WIDTH_SMALL = 20
const RUTSCHBLOCK_SVG_RECTANGLE_HEIGHT_SMALL =
  RUTSCHBLOCK_SVG_RECTANGLE_WIDTH_SMALL / 2

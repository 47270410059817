import reportService from '@/services/reportService.js'
import { InfoService } from '@/services/InfoService'
import Report from '@/model/report/Report.js'
import { i18n } from '@/plugins/i18n'

const REPORT_DETAIL_TITLE = i18n.global.t('common.reporting.reportDetailTitle')
const REPORT_JOURNAL_TITLE = i18n.global.t(
  'common.reporting.reportJournalTitle'
)

export const commonReportMixin = {
  data() {
    return {
      reportIsLoading: false,
      showWarning: false,
    }
  },
  computed: {},
  methods: {
    startPdfReporting(reportRawData, showDownloadFinish) {
      try {
        reportService
          .createPdfReport(reportRawData)
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Report.pdf'
            link.click()
            this.setPdfReportingInProgress(false)
            this.showWarning = false
            this.showFinishedDownload(showDownloadFinish)
          })
          .catch((err) => {
            this.showPrintError(err)
            this.setPdfReportingInProgress(false)
          })
      } catch (error) {
        this.showPrintError(error)
        this.setPdfReportingInProgress(false)
      }
    },
    getDetailReportBuilder(useTitle) {
      if (useTitle) {
        return new Report(REPORT_DETAIL_TITLE)
      }
      return new Report(undefined)
    },
    getJournalReportBuilder(
      rangeString,
      avalancheServiceName,
      compactPdfPreparation
    ) {
      return new Report(
        REPORT_JOURNAL_TITLE,
        rangeString,
        avalancheServiceName,
        compactPdfPreparation
      )
    },
    preLoadImages(obsEles) {
      const promises = []
      const imgMap = {}
      obsEles.forEach((ele) => {
        this.getDocIds(ele).forEach((id) => {
          imgMap[id] = ele
          promises.push(
            this.$store.dispatch('document/getDocumentMetaWithThumbnail', {
              id: id,
              dontShowError: true,
            })
          )
        })
      })
      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then((resp) => {
            resp.forEach((meta) => {
              if (
                (typeof meta.removeDate === 'undefined' || !meta.removeDate) &&
                meta.mediaType.includes('image')
              ) {
                // Nur die Attachment-Metadaten übernehmen, wenn es ein Bild ist
                const ele = imgMap[meta.id]
                const eleType = ele[ele.type]
                if (!eleType.imgMetas) {
                  eleType.imgMetas = []
                }
                eleType.imgMetas.push(meta)
              }
            })
            resolve(obsEles)
          })
          .catch(function (error) {
            const errorMsg =
              'document metas could not be loaded (' + error + ')'
            console.error(errorMsg)
            reject(errorMsg)
          })
      })
    },
    getDocIds(obsEle) {
      switch (obsEle.type) {
        case 'attachment':
          return obsEle.attachment.docIds
        case 'avalanche':
          return obsEle.avalanche.avalancheDocumentIds
        default:
          return []
      }
    },
    getAreaName(areaId) {
      const area = this.$store.getters['profile/getAreaWithId'](areaId)
      return area ? area.name : ''
    },
    getSectorName(areaId, sectorId) {
      const area = this.$store.getters['profile/getAreaWithId'](areaId)
      const sector = this.$store.getters['profile/getSectorWithId'](
        area,
        sectorId
      )
      return sector ? sector.name : ''
    },
    getObjectName(objectId) {
      const object = this.$store.getters['profile/getObjectToProtect'](objectId)
      return object ? object.name : ''
    },
    getAvalanchePathName(pathId) {
      const path = this.$store.getters['profile/getAvalanchePath'](pathId)
      return path ? path.name : ''
    },
    showPrintError(error) {
      InfoService.handleError(
        { message: error },
        {
          titleKey: 'common.reporting.reportGenerationErrorTitle',
          messageKey: 'common.reporting.reportGenerationErrorMessage',
          messageValues: { errorDetail: error },
          autoHide: true,
        }
      )
    },
    setPdfReportingInProgress(setInProgress) {
      this.$emit('sendReportState', { isWorking: setInProgress })
      this.reportIsLoading = setInProgress
    },
    showFinishedDownload(showDownloadFinish) {
      if (showDownloadFinish) {
        InfoService.showSuccessMessage({
          titleKey: 'common.reporting.reportFinishedTitle',
          messageKey: 'common.reporting.reportFinishedMessage',
          autoHide: false,
        })
      }
    },
  },
}

import { v1 as uuidV1 } from 'uuid'
import moment from 'moment'
import observationService from '@/services/observationService.js'
import util from '@/scripts/util.js'

// state
const state = {
  isFeedbackLoaded: false,
  observations: [],
  lastLocationFromYesterday: undefined,
  date: new Date(),
  location: null, // [46.8, 8.4],
  locationDate: null,
  origin: {
    // for danger and dangersign only, behaviour like location
    selection: null, // (pre-)selected value
    obsdate: null, // observation date (set in header) at time of saving
    cdatetime: null, // creation datetime of this - proposal valid only for 15 minutes
  },
  selected: null,
  mapselectedId: null,
}

// getters
const getters = {
  getCurrentLocationCoordinates(state) {
    return state.location?.coordinates
  },
  isObsForToday(state) {
    if (state.date && state.date !== null) {
      return moment(state.date).isSame(moment(), 'day')
    }
    return false
  },
}

// actions
const actions = {
  loadObservations({ commit, rootGetters }, interval) {
    commit('SET_OBSERVATIONS', [])
    const promises = []
    promises.push(
      observationService.getJournalObservations(
        rootGetters.user.id,
        rootGetters['profile/getAllAvalServiceIds'](),
        interval.upperDate,
        interval.range,
        interval.cancel
      )
    )

    return Promise.all(promises)
      .then((values) => {
        let obs = []
        if (values[0] && values[0].data) {
          obs = values[0].data.data // need all kind of observations
        }
        // sort by date
        // obs.sort((item1, item2) => (item1.date > item2.date) ? -1 : (item1.date === item2.date ? 0 : 1))
        obs.sort((item1, item2) =>
          item1.cTimestamp > item2.cTimestamp
            ? -1
            : item1.cTimestamp === item2.cTimestamp
            ? 0
            : 1
        )
        commit('SET_OBSERVATIONS', obs)
        commit('SET_EXCEPTION', null, { root: true })
        console.log('observations loaded...')
      })
      .catch(function (error) {
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
  submitObservation({ state, dispatch }, observation) {
    if (state.selected) {
      return dispatch('updateObservation', observation)
    } else {
      return dispatch('addObservation', observation)
    }
  },
  addObservation(context, observation) {
    observation.id = uuidV1()
    observation.uid = context.rootGetters.user.id
    observation.date = getObsDate(context, observation)
    if (!observation.location) {
      // if not set w/ studyplot location
      observation.location = context.state.location
    }
    observation.tags = {}
    if (!util.isAssessmentContent(observation, observation.type)) {
      if (context.rootGetters.profile.observerGroup) {
        observation.tags.observerGroup =
          context.rootGetters.profile.observerGroup
      }
      observation.studyplotId =
        context.rootGetters.profile.studyplotIdToObserverGroup
    }
    return new Promise((resolve, reject) => {
      observationService
        .addObservation(observation)
        .then(function () {
          console.log('observation added')
          context.commit('ADD_OBSERVATION', observation)
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            if (error.response && error.response.status === 409) {
              context.commit(
                'SET_EXCEPTION',
                'Eine Meldung für diesen Tag ist bereits vorhanden!',
                { root: true }
              )
            } else {
              context.commit('SET_EXCEPTION', error.message, { root: true })
            }
          }
          reject(error)
        })
    })
  },
  updateObservation({ state, commit }, observation) {
    observation.id = state.selected.id
    observation.date = moment(state.date).format('YYYY-MM-DD')
    observation.uid = state.selected.uid
    if (!observation.location) {
      // if not set w/ studyplot location
      observation.location = state.location
    }
    observation.version = state.selected.version
    observation.tags = state.selected.tags
    observation.studyplotId = state.selected.studyplotId
    observation.creatorSystem = state.selected.creatorSystem
    return new Promise((resolve, reject) => {
      observationService
        .updateObservation(observation)
        .then(function () {
          console.log('observation updated')
          commit('UPDATE_OBSERVATION', observation)
          commit('SET_EXCEPTION', null, { root: true })
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
  getLastLocationFromYesterday({ state, commit, rootGetters }) {
    if (state.lastLocationFromYesterday) {
      return state.lastLocationFromYesterday
    }
    commit('SET_LAST_LOCATION_YESTERDAY', [])
    const upperDate = moment().subtract(1, 'days')
    upperDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    return new Promise((resolve, reject) => {
      observationService
        .getObservationsByUserAndDateRange(rootGetters.user.id, upperDate, 1)
        .then(function (response) {
          let obs = []
          if (response && response.data) {
            obs = response.data.data
          }
          // sort by date
          obs.sort((item1, item2) =>
            item1.cTimestamp > item2.cTimestamp
              ? -1
              : item1.cTimestamp === item2.cTimestamp
              ? 0
              : 1
          )
          if (obs && obs.length > 0) {
            commit('SET_LAST_LOCATION_YESTERDAY', obs[0].location)
          }
          commit('SET_EXCEPTION', null, { root: true })
          console.log('last observations loaded...')
          resolve()
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_IS_FEEDBACK_LOADED(state, isFeedbackLoaded) {
    state.isFeedbackLoaded = isFeedbackLoaded
  },
  SET_LAST_LOCATION_YESTERDAY(state, location) {
    state.lastLocationFromYesterday = location
  },
  SET_OBSERVATIONS(state, observations) {
    state.observations = observations
  },
  ADD_OBSERVATION(state, observation) {
    state.observations.push(observation)
  },
  UPDATE_OBSERVATION(state, observation) {
    for (let i = 0; i < state.observations.length; i++) {
      if (state.observations[i].uid === observation.uid) {
        state.observations[i] = observation
        break
      }
    }
  },
  SET_DATE(state, date) {
    state.date = date
  },
  SET_LOCATION(state, location) {
    state.location = location
  },
  SET_LOCATION_DATE(state, locationDate) {
    state.locationDate = locationDate
  },
  SET_ORIGIN(state, origin) {
    state.origin = origin
  },
  SET_SELECTED(state, selected) {
    state.selected = selected
  },
  SET_MAPSELECTED_ID(state, selected) {
    state.mapselectedId = selected
  },
  SELECT(state, obs) {
    state.selected = obs
    state.date = obs.date
  },
}

// Helpers
function getObsDate(context, observation) {
  let obsDate
  if (util.isAssessmentContent(observation, observation.type)) {
    obsDate = moment()
  } else {
    obsDate = moment(context.state.date)
  }
  return obsDate.format('YYYY-MM-DD')
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

'use strict'

import situationDef from '@/reportDefinitions/assessment/situation.json'
import attachmentDef from '@/reportDefinitions/obsAndAssessment/attachment.json'
import dangerDef from '@/reportDefinitions/obsAndAssessment/danger.json'
import evaluationDef from '@/reportDefinitions/assessment/evaluation.json'
import decisionDef from '@/reportDefinitions/assessment/decision.json'
import measureDef from '@/reportDefinitions/assessment/measure.json'
import triggeringDef from '@/reportDefinitions/assessment/triggering.json'
import dangersignDef from '@/reportDefinitions/observations/dangersign.json'
import snowDef from '@/reportDefinitions/observations/snow.json'
import avalancheMainDef from '@/reportDefinitions/observations/singleAvalanche/avalancheMain.json'
import avalanchePosDef from '@/reportDefinitions/observations/singleAvalanche/avalanchePosition.json'
import avalancheDetailDef from '@/reportDefinitions/observations/singleAvalanche/avalancheExtendedDetails.json'
import avalanchePersonDamage from '@/reportDefinitions/observations/singleAvalanche/avalanchePersonDamage.json'
import avalSeveralMainDef from '@/reportDefinitions/observations/severalAvalanche/avalancheMain.json'
import avalSeveralPosDef from '@/reportDefinitions/observations/severalAvalanche/avalanchePosition.json'
import measurementMorningDef from '@/reportDefinitions/measurement/morning.json'
import studyplotObservationDef from '@/reportDefinitions/measurement/studyplotObservation.json'
import { SINGLE_AVALANCHE } from '@/scripts/const.js'

export const REPORT_VALUE_TYPE_STRING = 'string'
export const REPORT_VALUE_TYPE_SINGLE_ENUM = 'singleEnum'
export const REPORT_VALUE_TYPE_MULTIPLE_ENUM = 'multipleEnum'
export const REPORT_VALUE_TYPE_RANGE_ARRAY = 'rangeArray'
export const REPORT_VALUE_TYPE_COMMON_DATE_TIME = 'commonDateTime'
export const REPORT_VALUE_TYPE_DATE_ONLY = 'dateOnly'
export const REPORT_VALUE_TYPE_TIME_ONLY = 'timeOnly'
export const REPORT_VALUE_TYPE_DURATION = 'duration'
export const REPORT_VALUE_TYPE_DANGER_ASPECT_ROSETTE = 'dangerAspectRosette'
export const REPORT_VALUE_TYPE_WHUMPFCRACKS_ROSETTE = 'whumpfCracksRosette'
export const REPORT_VALUE_TYPE_WHUMPFCRACKS_RADAR = 'whumpfCracksRadar'
export const REPORT_VALUE_TYPE_SNOWHEIGHT_CHART = 'snowHeightChart'
export const REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_ONE =
  'avalancheStartZoneCountsOne'
export const REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_TWO =
  'avalancheStartZoneCountsTwo'
export const REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_THREE =
  'avalancheStartZoneCountsThree'

export default class ReportDefinitionreader {
  /* 
    Mappt die richtige Reportdefinition zum Beobachtungstyp
  */
  /*eslint-disable complexity*/
  static getReportDefinition(obsType, obsEle) {
    const errorMsg =
      'Keine zugehörige Reportdefinition für ' + obsType + ' gefunden'
    switch (obsType) {
      case 'situation':
        return [situationDef]
      case 'avalanche':
        return ReportDefinitionreader.getAvalancheReportDefinition(obsEle)
      case 'attachment':
        return [attachmentDef]
      case 'danger':
        return [dangerDef]
      case 'evaluation':
        return [evaluationDef]
      case 'decision':
        return [decisionDef]
      case 'measure':
        return [measureDef]
      case 'triggering':
        return [triggeringDef]
      case 'dangersign':
        return [dangersignDef]
      case 'snow':
        return [snowDef]
      case 'morning':
        obsEle.morning = obsEle.measurement
        return [measurementMorningDef]
      case 'studyplotobservation':
        obsEle.studyplotobservation = obsEle.snow
        obsEle.studyplotobservation.avalRelease = obsEle.dangersign.avalRelease
        return [studyplotObservationDef]
      default:
        console.error(errorMsg)
        throw errorMsg
    }
  }
  /*eslint-enable complexity*/

  static getAvalancheReportDefinition(aval) {
    if (aval.avalancheClass === SINGLE_AVALANCHE) {
      return [
        avalancheMainDef,
        avalanchePosDef,
        avalancheDetailDef,
        avalanchePersonDamage,
      ]
    }
    return [avalSeveralMainDef, avalSeveralPosDef]
  }
}

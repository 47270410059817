import { commonReportMixin } from '@/components/reporting/CommonReportMixin.js'
import { avalReportObsBuilderMixin } from '@/components/reporting/AvalReportObsBuilderMixin.js'
import { commonReportObsBuilderMixin } from '@/components/reporting/CommonReportObsBuilderMixin.js'

export const detailModalReportMixin = {
  mixins: [
    commonReportMixin,
    avalReportObsBuilderMixin,
    commonReportObsBuilderMixin,
  ],
  computed: {},
  methods: {
    getReportObs(obs, aval) {
      if (obs.length > 0) {
        return new Promise((resolve) => {
          const mixedObsAvalPromises = []
          obs.forEach((ele) => {
            mixedObsAvalPromises.push(...this.getAvalOrObsReport(ele))
          })
          Promise.all(mixedObsAvalPromises).then((results) => {
            resolve(results)
          })
        })
      } else if (aval && aval.avalancheClass) {
        return this.getAvalReportObs(aval, aval.avalancheClass)
      } else {
        const errorMsg =
          'Aufbereitung der Report-Objekte nicht möglich. Es ist weder "obs" noch "aval" gesetzt!'
        console.error(errorMsg)
        throw errorMsg
      }
    },
    getAvalOrObsReport(ele) {
      const obspromises = []
      if (!Array.isArray(ele)) {
        const obspromise = this.getCommonObservationPromise(ele)
        obspromise ? obspromises.push(obspromise) : undefined
      } else {
        obspromises.push(...this.getAvalReportObsFromObsList(ele))
      }
      return obspromises
    },
    getHeaderName() {
      return this.headerInfos.name
    },
  },
}

/* eslint-disable */
'use strict';

import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import { AbstractStyle } from '../AbstractStyle'
import {i18n} from '@/plugins/i18n'
import { SVG } from '../SVG'
import moment from 'moment'
import bulletinUtil, { BULLETIN_TYPE_MAP, BULLETIN_TYPE_TXT } from '../../../scripts/bulletin/bulletinUtil'

export class BulletinStyle extends AbstractStyle {
  constructor (locale) {
    super(locale)
    moment.locale(locale)
  }

  setVariant (variantValue) {
    variant = variantValue
  }

  dangerStyleFunction(feature, resolution) {
    // Es ergibt sich die gleiche Farbe für getColor(...) mit rating=1 oder rating=2
    const color = getColor(1, feature, resolution)
    const isHovered = feature.get('isHovered')
    const style = new Style({
      stroke: new Stroke({
        color: 'black',
        width: isHovered ? 5 : 1
      }),
      fill: new Fill({
        color: color
      })
    })

    style.setZIndex(isHovered ? 100 : 10)
    return [ style ];
  }

  dangerTextStyleFunction(feature, resolution) {
    let color = getColor(3, feature, resolution)
    const isHovered = feature.get('isHovered')

    let style = new Style({
      stroke: new Stroke({
        color: 'black',
        width: isHovered ? 5 : 1
      }),
      fill: new Fill({
        // NOTE: feature prop 'fill' only for quick visualisation, not for production level integration => use official codes!!
        color: color
      })
    })
    style.setZIndex(isHovered ? 100 : 10)
    return [ style ];
  }

  problem1StyleFunction(feature, resolution) {
    let color = getProblemColor(1, feature, resolution)
    const isHovered = feature.get('isHovered')

    let style = new Style({
      stroke: new Stroke({
        color: 'black',
        width: isHovered ? 5 : 1
      }),
      fill: new Fill({
        color: color
      })
    })
    style.setZIndex(isHovered ? 100 : 10)
    return [ style ]
  }
  problem2StyleFunction(feature, resolution) {
    let color = getProblemColor(2, feature, resolution)
    const isHovered = feature.get('isHovered')

    let style = new Style({
      stroke: new Stroke({
        color: 'black',
        width: isHovered ? 5 : 1
      }),
      fill: new Fill({
        color: color
      })
    })
    style.setZIndex(isHovered ? 100 : 10)
    return [ style ]
  }
  problemTextStyleFunction(feature, resolution) {
    let color = getProblemColor(3, feature, resolution)
    const isHovered = feature.get('isHovered')

    let style = new Style({
      stroke: new Stroke({
        color: 'black',
        width: isHovered ? 5 : 1
      }),
      fill: new Fill({
        color: color
      })
    })
    style.setZIndex(isHovered ? 100 : 10)
    return [ style ];
  }

  legend() {
    // NOTE (20200714/csz): w/o level detail for legend
    return {
      title: 'bulletin.label.shortBulletin',
      items: [
        { label: 'danger.legend.1', symbol: getLegendSymbol({ level: '1' }) },
        { label: 'danger.legend.2', symbol: getLegendSymbol({ level: '2' }) },
        { label: 'danger.legend.3', symbol: getLegendSymbol({ level: '3' }) },
        { label: 'danger.legend.4', symbol: getLegendSymbol({ level: '4' }) },
        { label: 'danger.legend.5', symbol: getLegendSymbol({ level: '5' }) },
        { label: 'danger.legend.unknown', symbol: getLegendSymbol({ level: 'NONE' }) }
      ]
    }
  }

  mouseOverFunction (feature) {
    feature.set('isHovered', true)
    feature.setStyle(undefined)

    let bulletinType = feature.get('bulletin_type')
    let validFromDatetime = feature.get('valid_from')
    let validFromText = (validFromDatetime ? moment(validFromDatetime).format("DD.MM.YYYY, HH:mm") : null)

    let dangerLevelText = null
    if (bulletinType === BULLETIN_TYPE_MAP) {
      let dangerLevelProp = (feature.get('rating') ? feature.get('rating')['dangerlevel']['level'] : null)
      let dangerLevelDetailProp = (feature.get('rating') ? feature.get('rating').dangerlevel.level_detail : null)
      let sepIndex = (dangerLevelProp ? dangerLevelProp.indexOf('_') : -1)
      let dangerLevel = (dangerLevelProp && sepIndex > 0 ? dangerLevelProp.substring(0, sepIndex) : null)
      let dangerLevelDetailText = bulletinUtil.getDangerLevelDetailText(dangerLevelDetailProp)
      dangerLevelText = dangerLevel ? enrich(i18n.global.t('danger.level.' + dangerLevel), dangerLevelDetailText) : i18n.global.t('bulletin.dangerLevel.noLevel')
    } else {
      if (bulletinType === BULLETIN_TYPE_TXT) {
        dangerLevelText = i18n.global.t('bulletin.label.textBulletin')
      }
    }
    let validityLevel = '<div>' + (validFromText ? validFromText + ': ' : '') + (dangerLevelText ? dangerLevelText : '') + '</div>'
    if (!validityLevel) {
      return ''
    }
    return validityLevel
  }
}

let getLegendSymbol = function (danger) {
  let style = STYLE_ELEMENTS[danger.level] || STYLE_ELEMENTS.UNKNOWN
  const symbol = '<g style="fill:' + style.fill + '; stroke:' + style.stroke + ';">' + SQUARE_SVG + '</g>'
  let svg = new SVG('0 0 25 15')
  return svg.createImage(symbol)
}

let getColor = function (rating, feature, resolution) {
  let color

  switch (rating) {
    case 1:
    case 2:
      let dangerLevelProp = feature.get('rating') ? feature.get('rating').dangerlevel.level : null
      let sepIndex = (dangerLevelProp ? dangerLevelProp.indexOf('_') : -1)
      let dangerLevel = (dangerLevelProp && sepIndex > 0 ? dangerLevelProp.substring(0, sepIndex) : null)

      color = (dangerLevel ? MAP_LEVEL_COLORS[dangerLevel] : MAP_LEVEL_COLORS.NONE)
      break
    case 3:
      color = MAP_LEVEL_COLORS.TXT
      break
    default:
      color = MAP_LEVEL_COLORS.NONE
  }

  if (resolution > 0) {
    return color.replace('0.1)', (Math.min(Math.round(Math.pow(resolution, 1/4) /5 * 100), 100) / 100) + ')')
  } else {
    return color
  }
}

let getProblemColor = function (rating, feature, resolution) {
  let color = MAP_PROBLEM_COLORS.NONE

  switch (rating) {
    case 1:
    case 2:
      let majorProblems = feature.get('rating') ? feature.get('rating').majorAvalProblems : []
      let minorProblems = feature.get('rating') ? feature.get('rating').additionalAvalProblems : []
      let allProblems = (majorProblems !== null && minorProblems !== null ? majorProblems.concat(minorProblems) : (majorProblems !== null ? majorProblems : minorProblems))

      if (allProblems && allProblems.length > 0) {
        switch (variant) {
          case 'problem.productVariantNewSnow':
            if (allProblems.includes('NEW_SNOW')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
          case 'problem.productVariantSnowDrift':
            if (allProblems.includes('SNOW_DRIFT')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
          case 'problem.productVariantOldSnow':
            if (allProblems.includes('OLD_SNOW')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
          case 'problem.productVariantUndefined':
            if (allProblems.includes('NO_DISTINCT_PATTERN')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
          case 'problem.productVariantWetSnow':
            if (allProblems.includes('WET_SNOW_AVALANCHE_DAYCOURSE') || allProblems.includes('WET_SNOW_AVALANCHE')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
          case 'problem.productVariantFullDepth':
            if (allProblems.includes('GROUND_SLAB_AVALANCHE')) {
              color = MAP_PROBLEM_COLORS['YES']
            } else {
              color = MAP_PROBLEM_COLORS['NO']
            }
            break
        }
      }
      break
    case 3: color = MAP_PROBLEM_COLORS.NONE
      break
    default: color = MAP_PROBLEM_COLORS.NONE
  }

  if (resolution > 0) {
    return color.replace('0.1)', (Math.min(Math.round(Math.pow(resolution, 1/4) /7 * 100), 100) / 100) + ')')
  } else {
    return color
  }
}

let enrich = function(levelText, levelDetail) {
  // levelDetail should serve as suffix for numerical part of levelText
  let pos = levelText.indexOf(' ')
  return levelText.slice(0, pos) + (levelDetail ? levelDetail : '') + levelText.slice(pos)
}

// ----------------------------------------------------------------------------------------------------
//  METADATA
// ----------------------------------------------------------------------------------------------------

const SQUARE_SVG = '<rect width="25" height="15" style="stroke-width:1 stroke:black"/>'

// NOTE: style for map only - w/ an opacity to be replaced based on resolution
const MAP_LEVEL_COLORS = {
  // Colors as HSLA-Values
  '1': 'hsla(80, 100%, 70%, 0.1)',
  '1+': 'hsla(80, 100%, 70%, 0.1)',
  '2-': 'hsla(60, 100%, 50%, 0.1)',
  '2': 'hsla(60, 100%, 50%, 0.1)',
  '2+': 'hsla(60, 100%, 50%, 0.1)',
  '3-': 'hsla(36, 100%, 50%, 0.1)',
  '3': 'hsla(36, 100%, 50%, 0.1)',
  '3+': 'hsla(36, 100%, 50%, 0.1)',
  '4-': 'hsla(0, 100%, 50%, 0.1)',
  '4': 'hsla(0, 100%, 50%, 0.1)',
  '4+': 'hsla(0, 100%, 50%, 0.1)',
  '5-': 'hsla(0, 100%, 20%, 0.1)',
  '5': 'hsla(0, 100%, 20%, 0.1)',
  'TXT': 'hsla(240, 100%, 80%, 0.1)',
  'NONE': 'hsla(0, 0%, 100%, 0.1)',
}

const MAP_PROBLEM_COLORS =
  {
    'NO': 'rgba(242, 216, 76, 0.1)',
    'YES': 'rgba(49, 0, 87, 0.1)',
    'NONE': 'rgba(255, 255, 255, 0.1)'
  }

// NOTE: style for legend only
const STYLE_ELEMENTS = {
  '1': {'fill': '#CCFF66', 'stroke': '#CCFF66', 'stroke-width': '2px'},
  '2': {'fill': '#FFFF00', 'stroke': '#FFFF00', 'stroke-width': '2px'},
  '3': {'fill': '#FF9900', 'stroke': '#FF9900', 'stroke-width': '2px'},
  '4': {'fill': '#FF0000', 'stroke': '#FF0000', 'stroke-width': '2px'},
  '5': {'fill': '#640000', 'stroke': '#640000', 'stroke-width': '2px'},
  'NONE': {'fill': '#FFFFFF', 'stroke': '#999999', 'stroke-width': '2px'}
}

let variant = null

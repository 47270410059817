'use strict'

import Point from 'ol/geom/Point'
import MapProjection from '@/scripts/MapProjection.js'
import moment from 'moment'
import { enums } from '@/components/I18N'
import store from '../store/index'
import { MEASURE } from '@/scripts/const.js'
import { DETAIL_COMPONENTS } from './const'
import { Projection } from 'ol/proj'

const CREATOR_SYSTEM_REPLY_PUBLIC = enums.creatorSystem[1]
const ORIGIN_MAX_MINUTES = 15

const PROJ_EPSG_4326 = new Projection({ code: 'EPSG:4326' })
const PROJ_EPSG_2056 = new Projection({ code: 'EPSG:2056' })

const funcs = {
  convertWGS84ToSwiss(wgs84Location) {
    if (wgs84Location && wgs84Location.type === 'Point') {
      if (wgs84Location.coordinates && wgs84Location.coordinates.length === 2) {
        const geoPoint = new Point([
          wgs84Location.coordinates[0],
          wgs84Location.coordinates[1],
        ]) // i.e. WGS84
        const geoCopy = geoPoint.clone()
        return geoCopy.transform('EPSG:4326', 'EPSG:21781').getCoordinates()
      }
    }
    return null
  },
  convertPointFromWGS84ToNewSwissCoordinates(wgs84PointCoordinates) {
    if (
      wgs84PointCoordinates &&
      Array.isArray(wgs84PointCoordinates) &&
      wgs84PointCoordinates.length === 2
    ) {
      const geoPoint = new Point([
        wgs84PointCoordinates[0],
        wgs84PointCoordinates[1],
      ])
      const transformed = geoPoint
        .transform(PROJ_EPSG_4326, PROJ_EPSG_2056)
        .getCoordinates()
      return transformed != null ? transformed : []
    }
    return []
  },
  convertGeoJsonToText(geometryJson) {
    if (geometryJson && geometryJson.type === 'Point') {
      if (geometryJson.coordinates && geometryJson.coordinates.length === 2) {
        // transform from WGS84 to CH1903+/LV95 for display purposes
        const geoPoint = new Point([
          geometryJson.coordinates[0],
          geometryJson.coordinates[1],
        ]) // i.e. WGS84
        const geoCopy = geoPoint.clone()
        const transformedCoord = geoCopy
          .transform('EPSG:4326', 'EPSG:2056')
          .getCoordinates()
        return (
          transformedCoord[0].toFixed(2).replace(/\.?0+$/, '') +
          '/' +
          transformedCoord[1].toFixed(2).replace(/\.?0+$/, '')
        )
      }
    }
    return null
  },
  isWGS84PointInCircle(centerWGS84, radiusInMeters, pointWGS84) {
    const centerSwissCoordinates = this.convertWGS84ToSwiss(centerWGS84)
    const pointSwissCoordinates = this.convertWGS84ToSwiss(pointWGS84)
    if (centerSwissCoordinates && pointSwissCoordinates) {
      return isPointInCircle(
        centerSwissCoordinates[0],
        centerSwissCoordinates[1],
        radiusInMeters,
        pointSwissCoordinates[0],
        pointSwissCoordinates[1]
      )
    }
  },
  isSwissPointInCircle(centerWGS84, radiusInMeters, pointSwiss) {
    const centerSwissCoordinates = this.convertWGS84ToSwiss(centerWGS84)
    if (centerSwissCoordinates && pointSwiss) {
      return isPointInCircle(
        centerSwissCoordinates[0],
        centerSwissCoordinates[1],
        radiusInMeters,
        pointSwiss[0],
        pointSwiss[1]
      )
    }
  },
  isPoint(geometry) {
    // NOTE: WGS84 coordinates expected - no verification here
    return geometry && geometry.type === 'Point' && geometry.coordinates
  },
  // Used in Dangersign/Input and Danger/Input
  // checks whether origin has already been set for selected date
  existValidOrigin(origin, storeObsdate) {
    return (
      origin &&
      origin.selection &&
      origin.obsdate === storeObsdate &&
      moment().diff(moment(origin.cdatetime), 'minutes') <= ORIGIN_MAX_MINUTES
    )
  },
  existValidHierarchy(hierarchy, storeDate) {
    // at least area selected
    return (
      hierarchy &&
      hierarchy.selection &&
      hierarchy.selection.areaId &&
      hierarchy.date &&
      moment(hierarchy.date).isSame(storeDate, 'day')
    )
  },
  getConsolidatedHierarchySelection(
    hierarchySelection,
    storeHierarchySelection,
    areaOnly
  ) {
    // function for area/sector forms
    if (hierarchySelection) {
      if (storeHierarchySelection) {
        if (hierarchySelection.areaId !== storeHierarchySelection.areaId) {
          if (areaOnly) {
            hierarchySelection.sectorId = null
          }
        }
      }
      return hierarchySelection
    } else {
      return storeHierarchySelection // should not be the case
    }
  },
  /*eslint-disable complexity*/
  isAssessmentContent(subContent, property) {
    // decide whether normal observation or assessment - NOTE: might change later
    if (
      subContent &&
      subContent[property] &&
      DETAIL_COMPONENTS.includes(property) &&
      (subContent[property].avalServiceId ||
        (subContent.hierarchy && subContent.hierarchy.avalServiceId))
    ) {
      return true
    }
    return false
  },
  /*eslint-enable complexity*/
  colorLuminance(hex, lum) {
    // hex — a hex color value such as “#abc” or “#123456” (the hash is optional)
    // lum — the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.

    // validate hex string
    // eslint-disable-next-line no-param-reassign
    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
      // eslint-disable-next-line no-param-reassign
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    // eslint-disable-next-line no-param-reassign
    lum = lum || 0
    // convert to decimal and change luminosity
    let rgb = '#'
    let c
    let i
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16)
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
      rgb += ('00' + c).substr(c.length)
    }
    return rgb
  },
  frequencyColor(isBorder, frequency, colors) {
    let resultColors = {
      color: colors[1],
      borderFaktor: 0.25,
    }
    if (frequency !== null || enums.dangersign.frequency[0]) {
      resultColors =
        frequency === enums.dangersign.frequency[1]
          ? resultColors
          : { color: colors[2], borderFaktor: 0.55 }
    }
    if (isBorder) {
      return funcs.colorLuminance(resultColors.color, resultColors.borderFaktor)
    }
    return resultColors.color
  },
  convertHexToRgbA(hex, alpha) {
    // precondition: hex w/ 3 or 6chars
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')
      // eslint-disable-next-line no-param-reassign
      alpha = alpha || 1
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        alpha +
        ')'
      )
    }
    return 'rgba(0,0,0,1)'
  },
  mapObservationsToFeatures(type, records) {
    const mapProjection = new MapProjection()
    const featureArray = []
    let feature
    records.forEach((record) => {
      // prepare geometry
      let geom = null
      if (record.location) {
        const geometry = new Point(record.location.coordinates) // EPSG:4326
        if (geometry) {
          geometry.transform('EPSG:4326', mapProjection.getOldProjection())
          geom = {
            type: geometry.getType(),
            coordinates: geometry.getCoordinates(),
          }
        }
      }
      const user = store.getters['user/getUserToId'](
        record.userId,
        CREATOR_SYSTEM_REPLY_PUBLIC
      )
      feature = {
        type: 'Feature',
        geometry: geom,
      }
      feature = this.createFeature(feature, type, record, user)
      featureArray.push(feature)
    })
    return featureArray
  },
  createFeature(feature, type, record, user) {
    let properties
    if (type === MEASURE) {
      properties = {
        id: record.id,
        name: record.name,
        timestamp: record.ts,
        cTimestamp: record.ts,
        [type]: record[type],
        type: record.type,
        observer: user ? user.firstName + ' ' + user.lastName : null,
        uid: record.uid,
        hierarchy: record.hierarchy,
        creatorSystem: record.creatorSystem,
      }
    } else {
      properties = {
        id: record.id,
        network: 'OBSERVER',
        timestamp: record.cTimestamp / 1000,
        cTimestamp: record.cTimestamp / 1000,
        [type]: record[type],
        observer: user ? user.firstName + ' ' + user.lastName : null,
        uid: record.uid,
        oDate: record.date,
        hierarchy: record.hierarchy,
        creatorSystem: record.creatorSystem,
      }
    }
    properties = this.setHierarchy(properties, type, record)
    feature.properties = properties
    return feature
  },
  setHierarchy(properties, type, record) {
    // Für Kompatibilität die hierarchy auch noch an den alten Ort setzen
    if (record.hierarchy && record.hierarchy.avalServiceId) {
      properties[type].hierarchy = record.hierarchy
    }
    return properties
  },
  isDateSupported() {
    const input = document.createElement('input')
    const value = 'a'
    input.setAttribute('type', 'date')
    input.setAttribute('value', value)
    return input.value !== value
  },

  /**
   * Generate the acronym from a name
   * @param name
   */
  acronymize: (name) =>
    name
      .replace(/-/gi, ' ')
      .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
      .replace(/ +/gi, ' ') // replace multiple spaces to one
      .split(/ /) // break the name into parts
      .reduce((acc, item) => acc + item[0] + '.', '') // assemble an abbreviation from the parts
      .toUpperCase(),

  /**
   * Add the station name and the timestamp to the feature properties
   */
  addSLFStationNameAndTimeStamp(featureColl) {
    if (!featureColl || featureColl.features.size <= 0) return

    const localeDateTime = moment(store.state.calendar.moment)

    featureColl.features.forEach((feat) => {
      let time
      // calculate the measerment time from the delay info, for each feature as the 'timestamp property'
      if (feat.properties.delay !== undefined) {
        time = moment(localeDateTime)
        feat.properties.timestamp = time
          .subtract(feat.properties.delay, 'minutes')
          .unix()
      }
    })
  },
}
export default funcs

function isPointInCircle(
  centerXInMeters,
  centerYInMeters,
  radiusInMeters,
  pointXInMeters,
  pointYInMeters
) {
  const radiusOfPoint = Math.sqrt(
    Math.pow(pointXInMeters - centerXInMeters, 2) +
      Math.pow(pointYInMeters - centerYInMeters, 2)
  )

  return radiusOfPoint <= radiusInMeters
}

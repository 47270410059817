import axios from 'axios'
import BaseService from '@/services/BaseService'
import { asV2Response } from '@/scripts/bulletin/v3response2simpleV2response'

const CancelToken = axios.CancelToken

export default class bulletinService {
  static srv = undefined
  static getInstance() {
    if (this.srv) {
      return this.srv.getInstance()
    }

    this.srv = new BaseService('BULLETIN_SERVICE_URL_V3', 5000)
    return this.srv.getInstance()
  }

  /**
   * Loads latest active bulletin at specified date and time.
   *
   * Latest active bulletin delivered w/ geometry information for immediate display.
   *
   * @param {string}   timestamp     date and time for which bulletin to be found
   * @param {integer}  rating        1 respectively 2, for first or second dangerMap wanted: default: 1
   * @param {boolean}  flatten       structure to be flattened: default: true
   * @param {object}   cancel        cancel object
   *
   * @return {Promise} Return active bulletin - if any - at the specified date and time (otherwise status 404)
   */
  static loadLatestActiveAtGeoJson(timestamp, rating, flatten, cancel) {
    if (!this.srv) {
      this.getInstance()
    }

    return this.loadGeojsonActiveAtViaV3(timestamp, rating, cancel)
  }

  /**
   * Loads latest active bulletin at specified date and time.
   *
   * Latest active bulletin delivered w/ geometry information for immediate display.
   *
   * @param {string}   timestamp     date and time for which bulletin to be found
   * @param {integer}  rating        1 respectively 2, for first or second dangerMap wanted: default: 1
   * @param {object}   cancel        cancel object
   *
   * @return {Promise} Return active bulletin - if any - at the specified date and time (otherwise status 404)
   */
  static loadGeojsonActiveAtViaV3(timestamp, rating, cancel) {
    return this.getInstance()
      .get('/de/geojson?activeAt=' + encodeURIComponent(timestamp), {
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel.cancelGeo = c
          }
        }),
      })
      .then(function (response) {
        return asV2Response(response, rating, timestamp)
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.info("Bulletin request 'loadLatestActiveAtGeoJson' canceled")
        } else {
          throw thrown
        }
      })
  }
}

'use strict'

import moment from 'moment'
import { i18n } from '@/plugins/i18n'

export default class Measure {
  constructor(id, type, begin, end, measure, reason) {
    this.id = id
    this.type = type
    this.begin = begin
    this.end = end
    this.measure = measure // ('NO_MEASURE’, 'CLOSURE', 'CLOSURE_WINTER', 'LOCKDOWN’, 'EVACUATION’, 'WARNING_LIGHT_ON’, ‘WARNING_LIGHT_OFF’)
    this.reason = reason
  }
  formatBegin() {
    return moment(this.begin).format('DD.MM.YYYY HH:mm')
  }
  formatEnd() {
    return this.end
      ? moment(this.end).format('DD.MM.YYYY HH:mm')
      : i18n.global.t('measure.label.untilFurtherNotice')
  }
  formatMeasure() {
    return i18n.global.t(`measure.${this.type}.${this.measure}`)
  }
}

<template>
  <Menu :moment="moment" :routes="routes"></Menu>
</template>

<script>
import Menu from '../Menu'
import DangersignAvalanche from './DangersignAvalanche'
import Snowdrift from './Snowdrift'
import WhumpfCracks from './WhumpfCracks'

export const Routes = [
  {
    name: 'dangersign.label.dangersignAvals',
    path: '/dangersign/dangersignAvalanche',
    component: DangersignAvalanche,
    meta: {
      map: true,
    },
    class: 'fa-solid fa-exclamation-triangle',
  },
  {
    name: 'dangersign.label.whumpfCracksShort',
    path: '/dangersign/whumpfCracks',
    component: WhumpfCracks,
    meta: {
      map: true,
    },
    class: 'fa-solid fa-signature',
  },
  {
    name: 'dangersign.label.snowdrift',
    path: '/dangersign/snowdrift',
    component: Snowdrift,
    meta: { map: true },
    class: 'fa-solid fa-wind',
  },
]

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dangersign',
  props: ['moment'],
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Menu },
  data() {
    return {
      routes: Routes,
    }
  },
}
</script>

<style scoped></style>

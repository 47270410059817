export const inputFrmRouteBackMixin = {
  methods: {
    /**
     * Durch diese beiden public RouteBack-Methode werden Query-Parameter aufbereitet, wenn ein Inputformular
     * durch Back oder Senden verlassen. Werden diese Query-Parameter gesetzt, führt dies dazu, dass das
     * - PLUS-Menu mit dem richtig ausgewählten Tab wieder aufgeht
     * - Im Journal ein vorher offenes Detail wieder geöffnet wird
     * - In einer Karte/View ein vorher offenes Detail wieder geöffnet wird
     * Dies Methode ist für die Assessmenttypen wie (Situation, Decison...) zu verwenden
     * @param {boolean} isNew Wurde das Inputformular im Neu- oder Edit-Modus geöffnet
     */
    routeBackAssessmentTypes(isNew) {
      this.$_inputFrmRouteBackMixin_routeBackWhenCalledFromJournal(
        isNew,
        true,
        'assessmentObs'
      )
    },
    /**
     * Analoge Funktionalität zur obigen Methode.
     * Dies Methode ist jedoc für die gemischten Typen (Attachment + Danger) zu verwenden
     * @param {boolean} isCalledFromJournal Ist zu Setzen wenn man von einem Input mit Ursprung Journal oder PLUS-Menu kommt
     * @param {boolean} isNew Wurde das Inputformular im Neu- oder Edit-Modus geöffnet
     * @param {boolean} assFlag Assessment oder Observation-Type
     * @param {boolean} isStudyplotDanger Handelt es sich im Falle von 'Danger' um eine Studyplot-Danger
     */
    routeBackMixedTypes(
      isCalledFromJournal,
      isNew,
      assFlag,
      isStudyplotDanger
    ) {
      if (isCalledFromJournal) {
        this.$_inputFrmRouteBackMixin_routeBackWhenCalledFromJournal(
          isNew,
          assFlag,
          'observation',
          isStudyplotDanger
        )
      } else {
        // RouteBack when Called from a MapView
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },
    // Namensgebung für eine private Methode gemäss Vue-Essentials:
    // https://vuejs.org/v2/style-guide/#Private-property-names-essential
    $_inputFrmRouteBackMixin_routeBackWhenCalledFromJournal(
      isNew,
      assFlag,
      storeToSelectedId,
      isStudyplotDanger
    ) {
      const query =
        this.$_inputFrmRouteBackMixin_getTabIdToChooseInPlusMenuAsQueryObject(
          assFlag,
          isStudyplotDanger
        )
      if (!isNew) {
        query.openObs = this.$store.state[storeToSelectedId].selected.id
      }
      this.$router.push({
        path: '/observation/list',
        query: query,
      })
      if (isNew) {
        this.$root.$emit('bv::show::modal', 'obsModal')
      }
    },
    $_inputFrmRouteBackMixin_getTabIdToChooseInPlusMenuAsQueryObject(
      assFlag,
      isStudyplotDanger
    ) {
      const studyplotTabId =
        this.$store.state.profile.studyplotIdToObserverGroup
      let tabId = this.$store.getters['profile/getObserverGroupId']
      if (assFlag) {
        tabId = this.$store.getters['profile/getAvalServiceId']
      }

      let query
      if (isStudyplotDanger) {
        query = {
          pathSetStudyplotId: studyplotTabId,
        }
      } else {
        query = {
          pathSetObsGroupId: tabId,
        }
      }
      return query
    },
  },
}

import Feature from 'ol/Feature'
import Select from 'ol/interaction/Select'
import Snap from 'ol/interaction/Snap'
import { getDrawControl } from '@/scripts/avalancheInputMap/avalancheInputControls'
import ModifyDeleteControl from '@/scripts/avalancheInputMap/modifyDeleteControl/ModifyDeleteControl'
import {
  AvalancheMapOl,
  ExtendedGeometry,
} from '@/components/avalanche/MapOLInterface'

interface DeleteInteractions {
  selectInteraction: Select
  snapInteraction: Snap
}

export function getDeleteInteraction(
  modifyDelCtrl: ModifyDeleteControl
): DeleteInteractions {
  const selectInteraction = new Select({
    hitTolerance: 10,
    filter: function (feature, featureLayer) {
      const extFeat = feature.getGeometry() as unknown as ExtendedGeometry
      return (
        !extFeat?.computed &&
        featureLayer?.get('zIndex') &&
        featureLayer?.get('zIndex') === 20
      )
    },
  })
  const snapInteraction = new Snap({
    source: modifyDelCtrl.getAvalMapCompDrawnSrc(),
    pixelTolerance: 20,
  })
  selectInteraction.on(
    'select',
    getSelectAndDeleteEvent(modifyDelCtrl, selectInteraction, snapInteraction)
  )
  return { selectInteraction, snapInteraction }
}

function getSelectAndDeleteEvent(
  modifyDelCtrl: ModifyDeleteControl,
  selectInteraction: Select,
  snapInteraction: Snap
) {
  return () => {
    if (!selectInteraction || !snapInteraction) {
      return
    }
    selectInteraction.getFeatures().forEach((selectedFeature) => {
      let x = 0
      const drawnFeatures: Feature[] =
        modifyDelCtrl.getAvalMapCompDrawnSrcFeatures()
      drawnFeatures.forEach((drawnFeature) => {
        const selFeatGeo =
          selectedFeature.getGeometry() as unknown as ExtendedGeometry
        const drawnFeatGeo =
          drawnFeature.getGeometry() as unknown as ExtendedGeometry
        if (selFeatGeo?.locationType === drawnFeatGeo?.locationType) {
          handleDeleteDiffrentLocationType(
            x,
            selectedFeature,
            drawnFeature,
            drawnFeatures,
            modifyDelCtrl.getAvalMapComponent()
          )
        }
        x++
      })
    })

    const drawnFeatures: Feature[] =
      modifyDelCtrl.getAvalMapCompDrawnSrcFeatures()
    if (drawnFeatures.length < 1) {
      modifyDelCtrl.getAvalMapCompOlMap().removeInteraction(selectInteraction)
      modifyDelCtrl.getAvalMapCompOlMap().removeInteraction(snapInteraction)
      modifyDelCtrl.setDisabled(true)
    } else {
      drawnFeatures.forEach((feature) => {
        modifyDelCtrl.getAvalMapComponent().addFeature(feature.getGeometry())
      })
    }
    modifyDelCtrl.removeAllInteractions()
    modifyDelCtrl.removeAllButtonActivity()
  }
}

function handleDeleteDiffrentLocationType(
  x: number,
  selectedFeature: Feature,
  drawnFeature: Feature,
  drawnFeatures: Feature[],
  avalMapComponent: AvalancheMapOl
) {
  const selFeatGeo =
    selectedFeature.getGeometry() as unknown as ExtendedGeometry
  const drawControl = getDrawControl(avalMapComponent.map.getControls())
  switch (selFeatGeo.locationType) {
    case 'avalancheGeo':
      avalMapComponent.removeAvalancheFeature()
      drawControl?.setDisabled(false)
      avalMapComponent.drawnSource?.clear(true)
      break
    case 'startZoneGeo':
      avalMapComponent.removeStartZoneFeature()
      if (avalMapComponent.depositGeo === null) {
        drawControl?.setDisabled(false)
      } else {
        drawControl?.disableButton(2, false) // disable startZoneButton
      }
      avalMapComponent.drawnSource?.removeFeature(drawnFeatures[x])
      break
    case 'depositGeo':
      avalMapComponent.removeDepositFeature()
      if (avalMapComponent.startZoneGeo === null) {
        drawControl?.setDisabled(false)
      } else {
        drawControl?.disableButton(3, false) // disable deposit button
      }
      avalMapComponent.drawnSource?.removeFeature(drawnFeatures[x])
      break
    default:
      break
  }
}

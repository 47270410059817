'use strict'

import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import { AbstractStyle } from '../AbstractStyle'

export const ZOOM_LEVEL_SHOW_ROSETTE = 350
export const ASPECTS = ['north', 'west', 'south', 'east']

export class AbstractCompassStyle extends AbstractStyle {
  getAltitudeLabel(featureValue, resolution) {
    if (resolution < ZOOM_LEVEL_SHOW_ROSETTE) {
      let min = 40
      let setted = false
      ASPECTS.forEach((aspect) => {
        if (
          featureValue &&
          Array.isArray(featureValue[aspect]) &&
          featureValue[aspect]
        ) {
          ;[0, 1, 2, 3, 4, 5].forEach((index) => {
            if (
              featureValue[aspect][index] !== undefined &&
              featureValue[aspect][index] !== null
            ) {
              min = Math.min(min, (index + 2) * 5)
              // max = Math.max(max, (index + 2) * 5 + 5)
              setted = true
            }
          })
        }
      })
      const max = min + 15
      if (!setted) {
        return ''
      }
      const labelValue = min + '...' + max
      return new Text({
        text: labelValue,
        offsetY: -29,
        font: '12px sans-serif',
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
      })
    } else {
      return ''
    }
  }
  getColoredSVG(fill) {
    return `
    <g style="stroke-width: 2px; stroke: rgb(204, 204, 204); fill: white;">
      <g>
        <path fill="${fill.west[0]}" id="01" d="M 0 0 L -21.21 -21.21 A 30 30 0 0 0 -21.21 21.21 L 0 0"></path>
        <path fill="${fill.south[0]}" id="02" d="M 0 0 L -21.21 21.21 A 30 30 0 0 0 21.21 21.21 L 0 0"></path>
        <path fill="${fill.east[0]}" id="03" d="M 0 0 L 21.21 21.21 A 30 30 0 0 0 21.21 -21.21 L 0 0"></path>
        <path fill="${fill.north[0]}" id="04" d="M 0 0 L 21.21 -21.21 A 30 30 0 0 0 -21.21 -21.21 L 0 0"></path>
      </g>
      <g>
        <path fill="${fill.west[1]}" id="11" d="M 0 0 L -15.55 -15.55 A 22 22 0 0 0 -15.55 15.55 L 0 0"></path>
        <path fill="${fill.south[1]}" id="12" d="M 0 0 L -15.55 15.55 A 22 22 0 0 0 15.55 15.55 L 0 0"></path>
        <path fill="${fill.east[1]}" id="13" d="M 0 0 L 15.55 15.55 A 22 22 0 0 0 15.55 -15.55 L 0 0"></path>
        <path fill="${fill.north[1]}" id="14" d="M 0 0 L 15.55 -15.55 A 22 22 0 0 0 -15.55 -15.55 L 0 0"></path>
      </g>
      <g>
        <path fill="${fill.west[2]}" id="21" d="M 0 0 L -8.48 -8.48 A 12 12 0 0 0 -8.48 8.48 L 0 0"></path>
        <path fill="${fill.south[2]}" id="22" d="M 0 0 L -8.48 8.48 A 12 12 0 0 0 8.48 8.48 L 0 0"></path>
        <path fill="${fill.east[2]}" id="23" d="M 0 0 L 8.48 8.48 A 12 12 0 0 0 8.48 -8.48 L 0 0"></path>
        <path fill="${fill.north[2]}" id="24" d="M 0 0 L 8.48 -8.48 A 12 12 0 0 0 -8.48 -8.48 L 0 0"></path>
      </g>
    </g>
  `
  }
}

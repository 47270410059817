import observationService from '@/services/observationService.js'
import avalancheService from '@/services/avalancheService.js'
import aggregationService from '@/services/aggregationService.js'
import { DETAIL_COMPONENTS_SORT } from '@/scripts/const'
import moment from 'moment'

const ZEITSPANNE_FUER_AGGREGATION_IN_STUNDEN = 1

// state
const state = {
  aggregatedObservations: [],
  aggregatedAvalanches: [],
}

// getters
const getters = {}

// actions
const actions = {
  loadAggregatedObservations({ commit }, aggregateInfos) {
    commit('SET_AGGREGATED_OBSERVATIONS', [])
    const promises = []
    promises.push(
      observationService.getObservationsByUserAndCreatedDateWithinHours(
        aggregateInfos.uid,
        aggregateInfos.creationDate,
        ZEITSPANNE_FUER_AGGREGATION_IN_STUNDEN
      )
    )
    return Promise.all(promises)
      .then((values) => {
        let obs = []
        if (values[0] && values[0].data && values[0].data.data) {
          obs = values[0].data.data // need all kind of observations
        }
        obs = aggregationService.getObservationsWithinRadius(
          aggregateInfos.location,
          obs,
          aggregateInfos.isAssessmentAggregation
        )
        // sort by Typ and then by date
        obs.sort((item1, item2) =>
          item1.cTimestamp > item2.cTimestamp
            ? -1
            : item1.cTimestamp === item2.cTimestamp
            ? 0
            : 1
        )
        const sortedObs = []
        // Es werden nur Typen gemäss "DETAIL_COMPONENTS_SORT" für die Aggregation berücksichtigt. Andere Typen werden
        // aussortiert. (z.B: Situation, Decision...)
        DETAIL_COMPONENTS_SORT.forEach((typ) => {
          obs.forEach((detail) => {
            if (detail.type === typ) {
              sortedObs.push(detail)
            }
          })
        })
        commit('SET_AGGREGATED_OBSERVATIONS', sortedObs)
        commit('SET_EXCEPTION', null, { root: true })
        console.log('AggregatedObservations loaded...')
      })
      .catch(function (error) {
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },

  loadAggregatedAvalanches({ commit }, aggregateInfos) {
    const startDate = moment(aggregateInfos.creationDate).subtract(
      ZEITSPANNE_FUER_AGGREGATION_IN_STUNDEN,
      'hours'
    )
    const endDate = moment(aggregateInfos.creationDate).add(
      ZEITSPANNE_FUER_AGGREGATION_IN_STUNDEN,
      'hours'
    )
    commit('SET_AGGREGATED_AVALANCHES', [])
    return avalancheService
      .getAvalanchesByCreationDateIntervalAndOptObserver(
        startDate,
        endDate,
        aggregateInfos.uid
      )
      .then((avalanches) => {
        const reducedAvalanches = aggregationService.getAvalanchesWithinRadius(
          aggregateInfos.location,
          avalanches.data
        )
        // sort by date
        reducedAvalanches.sort((item1, item2) =>
          moment(item1.creation).isAfter(moment(item2.creation))
            ? -1
            : moment(item1.creation).isSame(moment(item2.creation))
            ? 0
            : 1
        )
        commit('SET_AGGREGATED_AVALANCHES', reducedAvalanches)
        commit('SET_EXCEPTION', null, { root: true })
        console.log('aggregated avalanches by creation date loaded')
      })
      .catch(function (error) {
        commit('SET_AGGREGATED_AVALANCHES', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

// mutations
const mutations = {
  SET_AGGREGATED_OBSERVATIONS(state, aggregatedObservations) {
    state.aggregatedObservations = aggregatedObservations
  },
  SET_AGGREGATED_AVALANCHES(state, aggregatedAvalanches) {
    state.aggregatedAvalanches = aggregatedAvalanches
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

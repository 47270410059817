import { enums } from '../../I18N'
import {
  COLOR_GUENSTIG,
  COLOR_MITTEL,
  COLOR_SCHWACH,
  GEFAHREN_INDEX_COLORS_STROKE,
} from '@/components/styles/AbstractStyle'

export const NO_VALUE_CLASS = {
  style: { fill: '#ffffff', stroke: '#122e42' },
}

export const WHUMPF_CRACKS_STYLE_ELEMENTS = [
  {
    frequency: enums.dangersign.frequency[0],
    style: {
      fill: COLOR_GUENSTIG,
      scale: 0.5,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[0],
      strokeWidth: 16,
    },
  },
  {
    frequency: enums.dangersign.frequency[1],
    style: {
      fill: COLOR_MITTEL,
      scale: 0.7,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[1],
      strokeWidth: 16,
    },
  },
  {
    frequency: enums.dangersign.frequency[2],
    style: {
      fill: COLOR_SCHWACH,
      scale: 1.0,
      stroke: GEFAHREN_INDEX_COLORS_STROKE[2],
      strokeWidth: 16,
    },
  },
]

<template>
  <div>
    <svg :viewBox="viewBox" height="6rem">
      <template v-for="(aspect, i) in allAspects" :key="i">
        <g>
          <path
            :d="getAspectPath(i)"
            :fill="isActiveAspect(aspect) ? 'black' : 'white'"
            :stroke="isActiveAspect(aspect) ? 'none' : 'black'"
            stroke-width="2"
          />
          <text
            v-if="i % 2 === 0"
            :x="labelPositions[i].x"
            :y="labelPositions[i].y"
            :fill="isActiveAspect(aspect) ? 'white' : 'black'"
            :font-size="dimensions.labelFontSize"
            text-anchor="middle"
            dominant-baseline="middle"
          >
            {{ aspect }}
          </text>
        </g>
      </template>
    </svg>
  </div>
</template>

<script lang="ts">
import {
  allAspects,
  Aspect,
  Point,
  WindRoseDimensions,
  WindRoseProps,
} from './WindEuroseModels'
import { useWindEurose } from './useWindEurose'
import { computed, defineComponent, PropType, Ref } from 'vue'

export default defineComponent({
  props: {
    aspects: {
      type: Array as PropType<Aspect[]>,
      default: () => [],
    },
  },
  setup(props: WindRoseProps) {
    const reactiveAspects = computed(() => props.aspects)

    const {
      dimensions,
      labelPositions,
      viewBox,
      getAspectPath,
      isActiveAspect,
    } = useWindEurose(reactiveAspects)

    return {
      dimensions: dimensions as Ref<WindRoseDimensions>,
      labelPositions: labelPositions as Ref<Point[]>,
      viewBox,
      getAspectPath,
      isActiveAspect,
      allAspects,
    }
  },
})
</script>

import { Stroke } from 'ol/style'
import Style from 'ol/style/Style'
import { Geometry } from 'ol/geom'
import Text from 'ol/style/Text'

export interface Avalanche {
  id: string
  objectType: string
  avalancheMoisture: string
  activityIndex: number
  detailGeoType: string
  detailGeometry: Geometry
  noAvalDetailFound: boolean
  reloadErrors: number
}

export interface AvalancheRelease {
  value: boolean
}

export interface HumidityStylesValues {
  fill: string
  stroke_color: string
  stroke_width: number
  stroke: string
}

export type GetLabelStyleFunction = {
  getLabelStyle: string
  (
    avalanche: Avalanche,
    geometry: Geometry,
    textOffsetY: number,
    textOffsetX: number
  ): Style
}

const AVAL_SYMBOL_TEXT_STROKE = new Stroke({
  color: 'white',
  width: 2,
})
const AVAL_SYMBOL_TEXT = new Text({
  text: '',
  offsetY: 0,
  offsetX: 0,
  font: 'bold 12px sans-serif',
  stroke: AVAL_SYMBOL_TEXT_STROKE,
})

export const getAvalSymbolText = function (
  text: string,
  textOffsetX: number,
  textOffsetY: number
): Text {
  AVAL_SYMBOL_TEXT.setText(text)
  AVAL_SYMBOL_TEXT.setOffsetX(textOffsetX)
  AVAL_SYMBOL_TEXT.setOffsetY(textOffsetY)
  return AVAL_SYMBOL_TEXT
}

<template>
  <div></div>
</template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'DetailModalSnowprofile',
  props: ['aval'],
  watch: {
    aval: function () {
      emitter.emit('showSnowprofile', { aval: this.aval })
    },
  },
}
</script>

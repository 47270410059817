export function deepClone<T>(valueToClone: T): T {
  if (valueToClone == null) {
    return valueToClone
  }
  return JSON.parse(JSON.stringify(valueToClone))
}

export const objectToBase64 = <T>(object: T): string => {
  const json = JSON.stringify(object)
  return Buffer.from(json).toString('base64')
}

export const base64ToObject = <T>(base64String: string): T => {
  const json = Buffer.from(base64String, 'base64').toString()
  return JSON.parse(json)
}

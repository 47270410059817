export const observationProjection = 'EPSG:21781'

export interface CRS {
  type: string
  properties: { name: string }
}

export interface StationDataGeoJsonProperties {
  id: string // station code
  network: string
  name: string
  delay: number | null
}

'use strict'

import { AbstractAvalancheStyle } from './AbstractAvalancheStyle'

export class AvalancheStyleInput extends AbstractAvalancheStyle {
  styleFunction(feature, resolution) {
    return super.styleFunction(feature, resolution, getLabelStyle, true)
  }
}

const getLabelStyle = function () {
  return null
}

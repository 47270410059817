import bulletinService from '@/services/bulletinService.js'

// NOTE (20200703/csz): bulletinService.loadActiveAtInfo always returns a response: an empty array
//                      if there is no active bulletin at the specified datetime, or a filled array
//                      if there is one or more
//                      bulletinService.loadLatestActiveAtGeoJson returns 404 (not found) if there
//                      is no active bulletin at the specified datetime

const actions = {
  loadLatestActiveBulletinAtAsGeoJson(context, params) {
    const cancelObj = { cancelGeo: null }
    const promise = bulletinService.loadLatestActiveAtGeoJson(
      params.timestamp,
      params.rating,
      true,
      cancelObj
    )
    if (params.cancel) {
      params.cancel.addCancel(cancelObj.cancelGeo)
    }
    return new Promise((resolve, reject) => {
      promise
        .then(function (response) {
          console.debug(
            'loaded latest active bulletin at specified date and time as geojson'
          )
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response)
        })
        // eslint-disable-next-line complexity
        .catch(function (error) {
          if (!(error.response && error.response.status === 404)) {
            console.error(error)
          }
          if (
            !(
              error.response &&
              (error.response.status === 401 || error.response.status === 404)
            )
          ) {
            if (error.response && error.response.data) {
              context.commit('SET_EXCEPTION', error.response.data.message, {
                root: true,
              })
            } else {
              context.commit('SET_EXCEPTION', error.message, { root: true })
            }
          }
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions,
  mutations: {},
}

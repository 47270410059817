import { commonReportMixin } from '@/components/reporting/CommonReportMixin.js'
import { avalReportObsBuilderMixin } from '@/components/reporting/AvalReportObsBuilderMixin.js'
import { commonReportObsBuilderMixin } from '@/components/reporting/CommonReportObsBuilderMixin.js'
import moment from 'moment'

const SHOW_WARNING_LIMIT = 50

export const journalReportMixin = {
  data() {
    return {
      selectedAvalServiceId: undefined,
    }
  },
  mixins: [
    commonReportMixin,
    avalReportObsBuilderMixin,
    commonReportObsBuilderMixin,
  ],
  computed: {
    isOverWarningLimit() {
      return this.filteredItems.length > SHOW_WARNING_LIMIT
    },
  },
  methods: {
    pdfReport(compactPdfPreparation) {
      try {
        this.setPdfReportingInProgress(true)
        this.showWarning = this.isOverWarningLimit
        const vm = this
        const report = this.getJournalReportBuilder(
          this.getJournalDateRange(),
          this.getAvalancheServiceName(this.selectedAvalServiceId),
          compactPdfPreparation
        )
        Promise.all(this.getReportObsPromises(this.filteredItems))
          .then((results) => {
            return this.preLoadImages(results)
          })
          .then((reportObs) => {
            report.buildReportDetails(
              reportObs,
              vm.getReportConfigFunctions(),
              true
            )
            vm.startPdfReporting(
              report.getModelAsJson(),
              this.isOverWarningLimit
            )
          })
          .catch((err) => {
            this.showWarning = false
            this.setPdfReportingInProgress(false)
            this.showPrintError(err)
          })
      } catch (error) {
        this.showWarning = false
        this.setPdfReportingInProgress(false)
        this.showPrintError(error)
      }
    },
    getReportObsPromises(items) {
      const itemPromises = []
      items.forEach((item) => {
        if (item.type === 'avalanche') {
          itemPromises.push(
            this.getAvalReportObs(item, item.avalanche.avalancheClass)
          )
        } else {
          const itemPromise = this.getCommonObservationPromise(item)
          if (itemPromise) {
            itemPromises.push(itemPromise)
          }
        }
      })
      return itemPromises
    },
    getReportConfigFunctions() {
      return {
        detailTitel: this.typeFormatter,
        headerLabel: this.datetimeFormatter,
        headerText: this.userFormatter,
        areaName: this.getAreaName,
        sectorName: this.getSectorName,
        objectName: this.getObjectName,
        avalanchePathName: this.getAvalanchePathName,
      }
    },
    getJournalDateRange() {
      const rangeDateFormatter = 'DD.MM.YYYY'
      return (
        moment(this.fromDate).format(rangeDateFormatter) +
        ' - ' +
        moment(this.toDate).format(rangeDateFormatter)
      )
    },
    getAvalancheServiceName(id) {
      const avalService =
        this.$store.getters['profile/getAssmAvalServiceToId'](id)
      return avalService?.name
    },
  },
}

import { components as observationComps } from '@/model/generated/observationApi'
import { i18n } from '@/plugins/i18n'
import { Aspect } from '@/model/commonModels'

export type DangerDto = observationComps['schemas']['DangerDto']
export type Subdivision =
  observationComps['schemas']['DangerDto']['subdivision']
export type DangerProblem = observationComps['schemas']['DangerDto']['problem']
export type DangerAllDay = observationComps['schemas']['DangerDto']['allDay']
export type DangerLevel = observationComps['schemas']['DangerDto']['level']

export interface DangerModel {
  hierarchy: { areaId: null; sectorId: null; avalServiceId: null }
  problem: DangerProblem | null
  pattern: string[]
  allDay: DangerAllDay | null
  aspectFrom: Aspect | null
  aspectTo: Aspect | null
  altitudeAbove: boolean | null
  altitudeValue: boolean | null
  level: DangerLevel | null
  subdivision: Subdivision | null
  origin: string | null
  confidential: boolean
}

export enum BulletinTime {
  Morning = 'morning',
  Later = 'later',
}

export const DANGER_SUBDIVISION = ['MINUS', 'NEUTRAL', 'PLUS'] as const

export enum DANGER_SUBDIVISIONS {
  MINUS = 'MINUS',
  NEUTRAL = 'NEUTRAL',
  PLUS = 'PLUS',
}
export const DANGER_PROBLEM = ['DRY', 'WET', 'FULL_DEPTH'] as const
export enum DANGER_PROBLEMS {
  DRY = 'DRY',
  WET = 'WET',
  FULL_DEPTH = 'FULL_DEPTH',
}
export const DANGER_LEVEL = ['1', '2', '3', '4', '5'] as const
export enum DANGER_LEVELS {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
}

// Diese Funktion prüft nicht, ob z.B.(1+) valid ist, sonder konkateniert lediglich 'level' und 'subdivision'
// eslint-disable-next-line complexity
export const getFullDangerLevel = (
  subdivision: Subdivision | null,
  level: DangerLevel | null
): string => {
  const translatedLevel =
    level != null
      ? i18n.global.t('danger.legend.'.concat(level)).toString()
      : ''
  let levelText = translatedLevel
  if (translatedLevel.length > 0) {
    levelText = `${translatedLevel}, ${i18n.global.t(
      'danger.label.levelShort'
    )} ${level}`
  }
  if (subdivision && level) {
    return `${levelText}${getSubdivisionChar(subdivision)}`
  } else {
    return levelText
  }
}

export const getSubdivisionChar = (subdivision: Subdivision | null): string => {
  if (subdivision) {
    switch (subdivision) {
      case DANGER_SUBDIVISIONS.MINUS:
        return '-'
      case DANGER_SUBDIVISIONS.NEUTRAL:
        return '='
      case DANGER_SUBDIVISIONS.PLUS:
        return '+'
    }
    return ''
  } else {
    return ''
  }
}

import warnRegionService from '../../services/warnRegionService'

// state
const state = {
  zoom: null,
  center: null,
  regions: null,
}

// getters
const getters = {}

// actions
const actions = {
  setZoom({ commit }, zoom) {
    commit('SET_ZOOM', zoom)
  },
  setCenter({ commit }, center) {
    commit('SET_CENTER', center)
  },
  loadWarnRegions(context, date) {
    return warnRegionService
      .getWarnRegionsByDate(date)
      .then((regions) => {
        context.commit('SET_REGIONS', regions.data)
        context.commit('SET_EXCEPTION', null, { root: true })
      })
      .catch(function (error) {
        context.commit('SET_REGIONS', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },
}

// mutations
const mutations = {
  SET_ZOOM(state, zoom) {
    state.zoom = zoom
  },
  SET_CENTER(state, center) {
    state.center = center
  },
  SET_REGIONS(state, regions) {
    state.regions = regions
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export const routerMixin = {
  methods: {
    routeToWhenNeeded(path) {
      // To prevent: Error: Avoided redundant navigation to current location: "..."
      if (path && path !== this.$router.currentRoute.path) {
        this.$router.push({ path: path })
      }
    },
  },
}

import BaseService from '@/services/BaseService'

export default class warnRegionService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('WARN_REGION_SERVICE_URL', 20000)
    return this.srv.getInstance()
  }
  static getWarnRegionsByDate(date) {
    return this.getInstance().get('findByDate/geojson?date=' + date)
  }
}

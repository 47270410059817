import { Options } from 'ol/source/Cluster'
import { Cluster } from 'ol/source'
import { AbstractAvalancheStyle } from '@/components/styles/avalancheStyling/AbstractAvalancheStyle'
import OLMap from 'ol/Map'
import { FeatureLike } from 'ol/Feature'
import AvalancheGeometryLoader from '@/scripts/clustering/AvalancheGeometryLoader'
import store from '@/store/index.js'
import { DEFAULT_VIEW_EXTENT } from '@/scripts/Map'

class FullCluster extends Cluster {
  // Ab xxx Features, wird die Clusterschwelle anhand der sichtbaren Features berechnet,
  // ansonsten wie bis anhin ab einer bestimmten Zoomstife
  private MANY_FEATURES = 400
  private CLUSTERING_THRESHOLD_AT_FEATURE_COUNT_DESKTOP = 200
  private CLUSTERING_THRESHOLD_AT_FEATURE_COUNT_MOBILE = 20
  private MOBILE_AVAL_LIMIT_FOR_LOADING_DETAILS = 10000
  public static CLUSTERING_THRESHOLD_AT_RESOLUTION = 200

  private myAvalGeoLoader: AvalancheGeometryLoader
  private style: AbstractAvalancheStyle | null
  private olMap: OLMap | undefined | null
  private isClustered = true

  constructor(options: Options, olMap: OLMap | undefined) {
    super(options)
    this.myAvalGeoLoader = new AvalancheGeometryLoader()
    this.style = null
    this.olMap = olMap
  }

  public setStyle(style: AbstractAvalancheStyle): void {
    this.style = style
  }

  public setOlMap(olMap: OLMap): void {
    this.olMap = olMap
  }

  public recalculateCluster(): void {
    if (this.hasNoFeatures()) {
      return
    }
    if (this.showClustered() !== this.isClustered) {
      this.source?.changed()
      return
    }
    if (!this.isClustered) {
      // Nicht auf das Laden der Details warten
      this.reloadAvalGeoAndRefreshMap()
    }
  }

  public cluster(): void {
    if (this.hasNoFeatures()) {
      return
    }
    if (this.resolution) {
      if (this.showClustered()) {
        this.isClustered = true
        super.cluster()
      } else {
        if (this.resolution === undefined) {
          return
        }
        this.isClustered = false
        // Nicht auf das Laden der Details warten
        this.reloadAvalGeoAndRefreshMap()
        this.features.length = 0
        this.source?.getFeatures().forEach((originalFeature) => {
          this.features.push(
            super.createCluster([originalFeature], DEFAULT_VIEW_EXTENT)
          )
        })
      }
      if (this.features.length > 0) {
        this.style?.prepareClusters(this.features, this.resolution)
      }
    }
  }

  private showClustered(): boolean {
    if (!this.source || !this.resolution) {
      return false
    }
    if (this.source.getFeatures().length > this.MANY_FEATURES) {
      return (
        this.getNumberOfFeaturesCurrentlyVisible() <= 0 ||
        this.resolution > FullCluster.CLUSTERING_THRESHOLD_AT_RESOLUTION ||
        this.isLowerThanClusterThresold()
      )
    } else {
      return this.resolution > FullCluster.CLUSTERING_THRESHOLD_AT_RESOLUTION
    }
  }

  private isLowerThanClusterThresold(): boolean {
    if (store.state.mobilePlatform) {
      return (
        this.getNumberOfFeaturesCurrentlyVisible() >
        this.CLUSTERING_THRESHOLD_AT_FEATURE_COUNT_MOBILE
      )
    } else {
      return (
        this.getNumberOfFeaturesCurrentlyVisible() >
        this.CLUSTERING_THRESHOLD_AT_FEATURE_COUNT_DESKTOP
      )
    }
  }

  private getNumberOfFeaturesCurrentlyVisible(): number {
    return this.getFeaturesCurrentlyVisible().length
  }

  private getFeaturesCurrentlyVisible(): Array<FeatureLike> {
    let visibleFeatures: Array<FeatureLike> = []
    if (this.source && this.olMap?.getView()) {
      visibleFeatures = this.source.getFeaturesInExtent(
        this.olMap.getView().calculateExtent(this.olMap.getSize())
      )
    }
    return visibleFeatures
  }

  private hasNoFeatures(): boolean {
    return (
      this.source == null ||
      !this.source.getFeatures() ||
      this.source.getFeatures().length <= 0
    )
  }

  private async reloadAvalGeoAndRefreshMap() {
    if (this.hasNoFeatures() || this.isToManyDetailsOnMobile()) {
      return
    }
    if (
      await this.myAvalGeoLoader.enrichVisibleAvalFeaturesWithGeometryElement(
        this.getFeaturesCurrentlyVisible()
      )
    ) {
      this.source?.changed()
    }
  }

  private isToManyDetailsOnMobile() {
    if (this.source == null) {
      return false
    }
    return !!(
      store.state.mobilePlatform &&
      this.source.getFeatures().length >
        this.MOBILE_AVAL_LIMIT_FOR_LOADING_DETAILS
    )
  }

  public releaseMem(): void {
    this.features = []
    this.olMap = null
    this.dispose()
  }
}
export default FullCluster

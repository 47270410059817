'use strict'

import { i18n } from '@/plugins/i18n'
import SpecialValueBuilder from '@/model/report/SpecialValueBuilder.js'
import {
  REPORT_VALUE_TYPE_STRING,
  REPORT_VALUE_TYPE_SINGLE_ENUM,
  REPORT_VALUE_TYPE_MULTIPLE_ENUM,
  REPORT_VALUE_TYPE_RANGE_ARRAY,
  REPORT_VALUE_TYPE_COMMON_DATE_TIME,
  REPORT_VALUE_TYPE_DATE_ONLY,
  REPORT_VALUE_TYPE_TIME_ONLY,
  REPORT_VALUE_TYPE_DANGER_ASPECT_ROSETTE,
  REPORT_VALUE_TYPE_WHUMPFCRACKS_ROSETTE,
  REPORT_VALUE_TYPE_WHUMPFCRACKS_RADAR,
  REPORT_VALUE_TYPE_SNOWHEIGHT_CHART,
  REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_ONE,
  REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_TWO,
  REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_THREE,
  REPORT_VALUE_TYPE_DURATION,
} from '@/model/report/ReportDefinitionreader.js'
import moment from 'moment'
import { Duration } from 'luxon'
import { getFullDangerLevel } from '@/model/dangerModels'

const COMMON_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
const COMMON_DATE_ONLY_FORMAT = 'DD.MM.YYYY'
const COMMON_TIME_ONLY_FORMAT = 'HH:mm'

export default class TableEntryValueBuilder {
  /*
    Erstellt die Values für die Tabellen-Entries
  */
  /*eslint-disable complexity*/
  static getValue(objProp, propDef, obsType) {
    let value
    const valueCopy = TableEntryValueBuilder.getValuePrefix(
      objProp,
      propDef,
      obsType
    )
    switch (propDef.valueType) {
      case REPORT_VALUE_TYPE_STRING:
        value = valueCopy
        break
      case REPORT_VALUE_TYPE_SINGLE_ENUM:
        value = TableEntryValueBuilder.getSingleEnumValue(valueCopy, propDef)
        break
      case REPORT_VALUE_TYPE_MULTIPLE_ENUM:
        value = TableEntryValueBuilder.getMultipleEnumValue(valueCopy, propDef)
        break
      case REPORT_VALUE_TYPE_COMMON_DATE_TIME:
        value = TableEntryValueBuilder.getCommonDateTimeValue(valueCopy)
        break
      case REPORT_VALUE_TYPE_DATE_ONLY:
        value = TableEntryValueBuilder.getDateOnlyValue(valueCopy)
        break
      case REPORT_VALUE_TYPE_TIME_ONLY:
        value = TableEntryValueBuilder.getTimeOnlyValue(valueCopy)
        break
      case REPORT_VALUE_TYPE_DURATION:
        value = TableEntryValueBuilder.getDurationValue(valueCopy)
        break
      case REPORT_VALUE_TYPE_RANGE_ARRAY:
        value = TableEntryValueBuilder.getRangeValue(valueCopy, propDef)
        break
      case REPORT_VALUE_TYPE_DANGER_ASPECT_ROSETTE:
        return SpecialValueBuilder.getDangerAspectRosetteValue(valueCopy)
      case REPORT_VALUE_TYPE_WHUMPFCRACKS_ROSETTE:
        return SpecialValueBuilder.getWuCraRosettenValue(valueCopy)
      case REPORT_VALUE_TYPE_WHUMPFCRACKS_RADAR:
        return SpecialValueBuilder.getWuCraRadarValue(valueCopy)
      case REPORT_VALUE_TYPE_SNOWHEIGHT_CHART:
        return SpecialValueBuilder.getSnowHeightChartValue(valueCopy)
      case REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_ONE:
        return SpecialValueBuilder.getAvalStartzoneCountsValue(valueCopy, 1)
      case REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_TWO:
        return SpecialValueBuilder.getAvalStartzoneCountsValue(valueCopy, 2)
      case REPORT_VALUE_TYPE_AVALANCHE_STARTZONE_COUNTS_THREE:
        return SpecialValueBuilder.getAvalStartzoneCountsValue(valueCopy, 3)
      default:
        value = valueCopy
        break
    }
    return value + TableEntryValueBuilder.getValueSuffix(propDef)
  }
  /*eslint-enable complexity*/

  static getCommonDateTimeValue(val) {
    if (!val) {
      return ''
    }
    const dateTime = moment(val)
    return dateTime.isValid() ? dateTime.format(COMMON_DATE_TIME_FORMAT) : ''
  }
  static getDateOnlyValue(val) {
    if (!val) {
      return ''
    }
    const date = moment.utc(val).local()
    return date.isValid() ? date.format(COMMON_DATE_ONLY_FORMAT) : ''
  }
  static getTimeOnlyValue(val) {
    if (!val) {
      return ''
    }
    const dateTime = moment
      .utc(val.triggerDate + val.triggerTime, 'YYYY-MM-DDHH:mm')
      .local()
    return dateTime.isValid() ? dateTime.format(COMMON_TIME_ONLY_FORMAT) : ''
  }
  static getDurationValue(val) {
    if (!val) {
      return ''
    }
    return Duration.fromISO(val).toFormat('m')
  }
  static getValuePrefix(val, propDef, obsType) {
    if (propDef.rawValueSuffix) {
      // ValuePrefix aus einer anderen Property
      return val + obsType[propDef.rawValueSuffix]
    } else if (propDef.rawValueSuffixDangerDetail) {
      // ValuePrefix aus einer anderen Property speziell für Danger
      return getFullDangerLevel(
        obsType[propDef.rawValueSuffixDangerDetail],
        val
      )
    } else if (propDef.valuePrefix) {
      // Simpler ValuePrefix
      return propDef.valuePrefix + val
    }
    return val
  }
  static getValueSuffix(propDef) {
    if (propDef.valueSuffix) {
      return ' ' + i18n.global.t(propDef.valueSuffix)
    }
    return ''
  }
  static getSingleEnumValue(objProp, propDef) {
    if (!Array.isArray(propDef.enumBase)) {
      return i18n.global.t(propDef.enumBase + '.' + objProp)
    }
    let assetCode
    propDef.enumBase.forEach((ele) => {
      const prop = ele + '.' + objProp
      if (i18n.global.te(prop)) {
        assetCode = prop
      }
    })
    return i18n.global.t(assetCode)
  }
  static getMultipleEnumValue(objProp, propDef) {
    let val = ''
    objProp.forEach((ele, idx) => {
      val = val + TableEntryValueBuilder.getSingleEnumValue(ele, propDef)
      if (idx < objProp.length - 1) {
        val = val + ', '
      }
    })
    return val
  }
  static getRangeValue(valueCopy, propDef) {
    const rangeChar = propDef.rangeCharacter ? propDef.rangeCharacter : '-'
    return valueCopy[0] + rangeChar + valueCopy[1]
  }
}

<template>
  <div class="mapContainer" v-show="layer">
    <div id="mapPanel"></div>
    <div id="popup" class="mouseover-popup">
      <div id="popup-content"></div>
    </div>
    <span v-show="false">
      <font-awesome-icon :ref="faSolidFaFilter" :icon="faSolidFaFilter" />
      <font-awesome-icon
        :ref="faSolidFaCrosshairs"
        :icon="faSolidFaCrosshairs"
      />
      <font-awesome-icon
        :ref="faSolidFaLayerGroup"
        :icon="faSolidFaLayerGroup"
      />
      <font-awesome-icon
        :ref="faLightFaSensorAlert"
        :icon="faLightFaSensorAlert"
      />
      <font-awesome-icon :ref="faSolidFaList" :icon="faSolidFaList" />
    </span>
  </div>
</template>
<script>
import emitter from './EventHub'
import { Maplib } from '@/scripts/Map'
import store from '../store/index'
import ClusterVectorLayer from '../scripts/clustering/ClusterVectorLayer.js'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import getStyle from '../components/styles/PinStyle.js'
import { mapState } from 'vuex'
import { AdditionalLayer, BaseLayer } from '@/model/map/commonLayers'
import {
  FA_LIGHT_FA_SENSOR_ALERT,
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_FILTER,
  FA_SOLID_FA_LAYER_GROUP,
  FA_SOLID_FA_LIST,
} from '@/plugins/fontawesomeConsts'

export default {
  components: {},
  name: 'MapComponent',
  data() {
    return {
      first: true,
      map: null,
      layer: {},
      faSolidFaFilter: FA_SOLID_FA_FILTER,
      faSolidFaCrosshairs: FA_SOLID_FA_CROSSHAIRS,
      faSolidFaLayerGroup: FA_SOLID_FA_LAYER_GROUP,
      faLightFaSensorAlert: FA_LIGHT_FA_SENSOR_ALERT,
      faSolidFaList: FA_SOLID_FA_LIST,
    }
  },
  watch: {
    moment: function () {
      this.map.loadAndAddWarnregionLayerToDate()
    },
    baseLayer: {
      handler: function () {
        for (const layerName of Object.values(BaseLayer)) {
          const visible = layerName === this.baseLayer
          this.map?.setCommonLayerVisibility(layerName, visible)
        }
      },
      immediate: true,
    },
    additionalLayers: {
      handler: function () {
        for (const layerName of Object.values(AdditionalLayer)) {
          const visible = this.additionalLayers.includes(layerName)
          this.map?.setCommonLayerVisibility(layerName, visible)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState('calendar', ['moment']),
    baseLayer() {
      return this.$store.state.commonLayers.baseLayer
    },
    additionalLayers() {
      return this.$store.state.commonLayers.additionalLayers
    },
  },
  updated: function () {
    this.$nextTick(function () {
      this.map.recenter()
    })
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'Home':
          this.map.home()
          break
      }
    })
    if (store.state.mobilePlatform) {
      const el = document.getElementById('mapPanel')
      el.style.height = '600px'
    }
    emitter.on('mouseOver', (mouseOverFunction) => {
      this.map.addMouseOver(mouseOverFunction)
    })
    emitter.on('drawPositionVisualisation', (position) => {
      const feature = new Feature(new Point(position.coordinates))
      const pinStyle = getStyle()
      feature.setStyle(pinStyle)
      this.map.addPin(feature)
    })
    // eslint-disable-next-line complexity
    emitter.on('layer', (layer) => {
      this.layer = layer
      if (layer == null) {
        this.map.removeLayers()
        return
      }
      if (this.first) {
        this.map = new Maplib()
        this.map.init('mapPanel', {
          [FA_SOLID_FA_FILTER]: this.$refs[FA_SOLID_FA_FILTER].$el,
          [FA_SOLID_FA_CROSSHAIRS]: this.$refs[FA_SOLID_FA_CROSSHAIRS].$el,
          [FA_SOLID_FA_LAYER_GROUP]: this.$refs[FA_SOLID_FA_LAYER_GROUP].$el,
          [FA_LIGHT_FA_SENSOR_ALERT]: this.$refs[FA_LIGHT_FA_SENSOR_ALERT].$el,
          [FA_SOLID_FA_LIST]: this.$refs[FA_SOLID_FA_LIST].$el,
        })
        this.map.loadAndAddWarnregionLayerToDate()
        this.first = false
      }
      if (this.layer && !this.first) {
        if (!layer.auxiliary || layer.auxiliary === 'false') {
          // normal main layer to be added w/ style, features and so on
          this.map.addLayer(layer.name, layer.styleFunction)
          if (
            layer.style &&
            this.map.layers[layer.name] instanceof ClusterVectorLayer
          ) {
            this.map.layers[layer.name].setStyle(layer.style)
          }
          this.map.showLayer(layer.name)
          this.map.addFeatures(layer.name, layer.features)
          if (layer.onHover) {
            this.map.addHoverHandler(layer.onHover)
          }
          if (layer.onClick) {
            this.map.addClickHandler(layer.name, layer.onClick)
          }
          if (layer.onResolutionChange) {
            this.map.addResolutionChangeHandler(layer.onResolutionChange)
          }
          // NOTE(20190708): toggles filter based on selected map view
          if (layer.supportFilter && layer.supportFilter === true) {
            this.map.addFilterControl()
          } else {
            this.map.removeFilterControl()
          }
          if (!layer.supportLayer || layer.supportLayer === false) {
            // clean up auxiliary layers as well if not supported
            this.map.removeAuxiliaryLayers()
          }
        } else {
          // auxiliary layer to be added in addition to the main and basic ones or it is to be removed
          if (layer.operation.includes('removeAuxLayer')) {
            this.map.removeAuxiliaryLayer(layer.name)
          }
          if (layer.operation.includes('removeMainLayer')) {
            this.map.removeLayers()
          }
          if (layer.operation.includes('addAuxLayer')) {
            this.map.addAuxiliaryLayer(
              layer.name,
              layer.styleFunction,
              layer.operation.includes('placeUnderLayers')
            )
            this.map.showAuxiliaryLayer(layer.name)
            this.map.addAuxiliaryFeatures(layer.name, layer.features)
            if (layer.onClick) {
              this.map.addClickHandler(layer.name, layer.onClick)
            }
          }
          if (layer.supportFilter && layer.supportFilter === true) {
            this.map.addFilterControl()
          } else {
            this.map.removeFilterControl()
          }
        }
        if (layer.supportLayer && layer.supportLayer === true) {
          this.map.addLayerControl()
        } else {
          this.map.removeLayerControl()
        }
        this.$forceUpdate()
      }
    })
  },
  beforeUnmount() {
    this.$store.commit('avalanche/SET_TRIGGER_AVALANCHES', [])
    emitter.off('mouseOver')
    emitter.off('drawPositionVisualisation')
    emitter.off('layer')
  },
}
</script>

<style scoped>
#mapPanel {
  position: absolute;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 0;
  margin: 0;
  background-color: white;
  overflow-y: auto;
}
@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

.symbolHidden {
  display: none;
}

#mapPanel :deep(.ol-zoom) {
  left: unset;
  right: 10px;
}

#mapPanel :deep(.filter-control-mobile) {
  top: 6.9em;
  right: 0.4em;
}

#mapPanel :deep(.layer-control-mobile) {
  top: 9.1em;
  right: 0.4em;
  padding: 2px;
}

@media (max-width: 36em) {
  .filter-control {
    left: 6.2em;
  }
}

@media (max-width: 36em) {
  .layer-control {
    left: 6.2em;
  }
}

#mapPanel :deep(.position) {
  top: 74px;
  right: 0.6em;
}

#mapPanel :deep(.position-mobile) {
  top: 5px;
  right: 0.4em;
}

#mapPanel :deep(.common-layers-control) {
  top: 8.7em;
  right: 0.6em;
}

#mapPanel :deep(.common-layers-control-mobile) {
  top: 2.5em;
  right: 0.4em;
}

#mapPanel :deep(.ol-control button) {
  width: 29px;
  height: 29px;
  background-color: rgba(0, 60, 136, 0.5);
  color: #fff;
  font-size: 1.14em;
}

#mapPanel :deep(.ol-control button:active) {
  outline: none;
  border: none;
}

#mapPanel :deep(.ol-control button:focus) {
  outline: none;
}
</style>

<style>
.mapmenu {
  position: absolute;
  margin: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  border: 1px solid white;
}
.maptitle {
  position: absolute;
  left: 100px;
  top: 10px;
  font: bold 26px Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.mouseover-popup {
  background-color: #fff;
  border: 1px solid black;
  padding: 2px;
  font: 10px Arial, Helvetica, sans-serif;
  min-width: min-content;
  min-height: min-content;
  display: none;
}

.mouseover-symbol {
  width: 28px;
  height: 18px;
  color: red;
}

.svg-symbol {
  width: 28px;
  height: 25px;
}

.legend-control {
  top: 10.9em;
  right: 0.6em;
}

.legend-control-mobile {
  top: 4.7em;
  right: 0.4em;
}

.filter-control {
  top: 0.5em;
  left: 17em;
}

.layer-control {
  bottom: 0.5em;
  left: 17em;
  padding: 2px;
}
</style>

'use strict'

import { Control } from 'ol/control.js'
import Draw from 'ol/interaction/Draw.js'
import { getModifyDeleteControl } from './avalancheInputControls'
import {
  FA_SOLID_FA_ARROW_DOWN,
  FA_SOLID_FA_ARROW_UP,
} from '@/plugins/fontawesomeConsts'
import { toRaw } from 'vue'
import { i18n } from '@/plugins/i18n'

export default class DrawControl extends Control {
  constructor(mapComponent, modifyDeleteControl, faIconRefs) {
    const element = document.createElement('div')
    super({
      element: element,
    })
    this.mapComponent = mapComponent
    this.buttons = []
    const self = this
    this.drawType = 'None'
    const addDrawInteraction = function () {
      if (self.drawType !== 'None') {
        self.draw = new Draw({
          source: mapComponent.drawnSource,
          type: self.drawType,
        })
        self.draw.on('drawend', function (e) {
          const geometry = e.feature.getGeometry()
          getModifyDeleteControl(mapComponent.map.getControls()).setDisabled(
            false
          )
          for (let j = 0; j < self.buttons.length; j++) {
            self.buttons[j].classList.remove('active')
          }
          function disableEditing() {
            self.adaptButtonDisabling(mapComponent)
            mapComponent.drawingAvalanche = false
            mapComponent.drawingStartZone = false
            mapComponent.drawingDeposit = false
            mapComponent.drawingPoint = false
            self.getAvalMapCompOlMap().removeInteraction(self.draw)
          }
          const promise = mapComponent.addFeature(geometry)
          if (!promise) {
            disableEditing()
          } else {
            promise.then(() => {
              disableEditing()
            })
          }
        })
        self.getAvalMapCompOlMap().addInteraction(self.draw)
      }
    }

    this.addDrawInteraction = addDrawInteraction

    const clickedStartZonePoint = function (event) {
      event.preventDefault()
      modifyDeleteControl.setNotActiveAndRemoveInteractions()
      self.drawType = 'Point'
      self.getAvalMapCompOlMap().removeInteraction(self.draw)
      addDrawInteraction()
      self.buttons[2].classList.add('active')
      self.buttons[3].classList.remove('active')
      self.buttons[0].classList.remove('active')
      self.buttons[1].classList.remove('active')
      mapComponent.drawingStartZone = true
    }
    const clickedDepositPoint = function (event) {
      event.preventDefault()
      modifyDeleteControl.setNotActiveAndRemoveInteractions()
      self.drawType = 'Point'
      self.getAvalMapCompOlMap().removeInteraction(self.draw)
      addDrawInteraction()
      self.buttons[3].classList.add('active')
      self.buttons[0].classList.remove('active')
      self.buttons[1].classList.remove('active')
      self.buttons[2].classList.remove('active')
      mapComponent.drawingDeposit = true
    }
    const clickedAvalancheLine = function (event) {
      event.preventDefault()
      modifyDeleteControl.setNotActiveAndRemoveInteractions()
      self.drawType = 'LineString'
      self.getAvalMapCompOlMap().removeInteraction(self.draw)
      addDrawInteraction()
      self.buttons[1].classList.add('active')
      self.buttons[2].classList.remove('active')
      self.buttons[3].classList.remove('active')
      self.buttons[0].classList.remove('active')
      mapComponent.drawingAvalanche = true
    }
    const clickedAvalanchePolygon = function (event) {
      event.preventDefault()
      modifyDeleteControl.setNotActiveAndRemoveInteractions()
      self.drawType = 'Polygon'
      self.getAvalMapCompOlMap().removeInteraction(self.draw)
      addDrawInteraction()
      self.buttons[0].classList.add('active')
      self.buttons[1].classList.remove('active')
      self.buttons[2].classList.remove('active')
      self.buttons[3].classList.remove('active')
      mapComponent.drawingAvalanche = true
    }

    const buttonAvalanchePolygon = document.createElement('button')
    buttonAvalanchePolygon.innerHTML =
      '<img src="./static/avalanche/Lawine.png" width="29" height="29">'
    buttonAvalanchePolygon.className = 'draw-button'
    buttonAvalanchePolygon.id = 'avalanchePolygonButton'
    buttonAvalanchePolygon.title = i18n.global.t(
      'avalanche.label.drawing.avalancheOutline'
    )
    buttonAvalanchePolygon.addEventListener(
      'click',
      clickedAvalanchePolygon,
      false
    )
    element.appendChild(buttonAvalanchePolygon)
    this.buttons.push(buttonAvalanchePolygon)

    const buttonAvalancheLine = document.createElement('button')
    buttonAvalancheLine.innerHTML =
      '<img src="./static/avalanche/icons8-polyline-50.png" width="29" height="29">'
    buttonAvalancheLine.className = 'draw-button'
    buttonAvalancheLine.id = 'avalancheLineButton'
    buttonAvalancheLine.title = i18n.global.t(
      'avalanche.label.drawing.avalanchePath'
    )
    buttonAvalancheLine.addEventListener('click', clickedAvalancheLine, false)
    element.appendChild(buttonAvalancheLine)
    this.buttons.push(buttonAvalancheLine)

    const buttonStartZonePoint = document.createElement('button')
    buttonStartZonePoint.className = 'draw-button'
    buttonStartZonePoint.id = 'startZonePointButton'
    buttonStartZonePoint.title = i18n.global.t(
      'avalanche.label.drawing.avalancheStartPoint'
    )
    buttonStartZonePoint.addEventListener('click', clickedStartZonePoint, false)
    buttonStartZonePoint.appendChild(faIconRefs[FA_SOLID_FA_ARROW_UP])
    element.appendChild(buttonStartZonePoint)
    this.buttons.push(buttonStartZonePoint)

    const buttonDepositPoint = document.createElement('button')
    buttonDepositPoint.className = 'draw-button'
    buttonDepositPoint.id = 'depositPointButton'
    buttonDepositPoint.title = i18n.global.t(
      'avalanche.label.drawing.avalancheDepositPoint'
    )
    buttonDepositPoint.addEventListener('click', clickedDepositPoint, false)
    buttonDepositPoint.appendChild(faIconRefs[FA_SOLID_FA_ARROW_DOWN])
    element.appendChild(buttonDepositPoint)
    this.buttons.push(buttonDepositPoint)
    // }
    element.className = 'draw-type ol-unselectable ol-control'
  }
  getAvalMapCompOlMap() {
    return toRaw(this.mapComponent.map)
  }
  setDisabled(disabled) {
    const element = document.getElementsByClassName('draw-type')
    const buttons = element[0].children
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = disabled
    }
  }
  disableButton(i, disabled) {
    const element = document.getElementsByClassName('draw-type')
    const buttons = element[0].children
    buttons[i].disabled = disabled
  }
  removeDraw(mapComponent) {
    this.getAvalMapCompOlMap().removeInteraction(this.draw)
    getModifyDeleteControl(mapComponent.map.getControls()).setDisabled(false)
    this.adaptButtonDisabling(mapComponent)
    this.removeAllButtonActivity()
    mapComponent.drawingAvalanche = false
    mapComponent.drawingStartZone = false
    mapComponent.drawingDeposit = false
    mapComponent.drawingPoint = false
  }
  removeAllButtonActivity() {
    for (let j = 0; j < this.buttons.length; j++) {
      this.buttons[j].classList.remove('active')
    }
  }
  // eslint-disable-next-line complexity
  adaptButtonDisabling(mapComponent) {
    if (
      mapComponent.avalancheGeo ||
      mapComponent.coord ||
      (mapComponent.startZoneGeo && mapComponent.depositGeo)
    ) {
      this.setDisabled(true)
    } else if (mapComponent.startZoneGeo) {
      this.disableButton(2, true)
      this.disableButton(1, true)
      this.disableButton(0, true)
      this.disableButton(3, false)
    } else if (mapComponent.depositGeo) {
      this.disableButton(3, true)
      this.disableButton(1, true)
      this.disableButton(0, true)
      this.disableButton(2, false)
    } else {
      this.setDisabled(false)
    }
  }
  setNotActiveAndRemoveInteractions() {
    this.removeAllButtonActivity()
    this.getAvalMapCompOlMap().removeInteraction(this.draw)
  }
}

export class WindRoseDimensions {
  size: number
  center: number
  centerDistanceLong: number
  centerDistanceShort: number
  labelDistance: number
  labelFontSize: number

  constructor(size: number) {
    this.size = size
    this.center = size / 2
    this.centerDistanceLong = this.center
    this.centerDistanceShort = 0.7 * this.center
    this.labelDistance = 0.6 * this.center
    this.labelFontSize = this.size * 0.14
  }
}

export const allAspects = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'] as const
export type Aspect = (typeof allAspects)[number]

export interface WindRoseProps {
  aspects: Aspect[]
}

export interface Point {
  x: number
  y: number
}

<template>
  <div class="mapContainer">
    <CommonLayersPopup context="input"></CommonLayersPopup>
    <div id="inputMapPanel" class="mapPanel"></div>
    <span v-show="false">
      <font-awesome-icon
        :ref="faSolidFaCrosshairs"
        :icon="faSolidFaCrosshairs"
      />
      <font-awesome-icon
        :ref="faSolidFaLayerGroup"
        :icon="faSolidFaLayerGroup"
      />
    </span>
  </div>
</template>

<script>
import { Vector as VectorSource } from 'ol/source.js'
import ObservationMap from '../../scripts/observationInputMap/ObservationMap.js'
import MapProjection from '../../scripts/MapProjection.js'
import { getPositionControl } from '@/scripts/common/commonControls'
import CommonLayersPopup from '../CommonLayersPopup'
import { AdditionalLayer, BaseLayer } from '@/model/map/commonLayers'
import {
  FA_SOLID_FA_CROSSHAIRS,
  FA_SOLID_FA_LAYER_GROUP,
} from '@/plugins/fontawesomeConsts'
import emitter from '@/components/EventHub'

export default {
  name: 'MapComponent',
  components: { CommonLayersPopup },
  props: ['obs', 'readOnly', 'adaptMap'],
  data() {
    return {
      map: null,
      multi: true,
      mobile: false,
      showTwoFingerMessage: false,
      drawingPoint: false,
      coord: null,
      drawnSource: null,
      preexistingSource: null,
      projection: null,
      daysSelected: 3,
      options: [
        { value: 1, text: '1' },
        { value: 3, text: '3' },
        { value: 7, text: '7' },
      ],
      faSolidFaCrosshairs: FA_SOLID_FA_CROSSHAIRS,
      faSolidFaLayerGroup: FA_SOLID_FA_LAYER_GROUP,
    }
  },
  watch: {
    baseLayer: {
      handler: 'updateBaseLayer',
      immediate: true,
    },
    additionalLayers: {
      handler: 'updateAdditionalLayers',
      immediate: true,
      deep: true,
    },
  },
  computed: {
    baseLayer() {
      return this.$store.state.commonLayers.baseLayer
    },
    additionalLayers() {
      return this.$store.state.commonLayers.additionalLayers
    },
  },
  created() {
    if (this.$store.state.mobilePlatform) {
      this.mobile = true
    }
  },
  // eslint-disable-next-line complexity
  mounted() {
    if (this.$props.obs && this.$props.obs.coord) {
      this.coord = this.$props.obs.coord
    }
    if (!this.$props.readOnly) {
      emitter.on('soft-reset', this.softReset)
    }

    this.projection = new MapProjection().getOldProjection()
    this.drawnSource = new VectorSource({ wrapX: false })
    // this.preexistingSource = new VectorSource({wrapX: false})
    this.initializeMap()
    // set point drawing active
    if (
      !this.coord &&
      !(
        this.$store.state.observation.location &&
        this.$store.state.observation.location.locationDate ===
          this.$store.state.observation.date
      )
    ) {
      this.drawingPoint = true
    }
  },
  beforeUnmount() {
    if (!this.$props.readOnly) {
      emitter.off('soft-reset')
    }
  },
  methods: {
    initializeMap() {
      this.map = new ObservationMap(this, this.mobile ? 12 : 15, {
        [FA_SOLID_FA_CROSSHAIRS]: this.$refs[FA_SOLID_FA_CROSSHAIRS]?.$el,
        [FA_SOLID_FA_LAYER_GROUP]: this.$refs[FA_SOLID_FA_LAYER_GROUP]?.$el,
      })
      if (this.mobile) {
        getPositionControl(this.map.getControls()).setSwissZoom(12)
      } else {
        this.map.getView().setMinZoom(14)
      }
      this.updateBaseLayer()
      this.updateAdditionalLayers()
    },
    addFeature(geometry) {
      // !!! geometry is the original feature, transform only copies!
      const json = {}
      const geometryCopy = geometry.clone()
      json.coordinates = geometryCopy
        .transform('EPSG:21781', 'EPSG:4326')
        .getCoordinates()
      json.type = 'Point'
      this.$emit('coord', json)
      this.coord = this.$props.obs.coord
    },
    reset() {
      this.coord = null
    },
    setCoord(val) {
      this.coord = val
      this.map.drawPresentFeatures()
    },
    updateBaseLayer() {
      for (const layerName of Object.values(BaseLayer)) {
        const visible = layerName === this.baseLayer
        this.map?.setCommonLayerVisibility(layerName, visible)
      }
    },
    updateAdditionalLayers() {
      for (const layerName of Object.values(AdditionalLayer)) {
        const visible = this.additionalLayers.includes(layerName)
        this.map?.setCommonLayerVisibility(layerName, visible)
      }
    },
  },
}
</script>

<style scoped>
.mapPanel :deep(.position) {
  top: 74px;
  left: 10px;
}

.mapPanel :deep(.position-mobile) {
  top: 5px;
  left: 5px;
  padding: 0;
}

.mapPanel :deep(.common-layers-control) {
  top: 132px;
  left: 8px;
  padding: 0;
}

.mapPanel :deep(.common-layers-control-mobile) {
  top: 38px;
  left: 5px;
  padding: 0;
}

.mapPanel :deep(canvas) {
  display: block !important;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.row {
  margin-left: 0;
  margin-top: 10px;
}
.mapContainer {
  width: 100%;
  height: 550px;
  position: relative;
  background-color: rgb(255, 255, 255);
}

.mapPanel :deep(.active) {
  background-color: #007bff !important;
}

.mapPanel :deep(.ol-control) {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.mapPanel :deep(.ol-zoom) {
  width: 30px;
  padding: 0;
}

.mapPanel :deep(.ol-attribution) {
  height: 19px;
  width: 70px;
  display: block !important;
  padding: 0;
}

.mapPanel :deep(.ol-attribution ul) {
  padding: 2px;
}

.mapPanel :deep(.ol-attribution li) {
  float: left;
  width: max-content;
}

.mapPanel :deep(.ol-zoom button) {
  background-color: #fff !important;
  color: rgba(40, 40, 40, 0.7);
  padding-bottom: 3px;
}

.mapPanel :deep(.ol-zoom-in) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.mapPanel :deep(button) {
  height: 28px;
  background-color: #fff;
  width: 28px;
  margin: 0;
  color: rgba(40, 40, 40, 0.7);
}

.mapPanel {
  height: 100%;
}

.mapPanel :deep(button:disabled) {
  background-color: #e4e4e0 !important;
}

.mapPanel :deep(button:focus) {
  outline: 0;
}

.mapPanel :deep(.position) {
  top: 70px;
  left: 0.5em;
  padding: 0;
  background-color: #fff;
  width: 30px;
}

.mapPanel :deep(.button-switzerland) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #fff;
}
</style>

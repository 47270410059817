import moment from 'moment'
import axios from 'axios'
import BaseService from '@/services/BaseService'
import ObserverGroupValidator from '@/validation/observerGroupValidator.js'
import { enums } from '@/components/I18N.js'

const CREATOR_SYSTEM_WORKBENCH = enums.creatorSystem[0]
const CancelToken = axios.CancelToken

// Helpers
function getCreatorSystemHeader() {
  return {
    headers: { 'ch.slf.pro.system': CREATOR_SYSTEM_WORKBENCH },
  }
}
export function validateStorMeTag(avalanche) {
  // Das storMe-Flag entfernen, falls es nicht den Wert 'storMe' hat
  if (avalanche?.tags && avalanche.tags.storMe !== 'storMe') {
    delete avalanche.tags.storMe
  }
}

export default class avalancheService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('AVALANCHE_SERVICE_URL', 10000)
    return this.srv.getInstance()
  }
  static async createSingleAvalanche(avalanche) {
    await ObserverGroupValidator.validateAvalanche(avalanche).then((result) => {
      if (!result) {
        ObserverGroupValidator.rejectSave()
      }
    })
    validateStorMeTag(avalanche)
    return this.getInstance().post(
      'single-avalanches/',
      avalanche,
      getCreatorSystemHeader()
    )
  }
  static updateSingleAvalanche(avalanche) {
    validateStorMeTag(avalanche)
    return this.getInstance().put(
      'single-avalanches/' + avalanche.id,
      avalanche,
      getCreatorSystemHeader()
    )
  }
  static getSingleAvalanche(id) {
    return this.getInstance().get('single-avalanches/' + id)
  }
  static async createSeveralAvalanche(avalanche) {
    await ObserverGroupValidator.validateAvalanche(avalanche).then((result) => {
      if (!result) {
        ObserverGroupValidator.rejectSave()
      }
    })
    return this.getInstance().post(
      'several-avalanches/',
      avalanche,
      getCreatorSystemHeader()
    )
  }
  static updateSeveralAvalanche(avalanche) {
    return this.getInstance().put(
      'several-avalanches/' + avalanche.id,
      avalanche,
      getCreatorSystemHeader()
    )
  }
  static getSeveralAvalanche(id) {
    return this.getInstance().get('several-avalanches/' + id)
  }

  static getAvalanchesByTriggerDateAndCreateDateTimeIntervalAndOptObserver(
    lowerDateTime,
    upperDateTime,
    lowerCreateTime,
    upperCreateTime,
    userId,
    cancel
  ) {
    const lower = moment(lowerDateTime).utc().format('YYYY-MM-DDTHH:mm:ss')
    const upper = moment(upperDateTime).utc().format('YYYY-MM-DDTHH:mm:ss')
    const lowerCreate = moment(lowerCreateTime)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')
    const upperCreate = moment(upperCreateTime)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')

    if (userId) {
      return this.getInstance()
        .get(
          'avalanches?triggerDateTimeRange=' +
            encodeURIComponent(lower + '/' + upper) +
            '&creationDateTimeRange=' +
            encodeURIComponent(lowerCreate + '/' + upperCreate) +
            '&observer=' +
            userId,
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    } else {
      return this.getInstance()
        .get(
          'avalanches?triggerDateTimeRange=' +
            encodeURIComponent(lower + '/' + upper) +
            '&creationDateTimeRange=' +
            encodeURIComponent(lowerCreate + '/' + upperCreate),
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    }
  }

  static getAvalanchesByCreationDateIntervalAndOptObserver(
    lowerDateTime,
    upperDateTime,
    userId,
    cancel
  ) {
    // moment date object expected
    const lower = moment(lowerDateTime).utc().format('YYYY-MM-DDTHH:mm:ss')
    const upper = moment(upperDateTime).utc().format('YYYY-MM-DDTHH:mm:ss')

    if (userId) {
      return this.getInstance()
        .get(
          'avalanches?creationDateTimeRange=' +
            encodeURIComponent(lower + '/' + upper) +
            '&observer=' +
            userId,
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    } else {
      return this.getInstance()
        .get(
          'avalanches?creationDateTimeRange=' +
            encodeURIComponent(lower + '/' + upper),
          {
            cancelToken: new CancelToken(function executor(c) {
              if (cancel) {
                cancel.cancel = c
              }
            }),
          }
        )
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.info('Request canceled')
          } else {
            throw thrown
          }
        })
    }
  }
}

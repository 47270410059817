import BaseService from '@/services/BaseService'

export default class observerService extends BaseService {
  static srv = undefined
  static getInstance() {
    this.srv = this.srv || new BaseService('OBSERVER_SERVICE_URL', 5000)
    return this.srv.getInstance()
  }
  static getUserGroupsForUser(userid) {
    return this.getInstance().get('groups?userId=' + userid)
    // return observerInstance.get('groups?userId=52fa812a-135f-4745-d3aa-0635a858ea88') // observer id for ruinettes resp. for test
    /* var groupFake = [
      { 'id': '0821fe61-0d1c-4c62-8f5c-2402cd08ca61', 'station': '1MR', 'name': 'Mürren', 'region': '1233', 'legacy': 'VG', 'lead': 'Peter Bühler', 'contract': 'FIRMA', 'compensation': 'Schilthornbahn AG', 'users': [ { 'id': '19E36666-7B0A-4B83-6959-6DBF69F14BB9', 'lastname': 'Bühler', 'firstname': 'Peter' }, { 'id': '306D0CF2-C64F-4A35-23D2-9026A27B9A3E', 'lastname': 'von Allmen', 'firstname': 'Robert' } ] },
      { 'id': '802c7448-2564-488b-bb69-00d69febcb38', 'station': '', 'name': 'Region-Anzère', 'region': '4121', 'legacy': 'RB', 'lead': 'Philippe Fardel', 'contract': 'PRIVAT', 'compensation': '', 'users': [ { 'id': '54985830-5977-469E-64C5-185B6B682AD6', 'lastname': 'Fardel', 'firstname': 'Philippe' }, { 'id': '1469F146-6C44-4606-C271-1918EDDFC163', 'lastname': 'Morard', 'firstname': 'Emil' } ] },
      { 'id': '30059de4-d256-4bd0-a27d-4bcf5b0fc73c', 'station': '', 'name': 'Kanton Schwyz', 'region': '2131', 'legacy': 'LD', 'lead': 'Lukas Inderbitzin', 'contract': '', 'compensation': '', 'users': [ { 'id': '034AFCFF-0F0D-4875-3895-9A9FC0F193CE', 'lastname': 'Inderbitzin', 'firstname': 'Lukas' } ] },
      { 'id': '8e63c4a2-d754-40a6-903b-ac5129de8fb6', 'station': '', 'name': 'Region_Trient', 'region': '4111', 'legacy': 'LD', 'lead': 'Nicolas Vouilloz', 'contract': '', 'compensation': '', 'users': [ { 'id': 'A0DAD7DE-8D13-4F7E-6894-0D70E5B10B09', 'lastname': 'Vouilloz', 'firstname': 'Nicolas' }, { 'id': '46838011-18D0-4AF9-AB73-169E63D8D55A', 'lastname': 'Lugon', 'firstname': 'Jean-Luc' }, { 'id': '708E1127-A98E-4ED6-9D01-4D85C77A0E62', 'lastname': 'Burnet', 'firstname': 'Mathieu' } ] }
    ]
    return Promise.resolve({data: groupFake}) */
  }
}

'use strict'

import proj4 from 'proj4'
import { register } from 'ol/proj/proj4.js'
import { get as getProjection } from 'ol/proj'

class MapProjection {
  constructor() {
    proj4.defs(
      'EPSG:21781',
      '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 ' +
        '+k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
    )
    proj4.defs(
      'EPSG:2056',
      '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
    )

    // NOTE (20191101/csz): there is a later version: proj4 2.5.0 after 2.4.4 solving some bugs
    // - in case of an upgrade, need to test thoroughly since OpenLayers might rely on these old bugs
    //   and thus might introduce unexpected behavior
    // - => no proj4 upgrade now

    // NOTE (20191101/csz): For better accuracy with proj4 you would need a grid shift file. As usage
    // on swisstopo site not explained and it is not clear, will not use it here
    // => approx. 1.6m inaccuracy possible for 1 trafo
    // => should get rid of 21781 once maps in 2056 to avoid double conversions (text-wgs84-map)
    /* Extract: Transformation from EPSG:21781 to EPSG:2056 (LV03/CH1903 to LV95/CH1903+):
    +proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +nadgrids=CHENYX06a.gsb +units=m +no_defs
    +to +proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +nadgrids=@null +units=m +no_defs

    Transformation from EPSG:2056 to EPSG:4326 (LV95/CH1903+ to WGS84):
    +proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +ellps=bessel +x_0=2600000 +y_0=1200000 +towgs84=674.374,15.056,405.346,0,0,0,0 +k_0=1.0 +units=m +no_defs
    +to +proj=longlat +datum=WGS84 +no_defs

    Transformation from EPSG:21781 to EPSG:4326 (LV03/CH1903 to WGS84):
    +proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +units=m +nadgrids=chenyx06etrs.gsb +no_defs
    +to +proj=longlat +datum=WGS84 +no_defs */

    register(proj4)
    this.oldProjection = getProjection('EPSG:21781')
  }
  getOldProjection() {
    return this.oldProjection
  }
}

export default MapProjection

'use strict'

import { AbstractStyle, GEFAHREN_INDEX_COLORS } from '../AbstractStyle'
import { WhumpfCrackStyle } from './WhumpfCrackStyle'
import { SnowprofileStyle } from './SnowprofileStyle'
import { SNOWPROFILE, WHUMPF_CRACKS } from '@/scripts/const.js'

const wuCrackStyle = new WhumpfCrackStyle(undefined, undefined)
const snowprofileStyle = new SnowprofileStyle(undefined, undefined)

export class DangersignAvalancheStyle extends AbstractStyle {
  styleFunction(feature, resolution) {
    let dangersignStyles = []
    if (feature.get(WHUMPF_CRACKS)) {
      dangersignStyles = wuCrackStyle.styleFunction(feature, resolution, true)
    }
    if (feature.get(SNOWPROFILE)) {
      dangersignStyles = snowprofileStyle.styleFunction(feature)
    }
    return dangersignStyles
  }

  legend() {
    const rutschblockLegends = snowprofileStyle.getLegendRectangles()
    return {
      titleSymbs: [
        {
          icon: 'fa-regular fa-smile',
          color: GEFAHREN_INDEX_COLORS[0],
        },
        {
          icon: 'fa-regular fa-meh',
          color: GEFAHREN_INDEX_COLORS[1],
        },
        {
          icon: 'fa-regular fa-frown',
          color: GEFAHREN_INDEX_COLORS[2],
        },
      ],
      items: [
        {
          label: 'dangersign.label.whumpfCracks',
          symbol: wuCrackStyle.getLegendExclamationWhite(),
        },
        {
          label: 'snowprofile.label.rutschblock',
          symbol: rutschblockLegends[0],
        },
        {
          label: 'snowprofile.label.rutschblockSmall',
          symbol: rutschblockLegends[1],
        },
        { label: 'snowprofile.label.ect', symbol: rutschblockLegends[2] },
      ],
    }
  }
  mouseOverFunction(feature) {
    // MouseOver von den anderen Styles holen
    const whumpfCracksFeature = feature.get(WHUMPF_CRACKS)
    if (whumpfCracksFeature) {
      return wuCrackStyle.mouseOverFunction(feature)
    } else {
      return snowprofileStyle.mouseOverFunction(feature)
    }
  }
}

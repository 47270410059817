import BaseService from '@/services/BaseService'

const timeout = 10 * 60 * 1000 // 10 Minutes

export default class reportService extends BaseService {
  static srv = undefined
  static getInstance() {
    this.srv =
      this.srv ||
      new BaseService('REPORT_SERVICE_URL', timeout, undefined, 'blob')
    return this.srv.getInstance()
  }
  static createPdfReport(reportRawData) {
    return this.getInstance().post('pdf/journal', reportRawData)
  }
}

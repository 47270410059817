<template><span /></template>

<script>
import emitter from '@/components/EventHub'

export default {
  name: 'DetailModalMeasurement',
  props: ['measurement'],
  watch: {
    measurement: function () {
      emitter.emit('showMeasurement', { measurement: this.measurement })
    },
  },
}
</script>

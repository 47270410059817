<template>
  <div>
    <ProblemLegend class="problem-legend" :legend="legend" />
    <CommonLayersPopup />
    <b-alert
      :show="alertCountDown"
      dismissible
      variant="info"
      @dismissed="alertCountDown = 0"
      @dismiss-count-down="alertCountDownChanged"
      class="alert-text"
      >{{ alertText }}
    </b-alert>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..." />
    </div>
    <DetailModal det-id="detailModal" :obsIds="obsIds" :aggregate="aggregate" />
    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assm"
      :aggregate="true"
    />
  </div>
</template>

<script>
import ProblemLegend from './ProblemLegend'
import DetailModal from '../observation/DetailModal'
import { ProblemStyle } from '../styles/dangerStyling/ProblemStyle'
import { i18nMixin } from '../I18N'
import { Canceler } from '@/scripts/requestUtil.js'
import { dangerDetailMixin } from '@/components/danger/dangerDetailMixin'
import emitter from '@/components/EventHub'
import { deepClone } from '@/scripts/common'
import DetailModalAssessment from '@/components/assessment/detail/DetailModalAssessment.vue'
import util from '@/scripts/util'
import { DistributionAuxStyle } from '@/components/styles/dangerStyling/DistributionAuxStyle'
import CommonLayersPopup from '@/components/CommonLayersPopup.vue'

const CancelNormal = Canceler.getCancelObj()

export default {
  props: ['moment', 'calendar'],
  mixins: [i18nMixin, dangerDetailMixin],
  watch: {
    moment: function () {
      if (this.hasDateSelectionChanged()) {
        this.lastMoment = this.moment
        this.lastDatePreset = this.$store.state.calendar.preset
        this.refresh()
      }
    },
  },
  name: 'ProblemMap',
  components: {
    DetailModalAssessment,
    ProblemLegend,
    DetailModal,
    CommonLayersPopup,
  },
  data() {
    return {
      isAdmin: this.$store.state.user.user.isAdmin,
      clientRoles: this.$store.state.user.user.clientRoles,
      legend: new ProblemStyle(this.$i18n.locale).legend(),
      aggregate: true,
      obsIds: [],
      assm: {},
      filter: {}, // current filter selection incl. productVariant
      alertSecs: 1,
      alertCountDown: 0,
      alertText: null,
      isMoving: false,
      isRefreshing: false,
      lastMoment: undefined,
      lastDatePreset: undefined,
    }
  },
  beforeUnmount() {
    emitter.off('update::filter')
    emitter.off('repos::map')

    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests('normal')
  },
  mounted() {
    // for refresh in case of variant group change not handled by menuvariant...
    this.$nextTick(function () {
      emitter.emit('update::filter', {
        productVariant: this.$route.meta.defaultProductGroupVariant,
        own: true,
      })
    })

    /*eslint-disable complexity*/
    emitter.on('update::filter', (filter) => {
      // NOTE: for productVariant, react only on those of type 'problem'
      let isRightVariant = false
      let refresh = false
      if (
        filter &&
        filter.productVariant &&
        filter.productVariant.startsWith('problem.')
      ) {
        if (
          filter.own === true ||
          (this.filter.productVariant &&
            this.filter.productVariant !== filter.productVariant)
        ) {
          // ignore events coming from menuvariant, except for variant changes
          refresh = true
        }
        this.filter.productVariant = filter.productVariant
        isRightVariant = true

        emitter.emit('updateProblemLegend', filter.productVariant)
      }
      if (isRightVariant && refresh) {
        this.refresh()
      }
    })
    /*eslint-enable complexity*/
    emitter.on('repos::map', (flag) => {
      this.isMoving = flag && flag === true
    })
  },
  computed: {
    isLoading() {
      return this.isMoving || this.isRefreshing
    },
  },
  methods: {
    findAndShowObservation(observationId, featureCollection) {
      const feature = featureCollection.features.find((item) => {
        return item.properties.id === observationId
      })
      if (feature) {
        const isAssessment = util.isAssessmentContent(
          feature.properties,
          'danger'
        )
        if (isAssessment) {
          // Objekt klonen, damit der Watcher eine Änderung entdeckt
          this.assm = Object.assign({}, feature.properties)
        } else {
          this.obsIds = []
          this.obsIds.push(observationId)
        }
      }
    },
    /*eslint-disable complexity, no-param-reassign*/
    applyProblemFilter(features, filter) {
      let filteredFeatures = features
      // filter delivered features based on productVariant
      if (filter && filteredFeatures) {
        if (filter.productVariant) {
          // NOTE: since criteria/definitions might change, inner function for filter
          switch (filter.productVariant) {
            case 'problem.productVariantNewSnow':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const pattern = feature.properties.danger.pattern
                if (pattern) {
                  if (pattern.includes('NEW_SNOW')) {
                    feature.properties.danger.problemC = true
                    return true
                  } else {
                    if (
                      !pattern.includes('NEW_SNOW') &&
                      (pattern.includes('SNOWDRIFT') ||
                        pattern.includes('OLD_SNOW') ||
                        pattern.includes('UNDEFINED'))
                    ) {
                      feature.properties.danger.problemC = false
                      return true
                    }
                  }
                }
                return false
              })
              break
            case 'problem.productVariantSnowDrift':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const pattern = feature.properties.danger.pattern
                if (pattern) {
                  if (pattern.includes('SNOWDRIFT')) {
                    feature.properties.danger.problemC = true
                    return true
                  } else {
                    if (
                      !pattern.includes('SNOWDRIFT') &&
                      (pattern.includes('NEW_SNOW') ||
                        pattern.includes('OLD_SNOW') ||
                        pattern.includes('UNDEFINED'))
                    ) {
                      feature.properties.danger.problemC = false
                      return true
                    }
                  }
                }
                return false
              })
              break
            case 'problem.productVariantOldSnow':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const pattern = feature.properties.danger.pattern
                if (pattern) {
                  if (pattern.includes('OLD_SNOW')) {
                    feature.properties.danger.problemC = true
                    return true
                  } else {
                    if (
                      !pattern.includes('OLD_SNOW') &&
                      (pattern.includes('NEW_SNOW') ||
                        pattern.includes('SNOWDRIFT') ||
                        pattern.includes('UNDEFINED'))
                    ) {
                      feature.properties.danger.problemC = false
                      return true
                    }
                  }
                }
                return false
              })
              break
            case 'problem.productVariantUndefined':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const pattern = feature.properties.danger.pattern
                if (pattern) {
                  if (pattern.includes('UNDEFINED')) {
                    feature.properties.danger.problemC = true
                    return true
                  } else {
                    if (
                      !pattern.includes('UNDEFINED') &&
                      (pattern.includes('NEW_SNOW') ||
                        pattern.includes('SNOWDRIFT') ||
                        pattern.includes('OLD_SNOW'))
                    ) {
                      feature.properties.danger.problemC = false
                      return true
                    }
                  }
                }
                return false
              })
              break
            case 'problem.productVariantWetSnow':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const problem = feature.properties.danger.problem
                if (problem === 'WET') {
                  feature.properties.danger.problemC = true
                  return true
                } else if (problem === 'FULL_DEPTH') {
                  feature.properties.danger.problemC = false
                  return true
                }
                return false
              })
              break
            case 'problem.productVariantFullDepth':
              filteredFeatures = filteredFeatures.filter(function (feature) {
                const problem = feature.properties.danger.problem
                if (problem === 'FULL_DEPTH') {
                  feature.properties.danger.problemC = true
                  return true
                } else if (problem === 'WET') {
                  feature.properties.danger.problemC = false
                  return true
                }
                return false
              })
              break
          }
        }
      }
      return filteredFeatures
    },
    /*eslint-enable complexity, no-param-reassign*/
    alertCountDownChanged(alertCountDown) {
      this.alertCountDown = alertCountDown
    },
    refresh() {
      this.isRefreshing = true

      console.debug('Cancel requests on load')
      this.cancelRequests('normal')

      this.style = new ProblemStyle(this.$i18n.locale)
      this.styleFunction = this.style.styleFunction
      this.style.setVariant(this.filter.productVariant)
      this.style.setFilter(this.$store.state.calendar)
      this.distStyle = new DistributionAuxStyle(this.$i18n.locale)
      this.mouseOverFunction = this.distStyle.mouseOverFunction
      const self = this

      this.$store
        .dispatch(
          'danger/loadDangerObservationFeaturesByObservationDateTimeRange',
          {
            observationDateTimeRange: this.calendar.getPresetInterval(),
            isAdmin: this.isAdmin,
            cancel: CancelNormal,
          }
        )
        .then(() => {
          const featureCollection = deepClone(
            self.$store.state.danger.dangerFeatureCollection
          )
          featureCollection.features = self.applyProblemFilter(
            featureCollection.features,
            self.filter
          ) // NOTE: filter based on productVariant

          const problemClickHandler = function (feature) {
            if (feature) {
              const problemFeature = featureCollection.features.find((item) => {
                return item.properties.id === feature.get('id')
              })
              if (problemFeature) {
                const isAssessment = util.isAssessmentContent(
                  problemFeature.properties,
                  'danger'
                )
                if (isAssessment) {
                  // Objekt klonen, damit der Watcher eine Änderung entdeckt
                  self.assm = Object.assign({}, problemFeature.properties)
                  self.aggregate = true
                  self.$bvModal.show('detailModal')
                } else {
                  self.obsIds = []
                  self.obsIds.push(feature.get('id'))
                  self.aggregate = true
                  self.$bvModal.show('detailModalAssessment')
                }
              }
            }
          }
          const resolutionChangeHandler = function (
            resolution,
            layers,
            spider
          ) {
            spider.resolutionChangeHandler(
              layers['problem'].vectorLayer,
              resolution
            )
          }

          emitter.emit('layer', {
            name: 'problem',
            features: featureCollection,
            styleFunction: self.styleFunction,
            onClick: problemClickHandler,
            style: self.style,
            supportLayer: false,
            onResolutionChange: resolutionChangeHandler,
          })
          emitter.emit('mouseOver', self.mouseOverFunction)
          if (self.$route.query.openObs) {
            self.findAndShowObservation(
              self.$route.query.openObs,
              featureCollection
            )
          }
          self.isRefreshing = false
        })
        .catch((e) => {
          console.error(
            'Catch request loadDangerObservationFeaturesByObservationDateTimeRange: ' +
              e
          )
          alert(e)
          self.isRefreshing = false
        })
      CancelNormal.addCancel(CancelNormal.cancel)
    },
    hasDateSelectionChanged() {
      return (
        !this.lastMoment ||
        !this.lastMoment.isSame(this.moment) ||
        !this.lastDatePreset ||
        !(this.lastDatePreset === this.$store.state.calendar.preset)
      )
    },
    cancelRequests(layerType) {
      switch (layerType) {
        case 'normal':
          CancelNormal.cancelAll()
          break
      }
    },
  },
}
</script>

<style scoped>
img {
  width: 600px;
}

.problem-legend {
  width: fit-content;
}

.legend_mobile {
  top: 6.9em;
}

.alert-text {
  z-index: 100;
}

.overlay .alert {
  position: absolute;
  top: 0;
  width: 100%;
}

.spinner-center {
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 60%;
}
</style>

'use strict'

import BaseObject from '@/model/assessment/BaseObject'
import Path from '@/model/assessment/Path'
import Building from '@/model/assessment/Building'
import Area from '@/model/assessment/Area'
import AvalancheSafetyService from '@/model/assessment/AvalancheSafetyService'

export default class Mapper {
  static mapLocation(location) {
    return { type: 'Point', coordinates: [location.y, location.x] }
  }
  static mapArea(el) {
    return new Area(
      el.id,
      el.name,
      Mapper.mapLocation(el.location),
      el.active,
      el.warningLight,
      Mapper.mapObject(el.sectors, BaseObject),
      Mapper.mapObject(el.paths, Path),
      Mapper.mapObject(el.buildings, Building),
      Mapper.mapObject(el.avalanchePaths, BaseObject)
    )
  }
  static mapAvalancheSafetyService(id, name, areas) {
    return new AvalancheSafetyService(id, name, areas.map(Mapper.mapArea))
  }
  static mapObject(arr, clazz) {
    return arr?.map((o) => Mapper.mapClazz(o, clazz)) || []
  }
  static mapClazz(o, clazz) {
    return new clazz(
      o.id,
      o.name,
      Mapper.mapLocation(o.location),
      o.active,
      o.type
    )
  }
}

import moment from 'moment'
import { TRIGGERING, AVALPATH_TO_TRIGGERING } from '@/scripts/const.js'
import observationService from '@/services/observationService.js'
import { deepClone } from '@/scripts/common'

// state
const state = {
  // Beinhaltet keine Triggerings, welche ein anderes Triggering löschen (planned leer)
  triggerings: [],
}

// getters
const getters = {
  /*
    Bereitet ein Array aus neuen Objekten auf. Ein solches einzelnes Objekt vereint eine 'künstliche Lawinenauslösung' und einen Lawinenzug.
    Übergeben wird ein Array aus 'künstliche Lawinenauslösungen'
    Zurückgegeben wird ein Array mit den neuen Objekten
  */
  getTriggeringsWithAvalpaths:
    (state, getters, rootState, rootGetters) => (latestTriggeringObs) => {
      const triggers = []
      const avalPaths = deepClone(rootGetters['profile/getAllAvalanchePaths'])
      latestTriggeringObs.forEach((triggeringObs) => {
        if (triggeringObs) {
          const resultObject = avalPaths.find(
            (avalPath) =>
              avalPath.id === triggeringObs.hierarchy.avalanchePathId
          )
          if (resultObject) {
            resultObject[TRIGGERING] = triggeringObs.triggering
            resultObject.ts = triggeringObs.cTimestamp
            resultObject.cTimestamp = triggeringObs.cTimestamp
            resultObject.uid = triggeringObs.uid
            triggers.push(resultObject)
          }
        }
      })
      return triggers
    },
  getTriggeringForDetailModal: (state, getters) => (triggeringObs) => {
    if (!triggeringObs) {
      return {}
    }
    const triggeringsWithAvalpaths = getters['getTriggeringsWithAvalpaths']([
      triggeringObs,
    ])
    const triggeringDetail = {
      id: triggeringObs.id,
      cTimestamp: moment(triggeringObs.cTimestamp).unix() * 1000,
      type: TRIGGERING,
      uid: triggeringObs.uid,
      triggering: triggeringObs.triggering,
      hierarchy: triggeringObs.hierarchy,
      location: triggeringObs.location,
      creatorSystem: triggeringObs.creatorSystem,
    }
    if (triggeringsWithAvalpaths && triggeringsWithAvalpaths.length > 0) {
      triggeringDetail[AVALPATH_TO_TRIGGERING] = triggeringsWithAvalpaths[0]
    }
    return triggeringDetail
  },
}

// actions
const actions = {
  addTriggering(context, value) {
    const avalServiceId = context.rootGetters[
      'profile/getAssmAvalServiceToAvalanchePathId'
    ](value.hierarchy.avalanchePathId).id
    const location = context.rootGetters[
      'profile/getAvalanchePathLocationToId'
    ](value.hierarchy.avalanchePathId)
    const avalanchTriggeringToAdd = prepareAvalancheTriggering(
      value,
      avalServiceId,
      location
    )

    return context.dispatch(
      'assessmentObs/submitAssessmentObs',
      avalanchTriggeringToAdd,
      { root: true }
    )
  },
  // Das Resultat beinhaltet keine Triggerings, welche ein anderes Triggering löschen (planned leer)
  loadLatestTriggerings({ commit, state }, params) {
    commit('SET_TRIGGERINGS', [])
    return new Promise((resolve, reject) => {
      observationService
        .getLatestTriggerings(params.avalServiceIds, params.cancel)
        .then((latestTriggerings) => {
          commit('SET_TRIGGERINGS', latestTriggerings.data.data)
          commit('SET_EXCEPTION', null, { root: true })
          console.debug('latest triggerings loaded.')
          automaticTriggeringStateChange(commit, state)
          const latestReleasedTriggerings = state.triggerings.reduceRight(
            (latest, item) =>
              latest.find(
                (el) =>
                  el.hierarchy.avalanchePathId ===
                  item.hierarchy.avalanchePathId
              )
                ? latest
                : [...latest, item],
            []
          )
          resolve(latestReleasedTriggerings)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_TRIGGERINGS(state, triggerings) {
    // Triggerings welche eine andere Triggering löschen, werden hier entfernt
    state.triggerings = triggerings.filter((el) => el.triggering.planned)
  },
  ADD_TRIGGERINGS(state, triggerings) {
    state.triggerings = state.triggerings.concat(triggerings)
  },
}

// Helper-Functions
const AUT_RELEASE_INTERVAL_ACTIVE = 6

function automaticTriggeringStateChange(commit, state) {
  const addedTriggerings = []

  state.triggerings.forEach((triggerObs) => {
    // Wenn für eine künstliche Lawinenauslösung eine Sprengmethode gesetzt ist, wird sie nach einer gewissen Anzahl Stunden gelöscht
    const rightNow = moment()
    const triggerEle = triggerObs.triggering
    const elapsed =
      triggerEle.planned &&
      triggerEle.blastingMethod &&
      rightNow.isAfter(
        moment(triggerEle.planned).add(AUT_RELEASE_INTERVAL_ACTIVE, 'hours')
      )
    if (elapsed) {
      const releasedTriggerObs = {
        type: TRIGGERING,
      }
      const hierarchy = {
        avalanchePathId: triggerObs.hierarchy.avalanchePathId,
        avalServiceId: triggerObs.hierarchy.avalServiceId,
      }
      const triggering = {
        blastingMethod: null,
        planned: null,
        executor: null,
        reason: null,
      }
      releasedTriggerObs.hierarchy = hierarchy
      releasedTriggerObs.triggering = triggering
      releasedTriggerObs.location = location
      releasedTriggerObs.uid = triggerObs.uid
      releasedTriggerObs.timestamp = moment()

      addedTriggerings.push(releasedTriggerObs)
    }
  })
  commit('ADD_TRIGGERINGS', addedTriggerings)
}
function prepareAvalancheTriggering(value, avalServiceId, location) {
  const triggeringObs = {
    type: TRIGGERING,
  }
  const hierarchy = {
    avalanchePathId: value.hierarchy.avalanchePathId,
    areaId: value.hierarchy.areaId,
    avalServiceId: avalServiceId,
  }
  const triggering = {
    blastingMethod: value.form.blastingMethod,
    planned: value.form.planned,
    executor: value.form.executor,
    reason: value.form.reason,
    parentId: value.form.parentId,
  }
  triggering.reason = triggering.reason === '' ? null : triggering.reason

  triggeringObs.hierarchy = hierarchy
  triggeringObs.triggering = triggering
  triggeringObs.location = location

  return triggeringObs
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

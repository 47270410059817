<template>
  <div v-if="visible" :class="{ legend: !mobile, legend_mobile: mobile }">
    <table
      v-if="
        $props.legend &&
        !legend.title &&
        !legend.titleSymbs &&
        !legend.isRosette
      "
    >
      <tr v-for="entry in legend" :key="entry.id">
        <td><img :src="entry.symbol.src" class="symbol" /></td>
        <td class="label">{{ entry.label }}</td>
      </tr>
    </table>

    <table
      class="legend-table"
      v-if="
        $props.legend && legend.title && !legend.titleSymbs && !legend.isRosette
      "
      :style="legend.tableStyle != null ? legend.tableStyle : ''"
    >
      <caption v-if="legend.title.count" class="legend-title">
        <span>{{
          $t(legend.title.title) +
          ' ' +
          $tc(legend.title.name, legend.title.count)
        }}</span>
        <span class="unit-span"> [{{ $t(legend.title.unit) }}]</span>
      </caption>
      <caption
        v-if="!legend.title.count && legend.title.unit"
        class="legend-title"
      >
        <span>{{ $t(legend.title.title) }}</span>
        <span class="unit-span"> [{{ $t(legend.title.unit) }}]</span>
      </caption>
      <caption
        v-if="!legend.title.count && !legend.title.unit"
        class="legend-title"
      >
        {{
          $t(legend.title)
        }}
      </caption>
      <tr v-for="entry in legend.items" :key="entry.id">
        <td v-if="entry.symbol">
          <img
            v-if="entry.symbol.src"
            :src="entry.symbol.src"
            class="symbol"
            :style="entry.style != null ? entry.style : ''"
          />
        </td>
        <td
          v-if="entry.symbol"
          class="label"
          :class="{
            'section-header': entry.header,
            'section-margin': entry.margin,
          }"
        >
          {{ $t(entry.label) }}
        </td>
        <td v-if="entry.line"></td>
        <td v-if="entry.line">
          <hr :class="{ thin: (entry.type = 'THIN') }" />
        </td>
      </tr>
    </table>

    <div
      v-if="$props.legend && !legend.title && legend.titleSymbs"
      style="text-align: left"
    >
      <span
        style="padding-right: 4px"
        v-for="entry in legend.titleSymbs"
        :key="entry.id"
      >
        <font-awesome-icon
          size="lg"
          :icon="entry.icon"
          :style="{ color: entry.color }"
        ></font-awesome-icon>
      </span>
    </div>
    <hr v-if="$props.legend && !legend.title && legend.titleSymbs" />
    <table v-if="$props.legend && !legend.title && legend.titleSymbs">
      <tr v-for="entry in legend.items" :key="entry.id">
        <td v-if="entry.symbol">
          <img :src="entry.symbol.src" class="symbol" />
        </td>
        <td v-if="entry.symbol" class="label">{{ $t(entry.label) }}</td>
        <td v-if="entry.line"></td>
        <td v-if="entry.line">
          <hr :class="{ thin: (entry.type = 'THIN') }" />
        </td>
      </tr>
    </table>

    <!-- Fuer die  Wumm/Riss-Legende, damit die Rosette schön angezeigt wird-->
    <table
      v-if="
        $props.legend && !legend.titleSymbs && legend.isRosette && legend.items
      "
    >
      <caption class="legend-title">
        {{
          $t(legend.title)
        }}
      </caption>
      <tr v-for="entry in legend.items" :key="entry.id">
        <td v-if="!entry.isRosette" style="vertical-align: top">
          <img :src="entry.symbol.src" class="symbol" />
        </td>
        <td v-if="entry.isRosette" style="vertical-align: top">
          <img :src="entry.symbol.src" class="symbol-rosette" />
        </td>
        <td class="label">{{ entry.label }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import emitter from '@/components/EventHub'

export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Legend',
  props: ['legend'],
  data() {
    return {
      visible: false,
      mobile: false,
    }
  },
  created() {
    if (this.$store.state.mobilePlatform === false) {
      // Legend neu initial auch auf Desktop geschlossen / SLPPWB-804 Legende per Default geschlossen
      this.visible = false
    } else {
      this.mobile = true
    }
  },
  mounted() {
    emitter.on('showLegend', (show) => {
      show ? (this.visible = true) : (this.visible = false)
    })
  },
  beforeUnmount() {
    emitter.off('showLegend')
  },
}
</script>

<style scoped>
.section-header {
  font-weight: bold;
}

.section-margin {
  height: 40px;
  vertical-align: bottom;
}

.symbol {
  width: 25px;
  margin-right: 1em;
}
.symbol-rosette {
  width: 50px;
  margin-right: 1em;
}
.legend-table {
  float: left;
  width: 100%;
  word-break: normal;
}
.label {
  font-size: 14px;
}

.legend-title {
  caption-side: top;
  font-weight: bolder;
  color: black;
  padding-top: 5px;
  padding-bottom: 0;
  white-space: normal;
}

.unit-span {
  white-space: nowrap;
}

hr {
  background-color: black;
  color: black;
  border: black;
  height: 1px;
  margin: 10px 0 10px 0;
}

hr.thin {
  margin: 1px 0 1px 0;
  background-color: lightgray;
}
</style>

<style>
.legend {
  top: 13.2em;
  border: 1px solid #929292;
  border-radius: 4px;
  width: 11em;
  opacity: 1;
  background-color: rgb(255, 255, 255);
  right: 0.7em;
  z-index: 50;
  display: inline-block;
  position: absolute;
  padding: 10px;
}

.legend_mobile {
  opacity: 1;
  border: 1px solid #929292;
  border-radius: 4px;
  position: absolute;
  top: 6.9em;
  right: 0.5em;
  background-color: rgb(255, 255, 255);
  z-index: 50;
  padding: 0 5px;
  display: inline-block;
}
</style>

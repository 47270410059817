<template>
  <div>
    <!-- NOTE: sample template below - doesn't get inherited in subclass -->
    <Legend class="aux-legend" :legend="auxLegend"></Legend>
    <CommonLayersPopup></CommonLayersPopup>
    <LayerAux :layerOptions="translatedAuxLayerOptions"></LayerAux>
    <div v-if="isLoading === true" class="spinner-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import Legend from '../Legend'
import LayerAux from '../LayerAux'
import { i18nMixin } from '../I18N'
import CommonLayersPopup from '../CommonLayersPopup'
import emitter from '@/components/EventHub'

export default {
  name: 'AbstractBulletinAux',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { CommonLayersPopup, Legend, LayerAux },
  mixins: [i18nMixin],
  data() {
    return {
      auxLegend: null,
      auxLayerOptions: null, // auxiliary layers available for selection
      auxLayerSelection: {}, // current auxiliary layer selection
    }
  },
  beforeUnmount() {
    emitter.off('update::layer')
  },
  mounted() {
    emitter.on('update::layer', (layer) => {
      this.updateLayerSelection(layer)
      this.refreshLayers()
    })
    this.auxLayerOptions = this.getLayerOptions()
    this.initLayerSelection()
  },
  methods: {
    // eslint-disable-next-line complexity
    updateLayerSelection(layer) {
      // update layer selection can be re-implemented in subclass
      // EXAMPLE: react only on those of type 'bulletin' - to be overwritten in subcomponent if needed
      if (
        layer &&
        layer.productVariant &&
        layer.productVariant.startsWith('danger.bulletin')
      ) {
        this.auxLayerSelection.productVariant = layer.productVariant
      }
      if (layer && layer.layers) {
        const auxLayers = {}
        for (const layerKey in layer.layers) {
          if (
            Object.prototype.hasOwnProperty.call(layer.layers, layerKey) &&
            layerKey.startsWith('danger.bulletin')
          ) {
            auxLayers[layerKey] = layer.layers[layerKey]
          }
          this.auxLayerSelection.layers = auxLayers
        }
      }
    },
    getLayerOptions() {
      // layer options can be re-implemented in subclass
      // EXAMPLE: bulletin
      return {
        items: [
          {
            key: 'danger.bulletin',
            label: this.$i18n.t('bulletin.label.productBulletin'),
            options: this.options('bulletin', 'dangerMap', true),
          },
        ],
      }
      // }
    },
    initLayerSelection() {
      // NOTE (20200203/csz): initial layer selection to be implemented in subclass as it varies: static init or
      // nothing (if selection dependent on other criteria /-> 'update::layerList' event/)
    },
    refreshLayers() {
      // refresh layers to be implemented in subclass
    },
  },
  computed: {
    translatedAuxLayerOptions() {
      if (this.auxLayerOptions) {
        return this.getLayerOptions()
      } else {
        return this.auxLayerOptions
      }
    },
  },
}
</script>

<!--
Scoped Styles werden, wenn diese Komponente mit 'extends' wiederverwendet
wird, im Parent nicht mehr berücksichtigt. Daher wird dieser Teil ausgelagert
und in den Parents importiert. ACHTUNG: Hier trotzdem auch belassen/importieren.
Dies ist nötig, falls die Komponente, als 'Component-Child' verwendet wird!
-->
<style lang="css" scoped src="./AbstractBulletinAux.css"></style>

'use strict'

import Area from '@/model/assessment/Area'

export default class AvalancheSafetyService {
  constructor(id, name, areas) {
    this.id = id
    this.name = name
    this.areas = areas
  }
  filter() {
    return new AvalancheSafetyService(
      this.id,
      this.name,
      this.areas?.filter((o) => o.active).map((o) => o.filter()) || []
    )
  }
  static filter(el) {
    el.areas = el.areas.filter((a) => a.active)
    el.areas.forEach(Area.filter)
    return el
  }
}

<script>
import ObservationDetail from '@/components/observation/ObservationDetail.vue'
import { DETAIL_COMPONENTS } from '@/scripts/const'
import moment from 'moment'

export default {
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: ObservationDetail,
  name: 'SnowprofileDetail',
  data() {
    return {}
  },
  methods: {
    showOrAggregateObservation() {
      if (this.aval) {
        this.components = []
        this.obs = []
        this.obs.push(this.createSnowprofilerObs(this.aval[0]))
        this.importComponent(DETAIL_COMPONENTS[8])
        this.loadUserData(this.getHeaderInfo(this.aval))
      }
    },
    getHeaderInfo(feature) {
      let coord
      let uid
      if (Array.isArray(feature) && feature.length > 0) {
        uid = feature[0].get('uid')
        const geometry = feature[0].get('geometry')
        if (
          geometry &&
          Array.isArray(geometry.flatCoordinates) &&
          geometry.flatCoordinates.length > 1
        ) {
          coord =
            geometry.flatCoordinates[0].toString() +
            '/' +
            geometry.flatCoordinates[1].toString()
        }
      }
      return {
        uid: uid,
        coord: coord,
        place: undefined,
      }
    },
    createSnowprofilerObs(snowprofileFeature) {
      if (snowprofileFeature) {
        return {
          uid: snowprofileFeature.get('uid'),
          date: snowprofileFeature.get('oDate'),
          cTimestamp: moment.unix(snowprofileFeature.get('cTimestamp')),
          id: snowprofileFeature.get('id'),
          snowprofile: snowprofileFeature.get('snowprofile'),
        }
      }
      return undefined
    },
  },
}
</script>

<style lang="css" scoped src="../observation/ObservationDetail.css"></style>

import avalancheService from '@/services/avalancheService.js'

// state
const state = {
  triggerDateAvalanches: [], // avalanches of current date for map
  createDateAvalanches: [],
  selected: null,
  reportOlderAvalanche: false,
  reportOlderAvalancheDate: undefined,
}

// getters
const getters = {}

// actions
const actions = {
  loadAvalanchesByTriggerDateAndCreateDateTimeInterval(context, params) {
    return avalancheService
      .getAvalanchesByTriggerDateAndCreateDateTimeIntervalAndOptObserver(
        params.interval[0],
        params.interval[1],
        params.createInterval[0],
        params.createInterval[1],
        params.userId,
        params.cancel
      )
      .then((avalanches) => {
        if (!avalanches) {
          return
        }
        context.commit('SET_TRIGGER_AVALANCHES', avalanches.data)
        context.commit('SET_EXCEPTION', null, { root: true })
        console.debug('avalanches by trigger date loaded')
      })
      .catch(function (error) {
        context.commit('SET_TRIGGER_AVALANCHES', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },

  loadAvalanchesByCreateDateInterval(context, params) {
    return avalancheService
      .getAvalanchesByCreationDateIntervalAndOptObserver(
        params.interval[0],
        params.interval[1],
        params.userId,
        params.cancel
      )
      .then((avalanches) => {
        context.commit('SET_CREATE_AVALANCHES', avalanches.data)
        context.commit('SET_EXCEPTION', null, { root: true })
        console.log('avalanches by creation date loaded')
      })
      .catch(function (error) {
        context.commit('SET_CREATE_AVALANCHES', [])
        console.error(error)
        if (!(error.response && error.response.status === 401)) {
          context.commit('SET_EXCEPTION', error.message, { root: true })
        }
      })
  },

  addSingleAvalanche(context, singleAvalanche) {
    return new Promise((resolve, reject) => {
      avalancheService
        .createSingleAvalanche(singleAvalanche)
        .then(function (response) {
          console.log('singleAvalanche added')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data
            ) {
              context.commit(
                'SET_EXCEPTION',
                {
                  message: error.message,
                  details: error.response.data.fieldErrors,
                },
                { root: true }
              )
            } else {
              context.commit('SET_EXCEPTION', error.message, { root: true })
            }
          }
          reject(error)
        })
    })
  },

  updateSingleAvalanche(context, singleAvalanche) {
    return new Promise((resolve, reject) => {
      avalancheService
        .updateSingleAvalanche(singleAvalanche)
        .then(function (response) {
          console.log('singleAvalanche updated')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },

  getSingleAvalanche(context, id) {
    return new Promise((resolve, reject) => {
      avalancheService
        .getSingleAvalanche(id)
        .then(function (response) {
          console.log('got singleAvalanche')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },

  addSeveralAvalanche(context, severalAvalanche) {
    return new Promise((resolve, reject) => {
      avalancheService
        .createSeveralAvalanche(severalAvalanche)
        .then(function (response) {
          console.log('severalAvalanche added')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },

  updateSeveralAvalanche(context, severalAvalanche) {
    return new Promise((resolve, reject) => {
      avalancheService
        .updateSeveralAvalanche(severalAvalanche)
        .then(function (response) {
          console.log('severalAvalanche updated')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },

  getSeveralAvalanche(context, id) {
    return new Promise((resolve, reject) => {
      avalancheService
        .getSeveralAvalanche(id)
        .then(function (response) {
          console.log('got severalAvalanche')
          context.commit('SET_EXCEPTION', null, { root: true })
          resolve(response.data)
        })
        .catch(function (error) {
          console.error(error)
          if (!(error.response && error.response.status === 401)) {
            context.commit('SET_EXCEPTION', error.message, { root: true })
          }
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_TRIGGER_AVALANCHES(state, avalanches) {
    state.triggerDateAvalanches = avalanches
  },
  SET_CREATE_AVALANCHES(state, avalanches) {
    state.createDateAvalanches = avalanches
  },
  SET_SELECTED(state, selected) {
    state.selected = selected
  },
  SELECT(state, aval) {
    state.selected = aval
  },
  SET_REPORT_OLDER_AVAL_DATE(state, reportDate) {
    state.reportOlderAvalancheDate = reportDate
  },
  SET_REPORT_OLDER_AVAL(state, hastToReport) {
    state.reportOlderAvalanche = hastToReport
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

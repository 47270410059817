'use strict'

import { i18n } from '@/plugins/i18n'

export default class TableEntryLabelBuilder {
  /*
  Erstellt die Labels/Keys für die Tabellen-Entries
  */
  static getLabel(key, propDef, reprotDef) {
    let label = TableEntryLabelBuilder.getLabelPrefix(propDef)
    if (propDef.label) {
      label = label + i18n.global.t(propDef.label)
    } else {
      label = label + i18n.global.t(reprotDef.labelBase + '.' + key)
    }
    label = label + TableEntryLabelBuilder.getLabelSuffix(propDef)
    return label + ':'
  }

  static getLabelPrefix(propDef) {
    if (propDef.labelPrefix) {
      return i18n.global.t(propDef.labelPrefix) + ' '
    }
    return ''
  }

  static getLabelSuffix(propDef) {
    if (propDef.labelSuffix) {
      return ' ' + i18n.global.t(propDef.labelSuffix)
    }
    return ''
  }
}

import {
  AdditionalLayer,
  BaseLayer,
  CommonLayersState,
} from '@/model/map/commonLayers'

export default {
  namespaced: true as const,
  state: {
    baseLayer: BaseLayer.COLOR_MAP,
    additionalLayers: [],
  } as CommonLayersState,
  mutations: {
    SET_BASE_LAYER(state: CommonLayersState, baseLayer: BaseLayer): void {
      state.baseLayer = baseLayer
    },
    SET_ADDITIONAL_LAYERS(
      state: CommonLayersState,
      additionalLayers: AdditionalLayer[]
    ): void {
      state.additionalLayers = [...additionalLayers]
    },
  },
}

import axios, { AxiosResponse } from 'axios'

const ENVIRONMENT_FILE_URL = './environment'

export class EnvironmentService {
  private static settings: Record<string, string> = {}

  static async initialize(): Promise<void> {
    const response: AxiosResponse<string> = await axios.get(
      ENVIRONMENT_FILE_URL
    )
    this.settings = Object.fromEntries(
      response.data
        .split('\n')
        .map((el) => el.split('=', 2))
        .filter((el) => el.length > 1)
    )
  }

  private static getPrefixedVariableName(variableName: string): string {
    return 'VUE_APP_' + variableName
  }

  static getOptionalVariable(variableName: string): string | undefined {
    const prefixedVariableName = this.getPrefixedVariableName(variableName)
    return (
      EnvironmentService.settings[variableName] ||
      process.env[prefixedVariableName]
    )
  }

  static getVariable(variableName: string): string {
    const variable = this.getOptionalVariable(variableName)

    if (!variable) {
      throw new Error(
        `Environment variable '${this.getPrefixedVariableName(
          variableName
        )}' not defined!`
      )
    }
    return variable
  }
}

'use strict'

import BaseObject from './BaseObject'
import Building from './Building'
import Path from './Path'

export default class Area extends BaseObject {
  // prettier-ignore
  constructor(id, name, location, active, warningLight, sectors, paths, buildings, avalanchePaths) {
    super(id, name, location, active)
    this.warningLight = warningLight
    this.sectors = sectors
    this.paths = paths
    this.buildings = buildings
    this.avalanchePaths = avalanchePaths
    this.objects = [].concat(this.paths, this.buildings)
  }
  filter() {
    return new Area(
      this.id,
      this.name,
      this.location,
      this.active,
      this.warningLight,
      Area.map(this.sectors, BaseObject),
      Area.map(this.paths, Path),
      Area.map(this.buildings, Building),
      Area.map(this.avalanchePaths, BaseObject)
    )
  }
  static map(arr, clazz) {
    return arr?.filter((o) => o.active).map((o) => clazz.clone(o)) || []
  }
}

<template>
  <b-container>
    <b-alert
      v-model="showWarning"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000"
      variant="warning"
      dismissible
    >
      <strong>{{
        $t('common.reporting.manyReportElement1', {
          count: this.filteredItems.length,
        })
      }}</strong
      ><br />
      {{ $t('common.reporting.manyReportElement2') }}<br /><br />
      {{ $t('common.reporting.manyReportElement3') }}
    </b-alert>
    <b-card class="settings">
      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="$t('common.from')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="from-datepicker"
            class="mb-0"
          >
            <b-input-group size="sm">
              <calendar-component
                v-model="fromDateString"
                :max="toDate"
                @update:model-value="refresh"
                size="sm"
                class="mb-2 w-100 list-datepicker"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="$t('common.to')"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
            label-for="to-datepicker"
            class="mb-0"
          >
            <b-input-group size="sm">
              <calendar-component
                v-model="toDateString"
                :min="fromDate"
                @update:model-value="refresh"
                size="sm"
                class="mb-2 w-100 list-datepicker"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="settingsField">
          <b-form-group
            :label="$t('common.filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filter-input"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filter-input"
                :placeholder="$t('common.typeToSearch')"
              />
              <b-input-group-append>
                <b-button
                  class="journal-print-button"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t('common.clear') }}
                </b-button>
              </b-input-group-append>
              <b-button
                id="journal-report-button"
                size="sm"
                class="ml-2 journal-print-button"
                :disabled="
                  !this.filteredItems ||
                  this.filteredItems <= 0 ||
                  reportIsLoading
                "
                v-show="!reportIsLoading"
              >
                <font-awesome-icon
                  class="modal-print-icon"
                  icon="fa-solid fa-file-pdf"
                  size="lg"
                />
              </b-button>
              <div v-show="reportIsLoading" class="ml-2">
                <b-spinner />
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <AvalServiceSelect
            @avalServiceChanged="avalServiceChanged"
            :loaded="loaded"
            :selAvalService="selectedAvalServiceId"
          />
        </b-col>

        <b-col sm="5" md="6">
          <b-form-group
            :label="$t('common.perPage')"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-table
      id="observationList"
      small
      hover
      bordered
      responsive
      v-if="loaded && loadedItems.length > 0"
      sort-by="cTimestamp"
      :sort-desc="true"
      :items="items"
      :fields="fields"
      :filter="filter"
      :current-page="currentPage"
      :per-page="perPage"
      @filtered="onFiltered"
      @row-clicked="showDetailModal"
    />

    <b-row>
      <b-col>
        <b-alert
          variant="success"
          :show="loaded === true && loadedItems.length === 0"
        >
          {{ $t('ui.feedback.empty') }}
        </b-alert>
        <div v-if="!loaded" class="spinner-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row>

    <DetailModal
      det-id="detailModal"
      :obs-ids="obsIds"
      :aggregate="false"
      :aval="aval"
    />
    <DetailModalMeasurement :measurement="measurement" />
    <DetailModalAssessment
      det-id="detailModalAssessment"
      :assessment="assm"
      :aggregate="false"
    />
    <CompactPrintPreparationPopover
      :hasAvalancheServiceName="this.selectedAvalServiceId != null"
      @compactPdfPreparation="pdfReport"
    />
  </b-container>
</template>

<script>
import DetailModal from '@/components/observation/DetailModal'
import DetailModalMeasurement from '@/components/studyplot/DetailModalMeasurement'
import DetailModalAssessment from '@/components/assessment/detail/DetailModalAssessment'
import { v1 as uuidV1 } from 'uuid'
import util from '@/scripts/util'
import assessmentUtil from '@/scripts/assessmentUtil.js'
import obsTypeConverter from '@/scripts/obsTypeConverter.js'
import { DateTime } from 'luxon'

import moment from 'moment'
import {
  SNOW,
  DANGERSIGN,
  DANGER,
  AVALANCHE,
  ATTACHMENT,
  STUDYPLOTOBSERVATION,
  STUDYPLOTOBSERVATION_MORNING,
  SNOWPROFILE,
  MEASURE,
  I18N,
  ASSESSMENT_SECTOR,
  ASSESSMENT_SITUATION,
  ASSESSMENT_DECISION_AREA,
  ASSESSMENT_DECISION_SECTOR,
  TRIGGERING,
} from '@/scripts/const.js'
import { Canceler } from '@/scripts/requestUtil.js'
import CalendarComponent from '@/components/calendar/CalendarComponent'
import { journalReportMixin } from '@/components/reporting/JournalReportMixin.js'
import AvalServiceSelect from '@/components/observation/list/AvalServiceSelect.vue'
import { deepClone } from '@/scripts/common'
import CompactPrintPreparationPopover from '@/components/reporting/CompactPrintPreparationPopover.vue'

const OBSERVATIONS = [
  SNOW,
  DANGERSIGN,
  DANGER,
  ATTACHMENT,
  STUDYPLOTOBSERVATION,
]

const Cancel = Canceler.getCancelObj()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'List',
  mixins: [journalReportMixin],
  data() {
    return {
      fields: [
        {
          key: 'cTimestamp',
          label: 'Erstelldatum',
          sortable: true,
          filterByFormatted: false,
          formatter: this.datetimeFormatter,
        },
        {
          key: 'type',
          label: 'Typ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: this.typeFormatter,
        },
        {
          key: 'uid',
          label: 'Ersteller',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (v, k, i) => this.userFormatter(v, i),
        },
      ],
      // Enthält die Elemente, die von den Services geladen werden (Cache).
      loadedItems: [],
      // Enthält nur noch die vorgefilterten Elemente (Je nach Selectbox Lawinendienst). Diese werden der Tabelle übergeben.
      items: [],
      // In der Tabelle selber kann über die Textbox "Filter" noch nachgefiltert werden. Damit dieser Filter beim Report erzeugen
      // auch berücksichtigt wird, kann ich nicht einfach die items der Tabelle übergeben.
      filteredItems: [],
      filter: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      obsIds: [],
      aval: {},
      measurement: {},
      assm: {},
      loaded: false,
      fromDate: moment()
        .add(-1, 'week')
        .add(1, 'day')
        .startOf('day')
        .toISOString(),
      toDate: moment().endOf('day').toISOString(),
      openObs: undefined,
    }
  },
  components: {
    CompactPrintPreparationPopover,
    DetailModalAssessment,
    DetailModal,
    DetailModalMeasurement,
    CalendarComponent,
    AvalServiceSelect,
  },

  beforeUnmount() {
    this.cancelRequests()
  },

  created() {
    if (this.$route.query) {
      if (this.$route.query.openObs) {
        this.openObs = this.$route.query.openObs
      }
      if (this.$route.query.openPlusMenu) {
        this.$root.$emit('bv::show::modal', 'obsModal')
      }
    }
  },

  mounted() {
    this.refresh()
  },

  computed: {
    fromDateString: {
      get() {
        return moment(this.fromDate).format('DD.MM.YYYY')
      },
      set(val) {
        this.fromDate = moment(val, 'DD.MM.YYYY').toISOString()
      },
    },
    toDateString: {
      get() {
        return moment(this.toDate).format('DD.MM.YYYY')
      },
      set(val) {
        this.toDate = moment(val, 'DD.MM.YYYY').toISOString()
      },
    },
  },

  methods: {
    // eslint-disable-next-line complexity
    refresh() {
      this.cancelRequests()
      const self = this
      this.items = []
      this.onFiltered(this.items)
      this.filter = null
      this.loaded = false
      this.$store.commit('observation/SET_IS_FEEDBACK_LOADED', false)

      const promises = []
      const upperDate = moment(this.toDate).add(1, 'days')
      const lowerDate = moment(this.fromDate)
      const interval = [lowerDate, upperDate]
      const range = upperDate.diff(lowerDate, 'days')

      const cancelObj = { cancel: null }
      promises.push(
        this.$store.dispatch('avalanche/loadAvalanchesByCreateDateInterval', {
          interval: interval,
          userId: this.$store.getters.user.id,
          cancel: cancelObj,
        })
      )
      if (Cancel) {
        Cancel.addCancel(cancelObj.cancel)
      }

      let cancel = { cancel: null }
      promises.push(
        this.$store.dispatch('observation/loadObservations', {
          upperDate,
          range,
          cancel,
        })
      )
      if (Cancel) {
        Cancel.addCancel(cancel.cancel)
      }

      cancel = { cancel: null }
      promises.push(
        this.$store.dispatch('studyplot/loadMeasurements', {
          upperDate,
          range,
          cancel,
        })
      )
      if (Cancel) {
        Cancel.addCancel(cancel.cancel)
      }

      cancel = { cancel: null }
      promises.push(
        this.$store.dispatch('snowprofile/getDetailSnowProfiles', {
          startObsDate: lowerDate
            ? DateTime.fromISO(lowerDate.toISOString())
            : null,
          endObsDate: upperDate
            ? DateTime.fromISO(upperDate.toISOString())
            : null,
          userId: this.$store.getters.user.id,
          cancel,
        })
      )
      if (Cancel) {
        Cancel.addCancel(cancel.cancel)
      }

      let contactIds = []
      Promise.all(promises)
        .then(() => {
          this.loaded = true
          this.$store.commit('observation/SET_IS_FEEDBACK_LOADED', true)
          /*eslint-disable complexity*/
          this.$store.state.observation.observations.forEach((el) => {
            if (el.uid) {
              contactIds = contactIds.concat(el.uid)
            }
            const isAssessment = util.isAssessmentContent(el, el.type)
            const typeToShow = obsTypeConverter.getSpecialTypeToCommonType(el)
            if (el.type === MEASURE) {
              this.items.push(
                this.$store.getters['measure/getMeasureForDetailModal'](el)
              )
            } else if (el.type === TRIGGERING) {
              this.items.push(
                this.$store.getters['triggering/getTriggeringForDetailModal'](
                  el
                )
              )
            } else {
              this.items.push({
                id: el.id,
                cTimestamp: el.cTimestamp,
                date: OBSERVATIONS.includes(el.type) ? el.date : null,
                type: typeToShow,
                uid: el.uid,
                assessment: isAssessment,
                areaId: el.hierarchy ? el.hierarchy.areaId : null,
                sectorId: el.hierarchy ? el.hierarchy.sectorId : null,
                creatorSystem: el.creatorSystem,
                hierarchy: el.hierarchy,
              })
            }
          })
          /*eslint-enable complexity*/
          this.$store.state.studyplot.measurements.forEach((el) => {
            if (el.uid) {
              contactIds = contactIds.concat(el.uid)
            }
            this.items.push({
              id: uuidV1(),
              cTimestamp: moment(el.cTimestamp).unix() * 1000,
              date: el.date,
              type: el.type,
              uid: el.uid,
              measurement: el,
              management: el.management,
            })
          })

          this.$store.state.avalanche.createDateAvalanches.forEach((el) => {
            if (el.userId) {
              contactIds = contactIds.concat(el.userId)
            }
            this.items.push({
              id: el.id,
              cTimestamp: moment.utc(el.creation).local().unix() * 1000,
              date: el.triggerDate,
              type: AVALANCHE,
              uid: el.userId,
              avalanche: el,
              creatorSystem: el.creatorSystem,
            })
          })

          const studyplots = this.$store.state.profile.studyplots.map(
            (el) => el.id
          )
          this.$store.state.snowprofile.detailSnowProfiles
            .filter((el) => studyplots.includes(el.profile.statAbk))
            .forEach((el) => {
              if (el.profile.userId) {
                contactIds = contactIds.concat(el.profile.userId)
              }
              this.items.push({
                id: el.profile.id,
                cTimestamp: moment(el.profile.obsDate).unix() * 1000,
                date: el.profile.obsDate,
                type: SNOWPROFILE,
                uid: el.profile.userId,
                profile: el,
              })
            })

          this.onFiltered(this.items)
          this.loadedItems = [...this.items]
          this.filteredItems = [...this.items]
          this.openDetailOnRouteBack()
        })
        .then(function () {
          // Die fehlenden User noch nachladen
          self.$store.dispatch('user/loadMissingContacts', contactIds)
        })
        .catch(function (err) {
          console.error(
            'Promise zum Abfragen der Rückmeldungen hat einen Fehler geliefert!'
          )
          if (err && err.message) {
            console.error('Fehlermeldung: ' + err.message)
          }
        })
    },
    openDetailOnRouteBack() {
      if (this.openObs) {
        if (!this.openObs.includes('studyplot')) {
          const index = this.items.findIndex((item) => {
            return item.id === this.openObs
          })
          if (index > -1) {
            this.showDetailModal(this.items[index])
          }
        } else {
          const measurementIndex = this.items.findIndex((item) => {
            if (item.measurement) {
              const id =
                'studyplot.' +
                item.measurement.studyplot +
                '.' +
                item.type +
                '.' +
                item.date
              return id === this.openObs
            } else {
              return false
            }
          })
          if (measurementIndex > -1) {
            this.showDetailModal(this.items[measurementIndex])
          }
        }
      }
    },

    datetimeFormatter(value) {
      return moment(value).format('DD.MM.YYYY HH:mm')
    },

    userFormatter(id, item) {
      const user = this.$store.getters['user/getUserToIdOrDummyUser'](
        id,
        item.creatorSystem
      )
      const firstNameAcronym = util.acronymize(user.firstName)
      return user ? `${firstNameAcronym} ${user.lastName}` : '-'
    },
    // eslint-disable-next-line complexity
    getAssessmentAreaSuffix(value, item) {
      if (
        ASSESSMENT_SITUATION === value ||
        ASSESSMENT_DECISION_AREA === value ||
        ATTACHMENT === value ||
        DANGER === value
      ) {
        const area = this.$store.getters['profile/getAreaWithId'](item.areaId)
        const defaultValue = value === ASSESSMENT_SITUATION ? '-' : ''
        return ' ' + (area ? area.name : defaultValue)
      } else {
        return ''
      }
    },
    getAssessmentSectorSuffix(value, item) {
      if (ASSESSMENT_SECTOR === value || ASSESSMENT_DECISION_SECTOR === value) {
        const area = this.$store.getters['profile/getAreaWithId'](item.areaId)
        const sector = this.$store.getters['profile/getSectorWithId'](
          area,
          item.sectorId
        )
        return ' ' + (sector ? sector.name : '-')
      } else {
        return ''
      }
    },
    getAssessmentMeasureSuffix(value, item) {
      if (MEASURE === value && item.objectMeasure) {
        return ' ' + item.objectMeasure.name
      } else {
        return ''
      }
    },
    getAssessmentTriggeringSuffix(value, item) {
      if (TRIGGERING === value && item.hierarchy) {
        const avalanchePath =
          this.$store.state.profile.avalanchePaths[
            item.hierarchy.avalanchePathId
          ]
        return ' ' + (avalanchePath ? avalanchePath.name : '-')
      } else {
        return ''
      }
    },
    typeFormatter(value, key, item) {
      let suffix = ''
      if (item) {
        suffix =
          this.getAssessmentAreaSuffix(value, item) +
          this.getAssessmentSectorSuffix(value, item) +
          this.getAssessmentMeasureSuffix(value, item) +
          this.getAssessmentTriggeringSuffix(value, item)
      }
      return this.getTypeName(value, suffix, item)
    },
    getTypeName(value, suffix, item) {
      let typeName = this.$t(I18N[value])
      typeName = assessmentUtil.getAssessmentTypeName(value, item, typeName)

      return typeName + suffix || value
    },
    /*eslint-disable complexity*/

    showDetailModal(obsAvalSrc) {
      const obsAval = Object.assign({}, obsAvalSrc)
      let type = obsAval.type
      if (!type && obsAval[AVALANCHE]) {
        type = AVALANCHE
      }
      switch (type) {
        case SNOW:
        case DANGERSIGN:
        case STUDYPLOTOBSERVATION:
          this.showObsCommonDetails(obsAval)
          break
        case ATTACHMENT:
        case DANGER:
          if (obsAval.assessment) {
            this.showAssessmentDetails(obsAval)
          } else {
            this.showObsCommonDetails(obsAval)
          }
          break
        case ASSESSMENT_DECISION_AREA:
        case ASSESSMENT_DECISION_SECTOR:
        case ASSESSMENT_SITUATION:
        case ASSESSMENT_SECTOR:
        case MEASURE:
        case TRIGGERING:
          this.showAssessmentDetails(obsAval)
          break
        case AVALANCHE:
          this.showAvalancheDetails(obsAval.avalanche)
          break
        case STUDYPLOTOBSERVATION_MORNING:
          this.showMeasurementDetails(obsAval)
          break
        case SNOWPROFILE:
          this.showProfileDetails(obsAval)
          break
        default:
          console.error('Type unknown for DetailModal: ' + type)
          break
      }
    },
    /*eslint-enable complexity*/
    cancelRequests() {
      Cancel.cancelAll()
    },
    showObsCommonDetails(item) {
      this.obsIds = [item.id]
      this.$bvModal.show('detailModal')
    },

    showAvalancheDetails(item) {
      this.obsIds = []
      this.aval = deepClone(item)
      this.$bvModal.show('detailModal')
    },

    showMeasurementDetails(item) {
      this.obsIds = []
      this.aval = {}
      this.measurement = Object.assign({}, item)
      this.$bvModal.show('detailModalMeasurement')
    },

    showProfileDetails(item) {
      window.open(
        item.profile.url,
        '_blank',
        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
      )
    },

    showAssessmentDetails(item) {
      this.assm = item
      this.$bvModal.show('detailModalAssessment')
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredItems = [...filteredItems]
    },
    avalServiceChanged(value) {
      this.items = this.loadedItems.filter(
        (item) =>
          value === null ||
          (item.hierarchy && item.hierarchy.avalServiceId === value)
      )
      this.onFiltered(this.items)
      this.selectedAvalServiceId = value
    },
  },
}
</script>

<style scoped>
.journal-print-button {
  height: 31px;
}
.settings {
  margin-bottom: 20px;
}
.settingsField {
  margin-bottom: 10px;
}
.list-datepicker {
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 0.875rem;
  padding: 2px 0;
}
</style>

export function round(value: number, decimalPlaces: number): number {
  if (decimalPlaces < 0) {
    return Number(
      Math.round(Number(value + 'e-' + -decimalPlaces)) + 'e' + -decimalPlaces
    )
  }
  return Number(
    Math.round(Number(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
  )
}

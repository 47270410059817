import Vector from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
export const Z_INDEX_LAYER_REGIONS = 0
import { resolutionLimit } from '@/scripts/layers/tileLayerUtil'
import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'
import { FeatureCollection } from 'geojson'
import VectorLayer from 'ol/layer/Vector'

export const WARNREGION_SWISSTOPO_STROKE_WIDTH = 2
export const WARNREGION_SWISSTOPO_STROKE_COLOR = '#474797'
export const WARNREGION_DEFAULT_STROKE_WIDTH = 0.4
export const WARNREGION_DEFAULT_STROKE_COLOR = '#8989d4'

const SWISSTOPO_STYLE = new Style({
  stroke: new Stroke({
    color: WARNREGION_SWISSTOPO_STROKE_COLOR,
    width: WARNREGION_SWISSTOPO_STROKE_WIDTH,
  }),
})
const DEFAULT_STYLE = new Style({
  stroke: new Stroke({
    color: WARNREGION_DEFAULT_STROKE_COLOR,
    width: WARNREGION_DEFAULT_STROKE_WIDTH,
  }),
})

export const warnregionLayer = function (
  geojsonObject: FeatureCollection
): VectorLayer<VectorSource<Feature<Geometry>>> {
  const regionLayer = new Vector({
    source: new VectorSource({
      features: new GeoJSON({
        dataProjection: 'EPSG:4326',
      }).readFeatures(geojsonObject, {
        featureProjection: 'EPSG:21781',
      }) as Feature[],
    }),
    style: (feature, zoom) => {
      return zoom > resolutionLimit ? DEFAULT_STYLE : SWISSTOPO_STYLE
    },
  })
  regionLayer.setZIndex(Z_INDEX_LAYER_REGIONS)
  return regionLayer
}

'use strict'

export default class BaseObject {
  constructor(id, name, location, active) {
    this.id = id
    this.name = name
    this.location = location
    this.active = active
  }
  static clone(o) {
    return new this(o.id, o.name, o.location, o.active)
  }
}

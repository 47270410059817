'use strict'

/**
 * Object to track the requests made by this view,
 * to be able to cancel them on a reload (entrypoint: getObservationData)
 * and on view destruction.
 */
class CancelObj {
  cancelBucket = []

  addCancel(cancel) {
    this.cancelBucket.push(cancel)
  }

  cancelAll() {
    let elem
    while (this.cancelBucket.length > 0) {
      elem = this.cancelBucket.pop()
      if (elem) {
        elem()
      }
    }
  }
}

export const Canceler = {
  getCancelObj() {
    return new CancelObj()
  },
}
